import React, { useEffect } from "react";
import { ReactEditor, useSelected, useSlateStatic } from "slate-react";
import cn from "classnames";
import { useHistory } from "react-router-dom";

import { useElementContextMenu } from "components/slate/plugins/menus/formatting/ElementContextMenu";
import useReactNativeWebView from "hooks/useReactNativeWebView";
import { ElementProps } from "components/slate/types";
import { LinkElement, LinkType } from "components/slate/plugins/link/types";
import { useStaticProps } from "components/slate/hooks/useStaticProps";

import styles from "./index.module.scss";

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
// copied from slate inlines examples
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0, lineHeight: 1 }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const LinkComponent = (props: ElementProps & { element: LinkElement }) => {
  const { attributes, children, element } = props;
  const { url } = element;

  const history = useHistory();
  const selected = useSelected();
  const editor = useSlateStatic();

  const { isReactNativeWebView } = useReactNativeWebView();

  const { isHelpDocs } = useStaticProps();

  const {
    openElementContextMenu,
    closeElementContextMenu,
    setIsTargetHovered,
  } = useElementContextMenu();

  const handleMouseEnter = () => {
    if (!isReactNativeWebView) {
      const path = ReactEditor.findPath(editor, element);
      setIsTargetHovered(true);
      openElementContextMenu(LinkType, path, element);
    }
  };

  const handleMouseLeave = () => {
    setIsTargetHovered(false);
  };

  useEffect(() => {
    return () => closeElementContextMenu();
  }, []);

  return (
    <a
      {...attributes}
      className={cn("slate-LinkElement", {
        [styles.selected]: selected,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      href={url}
      onClick={(e) => {
        if (isHelpDocs && url.startsWith("/")) {
          // use internal routing for custom help pages
          e.preventDefault();
          history.push(url);
          return;
        }

        const linkElement = e.currentTarget;

        if (linkElement) {
          e.preventDefault();
          const href = linkElement.href;
          window.open(href, "_blank");
        }
      }}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};

export default LinkComponent;
