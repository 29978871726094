import { useCallback } from "react";
import { doc, updateDoc } from "firebase/firestore";

import { AutoLightDarkIcon, SunIcon, MoonIcon } from "thunk-icons";
import Button from "framework/components/form/Button";
import {
  ThemeTypes,
  ThemeValues,
  useThemeContext,
} from "providers/ThemeProvider";
import useAppPopper from "framework/components/poppers/useAppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import AppPopper, {
  PopperMenuItem,
} from "framework/components/poppers/AppPopper";
import { db } from "firebaseInstance";
import { useCurrentUserId } from "db/currentUser";

import styles from "./ThemePicker.module.scss";

interface Props {
  btnClass?: string;
}

const ThemeIcon = {
  [ThemeTypes.light]: SunIcon,
  [ThemeTypes.dark]: MoonIcon,
  [ThemeTypes.auto]: AutoLightDarkIcon,
};

const ThemeIconElement = {
  [ThemeTypes.light]: <SunIcon size={22} />,
  [ThemeTypes.dark]: <MoonIcon size={22} />,
  [ThemeTypes.auto]: <AutoLightDarkIcon size={22} />,
};

const ThemeLabel = {
  [ThemeTypes.light]: "Light Theme",
  [ThemeTypes.dark]: "Dark Theme",
  [ThemeTypes.auto]: "Auto",
};

export const ThemePicker = ({ btnClass }: Props) => {
  const { theme } = useThemeContext();
  const userId = useCurrentUserId();

  const popperProps = useAppPopper({
    placement: "top",
    hasArrow: false,
    offset: [0, 14],
  });
  useClickPopper(popperProps);

  const switchTheme = useCallback(
    async (theme: ThemeTypes) => {
      try {
        await updateDoc(doc(db, `users/${userId}`), {
          appTheme: theme,
        });
      } catch (e) {
        console.error(e.message);
        return e;
      }
    },
    [userId]
  );

  return (
    <>
      <AppPopper
        {...popperProps}
        content={<ThemeOptions currentTheme={theme} setTheme={switchTheme} />}
      />
      <Button
        ref={popperProps.setTargetElement}
        variant="circularElectronHeader"
        size="large"
        btnClass={btnClass}
        icon={ThemeIcon[theme]}
      >
        {ThemeLabel[theme]}
      </Button>
    </>
  );
};

const ThemeOptions = ({
  currentTheme,
  setTheme,
}: {
  currentTheme: ThemeTypes;
  setTheme: (theme: ThemeTypes) => void;
}) => {
  return (
    <div className={styles.popperContainer}>
      {ThemeValues.map((theme) => {
        const isSelected = theme === currentTheme;
        return (
          <PopperMenuItem itemClass={styles.themePickerListItem}>
            <div
              className={styles.themePickerIconAndLabel}
              onClick={() => setTheme(theme)}
            >
              {ThemeIconElement[theme]}
              {ThemeLabel[theme]}
            </div>
          </PopperMenuItem>
        );
      })}
    </div>
  );
};
