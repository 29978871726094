import React, { useState } from "react";

import { useCurrentUserId } from "db/currentUser";
import { Feedback, FeedbackMood } from "db/feedback/feedback.types";
import { useAuth } from "auth/AuthProvider";
import FeedbackMoodSwitch from "components/feedback/components/FeedbackMoodSwitch";
import FeedbackTextarea from "components/feedback/components/FeedbackTextarea";
import Button from "framework/components/form/Button";
import { useFeedback } from "components/feedback/hooks/useFeedback";
import { sendFeedbackToHeapAsEvent } from "components/feedback/helpers";
import Result from "framework/components/Result";
import usePersistedUserState from "hooks/usePersistedUserState";

import styles from "./index.module.scss";

type Values = { mood: FeedbackMood | null; text: string };
const initialValues = { mood: null, text: "" };

const FeedbackFormWidget = () => {
  const { feedbackId, user, upsertUserFeedback, setHasFeedback } =
    useFeedback();
  const { currentUser, authAccessType } = useAuth();
  const userId = useCurrentUserId();

  const [submitting, setSubmitting] = useState(false);
  const [finished, setFinished] = useState(false);

  const [values, setValues] = usePersistedUserState<Values>(
    "feedbackForm",
    (restored) => restored || initialValues
  );
  const updateValues = (updates: Partial<Values>) =>
    setValues((state) => ({ ...state, ...updates }));

  const handleFeedback = async () => {
    setSubmitting(true);
    try {
      // mb we don't need to store all this information in firestore since we send it to heap
      // let's see after some time
      const feedback: Feedback = {
        id: feedbackId,
        userId,
        email: currentUser.email,
        userCreatedAt: user?.createdAt ?? null,
        firstName: user?.firstName ?? null,
        lastName: user?.lastName ?? null,
        authAccessType: authAccessType,
        updatedAt: new Date(),
        type: "feedback",
        ...values,
      };

      sendFeedbackToHeapAsEvent(feedback);
      setHasFeedback(true);
      await upsertUserFeedback({ feedback });
      setSubmitting(false);
      setFinished(true);
    } catch (error) {
      // do nothing with error
      console.error(error);
      setSubmitting(false);
      setFinished(true);
    } finally {
      setValues(initialValues);
    }
  };

  if (finished) {
    return <Result title="Thank you!" isCenteredContent={true} />;
  }

  return (
    <div style={{ marginTop: 20 }} className={styles.container}>
      <span style={{ fontSize: 19, marginBottom: 15 }}>
        How is Thunk Notes making you feel?
      </span>
      <FeedbackMoodSwitch
        selectedMood={values.mood}
        onFeedback={(mood) => updateValues({ mood })}
      />
      <div style={{ padding: "10px 35px" }}>
        <FeedbackTextarea
          autoFocus={false}
          value={values.text}
          onChange={(e) => updateValues({ text: e.target.value })}
        />
        <div style={{ float: "right" }}>
          <Button
            disabled={values.mood == null}
            variant="primary"
            loading={submitting}
            onClick={() => handleFeedback()}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackFormWidget;
