import React from "react";

//  @ts-ignore ts-migrate(2307) FIXME: Cannot find module './index.module.scss' or its co... Remove this comment to see the full error message
import styles from "components/slate/plugins/menus/formatting/_shared/ToolbarMenuContainer/index.module.scss";

export const ToolbarMenuContainer = (props: any) => {
  const { children } = props;

  return <div className={styles.toolbarMenuContainer}>{children}</div>;
};
