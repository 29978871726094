const errors = {
  "auth/user-not-found": "sign_in.errors.invalid_credentials",
  "auth/wrong-password": "sign_in.errors.invalid_credentials",
  "auth/network-request-failed": "errors.common.connection_error",
};

export type ErrorInfo = { message: string; code: string };

export const getErrorInfo = (error: any = {}): ErrorInfo => {
  const message = errors[error.code];

  if (message != null) {
    return {
      message,
      code: null, // prevent showing what exactly is wrong (email or password)
    };
  }

  return {
    message: "sign_in.errors.unknown",
    code: error.code,
  };
};
