import create from "zustand";
import { Range } from "slate";

import { getEmojis } from "components/slate/plugins/emoji/getEmojis";

export type EmojiPluginState = {
  search: string;
  emojis: any[];
  flatEmojis: any[];
  curRow: number;
  valueIndex: number;
  prevBeforeMatch: boolean;
  targetRange: Range | null;

  setSearch: (search: string) => void;
  setValueIndex: (valueIndex: number) => void;
  setCurRow: (curRow: number) => void;
  setPrevBeforeMatch: (prevBeforeMatch: boolean) => void;
  setTargetRange: (targetRange: Range | null) => void;
};

const initialState = {
  search: "",
  emojis: [],
  flatEmojis: [],
  curRow: 1,
  valueIndex: 0,
  prevBeforeMatch: false,
  targetRange: null,
};

const createState = () =>
  create<EmojiPluginState>((set) => ({
    ...initialState,
    setSearch: (search: any) =>
      set(() => {
        const { emojis, flatEmojis } = getEmojis(search);
        return { search, emojis, flatEmojis };
      }),

    setValueIndex: (valueIndex) => set({ valueIndex }),
    setCurRow: (curRow) => set({ curRow }),
    setPrevBeforeMatch: (prevBeforeMatch) => set({ prevBeforeMatch }),

    setTargetRange: (targetRange) =>
      set(() => {
        const emojis: any[] = [];
        const flatEmojis: any[] = [];
        return {
          targetRange,
          emojis,
          flatEmojis,
        };
      }),
  }));

export default createState;
