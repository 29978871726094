import { Editor, Node, Text } from "slate";
import { indexBy } from "ramda";
import { deserializeHtml } from "@udecode/plate-core";

import { isCodeBlockElement } from "components/slate/plugins/codeBlock/utils";
import { patchPastedClipboardHtml } from "components/slate/plugins/serialization/withDeserialize/patchPastedClipboardHtml";
import { deserializePlugins } from "components/slate/plugins/serialization/withDeserialize/deserializePlugins";
import { normalizeClipboardFragment } from "components/slate/plugins/serialization/withDeserialize/normalizeClipboardFragment";

const insertHtml = (editor: Editor, html: string): boolean => {
  const [plainTextEntry] = Editor.nodes(editor, {
    at: Editor.start(editor, editor.selection),
    match: (node) => isCodeBlockElement(node),
  });
  const isHtmlAllowed = !plainTextEntry;

  if (html && isHtmlAllowed) {
    html = html.replace(new RegExp(String.fromCharCode(160), "g"), " "); // replace whitespaces 160 to 32, they could be at links edges

    const document = new DOMParser().parseFromString(html, "text/html");

    patchPastedClipboardHtml(document.body);

    const htmlFragment = deserializeHtml(
      {
        ...editor,
        plugins: deserializePlugins,
        pluginsByKey: indexBy((x) => x.key, deserializePlugins),
      },
      { element: document.body }
    );

    normalizeClipboardFragment(htmlFragment);

    if (htmlFragment) {
      editor.insertFragment(htmlFragment);
      return true;
    }
  }

  return false;
};

export default insertHtml;
