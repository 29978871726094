import React from "react";
import cn from "classnames";

import {
  HashMenuItem,
  highlightMatch,
  highlightParse,
} from "components/slate/plugins/menus/hashMenu/items/utils";

import styles from "./index.module.scss";
import Tag from "framework/components/Tag";
import { makeTag } from "db/tags/tags.mapping";

type SectionMenuListItemProps = {
  search: string;
  item: HashMenuItem;
  isActive: boolean;
  onSelect: (e: Event, item: any) => void;
};

const SectionMenuListItem = ({
  search,
  item,
  isActive,
  onSelect,
}: SectionMenuListItemProps) => {
  const Icon = item.icon;

  return (
    <button
      className={cn(styles.menuItem, {
        [styles.focused]: isActive,
      })}
      onMouseDown={(e) => {
        e.preventDefault();
        const event = e as any; // rewrite this
        onSelect(event, item);
      }}
    >
      {renderText(item, search)}
    </button>
  );
};

export default SectionMenuListItem;

const renderText = (item: HashMenuItem, search: string) => {
  const tag = item.tag ?? makeTag({ userId: null, title: search });
  const parsed = highlightParse(item.text, highlightMatch(item.text, search));

  return (
    <Tag
      readOnly={true}
      color={tag.color}
      variant={tag.variant}
      emoji={tag.emoji}
    >
      <span className={styles.text}>
        {parsed.map((chunk) => {
          if (chunk.highlight) {
            return <span className={styles.mark}>{chunk.text}</span>;
          } else {
            return <span>{chunk.text}</span>;
          }
        })}
      </span>
    </Tag>
  );
};
