import { doc, updateDoc } from "firebase/firestore";
import {
  ref,
  deleteObject,
  uploadString,
  getDownloadURL,
} from "firebase/storage";

import { db, storage } from "firebaseInstance";

const getAvatarPath = (userId: any) => {
  return `users/${userId}/avatar`;
};

export const uploadImage = async (userId: any, image: any) => {
  const path = getAvatarPath(userId);

  const avatarRef = ref(storage, path);

  const snapshot = await uploadString(avatarRef, image, "data_url");
  const url = await getDownloadURL(snapshot.ref);
  await updateDoc(await doc(db, `users/${userId}`), {
    avatarUrl: url,
    avatarStoragePath: path,
  });
};

export const removeImage = async (userId: any) => {
  await updateDoc(doc(db, `users/${userId}`), {
    avatarUrl: null,
    avatarStoragePath: null,
  });
  const avatarRef = ref(storage, getAvatarPath(userId));
  await deleteObject(avatarRef);
};
