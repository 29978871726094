import React, { memo, useCallback, useLayoutEffect, useState } from "react";
import { Editor, Node, Transforms } from "slate";
import { useSlateStatic } from "slate-react";
import { History } from "history";

import { SlashMenuProps } from "components/slate/plugins/menus/slashMenu/types";
import RangePopper from "components/slate/components/RangePopper";
import ComponentMenuContent from "components/slate/plugins/menus/componentMenu/components/ComponentMenuContent";
import { MenuItem } from "components/slate/plugins/menus/componentMenu/types";

import styles from "components/slate/plugins/menus/slashMenu/components/SlashMenu.module.scss";

const SlashMenu = memo((props: SlashMenuProps) => {
  const { initialTarget, target, search, closeMenu } = props;
  const editor = useSlateStatic();

  const [node, setNode] = useState<Node | null>(null);
  useLayoutEffect(() => {
    if (target && editor.selection) {
      const path = Editor.path(editor, target, { depth: 1 });
      const node = Node.descendant(editor, path);
      setNode(node);
    } else {
      setNode(null);
    }
  }, [editor, target, setNode]);

  const handleSelect = useCallback(
    (e: Event, history: History, item: MenuItem) => {
      const keepOpened =
        "children" in item || item.variant === "back" || item.keepMenuOpened;

      if (!keepOpened) {
        Transforms.delete(editor, { at: target });
      }

      item.applyMenuItem && item.applyMenuItem({ editor, history });

      if (!keepOpened) {
        closeMenu();
      }
    },
    [editor, target, closeMenu]
  );

  return (
    <RangePopper
      isVisible={initialTarget != null}
      range={initialTarget}
      className={styles.menuTooltip}
    >
      <ComponentMenuContent
        isOpened={initialTarget != null}
        node={node}
        search={search}
        onSelect={handleSelect}
        onCancel={closeMenu}
      />
    </RangePopper>
  );
});

export default SlashMenu;
