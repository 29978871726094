import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import Button from "framework/components/form/Button";
import { useOnboardingContext } from "../OnboardingProvider";

import styles from "../index.module.scss";
import reminderStyles from "./CalReminder.module.scss";

const CalReminderAction = () => {
  const history = useHistory();
  const { reminderFormValues, setReminderFormValues } = useOnboardingContext();

  const setAction = useCallback(
    (action: string) => {
      setReminderFormValues({
        ...reminderFormValues,
        action,
      });
      if (["other", "afternoon", "evening"].includes(action)) {
        history.push("/onboarding/reminder/cue");
        return;
      }
      history.push("/onboarding/reminder/time");
    },
    [history, reminderFormValues, setReminderFormValues]
  );

  return (
    <>
      <h3 className={styles.onboardingHeadline}>Stay consistent</h3>
      <p className={styles.onboardingCopy}>
        Research shows it's easier to think when you have a plan for when and
        where you will do it.
      </p>

      <div className={reminderStyles.calOption}>
        <h3 className={styles.onboardingHeadlineSm}>
          When do you usually have the time to think and write?
        </h3>
        <div className={cn(reminderStyles.buttonList, reminderStyles.vertical)}>
          {[
            {
              label: "First thing—right after waking up",
              value: "first_thing",
            },
            { label: "After I get my coffee", value: "after_coffee" },
            { label: "When I sit down at my desk", value: "get_to_desk" },
            { label: "In the afternoon", value: "afternoon" },
            { label: "In the evening", value: "evening" },
            { label: "Other", value: "other" },
          ].map((option) => (
            <div className={reminderStyles.buttonListOption} key={option.value}>
              <Button
                isSelected={option.value === reminderFormValues.action}
                variant="secondary"
                onClick={() => setAction(option.value)}
              >
                {option.label}
              </Button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CalReminderAction;
