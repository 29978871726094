import React, { useState } from "react";
import { createEditor, Descendant, Element } from "slate";
import { Slate, Editable } from "slate-react";

import usePlugins from "components/slate/hooks/usePlugins";
import useRenderLeaf from "components/slate/hooks/useRenderLeaf";
import useEditor from "components/slate/hooks/useEditor";
import SlateExtended from "components/slate/slate-extended/SlateExtended";
import { DndStateProvider } from "components/slate/slate-extended/dnd/useDndState";
import useDragOverlayRenderElement from "components/slate/hooks/useDragOverlayRenderElement";
import usePlateEditorPlugins from "components/slate/hooks/usePlateEditorPlugins";
import useDecorate from "components/slate/hooks/useDecorate";

type Props = {
  initialValue: Descendant[];
};

const SlateDragOverlayEditor = (props: Props) => {
  const [value, setValue] = useState(props.initialValue);

  const { plugins } = usePlugins({
    isMainEditor: true,
    slateId: null,
  });
  const editor = useEditor(createEditor, plugins);

  const renderElement = useDragOverlayRenderElement(editor, plugins);
  const renderLeaf = useRenderLeaf(editor, plugins);
  const decorate = useDecorate(editor, plugins);

  usePlateEditorPlugins(editor);

  return (
    <Slate editor={editor} value={value} onChange={setValue}>
      <SlateExtended>
        <DndStateProvider
          value={{
            activeId: null,
            activeElement: null,
            dragDepth: 0,
            dragOverlayHeight: null,
          }}
        >
          <Editable
            className="slate-editor"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            decorate={decorate}
            readOnly={true}
          />
        </DndStateProvider>
      </SlateExtended>
    </Slate>
  );
};

export default SlateDragOverlayEditor;
