import React, { useEffect, useMemo } from "react";
import { Descendant, Editor } from "slate";
import { ReactEditor, RenderElementProps, useSlateStatic } from "slate-react";
import { clone } from "ramda";
import cn from "classnames";
import { useResizeDetector } from "react-resize-detector";
import { ArrowRightIcon } from "thunk-icons";

import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import SlateDragOverlayEditor from "components/slate/editors/SlateDragOverlayEditor";
import { getComponentWrapperClass } from "components/slate/wrapper/utils";
import { indentationWidth } from "components/slate/wrapper/constants";
import { useStoreValue } from "state/utils";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";

import styles from "./index.module.scss";

type Props = {
  editor: Editor;
  activeId: string;
  onHeightChange: (height: number) => void;
};

const DragOverlayContent = (props: Props) => {
  const { editor, activeId, onHeightChange } = props;
  const { ref, height = 0 } = useResizeDetector();

  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);
  const terminalSelection = useStoreValue(
    TerminalStore.terminalSelection(editor.slateId)
  );
  const terminalPointer = useStoreValue(
    TerminalStore.terminalPointer(editor.slateId)
  );

  useEffect(() => {
    onHeightChange(height);
  }, [height]);

  const activeIndex = editor.children.findIndex(
    (x) => x.id === activeId
  ) as number;
  const element = editor.children[activeIndex];

  const domElement = ReactEditor.toDOMNode(editor, element);

  const baseDepth = ExtendedEditor.isNestingElement(editor, element)
    ? element.depth
    : 0;

  const initialValue = useMemo(() => {
    let content: Descendant[];

    const semanticNode = ExtendedEditor.semanticNode(editor, element);
    const { descendants } = semanticNode;

    if (isTerminalActive) {
      content = clone(
        editor.children.filter(
          (element, _index) =>
            terminalSelection.has(element.id) || terminalPointer === element.id
        )
      );
    } else {
      if (ExtendedEditor.isNestingElement(editor, element)) {
        content = clone(
          (element as any).folded
            ? [element]
            : [
                element,
                ...descendants.filter((x) => !x.hidden).map((x) => x.element),
              ]
        );

        // content.forEach((element) => {
        //   if (ExtendedEditor.isNestingElement(editor, element)) {
        //     element.depth -= baseDepth;
        //   }
        // });
      } else {
        content = clone([element]);
      }
    }

    return content;
  }, [
    editor.children,
    activeId,
    isTerminalActive,
    terminalSelection,
    terminalPointer,
  ]);

  console.log(initialValue);

  return (
    <div
      ref={ref}
      contentEditable={false}
      style={
        {
          "--baseDepth": `${baseDepth}px`,
          ...(domElement ? passStylesProps(domElement) : {}),
        } as React.CSSProperties
      }
      className={cn(styles.dragOverlay, {
        dragOverlayList: ExtendedEditor.isNestingElement(editor, element),
      })}
    >
      <div className={styles.handle}>
        <span>⠿</span>
      </div>
      {element && <SlateDragOverlayEditor initialValue={initialValue} />}
    </div>
  );
};

export const DragOverlayWrapper = (props: RenderElementProps) => {
  const { element, children } = props;
  const editor = useSlateStatic();

  const realSpacing = ExtendedEditor.isNestingElement(editor, element)
    ? indentationWidth * element.depth
    : 0;

  return (
    <div
      className={cn(
        styles.dragOverlayWrapper,
        getComponentWrapperClass(element),
        {
          [styles.folded]:
            ExtendedEditor.isFoldingElement(editor, element) && element.folded,
        }
      )}
      style={
        {
          "--spacing": `${realSpacing}px`,
        } as React.CSSProperties
      }
    >
      {ExtendedEditor.isFoldingElement(editor, element) &&
        !(
          ExtendedEditor.isNestingElement(editor, element) && !element.folded
        ) && (
          <div className={styles.foldingArrow}>
            <ArrowRightIcon size={11} />
          </div>
        )}
      {children}
    </div>
  );
};

export default DragOverlayContent;

const passStylesProps = (domNode: HTMLElement) => {
  return {
    "--card-pl": getComputedStyle(domNode).getPropertyValue("--card-pl"),
    "--card-pr": getComputedStyle(domNode).getPropertyValue("--card-pr"),
    "--component-line-height": getComputedStyle(domNode).getPropertyValue(
      "--component-line-height"
    ),
    "--component-margin-top": getComputedStyle(domNode).getPropertyValue(
      "--component-margin-top"
    ),
    "--component-margin-bottom": getComputedStyle(domNode).getPropertyValue(
      "--component-margin-bottom"
    ),
  } as React.CSSProperties;
};
