import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "stores/store";
import cn from "classnames";

import { SyncStatus } from "stores/syncMonitorStore";
import useNotifications from "hooks/useNotifications";

import styles from "./index.module.scss";

const SyncIndicator = observer(() => {
  const { syncMonitorStore } = useStore();
  const { addNotification, removeNotification } = useNotifications();
  const state = useMemo<{ notificationId: string | null }>(
    () => ({ notificationId: null }),
    []
  );

  const { status } = syncMonitorStore;

  useEffect(() => {
    if (state.notificationId == null && status === SyncStatus.Failed) {
      state.notificationId = addNotification({
        type: "loading",
        duration: 0,
        text: "Thunk synchronizing your data",
        onRemoval: () => {
          state.notificationId = null;
        },
      });
    }

    if (status === SyncStatus.Succeed) {
      removeNotification(state.notificationId);
      state.notificationId = null;
    }
  }, [status]);

  useEffect(() => {
    return () => {
      removeNotification(state.notificationId);
      state.notificationId = null;
    };
  }, []);

  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      const { status } = syncMonitorStore;

      if (status === SyncStatus.Failed || status === SyncStatus.Pending) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, []);

  return (
    <div
      className={cn(styles.container, {
        [styles.pending]: status === SyncStatus.Pending,
        [styles.failed]: status === SyncStatus.Failed,
        [styles.succeed]: status === SyncStatus.Succeed,
      })}
    />
  );
});

export default SyncIndicator;
