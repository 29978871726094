import React, { memo } from "react";
import { Link } from "react-router-dom";
import { OpenCardIcon, MoreIcon, PinNoteIcon } from "thunk-icons";

import { CardToolbar } from "framework/components/Card";
import CardToolbarButton from "framework/components/CardToolbarButton";
import MenuPopper, {
  useMenuPopper,
} from "components/editor/toolbar/CardMainToolbar/MenuPopper";
import ToolbarMenu, {
  ToolbarMenuItem,
} from "components/editor/toolbar/CardMainToolbar/ToolbarMenu";
import SimpleTooltip from "components/SimpleTooltip";
import Tag from "framework/components/Tag";

import styles from "./index.module.scss";

interface Props {
  href: string;
  isPinned?: boolean;
  onDocumentPin?: () => void;
}

const CardSidePanelToolbar = memo<Props>(
  ({ href, isPinned, onDocumentPin }: Props) => {
    const [menuTargetProps, menuPopperProps] = useMenuPopper({
      options: {
        placement: "bottom-end",
      },
    });

    const openIconProps = {
      id: "openNote",
      title: "Open note",
      icon: OpenCardIcon,
    };

    return (
      <CardToolbar>
        <div
          style={{
            visibility: isPinned ? "visible" : "hidden",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div className={styles.pinnedLabel}>
            Pinned
          </div>
        </div>
        {onDocumentPin && (
          <SimpleTooltip
            content={isPinned ? "Unpin Note" : "Pin Note"}
            placement="top"
            delay={[400, 0]}
          >
            <CardToolbarButton
              color={isPinned ? "var(--blue-color)" : "var(--text-color)"}
              icon={PinNoteIcon}
              onClick={onDocumentPin}
            />
          </SimpleTooltip>
        )}
        <CardToolbarButton icon={MoreIcon} {...menuTargetProps} />
        <MenuPopper {...menuPopperProps}>
          <ToolbarMenu>
            <Link to={href}>
              <ToolbarMenuItem className={styles.openSelectItem} {...openIconProps} />
            </Link>
          </ToolbarMenu>
        </MenuPopper>
      </CardToolbar>
    );
  }
);

export default CardSidePanelToolbar;
