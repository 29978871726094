import React, { useCallback } from "react";
import isHotkey from "is-hotkey";
import { Editor, Element } from "slate";

import withReference from "./withReference";
import * as handlers from "./handlers";
import { UseSlatePlugin } from "components/slate/types";
import { isHeadingType } from "components/slate/plugins/heading/utils";
import { isListItemType } from "components/slate/plugins/list/utils";
import useMemoizedObject from "hooks/useMemoizedObject";

const useReferencePlugin: UseSlatePlugin<{
  slateId: string;
  contextId?: string;
  contextInterval?: [string, string];
  contextType: Element["type"];
}> = (props) => {
  const { slateId, contextId, contextInterval, contextType } = props;

  const options = useMemoizedObject({
    slateId,
    contextId,
    contextInterval,
    contextType,
  });

  const onKeyDown = useCallback(
    (editor: Editor) => (e: React.KeyboardEvent) => {
      const { contextType } = options;

      if (isHotkey("Enter", e)) {
        if (isHeadingType(contextType) || isListItemType(contextType)) {
          return;
        }

        e.preventDefault();
      }
    },
    []
  );

  return {
    withOverrides: withReference(options),
    handlers: {
      ...handlers,
      onKeyDown,
    },
  };
};

export default useReferencePlugin;
