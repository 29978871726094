import React, { Fragment, memo, useMemo, useState } from "react";
import { createEditor, Descendant, Editor, Element } from "slate";
import { Editable, Slate } from "slate-react";

import useRenderLeaf from "components/slate/hooks/useRenderLeaf";
import useEditor from "components/slate/hooks/useEditor";
import SlateExtended from "components/slate/slate-extended/SlateExtended";
import usePlateEditorPlugins from "components/slate/hooks/usePlateEditorPlugins";
import useDecorate from "components/slate/hooks/useDecorate";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import AtMenu from "components/slate/plugins/menus/atMenu/components/AtMenu";
import HashMenu from "components/slate/plugins/menus/hashMenu/components/HashMenu";
import BacklinkMenu from "components/slate/plugins/menus/backlinkMenu/components/BacklinkMenu";
import { FormattingMenu } from "components/slate/plugins/menus/formatting/FormattingMenu";
import { EmojiComponent } from "components/slate/plugins/emoji/components/EmojiComponent";
import {
  ElementContextMenu,
  ElementContextMenuProvider,
} from "components/slate/plugins/menus/formatting/ElementContextMenu";
import { useReferenceRenderElement } from "components/slate/hooks/useRenderElement";
import useHandlers from "components/slate/hooks/useHandlers";
import { StaticPropsProvider } from "components/slate/hooks/useStaticProps";
import { EditorType } from "components/slate/types";
import { getReferencesDiffs } from "components/slate/plugins/backlink/db";
import useReferencePlugins from "components/slate/hooks/useReferencePlugins";
import { ImagesViewerProvider } from "components/slate/plugins/file/components/ImagesViewer";
import { ImageUploaderProvider } from "components/slate/plugins/file/components/ImageUploader";
import { DocumentType } from "thunk-core";
import { ListItemType } from "components/slate/plugins/list/types";
import { HideCompletedProvider } from "components/slate/hooks/useHideCompleted";
import { SyncSlateValue } from "components/slate/editors/SyncSlateValue";
import { useEditorsMapEffects } from "components/slate/state/EditorsStore";
import { useStoreValue } from "state/utils";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";

type Props = {
  slateId: string;
  contextInterval: [string, string];
  contextIntervalIndexes: [number, number];
  userId: string;
  documentId: string;
  documentType: DocumentType;
  value?: Descendant[];
  readOnly?: boolean;
  onChange?: (value: Descendant[]) => void;
  onSync?: () => void;
  onBacklinksChange?: (referencesDiff: any) => void;
  onBacklinkClick?: (element: BacklinkElement) => void;
  hideCompleted: boolean;
};

const SlateTodosEditor = memo((props: Props) => {
  const {
    slateId,
    contextInterval,
    contextIntervalIndexes,
    userId,
    documentId,
    documentType,
    onChange,
    onSync,
    onBacklinkClick,
    onBacklinksChange,
    readOnly,
    hideCompleted,
  } = props;

  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);

  const contextType = ListItemType;
  const contextDepth = 0;

  const content = props.value;

  const [value, setValue] = useState(content);

  const state = useMemo(() => ({ contextIntervalIndexes }), []);
  useMemo(() => {
    Object.assign(state, { contextIntervalIndexes });
  }, [contextIntervalIndexes]);

  const {
    plugins,
    atMenuPlugin,
    hashMenuPlugin,
    backlinkMenuPlugin,
    formattingMenuPlugin,
    emojiPlugin,
  } = useReferencePlugins({
    slateId: slateId,
    isMainEditor: false,
    userId,
    contextInterval,
    contextType,
    isContextElement: (editor: Editor) => (element: Element) => {
      const { contextIntervalIndexes } = state;
      const index = editor.children.indexOf(element);
      return (
        contextIntervalIndexes[0] <= index && index <= contextIntervalIndexes[1]
      );
    },
  });

  const editor = useEditor(createEditor, plugins);
  const handlers = useHandlers(editor, plugins);

  const renderElement = useReferenceRenderElement(editor, plugins, {
    contextDepth,
  });
  const renderLeaf = useRenderLeaf(editor, plugins);
  const decorate = useDecorate(editor, plugins);

  usePlateEditorPlugins(editor);

  useEditorsMapEffects(slateId, editor);

  const handleChange = (content: Descendant[]) => {
    if (readOnly) {
      return;
    }

    if (onBacklinksChange) {
      const referencesDiff = getReferencesDiffs(value, content);
      onBacklinksChange(referencesDiff);
    }

    if (value !== content && onChange) {
      onChange(content);
    }
    setValue(content);

    plugins.filter((x) => x.onChange).map((x) => x.onChange(editor)(content));
  };

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <ElementContextMenuProvider editor={editor}>
        <StaticPropsProvider
          value={{
            readOnly,
            slateId: "",
            editorType: EditorType.ReferenceEditor,
            documentId,
            documentType,
            isSharing: false,
            isSnippet: true,
            isMainEditor: false,
            isHelpDocs: false,
            noSortable: true,
            pageTitle: "",
            onBacklinkClick,
          }}
        >
          <HideCompletedProvider value={hideCompleted}>
            <ImageUploaderProvider editor={editor}>
              <ImagesViewerProvider content={value as any}>
                <SyncSlateValue
                  value={content}
                  setValue={(value) => {
                    setValue(value);
                    onSync && onSync();
                  }}
                  dragging={false}
                >
                  <SlateExtended>
                    <Editable
                      data-slate-id={slateId}
                      className="slate-editor"
                      readOnly={readOnly || isTerminalActive}
                      renderElement={renderElement}
                      renderLeaf={renderLeaf}
                      decorate={decorate}
                      {...handlers}
                    />
                    {!readOnly && (
                      <Fragment>
                        <AtMenu {...atMenuPlugin.state} />
                        <HashMenu {...hashMenuPlugin.state} />
                        <BacklinkMenu {...backlinkMenuPlugin.state} />
                        <FormattingMenu {...formattingMenuPlugin.state} />
                        <EmojiComponent {...emojiPlugin.state} />
                        <ElementContextMenu />
                      </Fragment>
                    )}
                  </SlateExtended>
                </SyncSlateValue>
              </ImagesViewerProvider>
            </ImageUploaderProvider>
          </HideCompletedProvider>
        </StaticPropsProvider>
      </ElementContextMenuProvider>
    </Slate>
  );
});

export default SlateTodosEditor;
