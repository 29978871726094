import React from "react";

import nigelTasks from "images/nigel_sidepanel_empty.svg";

import styles from "./index.module.scss";

const NoTasksEmptyState = () => {
  return (
    <div className={styles.root}>
      <img src={nigelTasks} alt="Nigel" className={styles.image} />
      <h2 className={styles.title}>Type "/todo"</h2>
      <p className={styles.content}>Type "/todo" to create new To Do items.</p>
    </div>
  );
};

export default NoTasksEmptyState;
