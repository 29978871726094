import { useEffect } from "react";

import { AppPopperProps } from "./useAppPopper";

const useClickPopper = (props: AppPopperProps) => {
  const { targetElement, contentElement, openPopper, closePopper } = props;

  useEffect(() => {
    if (targetElement) {
      targetElement.addEventListener("click", openPopper);
    }

    return () => {
      if (targetElement) {
        targetElement.removeEventListener("click", openPopper);
      }
    };
  }, [targetElement]);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!targetElement || !contentElement) {
        return;
      }

      if (
        targetElement.contains(event.target as Node) ||
        contentElement.contains(event.target as Node) ||
        Array.from(document.querySelectorAll("[data-not-close-popper]")).some(
          (x) => x.contains(event.target as Node)
        )
      ) {
        return;
      }

      closePopper();
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [targetElement, contentElement]);
};

export default useClickPopper;
