import { useForm } from "react-hook-form";

import TextInput from "framework/components/form/TextInput";
import Button from "framework/components/form/Button";
import { SignUpFormValues, useSignUpContext } from "../SignUpProvider";
import julianCoffee from "images/julian_coffee.svg";
import CopyPreview from "components/CopyPreview";

import styles from "../index.module.scss";

const NameForm = () => {
  const { formValues, handleSubmitName } = useSignUpContext();

  const { register, handleSubmit, errors } = useForm<
    Pick<SignUpFormValues, "firstName" | "lastName">
  >({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
    },
  });

  return (
    <>
      <form
        className={styles.signUpForm}
        onSubmit={handleSubmit((values) => {
          handleSubmitName(values);
          return false;
        })}
        noValidate
      >
        <figure className={styles.signUpFormImageContainer}>
          <img
            src={julianCoffee}
            alt="Julian"
            className={styles.signUpFormImage}
          />
        </figure>

        <h3 className={styles.signUpFormHeadline}>
          👋 Hey, my name's Julian. What's yours?
        </h3>

        <TextInput
          type="text"
          error={errors.firstName?.message}
          name="firstName"
          placeholder="First Name"
          inputRef={register({
            required: "First name required",
          })}
        />

        <TextInput
          type="text"
          error={errors.lastName?.message}
          name="lastName"
          placeholder="Last Name"
          inputRef={register({
            required: "Last name required",
          })}
        />

        <div style={{ textAlign: "right" }}>
          <Button variant="secondary" type="submit">
            Continue
          </Button>
        </div>
      </form>
      <CopyPreview />
    </>
  );
};

export default NameForm;
