import React, { useEffect } from "react";

import useKeyboardNavigation from "../../hooks/useKeyboardNavigation";
import { scrollParentToElement } from "utils/scrollParentToElement";

import styles from "./index.module.scss";

export type MenuListProps<T> = {
  containerRef?: React.MutableRefObject<HTMLDivElement>;
  isOpened: boolean;
  items: any[];
  renderListItem: ({
    item,
    isActive,
  }: {
    item: T;
    isActive: boolean;
  }) => React.ReactNode;
  onSelect: (e: KeyboardEvent, item: T) => void;
  onCancel: (e: KeyboardEvent) => void;
};

const MenuList = <T,>(props: MenuListProps<T>) => {
  const {
    containerRef,
    isOpened,
    items,
    renderListItem,
    onSelect,
    onCancel,
  } = props;

  const { index, setIndex } = useKeyboardNavigation({
    listening: isOpened,
    items,
    onSelect,
    onCancel,
  });

  useEffect(() => {
    if (isOpened) {
      setIndex(0);
    }
  }, [isOpened, items]);

  useEffect(() => {
    if (containerRef.current) {
      const element = containerRef.current.children[index];
      element && scrollParentToElement(element);
    }
  }, [index]);

  return (
    <div ref={containerRef} className={styles.container}>
      {items.length > 0 ? (
        items.map((item, itemIndex) =>
          renderListItem({ item, isActive: index === itemIndex })
        )
      ) : (
        <div className={styles.placeholder}>No results.</div>
      )}
    </div>
  );
};

export default MenuList;
