import React from "react";

import Layout from "components/layout/Layout";
import AppSidebar from "./AppSidebar";
import AppHelp from "layout/AppLayout/AppHelp";
import AppKeydownHandler from "layout/AppLayout/AppKeydownHandler";
import { SidebarProvider } from "providers/SidebarProvider";
import { SearchProvider } from "providers/SearchProvider";
import { SidePanelProvider } from "providers/SidePanelProvider";
import ExpiredNotification from "components/subscriptions/ExpiredNotification";
import ExpiredModal from "components/subscriptions/ExpiredModal";
import SyncIndicator from "components/SyncIndicator";
import { DueDateSelectCalendarMenuProvider } from "providers/DueDateSelectCalendarMenuProvider";
import SidePanel from "components/editor/SidePanel";
import { IntercomProvider } from "react-use-intercom";
import { IntercomConfig } from "components/Intercom";
import useForceOnboarding from "hooks/useForceOnboarding";
import ElectronLayout from "components/electron/ElectronLayout";
import HistoryManager from "components/history/HistoryManager";

const AppLayout = ({ children, noDefaultStyles, fullWidth, ...rest }: any) => {
  useForceOnboarding();

  return (
    <SidebarProvider>
      <SidePanelProvider>
        <SearchProvider>
          <ElectronLayout>
            <IntercomProvider appId="ikhj1qey">
              <IntercomConfig />
              <DueDateSelectCalendarMenuProvider>
                <Layout
                  noDefaultStyles={noDefaultStyles}
                  fullWidth={fullWidth}
                  notification={<ExpiredNotification />}
                  sidebar={<AppSidebar />}
                  sidePanel={<SidePanel />}
                  {...rest}
                >
                  {children}
                </Layout>
                <AppKeydownHandler />
              </DueDateSelectCalendarMenuProvider>
            </IntercomProvider>

            <AppHelp />
            <ExpiredModal />
            <SyncIndicator />
            <HistoryManager />
            {/*<ReactNativeGateway />*/}
          </ElectronLayout>
        </SearchProvider>
      </SidePanelProvider>
    </SidebarProvider>
  );
};

export default AppLayout;
