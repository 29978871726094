import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import { signInWithEmailAndPassword } from "firebase/auth";

import AuthForm from "components/AuthForm";
import AuthPageContainer from "components/AuthPageContainer";
import { auth } from "firebaseInstance";
import { ErrorInfo, getErrorInfo } from "./errors";
import { useAuth } from "auth/AuthProvider";
import { env } from "thunk-env";
import CopyPreview from "components/CopyPreview";

const SignInPage = () => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const [submitError, setSubmitError] = useState<ErrorInfo>(null);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (data: any) => {
    setSubmitting(true);
    try {
      const { email, password } = data;
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.error(error);
      const errorInfo = getErrorInfo(error);

      setSubmitError(errorInfo);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.replace("/");
    }
  }, [isAuthenticated]);

  const error = submitError && {
    code: submitError.code,
    message: (
      <Trans
        i18nKey={submitError.message}
        values={{ email: env.REACT_APP_SUPPORT_EMAIL }}
        components={{
          email: <a href={`mailto:${env.REACT_APP_SUPPORT_EMAIL}`} />,
        }}
      />
    ),
  };

  return (
    <AuthPageContainer>
      <AuthForm
        onSubmit={onSubmit}
        submitError={error}
        submitting={submitting}
        primaryText="Sign In"
        actionText="Sign In"
        mode="signin"
      />
      <CopyPreview />
    </AuthPageContainer>
  );
};

export default SignInPage;
