import { useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { atcb_action } from "add-to-calendar-button";
import { MenuCalendarIcon } from "thunk-icons";

import Button from "framework/components/form/Button";
import { useOnboardingContext } from "../OnboardingProvider";

import styles from "../index.module.scss";
import reminderStyles from "./CalReminder.module.scss";
import "add-to-calendar-button/assets/css/atcb.css";

const generateTimeOptions = () => {
  const options = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const hour = i < 12 ? i : i - 12;
      const amPm = i < 12 ? "AM" : "PM";
      const minute = j < 10 ? `0${j}` : j;
      options.push({
        value: `${i < 10 ? `0${i}` : i}:${minute}`,
        label: `${hour === 0 ? 12 : hour}:${minute} ${amPm}`,
      });
    }
  }
  return options;
};

const getDefaultTimeOptions = (actionSelection: string) => {
  switch (actionSelection) {
    case "afternoon":
      return [
        { label: "1:00 PM", value: "13:00" },
        { label: "2:00 PM", value: "14:00" },
        { label: "3:00 PM", value: "15:00" },
      ];
    case "evening":
      return [
        { label: "5:00 PM", value: "17:00" },
        { label: "6:00 PM", value: "18:00" },
        { label: "7:00 PM", value: "18:00" },
      ];
    default:
      return [
        { label: "7:00 AM", value: "07:00" },
        { label: "8:00 AM", value: "08:00" },
        { label: "9:00 AM", value: "09:00" },
      ];
  }
};

const recurringOptions = {
  weekdays: "RRULE:FREQ=WEEKLY;INTERVAL=1;WKST=MO;BYDAY=MO,TU,WE,TH,FR",
  daily: "RRULE:FREQ=DAILY;INTERVAL=1",
};

const CalReminderTime = () => {
  const {
    reminderFormValues,
    setReminderFormValues,
    submitting,
    handleReminderSubmit,
  } = useOnboardingContext();
  const [hasTakenAction, setHasTakenAction] = useState(false);
  const [customTime, setCustomTime] = useState(false);
  const timeOptions = useMemo(() => generateTimeOptions(), []);

  const handleClick = () => {
    const optionIndex = timeOptions.findIndex(
      (option) => option.value === reminderFormValues.time
    );
    atcb_action({
      name: "Thunk Daily Routine",
      description:
        "Complete your daily routine: <br>→ [url]https://app.thunknotes.com/writing[/url]",
      startDate: "today",
      endDate: "today",
      startTime: reminderFormValues.time,
      endTime:
        timeOptions[
          optionIndex === timeOptions.length - 1 ? 0 : optionIndex + 1
        ].value,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      location: "https://app.thunknotes.com/writing",
      options: ["Apple", "Google", "iCal", "Yahoo"],
      recurrence: recurringOptions[reminderFormValues.days],
      iCalFileName: "Thunk-Daily-Event",
    });
    setHasTakenAction(true);
  };

  const setTime = useCallback(
    (time: string) => {
      setReminderFormValues({
        ...reminderFormValues,
        time,
      });
    },
    [reminderFormValues, setReminderFormValues]
  );

  return (
    <>
      <h3 className={styles.onboardingHeadline}>Stay consistent</h3>
      <p className={styles.onboardingCopy}>
        Research shows people who choose a specific time are more likely to hit
        their goals.
      </p>

      <div className={reminderStyles.calOption}>
        <h3 className={styles.onboardingHeadlineSm}>
          When would you like a reminder?
        </h3>
        {!customTime && (
          <div className={reminderStyles.buttonList}>
            {getDefaultTimeOptions(reminderFormValues.action).map((option) => (
              <div
                className={reminderStyles.buttonListOption}
                key={option.value}
              >
                <Button
                  isSelected={option.value === reminderFormValues.time}
                  variant="secondary"
                  onClick={() => setTime(option.value)}
                >
                  {option.label}
                </Button>
              </div>
            ))}
            <Button
              isSelected={customTime}
              variant="secondary"
              key="customTime"
              onClick={() => setCustomTime(true)}
            >
              Custom
            </Button>
          </div>
        )}
        {customTime && (
          <div className={reminderStyles.select}>
            <select
              value={reminderFormValues.time}
              onChange={(e) => setTime(e.target.value)}
              className={reminderStyles.selectStyles}
            >
              {timeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span className={reminderStyles.focus}></span>
          </div>
        )}
      </div>
      <div className={reminderStyles.calOption}>
        <h3 className={styles.onboardingHeadlineSm}>
          Which days would you like to be reminded?
        </h3>
        <div className={cn(reminderStyles.buttonList, reminderStyles.vertical)}>
          {[
            { label: "Weekdays only", value: "weekdays" },
            { label: "Every day", value: "daily" },
          ].map((option) => (
            <div className={reminderStyles.buttonListOption} key={option.value}>
              <Button
                isSelected={option.value === reminderFormValues.days}
                variant="secondary"
                onClick={() =>
                  setReminderFormValues({
                    ...reminderFormValues,
                    days: option.value,
                  })
                }
              >
                {option.label}
              </Button>
            </div>
          ))}
        </div>
      </div>

      {reminderFormValues.days && reminderFormValues.time && (
        <div className={reminderStyles.addToCal}>
          <div>
            <Button
              icon={hasTakenAction ? null : MenuCalendarIcon}
              variant={hasTakenAction ? "ghost" : "primary"}
              onClick={handleClick}
            >
              Add to calendar
            </Button>
          </div>
          {hasTakenAction && (
            <div>
              <Button
                variant="primary"
                onClick={handleReminderSubmit}
                loading={submitting}
              >
                Let's wrap this up
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CalReminderTime;
