import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useDocumentsStore } from "stores/store";
import { EditorType } from "components/slate/types";
import { useSaveNotification } from "hooks/useSaveNotification";
import useScrollToTop from "hooks/useScrollToTop";
import CardPageLayout from "components/layout/CardPageLayout";
import CardNavigationActions from "components/editor/CardNavigationActions";
import CardMainToolbar from "components/editor/toolbar/CardMainToolbar";
import EditorCard from "components/editor/EditorCard";
import AppResult from "components/results/AppResult";
import Helmet from "components/Helmet";
import SidePanelToggle from "components/layout/SidePanelToggle";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import { useCurrentUserId } from "db/currentUser";
import { getEditorPathname } from "helpers";
import DocumentEditor from "components/editor/DocumentEditor";
import { useEditingSession } from "stores/hooks/useEditingSession";
import { useDocumentUserContext } from "providers/DocumentUserProvider";
import StreamProvider from "components/slate/plugins/stream/components/StreamProvider";
import { Document, DocumentType } from "thunk-core";
import { EditorsStore } from "components/slate/state/EditorsStore";
import { useStoreValue } from "state/utils";

import styles from "./index.module.scss";

// THIS IS A PAGE THAT IS NOT THE DAILY NOTE (We should really rename this so it's more clear what it is.)
const PageEditingPage = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const [ready, setReady] = useState(false);
  const history = useHistory();

  const { isDocumentOwner } = useDocumentUserContext();
  const userId = useCurrentUserId();
  const documentType = DocumentType.PAGE;
  const documentsStore = useDocumentsStore(documentType);

  useSaveNotification();
  useScrollToTop([pageId]);

  //stop body scrolling on this page
  useEffect(() => {
    document.body.removeAttribute("data-not-public");
    document.body.removeAttribute("daily-note");
    document.body.removeAttribute("no-side-panel-page");
  });

  useEffect(() => {
    if (pageId === "new" && isDocumentOwner) {
      setReady(false);
      const id = documentsStore.createNewDocument({
        type: documentType,
        userId: userId,
      });
      history.push(getEditorPathname(documentType, id, null));
    } else {
      setReady(true);
    }
  }, [pageId, isDocumentOwner, userId]);

  if (!ready) {
    return <EditorCard title={""} loading={true} loadingTimeout={300} />;
  }

  return <PageMainEditor documentId={pageId} />;
};

export default PageEditingPage;

const PageMainEditor = observer(({ documentId }: { documentId: string }) => {
  const history = useHistory();
  const editorType = EditorType.MainEditor;
  const documentType = DocumentType.PAGE;

  const { documentUserId } = useDocumentUserContext();

  const containerRef = React.useRef<HTMLDivElement>(null);

  const {
    loading,
    error,
    title,
    content,
    document,
    wordCount,
    slateId,
  } = useEditingSession({
    userId: documentUserId,
    editorType,
    documentId,
    dateId: null,
    documentType,
  });

  const editor = useStoreValue(EditorsStore.editor(slateId));

  const afterRemove = useCallback(() => {
    history.replace("/writing");
  }, []);

  return (
    <PageEditingPageLayout title={title} document={document}>
      <StreamProvider editor={editor}>
        {(function () {
          if (loading) {
            return (
              <EditorCard
                className={styles.dailyNoteEditor}
                title={title}
                loading={true}
                loadingTimeout={300}
              />
            );
          }

          if (error) {
            return (
              <EditorCard className={styles.dailyNoteEditor} title={title}>
                <AppResult resultType="fetching" isCenteredContent={true} />
              </EditorCard>
            );
          }

          if (!document) {
            return (
              <EditorCard>
                <AppResult resultType="notFound" isCenteredContent={true} />
              </EditorCard>
            );
          }

          return (
            <div className={styles.cardContentWrapper} ref={containerRef}>
              <CardNavigationActions
                containerRef={containerRef}
                pageTitle={title}
                rightActions={<SidePanelToggle />}
                document={document}
                toolbar={
                  <CardMainToolbar
                    document={document}
                    onAfterRemove={afterRemove}
                    wordCount={wordCount}
                  />
                }
              />
              <DocumentEditor
                slateId={slateId}
                editorType={editorType}
                documentType={documentType}
                documentId={documentId}
                className={styles.editor}
                document={document}
                content={content}
              />
            </div>
          );
        })()}
      </StreamProvider>
    </PageEditingPageLayout>
  );
});

const PageEditingPageLayout = ({
  children,
  title,
}: React.PropsWithChildren<{ title: string | null; document: Document }>) => {
  return (
    <>
      <Helmet pageTitle={title} />
      <CardPageLayout>
        <WidgetErrorBoundary>{children}</WidgetErrorBoundary>
      </CardPageLayout>
    </>
  );
};
