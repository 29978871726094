import React from "react";
import { isMobile } from "react-device-detect";

import { TableFilterToolbar } from "framework/components/table/TableFilterMobile";
import TextInput from "framework/components/form/TextInput";

type Props = {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

const TagsFilterMobile = (props: Props) => {
  const { search, setSearch } = props;

  return (
    <TableFilterToolbar>
      <TextInput
        name="search"
        autoFocus={!isMobile}
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </TableFilterToolbar>
  );
};

export default TagsFilterMobile;
