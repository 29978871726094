import queryString from "query-string";
import { matchPath } from "react-router";
import { getDateFromUrl } from "helpers";
import { formatDifferenceInDays } from "utils/dateUtils";
import { store } from "stores/store";

export type RecentItemData = {
  href: string;
  text: string;
};

export const useRecentItems = (hrefs: string[]): RecentItemData[] => {
  if (!hrefs) {
    return [];
  }

  return hrefs
    .map((href) => {
      const text = getText(href);

      if (!text) {
        return null;
      }

      return {
        href,
        text,
      };
    })
    .filter(Boolean);
};

export const getText = (href: string) => {
  const [pathname, search] = href.split("?");

  switch (pathname) {
    case "/account": {
      return "Settings & Account";
    }
    case "/snippets": {
      return "Templates";
    }
    case "/tags": {
      return "Tags";
    }
    case "/overview": {
      return "All Notes";
    }
    case "/graph": {
      return "Explore";
    }
    case "/invitations": {
      return "Invitations";
    }
    case "/feedback": {
      return "Feedback";
    }
  }

  const writingMatch = matchPath<any>(pathname, "/writing");
  if (writingMatch) {
    const { date: dateId } = queryString.parse(search);
    const date = getDateFromUrl(dateId as string);

    return formatDifferenceInDays(date);
  }

  const templateMatch = matchPath<{ snippetId: string }>(pathname, [
    "/snippets/:snippetId",
    "/users/:userId/snippets/:snippetId",
  ]);
  if (templateMatch) {
    const { snippetId } = templateMatch.params;
    const { templatesStore } = store;
    const template = templatesStore.getDocument(snippetId);

    return template?.title;
  }

  const pageMatch = matchPath<{ pageId: string }>(pathname, [
    "/page/:pageId",
    "/users/:userId/page/:pageId",
  ]);
  if (pageMatch) {
    const { pageId } = pageMatch.params;
    const { pagesStore } = store;
    const page = pagesStore.getDocument(pageId);

    return page?.title;
  }

  return null;
};
