import { doc } from "firebase/firestore";

import { db } from "firebaseInstance";
import { getDocumentData } from "db/utils";
import { MaintenanceVariable } from "db/variables/variables.types";
import { getVariablesPath } from "thunk-core";

export const getMaintenanceVariable = async (): Promise<
  MaintenanceVariable
> => {
  return (await getDocumentData(
    doc(db, getVariablesPath("maintenance"))
  )) as MaintenanceVariable;
};
