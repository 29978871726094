import React from "react";

import { ElementProps } from "components/slate/types";
import {
  Heading1Element,
  Heading2Element,
  Heading3Element,
} from "components/slate/plugins/heading/types";

export const Heading1Component = (
  props: ElementProps & { element: Heading1Element }
) => {
  const { children, attributes } = props;

  return (
    <h1 className="slate-h1 slate-h1-line-height" {...attributes}>
      {children}
    </h1>
  );
};

export const Heading2Component = (
  props: ElementProps & { element: Heading2Element }
) => {
  const { children, attributes } = props;

  return (
    <h2 className="slate-h2 slate-h2-line-height" {...attributes}>
      {children}
    </h2>
  );
};

export const Heading3Component = (
  props: ElementProps & { element: Heading3Element }
) => {
  const { children, attributes } = props;

  return (
    <h3 className="slate-h3 slate-h3-line-height" {...attributes}>
      {children}
    </h3>
  );
};
