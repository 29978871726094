import { Transforms } from "slate";

import { MediaEmbedType } from "components/slate/plugins/mediaEmbed/types";
import { makeNodeId } from "components/slate/config/makeNodeId";

export const insertMediaEmbed = (editor: any, { url = "", inputText = "" }) => {
  Transforms.insertNodes(editor, {
    id: makeNodeId(true),
    type: MediaEmbedType,
    url,
    inputText,
    children: [{ text: "" }],
  });
};
