import { v4 as uuidv4 } from "uuid";

import { DailyTemplateFormValues } from "./OnboardingProvider";
import {
  BulletedListItemElement,
  ListItemType,
  ListTypes,
  NumberedListItemElement,
} from "components/slate/plugins/list/types";
import { Heading2Element } from "components/slate/plugins/heading/types";
import { Descendant } from "slate";
import {
  ParagraphElement,
  ParagraphType,
} from "components/slate/plugins/paragraph/types";
import { store } from "stores/store";
import { DocumentType } from "thunk-core";
import { makePageBacklink } from "db/backlinks/backlinks.mapping";
import { mapElementToBlock } from "db/blocks/blocks.mapping";

const createHeading2 = (
  children: Descendant[] = [{ text: "" }]
): Heading2Element => {
  return {
    id: uuidv4(),
    type: "h2",
    children,
  };
};

const createBulletedListItem = (
  children: Descendant[] = [{ text: "" }]
): BulletedListItemElement => {
  return {
    id: uuidv4(),
    type: ListItemType,
    children,
    depth: 0,
    listType: ListTypes.Bulleted,
  };
};

const createNumberedListItem = (
  children: Descendant[] = [{ text: "" }]
): NumberedListItemElement => {
  return {
    id: uuidv4(),
    type: ListItemType,
    children,
    depth: 0,
    listType: ListTypes.Numbered,
  };
};

const createParagraph = (
  children: Descendant[] = [{ text: "" }]
): ParagraphElement => {
  return {
    id: uuidv4(),
    type: ParagraphType,
    children,
  };
};

const createMorningRoutineSection = (userId: string) => {
  const { pagesStore } = store;
  const morningRoutinePage = {
    id: uuidv4(),
    type: DocumentType.PAGE,
    title: "Morning Routine",
    userId,
  };
  pagesStore.createNewDocument(morningRoutinePage);
  const blockElements = [
    createHeading2([{ text: "Morning Routine" }]),
    createBulletedListItem([
      { text: "Complete my " },
      makePageBacklink(userId, {
        documentId: morningRoutinePage.id,
        initialText: morningRoutinePage.title,
      }),
      { text: "" },
    ]),
    createParagraph(),
  ];

  return blockElements;
};

const createGratitudeSection = (userId: string) => {
  const { pagesStore } = store;
  const gratitudePage = {
    id: uuidv4(),
    type: DocumentType.PAGE,
    title: "Gratitudes",
    userId,
  };

  pagesStore.createNewDocument(gratitudePage);
  const blockElements = [
    createHeading2([
      { text: "" },
      makePageBacklink(userId, {
        documentId: gratitudePage.id,
        initialText: gratitudePage.title,
      }),
      { text: "" },
    ]),
    createNumberedListItem(),
    createNumberedListItem(),
    createNumberedListItem(),
    createParagraph(),
  ];

  return blockElements;
};

const createDailySummarySection = (userId: string) => {
  const { pagesStore } = store;
  const dailySummaryPage = {
    id: uuidv4(),
    type: DocumentType.PAGE,
    title: "Daily Summary",
    userId,
  };
  pagesStore.createNewDocument(dailySummaryPage);
  const blockElements = [
    createHeading2([{ text: "Daily Summary" }]),
    createBulletedListItem([
      { text: "Complete my " },
      makePageBacklink(userId, {
        documentId: dailySummaryPage.id,
        initialText: dailySummaryPage.title,
      }),
      { text: "" },
    ]),
    createParagraph(),
  ];

  return blockElements;
};

const createDailyLogSection = (userId: string) => {
  const blockElements = [
    createHeading2([{ text: "Timestamp Log" }]),
    createBulletedListItem(),
    createParagraph(),
  ];
  return blockElements;
};

const createBlocksForDailyTemplate = (
  userId: string,
  options: DailyTemplateFormValues
) => {
  const blocks = [];
  const ids = [];

  if (options.morningRoutine) {
    const morningRoutineBlocks = createMorningRoutineSection(userId);
    blocks.push(...morningRoutineBlocks);
    ids.push(...morningRoutineBlocks.map((b) => b.id));
  }
  if (options.gratitudes) {
    const gratitudeBlocks = createGratitudeSection(userId);
    blocks.push(...gratitudeBlocks);
    ids.push(...gratitudeBlocks.map((b) => b.id));
  }
  if (options.dailyLog) {
    const dailyLogBlocks = createDailyLogSection(userId);
    blocks.push(...dailyLogBlocks);
    ids.push(...dailyLogBlocks.map((b) => b.id));
  }
  if (options.dailySummary) {
    const dailySummaryBlocks = createDailySummarySection(userId);
    blocks.push(...dailySummaryBlocks);
    ids.push(...dailySummaryBlocks.map((b) => b.id));
  }

  return { blocks, ids };
};

export const createDailyTemplate = (
  userId: string,
  options: DailyTemplateFormValues
) => {
  const { templatesStore } = store;

  const { blocks, ids } = createBlocksForDailyTemplate(userId, options);
  const dailyTemplateTemplate = {
    id: uuidv4(),
    type: DocumentType.SNIPPET,
    title: "Daily Template",
    userId,
    blocks: ids,
  };

  const blocksForTemplate = blocks.map((blockElement) =>
    mapElementToBlock(
      {
        pageId: dailyTemplateTemplate.id,
        pageType: dailyTemplateTemplate.type,
        userId,
        dateId: null,
      },
      blockElement
    )
  );
  templatesStore.createNewSnippet(dailyTemplateTemplate, blocksForTemplate);

  return dailyTemplateTemplate.id;
};
