import React from "react";
import cn from "classnames";
import { AvatarIcon, AvatarActiveIcon } from "thunk-icons";

import styles from "./index.module.scss";

type Props = {
  type: "default" | "profile" | "header";
  avatarUrl: string;
};

const Avatar: React.FC<any> = ({ type = "default", avatarUrl }: Props) => {
  const className = cn(styles.avatar, styles[type]);

  return (
    <>
      {avatarUrl ? (
        <img className={className} src={avatarUrl} alt="avatar" />
      ) : (
        <div>
          <AvatarActiveIcon
            className={cn(className, styles.avatarPlaceholder, styles.active)}
          />
          <AvatarIcon className={cn(className, styles.avatarPlaceholder)} />
        </div>
      )}
    </>
  );
};

export default Avatar;
