import {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";

import useWindowEventListener from "hooks/useWindowEventListener";

export const inputModes = {
  mouse: "mouse",
  keyboard: "keyboard",
};

//  @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
const InputModeContext = createContext();
export const useInputMode = () => {
  return useContext(InputModeContext);
};

const InputModeProvider = ({ children }: any) => {
  const [inputMode, setInputMode] = useState(null);

  const listener = useCallback((e) => {
    if (e instanceof MouseEvent) {
      //  @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setInputMode(inputModes.mouse);
    } else if (e instanceof KeyboardEvent) {
      //  @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setInputMode(inputModes.keyboard);
    }
  }, []);

  useEffect(() => {
    if (inputMode) {
      //  @ts-ignore ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      document.body.setAttribute("input-mode", inputMode);
    }
  }, [inputMode]);

  //  @ts-ignore ts-migrate(2322) FIXME: Type '(e: any) => void' is not assignable to type ... Remove this comment to see the full error message
  useWindowEventListener("keydown", listener, [listener]);
  //  @ts-ignore ts-migrate(2322) FIXME: Type '(e: any) => void' is not assignable to type ... Remove this comment to see the full error message
  useWindowEventListener("mousemove", listener, [listener]);

  return (
    <InputModeContext.Provider value={{ inputMode }}>
      {children}
    </InputModeContext.Provider>
  );
};

export default InputModeProvider;
