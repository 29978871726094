import create from "zustand";

export type StreamState = {
  isStreamActive: boolean;
  setIsStreamActive: (isStreamActive: boolean) => void;
};

const initialState = {
  isStreamActive: false,
};

export const createState = () =>
  create<StreamState>((set) => ({
    ...initialState,
    setIsStreamActive: (isStreamActive) => {
      set(() => {
        return { isStreamActive };
      });
    },
  }));
