import React, { useCallback, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import useEventListener from "hooks/useEventListener";
import { PortalBody } from "framework/components/Portal";
import { useCurrentUserData } from "db/user/user.hooks";
import {
  RecentItemData,
  useRecentItems,
} from "layout/AppLayout/AppRecentPages/utils";

import styles from "./index.module.scss";

const AppRecentPagesSwitch = observer(() => {
  const history = useHistory();
  const { isLoading, isError, error, data: user } = useCurrentUserData();
  const items = useRecentItems(user?.recents);

  const [isVisible, setIsVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const reset = useCallback(() => {
    setActiveIndex(0);
    setIsVisible(false);
  }, [setIsVisible, setActiveIndex]);

  const handleKeyDown = useCallback(
    (e) => {
      if (items.length === 0) {
        return;
      }

      if (e.key === "Tab" && e.altKey) {
        e.preventDefault();
        setIsVisible(true);

        if (e.shiftKey) {
          setActiveIndex((state) =>
            state === 0 ? items.length - 1 : state - 1
          );
        } else {
          setActiveIndex((state) => (state + 1) % items.length);
        }
      }
    },
    [items]
  );

  const handleKeyUp = useCallback(
    (e) => {
      if (!isVisible) {
        reset();
        return;
      }

      if (e.key === "Alt") {
        const item = items[activeIndex];
        if (item) {
          history.push(item.href);
        }
        reset();
      }
    },
    [isVisible, items, activeIndex, history, reset]
  );

  const handleClick = useCallback(
    (e) => {
      if (!e.altKey) {
        reset();
      }
    },
    [reset]
  );

  useEventListener(window, "keydown", handleKeyDown, [handleKeyDown]);
  useEventListener(window, "keyup", handleKeyUp, [handleKeyUp]);
  useEventListener(window, "click", handleClick, [handleClick]);

  if (isLoading) {
    return null;
  }

  if (isError) {
    console.error(error);
    return null;
  }

  return (
    <PortalBody>
      <div
        className={cn(styles.container, {
          [styles.visible]: isVisible,
        })}
      >
        <div className={styles.content}>
          <div className={styles.recentTitle}>Recent Notes</div>
          {items.map((item, index) => {
            return (
              <RecentItem
                key={item.href}
                item={item}
                isActive={activeIndex === index}
                onClick={(e: any) => {
                  e.preventDefault();
                  setActiveIndex(index);
                }}
              />
            );
          })}
        </div>
      </div>
    </PortalBody>
  );
});

export default AppRecentPagesSwitch;

const RecentItem = ({
  item,
  isActive,
  onClick,
}: {
  item: RecentItemData;
  isActive: boolean;
  onClick: React.MouseEventHandler;
}) => {
  const { text, href } = item;

  return (
    <NavLink
      isActive={() => isActive}
      className={styles.recentItem}
      activeClassName={styles.active}
      to={href}
      onClick={onClick}
    >
      <div className={styles.itemTitle}>{text}</div>
    </NavLink>
  );
};
