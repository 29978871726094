import { Editor } from "slate";

import { markdownToHtml } from "components/slate/plugins/serialization/markdownToHtml";
import insertHtml from "components/slate/plugins/serialization/transforms/insertHtml";

const insertMarkdown = (editor: Editor, markdownText: string) => {
  return insertHtml(editor, markdownToHtml(markdownText));
};

export default insertMarkdown;
