import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Button from "framework/components/form/Button";

import styles from "./index.module.scss";

const CardToolbarPopupMenuButton = forwardRef<any, any>(
  ({ onClick, onMouseDown, icon: Icon, color, children }, ref) => {
    return (
      <Button
        ref={ref}
        btnClass={styles.cardToolbarButton}
        style={{ color }}
        onClick={onClick}
        onMouseDown={onMouseDown}
        size="symbol"
      >
        <Icon size={21} />
      </Button>
    );
  }
);

CardToolbarPopupMenuButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  color: PropTypes.string,
};

export default CardToolbarPopupMenuButton;
