import AuthPageContainer from "components/AuthPageContainer";

import { Route, Switch } from "react-router-dom";

import NotFoundPage from "pages/other/NotFoundPage";
import { SignUpStep } from "./SignUpProvider";
import EmailForm from "./Steps/EmailForm";
import NameForm from "./Steps/NameForm";
import NoHumor from "./Steps/NoHumor";
import PasswordForm from "./Steps/Password";
import SillyName from "./Steps/SillyName";
import YouHateFun from "../../app/OnboardingPage/Steps/YouHateFun";
import SimpleRoute from "routing/SimpleRoute";

const SignUpTemplate = () => {
  return (
    <Switch>
      <SimpleRoute
        layout={AuthPageContainer}
        path="/signup"
        exact
        component={NameForm}
      />
      <SimpleRoute
        layout={AuthPageContainer}
        path={`/signup/${SignUpStep.SillyName}`}
        component={SillyName}
      />
      <SimpleRoute
        layout={AuthPageContainer}
        path={`/signup/${SignUpStep.NoHumor}`}
        component={NoHumor}
      />
      <SimpleRoute
        layout={AuthPageContainer}
        path={`/signup/${SignUpStep.Email}`}
        component={EmailForm}
      />
      <SimpleRoute
        layout={AuthPageContainer}
        path={`/signup/${SignUpStep.Password}`}
        component={PasswordForm}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default SignUpTemplate;
