/* prettier-ignore */
/* eslint-disable */
// @ts-nocheck
const injectScript = (appId: any) => {
  //  @ts-ignore ts-migrate(2339) FIXME: Property 'heap' does not exist on type 'Window & t... Remove this comment to see the full error message
  window.heap=window.heap||[],heap.load=function(e: any,t: any){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e: any){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  //  @ts-ignore ts-migrate(2304) FIXME: Cannot find name 'heap'.
  heap.load(appId);
}

export default injectScript;
