import { UseSlatePlugin } from "components/slate/types";
import { withDeserialize } from "components/slate/plugins/serialization/withDeserialize";

const useDeserializePlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withDeserialize,
  };
};

export default useDeserializePlugin;
