import { FocusEvent, useCallback } from "react";
import { Editor, Node, Range } from "slate";

import { BacklinkMenuProps } from "./types";
import useZustandCreateStore from "hooks/useZustandCreateStore";
import createState from "./state";
import useMemoObject from "hooks/useMemoObject";
import {
  insertBrackets,
  wrapFragmentInBrackets,
} from "components/slate/plugins/backlink/transforms";
import { escapeRegExp, getText } from "components/slate/utils";
import { UseSlatePlugin } from "components/slate/types";

const useBacklinkMenuPlugin: UseSlatePlugin<{}, BacklinkMenuProps> = () => {
  const { state, getState } = useZustandCreateStore(createState, []);

  const onKeyDown = useCallback(
    (editor) => (e: any) => {
      const { key } = e;
      const { selection } = editor;

      if (key === "[") {
        if (Range.isCollapsed(selection)) {
          e.preventDefault();
          insertBrackets(editor);
        } else {
          const selectedFragment = Node.fragment(editor, selection);
          // Only allow wrapping content within one text node
          if (
            selectedFragment.length === 1 &&
            //  @ts-ignore ts-migrate(2339) FIXME: Property 'children' does not exist on type 'Descen... Remove this comment to see the full error message
            selectedFragment[0].children.length === 1
          ) {
            e.preventDefault();
            wrapFragmentInBrackets(editor, selectedFragment[0]);
          }
        }
      }
    },
    [getState]
  );

  const onChange = useCallback(
    (editor) => (value) => {
      const { openMenu, setSearch, closeMenu } = getState();

      const { selection } = editor;
      const trigger = "[[";
      const captureEnd = "]]";

      if (selection && Range.isCollapsed(selection)) {
        const cursor = Range.start(selection);

        // Point at the start of line
        const lineStart = Editor.before(editor, cursor, { unit: "line" });
        const beforeRange =
          lineStart && Editor.range(editor, lineStart, cursor);
        const beforeText = getText(editor, beforeRange);

        // Find start pattern
        const escapedTrigger = escapeRegExp(trigger);
        const escapedCaptureEnd = escapeRegExp(captureEnd);
        const beforeRegex = new RegExp(`${escapedTrigger}((.*?))$`);
        const match = !!beforeText && beforeText.match(beforeRegex);

        // Get the whole range
        const linkStartPoint = match
          ? Editor.before(editor, cursor, {
              unit: "character",
              distance: match[1].length + trigger.length,
            })
          : null;
        const linkEndPoint = match
          ? Editor.after(editor, cursor, {
              unit: "character",
              distance: trigger.length,
            })
          : null;
        const bracketRange =
          linkStartPoint &&
          linkEndPoint &&
          Editor.range(editor, linkStartPoint, linkEndPoint);

        // Make sure fully wrapped in [[]]
        const fullText = getText(editor, bracketRange);
        const fullBracketMatch =
          fullText &&
          fullText.match(
            new RegExp(`${escapedTrigger}(.*?)${escapedCaptureEnd}`)
          );

        if (fullBracketMatch) {
          const searchTerm = fullBracketMatch[1];
          openMenu(bracketRange);
          setSearch(searchTerm, bracketRange);
          return;
        }

        closeMenu();
      }
    },
    [getState]
  );

  const onBlur = useCallback(
    (editor: Editor) => (e: FocusEvent) => {
      const { closeMenu } = getState();
      closeMenu();
    },
    [getState]
  );

  return {
    handlers: useMemoObject({
      onKeyDown,
      onBlur,
    }),
    onChange,
    state: useMemoObject<BacklinkMenuProps>({
      initialTarget: state.initialTarget,
      target: state.target,
      search: state.search,
      closeMenu: state.closeMenu,
    }),
  };
};

export default useBacklinkMenuPlugin;
