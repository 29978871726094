export default (
  event: any,
  hits: any,
  targetRef: any,
  index: any,
  setIndex: any,
  navigateToHit: any,
  closeAutoSuggest: any
) => {
  const { key } = event;
  switch (key) {
    case "ArrowDown":
      event.preventDefault();
      const prevIndex = index >= hits.length - 1 ? 0 : index + 1;
      setIndex(prevIndex);
      break;
    case "ArrowUp":
      event.preventDefault();
      const nextIndex = index <= 0 ? hits.length - 1 : index - 1;
      setIndex(nextIndex);
      break;
    case "Enter":
      event.preventDefault();

      const doOpenInNewTab = event.metaKey;

      if (doOpenInNewTab) {
        navigateToHit(hits[index], true);
      } else {
        navigateToHit(hits[index]);
        closeAutoSuggest();
      }
      break;
    case "Escape":
      event.preventDefault();
      closeAutoSuggest();
      break;
    default:
      break;
  }
};
