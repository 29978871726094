import { Descendant } from "slate";

export type MediaEmbedType = "media_embed";
export const MediaEmbedType: MediaEmbedType = "media_embed";

export type MediaEmbedElement = {
  id: string;
  type: MediaEmbedType;
  url: string;
  inputText: string;
  children: Descendant[];
};
