import React, {useEffect, useState } from "react";
import { descend, sortWith } from "ramda";

import OverviewPageLayout from "components/layout/OverviewPageLayout";
import Helmet from "components/Helmet";
import { useAllUsersFeedback } from "db/feedback/feedback.hooks";
import CardSpinner from "framework/components/Card/CardSpinner";
import AppResult from "components/results/AppResult";
import { formatDateTime } from "utils/dateUtils";
import { Feedback } from "db/feedback/feedback.types";
import Result from "framework/components/Result";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import TextInput from "framework/components/form/TextInput";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

import styles from "./index.module.scss";

const FeedbackOverviewPage = () => {
  const { isLoading, isError, error, data } = useAllUsersFeedback();
  const [search, setSearch] = useState("");
  const { isMobile } = useDeviceDetector();

  //allow body scrolling on this page
  useEffect(() => {
    document.body.setAttribute("no-side-panel-page", "true");
  });

  return (
    <OverviewPageLayout
      title="Feedback"
      rightActions={
        <TextInput
          name="search"
          autoFocus={!isMobile}
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      }
    >
      <Helmet pageTitle="Feedback" />
      <WidgetErrorBoundary>
        {(function () {
          if (isLoading) {
            return (
              <div>
                <CardSpinner />
              </div>
            );
          }

          if (isError) {
            console.error(error);
            return <AppResult resultType="fetching" isCenteredContent={true} />;
          }

          const feedback = data
            ? sortWith([descend<Feedback>((x) => x.updatedAt)], data)
            : [];

          if (!feedback.length) {
            return (
              <Result title="No feedback found" isCenteredContent={true} />
            );
          }

          const filtered =
            search.trim() === ""
              ? feedback
              : feedback.filter((x) =>
                  [x.email, x.lastName, x.firstName, x.mood, x.text]
                    .join(" ")
                    .toLowerCase()
                    .includes(search.toLowerCase())
                );

          return (
            <table className={styles.table}>
              <thead>
                <tr className={styles.tr}>
                  <th className={styles.email}>Email</th>
                  <th>Name</th>
                  <th>Access</th>
                  <th>Dismiss</th>
                  <th>Option</th>
                  <th>Text</th>
                  <th>Updated At</th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((item) => {
                  return (
                    <tr key={item.id} className={styles.tr}>
                      <td className={styles.email}>{item.email}</td>
                      <td>{`${item.firstName || ""} ${
                        item.lastName || ""
                      }`}</td>
                      <td>{item.authAccessType}</td>
                      <td>{item.dismiss.toString()}</td>
                      <td>{item.mood}</td>
                      <td>{item.text}</td>
                      <td>
                        {item.updatedAt && formatDateTime(item.updatedAt)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })()}
      </WidgetErrorBoundary>
    </OverviewPageLayout>
  );
};

export default FeedbackOverviewPage;
