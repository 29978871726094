export const getUserPath = (userId: string) => `users/${userId}`;
export const getFeedbackCollectionPath = () => `feedback`;
export const getFeedbackPath = (feedbackId: string) => `feedback/${feedbackId}`;
export const getTemplatesPath = (userId: string) => `users/${userId}/templates`;
export const getTemplatePath = (userId: string, templateId: string) =>
  `users/${userId}/templates/${templateId}`;
export const getDocumentsPath = () => "pages";
export const getDocumentPath = (documentId: string) =>
  `${getDocumentsPath()}/${documentId}`;
export const getBlocksPath = () => "blocks";
export const getBlockPath = (blockId: string) =>
  `${getBlocksPath()}/${blockId}`;
export const getDocumentsSettingsPath = (documentId: string) =>
  `page-settings/${documentId}`;
export const getVariablesPath = (key: string) => `variables/${key}`;
export const getTagsPath = () => "tags";
export const getTagPath = (tagId: string) => `tags/${tagId}`;
export const getFilePath = (userId: string, fileId: string, ext: string) => {
  return `users/${userId}/files/${fileId}${ext}`;
};
