import { useMutation, useQuery, UseQueryResult } from "react-query";

import {
  getAllUsersFeedback,
  hasFeedback,
  upsertFeedback,
} from "db/feedback/feedback.queries";
import { Feedback } from "db/feedback/feedback.types";
import { queryClient } from "db/queryClient";

export const useAllUsersFeedback = () => {
  return useQuery(["admin_feedback"], getAllUsersFeedback);
};

export const useUserHasFeedback = (
  userId: string
): UseQueryResult<Feedback> => {
  return useQuery(["feedback", userId], () => hasFeedback(userId));
};

export const useUpsertUserFeedback = () => {
  return useMutation(upsertFeedback, {
    onSuccess: () => {
      queryClient.invalidateQueries("feedback");
    },
  });
};
