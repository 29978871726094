import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getStorage, connectStorageEmulator } from "firebase/storage";

import { env } from "thunk-env";

const config = JSON.parse(env.REACT_APP_FIREBASE_CONFIG_JSON);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const idb = globalThis.indexedDB;

const firebaseApp = initializeApp({
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  databaseURL: config.databaseURL,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId,
});

const auth = initializeAuth(firebaseApp, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence],
});
if (env.REACT_APP_AUTH_EMULATOR_HOST) {
  connectAuthEmulator(auth, env.REACT_APP_AUTH_EMULATOR_HOST);
}

const db = getFirestore(firebaseApp);
if (env.REACT_APP_FIRESTORE_EMULATOR_HOST) {
  const [host, port] = env.REACT_APP_FIRESTORE_EMULATOR_HOST.split(":");
  connectFirestoreEmulator(db, host, Number(port));
}

// enableIndexedDbPersistence(db).catch(function (error) {
//   if (error.code === "failed-precondition") {
//     // Multiple tabs open, persistence can only be enabled
//     // in one tab at a a time.
//   } else if (error.code === "unimplemented") {
//     // The current browser does not support all of the
//     // features required to enable persistence
//   }
//   console.error(error);
// });

const realtimeDb = getDatabase();
if (env.REACT_APP_DATABASE_EMULATOR_HOST) {
  const [host, port] = env.REACT_APP_DATABASE_EMULATOR_HOST.split(":");
  connectDatabaseEmulator(realtimeDb, host, Number(port));
}

const storage = getStorage();
if (env.REACT_APP_STORAGE_EMULATOR_HOST) {
  const [host, port] = env.REACT_APP_STORAGE_EMULATOR_HOST.split(":");
  connectStorageEmulator(storage, host, Number(port));
}
storage.maxUploadRetryTime = 3000;

const functions = getFunctions();

export { firebaseApp, auth, db, realtimeDb, storage, functions };
