import React from "react";

import julianSlash from "images/julian_sidepanel_pin.svg";

import styles from "components/editor/SidePanel/backlinks/empty/index.module.scss";

const NoBlockLinksEmptyState = () => {
  return (
    <div className={styles.root}>
      <img src={julianSlash} alt="Julian" className={styles.image} />
      <h2 className={styles.title}>Pin a note</h2>
      <p className={styles.content}>
        Pin a note to keep it here in the side panel.
      </p>
    </div>
  );
};

export default NoBlockLinksEmptyState;
