import { useCallback } from "react";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  MenuCalendarIcon,
} from "thunk-icons";
import { useHistory, useLocation } from "react-router-dom";
import { add, format } from "date-fns";
import queryString from "query-string";
import { getEditorPathname, makeDateId, useDateFromUrl } from "helpers";
import { isWindows } from "react-device-detect";

import Button from "framework/components/form/Button";
import AppCalendar from "pages/app/NoteEditingPage/AppCalendar";
import useAppPopper from "framework/components/poppers/useAppPopper";
import AppPopper from "framework/components/poppers/AppPopper";

import styles from "./index.module.scss";
import { DocumentType } from "thunk-core";
import useEventListener from "hooks/useEventListener";
import SimpleTooltip from "components/SimpleTooltip";

const CalendarNavigation = () => {
  const history = useHistory();
  const location = useLocation();
  const search = queryString.parse(location.search);

  const metaKey = isWindows ? "⊞" : "⌘";

  const date = useDateFromUrl(search.date as string);

  const redirectToDate = useCallback((value: Date) => {
    const path = getEditorPathname(DocumentType.NOTE, null, makeDateId(value));
    return history.push(path);
  }, []);

  useEventListener(
    window,
    "keydown",
    async (e: any) => {
      if (e.metaKey && e.ctrlKey && e.code === "ArrowRight") {
        redirectToDate(add(date, { days: 1 }));
      }
      if (e.metaKey && e.ctrlKey && e.code === "ArrowLeft") {
        redirectToDate(add(date, { days: -1 }));
      }
    },
    [redirectToDate, date]
  );

  const popperProps = useAppPopper({ offset: [52, 15], hasArrow: true });

  const chooseDate = useCallback(
    (date: Date) => {
      popperProps.closePopper();
      redirectToDate(date);
    },
    [redirectToDate]
  );

  return (
    <div className={styles.container}>
      <div className={styles.calendarButtonGroup}>
        <SimpleTooltip content={`${metaKey} + Ctrl + ⬅`}>
          <Button
            variant="circularElectronHeader"
            btnClass={styles.leftCalendarControlButton}
            size="symbol"
            icon={ChevronLeftIcon}
            iconSize={24}
            onClick={() => {
              redirectToDate(add(date, { days: -1 }));
            }}
          />
        </SimpleTooltip>
        <SimpleTooltip content="Go to Today (Ctrl+1)">
          <Button
            variant="circularElectronHeader"
            btnClass={styles.dateButton}
            ref={popperProps.setTargetElement}
            isSelected={popperProps.isOpened}
            size="symbol"
            onClick={() =>
              popperProps.isOpened
                ? popperProps.closePopper()
                : popperProps.openPopper()
            }
          >
            <span className={styles.dateText}>
              {format(date, "iii, LLL d")}
            </span>
            <span className={styles.dateTextMini}>{format(date, "LLL d")}</span>
          </Button>
        </SimpleTooltip>
        <SimpleTooltip content={`${metaKey} + Ctrl + ⮕`}>
          <Button
            variant="circularElectronHeader"
            btnClass={styles.rightCalendarControlButton}
            size="symbol"
            icon={ChevronRightIcon}
            iconSize={24}
            onClick={() => {
              redirectToDate(add(date, { days: 1 }));
            }}
          />
        </SimpleTooltip>
      </div>

      <AppPopper
        {...popperProps}
        className={styles.calendarPopper}
        color="primary"
        content={
          (popperProps.isOpened || popperProps.isOpenedDebounced) && (
            <AppCalendar
              date={date}
              onSelectDate={chooseDate}
              onTodayClick={chooseDate}
            />
          )
        }
      />
    </div>
  );
};

export default CalendarNavigation;
