import { Transforms } from "slate";
import { getPointBefore } from "@udecode/plate-core";

export const insertEmoji = (editor: any, range: any, obj: any) => {
  const selection = editor.selection;
  const startMarkup = ":";
  const startMarkupPointBefore = getPointBefore(editor, selection.anchor, {
    matchString: startMarkup,
    skipInvalid: true,
  });
  const endMarkupPointBefore = selection.anchor;
  const markupRange = {
    anchor: startMarkupPointBefore,
    focus: endMarkupPointBefore,
  };
  Transforms.select(editor, markupRange);
  Transforms.insertText(editor, ` ${obj.native}`);
};
