import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useHistoryState } from "state/layout";

const HistoryManager = () => {
  const history = useHistory();
  const [historyState, setHistoryState] = useHistoryState();

  useEffect(() => {
    if (historyState.locations.length === 0) {
      setHistoryState({
        locations: [history.location],
        index: 0,
      });
    }

    history.listen((location, action) => {
      if (action === "PUSH") {
        setHistoryState((state) => ({
          locations: [...state.locations.slice(0, state.index + 1), location],
          index: state.index + 1,
        }));
        return;
      }

      if (action === "POP") {
        const { key } = location;

        setHistoryState((state) => {
          const index = state.locations.findIndex((x) => x.key === key);

          if (index === -1) {
            return;
          }

          return {
            locations: state.locations,
            index: Math.min(Math.max(index, 0), state.locations.length - 1),
          };
        });
        return;
      }

      if (action === "REPLACE") {
        setHistoryState((state) => {
          return {
            locations: [...state.locations.slice(0, -1), location],
            index: state.index,
          };
        });
        return;
      }
    });
  }, []);

  return null;
};

export default HistoryManager;
