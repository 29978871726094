import { FirestoreDataConverter } from "../types";
import { Document, DocumentType } from "./documents.types";

export const DOCUMENT_CONVERTER: FirestoreDataConverter<Document> = {
  toFirestore: (document) => {
    const {
      id,
      imageUrl,
      imageStoragePath,
      imageUrlDark,
      imageStoragePathDark,
      ...converted
    } = document;

    return converted;
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data();

    return {
      id: snapshot.ref.id,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      type: data.type ?? DocumentType.PAGE,
      userId: data.userId,
      dateId: data.dateId ?? null,
      parentId: data.parentId ?? null,
      sharedId: data.sharedId,
      originPageId: data.originPageId ?? null,
      originUserId: data.originUserId ?? null,
      title: data.title ?? "",
      lowercaseTitle: data.lowercaseTitle ?? "",
      isShared: data.isShared ?? false,
      isFavorite: data.isFavorite ?? false,
      showWordCount: data.showWordCount ?? false,
      blocks: data.blocks ?? [],
      tags: data.tags ?? [],
      isEmpty: data.isEmpty ?? true,
      imageStoragePath: data.imageStoragePath ?? null,
      imageUrl: data.imageUrl ?? null,
      imageStoragePathDark: data.imageStoragePathDark ?? null,
      imageUrlDark: data.imageUrlDark ?? null,
      hasSyncedRemote: data.hasSyncedRemote ?? false,
      lastModifiedSession: data.lastModifiedSession ?? null,
      headerImage: data.headerImage ?? null,
    };
  },
};
