import isHotkey from "is-hotkey";
//  @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'thun... Remove this comment to see the full error message
import { JulianIcon } from "thunk-icons";

import { appShortcuts } from "appShortcuts";
import useWindowEventListener from "./useWindowEventListener";
import useNotifications from "./useNotifications";

const useSaveNotification = () => {
  const { addNotification } = useNotifications();
  useWindowEventListener(
    "keydown",
    (e: any) => {
      if (isHotkey(appShortcuts.saveNotification, e)) {
        e.preventDefault();
        addNotification({
          icon: JulianIcon,
          duration: 2500,
          type: "success",
          text: `Thunk auto-saves your work for you`,
        });
      }
    },
    []
  );
};

export { useSaveNotification };
