import React, { useCallback } from "react";
import cn from "classnames";

import CollapseButton from "framework/components/CollapseButton";
import { useDocumentSettingsState } from "db/documentsSettings/documentsSettings.hooks";
import { useCurrentUserId } from "db/currentUser";

import styles from "components/editor/DocumentReferences/index.module.scss";

type Props = {
  documentId: string;
  title: React.ReactNode;
  children: ({ opened }: { opened: boolean }) => React.ReactNode;
};

const ReferencesSection = ({ documentId, title, children }: Props) => {
  const userId = useCurrentUserId();

  const [{ data: referencesOpened }, setReferencesOpened] =
    useDocumentSettingsState(userId, documentId, "referencesOpened");

  const handleToggle = useCallback(() => {
    setReferencesOpened((state) => !state);
  }, [setReferencesOpened]);

  return (
    <div
      className={cn(styles.refHeader, {
        [styles.refHeaderCollapsed]: !referencesOpened,
      })}
    >
      <CollapseButton
        className={styles.refCollapseButton}
        arrowClassName={styles.refCollapseArrow}
        isOpened={referencesOpened}
        onClick={() => handleToggle()}
      >
        <div className={styles.referencesTitle}>{title}</div>
      </CollapseButton>
      {children({ opened: referencesOpened })}
    </div>
  );
};

export default ReferencesSection;
