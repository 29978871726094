import { UseSlatePlugin } from "components/slate/types";
import { withBacklink } from "components/slate/plugins/backlink/withBacklink";
import React, { useCallback, useMemo } from "react";
import { Descendant, Editor } from "slate";
import debounce from "lodash.debounce";

import {
  isBacklinkElement,
  setSelectedBacklinks,
} from "components/slate/plugins/backlink/utils";
import BacklinkComponent from "components/slate/plugins/backlink/components/BacklinkComponent";
import { useAuth } from "auth/AuthProvider";

const useBacklinkPlugin: UseSlatePlugin<{
  isMainEditor: boolean;
}> = ({ isMainEditor }) => {
  const { currentUser } = useAuth();

  const setSelectedBacklinksDebounced = useMemo(
    () =>
      debounce((editor: Editor) => {
        if (isMainEditor) {
          setSelectedBacklinks(editor);
        }
      }, 150),
    [isMainEditor]
  );

  const onChange = useCallback(
    (editor: Editor) => (value: Descendant[]) => {
      setSelectedBacklinksDebounced(editor);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    withOverrides: withBacklink(currentUser?.uid),
    onChange,
    renderElement: (props) => {
      if (isBacklinkElement(props.element)) {
        return <BacklinkComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useBacklinkPlugin;
