type ThunkEnv = {
  // main
  NODE_ENV: "development" | "test" | "production";
  REACT_APP_ENV: "development" | "staging" | "production";

  // github secrets
  REACT_APP_FIREBASE_CONFIG_JSON: string;
  REACT_APP_ALGOLIA_APP_ID: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_HEAP_APP_ID: string; // only for production

  // REACT_APP_ENV specific, but stored in git
  REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT: string;
  REACT_APP_ALGOLIA_PAGE_INDEX: string;
  REACT_APP_ALGOLIA_BLOCK_INDEX: string;

  // common, stored in git
  REACT_APP_SUPPORT_EMAIL: string;
  REACT_APP_LANDING_SITE_HOST: string;
  REACT_APP_HOST: string;
  REACT_APP_DISCORD_SUPPORT_LINK: string;

  // only local
  REACT_APP_FIRESTORE_EMULATOR_HOST: string;
  REACT_APP_AUTH_EMULATOR_HOST: string;
  REACT_APP_DATABASE_EMULATOR_HOST: string;
  REACT_APP_STORAGE_EMULATOR_HOST: string;
  REACT_APP_SLATE_DEBUG: boolean;

  // react native
  REACT_APP_WEBVIEW: boolean;
};

export const env: ThunkEnv = {
  NODE_ENV: process.env.NODE_ENV as ThunkEnv["NODE_ENV"],
  REACT_APP_ENV: process.env.REACT_APP_ENV as ThunkEnv["REACT_APP_ENV"],

  REACT_APP_FIREBASE_CONFIG_JSON: process.env.REACT_APP_FIREBASE_CONFIG_JSON,
  REACT_APP_ALGOLIA_APP_ID: process.env.REACT_APP_ALGOLIA_APP_ID,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  REACT_APP_HEAP_APP_ID: process.env.REACT_APP_HEAP_APP_ID,

  REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT:
    process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_ENDPOINT ||
    process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT,
  REACT_APP_ALGOLIA_PAGE_INDEX: process.env.REACT_APP_ALGOLIA_PAGE_INDEX,
  REACT_APP_ALGOLIA_BLOCK_INDEX: process.env.REACT_APP_ALGOLIA_BLOCK_INDEX,

  REACT_APP_SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
  REACT_APP_LANDING_SITE_HOST: process.env.REACT_APP_LANDING_SITE_HOST,
  REACT_APP_HOST: process.env.REACT_APP_HOST,
  REACT_APP_DISCORD_SUPPORT_LINK: process.env.REACT_APP_DISCORD_SUPPORT_LINK,

  REACT_APP_FIRESTORE_EMULATOR_HOST:
    process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
  REACT_APP_AUTH_EMULATOR_HOST: process.env.REACT_APP_AUTH_EMULATOR_HOST,
  REACT_APP_DATABASE_EMULATOR_HOST:
    process.env.REACT_APP_DATABASE_EMULATOR_HOST,
  REACT_APP_STORAGE_EMULATOR_HOST: process.env.REACT_APP_STORAGE_EMULATOR_HOST,
  REACT_APP_SLATE_DEBUG: Boolean(process.env.REACT_APP_SLATE_DEBUG),

  REACT_APP_WEBVIEW: Boolean(process.env.REACT_APP_WEBVIEW),
};

// @ts-ignore
window.__TEST_THUNK_ENV__ = env; // TODO: remove in any next release
