import React, { forwardRef } from "react";

import { ReactComponent as PopperArrowSvg } from "framework/components/poppers/popper_arrow.svg";

import styles from "./index.module.scss";

type Props = {
  style: React.CSSProperties;
};

const PopperArrow = ({ style }: Props, ref) => {
  return (
    <div ref={ref} style={style} className={styles.arrow}>
      <PopperArrowSvg />
    </div>
  );
};

export default forwardRef<HTMLElement, Props>(PopperArrow);
