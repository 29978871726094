import {
  createPluginFactory,
  PlatePlugin,
  DeserializeHtml,
} from "@udecode/plate-core";
import { nanoid } from "nanoid";

import { ParagraphType } from "components/slate/plugins/paragraph/types";
import {
  Heading1Type,
  Heading2Type,
  Heading3Type,
} from "components/slate/plugins/heading/types";
import { ImageType } from "components/slate/plugins/file/types";
import { LinkType } from "components/slate/plugins/link/types";
import { HorizontalRuleType } from "components/slate/plugins/horizontalRule/types";
import { BlockquoteType } from "components/slate/plugins/blockquote/types";
import { getListItemProps } from "components/slate/plugins/serialization/utils";
import { ListItemType } from "components/slate/plugins/list/types";
import {
  CodeBlockType,
  CodeLineType,
} from "components/slate/plugins/codeBlock/types";

const rules: DeserializeHtml[] = [
  {
    isElement: true,
    getNode: () => ({ type: ParagraphType }),
    rules: [
      {
        validNodeName: ["P", "H4", "H5", "H6"],
      },
    ],
  },
  {
    getNode: () => ({ type: Heading1Type }),
    isElement: true,
    rules: [
      {
        validNodeName: "H1",
      },
    ],
  },
  {
    getNode: () => ({ type: Heading2Type }),
    isElement: true,
    rules: [
      {
        validNodeName: "H2",
      },
    ],
  },
  {
    getNode: () => ({ type: Heading3Type }),
    isElement: true,
    rules: [
      {
        validNodeName: "H3",
      },
    ],
  },
  {
    getNode: (el) => ({ type: ImageType, url: el.getAttribute("src") }),
    isElement: true,
    rules: [
      {
        validNodeName: "IMG",
      },
    ],
  },
  {
    getNode: (el) => ({ type: LinkType, url: el.getAttribute("href") }),
    isElement: true,
    rules: [
      {
        validNodeName: "A",
      },
    ],
    query: (el) => {
      return !!el.textContent && el.textContent.trim() !== ""; // skip if link includes non text info (e.g. image)
    },
  },
  {
    getNode: () => ({ type: HorizontalRuleType }),
    isElement: true,
    rules: [
      {
        validNodeName: "HR",
      },
    ],
  },
  {
    getNode: () => ({ type: BlockquoteType }),
    isElement: true,
    rules: [
      {
        validNodeName: "BLOCKQUOTE",
      },
    ],
  },
  {
    getNode: (el) => {
      const { listType, depth } = getListItemProps(el);

      return {
        type: ListItemType,
        depth,
        listType,
      };
    },
    isElement: true,
    rules: [
      {
        validNodeName: ["LI"],
      },
    ],
  },
  {
    isElement: true,
    rules: [
      {
        validNodeName: "PRE",
      },
    ],
    getNode: (el) => {
      const languageSelectorText =
        [...el.childNodes].find((node: ChildNode) => node.nodeName === "SELECT")
          ?.textContent || "";

      const textContent =
        el.textContent?.replace(languageSelectorText, "") || "";

      let lines = textContent.split("\n");

      if (!lines?.length) {
        lines = [textContent];
      }

      const codeLines = lines.map((line) => ({
        type: CodeLineType,
        children: [{ text: line }],
      }));

      return {
        type: CodeBlockType,
        children: codeLines,
      };
    },
  },
  {
    isLeaf: true,
    getNode: () => ({ bold: true }),
    rules: [
      { validNodeName: ["STRONG", "B"] },
      {
        validStyle: {
          fontWeight: ["600", "700", "bold"],
        },
      },
    ],
  },
  {
    isLeaf: true,
    getNode: () => ({ italic: true }),
    rules: [
      { validNodeName: ["EM", "I"] },
      {
        validStyle: {
          fontStyle: "italic",
        },
      },
    ],
  },
  {
    isLeaf: true,
    getNode: () => ({ code: true }),
    rules: [
      {
        validNodeName: ["CODE"],
      },
      {
        validStyle: {
          wordWrap: "break-word",
        },
      },
    ],
  },
  {
    isLeaf: true,
    getNode: () => ({ underline: true }),
    rules: [
      {
        validNodeName: ["U"],
      },
      {
        validStyle: {
          textDecoration: ["underline"],
        },
      },
    ],
  },
];

export const deserializePlugins: PlatePlugin<any, any>[] = rules.map((rule) =>
  createPluginFactory<any>({
    key: nanoid(4),
    deserializeHtml: rule,
  })()
);

deserializePlugins.forEach((p) => {
  p.inject = {};
});
