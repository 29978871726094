import React, { Fragment, useState } from "react";
import { createEditor, Descendant } from "slate";
import { Editable, Slate } from "slate-react";

import useRenderLeaf from "components/slate/hooks/useRenderLeaf";
import useEditor from "components/slate/hooks/useEditor";
import SlateExtended from "components/slate/slate-extended/SlateExtended";
import usePlateEditorPlugins from "components/slate/hooks/usePlateEditorPlugins";
import useDecorate from "components/slate/hooks/useDecorate";
import useBlockPlugins from "components/slate/hooks/useBlockPlugins";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import AtMenu from "components/slate/plugins/menus/atMenu/components/AtMenu";
import HashMenu from "components/slate/plugins/menus/hashMenu/components/HashMenu";
import BacklinkMenu from "components/slate/plugins/menus/backlinkMenu/components/BacklinkMenu";
import { FormattingMenu } from "components/slate/plugins/menus/formatting/FormattingMenu";
import { EmojiComponent } from "components/slate/plugins/emoji/components/EmojiComponent";
import {
  ElementContextMenu,
  ElementContextMenuProvider,
} from "components/slate/plugins/menus/formatting/ElementContextMenu";
import { useBlockRenderElement } from "components/slate/hooks/useRenderElement";
import useHandlers from "components/slate/hooks/useHandlers";
import { StaticPropsProvider } from "components/slate/hooks/useStaticProps";
import { EditorType } from "components/slate/types";
import { getReferencesDiffs } from "components/slate/plugins/backlink/db";
import { DocumentType } from "thunk-core";
import { SyncSlateValue } from "components/slate/editors/SyncSlateValue";
import { useEditorsMapEffects } from "components/slate/state/EditorsStore";

type Props = {
  slateId: string;
  documentId: string;
  documentType: DocumentType;
  userId: string;
  value?: Descendant[];
  readOnly?: boolean;
  onChange?: (value: Descendant[]) => void;
  onSync?: () => void;
  onBacklinksChange?: (referencesDiff: any) => void;
  onBacklinkClick?: (element: BacklinkElement) => void;
};

const SlateBlockEditor = (props: Props) => {
  const {
    slateId,
    documentId,
    documentType,
    userId,
    onChange,
    onSync,
    onBacklinkClick,
    onBacklinksChange,
    readOnly,
  } = props;

  const content = props.value;

  const [value, setValue] = useState(content);

  const {
    plugins,
    atMenuPlugin,
    hashMenuPlugin,
    backlinkMenuPlugin,
    formattingMenuPlugin,
    emojiPlugin,
  } = useBlockPlugins({
    isMainEditor: false,
    slateId,
    userId,
  });
  const editor = useEditor(createEditor, plugins);
  const handlers = useHandlers(editor, plugins);

  const renderElement = useBlockRenderElement(editor, plugins);
  const renderLeaf = useRenderLeaf(editor, plugins);
  const decorate = useDecorate(editor, plugins);

  usePlateEditorPlugins(editor);

  const handleChange = (content: Descendant[]) => {
    if (readOnly) {
      return;
    }

    if (onBacklinksChange) {
      const referencesDiff = getReferencesDiffs(value, content);
      onBacklinksChange(referencesDiff);
    }

    if (value !== content && onChange) {
      onChange(content);
    }
    setValue(content);

    plugins.filter((x) => x.onChange).map((x) => x.onChange(editor)(content));
  };

  useEditorsMapEffects(slateId, editor);

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <ElementContextMenuProvider editor={editor}>
        <StaticPropsProvider
          value={{
            readOnly,
            slateId: "",
            editorType: EditorType.ReferenceEditor,
            documentId,
            documentType,
            isSharing: false,
            isSnippet: true,
            isMainEditor: false,
            isHelpDocs: false,
            noSortable: true,
            pageTitle: "",
            onBacklinkClick,
          }}
        >
          <SyncSlateValue
            value={content}
            setValue={(value) => {
              setValue(value);
              onSync && onSync();
            }}
            dragging={false}
          >
            <SlateExtended>
              <Editable
                data-slate-id={slateId}
                className="slate-editor"
                readOnly={readOnly}
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                decorate={decorate}
                {...handlers}
              />
              {!readOnly && (
                <Fragment>
                  <AtMenu {...atMenuPlugin.state} />
                  <HashMenu {...hashMenuPlugin.state} />
                  <BacklinkMenu {...backlinkMenuPlugin.state} />
                  <FormattingMenu {...formattingMenuPlugin.state} />
                  <EmojiComponent {...emojiPlugin.state} />
                  <ElementContextMenu />
                </Fragment>
              )}
            </SlateExtended>
          </SyncSlateValue>
        </StaticPropsProvider>
      </ElementContextMenuProvider>
    </Slate>
  );
};

export default SlateBlockEditor;
