import React, { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import styles from "./index.module.scss";

const ForkingPage = () => {
  const history = useHistory();
  const location = useLocation();
  const search = queryString.parse(location.search);

  const forkId = search.forkId as string;
  const forkTitle = search.forkTitle as string;

  const redirect = search.redirect as "signin" | "signup";

  useEffect(() => {
    if (forkId && (redirect === "signin" || redirect === "signup")) {
      window.sessionStorage.setItem(
        "forkPage",
        JSON.stringify({ forkId, forkTitle, isNewUser: redirect === "signup" })
      );

      window.location.href = `/${redirect}`;
    }

    history.replace("/");
  }, []);

  return (
    <div className={styles.container}>
      <BeatLoader color="var(--blue-color)" />
    </div>
  );
};

export default ForkingPage;
