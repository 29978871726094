import React from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { useBacklinkTitle } from "stores/hooks/useBacklinkTitle";

import styles from "./index.module.scss";
import Checkbox from "framework/components/form/Checkbox";

type Props = {
  backlinks: BacklinkElement[];
  pinned: Set<string>;
  onDocumentPin: (targetId: string, isPinned: boolean) => void;
  onBatchPin: (hasPinned: boolean) => void;
};

const BacklinksPopperContent = observer((props: Props) => {
  const { backlinks, pinned, onBatchPin, onDocumentPin } = props;

  const hasPinned = pinned.size > 0;

  return (
    <div className={styles.linksPopperList}>
      <div className={cn(styles.clearAll)}>
        <div className={styles.linkText}>
          <Checkbox
            className={styles.bulkPin}
            checked={hasPinned}
            onChange={() => onBatchPin(hasPinned)}
            label={hasPinned ? "Unpin all" : "Pin all"}
          />
        </div>
        <div className={styles.pinnedIndicator}>{pinned.size} Pinned</div>
      </div>
      <hr className="dividerLine" />

      {backlinks.map((backlink) => {
        const isSelected = pinned.has(backlink.targetId);

        return (
          <BacklinkCheckbox
            key={backlink.id}
            backlink={backlink}
            checked={isSelected}
            onChange={() => onDocumentPin(backlink.targetId, isSelected)}
          />
        );
      })}
    </div>
  );
});

export default BacklinksPopperContent;

const BacklinkCheckbox = observer(
  ({
    backlink,
    checked,
    onChange,
  }: {
    backlink: BacklinkElement;
    checked: boolean;
    onChange: React.ChangeEventHandler;
  }) => {
    const title = useBacklinkTitle(backlink);

    return (
      <div className={styles.linksPopperListItem}>
        <div className={styles.linkText}>
          <Checkbox
            key={backlink.targetId}
            checked={checked}
            onChange={onChange}
            label={title}
          />
        </div>
      </div>
    );
  }
);
