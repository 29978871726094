import React from "react";

import { withLink } from "./withLink";
import { UseSlatePlugin } from "components/slate/types";
import { isLinkElement } from "components/slate/plugins/link/utils";
import LinkComponent from "components/slate/plugins/link/components/LinkComponent";

const useLinkPlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withLink,
    renderElement: (props) => {
      if (isLinkElement(props.element)) {
        return <LinkComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useLinkPlugin;
