import * as React from "react";
import { isMarkActive } from "@udecode/plate-core";
import { useSlate } from "slate-react";
import { Range } from "slate";

import ToolbarButton from "./ToolbarButton";

import useApplyFormatting from "components/slate/plugins/menus/formatting/FormattingMenu/useApplyFormatting";

export const ToolbarMark = ({ type, ...props }: any) => {
  const editor = useSlate();
  const applyFormatting = useApplyFormatting();

  return (
    <ToolbarButton
      active={
        editor?.selection != null &&
        Range.isExpanded(editor.selection) &&
        isMarkActive(editor, type)
      }
      onMouseDown={(e) => {
        e.preventDefault();
        applyFormatting(editor, type);
      }}
      {...props}
    />
  );
};
