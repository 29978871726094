import { Editor, Path, Transforms } from "slate";

import { makeNodeId } from "components/slate/config/makeNodeId";
import { ParagraphType } from "components/slate/plugins/paragraph/types";

export const addBlockAbove = (editor: Editor) => {
  const path = Editor.path(editor, editor.selection, { depth: 1 });

  Transforms.insertNodes(
    editor,
    {
      id: makeNodeId(true),
      type: ParagraphType,
      children: [{ text: "" }],
    },
    { at: path, select: true }
  );
};

export const addBlockBelow = (editor: Editor) => {
  const path = Editor.path(editor, editor.selection, { depth: 1 });

  Transforms.insertNodes(
    editor,
    {
      id: makeNodeId(true),
      type: ParagraphType,
      children: [{ text: "" }],
    },
    { at: Path.next(path), select: true }
  );
};
