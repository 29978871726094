import React from "react";
import { useSlate } from "slate-react";
import cn from "classnames";

import { ElementProps } from "components/slate/types";
import { ListItemElement } from "components/slate/plugins/list/types";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { getNumberedPointerContent } from "components/slate/plugins/list/getPointerContent";

import styles from "./index.module.scss";

export const renderNumberedItem = (
  props: ElementProps & { element: ListItemElement }
) => {
  return (
    <div
      {...props.attributes}
      className={cn(
        styles.listItem,
        styles[`listItem_${props.element.listType}`]
      )}
    >
      <NumberedPointer {...props} />
      <div className={styles.listItemText}>{props.children}</div>
    </div>
  );
};

const NumberedPointer = (
  props: ElementProps & { element: ListItemElement }
) => {
  const editor = useSlate(); // useSlate to rerender pointer content (index) when this element isn't changed directly
  const semanticNode = ExtendedEditor.semanticNode(editor, props.element);

  return (
    <span
      contentEditable={false}
      data-slate-clipboard-skip={true}
      className={styles.pointer}
      style={
        {
          "--pointer-content": `"${getNumberedPointerContent(
            props.element.depth,
            semanticNode?.listIndex || 0
          )}"`,
        } as React.CSSProperties
      }
      {...props.listeners}
    />
  );
};
