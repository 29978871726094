import React from "react";
import { Descendant, Node } from "slate";
import { ascend, descend, groupBy, sortWith } from "ramda";

import FormGroupLabel from "framework/components/form/FormGroupLabel";
import Button from "framework/components/form/Button";
import { useCurrentUserId } from "db/currentUser";
import { getAllBlocks } from "db/blocks/blocks.queries";
import { getAllDocuments } from "db/documents/documents.queries";
import { mapBlockToElement } from "db/blocks/blocks.mapping";
import useNotify from "hooks/useNotify";
import { Document, DocumentType } from "thunk-core";

const downloadJson = async (data, fileName) => {
  const json = JSON.stringify(data);
  const blob = new Blob([json], { type: "application/json" });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName + ".json";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ExportData = () => {
  const userId = useCurrentUserId();
  const notify = useNotify();

  const [loading, setLoading] = React.useState(false);
  const onExportJsonClick = async () => {
    setLoading(true);

    const exportingNotificationId = notify.loading("Exporting data");

    try {
      const [documents, blocks] = await Promise.all([
        getAllDocuments(userId),
        getAllBlocks(userId),
      ]);

      const blocksMap = new Map(blocks.map((block) => [block.id, block]));

      const contents: (Document & { content: Descendant[] })[] = documents.map(
        (document) => {
          const content = document.blocks
            .map((blockId) => {
              const block = blocksMap.get(blockId);

              if (block) {
                return mapBlockToElement(block);
              }

              return null;
            })
            .filter(Boolean);

          return {
            ...document,
            content,
            string: content.map(Node.string).join("\n"),
          };
        }
      );

      const sorted = sortWith(
        [
          descend<Document>((x) => x.type),
          ascend<Document>((x) =>
            x.type !== DocumentType.NOTE ? x.title.toLowerCase() : null
          ),
          descend<Document>((x) =>
            x.type === DocumentType.NOTE ? x.dateId : null
          ),
        ],
        contents
      );

      const grouped = groupBy((x) => x.type, sorted);

      const userData = grouped;

      await downloadJson(userData, `export-${userId}`);
      setLoading(false);
      notify.success("Exporting is finished");
    } catch (error) {
      console.error(error);
      notify.error("An error occurred while exporting");
    } finally {
      notify.remove(exportingNotificationId);
    }
  };
  return (
    <div>
      <FormGroupLabel labelText="Export Data" />

      <Button loading={loading} variant="secondary" onClick={onExportJsonClick}>
        Export JSON
      </Button>
    </div>
  );
};

export default ExportData;
