import React, { useEffect, useMemo } from "react";
import { flatten } from "ramda";

import useKeyboardNavigation from "components/slate/plugins/menus/componentMenu/hooks/useKeyboardNavigation";
import { ListSection } from "components/slate/plugins/menus/hashMenu/components/types";

import styles from "./index.module.scss";

export type SectionMenuListProps<T> = {
  containerRef?: any;
  isOpened: boolean;
  sections: ListSection<T>[];
  renderListItem: ({
    item,
    isActive,
  }: {
    item: T;
    isActive: boolean;
  }) => React.ReactNode;
  renderSectionHeader: ({
    section,
  }: {
    section: ListSection<T>;
  }) => React.ReactNode;
  onSelect: (e: KeyboardEvent, item: any) => void;
  onCancel: (e: KeyboardEvent) => void;
};

const SectionMenuList = <T,>(props: SectionMenuListProps<T>) => {
  const { containerRef, isOpened, sections, onSelect, onCancel } = props;

  const flattenItems = useMemo(
    () => flatten(sections.map((s) => s.items)),
    [sections]
  );

  const { index, setIndex } = useKeyboardNavigation<T>({
    listening: isOpened,
    items: flattenItems,
    onSelect,
    onCancel,
  });

  useEffect(() => {
    if (isOpened) {
      setIndex(0);
    }
  }, [isOpened, sections]);

  return (
    <div ref={containerRef} className={styles.container}>
      {flattenItems.length > 0 ? (
        renderSections(props, index)
      ) : (
        <div className={styles.placeholder}>
          Continue typing to create your first tag.
        </div>
      )}
    </div>
  );
};

export default SectionMenuList;

const renderSections = <T,>(
  props: SectionMenuListProps<T>,
  index: number
): React.ReactNode[] => {
  const { sections, renderSectionHeader, renderListItem } = props;

  const reactElements: React.ReactNode[] = [];
  let itemIndex = 0;

  for (const section of sections) {
    reactElements.push(renderSectionHeader({ section }));
    for (const item of section.items) {
      reactElements.push(
        renderListItem({
          item,
          isActive: index === itemIndex,
        })
      );
      itemIndex++;
    }
  }

  return reactElements;
};
