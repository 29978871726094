import React, { forwardRef } from "react";
//  @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'thun... Remove this comment to see the full error message
import { CancelIcon } from "thunk-icons";

//  @ts-ignore ts-migrate(2307) FIXME: Cannot find module './index.module.scss' or its co... Remove this comment to see the full error message
import styles from "components/slate/plugins/menus/formatting/FormattingMenu/FormattingMenuToolbar/TemplatesToolbar/TemplatesToolbarInput/index.module.scss";

const ClearButton = ({ onClick }: any) => {
  return (
    <button className={styles.closeIconButton} onClick={onClick}>
      <CancelIcon />
    </button>
  );
};

export const TemplatesToolbarInput = forwardRef(
  //  @ts-ignore ts-migrate(2339) FIXME: Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
  ({ value, onChange, onKeyDown, onRemove, onFocus, onBlur }, ref) => {
    return (
      <div className={styles.inputContainer}>
        <input
          //  @ts-ignore ts-migrate(2322) FIXME: Type 'ForwardedRef<unknown>' is not assignable to ... Remove this comment to see the full error message
          ref={ref}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder="Enter template name"
          className={styles.input}
          type="text"
          onKeyDown={onKeyDown}
        />
        <ClearButton onClick={onRemove} />
      </div>
    );
  }
);
