import React from "react";

import { UseSlatePlugin } from "components/slate/types";
import { isHorizontalRuleType } from "components/slate/plugins/horizontalRule/utils";
import { HorizontalRuleComponent } from "components/slate/plugins/horizontalRule/components/HorizontalRuleComponent";

const useHorizontalRulePlugin: UseSlatePlugin = () => {
  return {
    renderElement: (props) => {
      if (isHorizontalRuleType(props.element)) {
        return <HorizontalRuleComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useHorizontalRulePlugin;
