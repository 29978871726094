import React from "react";
import cn from "classnames";

import styles from "./index.module.scss";

import SimpleTooltip from "components/SimpleTooltip";

export enum PieCheckboxStates {
  auto = "auto",
  manual = "manual",
}

type Props = {
  id?: string;
  style?: React.CSSProperties;
  className?: string;
  checked?: boolean;
  state?: PieCheckboxStates;
  progress: number;
  label?: React.ReactNode;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent, checked: boolean) => void;
};

const PieCheckbox = ({
  id,
  style,
  className,
  checked,
  state,
  progress,
  label,
  disabled = false,
  onChange,
}: Props) => {
  if (!state) {
    state = PieCheckboxStates.manual;
  }

  const inputChecked =
    state === PieCheckboxStates.manual ? checked : progress === 1;
  const pieRadius = inputChecked ? 4 : 2.5;

  if (progress > 0) {
    return (
      <label
        style={style}
        className={cn(styles.wrapper, className, {
          [styles.withLabel]: label != null,
        })}
      >
        <input
          id={id}
          className={cn(styles.input, className, {
            [styles.pie]: state === PieCheckboxStates.auto && progress > 0,
          })}
          type="checkbox"
          checked={inputChecked}
          disabled={disabled}
          onChange={(e) => {
            onChange(e, e.target.checked);
          }}
        />
        <SimpleTooltip
          content={Math.round(progress * 100) + "%"}
          placement="top"
          offset={[0, 15]}
          duration={[100, 100]}
        >
          <svg
            overflow="visible"
            className={styles.svg}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle className={styles.shadow} cx="8" cy="8" r="8" />
            <circle className={styles.border} cx="8" cy="8" r="7.5" />
            <circle
              className={styles.progress}
              style={
                {
                  "--radius": pieRadius,
                  "--progress": progress,
                } as React.CSSProperties
              }
              r={pieRadius}
              cx="8"
              cy="8"
              transform="rotate(-90) translate(-16)"
            />
            <polyline
              className={styles.mark}
              points="3.52301 8.30512 6.60555 11.3921 12.5621 4.91168"
            />
          </svg>
        </SimpleTooltip>

        {label && <span style={{ marginLeft: 6 }}>{label}</span>}
      </label>
    );
  } else {
    return (
      <label
        style={style}
        className={cn(styles.wrapper, className, {
          [styles.withLabel]: label != null,
        })}
      >
        <input
          id={id}
          className={cn(styles.input, className, {
            [styles.pie]: state === PieCheckboxStates.auto && progress > 0,
          })}
          type="checkbox"
          checked={inputChecked}
          disabled={disabled}
          onChange={(e) => {
            onChange(e, e.target.checked);
          }}
        />
        <svg
          overflow="visible"
          className={styles.svg}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle className={styles.shadow} cx="8" cy="8" r="8" />
          <circle className={styles.border} cx="8" cy="8" r="7.5" />
          <circle
            className={styles.progress}
            style={
              {
                "--radius": pieRadius,
                "--progress": progress,
              } as React.CSSProperties
            }
            r={pieRadius}
            cx="8"
            cy="8"
            transform="rotate(-90) translate(-16)"
          />
          <polyline
            className={styles.mark}
            points="3.52301 8.30512 6.60555 11.3921 12.5621 4.91168"
          />
        </svg>

        {label && <span style={{ marginLeft: 6 }}>{label}</span>}
      </label>
    );
  }
};

export default PieCheckbox;
