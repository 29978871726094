import React, { useCallback } from "react";
import { Descendant, Editor, Element } from "slate";

import { UseSlatePlugin } from "components/slate/types";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import useMemoObject from "hooks/useMemoObject";
import { useCopyBlocksHandler } from "components/slate/plugins/terminal/actions";

const getIndex = (editor: Editor) =>
  Editor.path(editor, editor.selection, { depth: 1 })[0];

const useTerminalPlugin: UseSlatePlugin = () => {
  const terminalActions = TerminalStore.useTerminalActions();

  const onMount = useCallback((editor: Editor) => {
    if (editor.selection && editor.slateId) {
      const index = getIndex(editor);
      const element = editor.children[index];

      if (element) {
        terminalActions.setPointer(editor.slateId, element.id);
      }
    }
  }, []);

  const onChange = useCallback(
    (editor: Editor) => (value: Descendant[]) => {
      if (editor.selection && editor.slateId) {
        const index = getIndex(editor);
        const element = value[index] as Element;

        if (element) {
          terminalActions.setPointer(editor.slateId, element.id);
        }
      }
    },
    []
  );

  const onUnmount = useCallback((editor: Editor) => {
    terminalActions.setPointer(editor.slateId, null);
  }, []);

  const onCopy = useCopyBlocksHandler();

  return {
    onMount,
    onChange,
    onUnmount,
    handlers: useMemoObject({
      onCopy: useCallback(
        (editor) => (event) => {
          console.log("test onCopy");
          onCopy(event.nativeEvent as ClipboardEvent);
        },
        []
      ),
    }),
  };
};

export default useTerminalPlugin;
