import { Editor } from "slate";
import { SyntheticEvent } from "react";
import { flatten, mergeWith } from "ramda";
import {
  DOMHandlersKeys,
  EditorHandler,
  Handler,
} from "components/slate/types";

type Plugin = (editor: Editor) => Editor;

export const composePlugins = (plugins: Plugin[], _editor: Editor) => {
  let editor = _editor;
  for (const plugin of plugins.reverse()) {
    editor = plugin(editor);
  }

  return editor;
};

export const composeHandlers = (
  editor: Editor,
  handlersConfig: Partial<Record<DOMHandlersKeys, EditorHandler>>[]
) => {
  const grouped = handlersConfig.reduce(
    (acc, x) => mergeWith((a, b) => flatten([a, b]), acc, x),
    {}
  ) as Record<DOMHandlersKeys, EditorHandler[]>;

  const composed: Partial<Record<string, Handler>> = {};
  for (let [key, value] of Object.entries(grouped)) {
    value = Array.isArray(value) ? value : [value];
    composed[key] = (e: SyntheticEvent) =>
      value.forEach((handler) => handler && handler(editor)!(e));
  }

  return composed as Partial<Record<DOMHandlersKeys, Handler>>;
};
