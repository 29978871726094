import React, { memo } from "react";
import { CrossIcon } from "thunk-icons";

import Button, { ButtonProps } from "framework/components/form/Button";
import { tagsToString } from "db/tags/tags.utils";

import styles from "./index.module.scss";
import { Tag as TagDb } from "thunk-core";

type Props = {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  selectedTags: TagDb[];
  setSelectedTitles: React.Dispatch<React.SetStateAction<string[]>>;
};

export const FilterNotifications = memo((props: Props) => {
  const { search, setSearch, setSelectedTitles, selectedTags } = props;

  const clearAll = () => {
    setSearch("");
    setSelectedTitles([]);
  };

  return (
    <div className={styles.filterNotifications}>
      {search && (
        <FilterNotification
          name="Notes"
          statement="where title contains"
          values={`"${search}"`}
          onRemove={() => setSearch("")}
        />
      )}
      {selectedTags.length > 0 && (
        <FilterNotification
          name="Notes"
          statement="tagged with"
          values={tagsToString(selectedTags)}
          onRemove={() => setSelectedTitles([])}
        />
      )}
      {(search || selectedTags.length > 0) && (
        <ClearFilterNotificationsButton onClick={() => clearAll()} />
      )}
    </div>
  );
});

const ClearFilterNotificationsButton = (props: ButtonProps) => {
  return (
    <Button variant="ghost" size="small" {...props}>
      Clear all
    </Button>
  );
};

const FilterNotification = ({
  name,
  statement,
  values,
  onRemove,
}: {
  name: string;
  statement: string;
  values: string;
  onRemove: React.MouseEventHandler;
}) => {
  return (
    <div className={styles.filterNotification}>
      <div className={styles.filterNotificationContent}>
        <span>{name} </span>
        <span className={styles.statement}>{statement} </span>
        <span>{values}</span>
      </div>
      <button onClick={onRemove}>
        <CrossIcon size={20} />
      </button>
    </div>
  );
};
