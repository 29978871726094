import { Editor, Location, Transforms } from "slate";

import { makeNodeId } from "components/slate/config/makeNodeId";
import { FileElement, FileType } from "./types";

export const insertFileElement = (editor: Editor, file: FileElement) => {
  Transforms.insertNodes(editor, file);
};

export const insertEmptyFileElement = (editor: Editor) => {
  Transforms.insertNodes(editor, {
    id: makeNodeId(true),
    type: FileType,
    children: [{ text: "" }],
  } as any);
};

export const updateFileElement = (
  editor: Editor,
  updates: Omit<FileElement, "id" | "type">,
  path: Location
) => {
  Transforms.setNodes(
    editor,
    {
      url: updates.url,
      fileName: updates.fileName,
      fileSize: updates.fileSize,
      extension: updates.extension,
      children: updates.children,
    },
    { at: path }
  );
};
