import { Subscription } from "@stripe/firestore-stripe-payments";

import { formatDate } from "utils/dateUtils";

export const formatCentPrice = (
  unitAmount: number,
  currency: string
): string => {
  const priceValue = unitAmount / 100;
  const result = priceValue.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
  });

  return result;
};

const formatStripeDate = (stringDate: string) => {
  return formatDate(new Date(stringDate));
};

export const getSubscriptionTagText = (t: any, subscription: Subscription) => {
  if (subscription.cancel_at_period_end) {
    return t("subscription.tags.cancelled");
  } else if (subscription.status === "trialing") {
    return t("subscription.tags.trialing");
  } else if (subscription.status === "active") {
    return t("subscription.tags.active");
  } else {
    return t("subscription.tags.expired");
  }
};

export const getSubscriptionPeriodDetails = (
  t: any,
  subscription: Subscription
) => {
  if (subscription.status !== "active" && subscription.status !== "trialing") {
    const date = formatStripeDate(subscription.ended_at);
    return subscription.ended_at
      ? t("subscription.details.expired", { date })
      : "";
  }

  if (subscription.cancel_at_period_end) {
    if (subscription.status === "trialing") {
      const date = formatStripeDate(subscription.trial_end);
      return t("subscription.details.trialing_cancelled", { date });
    } else if (subscription.status === "active") {
      const date = formatStripeDate(subscription.cancel_at);
      return t("subscription.details.active_cancelled", {
        date,
      });
    }
  } else {
    if (subscription.status === "trialing") {
      const date = formatStripeDate(subscription.trial_end);
      return t("subscription.details.trialing", { date });
    } else if (subscription.status === "active") {
      const date = formatStripeDate(subscription.current_period_end);
      return t("subscription.details.active", { date });
    }
  }
};
