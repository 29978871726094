import { useCallback } from "react";
import { Editor, NodeEntry, Range } from "slate";

import { SlatePlugin } from "components/slate/types";

const useDecorate = (editor: Editor, plugins: SlatePlugin[]) => {
  const decorate = useCallback((entry: NodeEntry): Range[] => {
    const decorators = plugins.filter((x) => x.decorate).map((x) => x.decorate);

    for (const decorator of decorators) {
      const range = decorator(editor)(entry);

      if (range.length > 0) {
        return range;
      }
    }

    return [];
  }, []);

  return decorate;
};

export default useDecorate;
