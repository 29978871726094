import React, { useCallback, useEffect } from "react";
import queryString from "query-string";
import { BeatLoader } from "react-spinners";
import { useHistory, useLocation } from "react-router-dom";

import {
  createHrefLandingSite,
  toLandingSiteReplace,
} from "routing/utils/redirects";
import { useAuth } from "auth/AuthProvider";
import { AuthAccessTypes } from "auth/types";
import { createCheckoutSession } from "db/stripe";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

import styles from "pages/auth/PricingPage/index.module.scss";

// this page is used only to redirect user to other pages
const PricingPage = () => {
  const history = useHistory();
  const location = useLocation();
  const search = queryString.parse(location.search);
  const priceId = search.priceId as string;
  const priceTitle = search.priceTitle as string;

  const { isElectron } = useDeviceDetector();

  const { isAuthenticated, authAccessType } = useAuth();

  const handleCheckout = useCallback(async (priceId: string) => {
    try {
      const checkoutSession = await createCheckoutSession(priceId, {
        success_url: window.location.origin,
        cancel_url: createHrefLandingSite("/pricing"),
      });
      window.location.assign(checkoutSession.url);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (
      priceId &&
      isAuthenticated &&
      (authAccessType === AuthAccessTypes.admin ||
        authAccessType === AuthAccessTypes.earlyAccess)
    ) {
      // redirect to app
      history.replace("/");
      return;
    }

    if (priceId && isAuthenticated) {
      if (authAccessType === AuthAccessTypes.subscription) {
        // redirect to app
        history.replace("/account?isUpgrade=true");
        return;
      }
      // checkout product
      handleCheckout(priceId);
      return;
    }

    if (priceId && !isAuthenticated) {
      // sign up with specific plan
      history.replace(
        `/signup?priceId=${priceId}&priceTitle=${priceTitle ? priceTitle : ""}`
      );
      return;
    }

    if (
      (!priceId &&
        isAuthenticated &&
        authAccessType === AuthAccessTypes.trial) ||
      authAccessType === AuthAccessTypes.trialExpired
    ) {
      // subscriptions required
      toLandingSiteReplace("/trial-pricing");
      return;
    }

    if (isElectron) {
      // redirect to minimalistic pricing screen
      toLandingSiteReplace("/trial-pricing");
      return;
    }

    // for all other cases redirect to regular pricing screen
    toLandingSiteReplace("/pricing");
  }, [isAuthenticated, priceId]);

  return (
    <div className={styles.container}>
      <BeatLoader color="var(--blue-color)" />
    </div>
  );
};

export default PricingPage;
