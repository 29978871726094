import { useCallback } from "react";
import { observer } from "mobx-react-lite";

import EditorCard from "components/editor/EditorCard";
import CardSidePanelToolbar from "components/editor/toolbar/CardSidePanelToolbar";
import { dateIdToDate, getEditorPathname } from "helpers";
import AppResult from "components/results/AppResult";
import { EditorType } from "components/slate/types";
import { useCurrentUserId } from "db/currentUser";
import { useEditingSession } from "stores/hooks/useEditingSession";
import DocumentEditor from "components/editor/DocumentEditor";
import { DocumentType } from "thunk-core";

import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import CardNavigationActions from "components/editor/CardNavigationActions";

import styles from "./index.module.scss";

type BacklinksCardProps = {
  documentType: DocumentType;
  targetId: string;
  isVisible: boolean;
  isPinned: boolean;
  onDocumentPin: (targetId: string, isPinned: boolean) => void;
};

const BacklinksCard = observer((props: BacklinksCardProps) => {
  const editorType = EditorType.SidePanelEditor;
  const { targetId, documentType, isVisible, isPinned, onDocumentPin } = props;

  const userId = useCurrentUserId();

  const { loading, error, slateId, content, document } = useEditingSession({
    userId,
    editorType,
    documentId: documentType === DocumentType.PAGE ? targetId : null,
    dateId: documentType === DocumentType.NOTE ? targetId : null,
    documentType,
  });

  const cardProps = {
    date: documentType === DocumentType.NOTE && dateIdToDate(targetId),
    title: documentType === DocumentType.PAGE && document?.title,
    isSidePanelEditor: true,
  };

  const handleDocumentPin = useCallback(() => {
    return onDocumentPin(targetId, isPinned);
  }, [onDocumentPin, targetId, isPinned]);

  if (!isVisible) {
    return null;
  }

  if (loading) {
    return <EditorCard {...cardProps} />;
  }

  if (error) {
    console.error(error);
    return (
      <EditorCard {...cardProps}>
        <AppResult resultType="fetching" isCenteredContent={true} />
      </EditorCard>
    );
  }

  const documentId = document.id; // get documentId after session started

  const toolbar = (
    <CardSidePanelToolbar
      href={getEditorPathname(documentType, documentId, document.dateId)}
      isPinned={isPinned}
      onDocumentPin={handleDocumentPin}
    />
  );

  return (
    <div className={styles.cardContentWrapper}>
      <CardNavigationActions
        isBacklinksCard={true}
        pageTitle={getDocumentTitle(document)}
        toolbar={toolbar}
        document={document}
      />
      <DocumentEditor
        slateId={slateId}
        editorType={editorType}
        documentId={documentId}
        documentType={documentType}
        date={
          documentType === DocumentType.NOTE ? dateIdToDate(targetId) : null
        }
        content={content}
        document={document}
      />
    </div>
  );
});

export default BacklinksCard;
