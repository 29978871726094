import { emojiIndex } from "components/slate/plugins/emoji/emojiIndex";

import data from "emoji-mart/data/all.json";

const _getEmojis = (search: string) => {
  const emojis = search ? emojiIndex.search(search.toLowerCase()) : [];

  const emojisArrayWithIds = emojis.length ? emojis.map((i) => i.id) : [];

  const emojisByCategory = data.categories
    .map((category) => {
      const foundEmojis = category.emojis.filter((emoji) =>
        emojisArrayWithIds.includes(emoji)
      );

      if (!foundEmojis.length) {
        return;
      }

      return {
        name: category.name,
        emojis: foundEmojis.sort(),
      };
    })
    .filter((i) => i)
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const joiningArrayWithEmptyValues: (string[] | "empty")[] = [];

  emojisByCategory.forEach((cat) => {
    const restOfItemInRowForMatchNine =
      cat.emojis.length % 9 === 0 ? 0 : 9 - (cat.emojis.length % 9);

    joiningArrayWithEmptyValues.push(cat.emojis);
    for (let i = 1; i <= restOfItemInRowForMatchNine; i++) {
      joiningArrayWithEmptyValues.push("empty");
    }
  });

  const flatEmojis = joiningArrayWithEmptyValues.flat();

  return { emojis: emojisByCategory, flatEmojis };
};

export const getEmojis = (search: any) => {
  if (!search || search.trim() === "") {
    return { emojis: [], flatEmojis: [] };
  }

  return _getEmojis(search);
};
