import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import { TodayIcon } from "thunk-icons";

import SimpleTooltip from "components/SimpleTooltip";
import { AppSearch } from "layout/AppLayout/AppSearch";
import CalendarNavigation from "pages/app/NoteEditingPage/CalendarNavigation";
import SidebarToggle from "components/layout/SidebarToggle";
import SidePanelToggle from "components/layout/SidePanelToggle";
import { useHistoryArrowsValue } from "state/layout";
import { useThemeContext } from "providers/ThemeProvider";
import { useCurrentUserId } from "db/currentUser";

import styles from "./index.module.scss";
import { useStore } from "stores/store";
import { observer } from "mobx-react-lite";
import Breadcrumb from "components/Breadcrumb";

const ElectronHeader = observer(() => {
  const history = useHistory();
  const { pagesStore } = useStore();

  const arrows = useHistoryArrowsValue();
  const activePage = pagesStore.getDocument(pagesStore.activeDocumentId);
  return (
    <div className={styles.electronHeader}>
      <div className={styles.electronHeaderLeft}>
        <SidebarToggle />
        <div className={styles.electronNavButtons}>
          <button
            style={{ opacity: !arrows.hasBackward ? 0.5 : 1 }}
            disabled={!arrows.hasBackward}
            onClick={() => {
              history.goBack();
            }}
          >
            <BsArrowLeftShort size={28} />
          </button>
          <button
            style={{ opacity: !arrows.hasForward ? 0.5 : 1 }}
            disabled={!arrows.hasForward}
            onClick={() => {
              history.goForward();
            }}
          >
            <BsArrowRightShort size={28} />
          </button>
        </div>
        <CalendarNavigation />

        {activePage && (
          <div className={styles.breadcrumb}>
            <Breadcrumb document={activePage} />
          </div>
        )}
      </div>

      <div className={styles.electronHeaderRight}>
        <div className={styles.electronSearch}>
          <AppSearch />
        </div>
        <SimpleTooltip content="Go to Today (Ctrl+1)">
          <NavLink
            className={cn(styles.electronHeaderTodayLink)}
            to={"/writing"}
          >
            <TodayIcon className={styles.todayIcon} size={22}/><div>Today</div>
          </NavLink>
        </SimpleTooltip>
        <NavLink
          className={cn(styles.electronHeaderTodayLinkMobile)}
          to={"/writing"}
        >
          <TodayIcon className={styles.todayIcon} size={22} />
        </NavLink>
        <SidePanelToggle className={styles.sidePanelToggle} />
      </div>
    </div>
  );
});

export default ElectronHeader;
