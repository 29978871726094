import { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Document } from "thunk-core";
import { useStore } from "stores/store";
import { useCurrentUserId } from "db/currentUser";
import { useLoadable } from "hooks/useLoadable";
import ReferenceGroup from "components/editor/DocumentReferences/ReferenceGroup";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import ReferencesSection from "components/editor/DocumentReferences/ReferencesSection";
import { useDocumentSettingsState } from "db/documentsSettings/documentsSettings.hooks";
import { toggleSet } from "utils/toggleCollections";
import { getTargetId } from "db/documents/documents.utils";

import styles from "./index.module.scss";

type Props = {
  isSharing?: boolean;
  document: Document;
  onBacklinksChange?: (referencesDiff: any) => void;
  onBacklinkClick?: (element: BacklinkElement) => void;
};

const DocumentReferences = observer(
  ({
    isSharing = false,
    document,
    onBacklinkClick,
    onBacklinksChange,
  }: Props) => {
    const userId = useCurrentUserId();
    const referenceTargetId = getTargetId(document);
    const documentId = document.id;
    const { blocksStore } = useStore();

    const { t } = useTranslation();

    const [{ loading, error }, loadable] = useLoadable();

    const initialize = async (userId: string, document: Document) => {
      loadable.start();
      try {
        await blocksStore.loadReferences(userId, getTargetId(document));

        loadable.success();
      } catch (error) {
        console.error(error);
        loadable.error(error);
      }
    };

    useEffect(() => {
      initialize(userId, document);
    }, [userId, document]);

    const [
      { data: referencesCollapsed, isLoading: settingsLoading },
      setReferencesCollapsed,
    ] = useDocumentSettingsState(userId, documentId, "referencesCollapsed");

    const handleToggle = useCallback(
      (targetId: string, isOpened: boolean) =>
        setReferencesCollapsed((state) => toggleSet(state, targetId, isOpened)),
      [setReferencesCollapsed]
    );

    if (loading || settingsLoading || error) {
      return null;
    }

    const groupedReferences = blocksStore.getReferences(getTargetId(document));

    if (!groupedReferences.length) {
      return null;
    }

    return (
      <div className={styles.referencesSection}>
        <ReferencesSection
          documentId={document.id}
          title={
            <div className={styles.referencesTitle}>
              {groupedReferences.length} {t("link_page.references_title")}
            </div>
          }
        >
          {({ opened }) => {
            return (
              opened && (
                <div className={styles.refGroupsContainer}>
                  {groupedReferences.map((reference) => {
                    const { document, blocks } = reference;

                    return (
                      <ReferenceGroup
                        key={document.id}
                        referenceTargetId={referenceTargetId}
                        isSharing={isSharing}
                        document={document}
                        blocks={blocks}
                        isOpened={
                          !referencesCollapsed.has(getTargetId(document))
                        }
                        handleToggle={handleToggle}
                        onBacklinkClick={onBacklinkClick}
                        onBacklinksChange={onBacklinksChange}
                      />
                    );
                  })}
                </div>
              )
            );
          }}
        </ReferencesSection>
      </div>
    );
  }
);

export default DocumentReferences;
