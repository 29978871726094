import React from "react";
import { CrossIcon } from "thunk-icons";

import { useDeviceDetector } from "providers/DeviceDetectorProvider";

import styles from "./index.module.scss";

const StreamCloseButton = (props: React.ButtonHTMLAttributes<any>) => {
  const { isMobile, isTablet } = useDeviceDetector();

  return (
    <div className={styles.closeButtonContainer}>
      <button className={styles.closeButton} {...props}>
        <CrossIcon size={26} />
      </button>
      {!(isMobile || isTablet) && (
        <span className={styles.closeButtonTip}>esc</span>
      )}
    </div>
  );
};

export default StreamCloseButton;
