import React from "react";

import PieCheckbox from "framework/components/form/PieCheckbox";

import { useStoreValue } from "state/utils";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { useStaticProps } from "components/slate/hooks/useStaticProps";

import styles from "components/slate/wrapper/index.module.scss";

type Props = {
  elementId: string;
};

const ItemCheckbox = (props: Props) => {
  const { elementId } = props;
  const { slateId } = useStaticProps();

  const isElementSelected = useStoreValue(
    TerminalStore.isElementSelected({ slateId, elementId })
  );

  return (
    <PieCheckbox
      style={{ display: "block" }}
      className={styles.terminalCheckbox}
      progress={isElementSelected ? 100 : 0}
      checked={isElementSelected}
    />
  );
};

export default ItemCheckbox;
