import { v4 as uuidv4 } from "uuid";
import { Editor, Element } from "slate";

import { env } from "thunk-env";
import {
  BacklinkElement,
  BacklinkType,
} from "components/slate/plugins/backlink/types";
import { ReactEditor } from "slate-react";
import { findBacklinkNodes } from "components/slate/utils";
import { isListItemElement } from "components/slate/plugins/list/utils";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { store } from "stores/store";

export const swapIds = (obj: any) => {
  if (!obj.children) {
    return obj;
  }
  if (obj.children.some((c: any) => c.children)) {
    obj.children = obj.children.map((child: any) => swapIds(child));
  }
  if (obj.type === BacklinkType) {
    obj.id = uuidv4();
  }
  return obj;
};

export const isBacklinkElement = (value: any): value is BacklinkElement => {
  return Element.isElementType<BacklinkElement>(value, BacklinkType);
};

export const isThunkLink = (text: string) => {
  try {
    const url = new URL(text);
    let isThunkLink = false;
    if (env.REACT_APP_ENV === "development") {
      isThunkLink =
        url.hostname === "localhost" ||
        url.hostname.includes(env.REACT_APP_HOST);
    } else {
      isThunkLink = url.hostname === env.REACT_APP_HOST;
    }
    const hashString = url.hash.substring(1);
    const [refType, refId] = hashString.split(":");
    return isThunkLink && refType === "block" && refId;
  } catch (e) {
    return false;
  }
};

export const extractSelectedLinks = (editor: Editor) => {
  if (!editor || !ReactEditor.isFocused(editor) || !editor.selection) {
    return [];
  }

  const path = Editor.path(editor, editor.selection, {
    depth: 1,
    edge: "start",
  });
  const [node] = Editor.node(editor, path);

  if (Element.isElement(node)) {
    const backlinks = findBacklinkNodes(node.children);

    let parentBacklinks: BacklinkElement[] = [];
    if (isListItemElement(node)) {
      try {
        const semanticPath = ExtendedEditor.semanticPath(editor, node);

        const backlinks = semanticPath.reduce<BacklinkElement[]>(
          (acc, semanticNode) => {
            return [
              ...acc,
              ...findBacklinkNodes(semanticNode.element.children),
            ];
          },
          []
        );

        return backlinks;
      } catch (error) {}
    } else {
      const backlinks = findBacklinkNodes(node.children);
      return backlinks;
    }

    return [...backlinks, ...parentBacklinks];
  }

  return [];
};

export const setSelectedBacklinks = (editor: Editor) => {
  const links = extractSelectedLinks(editor);
  const { sidePanelStore } = store;

  if (editor.selection && links.length > 0) {
    sidePanelStore.setSelectedBacklinks(links.map((x) => x.targetId));
  }
};
