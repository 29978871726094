import React, { memo, useEffect, useRef } from "react";
import cn from "classnames";
import { Editor, Transforms } from "slate";
import isHotkey from "is-hotkey";
import { isMobile } from "react-device-detect";

import { CheckListItemElement } from "components/slate/plugins/list/types";
import { ElementProps } from "components/slate/types";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlate,
  useSlateStatic,
} from "slate-react";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { checkCheckListItem } from "components/slate/plugins/list/transforms";
import Checkbox from "framework/components/form/Checkbox";
import { appShortcuts } from "appShortcuts";

import styles from "./index.module.scss";

type CheckItemProps = ElementProps & {
  element: CheckListItemElement;
  readOnly: boolean;
};

export const CheckItem = (props: CheckItemProps) => {
  const editor = useSlate();

  const semanticNode = ExtendedEditor.semanticNode(editor, props.element);
  const dimmed = semanticNode.dimmed;

  return <CheckItemMemoized {...props} dimmed={dimmed} />;
};

const CheckItemMemoized = memo(
  (
    props: CheckItemProps & {
      dimmed: boolean;
    }
  ) => {
    const editor = useSlateStatic();
    const focused = useFocused();
    const selected = useSelected();
    const checked = Boolean(props.element.checked);

    const handleCheck = (checked: boolean) => {
      if (props.readOnly) {
        return;
      }

      checkCheckListItem(editor, props.element, checked);

      if (!isMobile) {
        const path = ReactEditor.findPath(editor, props.element);

        // select and focus editor, to activate undo/redo, it also keeps sync editor and checkbox focused state
        Transforms.select(editor, Editor.end(editor, path));
        ReactEditor.focus(editor);
      }
    };

    const keydownListener = useRef<(e: KeyboardEvent) => void | null>(null);
    useEffect(() => {
      keydownListener.current = (e) => {
        if (focused && selected && isHotkey(appShortcuts.toggleTodoItem, e)) {
          handleCheck(!checked);
        }
      };
    });

    useEffect(() => {
      const listener = (e: KeyboardEvent) => keydownListener.current(e);
      window.addEventListener("keydown", listener);

      return () => window.removeEventListener("keydown", listener);
    }, []);

    return (
      <div
        {...props.attributes}
        className={cn(
          styles.listItem,
          styles[`listItem_${props.element.listType}`],
          {
            [styles.checked]: checked || props.dimmed,
          }
        )}
      >
        <span
          data-slate-clipboard-skip={true}
          contentEditable={false}
          className={cn(styles.checkboxPointer)}
        >
          <Checkbox
            checked={checked}
            onChange={(e, checked) => handleCheck(checked)}
          />
        </span>

        <div style={{ display: "flex" }}>
          <div className={styles.listItemText}>{props.children}</div>
        </div>
      </div>
    );
  }
);
