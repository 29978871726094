import { doc, updateDoc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";

import { db } from "firebaseInstance";

export const useHelpPageConfig = () => {
  const result = useDocumentDataOnce(doc(db, `variables/helpDocs`));
  return result;
};

export const setIsHelpSeen = async (userId: any) => {
  return await updateDoc(doc(db, `users/${userId}`), {
    isHelpSeen: true,
  });
};
