import React, { useEffect } from "react";

import ElectronHeader from "components/electron/ElectronLayout/ElectronHeader";

import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode;
};

const ElectronLayout = (props: Props) => {
  const { children } = props;

  useEffect(() => {
    document.body.setAttribute("data-electron-layout", "true");

    return () => {
      document.body.removeAttribute("data-electron-layout");
    };
  }, []);

  return (
    <div className={styles.electronContainer}>
      <ElectronHeader />
      {children}
    </div>
  );
};

export default ElectronLayout;
