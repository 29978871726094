import React from "react";
import { useFocused } from "slate-react";

import styles from "./index.module.scss";

const EditorPlaceholder = () => {
  const focused = useFocused();

  if (focused) {
    return null;
  }

  return <div className={styles.placeholder}>Click here to write...</div>;
};

export default EditorPlaceholder;
