import { Editor, Range, Transforms, Path, Element } from "slate";
import { nanoid } from "nanoid";

import { ExtendedEditor } from "./extendedEditor";
import { FoldingElement } from "./types";
import {
  ParagraphElement,
  ParagraphType,
} from "components/slate/plugins/paragraph/types";
import {
  ListItemElement,
  ListItemType,
  ListTypes,
} from "components/slate/plugins/list/types";
import { makeNodeId } from "components/slate/config/makeNodeId";

export type ExtendedOptions = {
  isContextElement?: (editor: Editor) => ExtendedEditor["isContextElement"];
  compareLevels: (editor: Editor) => ExtendedEditor["compareLevels"];
};

export const withExtended = ({
  isContextElement,
  compareLevels,
}: ExtendedOptions) => <T extends Editor>(e: T) => {
  const editor = e as T & ExtendedEditor;

  const { insertBreak, deleteBackward, normalizeNode } = editor;

  editor.deleteBackward = (unit) => {
    if (editor.selection) {
      const path = Editor.path(editor, editor.selection, { depth: 1 });
      const atStart = Range.includes(
        editor.selection,
        Editor.start(editor, path)
      );

      if (atStart && Path.hasPrevious(path)) {
        const prevEntry = Editor.previous(editor, { at: path })!;

        const node = prevEntry[0] as Element;
        const { hidden } = ExtendedEditor.semanticNode(editor, node);
        const semanticPath = ExtendedEditor.semanticPath(editor, node);

        if (hidden) {
          const start = [semanticPath[0].index];
          const end = prevEntry[1];
          const at = Editor.range(editor, start, end);

          Transforms.setNodes(
            editor,
            { folded: false, foldedCount: 0 },
            {
              at,
              match: (node) =>
                ExtendedEditor.isFoldingElement(editor, node) && !!node.folded,
            }
          );

          Transforms.setNodes(editor, { hash: nanoid(4) } as any, { at });

          deleteBackward(unit);
          return;
        }
      }
    }

    deleteBackward(unit);
  };

  editor.insertBreak = () => {
    const [entry] = Editor.nodes(editor, {
      match: (node, path): node is Element & FoldingElement =>
        path.length === 1 &&
        ExtendedEditor.isFoldingElement(editor, node) &&
        !!node.folded &&
        !!editor.selection &&
        Range.includes(editor.selection, Editor.end(editor, path)),
    });

    if (entry) {
      const [node, path] = entry;

      const index = path[0];
      const skipCount = node.foldedCount || 0;
      const at = [index + skipCount + 1];

      const newNode = ExtendedEditor.isNestingElement(editor, node)
        ? getEmptyListItem({ depth: node.depth })
        : getEmptyParagraph();

      Transforms.insertNodes(editor, newNode, {
        at,
      });
      Transforms.select(editor, Editor.end(editor, at));
      return;
    }

    insertBreak();
  };

  editor.normalizeNode = (entry) => {
    const [node] = entry;

    if (ExtendedEditor.isProgressElement(editor, node)) {
      // sync progress and checked for all ancestors
      const semanticAncestors = ExtendedEditor.semanticAncestors(editor, node);

      for (const ancestor of semanticAncestors) {
        if (ExtendedEditor.isProgressElement(editor, ancestor.element)) {
          const element = ancestor.element;
          const checked = element.checked;

          const semanticNode = ExtendedEditor.semanticNode(editor, element);

          if (semanticNode.progress === 1 && !checked) {
            Transforms.setNodes(
              editor,
              { checked: true },
              { match: (node) => node === element, at: [semanticNode.index] }
            );
          }

          if (semanticNode.progress !== 1 && checked) {
            Transforms.setNodes(
              editor,
              { checked: false },
              { match: (node) => node === element, at: [semanticNode.index] }
            );
          }
        }
      }
    }

    normalizeNode(entry);
  };

  editor.compareLevels = compareLevels(editor);
  editor.isContextElement = isContextElement
    ? isContextElement(editor)
    : () => true;
  editor.isFoldingElement = () => false;
  editor.isNestingElement = () => false;
  editor.isProgressElement = () => false;

  return editor;
};

const getEmptyParagraph = (): ParagraphElement => {
  return {
    id: makeNodeId(true),
    type: ParagraphType,
    children: [
      {
        text: "",
      },
    ],
  };
};

const getEmptyListItem = (
  listItem: Partial<ListItemElement>
): ListItemElement => {
  return {
    id: makeNodeId(true),
    type: ListItemType,
    children: [
      {
        text: "",
      },
    ],
    listType: listItem.listType ?? ListTypes.Bulleted,
    checked: false,
    depth: listItem.depth ?? 0,
  };
};
