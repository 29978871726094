import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReactEditor, useSelected, useSlateStatic } from "slate-react";
import { ImageIcon } from "thunk-icons";
import { Transforms, Element, Editor } from "slate";
import cn from "classnames";

import ImageUploadModal from "components/ImageUploadModal";
import Button from "framework/components/form/Button";
import useMemoObject from "hooks/useMemoObject";
import { ElementProps } from "components/slate/types";
import { ImageElement } from "components/slate/plugins/file/types";

import styles from "./index.module.scss";

type Value = {
  target: Element | null;
  openImageUploader: (element: Element) => void;
  closeImageUploader: () => void;
};

const ImageUploaderContext = createContext<Value>(null as Value);

const EDITOR_TO_IMAGE_UPLOADER = new WeakMap<
  Editor,
  Value["openImageUploader"]
>();

export const openImageUploader = (editor: Editor, element: Element) => {
  const open = EDITOR_TO_IMAGE_UPLOADER.get(editor);
  if (open) {
    open(element);
  }
};

export const ImageUploaderProvider = ({
  editor,
  children,
}: React.PropsWithChildren<{ editor: Editor }>) => {
  const [target, setTarget] = useState<Element | null>(null);

  const openImageUploader = useCallback((target: Element) => {
    setTarget(target);
  }, []);

  const closeImageUploader = useCallback(() => setTarget(null), []);

  useEffect(() => {
    return () => {
      editor && EDITOR_TO_IMAGE_UPLOADER.delete(editor);
    };
  }, []);

  EDITOR_TO_IMAGE_UPLOADER.set(editor, openImageUploader);

  return (
    <ImageUploaderContext.Provider
      value={useMemoObject({
        target,
        openImageUploader,
        closeImageUploader,
      })}
    >
      {children}
    </ImageUploaderContext.Provider>
  );
};

export const ImageUploaderModal = () => {
  const editor = useSlateStatic();
  const { target, closeImageUploader } = useContext(ImageUploaderContext);

  const closeModal = useCallback(
    (urls: string[] | null) => {
      closeImageUploader();

      urls = urls || [];

      if (urls.length) {
        const path = ReactEditor.findPath(editor, target);
        Transforms.setNodes(
          editor,
          {
            url: urls[0],
          },
          { at: path }
        );
      }
    },
    [editor, target]
  );

  return (
    <ImageUploadModal isOpen={target != null} close={closeModal} allowUrls />
  );
};

const ImageUploader = ({
  children,
  element,
}: ElementProps & { element: ImageElement }) => {
  const selected = useSelected();

  const { openImageUploader } = useContext(ImageUploaderContext);

  const openModal = (e: any) => {
    e.preventDefault();
    openImageUploader(element);
  };

  return (
    <div className={styles.root}>
      <div contentEditable={false}>
        <Button
          icon={ImageIcon}
          onClick={openModal}
          variant="secondary"
          btnClass={cn(styles.btn, { [styles.selected]: selected })}
        >
          Click to upload an image
        </Button>
      </div>
      {children}
    </div>
  );
};

export default ImageUploader;
