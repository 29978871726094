import React from "react";

import Modal from "framework/components/Modal";
import Button from "framework/components/form/Button";
import { UseDialogHook } from "hooks/useDialog";
import { ReactComponent as ForkPageImage } from "images/fork_page.svg";
import { ReactComponent as LogoJulianNotesDark } from "images/logo_julian_notes_dark.svg";

import styles from "./index.module.scss";

export type ForkingModalProps = UseDialogHook<
  {},
  "accountExist" | "newAccount"
> & { title: string };

const ForkingModal = (props: ForkingModalProps) => {
  const { title, ...rest } = props;

  return (
    <Modal
      {...rest}
      title={`Edit a copy of "${title}"`}
      titleClass={styles.forkModalTitle}
      contentClass={styles.forkModalContent}
    >
      <div className={styles.forkModalContainer}>
        <ForkPageImage className={styles.forkPageImage} />
        <div className={styles.actions}>
          <Button
            variant="circular"
            size="large"
            onClick={() => rest.close("accountExist")}
          >
            I Have a Thunk Notes Account
          </Button>
          <Button variant="primary" size="large" onClick={() => rest.close("newAccount")}>
            Create Account
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ForkingModal;
