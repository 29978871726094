import React from "react";
import { Link as RRLink } from "react-router-dom";
import cn from "classnames";

import { useThemeContext } from "providers/ThemeProvider";
import { useAuth } from "auth/AuthProvider";
import { createHrefLandingSite } from "routing/utils/redirects";

import logoJulian from "images/logo_julian.svg";
import logoJulianDark from "images/logo_julian_dark.svg";
import logoNigel from "images/logo_nigel.svg";
import logoNigelDark from "images/logo_nigel_dark.svg";

import styles from "./index.module.scss";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

type LogoProps = {
  className?: string;
};

const HeaderLogo = ({ className }: LogoProps) => {
  const { isAuthenticated } = useAuth();

  const { darkMode } = useThemeContext();
  const { isElectron } = useDeviceDetector();

  const content = (
    <>
      <img className={styles.logoJulian} src={logoJulian} alt="logo" />
      <img className={styles.logoJulianDark} src={logoJulianDark} alt="logo" />
      <img className={styles.logoNigel} src={logoNigel} alt="logo" />
      <img className={styles.logoNigelDark} src={logoNigelDark} alt="logo" />
    </>
  );

  if (isAuthenticated) {
    return (
      <RRLink className={cn(styles.logoLink, className)} to="/">
        {content}
      </RRLink>
    );
  }

  return (
    <a
      className={cn(
        styles.logoLink,
        {
          [styles.electronLogo]: isElectron,
        },
        className
      )}
      href={isElectron ? "#" : createHrefLandingSite()}
    >
      {content}
    </a>
  );
};

export default HeaderLogo;
