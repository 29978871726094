import React, { PropsWithChildren } from "react";
import { QueryClientProvider } from "react-query";

import { queryClient } from "db/queryClient";

const ReactQueryProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default ReactQueryProvider;
