import React, { useContext } from "react";

import { SharedBacklink, SharedTag } from "pages/other/SharingPage";

type Value = null | {
  pagesMap: Record<string, SharedBacklink>;
  tagsMap: Record<string, SharedTag>;
};

const SharingContext = React.createContext<Value>(null);

export function useSharingContext(): Value {
  return useContext(SharingContext);
}

export const SharingProvider = ({
  pagesMap,
  tagsMap,
  children,
}: Value & { children: React.ReactNode }) => {
  return (
    <SharingContext.Provider
      value={{
        pagesMap,
        tagsMap,
      }}
    >
      {children}
    </SharingContext.Provider>
  );
};
