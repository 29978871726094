import {
  FirestoreDataConverter,
  getDocs,
  Query,
  Timestamp,
  Transaction,
  DocumentReference,
  SetOptions,
  UpdateData,
  getDoc,
} from "firebase/firestore";
import { addNotification } from "hooks/useNotifications";
import { FirestoreErrorCodes } from "db/constants";

import { makeDateId } from "helpers";
import { DocumentType } from "thunk-core";

export const getDocumentData = async <T>(
  ref: DocumentReference<T>
): Promise<T | null> => {
  const snapshot = await getDoc(ref);

  if (!snapshot.exists()) {
    return null;
  }

  return snapshot.data();
};

export const getFirstDocumentData = async <T>(
  q: Query<T>
): Promise<T | null> => {
  const snapshot = await getDocs(q);

  if (!snapshot.empty) {
    return snapshot.docs[0].data();
  }

  return null;
};

export const getCollectionData = async <T>(q: Query<T>): Promise<T[]> => {
  const snapshot = await getDocs(q);
  return snapshot.docs.map((snapshot) => snapshot.data());
};

export const getTransactionData = async <T>(
  trx: Transaction,
  docRef: DocumentReference,
  converter: FirestoreDataConverter<T>
) => {
  const snapshot = await trx.get(docRef.withConverter(converter));
  return snapshot.exists() ? snapshot.data() : null;
};

export const setTransaction = async <T>(
  trx: Transaction,
  docRef: DocumentReference,
  item: Partial<T>,
  options: SetOptions
) => {
  return await trx.set(docRef, item, options);
};

export const updateTransaction = async <T>(
  trx: Transaction,
  docRef: DocumentReference,
  updates: UpdateData<T>,
  converter: FirestoreDataConverter<T>
) => {
  return await trx.update(docRef.withConverter(converter), updates);
};

export const deleteTransaction = async (
  trx: Transaction,
  docRef: DocumentReference
) => {
  return await trx.delete(docRef);
};

export const toNullableDate = (timestamp: Timestamp | null): Date | null => {
  if (timestamp == null) {
    return null;
  }

  return toDate(timestamp);
};

export const toFormattedDateString = (
  timestamp: Timestamp | null
): string | null => {
  if (timestamp == null) {
    return null;
  }

  return makeDateId(toDate(timestamp));
};

export const toDate = (timestamp: Timestamp): Date => {
  return timestamp.toDate();
};

export const getItemFirestorePath = (
  type: DocumentType,
  userId: string,
  itemId: string
) => {
  type = type || DocumentType.PAGE;

  switch (type) {
    case DocumentType.PAGE: {
      return `pages/${itemId}`;
    }
    case DocumentType.NOTE: {
      return `notes/${itemId}`;
    }
    case DocumentType.SNIPPET: {
      return `templates/${itemId}`;
    }
    default: {
      return "";
    }
  }
};

// Api error handler
export const handleApiError = (error: any, message: string) => {
  console.error(error);
  if (error.code) {
    switch (error.code) {
      case FirestoreErrorCodes.PERMISSION_DENIED:
        addNotification({
          duration: 2500,
          type: "error",
          text: "Unauthorized",
        });
        return;
    }
  }

  addNotification({
    duration: 2500,
    type: "error",
    text: message,
  });
};
