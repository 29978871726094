import React from "react";
import { useLocation } from "react-router-dom";

import { HelpBreadcrumbs } from "layout/AppLayout/AppHelp/HelpPageContainer";
import FeedbackFormWidget from "components/feedback/FeedbackFormWidget";

const HelpFeedback = () => {
  const location = useLocation();

  return (
    <>
      <HelpBreadcrumbs location={location} />
      <FeedbackFormWidget />
    </>
  );
};

export default HelpFeedback;
