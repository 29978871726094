import React from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
  id?: string;
  style?: React.CSSProperties;
  className?: string;
  checked?: boolean;
  label?: React.ReactNode;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent, checked: boolean) => void;
};

const Checkbox = ({
  id,
  style,
  className,
  checked,
  label,
  disabled = false,
  onChange,
}: Props) => {
  return (
    <label
      style={style}
      className={cn(styles.checkWrapper, className, {
        [styles.withLabel]: label != null,
      })}
    >
      <input
        id={id}
        className={styles.checkInput}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => onChange(e, e.target.checked)}
      />
      <svg
        overflow="visible"
        className={styles.checkSvg}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className={styles.checkCircle} cx="8" cy="8" r="8" />
        <path
          className={styles.checkShadow}
          d="M0 8C0 4.22876 0 2.34315 1.17157 1.17157C2.34315 0 4.22876 0 8 0V0C11.7712 0 13.6569 0 14.8284 1.17157C16 2.34315 16 4.22876 16 8V8C16 11.7712 16 13.6569 14.8284 14.8284C13.6569 16 11.7712 16 8 16V16C4.22876 16 2.34315 16 1.17157 14.8284C0 13.6569 0 11.7712 0 8V8Z"
        />
        <path
          className={styles.checkRect}
          d="M0 8C0 4.22876 0 2.34315 1.17157 1.17157C2.34315 0 4.22876 0 8 0V0C11.7712 0 13.6569 0 14.8284 1.17157C16 2.34315 16 4.22876 16 8V8C16 11.7712 16 13.6569 14.8284 14.8284C13.6569 16 11.7712 16 8 16V16C4.22876 16 2.34315 16 1.17157 14.8284C0 13.6569 0 11.7712 0 8V8Z"
          fill="#D9D9D9"
        />
        <polyline
          className={styles.checkMark}
          stroke-width="2"
          points="3.52301 8.30512 6.60555 11.3921 12.5621 4.91168"
        />
      </svg>
      {label && <span style={{ marginLeft: 6 }}>{label}</span>}
    </label>
  );
};

export default Checkbox;
