import * as handlers from "./handlers";
import { UseSlatePlugin } from "components/slate/types";

const useExitBreakPlugin: UseSlatePlugin = () => {
  return {
    handlers,
  };
};

export default useExitBreakPlugin;
