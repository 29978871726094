import React from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Alert from "framework/components/Alert";
import TextInput from "framework/components/form/TextInput";
import Button from "framework/components/form/Button";
import { passwordMinLength } from "constants/validation";

//  @ts-ignore ts-migrate(2307) FIXME: Cannot find module './index.module.scss' or its co... Remove this comment to see the full error message
import styles from "./index.module.scss";

const NewPasswordForm = ({
  onSubmit,
  submitting,
  submitError,
  successMessage,
  defaultValues = {},
}: any) => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues,
  });

  const { t } = useTranslation();

  return (
    <form
      className={styles.newPasswordForm}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <h3>{t("new_password_form.title")}</h3>

      {submitError && (
        //  @ts-ignore ts-migrate(2322) FIXME: Type '{ children: any; status: string; code: any; ... Remove this comment to see the full error message
        <Alert status="error" code={submitError.code}>
          {submitError.message}
        </Alert>
      )}
      {successMessage && <Alert status="error">{successMessage}</Alert>}

      <TextInput
        type="password"
        error={errors.password?.message}
        name="password"
        placeholder="**********"
        labelText={t("new_password_form.input.password.label")}
        //  @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
        inputRef={register({
          required: t("new_password_form.input.password.validation.required"),
          minLength: {
            value: passwordMinLength,
            message: t(
              "new_password_form.input.password.validation.minLength",
              { value: passwordMinLength }
            ),
          },
        })}
      />
      <Button
        disabled={submitting}
        loading={submitting}
        variant="secondary"
        type="submit"
      >
        {t("new_password_form.buttons.submit")}
      </Button>
    </form>
  );
};

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  submitting: PropTypes.bool,
  submitError: PropTypes.string,
  successMessage: PropTypes.string,
};

export default NewPasswordForm;
