import { useState } from "react";
import { Editor } from "slate";

import {
  CodeBlockType,
  CodeLineType,
} from "components/slate/plugins/codeBlock/types";

const usePlateEditorPlugins = (_editor: Editor) => {
  const editor = _editor as any;
  useState(() => {
    editor.pluginsByKey = {
      code_block: {
        type: CodeBlockType,
        options: {
          syntax: true,
        },
      },
      code_line: {
        type: CodeLineType,
      },
    };
    editor.plugins = Object.entries(editor.pluginsByKey).map((x) => x[1]);
  });
};

export default usePlateEditorPlugins;
