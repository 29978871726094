import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSlateStatic } from "slate-react";
import { useResizeDetector } from "react-resize-detector";
import { Transforms } from "slate";
import { descend, sortBy, sortWith } from "ramda";
import { observer } from "mobx-react-lite";

import { HashMenuProps } from "components/slate/plugins/menus/hashMenu/types";
import RangePopper from "components/slate/components/RangePopper";
import SectionMenuList from "components/slate/plugins/menus/hashMenu/components/SectionMenuList";
import SectionMenuListItem from "components/slate/plugins/menus/hashMenu/components/SectionMenuListItem";
import SectionMenuListHeader from "components/slate/plugins/menus/hashMenu/components/SectionMenuListHeader";
import {
  HashMenuItem,
  makeTagMenuItem,
  highlightMatch,
  makeNewTagMenuItem,
} from "components/slate/plugins/menus/hashMenu/items/utils";
import { useCurrentUserId } from "db/currentUser";
import { useStaticProps } from "components/slate/hooks/useStaticProps";
import { useStore } from "stores/store";

import styles from "components/slate/plugins/menus/hashMenu/components/HashMenu.module.scss";
import { Document, DocumentType, Tag } from "thunk-core";

const MaxItems = 10;

const HashMenu = memo((props: HashMenuProps) => {
  const { initialTarget, target, closeMenu } = props;
  const [height, setHeight] = useState(0);
  const { documentId, documentType } = useStaticProps();

  const editor = useSlateStatic();

  const handleSelect = useCallback(
    (e: Event, item: HashMenuItem) => {
      Transforms.delete(editor, { at: target });
      Transforms.select(editor, editor.selection);
      item.applyInEditor({ editor });
      closeMenu();
    },
    [editor, target, closeMenu]
  );

  return (
    <RangePopper
      isVisible={initialTarget != null}
      range={initialTarget}
      className={styles.menuTooltip}
      style={{ height: height + 10 }}
    >
      {initialTarget != null && (
        <HashMenuContent
          documentId={documentId}
          documentType={documentType}
          isOpened={props.initialTarget != null}
          search={props.search}
          closeMenu={props.closeMenu}
          onSelect={handleSelect}
          onHeightChange={setHeight}
        />
      )}
    </RangePopper>
  );
});

export default HashMenu;

export const HashMenuContent = observer(
  <T,>(props: {
    documentId: string;
    documentType: DocumentType;
    isOpened: boolean;
    search: string;
    closeMenu: () => void;
    onSelect: (e: KeyboardEvent, item: T) => void;
    onHeightChange?: (height: number) => void;
  }) => {
    const { height = 0, ref: containerRef } = useResizeDetector();
    const {
      documentId,
      documentType,
      isOpened,
      onSelect,
      search,
      closeMenu,
      onHeightChange,
    } = props;
    const userId = useCurrentUserId();

    const { tagsStore } = useStore();
    const tags = tagsStore.getTags();
    const isLoading = tagsStore.loading;
    const isError = tagsStore.error;

    const filteredTags = useMemo(() => {
      if (!tags) {
        return null;
      }

      if (!search) {
        return tags.slice(0, MaxItems);
      }

      const sorted = tags;

      const result: Tag[] = [];
      for (const tag of sorted) {
        if (tag.title && highlightMatch(tag.title, search).length) {
          result.push(tag);
        }

        if (result.length >= MaxItems) {
          return result;
        }
      }

      return result;
    }, [tags, search]);

    useEffect(() => {
      onHeightChange && onHeightChange(height);
    }, [height, onHeightChange]);

    const handleClose = useCallback(() => {
      closeMenu();
    }, [closeMenu]);

    const sections = useMemo(() => {
      const tagExists = tags?.find(
        (b) => b.title.toLowerCase() === search.toLowerCase()
      );

      const doShowTags = filteredTags && filteredTags.length > 0;

      const doShowNewTagMenuItem = search && !isLoading && !tagExists;

      const sections = [
        doShowTags && {
          sort: 0,
          title: "Add a tag",
          items: sortBy(
            (x) => x.text,
            filteredTags.map((tag) =>
              makeTagMenuItem({
                text: tag.title,
                hint: "",
                documentId,
                documentType,
                tag,
              })
            )
          ),
        },
        doShowNewTagMenuItem && {
          sort: 0,
          title: "Create tag & Add",
          items: [
            makeNewTagMenuItem({
              text: search,
              hint: `#${search}`,
              documentId,
              documentType,
              userId,
              title: search,
            }),
          ],
        },
      ].filter(Boolean);

      return sortBy((x) => x.sort, sections);
    }, [search, isLoading, filteredTags]);

    return (
      <SectionMenuList
        containerRef={containerRef}
        isOpened={isOpened}
        sections={sections}
        renderListItem={({ item, isActive }) => (
          <SectionMenuListItem
            search={search}
            item={item}
            isActive={isActive}
            onSelect={onSelect}
          />
        )}
        renderSectionHeader={({ section }) => (
          <SectionMenuListHeader section={section} />
        )}
        onSelect={onSelect}
        onCancel={handleClose}
      />
    );
  }
);

const sortDocuments = (search: string, documents: Document[]) =>
  sortWith(
    [
      descend<Document>(
        (b) => b.title?.toLowerCase() === search?.toLowerCase()
      ),
    ],
    documents
  );
