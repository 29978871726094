import React from "react";

import { Template } from "db/types";
import TemplatesGrid from "pages/app/TemplatesOverviewPage/TemplatesGrid";
import { TemplatePreviewCard } from "pages/app/TemplatesOverviewPage/TemplatePreviewCard";
import { ReactComponent as CreateTemplateResultImage } from "images/create-template-result.svg";
import Result from "framework/components/Result";

import styles from "./index.module.scss";
import { Document } from "thunk-core";

type UserTemplatesSectionProps = {
  title: string;
  templates: Document[];
};

const UserTemplatesSection = ({
  title,
  templates,
}: UserTemplatesSectionProps) => {
  if (templates.length === 0) {
    return (
      <div className={styles.section}>
        {/*<h3 className={styles.title}>{title}</h3>*/}
        <Result
          image={
            <CreateTemplateResultImage style={{ width: 270, height: 270 }} />
          }
          text={
            <span className={styles.message}>Create your first template.</span>
          }
        />
      </div>
    );
  }

  return (
    <div className={styles.section}>
      {/*<h3 className={styles.title}>{title}</h3>*/}
      <TemplatesGrid
        items={templates}
        renderGridItem={({ item }) => (
          <TemplatePreviewCard template={item} path={`/snippets/${item.id}`} />
        )}
      />
    </div>
  );
};

export default UserTemplatesSection;
