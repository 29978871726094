import { Editor, Element, Range, Transforms } from "slate";
import React, { Fragment, memo, MutableRefObject } from "react";
import { Transform } from "@dnd-kit/utilities";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import cn from "classnames";
import { isIOS, isMobile } from "react-device-detect";
import {
  ReactEditor,
  useReadOnly,
  useSelected,
  useSlateStatic,
} from "slate-react";

import { DragHandle } from "components/slate/wrapper/DragHandle";
import { Placeholder } from "components/slate/wrapper/Placeholder";
import { FoldingArrow } from "components/slate/wrapper/FoldingArrow";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { isEmptyNode } from "components/slate/utils";
import { isCodeBlockElement } from "components/slate/plugins/codeBlock/utils";
import FoldingLine from "components/slate/wrapper/FoldingLine";
import { ElementProps } from "components/slate/types";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { useStoreValue } from "state/utils";
import LineNumber from "components/slate/wrapper/LineNumber";
import ItemCheckbox from "components/slate/wrapper/ItemCheckbox";

import styles from "components/slate/wrapper/index.module.scss";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

export type ItemProps = {
  children: React.ReactNode;
  element: Element;
  elementRef: MutableRefObject<HTMLElement | null>;
  renderElement?: (props: ElementProps) => JSX.Element;

  semanticChildrenCount: number;
  isInViewport?: boolean;
  onFold?: React.MouseEventHandler;

  // sortable props
  transition?: string | null;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  isDragging?: boolean;
  isSorting?: boolean;
};

const ItemComponent = ({
  children,
  element,
  elementRef,
  renderElement,

  semanticChildrenCount,
  isInViewport,
  onFold,

  transition,
  transform,
  listeners,
  isDragging = false,
  isSorting = false,
}: ItemProps) => {
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const selected = useSelected();
  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);
  const terminalActions = TerminalStore.useTerminalActions();

  const { isMobileScreen } = useDeviceDetector();

  const doRenderHandle = !readOnly && isInViewport;
  const doRenderFoldingArrow =
    isInViewport && ExtendedEditor.isFoldingElement(editor, element);
  const doRenderFoldingLine =
    ExtendedEditor.isFoldingElement(editor, element) &&
    ExtendedEditor.isNestingElement(editor, element);
  const doRenderPlaceholder =
    !readOnly &&
    isInViewport &&
    selected &&
    editor.selection &&
    Range.isCollapsed(editor.selection) &&
    isEmptyNode(element);

  const itemListeners =
    (isMobile && isIOS) || isTerminalActive ? listeners : null;

  return (
    <Fragment>
      {doRenderHandle && (
        <DragHandle
          elementRef={elementRef}
          listeners={listeners}
          elementId={element.id}
        />
      )}
      {doRenderPlaceholder && <Placeholder element={element} />}
      <div
        {...itemListeners}
        data-slate-clipboard-skip-linebreak={true}
        className={cn(styles.item, {
          [styles.dragging]: isDragging,
          [styles.disableInteraction]: isSorting,
          [styles.noPadding]: isCodeBlockElement(element),
        })}
        onDoubleClick={(e) => {
          if (isTerminalActive) {
            e.preventDefault();
            terminalActions.closeTerminal();

            ReactEditor.focus(editor);
            Transforms.select(editor, Editor.start(editor, editor.selection)); // Editor.start - start because of double click
          }
        }}
        style={
          {
            transition,
            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,
          } as React.CSSProperties
        }
      >
        <div data-slate-clipboard-skip={true} className={styles.indicator} />
        {doRenderFoldingArrow && (
          <FoldingArrow element={element} onFold={onFold} />
        )}
        {doRenderFoldingLine && (
          <FoldingLine
            element={element}
            semanticChildrenCount={semanticChildrenCount}
            onFold={onFold}
            transform={transform}
          />
        )}
        {isTerminalActive && isMobileScreen && (
          <ItemCheckbox elementId={element.id} />
        )}
        {/*{isTerminalActive && <LineNumber element={element} />}*/}
        {renderElement({
          element,
          children,
          listeners,
        })}
      </div>
    </Fragment>
  );
};

export const Item = memo(ItemComponent, (prev, next) => {
  for (const key of [...Object.keys(prev), ...Object.keys(next)] as [
    keyof ItemProps
  ]) {
    if (key === "children" || key === "listeners") {
      continue;
    }

    if (key === "transition") {
      if (
        (prev[key] == null && next[key]?.includes("0ms")) ||
        (next[key] == null && prev[key]?.includes("0ms"))
      ) {
        continue;
      }
    }

    if (prev[key] !== next[key]) {
      return false;
    }
  }

  return true;
});
