import { Editor, Transforms } from "slate";

import { CodeMarkType } from "components/slate/plugins/marks/types";

const resetMark = (editor: Editor, mark: string) => {
  const { selection } = editor;
  if (selection) {
  }

  Transforms.select(editor, {
    anchor: selection.focus,
    focus: {
      ...selection.focus,
      offset: selection.focus.offset - 1,
    },
  });
  Editor.removeMark(editor, mark);
  Transforms.collapse(editor);
};

export const insertDate = (editor: Editor, text: string) => {
  const node = { text, [CodeMarkType]: true };
  Transforms.insertNodes(editor, node);
  Transforms.insertText(editor, " ");

  resetMark(editor, CodeMarkType);
};
