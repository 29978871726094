import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";

import { useDocumentsStore, useStore } from "stores/store";
import UpdateTagMenu from "components/tags/UpdateTagMenu";
import Tag from "framework/components/Tag";

import styles from "./index.module.scss";
import { Document, Tag as TagDb } from "thunk-core";

type Props = {
  document: Document;
};

const DocumentTags = observer((props: Props) => {
  const { document } = props;

  const documentsStore = useDocumentsStore(document.type);

  const { tagsStore } = useStore();
  const loading = tagsStore.loading;

  const handleRemove = useCallback(
    (tagId: string) => {
      documentsStore.removeTag(document.id, tagId);
    },
    [document]
  );

  const tags = document.tags
    .map((tagId) => toJS(tagsStore.tags.get(tagId)))
    .filter(Boolean);

  if (loading) {
    return null;
  }

  return (
    <div>
      <div className={styles.tags}>
        {tags.map((tag) => (
          <DocumentTag key={tag.id} tag={tag} handleRemove={handleRemove} />
        ))}
      </div>
      {tags.length > 0 && <hr className={styles.divider} />}
    </div>
  );
});

export default DocumentTags;

export const DocumentTag = observer(
  ({
    tag,
    readOnly,
    handleRemove,
  }: {
    tag: TagDb;
    readOnly?: boolean;
    handleRemove?: (tagId: string) => void;
  }) => {
    if (!tag) {
      return null;
    }

    return (
      <UpdateTagMenu tag={tag}>
        {({ ref }) => (
          <Tag
            containerRef={ref}
            readOnly={readOnly}
            color={tag.color}
            variant={tag.variant}
            emoji={tag.emoji}
            onRemove={() => handleRemove(tag.id)}
          >
            {tag?.title}
          </Tag>
        )}
      </UpdateTagMenu>
    );
  }
);
