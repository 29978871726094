import React from "react";

import { BacklinkMenuProps } from "../types";

import AtMenu from "components/slate/plugins/menus/atMenu/components/AtMenu";

const BacklinkMenu = (props: BacklinkMenuProps) => {
  return <AtMenu {...props} />;
};

export default BacklinkMenu;
