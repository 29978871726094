import React, { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "framework/components/form/Button";

import styles from "./index.module.scss";

export const EditableFieldActions = ({
  isEditing,
  onCancelClick,
  onEditClick,
}: any) => {
  const { t } = useTranslation();

  return isEditing ? (
    <div className={styles.actions}>
      <Button key="cancel" onClick={onCancelClick}>
        {t("editable_field.actions.cancel")}
      </Button>
      <Button key="save" type="submit" variant="secondary">
        {t("editable_field.actions.save")}
      </Button>
    </div>
  ) : (
    <Button key="edit" onClick={onEditClick}>
      {t("editable_field.actions.edit")}
    </Button>
  );
};

export function useEditableField({
  name,
  defaultValue,
  onSubmit,
  registerParams,
}: any) {
  const inputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  const form = useForm({
    defaultValues: { [name]: defaultValue },
  });
  const { register, setValue, reset } = form;

  async function submit(state: any) {
    setIsEditing(false);
    if (state[name] !== defaultValue) {
      const error = await onSubmit(state[name], form);

      if (error) {
        error && setIsEditing(true);
      } else {
        //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
        inputRef.current && inputRef.current.blur();
      }
    }
  }

  function setRef(ref: any) {
    //  @ts-ignore ts-migrate(2349) FIXME: This expression is not callable.
    register(registerParams)(ref);
    inputRef.current = ref;
  }

  function cancel() {
    setValue(name, defaultValue);
    setIsEditing(false);
  }

  function handleKeyDown(e: any) {
    if (e.key === "Escape") {
      cancel();
      e.target.blur();
      e.stopPropagation(); // for modal
    }
  }

  useEffect(() => {
    isEditing &&
      inputRef.current &&
      //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      inputRef.current.type !== "radio" &&
      //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
      inputRef.current.focus();
  }, [isEditing]);

  const actions = (
    <EditableFieldActions
      isEditing={isEditing}
      onCancelClick={cancel}
      onEditClick={() => setIsEditing(true)}
    />
  );

  return {
    isEditing,
    setIsEditing,
    form,
    submit,
    setRef,
    cancel,
    actions,
    handleKeyDown,
  };
}

export default useEditableField;
