import { Editor } from "slate";

import {
  makeNoteBacklink,
  makePageBacklink,
} from "db/backlinks/backlinks.mapping";
import { insertBacklink } from "./transforms";
import { isBacklinkElement, isThunkLink, swapIds } from "./utils";
import { store } from "stores/store";

const withBacklink = (userId?: string) => (editor: Editor) => {
  const { insertFragment, insertData, isInline, isVoid } = editor;

  editor.insertFragment = (fragment: any) => {
    /**
     * If someone copies text from an editor that contains links
     * we need to give the pasted links new IDs and source information
     */
    const withReplacedLinkIds = swapIds({ children: fragment });
    insertFragment(withReplacedLinkIds.children);
  };

  editor.insertData = (data: any) => {
    const text = data.getData("text/plain");

    if (isThunkLink(text)) {
      const url = new URL(text);
      const hashString = url.hash.substring(1);
      const refId = hashString.split(":")[1];
      // In case text is a link that include ":"
      // join after block id with : (ex. text = https://www.youtube.com/watch?v=blahblah)
      const blockText = hashString.split(":").slice(2).join(":");
      const dateId = url.searchParams.get("date");
      const pointsToPage = !dateId;
      let initialText = "";
      let documentId = "";
      if (pointsToPage) {
        documentId = url.pathname.substring("/page/".length);
        const { pagesStore } = store;
        const doc = pagesStore.getDocument(documentId);
        initialText = doc ? doc.title : "";
      }
      const elemToInsert = pointsToPage
        ? makePageBacklink(userId, {
            documentId,
            initialText,
            targetBlockId: refId,
            blockText: blockText ? decodeURIComponent(blockText) : null,
          })
        : makeNoteBacklink(userId, {
            dateId,
            targetBlockId: refId,
            blockText: blockText ? decodeURIComponent(blockText) : null,
          });
      insertBacklink(editor, elemToInsert);
      return;
    }

    insertData(data);
  };

  editor.isInline = (element) =>
    isBacklinkElement(element) || isInline(element);
  editor.isVoid = (element) => isBacklinkElement(element) || isVoid(element);

  return editor;
};

export { withBacklink };
