import { doc, updateDoc } from "firebase/firestore";

import { db } from "firebaseInstance";

export const setSectionOpened = async (
  userId: any,
  sectionId: any,
  isOpened: any
) => {
  try {
    await updateDoc(doc(db, `users/${userId}`), {
      [`sidebarSections.${sectionId}`]: isOpened,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
