import React, { useEffect, useState, useRef, forwardRef } from "react";
import { CategoryProps, Emoji, EmojiData } from "emoji-mart";
import cn from "classnames";

import { emojiIndex } from "components/slate/plugins/emoji/emojiIndex";
import { EmojiPluginState } from "components/slate/plugins/emoji/state";

import styles from "./index.module.scss";

const CustomEmojiPicker = ({
  search,
  addEmoji,
  emojis,
  flatEmojis,
  valueIndex,
  curRow,
}: Partial<EmojiPluginState> & { addEmoji: (emoji: EmojiData) => void }) => {
  const currentItemRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (containerRef.current && currentItemRef.current) {
      const offsetTop =
        currentItemRef.current.offsetTop <= 128
          ? 90
          : currentItemRef.current.offsetTop;

      containerRef.current.scrollTo({
        top: offsetTop - 90,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [currentItemRef, containerRef]);

  return (
    <div className={cn(styles.emojiMartEmoji)} ref={containerRef}>
      {emojis && emojis.length > 0 ? (
        emojis.map((category, i) => (
          <EmojisWithCategory
            ref={currentItemRef}
            key={i}
            category={category}
            handleClick={addEmoji}
            search={search}
            valueIndex={valueIndex}
            flatEmojis={flatEmojis}
          />
        ))
      ) : (
        <p className={cn(styles.emojiMartCategoryName)}>No Results.</p>
      )}
    </div>
  );
};

type EmojisWithCategoryProps = {
  category: CategoryProps;
  handleClick: (emoji: EmojiData) => void;
  flatEmojis: any[];
  search: string;
  valueIndex: number;
};

const EmojisWithCategory = forwardRef<HTMLDivElement, EmojisWithCategoryProps>(
  ({ category, handleClick, flatEmojis, valueIndex }, ref) => {
    const [selectedImo, setSelectedImo] = useState(null);

    useEffect(() => {
      if (flatEmojis) {
        setSelectedImo(flatEmojis[valueIndex]);
      }
    }, [valueIndex, flatEmojis]);

    const onHandleClick = (emoji: string) => {
      const pickedEmoji = emojiIndex.search(emoji)[0];
      handleClick(pickedEmoji);
    };

    return (
      <div className={cn(styles.emojiMartCategoryRow)}>
        <p className={cn(styles.emojiMartCategoryName)}>{category.name}</p>
        <div className={cn(styles.emojiMartSingleEmojiWarpper)}>
          {category.emojis.map((emoji: any) => (
            <div
              key={emoji}
              className={`${cn(styles.emojiItem)} ${
                selectedImo === emoji ? cn(styles.active) : null
              }`}
              onClick={() => onHandleClick(emoji)}
              ref={selectedImo === emoji ? ref : undefined}
            >
              <Emoji tooltip native emoji={emoji} size={25} />
            </div>
          ))}
        </div>
      </div>
    );
  }
);
export default CustomEmojiPicker;
