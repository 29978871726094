import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import cn from "classnames";
import { GenericFileIcon } from "thunk-icons";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ReactEditor, useSlateStatic } from "slate-react";
import { BeatLoader } from "react-spinners";

import { storage } from "firebaseInstance";
import { addNotification } from "hooks/useNotifications";
import { useCurrentUserId } from "db/currentUser";
import {
  acceptedFileMimeTypes,
  getFileExtension,
  MAX_SIZE_FILE_MB,
} from "../../utils";
import { FileElement } from "../../types";
import { getErrorInfoForFiles } from "../../errors";
import { updateFileElement } from "../../transforms";

import styles from "./FileUploadComponent.module.scss";
import { getFilePath } from "thunk-core";

const defaultFileValidator = (file: any) => {
  if (file.size > MAX_SIZE_FILE_MB * 1024 * 1024) {
    return {
      code: "file-too-big",
      message: `File must be less than ${MAX_SIZE_FILE_MB}MB`,
    };
  }
};

const DEFAULT_ACCEPT = acceptedFileMimeTypes;

export const FileUploadComponent = ({ element }: { element: FileElement }) => {
  const userId = useCurrentUserId();
  const [uploading, setUploading] = useState(false);
  const editor = useSlateStatic();

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length === 1) {
        const file = acceptedFiles[0];
        const fileId = element.id;
        const ext = getFileExtension(file.name);
        const extPath = ext ? `.${ext}` : "";
        const path = getFilePath(userId, fileId, extPath);

        setUploading(true);

        try {
          const fileRef = ref(storage, path);
          const snapshot = await uploadBytes(fileRef, file);
          const url = await getDownloadURL(snapshot.ref);
          const elPath = ReactEditor.findPath(editor, element);
          updateFileElement(
            editor,
            {
              url,
              fileName: file.name,
              fileSize: file.size,
              extension: ext,
              children: [{ text: file.name }],
            },
            elPath
          );
        } catch (error) {
          console.error(error);
          setUploading(false);
          const errorInfo = getErrorInfoForFiles(error);

          addNotification({
            duration: 2500,
            type: "error",
            text: errorInfo.message,
          });
          throw error;
        }
      }

      if (rejectedFiles.length > 0) {
        const codes = {};
        const uniqMessages = [];
        rejectedFiles.forEach((rejection) => {
          rejection.errors?.forEach((error) => {
            if (!codes[error.code]) {
              codes[error.code] = true;
              uniqMessages.push(error.message);
            }
          });
        });
        uniqMessages.forEach((message) => {
          addNotification({
            text: message,
            type: "error",
            duration: 5000,
          });
        });
      }
    },
    [editor, element, userId]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    noDragEventsBubbling: true,
    accept: DEFAULT_ACCEPT,
    maxFiles: 1,
    validator: defaultFileValidator,
  });

  const className = useMemo(
    () =>
      cn(
        styles.dropzone,
        { [styles.active]: isDragActive },
        { [styles.accepted]: isDragAccept },
        { [styles.rejected]: isDragReject }
      ),
    [isDragActive, isDragReject, isDragAccept]
  );

  if (uploading) {
    return (
      <div
        style={{ textAlign: "center", width: "100%", padding: "15px 0 10px" }}
      >
        <BeatLoader color="var(--blue-color)" />
      </div>
    );
  }

  return (
    <div {...getRootProps({ className })}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className={styles.dropzoneText}>
          <GenericFileIcon size={32} />
          <p>Drop a file here ...</p>
        </div>
      ) : (
        <div className={styles.dropzoneText}>
          <GenericFileIcon size={32} />
          <p>Drop a file here, or click to select a file</p>
        </div>
      )}
    </div>
  );
};
