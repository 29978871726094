import { useState } from "react";
import { Editor, Element } from "slate";

import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { SemanticNode } from "components/slate/slate-extended/types";

export const useDragOverlayHeight = (
  editor: Editor,
  activeElement: Element,
  semanticNode: SemanticNode,
  selectedIds: Set<string>,
  lineHeight: number
) => {
  const [_dragOverlayHeight, setDragOverlayHeight] = useState<number | null>(
    null
  );

  const descendantsCount =
    semanticNode &&
    ExtendedEditor.isFoldingElement(editor, activeElement) &&
    ExtendedEditor.isNestingElement(editor, activeElement) &&
    !activeElement.folded
      ? semanticNode.descendants.filter((x) => !x.hidden).length
      : 0;
  const length = Math.max(descendantsCount, selectedIds.size) + 1;

  const minOverlayHeight = length * lineHeight;

  const dragOverlayHeight =
    length > 1
      ? _dragOverlayHeight == null
        ? minOverlayHeight
        : Math.max(minOverlayHeight, _dragOverlayHeight)
      : null;

  return { dragOverlayHeight, setDragOverlayHeight };
};
