import { Descendant, Element } from "slate";
import { action, makeObservable, observable, runInAction } from "mobx";
import { clone, equals, splitEvery, uniq } from "ramda";
import {
  doc,
  onSnapshot,
  query,
  collection,
  Timestamp,
  Unsubscribe,
  where,
  writeBatch,
  WriteBatch,
} from "firebase/firestore";
import md5 from "crypto-js/md5";
import { v4 as uuid } from "uuid";

import {
  getDocument,
  getDocumentByDateId,
  getUserDocuments,
  getUserNotes,
  removeDocument,
  updateDocument,
} from "db/documents/documents.queries";
import { handleApiError } from "db/utils";
import { store } from "stores/store";
import { db } from "firebaseInstance";
import {
  makeDocument,
  makeDocumentAsBacklink,
} from "db/documents/documents.mapping";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { getPageBlocks, removeBlock } from "db/blocks/blocks.queries";
import DocumentsSynchronizer, {
  DocumentSyncData,
} from "stores/documentsSynchronizer";
import { isEmptyContent } from "components/slate/utils";
import { makeSharedId, mapContentToJSON } from "helpers";
import { getBlocksContent, mapElementToBlock } from "db/blocks/blocks.mapping";
import {
  Block,
  BLOCK_CONVERTER,
  Document,
  DOCUMENT_CONVERTER,
  DocumentSchema,
  DocumentType,
  getBlockPath,
  getDocumentPath,
  MakeDocumentAsBacklinkParams,
  MakeDocumentParams,
  MakeSnippetParams,
  getBlocksPath,
} from "thunk-core";
import { SyncStatus } from "./syncMonitorStore";
import { addNotification } from "hooks/useNotifications";
import { EditorType } from "components/slate/types";
import { TAB_SESSION_ID } from "utils/tabSessionId";
import { swapIds } from "components/slate/plugins/backlink/utils";
import { CustomElement } from "components/slate/types/elements";
import { ParagraphType } from "components/slate/plugins/paragraph/types";
import { getRandomHeaderImage } from "./utils/getRandomHeaderImage";

export default class DocumentsStore {
  private _sessions = new Map<string, Element[]>(); // not observable sessions to keep original reference to compare
  sessions = new Map<string, Element[]>(); // use it only through getters/setters, should be synced with _sessions
  sessionsByDocuments = new Map<string, Set<string>>();
  sessionSubscriptions = new Map<string, Unsubscribe[]>();
  private synchronizer: DocumentsSynchronizer;

  readonly documentType: DocumentType;

  documents = new Map<string, Document>();
  loading = true;
  error = null;

  constructor(type: DocumentType) {
    this.documentType = type;
    this.synchronizer = new DocumentsSynchronizer(
      (documentId: string, data: DocumentSyncData) =>
        this.synchronize(documentId, data)
    );
    makeObservable(this, {
      documents: observable,
      sessions: observable,
      sessionsByDocuments: observable,
      loading: observable,
      createNewDocument: action.bound,
      createNewDocumentAsBacklink: action.bound,
      setDocument: action.bound,
      updateDocument: action.bound,
      updateDocumentRemote: action.bound,
      removeDocumentRemote: action.bound,
      updateContent: action.bound,
      updateBlockContent: action.bound,
      startEditingSession: action.bound,
      startDailyEditingSession: action.bound,
      endEditingSession: action.bound,
      loadDocument: action.bound,
      loadAllDocuments: action.bound,
      synchronize: action.bound,
    });
  }

  getDocuments() {
    return Array.from(this.documents.values());
  }

  getDocument(documentId: string) {
    return this.documents.get(documentId);
  }

  getDocumentByDateId(dateId: string) {
    if (!dateId) {
      return null;
    }

    const document = this.getDocuments().find((x) => x.dateId == dateId);
    return document;
  }

  getBacklinks = (documentId: string): BacklinkElement[] => {
    const { blocksStore } = store;

    const document = this.getDocument(documentId);

    if (!document) {
      return [];
    }

    const blocks = blocksStore
      .getBlocks(document.blocks)
      .filter((block) => !!block.targets?.length);
    const backlinks = blocksStore.getBlocksBacklinks(blocks);

    return backlinks;
  };

  getEditingSession = ({ slateId }: { slateId: string }): Element[] | null => {
    this.sessions.get(slateId); // it called here to cause re-renders when session changed
    return this._sessions.get(slateId); // return plain object instead of proxy for right comparison on first content change
  };

  getDocumentContent = (documentId: string): Element[] | null => {
    const { blocksStore } = store;

    const document = this.getDocument(documentId);

    if (!document) {
      return null;
    }

    const blocks = blocksStore.getBlocks(document.blocks);
    const content = getBlocksContent(blocks);

    return content;
  };

  createNewSnippet(params: MakeSnippetParams, blocks: Block[]): string {
    const { blocksStore } = store;
    const document = makeDocument({
      ...params,
      type: DocumentType.SNIPPET,
    });

    // Update stores
    blocksStore.setBlocks(blocks);
    this.setDocument(document);

    // Sync
    this.synchronizer.notifyWorker(document.id, {
      blocksChanged: document.blocks,
    });
    this.synchronizer.notifyWorker(document.id, { documentChanged: true });

    return document.id;
  }

  createNewDocumentWithContent(
    content: Descendant[],
    options: {
      userId: string;
      title?: string;
      dateId?: string;
      documentType: DocumentType;
    }
  ) {
    const { userId, title = "", dateId = "", documentType } = options;

    const pageId = uuid();
    const pageType = documentType;

    const blocks = content.map((element) => {
      const elem = swapIds(element);
      return mapElementToBlock(
        {
          pageId,
          pageType,
          dateId,
          userId,
        },
        {
          ...elem,
          id: uuid(),
        } as CustomElement
      );
    });
    const blockIds = blocks.map((block) => block.id);

    const { blocksStore } = store;
    const document = makeDocument({
      id: pageId,
      blocks: blockIds,
      title,
      dateId,
      userId,
      type: pageType,
    });

    // Update stores
    blocksStore.setBlocks(blocks);
    this.setDocument(document);

    // Sync
    this.synchronizer.notifyWorker(document.id, {
      blocksChanged: document.blocks,
    });
    this.synchronizer.notifyWorker(document.id, { documentChanged: true });

    return document.id;
  }

  addContentToDocument(
    content: Descendant[],
    options: {
      documentId: string;
      dateId?: string;
      documentType: DocumentType;
    }
  ) {
    const { documentId, dateId = "", documentType } = options;

    const pageId = documentId;
    const pageType = documentType;

    const document = this.getDocument(pageId);

    const blocks = [
      ...content,
      { id: uuid(), type: ParagraphType, children: [{ text: "" }] },
    ].map((element) => {
      const elem = swapIds(element);
      return mapElementToBlock(
        {
          pageId,
          pageType,
          dateId,
          userId: document.userId,
        },
        {
          ...elem,
          id: uuid(),
        } as CustomElement
      );
    });
    const blockIds = blocks.map((block) => block.id);

    this.updateDocument(pageId, {
      blocks: [...document.blocks, ...blockIds],
    });

    const { blocksStore } = store;

    // Update stores
    blocksStore.setBlocks(blocks);
    this.setDocument(document);

    // Sync
    this.synchronizer.notifyWorker(document.id, {
      blocksChanged: document.blocks,
    });
    this.synchronizer.notifyWorker(document.id, { documentChanged: true });

    // update other editors with the same document
    const sessions = this.sessionsByDocuments.get(documentId);

    if (sessions) {
      for (const sessionId of sessions) {
        const content = this.getDocumentContent(documentId);
        this.setSession({ slateId: sessionId, documentId, content });
      }
    }

    return document.id;
  }

  createNewDocument(params: MakeDocumentParams): string {
    const document = makeDocument(params);

    this.setDocument(document);
    this.synchronizer.notifyWorker(document.id, { documentChanged: true });
    return document.id;
  }

  createNewDocumentAsBacklink(params: MakeDocumentAsBacklinkParams) {
    const document = makeDocumentAsBacklink(params);

    this.setDocument(document);
    this.synchronizer.notifyWorker(document.id, { documentChanged: true });
  }

  setDocument(document: Document) {
    // just set without notifying sync worker
    this.documents.set(document.id, document);
  }

  setDocuments(documents: Document[]) {
    for (const document of documents) {
      this.setDocument(document);
    }
  }

  setSession({
    slateId,
    documentId,
    content,
  }: {
    slateId: string;
    documentId: string;
    content: Element[];
  }) {
    this._sessions.set(slateId, content);
    this.sessions.set(slateId, content);

    const documentSessions = this.sessionsByDocuments.get(documentId);
    if (documentSessions) {
      documentSessions.add(slateId);
    } else {
      this.sessionsByDocuments.set(documentId, new Set([slateId]));
    }
  }

  updateDocument(documentId: string, updates: Partial<DocumentSchema>) {
    const document = this.getDocument(documentId);

    if (!document) {
      return;
    }

    if (updates.title) {
      updates.lowercaseTitle = updates.title.toLowerCase();
      updates.sharedId = makeSharedId(updates.title);
    }

    if (!document.hasSyncedRemote) {
      document.hasSyncedRemote = true;
    }

    // Always set the lastModifiedSession to the current tab session id
    document.lastModifiedSession = TAB_SESSION_ID;

    Object.assign(document, updates, { updatedAt: Timestamp.now() });
    this.synchronizer.notifyWorker(document.id, { documentChanged: true });
  }

  async updateDocumentRemote(
    documentId: string,
    updates: Partial<DocumentSchema>
  ) {
    const document = this.getDocument(documentId);

    if (!document) {
      return;
    }

    await updateDocument(documentId, updates);
    runInAction(() => {
      Object.assign(document, updates, { updatedAt: Timestamp.now() });
    });
  }

  async removeDocumentRemote(documentId: string) {
    const document = this.getDocument(documentId);

    if (!document) {
      return;
    }

    const { blocksStore } = store;

    await removeDocument(document);

    runInAction(() => {
      this.documents.delete(documentId);
      for (const blockId of document.blocks) {
        blocksStore.removeBlock(blockId);
      }
    });
  }

  addTag(documentId: string, tagId: string) {
    if (this.documentType !== DocumentType.PAGE) {
      return;
    }

    const document = this.getDocument(documentId);

    if (!document) {
      return;
    }

    const tags = uniq([...document.tags, tagId]);
    this.updateDocument(documentId, { tags });
  }

  removeTag(documentId: string, tagId: string) {
    if (this.documentType !== DocumentType.PAGE) {
      return;
    }

    const document = this.getDocument(documentId);

    if (!document) {
      return;
    }

    const tags = document.tags.filter((id) => id !== tagId);
    this.updateDocument(documentId, { tags });
  }

  updateContent({
    slateId,
    userId,
    documentId,
    nextContent,
  }: {
    slateId: string;
    userId: string;
    documentId: string;
    nextContent: Element[];
  }) {
    const { blocksStore } = store;
    const prevDocument = this.getDocument(documentId);
    const prevContent = this._sessions.get(slateId);

    if (!prevContent) {
      throw new Error(
        // prettier-ignore
        `Editing session ${slateId} is not found while content updating`
      );
    }

    const blocksChanged = new Set<string>();
    const blocksRemoved = new Set<string>();

    const prevMap = new Map<string, Element>(); // create map to simplify access to prevContent elements
    for (const element of prevContent) {
      prevMap.set(element.id, element);
    }

    const nextMap = new Map<string, Element>(); // create map to simplify access to nextContent elements
    const nextIds: string[] = [];
    for (const element of nextContent) {
      const prevElement = prevMap.get(element.id);

      if (prevElement !== element) {
        blocksStore.setBlock(
          element.id,
          mapElementToBlock(
            {
              userId,
              pageId: documentId,
              pageType: prevDocument.type,
              dateId: prevDocument.dateId,
              lastModifiedSession: TAB_SESSION_ID,
            },
            element
          )
        );
        blocksChanged.add(element.id);
      }

      prevMap.delete(element.id); // keep only elements that should be removed
      nextIds.push(element.id); // collect ids
      nextMap.set(element.id, element);
    }

    this.synchronizer.notifyWorker(documentId, {
      blocksChanged: Array.from(blocksChanged),
    });

    for (const element of prevMap.values()) {
      // at this moment prevMap contains only elements that should be removed
      blocksStore.removeBlock(element.id);
      blocksRemoved.add(element.id);
    }

    this.synchronizer.notifyWorker(documentId, {
      blocksRemoved: Array.from(blocksRemoved),
    });

    const documentUpdates: Partial<Document> = {};

    documentUpdates.blocks = nextIds;

    const isEmpty = isEmptyContent(nextContent);
    if (prevDocument.isEmpty !== isEmpty) {
      documentUpdates.isEmpty = isEmpty;
    }

    // Always update timestamp to reflect content has changed on page
    this.updateDocument(documentId, documentUpdates);

    this.setSession({
      slateId,
      documentId,
      content: nextContent,
    });

    // update other editors with the same document
    const sessions = this.sessionsByDocuments.get(documentId);

    for (const sessionId of sessions) {
      if (sessionId !== slateId) {
        const content = this.getEditingSession({ slateId: sessionId });

        if (content) {
          const contentMap = new Map<string, Element>();
          for (const element of content) {
            contentMap.set(element.id, element);
          }

          const _nextContent = nextContent.map((element) => {
            return blocksChanged.has(element.id)
              ? clone(nextMap.get(element.id))
              : contentMap.get(element.id);
          });

          this.setSession({
            slateId: sessionId,
            documentId,
            content: _nextContent,
          });
        }
      }
    }
  }

  // todo add typing to block properties
  updateBlockContent({
    documentId,
    blockId,
    element,
  }: {
    documentId: string;
    blockId: string;
    element: Element;
  }) {
    const { blocksStore } = store;

    const block = blocksStore.getBlock(blockId);

    if (!block) {
      return;
    }

    const newBlock = mapElementToBlock(
      {
        userId: block.userId,
        pageId: block.pageId,
        pageType: block.pageType,
        dateId: block.dateId,
      },
      element
    );

    blocksStore.setBlock(element.id, newBlock);

    const sessions = this.sessionsByDocuments.get(documentId);
    if (sessions != null) {
      for (const slateId of sessions) {
        const content = this.getEditingSession({ slateId });

        // if main editor editing session exists update related element
        const newContent = content.map((_element) =>
          _element.id === blockId ? clone(element) : _element
        );
        this.setSession({ slateId, documentId, content: newContent });
      }
    }

    this.updateDocument(block.pageId, {}); // update timestamp
    this.synchronizer.notifyWorker(documentId, { blocksChanged: [blockId] });
  }

  private handleDocumentSubscriptionForSession(
    documentId: string,
    sessionId: string,
    shouldRedirectOnDeletion: boolean = false
  ) {
    const docSubscription = onSnapshot(
      doc(db, getDocumentPath(documentId)).withConverter(DOCUMENT_CONVERTER),
      (doc) => {
        const { syncMonitorStore } = store;

        // Ignore changes made by this tab session
        if (doc.exists() && doc.data().lastModifiedSession === TAB_SESSION_ID) {
          return;
        }

        // Deleted document
        if (!doc.exists() && this.documents.has(documentId)) {
          const doc = this.documents.get(documentId);
          if (
            !doc.hasSyncedRemote ||
            syncMonitorStore.getDocSyncState(documentId) === SyncStatus.Pending
          ) {
            // new document is being synced, ignore
            return;
          }

          // If document is deleted and was the main editor, redirect to all notes
          if (shouldRedirectOnDeletion) {
            window.location.href = "/overview";
            addNotification({
              type: "warning",
              text: "Document was deleted",
              duration: 2000,
            });
          }
          return;
        }
        // New or updated document
        if (
          !this.documents.has(documentId) ||
          !equals(this.documents.get(documentId), doc.data())
        ) {
          runInAction(() => {
            this.setDocument(doc.data());
            const content = this.getDocumentContent(documentId);
            this.setSession({ slateId: sessionId, documentId, content });
            return;
          });
        }
      }
    );

    return docSubscription;
  }

  private handleBlockSubscriptionForSession(
    userId: string,
    documentId: string,
    sessionId: string
  ) {
    const { blocksStore } = store;
    const blockSubscription = onSnapshot(
      query(
        collection(db, getBlocksPath()),
        where("userId", "==", userId),
        where("pageId", "==", documentId)
      ).withConverter(BLOCK_CONVERTER),
      (snap) => {
        let changed = false;
        snap.docChanges().forEach((change) => {
          // Ignore changes made by this session
          if (
            change.doc.exists() &&
            change.doc.data().lastModifiedSession === TAB_SESSION_ID
          ) {
            return;
          }
          const isNewOrModified =
            (change.type === "added" &&
              !blocksStore.blocks.has(change.doc.id)) ||
            (change.type === "modified" &&
              !equals(blocksStore.getBlock(change.doc.id), change.doc.data()));
          // Handle blocks added or modified by other sessions
          if (isNewOrModified) {
            runInAction(() => {
              blocksStore.setBlock(change.doc.id, change.doc.data());
              changed = true;
            });
          }
          // Handle blocks removed by other sessions
          if (
            change.type === "removed" &&
            blocksStore.blocks.has(change.doc.id)
          ) {
            runInAction(() => {
              blocksStore.removeBlock(change.doc.id);
              changed = true;
            });
          }
        });
        if (changed) {
          // If there was a change, update the session
          runInAction(() => {
            const content = this.getDocumentContent(documentId);
            this.setSession({ slateId: sessionId, documentId, content });
          });
        }
      }
    );

    return blockSubscription;
  }

  async startEditingSession(
    userId,
    slateId: string,
    documentId: string,
    editorType: EditorType,
    { signal }: { signal: AbortSignal }
  ): Promise<void> {
    const { blocksStore, pagesStore } = store;

    const [document, blocks] = await Promise.all([
      this.getDocumentLazy(documentId),
      getPageBlocks(userId, documentId),
    ]);

    if (!document) {
      throw new Error("Document is not found on session start");
    }

    runInAction(() => {
      this.setDocument(document);
      blocksStore.setOnlyNewBlocks(blocks);
      if (editorType === EditorType.MainEditor) {
        pagesStore.setActiveDocumentId(documentId);
      }

      if (!signal.aborted) {
        // create session only if editor still rendered
        const content = this.getDocumentContent(documentId);
        this.setSession({ slateId, documentId, content });
      }
    });

    // ------------------------------------------
    // Start subscriptions
    // ------------------------------------------
    const docSubscription = this.handleDocumentSubscriptionForSession(
      documentId,
      slateId,
      editorType === EditorType.MainEditor
    );

    const blockSubscription = this.handleBlockSubscriptionForSession(
      userId,
      documentId,
      slateId
    );

    runInAction(() => {
      this.sessionSubscriptions.set(slateId, [
        docSubscription,
        blockSubscription,
      ]);
    });

    // ------------------------------------------
  }

  async startDailyEditingSession(
    userId: string,
    slateId: string,
    dateId: string,
    { signal }: { signal: AbortSignal }
  ): Promise<string> {
    const { blocksStore } = store;

    let document = await this.getDocumentByDateLazy(userId, dateId);

    if (!document) {
      document = makeDocument({
        type: this.documentType,
        userId: userId,
        dateId: dateId,
        headerImage: getRandomHeaderImage(),
      });
    }

    const documentId = document.id;

    const blocks = await getPageBlocks(userId, documentId);

    runInAction(() => {
      this.setDocument(document);
      blocksStore.setOnlyNewBlocks(blocks);

      if (!signal.aborted) {
        // create session only if editor still rendered
        const content = this.getDocumentContent(documentId);
        this.setSession({ slateId, documentId, content });
      }
    });

    // ------------------------------------------
    // Start subscriptions
    // ------------------------------------------
    const docSubscription = this.handleDocumentSubscriptionForSession(
      documentId,
      slateId
    );

    const blockSubscription = this.handleBlockSubscriptionForSession(
      userId,
      documentId,
      slateId
    );

    runInAction(() => {
      this.sessionSubscriptions.set(slateId, [
        docSubscription,
        blockSubscription,
      ]);
    });

    // ------------------------------------------

    return documentId;
  }

  endEditingSession({
    slateId,
    documentId,
    editorType,
  }: {
    slateId: string;
    documentId: string;
    editorType: EditorType;
  }) {
    const { pagesStore } = store;

    this._sessions.delete(slateId);
    this.sessions.delete(slateId);
    if (editorType === EditorType.MainEditor) {
      pagesStore.setActiveDocumentId(null);
    }

    // Unsubscribe firebase listeners
    const unsubs = this.sessionSubscriptions.get(slateId);
    if (unsubs) {
      unsubs.forEach((unsub) => unsub());
      this.sessionSubscriptions.delete(slateId);
    }

    const sessions = this.sessionsByDocuments.get(documentId);
    if (sessions) {
      sessions.delete(slateId);
    }
  }

  async getDocumentLazy(documentId: string): Promise<Document | null> {
    let document = this.getDocument(documentId);

    if (document) {
      return document;
    }

    document = await getDocument(documentId);

    return document;
  }

  async getDocumentByDateLazy(
    userId: string,
    dateId: string
  ): Promise<Document | null> {
    let document = this.getDocumentByDateId(dateId);

    if (document) {
      return document;
    }

    document = await getDocumentByDateId(userId, dateId);

    return document;
  }

  async loadDocument(documentId: string) {
    const document = await getDocument(documentId);
    runInAction(() => {
      this.documents.set(documentId, document);
    });
  }

  async loadAllDocuments(userId: string) {
    this.documents.clear();
    this.loading = true;
    this.error = null;

    try {
      const documents =
        this.documentType === DocumentType.NOTE
          ? await getUserNotes(userId)
          : await getUserDocuments(userId, this.documentType);

      runInAction(() => {
        documents.forEach((x) => this.documents.set(x.id, x));
        this.loading = false;
      });
    } catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = error;
      });
      handleApiError(error, "Failed to load pages");
    }
  }

  async synchronize(documentId: string, data: DocumentSyncData) {
    const { blocksStore } = store;
    const {
      documentChanged,
      blocksChanged: _blocksChanged,
      blocksRemoved,
    } = data;

    const document = this.getDocument(documentId);
    const contentHash = md5(
      mapContentToJSON(this.getDocumentContent(documentId))
    ).toString();

    if (!document) {
      return;
    }

    const batches: WriteBatch[] = [];

    // split into multiple batches, because batch write can handle maximum 500 writes
    for (const blocksChanged of splitEvery(450, _blocksChanged)) {
      const batch = writeBatch(db);

      for (const id of blocksChanged) {
        const block = blocksStore.getBlock(id);

        // if this block is already removed, we skip this update
        if (block != null) {
          batch.set(
            doc(db, getBlockPath(block.id)).withConverter(BLOCK_CONVERTER),
            block
          );
        }
      }

      batches.push(batch);
    }

    if (batches[batches.length - 1] == null) {
      // add at least 1 batch
      batches.push(writeBatch(db));
    }

    const lastBatch = batches[batches.length - 1];

    if (documentChanged) {
      lastBatch.set(
        doc(db, getDocumentPath(documentId)).withConverter(DOCUMENT_CONVERTER),
        { ...document, contentHash }
      );
    }

    await Promise.all(batches.map((batch) => batch.commit()));

    // run after updates success
    Promise.resolve().then(async () => {
      // - handle removes async way without affecting updates
      // - we assume that element ids are unique
      // - mb we can create function that removes all unused blocks
      await this.removeBlocksRemote(blocksRemoved);
    });
  }

  async removeBlocksRemote(blockIds: string[]) {
    const { blocksStore } = store;

    const promises = blockIds
      .map((blockId) => {
        const block = blocksStore.getBlock(blockId);

        if (block != null) {
          // if this block still in state, we skip this
          return null;
        }

        return removeBlock(blockId);
      })
      .filter(Boolean);

    await Promise.all(promises);
  }
}
