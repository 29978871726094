import React, { useState, useEffect, useMemo } from "react";

const usePersistedState = <T>(
  id: string,
  initialState: (any) => T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const key = `thunk_${id}`;

  const persistor = useMemo(
    () => ({
      persist: (state: any) => {
        try {
          localStorage.setItem(key, JSON.stringify(state));
        } catch (error) {
          console.error(error);
          return null;
        }
      },
      restore: () => {
        try {
          const state = JSON.parse(localStorage.getItem(key));
          return state;
        } catch (error) {
          console.error(error);
          localStorage.removeItem(key);
          return null;
        }
      },
    }),
    [key]
  );

  const [state, setState] = useState<T>(() => {
    const restored = persistor.restore();

    return initialState(restored);
  });

  useEffect(() => {
    persistor.persist(state);
  }, [persistor, state]);

  return [state, setState];
};

export default usePersistedState;
