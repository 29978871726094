import React from "react";

import { ElementProps } from "components/slate/types";
import {
  ListItemElement,
  ListTypes,
} from "components/slate/plugins/list/types";
import {
  isCheckListItemElement,
  isTodoListItemElement,
} from "components/slate/plugins/list/utils";
import { renderBulletedItem } from "components/slate/plugins/list/components/BulletedItem";
import { renderNumberedItem } from "components/slate/plugins/list/components/NumberedItem";
import { TodoItem } from "components/slate/plugins/list/components/TodoItem";
import { CheckItem } from "components/slate/plugins/list/components/CheckItem";
import { useStaticProps } from "components/slate/hooks/useStaticProps";

const ListItem = (props: ElementProps & { element: ListItemElement }) => {
  const { readOnly } = useStaticProps();

  if (isTodoListItemElement(props.element)) {
    return <TodoItem {...props} element={props.element} readOnly={readOnly} />;
  }

  if (isCheckListItemElement(props.element)) {
    return <CheckItem {...props} element={props.element} readOnly={readOnly} />;
  }

  if (props.element.listType === ListTypes.Numbered) {
    return renderNumberedItem(props);
  }

  return renderBulletedItem(props);
};

export default ListItem;
