import { useEffect } from "react";

import preloadImages from "utils/preloadImages";

const context = require.context("./", true, /\.(svg|png|jpg|jpeg|webp)$/);

const PreloadImages = () => {
  useEffect(() => {
    const urls = [];
    context.keys().forEach((key) => urls.push(context(key).default));

    preloadImages(urls);
  }, []);

  return null;
};

export default PreloadImages;
