import { ImageIcon } from "thunk-icons";
import Button from "framework/components/form/Button";
import useAppPopper from "framework/components/poppers/useAppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import AppPopper, {
  PopperMenuItem,
} from "framework/components/poppers/AppPopper";
import { HEADER_IMAGE_THUMBNAILS } from "./headerImages";

import styles from "./HeaderImagePicker.module.scss";

interface Props {
  btnClass?: string;
  onUpdateHeaderImage?: (headerImageUrl?: string) => void;
}

export const HeaderImagePicker = ({ btnClass, onUpdateHeaderImage }: Props) => {
  const popperProps = useAppPopper({
    placement: "bottom",
    hasArrow: true,
  });
  useClickPopper(popperProps);

  return (
    <>
      <AppPopper
        {...popperProps}
        content={<ImageOptions onUpdateHeaderImage={onUpdateHeaderImage} />}
      />
      <Button
        ref={popperProps.setTargetElement}
        variant="circularCardHeader"
        size="large"
        btnClass={btnClass}
        icon={ImageIcon}
        iconSize={18}
      >
        Change Image
      </Button>
    </>
  );
};

const ImageOptions = ({ onUpdateHeaderImage }) => {
  return (
    <div className={styles.popperContainer}>
      <div className={styles.popperTitle}>Select Image</div>
      <PopperMenuItem itemClass={styles.headerImagePopperItems}>
        <Button
          onClick={() => onUpdateHeaderImage(null)}
          variant={"headerImage"}
          btnClass={styles.noImageBtn}
        >
          No Image
        </Button>
        {HEADER_IMAGE_THUMBNAILS.map((image, index) => (
          <Button
            key={`image-${index}`}
            onClick={() =>
              onUpdateHeaderImage(
                `https://firebasestorage.googleapis.com/v0/b/thunk-production.appspot.com/o/assets%2F${
                  index + 1
                }.webp?alt=media`
              )
            }
            variant={"headerImage"}
            style={{
              background: `url('${image}')  no-repeat`,
            }}
          />
        ))}
      </PopperMenuItem>
    </div>
  );
};

export default HeaderImagePicker;
