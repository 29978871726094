import { SignUpProvider } from "./SignUpProvider";
import SignUpTemplate from "./SignUpTemplate";

const SignUpOnboarding = () => {
  return (
    <SignUpProvider>
      <SignUpTemplate />
    </SignUpProvider>
  );
};

export default SignUpOnboarding;
