import { v4 as uuidv4 } from "uuid";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import i18n from "i18n";

import { storage } from "firebaseInstance";
import { addNotification, removeNotification } from "hooks/useNotifications";
import { getErrorInfo } from "./errors";
import { getImageExtension } from "components/slate/plugins/file/utils";

export const uploadImage = async (userId: string, file: any, image: any) => {
  if (!userId) {
    const errorInfo = getErrorInfo();

    addNotification({
      duration: 2500,
      type: "error",
      text: i18n.t(errorInfo.message),
    });

    return;
  }

  const imageId = uuidv4();
  const ext = getImageExtension(file.name);
  const extPath = ext ? `.${ext}` : "";
  const path = `users/${userId}/content-images/${imageId}/image${extPath}`;

  const uploadingNotificationId = addNotification({
    duration: 0,
    type: "loading",
    text: "Uploading image",
  });

  try {
    const avatarRef = ref(storage, path);

    const snapshot = await uploadString(avatarRef, image, "data_url");
    const url = await getDownloadURL(snapshot.ref);

    removeNotification(uploadingNotificationId);

    addNotification({
      duration: 2500,
      type: "success",
      text: "Image successfully uploaded",
    });

    return url;
  } catch (error) {
    console.error(error);
    removeNotification(uploadingNotificationId);
    const errorInfo = getErrorInfo(error);

    addNotification({
      duration: 2500,
      type: "error",
      text: i18n.t(errorInfo.message),
    });
    throw error; // prevent inserting empty image
  }
};
