import { UseSlatePlugin } from "components/slate/types";
import withStream from "components/slate/plugins/stream/withStream";
import { useStreamStateContext } from "components/slate/plugins/stream/components/StreamProvider";

const useStreamPlugin: UseSlatePlugin = () => {
  useStreamStateContext(); // workaround: to rerender

  return {
    withOverrides: withStream,
  };
};

export default useStreamPlugin;
