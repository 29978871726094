import create from "zustand";

type ComponentMenuState = {
  currentElement: HTMLElement | null;
  search: string;

  openMenu: (element: HTMLElement | null) => void;
  closeMenu: () => void;
  setSearch: (search: string) => void;
};

const createState = () =>
  create<ComponentMenuState>((set) => ({
    currentElement: null,
    search: "",

    openMenu: (element) => set({ currentElement: element }),
    closeMenu: () => set({ currentElement: null, search: "" }),
    setSearch: (search) => set(() => ({ search })),
  }));

export default createState;
