import { Timestamp } from "firebase/firestore";
import { v4 as uuid } from "uuid";

import { TagElement, TagType } from "components/slate/plugins/tag/types";
import { Tag } from "thunk-core";

export const makeTag = (params: {
  userId: string;
  title: string;
  color?: Tag["color"];
  variant?: Tag["variant"];
  emoji?: Tag["emoji"];
}): Tag => {
  const id = uuid();

  const tag: Tag = {
    id: id,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    userId: params.userId,
    title: params.title || "",
    color: params.color ?? "default",
    variant: params.variant ?? "outlined",
    emoji: params.emoji ?? null,
  };

  return tag;
};

export const mapTagToTagElement = (tag: Tag): TagElement => {
  const element = {
    id: tag.id,
    type: TagType,
    userId: tag.userId,
    tagId: tag.id,
    children: [{ text: `[[${tag.id}]]` }],
  };

  return element;
};
