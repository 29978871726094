import React, { useContext } from "react";
import { useRouteMatch } from "react-router-dom";

import { useCurrentUserId } from "db/currentUser";

const DocumentUserContext = React.createContext<{
  documentUserId: string;
  isDocumentOwner: boolean;
}>({ documentUserId: null, isDocumentOwner: true });

export function useDocumentUserContext() {
  return useContext(DocumentUserContext);
}

export const DocumentUserProvider = ({ children }: any) => {
  const userId = useCurrentUserId();

  const pageMatch = useRouteMatch<{ userId: string | null }>(
    "/users/:userId/page/:pageId"
  );
  const templateMatch = useRouteMatch<{ userId: string | null }>(
    "/users/:userId/snippets/:snippet"
  );

  const documentUserId =
    pageMatch?.params?.userId || templateMatch?.params?.userId || userId;
  const isDocumentOwner = documentUserId === userId;

  return (
    <DocumentUserContext.Provider value={{ documentUserId, isDocumentOwner }}>
      {children}
    </DocumentUserContext.Provider>
  );
};
