import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useStore } from "stores/store";
import {
  TableFilterClearButton,
  TableFilterSection,
} from "framework/components/table/TableFilter";
import Tag from "framework/components/Tag";
import { buildTagsTree } from "pages/app/TagsOverviewPage/useFilteredTags";

import styles from "./index.module.scss";
import { Tag as TagDb } from "thunk-core";
import Checkbox from "framework/components/form/Checkbox";

const indentationWidth = 25;

type Props = {
  selectedTags: TagDb[];
  setSelectedTitles: React.Dispatch<React.SetStateAction<string[]>>;
};

const TagsFilterSection = observer((props: Props) => {
  const history = useHistory();

  const { setSelectedTitles, selectedTags } = props;

  const { tagsStore } = useStore();
  const flatTree = buildTagsTree(tagsStore.getTags());

  const handleSelect = (tag: TagDb, isSelected: boolean) => {
    const newTags = isSelected
      ? selectedTags.filter((x) => x.id !== tag.id)
      : [...selectedTags, tag];

    setSelectedTitles(newTags.map((x) => x.title));
  };

  return (
    <TableFilterSection
      className={styles.tagFiltersSection}
      title="Filter by tag"
      actions={
        selectedTags.length > 0 && (
          <TableFilterClearButton
            onClick={() => {
              history.replace({ search: "" });
            }}
          />
        )
      }
    >
      {(function () {
        if (flatTree?.length === 0) {
          return <i>You don't have any tags yet.</i>;
        }

        return (
          <div>
            {flatTree && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  marginBottom: 20,
                }}
              >
                {flatTree
                  .sort((x, y) =>
                    x.item.title
                      .toLowerCase()
                      .localeCompare(y.item.title.toLowerCase())
                  )
                  .map(({ item: tag, level }) => {
                    const isSelected = selectedTags
                      .map((x) => x.id)
                      .includes(tag.id);

                    return (
                      <div className={styles.tagFilterItem} key={tag.id}>
                        <Checkbox
                          checked={isSelected}
                          onChange={() => handleSelect(tag, isSelected)}
                        />

                        <div>
                          <Tag
                            style={{
                              margin: 5,
                              marginLeft: level * indentationWidth,
                            }}
                            key={tag.id}
                            readOnly={true}
                            color={tag.color}
                            variant={tag.variant}
                            emoji={tag.emoji}
                            onClick={() => {
                              handleSelect(tag, isSelected);
                            }}
                          >
                            {tag.title}
                          </Tag>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        );
      })()}
    </TableFilterSection>
  );
});

export default TagsFilterSection;
