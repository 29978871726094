import React from "react";
import Loader from "react-spinners/ClipLoader";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { Subscription } from "@stripe/firestore-stripe-payments";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

import {
  createBillingPortalSession,
  useCurrentUserSubscriptions,
  usePrices,
} from "db/stripe";
import LoadingButton from "framework/components/form/LoadingButton";
import { useAuth } from "auth/AuthProvider";
import { AuthAccessTypes } from "auth/types";
import { formatCentPrice } from "components/subscriptions/utils";
import { PriceWithProduct } from "db/types";
import Button from "framework/components/form/Button";
import { trialDaysAvailable } from "auth/helpers";

import styles from "./index.module.scss";
import { useIntercom } from "react-use-intercom";

const SubscriptionSettings = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const search = queryString.parse(location.search);
  const isUpgrade = (search.isUpgrade as string) === "true";
  const intercom = useIntercom();

  const { authAccessType, currentUser, claims } = useAuth();
  const [prices, loadingPrices] = usePrices();
  const isTrial = authAccessType === AuthAccessTypes.trial;
  const expired = authAccessType === AuthAccessTypes.subscriptionExpired;
  const [subscriptions, loadingSubscriptions] = useCurrentUserSubscriptions(
    expired
  );

  const loading = loadingPrices || loadingSubscriptions;

  const allCanceled = subscriptions?.every((s) => s.cancel_at_period_end);

  const handleManage = async () => {
    const { url } = await createBillingPortalSession();
    window.location.href = url;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {isTrial &&
        t("subscription.account.messages.trial", {
          days: trialDaysAvailable(currentUser, claims),
        })}

      {subscriptions.map((subscription) => {
        const price = prices.find((price) => price.id === subscription.price);
        return <SubscriptionItem subscription={subscription} price={price} />;
      })}
      <div className={styles.action}>
        {!expired &&
          !isTrial &&
          authAccessType !== AuthAccessTypes.trialExpired && (
            <div className={styles.actions}>
              <LoadingButton
                btnClass={styles.manageButton}
                variant="secondary"
                onClick={handleManage}
              >
                {t("subscription.buttons.manage_subscription")}
              </LoadingButton>
              <Button
                variant="ghost"
                btnClass={cn(styles.manageButton, {
                  [styles.wiggle]: isUpgrade,
                })}
                onClick={() => intercom.show()}
              >
                Contact us to change plan
              </Button>
            </div>
          )}
        {expired && (
          <Button variant="secondary" as={Link} to="/pricing">
            {t("subscription.buttons.renew_subscription")}
          </Button>
        )}
        {(isTrial || (!expired && allCanceled)) && (
          <Button variant="secondary" as={Link} to="/pricing">
            {t("subscription.buttons.all_available_plans")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionSettings;

type SubscriptionComponentProps = {
  subscription: Subscription;
  price: PriceWithProduct;
};

const SubscriptionItem = ({
  subscription,
  price,
}: SubscriptionComponentProps) => {
  const { t } = useTranslation();

  if (!price) {
    return null;
  }

  const { interval, productData, description, type } = price;
  const formattedPrice = formatCentPrice(price.unit_amount, price.currency);

  return (
    <div>
      <div className={styles.name}>
        <span className={styles.emphasis}>Current plan:</span>{" "}
        {description ? description : productData.name}{" "}
      </div>
      {/*<div className={styles.price}>*/}
      {/*  {type === "recurring"*/}
      {/*    ? `${formattedPrice}/${interval}`*/}
      {/*    : "one-time payment"}*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*  Status:{" "}*/}
      {/*  <Tag type="default" variant="filled">*/}
      {/*    {getSubscriptionTagText(t, subscription)}*/}
      {/*  </Tag>*/}
      {/*</div>*/}
      {/*<div className={styles.details}>*/}
      {/*  {getSubscriptionPeriodDetails(t, subscription)}*/}
      {/*</div>*/}
    </div>
  );
};
