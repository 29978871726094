import { useRouteMatch } from "react-router-dom";

export const useSidePanelMatch = (): boolean => {
  const noteMatch = useRouteMatch("/writing");
  const pageMatch = useRouteMatch([
    "/page/:pageId",
    "/users/:userId/page/:pageId",
  ]);

  const matched = noteMatch != null || pageMatch != null;

  return matched;
};
