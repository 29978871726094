import React, {
  useState,
  memo,
  useLayoutEffect,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { Node } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import isHotkey from "is-hotkey";
import { History } from "history";

import {
  ComponentMenuProps,
  MenuItem,
} from "components/slate/plugins/menus/componentMenu/types";
import { PortalBody } from "framework/components/Portal";
import { appShortcuts } from "appShortcuts";
import ElementPopper from "components/slate/components/ElementPopper";
import ComponentMenuContent from "components/slate/plugins/menus/componentMenu/components/ComponentMenuContent";

import styles from "./ComponentMenu.module.scss";

const ComponentMenu = memo((props: ComponentMenuProps) => {
  const {
    currentElement,
    search,
    setSearch,
    isOpened,
    closeComponentsMenu,
  } = props;
  const editor = useSlateStatic();

  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [node, setNode] = useState<Node | null>(null);
  useLayoutEffect(() => {
    if (currentElement) {
      setTargetElement(getComponentMenuTarget(currentElement));
      setNode(ReactEditor.toSlateNode(editor, currentElement));
    } else {
      setTargetElement(null);
      setNode(null);
    }
  }, [editor, currentElement]);

  const handleSelect = useCallback(
    (e: Event, history: History, item: MenuItem) => {
      item.applyMenuItem && item.applyMenuItem({ editor, history });

      const keepOpened =
        "children" in item || item.variant === "back" || item.keepMenuOpened;
      if (!keepOpened) {
        closeComponentsMenu(editor, { focus: true });
      }
    },
    [editor]
  );

  const handleClose = useCallback(() => {
    closeComponentsMenu(editor, { focus: true });
  }, [editor, closeComponentsMenu]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    isOpened && setTimeout(() => inputRef.current.focus(), 0);
  }, [isOpened]);

  return (
    <PortalBody>
      <ElementPopper
        isVisible={targetElement != null}
        element={targetElement}
        className={styles.menuTooltip}
        onClickOutside={handleClose}
      >
        <input
          className={styles.searchInput}
          placeholder="Type to filter this list"
          ref={inputRef}
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (isHotkey(appShortcuts.toggleComponentsMenu, e)) {
              e.preventDefault();
              closeComponentsMenu(editor, { focus: true });
            }
          }}
        />
        <ComponentMenuContent
          isOpened={targetElement != null}
          node={node}
          search={search}
          onSelect={handleSelect}
          onCancel={handleClose}
        />
      </ElementPopper>
    </PortalBody>
  );
});

export default ComponentMenu;

const getComponentMenuTarget = (
  element: HTMLElement | null
): HTMLElement | null => {
  const target = element?.querySelector(
    "[data-slate-component-menu-target]"
  ) as HTMLElement;
  return target;
};
