import React from "react";
import { Editor, Range, Node } from "slate";
import isHotkey from "is-hotkey";

import { isCheckableListItem } from "../list/utils";
import {
  addBlockAbove,
  addBlockBelow,
} from "components/slate/plugins/exitBreak/transforms";

const shouldBreak = (node: Node) => {
  // keep it to handle on list item level
  return !isCheckableListItem(node);
};

export const onKeyDown = (editor: Editor) => (e: React.KeyboardEvent) => {
  if (!editor.selection || Range.isExpanded(editor.selection)) {
    return;
  }

  if (isHotkey(["mod+enter"], e)) {
    const path = Editor.path(editor, editor.selection, { depth: 1 });
    const [node] = Editor.node(editor, path);

    if (!shouldBreak(node)) {
      return;
    }

    addBlockBelow(editor);
  }

  if (isHotkey(["shift+mod+enter"], e)) {
    addBlockAbove(editor);
  }
};
