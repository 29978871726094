import React from "react";
import isHotkey from "is-hotkey";

import TextInput from "framework/components/form/TextInput";
import Button from "framework/components/form/Button";

type Props = {
  inputRef?: React.ForwardedRef<any>;
  initialValue: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onSubmit: (value: string) => void;
  buttonTitle: string;
};

const TitleTextInput = (props: Props) => {
  const { inputRef, initialValue, value, buttonTitle, onChange, onSubmit } =
    props;

  return (
    <div style={{ display: "flex", width: 240 }}>
      <TextInput
        inputRef={inputRef}
        type="text"
        value={value}
        onChange={onChange}
        onKeyDown={(e) => {
          if (isHotkey("Enter", e)) {
            onSubmit(value);
          }
        }}
      />
      <Button
        style={{ marginLeft: 5 }}
        variant="primary"
        disabled={value === initialValue || value.trim() === ""}
        onClick={() => onSubmit(value)}
      >
        {buttonTitle}
      </Button>
    </div>
  );
};

export default TitleTextInput;
