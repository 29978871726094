import { withResetType } from "./withResetType";
import { UseSlatePlugin } from "components/slate/types";

const useResetTypePlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withResetType,
  };
};

export default useResetTypePlugin;
