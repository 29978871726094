import delay from "utils/delay";
import { store } from "stores/store";

export type TagSyncData = {
  tagChanged: boolean;
};

type Synchronize = (tagId: string, data: TagSyncData) => Promise<void>;

export default class TagsSynchronizer {
  workers = new Map<string, TagSyncData>();
  synchronize: Synchronize = null;

  constructor(synchronize: Synchronize) {
    this.synchronize = synchronize;
  }

  async runWorker(tagId: string): Promise<void> {
    const { syncMonitorStore } = store;
    syncMonitorStore.syncPending(tagId);

    await delay(1); // be sure it runs async way

    while (true) {
      await delay(500);

      const workerData = this.workers.get(tagId);

      if (!workerData) {
        throw new Error("Worker data can't be null at this moment");
      }

      if (!this.isEmpty(workerData)) {
        const extracted = this.extractData(tagId);
        try {
          await this.synchronize(tagId, extracted);

          syncMonitorStore.syncSucceed(tagId);
        } catch (error) {
          console.error(error);

          syncMonitorStore.syncFailed(tagId);

          await delay(3000);
          this.assignUpdates(this.workers.get(tagId), extracted); // revert
        }
      } else {
        return;
      }
    }
  }

  async notifyWorker(
    tagId: string,
    updates: Partial<TagSyncData>
  ): Promise<void> {
    if (this.isEmpty(updates)) {
      return;
    }

    if (this.workers.has(tagId)) {
      const workerData = this.workers.get(tagId);

      if (!workerData) {
        throw new Error("Worker data can't be null at this moment");
      }

      this.assignUpdates(workerData, updates);

      return;
    }

    this.workers.set(tagId, this.assignUpdates(this.getInitialData(), updates));

    await this.runWorker(tagId);

    this.workers.delete(tagId);
  }

  assignUpdates(data: TagSyncData, updates: Partial<TagSyncData>) {
    const { tagChanged } = updates;

    if (tagChanged != null) {
      data.tagChanged = tagChanged;
    }

    return data;
  }

  isEmpty(data: TagSyncData | Partial<TagSyncData>) {
    return !data.tagChanged;
  }

  extractData(tagId: string): TagSyncData {
    const data = this.workers.get(tagId);
    this.workers.set(tagId, this.getInitialData());

    return data;
  }

  getInitialData(): TagSyncData {
    return {
      tagChanged: false,
    };
  }
}
