import cn from "classnames";
import { Link } from "react-router-dom";
import { isWindows } from "react-device-detect";
import { HelpIcon } from "thunk-icons";

import { useSidebarContext } from "providers/SidebarProvider";
import Button from "framework/components/form/Button";
import SimpleTooltip from "components/SimpleTooltip";

import styles from "components/layout/Sidebar/index.module.scss";
import { ThemePicker } from "components/ThemePicker";

import { useHelpSectionState } from "state/layout";

import ElectronAvatar from "components/electron/ElectronLayout/ElectronAvatar";

const Sidebar = ({
  navigation,
  search,
  favorites,
  recentPages,
  actions,
}: any) => {
  const { isSidebarOpened } = useSidebarContext();

  const [isHelpOpened, setIsHelpOpened] = useHelpSectionState();

  const metaKey = isWindows ? "⊞" : "⌘";
  const optionKey = isWindows ? "Alt" : "⌥";

  return (
    <div
      className={cn(styles.sidebar, {
        [styles.collapsed]: !isSidebarOpened,
      })}
    >
      <div className={styles.sidebarWrapper}>
        <div className={styles.sidebarContent}>
          <div>{search}</div>
          <div>
            {navigation}
            <Button
              variant="circularElectronHeader"
              size={"large"}
              btnClass={styles.helpButton}
              onClick={() => setIsHelpOpened((state) => !state)}
            >
              <HelpIcon size={28} /> Help
            </Button>
            <Button
              as={Link}
              to="/account"
              variant="circularElectronHeader"
              size={"large"}
              btnClass={styles.accountButton}
            >
              <ElectronAvatar /> Settings & Account
            </Button>
          </div>
          <div>
            {favorites}
            {recentPages}
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        {actions}
        <ThemePicker btnClass={styles.bottomActionsButton} />

        {/* <Button
          as={Link}
          to="/account"
          variant="circularElectronHeader"
          size={"large"}
          btnClass={cn(styles.bottomActionsButton, styles.accountButton)}
        >
          <ElectronAvatar /> Account
        </Button> */}

        <SimpleTooltip
          content={`New note (${metaKey}+${optionKey}+N)`}
          placement="top"
          offset={[0, 17]}
        >
          <Button
            as={Link}
            to="/page/new"
            variant="primary"
            size={"large"}
            btnClass={styles.bottomActionsButton}
          >
            {"+ New note"}
          </Button>
        </SimpleTooltip>
      </div>
    </div>
  );
};

export default Sidebar;
