import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { toJS } from "mobx";
import { MoveIcon } from "thunk-icons";
import cn from "classnames";

import { useStore } from "stores/store";
import Helmet from "components/Helmet";
import OverviewPageLayout from "components/layout/OverviewPageLayout";
import { formatDate } from "utils/dateUtils";
import { getEditorPathname, treeFlatMap } from "helpers";
import CardSpinner from "framework/components/Card/CardSpinner";
import AppResult, { CreateItemButton } from "components/results/AppResult";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import { DocumentTag } from "components/editor/DocumentTags";
import {
  Table,
  TableBody,
  TableCell,
  TableCheckboxCell,
  TableHead,
  TableHeaderCell,
  TableHeaderCheckboxCell,
  TableRow,
  TableRowEmpty,
  TableSelectionProvider,
} from "framework/components/table";
import Button from "framework/components/form/Button";
import useSorting from "framework/components/table/useSorting";
import useComparator from "framework/components/table/useComparator";
import { tagsUrlQueryKey } from "db/tags/tags.constants";
import useUrlQueryState from "hooks/useUrlQueryState";
import DocumentsFilter from "pages/app/DocumentsOverviewPage/DocumentsFilter";
import buildTree from "utils/buildTree";
import { sortRecursively } from "utils/sortRecursively";
import stringFilter from "utils/stringFilter";
import idsFilter from "utils/idsFilter";
import TableCount from "framework/components/table/TableCount";
import useSelection from "framework/components/table/useSelection";
import MovePagesMenu from "./MovePagesMenu";
import DocumentsFilterMobile from "pages/app/DocumentsOverviewPage/DocumentsFilterMobile";
import { FilterNotifications } from "pages/app/DocumentsOverviewPage/FilterNotifications";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import DeleteNotesButton from "./DeleteDocumentsButton";
import { Document } from "thunk-core";

import styles from "./index.module.scss";

const indentationWidth = 25;

const DocumentsOverviewPage = observer(() => {
  const { pagesStore, tagsStore } = useStore();

  //allow body scrolling on this page
  useEffect(() => {
    document.body.removeAttribute("data-not-public");
    document.body.removeAttribute("daily-note");
    document.body.setAttribute("no-side-panel-page", "true");
  });

  const loading = pagesStore.loading;
  const error = pagesStore.error;

  const [search, setSearch] = useState("");
  const [selectedTitles, setSelectedTitles] = useUrlQueryState(tagsUrlQueryKey);

  const pages = pagesStore.getDocuments();

  const { isMobileScreen } = useDeviceDetector();

  const tags = tagsStore.getTags();
  const selectedTags = useMemo(
    () =>
      tags.filter((tag) =>
        selectedTitles.some(
          (title) =>
            title.toLowerCase().trim() === tag.title.toLowerCase().trim()
        )
      ),
    [selectedTitles]
  );

  const selectedTagsWithChildren = useMemo(
    () =>
      tags.filter((tag) =>
        selectedTitles.some(
          (title) =>
            title.toLowerCase().trim() ===
              getPath(tag.title.toLowerCase().trim()) ||
            title.toLowerCase().trim() === tag.title.toLowerCase().trim()
        )
      ),
    [selectedTitles]
  );

  const [sorting, updateSorting] = useSorting<Document>(
    "documentsOverviewSorting",
    {
      field: "updatedAt",
      order: "desc",
    }
  );
  const comparator = useComparator(sorting);
  const allIds = useMemo(() => pages.map((x) => x.id), [pages]);
  const selection = useSelection(allIds);

  const flatTree = useMemo(() => {
    if (loading || error) {
      return [];
    }

    if (search.trim() != "" || selectedTagsWithChildren.length > 0) {
      return pages
        .filter(
          (page) =>
            stringFilter(page.title, search) &&
            idsFilter(
              page.tags,
              selectedTagsWithChildren.map((x) => x.id),
              selectedTagsWithChildren.length > 0
            )
        )
        .sort(comparator)
        .map((document) => ({
          item: document,
          level: 0,
          children: [],
        }));
    }

    const tree = buildTree(pages);
    const filteredTree = tree.filter((x) => !x.item.parentId);

    sortRecursively(filteredTree, (x, y) => comparator(x.item, y.item));

    const docs = treeFlatMap(filteredTree, (item, context) => ({
      ...item,
      level: context.depth - 1,
    }));

    return docs;
  }, [pages, search, selectedTagsWithChildren]);

  const filteredIds = useMemo(() => flatTree.map((x) => x.item.id), [flatTree]);

  // If a filter filters out a selected page, unselect it to avoid un-intentional actions
  useEffect(() => {
    const nonVisibleIds = Array.from(selection.selectedIds).filter(
      (id) => !filteredIds.includes(id)
    );

    if (nonVisibleIds.length > 0) {
      selection.setSelectedIds((state) => {
        const newState = new Set(state);
        nonVisibleIds.forEach((id) => newState.delete(id));

        return newState;
      });
    }
  }, [filteredIds]);

  const rightActions = (
    <>
      {selection.selectedIds.size > 0 && (
        <>
          <MovePagesMenu selection={selection}>
            {({ ref }) => (
              <Button
                ref={ref}
                variant="circularElectronHeader"
                size="large"
                mobileIcon={MoveIcon}
                icon={MoveIcon}
                iconSize={14}
              >
                Move
              </Button>
            )}
          </MovePagesMenu>
          <DeleteNotesButton selection={selection} />
        </>
      )}
      <Button as={Link} to={"/page/new"} variant="primary" size="large">
        + New Note
      </Button>
    </>
  );

  return (
    <OverviewPageLayout
      title={isMobileScreen ? "Notes" : "All Notes"}
      leftActions={
        <div>
          <FilterNotifications
            search={search}
            setSearch={setSearch}
            selectedTags={selectedTags}
            setSelectedTitles={setSelectedTitles}
          />
        </div>
      }
      rightActions={rightActions}
    >
      <Helmet pageTitle="All Notes" />
      <DocumentsFilterMobile
        search={search}
        setSearch={setSearch}
        selectedTags={selectedTags}
        setSelectedTitles={setSelectedTitles}
      />
      {(function () {
        if (loading) {
          return <CardSpinner loading={loading} loadingTimeout={300} />;
        }

        if (error) {
          console.error(error);
          return <AppResult resultType="fetching" isCenteredContent={true} />;
        }

        if (pages.length === 0) {
          return (
            <AppResult
              isCenteredContent={true}
              title="You don't have any notes yet. Let's make some!"
              actions={
                <CreateItemButton as={Link} to="/page/new">
                  Create new note
                </CreateItemButton>
              }
            />
          );
        }

        return (
          <TableSelectionProvider filteredIds={filteredIds} {...selection}>
            <Table
              filter={
                <DocumentsFilter
                  search={search}
                  setSearch={setSearch}
                  selectedTags={selectedTags}
                  setSelectedTitles={setSelectedTitles}
                />
              }
              filterCount={
                <TableCount
                  count={flatTree.length}
                  total={pages.length}
                  name="Notes"
                />
              }
            >
              <TableHead>
                <TableRow>
                  <TableHeaderCheckboxCell />
                  <TableHeaderCell
                    sortingKey="title"
                    sorting={sorting}
                    className={styles.title}
                    onClick={() => updateSorting("title", "asc")}
                  >
                    Title
                  </TableHeaderCell>
                  <TableHeaderCell className={styles.tags}>
                    Tags
                  </TableHeaderCell>
                  <TableHeaderCell
                    sortingKey="updatedAt"
                    sorting={sorting}
                    onClick={() => updateSorting("updatedAt", "desc")}
                  >
                    Updated
                  </TableHeaderCell>
                  <TableHeaderCell
                    sortingKey="createdAt"
                    sorting={sorting}
                    onClick={() => updateSorting("createdAt", "desc")}
                  >
                    Created
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(function () {
                  if (flatTree.length === 0) {
                    return <TableRowEmpty colSpan={4} />;
                  }

                  return flatTree.map(({ item, level }) => {
                    return (
                      <DocumentTableRow
                        key={item.id}
                        document={item}
                        level={level}
                      />
                    );
                  });
                })()}
              </TableBody>
            </Table>
          </TableSelectionProvider>
        );
      })()}
    </OverviewPageLayout>
  );
});

export default DocumentsOverviewPage;

const DocumentTableRow = observer(
  ({ document, level }: { document: Document; level: number }) => {
    const { tagsStore } = useStore();

    return (
      <TableRow key={document.id}>
        <TableCheckboxCell itemId={document.id} />
        <TableCell
          style={
            {
              "--spacing": `${level * indentationWidth}px`,
            } as React.CSSProperties
          }
          className={styles.title}
        >
          <Link
            to={getEditorPathname(document.type, document.id, document.dateId)}
          >
            {getDocumentTitle(document) || "[no title]"}
          </Link>
          {document.tags.length > 0 && (
            <div
              className={cn(styles.tagsContainer, styles.tagsContainerMobile)}
            >
              {document.tags.map((tagId) => (
                <DocumentTag
                  readOnly={true}
                  key={tagId}
                  tag={toJS(tagsStore.getTag(tagId))}
                />
              ))}
            </div>
          )}
        </TableCell>
        <TableCell className={styles.tags}>
          <div className={styles.tagsContainer}>
            {document.tags.map((tagId) => (
              <DocumentTag
                readOnly={true}
                key={tagId}
                tag={toJS(tagsStore.getTag(tagId))}
              />
            ))}
          </div>
        </TableCell>
        <TableCell className={styles.date}>
          {formatDate(document.updatedAt?.toDate())}
        </TableCell>
        <TableCell className={styles.date}>
          {formatDate(document.createdAt?.toDate())}
        </TableCell>
      </TableRow>
    );
  }
);

const getPath = (str: string) => {
  return str.substr(0, str.lastIndexOf("/"));
};
