import { Transforms, Editor, Path } from "slate";
import { clone } from "ramda";

import { isEmptyNode } from "components/slate/utils";
import { isParagraphElement } from "components/slate/plugins/paragraph/utils";
import { swapAllIds } from "../util";

export const insertTemplate = (editor: any, range: any, fragment: any) => {
  const withFreshIds = swapAllIds({ children: clone(fragment) });
  Transforms.select(editor, range);
  Transforms.insertNodes(editor, withFreshIds.children, { at: range });
  // remove empty paragraph
  if (editor.selection) {
    const [entry] = Editor.nodes(editor, {
      match: (node, path) =>
        isParagraphElement(node) && isEmptyNode(node) && path.length === 1,
    });
    if (entry) {
      const [, path] = entry;
      Transforms.removeNodes(editor, {
        at: path,
        match: isParagraphElement,
      });
      if (!Path.equals(path, [0])) {
        Transforms.move(editor, { unit: "offset" }); // move selection to the begging of template
      }
    }
  }
};
