import { flatten } from "ramda";
import { parseDate, parse } from "chrono-node";

import { formatDate } from "utils/dateUtils";

type DateItem = {
  title: string;
  hint: string;
  date: Date;
};

const weekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

const defaultSearch = ["today", "tomorrow", "yesterday"];

const keywords = [
  "today",
  "tomorrow",
  "yesterday",
  ...weekdays,
  ...weekdays.map((x) => `next ${x}`),
  ...weekdays.map((x) => `prev ${x}`),
  ...weekdays.map((x) => `last ${x}`),
  ...months,
];

const _getDateItems = (search: string): DateItem[] => {
  const found = keywords.find(
    (keyword) => keyword.indexOf(search.toLowerCase()) === 0
  );
  if (found) {
    search = found;
  }

  const results = parse(search);

  if (!results.length) {
    return [];
  }

  const result = results[0];

  const date = result.start.date();
  const triggerText = result.text;

  if (triggerText && triggerText.length < search.trim().length) {
    // prevent showing date item if search contains more stuff than only trigger text has
    return [];
  }

  return [
    {
      title: formatDate(date),
      hint: search,
      date,
    },
  ];
};

export const getDateItems = (search: string): DateItem[] => {
  if (!search || search.trim() === "") {
    const defaultOptions = flatten(defaultSearch.map(_getDateItems));
    return defaultOptions;
  }

  return _getDateItems(search);
};
