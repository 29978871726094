import React from "react";
import { Editor } from "slate";
import { isKeyHotkey } from "is-hotkey";

export const onKeyDown = (editor: Editor) => (e: React.KeyboardEvent) => {
  if (isKeyHotkey(["Enter", "Tab"], e)) {
    e.preventDefault();
    return;
  }
};

export const onPaste = (editor: Editor) => (e: React.KeyboardEvent) => {
  e.preventDefault();
};
