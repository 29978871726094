import { observer } from "mobx-react-lite";
import { DeleteNoteIcon } from "thunk-icons";

import Button from "framework/components/form/Button";
import useNotify from "hooks/useNotify";
import useDialog from "hooks/useDialog";
import DeleteConfirmModal from "components/modals/DeleteConfirmModal";
import { useStore } from "stores/store";
import { TableSelection } from "framework/components/table/useSelection";

type Props = {
  selection: TableSelection;
};

const DeleteNotesButton = observer((props: Props) => {
  const { selection } = props;

  const { pagesStore } = useStore();

  const removeDialog = useDialog<{}, boolean>();
  const notify = useNotify();

  const handleRemove = async () => {
    const selectedIds = [...selection.selectedIds];

    const isConfirmed = await removeDialog.open(
      `Are you sure you want to remove ${selectedIds.length} ${
        selectedIds.length > 1 ? "notes" : "note"
      }? We can't get ${
        selectedIds.length > 1 ? "them" : "it"
      } back for you if you delete ${selectedIds.length > 1 ? "them" : "it"}!`
    );

    if (isConfirmed) {
      const removed = await Promise.all<string | null>(
        selectedIds.map(async (id) => {
          try {
            return await pagesStore.removeDocumentRemote(id);
          } catch (error) {
            notify.error(`There was an error removing ${id}`);
            return Promise.resolve(null);
          }
        })
      );
      const total = removed.filter((r) => r !== null).length;
      notify.success(
        `${total} ${total === 1 ? "Note" : "Notes"} ${
          total === 1 ? "was" : "were"
        } removed`
      );
    }
  };

  return (
    <>
      <Button
        mobileIcon={DeleteNoteIcon}
        variant="error"
        size="large"
        onClick={handleRemove}
      >
        Delete
      </Button>
      <DeleteConfirmModal
        {...removeDialog}
        title="Are you sure?"
        okText={`Delete ${selection.selectedIds.size === 1 ? "note" : "notes"}`}
      >
        {({ modalData }) => modalData}
      </DeleteConfirmModal>
    </>
  );
});

export default DeleteNotesButton;
