import { v4 as uuidv4 } from "uuid";
import { Element } from "slate";

import { TagType, TagElement } from "components/slate/plugins/tag/types";

export const swapIds = (obj: any) => {
  if (!obj.children) {
    return obj;
  }
  if (obj.children.some((c: any) => c.children)) {
    obj.children = obj.children.map((child: any) => swapIds(child));
  }
  if (obj.type === TagType) {
    obj.id = uuidv4();
  }
  return obj;
};

export const isTagElement = (value: any): value is TagElement => {
  return Element.isElementType<TagElement>(value, TagType);
};
