import React from "react";
import cn from "classnames";

import Checkbox from "framework/components/form/Checkbox";

import styles from "./index.module.scss";

type Props = {
  hideCompletedTasks: boolean;
  setHideCompletedTasks: React.Dispatch<React.SetStateAction<boolean>>;
};

const TasksPopperContent = (props: Props) => {
  const { hideCompletedTasks, setHideCompletedTasks } = props;

  return (
    <div className={styles.linksPopperList}>
      <div className={cn(styles.clearAll)}>
        <div className={styles.linkText}>
          <div className={styles.linksPopperListItem}>
            <Checkbox
              checked={hideCompletedTasks}
              onChange={() => setHideCompletedTasks((state) => !state)}
              label={"Hide completed tasks"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TasksPopperContent;
