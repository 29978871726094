import React from "react";
import { NewNoteIcon, AtIcon, MenuCalendarIcon } from "thunk-icons";
import { Editor } from "slate";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { v4 as uuid } from "uuid";

import { EditorActions } from "components/slate/actions";

export type AtMenuItem = {
  text: string;
  hint?: string;
  icon?: React.ComponentType<any>;
  createAction?: boolean;
  nonLink?: boolean;
  layout: "inline" | "vertical";
  applyItem: (editor: Editor) => void;
};

export const makePageBacklinkMeuItem = (
  text: string,
  hint: string,
  targetId: string,
  userId: string
): AtMenuItem => {
  return {
    text,
    hint,
    icon: () => <AtIcon size={19} style={{ fill: "var(--text-color)" }} />,
    applyItem: (editor) =>
      EditorActions.insertPageBacklink(
        { editor },
        {
          userId,
          params: { documentId: targetId, initialText: text },
        }
      ),
    layout: "vertical",
  };
};

export const makeTimestampMenuItem = (
  text: string,
  hint: string
): AtMenuItem => {
  return {
    text,
    hint,
    applyItem: (editor) => EditorActions.insertTimestamp({ editor }, { text }),
    layout: "inline",
    nonLink: true,
  };
};

export const makeNoteBacklinkMenuItem = (
  text: string,
  hint: string,
  dateId: string,
  userId: string
): AtMenuItem => {
  return {
    text,
    hint,
    icon: () => (
      <MenuCalendarIcon style={{ fill: "var(--text-color)" }} size={19} />
    ),
    applyItem: (editor) =>
      EditorActions.insertNoteBacklink(
        { editor },
        { userId, params: { dateId } }
      ),
    layout: "inline",
  };
};

export const makeNewPageBacklinkMenuItem = (
  text: string,
  hint: string,
  userId: string,
  parentId: string | null,
  parentType: string
): AtMenuItem => {
  return {
    text,
    hint,
    icon: () => <NewNoteIcon style={{ fill: "var(--text-color)" }} size={20} />,
    applyItem: (editor) =>
      EditorActions.insertNewPageBacklink(
        { editor },
        {
          params: {
            id: uuid(),
            userId: userId,
            parentId,
            parentType,
            title: text,
          },
        }
      ),
    createAction: true,
    layout: "vertical",
  };
};

export const highlightMatch = (value: string, search: string) => {
  return match(value, search, { requireMatchAll: true }) as [number, number][];
};

export const highlightParse = (
  value: string,
  matchResult: [number, number][]
) => {
  return parse(value, matchResult) as { text: string; highlight: boolean }[];
};
