import React, { useContext, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import isHotkey from "is-hotkey";

import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import useWindowEventListener from "hooks/useWindowEventListener";
import { appShortcuts } from "appShortcuts";
import { useSidebarState } from "state/layout";

type SidebarContextValue = {
  isSidebarOpened: boolean;
  toggleSidebar: (
    isOpened: React.SetStateAction<boolean>,
    onlyMobile?: boolean
  ) => void;
};

const SidebarContext = React.createContext<SidebarContextValue>(null);

export const useSidebarContext = () => {
  return useContext(SidebarContext);
};

export const SidebarProvider = ({ children }: any) => {
  const location = useLocation();
  const { isMobileScreen } = useDeviceDetector();

  const [isSidebarOpened, setIsSidebarOpened] = useSidebarState();

  const toggleSidebar = (isOpened: boolean, onlyMobile: boolean = false) => {
    if (onlyMobile && !isMobileScreen) {
      return;
    }

    setIsSidebarOpened(isOpened);
  };

  useLayoutEffect(() => {
    isSidebarOpened
      ? document.body.setAttribute("data-sidebar-opened", "true")
      : document.body.removeAttribute("data-sidebar-opened");
  }, [isSidebarOpened]);

  useEffect(() => {
    toggleSidebar(false, true);
  }, [location]);

  useWindowEventListener(
    "keydown",
    (e: any) => {
      if (isHotkey(appShortcuts.toggleSidebar, e)) {
        setIsSidebarOpened(!isSidebarOpened);
      }
    },
    [setIsSidebarOpened, isSidebarOpened]
  );

  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpened,
        toggleSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
