import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

import TextInput from "framework/components/form/TextInput";
import Button from "framework/components/form/Button";
import { SignUpFormValues, useSignUpContext } from "../SignUpProvider";
import nigelNoLook from "images/nigel_nolook.svg";
import { passwordMinLength } from "constants/validation";
import { env } from "thunk-env";
import Alert from "framework/components/Alert";

import styles from "../index.module.scss";

const PasswordForm = () => {
  const {
    error,
    submitting,
    formValues,
    goBackPassword,
    handleSubmitPassword,
    requiresEmail,
  } = useSignUpContext();
  const { register, handleSubmit, errors, reset } = useForm<
    Pick<SignUpFormValues, "password">
  >({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      password: formValues.password,
    },
  });

  useEffect(() => {
    requiresEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitError = error && {
    code: error.code,
    message: (
      <Trans
        i18nKey={error.message}
        values={{ email: env.REACT_APP_SUPPORT_EMAIL }}
        components={{
          email: <a href={`mailto:${env.REACT_APP_SUPPORT_EMAIL}`} />,
          signIn: <Link to="/signin" />,
        }}
      />
    ),
  };

  return (
    <form
      className={styles.signUpForm}
      onSubmit={handleSubmit((values) => {
        handleSubmitPassword(values);
        return false;
      })}
      noValidate
    >
      {submitError && (
        <Alert status="error" code={submitError.code}>
          {submitError.message}
        </Alert>
      )}

      <figure className={styles.signUpFormImageContainer}>
        <img src={nigelNoLook} alt="Nigel" className={styles.signUpFormImage} />
      </figure>

      <h3 className={styles.signUpFormHeadline}>
        And your password? Don't worry—I won't look.
      </h3>

      <TextInput
        type="password"
        error={errors.password?.message}
        name="password"
        placeholder="**********"
        labelText="Password"
        inputRef={register({
          required: "Password required",
          minLength: {
            value: passwordMinLength,
            message: `Password must be at least ${passwordMinLength} characters`,
          },
        })}
      />

      <div style={{ textAlign: "right" }}>
        <Button
          variant="ghost"
          type="button"
          onClick={() => {
            reset();
            goBackPassword();
          }}
        >
          Back
        </Button>
        <Button
          variant="secondary"
          type="submit"
          loading={submitting}
          disabled={submitting}
        >
          Create Account
        </Button>
      </div>
    </form>
  );
};

export default PasswordForm;
