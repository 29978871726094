import { Editor } from "slate";
import { insertEmptyCodeBlock } from "@udecode/plate-code-block";
import { AutoformatRule } from "@udecode/plate-autoformat";

import { toggleList } from "components/slate/plugins/list/transforms";
import { BlockquoteType } from "components/slate/plugins/blockquote/types";
import { HorizontalRuleType } from "components/slate/plugins/horizontalRule/types";
import { insertHorizontalRule } from "components/slate/plugins/horizontalRule/transforms";
import { isCodeBlockElement } from "components/slate/plugins/codeBlock/utils";
import { ListItemType, ListTypes } from "components/slate/plugins/list/types";
import {
  Heading1Type,
  Heading2Type,
  Heading3Type,
} from "components/slate/plugins/heading/types";
import {
  BoldMarkType,
  CodeMarkType,
  ItalicMarkType,
  KbdMarkType,
  StrikethroughMarkType,
} from "components/slate/plugins/marks/types";
import {
  CodeBlockType,
  CodeLineType,
} from "components/slate/plugins/codeBlock/types";

const query = (editor: Editor) => {
  const [plainTextEntry] = Editor.nodes(editor, {
    at: Editor.start(editor, editor.selection),
    match: (node) => isCodeBlockElement(node),
  });
  const isAutoformatAllowed = !plainTextEntry;
  return isAutoformatAllowed;
};

export const optionsAutoformat: AutoformatRule[] = [
  {
    mode: "block",
    type: Heading1Type,
    match: "# ",
    query,
  },
  {
    mode: "block",
    type: Heading2Type,
    match: "## ",
    query,
  },
  {
    mode: "block",
    type: Heading3Type,
    match: "### ",
    query,
  },
  {
    mode: "block",
    type: ListItemType,
    match: ["* ", "- "],
    format: (editor: Editor) => {
      toggleList(editor, { listType: ListTypes.Bulleted });
    },
    query,
  },
  {
    mode: "block",
    type: ListItemType,
    match: ["1. ", "1) "],
    format: (editor: Editor) => {
      toggleList(editor, { listType: ListTypes.Numbered });
    },
    query,
  },
  {
    mode: "block",
    type: ListItemType,
    match: ["o ", "O "],
    format: (editor: Editor) => {
      toggleList(editor, { listType: ListTypes.TodoList });
    },
    query,
  },
  {
    mode: "block",
    type: ListItemType,
    match: ["[] ", "x ", "X "],
    format: (editor: Editor) => {
      toggleList(editor, { listType: ListTypes.CheckList });
    },
    query,
  },
  {
    mode: "block",
    type: BlockquoteType,
    match: "> ",
    query,
  },
  {
    mode: "block",
    type: HorizontalRuleType,
    match: ["--- ", "-- ", "___ "],
    format: (editor) => {
      insertHorizontalRule(editor);
    },
    query,
  },
  {
    mode: "mark",
    type: [BoldMarkType, ItalicMarkType],
    match: "***",
    query,
  },
  {
    mode: "mark",
    type: BoldMarkType,
    match: "**",
    query,
  },
  {
    mode: "mark",
    type: ItalicMarkType,
    match: "*",
    query,
  },
  {
    mode: "mark",
    type: ItalicMarkType,
    match: "_",
    query,
  },
  {
    mode: "mark",
    type: CodeMarkType,
    match: "`",
    query,
  },
  {
    mode: "mark",
    type: KbdMarkType,
    match: "@@",
    query,
  },
  {
    mode: "mark",
    type: StrikethroughMarkType,
    match: "~~",
    query,
  },
  {
    mode: "block",
    type: CodeBlockType,
    match: "```",
    triggerAtBlockStart: false,
    format: (editor: Editor) => {
      insertEmptyCodeBlock(editor as any, {
        defaultType: CodeLineType,
        insertNodesOptions: { select: true },
      });
    },
    query,
  },
];
