import React, { useCallback } from "react";
import { Editor } from "slate";
import { DefaultElement, ReactEditor, RenderElementProps } from "slate-react";

import { ElementWrapper } from "components/slate/wrapper";
import { ElementProps, SlatePlugin } from "components/slate/types";
import ReferenceWrapper from "components/slate/wrapper/ReferenceWrapper";

export const useWrappedRenderElement = (
  editor: Editor,
  plugins: SlatePlugin[]
) => {
  const renderers = plugins
    .filter((x) => x.renderElement)
    .map((x) => x.renderElement!);

  const _renderElement = useCallback(
    (props: RenderElementProps) => {
      const { attributes, children, element } = props;

      const path = ReactEditor.findPath(editor, element);
      const renderElement = makeRenderElement(renderers);

      if (path.length === 1) {
        return (
          <ElementWrapper
            attributes={attributes}
            element={element}
            renderElement={renderElement}
          >
            {children}
          </ElementWrapper>
        );
      }

      return renderElement({
        attributes,
        element,
        children,
      });
    },
    [editor]
  );

  return _renderElement;
};

export const useReferenceRenderElement = (
  editor: Editor,
  plugins: SlatePlugin[],
  { contextDepth }: { contextDepth: number }
) => {
  const renderers = plugins
    .filter((x) => x.renderElement)
    .map((x) => x.renderElement!);

  const _renderElement = useCallback(
    (props: RenderElementProps) => {
      const { attributes, children, element } = props;

      const path = ReactEditor.findPath(editor, element);
      const renderElement = makeRenderElement(renderers);

      if (path.length === 1) {
        return (
          <ReferenceWrapper
            contextDepth={contextDepth}
            attributes={attributes}
            element={element}
            renderElement={renderElement}
          >
            {children}
          </ReferenceWrapper>
        );
      }

      return renderElement({
        attributes,
        element,
        children,
      });
    },
    [editor, contextDepth]
  );

  return _renderElement;
};

export const useBlockRenderElement = (
  editor: Editor,
  plugins: SlatePlugin[]
) => {
  const renderers = plugins
    .filter((x) => x.renderElement)
    .map((x) => x.renderElement!);

  const _renderElement = useCallback(
    (props: RenderElementProps) => {
      const { attributes, children, element } = props;

      const renderElement = makeRenderElement(renderers);

      return renderElement({
        attributes,
        element,
        children,
      });
    },
    [editor]
  );

  return _renderElement;
};

export const makeRenderElement =
  (renderers: ((props: ElementProps) => JSX.Element | null)[]) =>
  (props: ElementProps) => {
    for (const render of renderers) {
      const rendered = render(props);

      if (rendered) {
        return rendered;
      }
    }

    return (
      <DefaultElement
        children={props.children}
        element={props.element}
        attributes={props.attributes!}
      />
    );
  };
