import { Route, Switch, useHistory } from "react-router-dom";
import cn from "classnames";

import julianStepTwo from "images/julian_step_2.svg";
import CalReminderAction from "./CalReminderAction";
import CalReminderCue from "./CalReminderCue";
import AppResult from "components/results/AppResult";
import CalReminderTime from "./CalReminderTime";
import Button from "framework/components/form/Button";

import styles from "../index.module.scss";
import reminderStyles from "./CalReminder.module.scss";

const CalReminder = () => {
  const history = useHistory();
  return (
    <div className={cn(reminderStyles.root, styles.onboardingContainerSm)}>
      <figure className={styles.onboardingImageContainer}>
        <img
          src={julianStepTwo}
          alt="Julian Step 2"
          className={styles.onboardingImage}
        />
      </figure>

      <Switch>
        <Route
          path="/onboarding/reminder"
          exact
          component={CalReminderAction}
        />
        <Route
          path="/onboarding/reminder/cue"
          exact
          component={CalReminderCue}
        />
        <Route
          path="/onboarding/reminder/time"
          exact
          component={CalReminderTime}
        />
        <Route
          component={() => (
            <AppResult
              isCenteredContent={true}
              resultType="notFound"
              actions={
                <Button
                  variant="secondary"
                  onClick={() => history.push("/onboarding")}
                >
                  Go back
                </Button>
              }
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default CalReminder;
