import { Link } from "react-router-dom";

import CollapseButton from "framework/components/CollapseButton";
import { getEditorPathname } from "helpers";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import { Document } from "thunk-core";

import styles from "../index.module.scss";

type Props = {
  document: Document;
  isCollapsed: boolean;
  onCollapse: (isCollapsed: boolean) => void;
  checkedCount: number;
  totalCount: number;
};

const TasksSection = (props: Props) => {
  const { document, isCollapsed, onCollapse, checkedCount, totalCount } = props;
  const pageHref = getEditorPathname(
    document.type,
    document.id,
    document.dateId
  );
  const isOpened = !isCollapsed;
  const completedText = `${checkedCount}/${totalCount}`;
  const title = getDocumentTitle(document);

  return (
    <>
      <CollapseButton
        className={styles.taskCollapseArrow}
        isOpened={isOpened}
        onClick={() => {
          onCollapse(isOpened);
        }}
      />
      <Link className={styles.sectionTitle} to={pageHref}>
        <span className={styles.titleText}>{title}</span>
        <span className={styles.completedText}>{completedText}</span>
      </Link>{" "}
    </>
  );
};

export default TasksSection;
