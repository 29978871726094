const useReactNativeWebView = () => {
  //  @ts-ignore ts-migrate(2339) FIXME: Property 'ReactNativeWebView' does not exist on ty... Remove this comment to see the full error message
  const ReactNativeWebView = window.ReactNativeWebView;

  return {
    isReactNativeWebView: ReactNativeWebView != null,
    ReactNativeWebView,
  };
};

export default useReactNativeWebView;
