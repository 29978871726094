import { Block } from "./blocks.types";
import { FirestoreDataConverter } from "../types";

export const BLOCK_CONVERTER: FirestoreDataConverter<Block> = {
  toFirestore: (block: Block) => {
    const { id, ...converted } = block;
    return converted;
  },
  fromFirestore: (snapshot): Block => {
    const data = snapshot.data();
    return {
      id: snapshot.ref.id,
      userId: data.userId,
      pageId: data.pageId,
      pageType: data.pageType,
      dateId: data.dateId,
      type: data.type,
      contentJSON: data.contentJSON,
      properties: data.properties ?? {},
      targets: data.targets ?? [],
      tags: data.tags ?? [],
      lastModifiedSession: data.lastModifiedSession ?? null,
    };
  },
};
