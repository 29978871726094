import { useHistory } from "react-router-dom";

import useEventListener from "hooks/useEventListener";
import { useCurrentUserId } from "db/currentUser";
import { updateDoc, doc } from "firebase/firestore";
import { useThemeContext } from "providers/ThemeProvider";
import { db } from "firebaseInstance";

const AppKeydownHandler = () => {
  const history = useHistory();
  const userId = useCurrentUserId();
  const { darkMode } = useThemeContext();

  useEventListener(
    window,
    "keydown",
    async (e: any) => {
      if (e.metaKey && e.altKey && e.code === "KeyN") {
        history.push("/page/new");
      }
      if (e.ctrlKey && e.code === "Digit1") {
        history.push("/writing");
      }
      if (e.ctrlKey && e.code === "Digit2") {
        history.push("/graph");
      }
      if (e.ctrlKey && e.code === "Digit3") {
        history.push("/overview");
      }
      if (e.ctrlKey && e.code === "Digit4") {
        history.push("/tags");
      }
      if (e.ctrlKey && e.code === "Digit5") {
        history.push("/snippets");
      }
      if (userId && e.ctrlKey && e.code === "KeyL") {
        try {
          await updateDoc(doc(db, `users/${userId}`), {
            darkMode: !darkMode,
          });
        } catch (e) {
          console.error(e.message);
          return e;
        }
      }
    },
    [history, userId, darkMode]
  );

  return null;
};

export default AppKeydownHandler;
