import React from "react";
import cn from "classnames";
import {
  SquareClose,
  JulianIcon,
  LoadingIcon,
  SuccessIcon,
  WarningIcon,
} from "thunk-icons";

import styles from "./index.module.scss";
import "./react-notifications-component-overrides.scss";

const icons = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: WarningIcon,
};

const containerStyles = {
  "bottom-center": styles.bottomCenter,
  "bottom-right": styles.bottomRight,
};

export type NotificationProps = {
  container?: any;
  type: "default" | "success" | "warning" | "error" | "loading";
  text: React.ReactNode;
  icon?: "success" | "warning" | "error";
  doShowCloseIcon?: boolean;
  doShowLogo?: boolean;
  buttons?: React.ReactNode;
};

const Notification = ({
  container,
  type,
  text,
  icon = null,
  doShowCloseIcon = false,
  doShowLogo = false,
  buttons,
}: NotificationProps) => {
  const StatusIcon = icon || icons[type];

  return (
    <div className={cn(styles.container, containerStyles[container])}>
      {doShowLogo && (
        <div className={styles.logoIcon}>
          <JulianIcon size={55} />
        </div>
      )}
      <div className={styles.mainContainer}>
        <div className={styles.textContainer}>
          {StatusIcon && <StatusIcon className={styles.statusIcon} />}
          {type === "loading" && (
            <div className={styles.loadingIcon}>
              <LoadingIcon />
            </div>
          )}
          <span>{text}</span>
        </div>
        {doShowCloseIcon && (
          <SquareClose size={28} className={styles.dismissIcon} />
        )}
      </div>
      {buttons && <div className={styles.buttons}>{buttons}</div>}
    </div>
  );
};

export default Notification;
