import { useCallback, useMemo, useRef } from "react";
import { format, parse, startOfWeek, add, isAfter } from "date-fns";
import {
  AnytimeIcon,
  MenuCalendarIcon,
  NextWeekIcon,
  TomorrowIcon,
} from "thunk-icons";
import { useReadOnly, useSlateStatic } from "slate-react";
import cn from "classnames";

import { TodoListItemElement } from "../types";
import { assignDueDate } from "../transforms";
import { DueDateCalendarActionProps } from "components/tasks/DueDateCalendarAction/DueDateCalendarAction";
import { getInheritedDueDate } from "../utils";
import { useDueDateSelectCalendarMenuContext } from "providers/DueDateSelectCalendarMenuProvider";
import { useStaticProps } from "components/slate/hooks/useStaticProps";

import styles from "./toDoItemDueDateButton.module.scss";

const ToDoItemDueDateButton = ({
  element,
  btnClassName,
}: {
  element: TodoListItemElement;
  btnClassName?: string;
}) => {
  const editor = useSlateStatic();
  const isReadOnly = useReadOnly();
  const { isSharing } = useStaticProps();
  const dueDateMenuProps = useDueDateSelectCalendarMenuContext();
  const inheritedDate = getInheritedDueDate(editor, element);
  const hasDueDateOrInherited = !!element.dueDate || !!inheritedDate;
  const dueDateOrInherited = element.dueDate || inheritedDate;

  const date = useMemo(() => {
    const _date = parse(element.dueDate, "MM-dd-yyyy", new Date());
    return isNaN(_date.getTime()) ? new Date() : _date;
  }, [element.dueDate]);

  const isPastDue = useMemo(() => {
    const _due = parse(dueDateOrInherited, "MM-dd-yyyy", new Date());
    return isAfter(new Date(), _due);
  }, [dueDateOrInherited]);

  const containerRef = useRef(null);
  const targetRef = useRef(null);
  const isButtonVisible =
    !isSharing &&
    dueDateMenuProps.isVisible &&
    targetRef.current === dueDateMenuProps.targetElement;

  const chooseDate = useCallback(
    (date: Date) => {
      assignDueDate(editor, element, format(date, "MM-dd-yyyy"));
      dueDateMenuProps.closeCalendar();
    },
    [dueDateMenuProps, editor, element]
  );

  const setTomorrow = useCallback(() => {
    assignDueDate(
      editor,
      element,
      format(add(new Date(), { days: 1 }), "MM-dd-yyyy")
    );
    dueDateMenuProps.closeCalendar();
  }, [dueDateMenuProps, editor, element]);

  const setNextWeek = useCallback(() => {
    const startNextWeek = startOfWeek(add(new Date(), { days: 7 }));
    assignDueDate(editor, element, format(startNextWeek, "MM-dd-yyyy"));
    dueDateMenuProps.closeCalendar();
  }, [dueDateMenuProps, editor, element]);

  const clearDate = useCallback(() => {
    assignDueDate(editor, element, null);
    dueDateMenuProps.closeCalendar();
  }, [dueDateMenuProps, editor, element]);

  const actions: DueDateCalendarActionProps[] = useMemo(
    () => [
      {
        icon: TomorrowIcon,
        text: "Tomorrow",
        hintText: format(add(new Date(), { days: 1 }), "MMM d"),
        onClick: setTomorrow,
      },
      {
        icon: NextWeekIcon,
        text: "Next Week",
        hintText: format(startOfWeek(add(new Date(), { days: 7 })), "MMM d"),
        onClick: setNextWeek,
      },
      {
        icon: AnytimeIcon,
        text: "Anytime",
        onClick: clearDate,
      },
    ],
    [clearDate, setNextWeek, setTomorrow]
  );

  const toggleMenu = () => {
    if (isReadOnly) return;
    dueDateMenuProps.isVisible
      ? dueDateMenuProps.closeCalendar()
      : dueDateMenuProps.openCalendar({
          targetElement: targetRef.current,
          actions,
          date,
          onDateChange: chooseDate,
        });
  };

  return (
    <div
      ref={containerRef}
      className={styles.container}
      contentEditable={false}
      data-slate-clipboard-skip={true}
    >
      <div
        ref={targetRef}
        onClick={toggleMenu}
        className={cn(styles.calendarButton, btnClassName, {
          [styles.visible]: isButtonVisible,
        })}
      >
        {!isSharing && !hasDueDateOrInherited && (
          <MenuCalendarIcon size={20} title="Due date" />
        )}
        {hasDueDateOrInherited && (
          <div
            className={cn(styles.toDoDueDate, {
              [styles.sharing]: isSharing,
              [styles.pastDue]: isPastDue,
            })}
            title="Due date"
          >
            {format(
              parse(dueDateOrInherited, "MM-dd-yyyy", new Date()),
              "MMM d"
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ToDoItemDueDateButton;
