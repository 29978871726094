import usePersistedState from "hooks/usePersistedState";
import { useCurrentUserId } from "db/currentUser";

const usePersistedUserState = <T>(id: string, initialState: (any) => T) => {
  const userId = useCurrentUserId();

  return usePersistedState<T>(`${userId}_${id}`, initialState);
};

export default usePersistedUserState;
