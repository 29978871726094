import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Link from "framework/components/Link";
import TextInput from "framework/components/form/TextInput";
import Button from "framework/components/form/Button";
import { EMAIL_REGEX } from "helpers";
import { passwordMinLength } from "constants/validation";
import Alert from "framework/components/Alert";

import styles from "./index.module.scss";

export type AuthFormValues = {
  email: string;
  firstName: string;
  password: string;
};

type AuthFormProps = {
  onSubmit: (values: AuthFormValues) => Promise<any>;
  defaultValues?: Partial<AuthFormValues>;
  submitting: boolean;
  submitError?: any;
  successMessage?: string;
  primaryText: string;
  actionText: string;
  mode: "signin" | "signup" | "reset";
};

const AuthForm = ({
  onSubmit,
  primaryText,
  actionText,
  mode,
  submitting,
  submitError,
  successMessage,
  defaultValues = {},
}: AuthFormProps) => {
  const { register, handleSubmit, errors } = useForm<AuthFormValues>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues,
  });

  const { t } = useTranslation();

  const passwordText = mode === "signup" ? "Create Password" : "Password";

  return (
    <form
      className={styles.authForm}
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <h3>{primaryText}</h3>

      {submitError && (
        <Alert status="error" code={submitError.code}>
          {submitError.message}
        </Alert>
      )}
      {successMessage && <Alert status="success">{successMessage}</Alert>}

      {mode === "signup" && (
        <TextInput
          type="text"
          error={errors.firstName?.message}
          name="firstName"
          disabled={submitting}
          placeholder="First Name"
          labelText="First Name"
          inputRef={register({
            required: "First name required",
          })}
        />
      )}

      <TextInput
        type="email"
        error={errors.email?.message}
        name="email"
        disabled={submitting}
        placeholder="example@email.com"
        labelText="Email Address"
        inputRef={register({
          required: "Email address required",
          pattern: { value: EMAIL_REGEX, message: "Invalid email address" },
        })}
      />

      {mode !== "reset" && (
        <TextInput
          type="password"
          error={errors.password?.message}
          name="password"
          disabled={submitting}
          placeholder="**********"
          labelText={passwordText}
          labelContentRight={
            mode !== "signup" && <Link to="/reset">Forgot password?</Link>
          }
          // @ts-ignore @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
          inputRef={register({
            required: t("new_password_form.input.password.validation.required"),
            minLength: {
              value: passwordMinLength,
              message: t(
                "new_password_form.input.password.validation.minLength",
                { value: passwordMinLength }
              ),
            },
          })}
        />
      )}
      <Button
        loading={submitting}
        disabled={submitting}
        variant="secondary"
        type="submit"
      >
        {actionText}
      </Button>
    </form>
  );
};

export default AuthForm;
