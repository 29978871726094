import React from "react";
import { createPortal } from "react-dom";

import { useStreamContext } from "components/slate/plugins/stream/components/StreamProvider";
import StreamCloseButton from "components/slate/plugins/stream/components/StreamCloseButton";
import { Card } from "framework/components/Card";

import styles from "./index.module.scss";

const StreamScreen = () => {
  const { isStreamActive, setIsStreamActive } = useStreamContext();

  return createPortal(
    <div
      data-slate-stream-container={true}
      className={styles.streamScreenWrapper}
      style={
        {
          display: isStreamActive ? "block" : "none",
        } as React.CSSProperties
      }
    >
      <StreamCloseButton onClick={() => setIsStreamActive(false)} />
      <Card
        className={styles.streamScreen}
        fullScreen={true}
        noPadding={true}
        title={
          <div
            className={styles.titleContainer}
            data-slate-stream-title-container={true}
          />
        }
      >
        <div
          className={styles.editorContainer}
          data-slate-stream-editor-container={true}
        />
      </Card>
    </div>,
    document.body
  );
};

export default StreamScreen;

export const StreamTitlePortal = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { isStreamActive } = useStreamContext();

  if (!isStreamActive) {
    return <>{children}</>;
  }

  return createPortal(
    children,
    document.querySelector("[data-slate-stream-title-container]")
  );
};

export const StreamEditorPortal = ({
  children,
  disabled,
}: React.PropsWithChildren<{ disabled: boolean }>) => {
  const { isStreamActive } = useStreamContext();

  if (!isStreamActive || disabled) {
    return <>{children}</>;
  }

  return createPortal(
    children,
    document.querySelector("[data-slate-stream-editor-container]")
  );
};
