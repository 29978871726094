import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Button from "framework/components/form/Button";

import styles from "./index.module.scss";

const CardToolbarButton = forwardRef<any, any>(
  ({ onClick, onMouseDown, icon: Icon, color, children }, ref) => {
    return (
      <div className={styles.cardToolbarButton}>
      <Button
        ref={ref}
        style={{ color }}
        onClick={onClick}
        onMouseDown={onMouseDown}
        size="symbol"
        variant="circularCardHeader"
      >
        <Icon size={22} />
      </Button>
      </div>
    );
  }
);

CardToolbarButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  color: PropTypes.string,
};

export default CardToolbarButton;
