const stylesProps = {
  primary: {
    light: {
      onColor: "#a6caff",
      offColor: "#dadada",
      onHandleColor: "#0177ff",
      offHandleColor: "#0177ff",
      activeBoxShadow: "rgb(1, 119, 255, 0.4) 0px 0px 1px 2px",
    },
    dark: {
      onColor: "#1a2e48",
      offColor: "#2a3748",
      onHandleColor: "#0177ff",
      offHandleColor: "#0177ff",
    },
  },
  purple: {
    light: {
      onColor: "#6E3CDB",
      offColor: "#AC87FF",
      onHandleColor: "#ffffff",
      offHandleColor: "#ffffff",
      activeBoxShadow: "rgba(51, 0, 153, 0.3) 0px 0px 2px 3px",
    },
  },
};

export const getStylesProps = (theme: any, color: any) => {
  return theme === "dark"
    ? {
        //  @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...stylesProps[color].light,
        //  @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        ...stylesProps[color].dark,
      }
    : //  @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      stylesProps[color].light;
};
