import React from "react";
import cn from "classnames";
import InputMask from "react-input-mask";
import { LoadingIcon } from "thunk-icons";

import FormGroup from "framework/components/form/FormGroup";
import FormGroupLabel from "framework/components/form/FormGroupLabel";

import styles from "./index.module.scss";

type Props = {
  error?: any;
  labelText?: string;
  labelContentRight?: React.ReactNode;
  labelStyle?: React.CSSProperties;
  inputRef?: React.ForwardedRef<any>;
  mask?: any;
  maskChar?: any;
  alwaysShowMask?: any;
  isMonospace?: boolean;
  inputWidth?: React.CSSProperties["width"];
  feedback?: React.ReactNode;
  feedbackIcon?: string;
  contentRight?: React.ReactNode;
  loading?: boolean;
} & JSX.IntrinsicElements["input"];

const TextInput = (props: Props) => {
  const {
    error,
    labelText,
    labelContentRight,
    labelStyle,
    inputRef,
    mask,
    maskChar,
    alwaysShowMask,
    isMonospace,
    inputWidth,
    feedback,
    feedbackIcon,
    contentRight,
    loading,
    onClick,
    style,
    ...rest
  } = props;

  return (
    <FormGroup>
      {labelText && (
        <FormGroupLabel
          htmlFor={props.name}
          hasError={Boolean(error)}
          labelText={labelText}
          labelContentRight={labelContentRight}
          style={labelStyle}
        />
      )}
      <div className={styles.inputContainer}>
        <div onClick={onClick} className={styles.inputWrapper}>
          <InputMask
            style={{ width: inputWidth, ...style }}
            mask={mask}
            maskchar={maskChar}
            alwaysShowMask={alwaysShowMask}
            className={cn(styles.input, {
              [styles.inputWithError]: error,
              [styles.isMonospace]: isMonospace,
            })}
            ref={inputRef}
            {...rest}
          />
          {(feedback || feedbackIcon || loading) && (
            <div className={styles.feedback}>
              {feedback}
              {feedbackIcon && (
                <img
                  className={styles.feedbackIcon}
                  src={feedbackIcon}
                  alt=""
                />
              )}
              {loading && <LoadingIcon className={styles.loadingIcon} />}
            </div>
          )}
        </div>
        {contentRight && (
          <div className={styles.contentRight}>{contentRight}</div>
        )}
      </div>

      {error && <div className={styles.inputError}>{error}</div>}
    </FormGroup>
  );
};

export default TextInput;
