import { Element, Node } from "slate";
import {
  ELEMENTS_NON_TEXT,
  ELEMENTS_TEXT_BLOCKS,
} from "components/slate/plugins/menus/componentMenu/constants";
import crawl from "tree-crawl";

import {
  defaultMenuConfig,
  nonTextConfig,
  textMenuConfig,
  MenuItems,
} from "components/slate/plugins/menus/componentMenu/items";
import {
  GroupItem,
  MenuItem,
} from "components/slate/plugins/menus/componentMenu/types";
import { clone } from "ramda";

export const filterConfig = (
  config: MenuItem[],
  fn: (item: MenuItem) => boolean
) => {
  const _config = {
    children: clone(config),
  };

  crawl<any>(
    _config,
    (item: MenuItem, context) => {
      if (!fn(item)) {
        const parent = context.parent;
        parent.children.splice(context.index, 1);

        context.remove();
        return;
      }

      if (
        "children" in item &&
        item.children.length === 0 &&
        !item.dynamicChildren
      ) {
        const parent = context.parent;
        parent.children.splice(context.index, 1);

        context.remove();
      }
    },
    { order: "post" }
  );

  return _config.children;
};

export const _getDefaultConfig = (
  nodes: Node[],
  isEmpty: boolean
): MenuItem[] => {
  const types = new Set(nodes.filter(Element.isElement).map((x) => x.type));

  if (hasIntersection(ELEMENTS_TEXT_BLOCKS, types)) {
    if (isEmpty) {
      return defaultMenuConfig;
    }

    return textMenuConfig;
  }

  if (hasIntersection(ELEMENTS_NON_TEXT, types)) {
    return nonTextConfig;
  }

  return defaultMenuConfig;
};

export const getDefaultConfig = (
  nodes: Node[],
  isEmpty: boolean
): MenuItem[] => {
  const expanded = nodes.length > 1;

  const config = _getDefaultConfig(nodes, isEmpty);
  const filtered = filterConfig(config, (item) =>
    expanded ? !item.singleBlockAction : true
  );

  return filtered;
};

const prepareToSearch = (text: string) => text.replace(/\s/g, "").toLowerCase();

export const getSearchConfig = (
  nodes: Node[],
  isEmpty: boolean,
  search: string
): MenuItem[] => {
  const nestedConfig = getDefaultConfig(nodes, isEmpty);

  const items: MenuItem[] = [];
  crawl<any>(
    { children: nestedConfig },
    (item: MenuItem) => {
      if (!("children" in item)) {
        items.push(item);
      }
    },
    {}
  );

  return items.filter(
    (item: any) =>
      prepareToSearch(item.text).includes(search.toLowerCase()) ||
      item.alias?.some((a) => prepareToSearch(a).includes(search.toLowerCase()))
  );
};

export const getGroupConfig = (
  nodes: Node[],
  isEmpty: boolean,
  groupId: string
): MenuItem[] => {
  const nestedConfig = getDefaultConfig(nodes, isEmpty);

  const group = nestedConfig.find(
    (item) => "children" in item && item.id === groupId
  ) as GroupItem;

  return [MenuItems.back, ...group.children];
};

function hasIntersection<T>(setA: Set<T>, setB: Set<T>) {
  for (const a of setA) {
    if (setB.has(a)) {
      return true;
    }
  }

  return false;
}
