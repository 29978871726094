export const toDate = (timestamp: any): Date => {
  return timestamp.toDate();
};

export const toNullableDate = (timestamp: any | null): Date | null => {
  if (timestamp == null) {
    return null;
  }

  return toDate(timestamp);
};
