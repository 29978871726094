import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { db } from "firebaseInstance";

const useUserData = (userId: string | null) => {
  const docRef = userId ? doc(db, `users/${userId}`) : null;

  return useDocumentData(docRef, {
    idField: "uid",
  });
};

export { useUserData };
