import { Editor, Range } from "slate";

const withStream = (editor: Editor) => {
  const { insertBreak, deleteBackward, deleteFragment } = editor;

  // editor.insertBreak = () => {
  //   const container = document.querySelector("[data-slate-stream-container]");
  //
  //   if (container && editor.selection) {
  //     if (Range.isCollapsed(editor.selection)) {
  //       container.scrollBy({
  //         top: 36,
  //       });
  //     }
  //   }
  //
  //   insertBreak();
  // };
  //
  // editor.deleteBackward = (unit) => {
  //   const container = document.querySelector("[data-slate-stream-container]");
  //
  //   if (container && Range.isCollapsed(editor.selection)) {
  //     const path = Editor.path(editor, editor.selection, { depth: 1 });
  //     const atStartOfBlock = Range.includes(
  //       editor.selection,
  //       Editor.start(editor, path)
  //     );
  //     const atStartOfEditor = Range.includes(
  //       editor.selection,
  //       Editor.start(editor, [])
  //     );
  //
  //     if (atStartOfBlock && !atStartOfEditor) {
  //       container.scrollBy({
  //         top: -36,
  //       });
  //     }
  //   }
  //
  //   deleteBackward(unit);
  // };

  editor.deleteFragment = (direction) => {
    const container = document.querySelector("[data-slate-stream-container]");

    if (container && Range.isExpanded(editor.selection)) {
      const range = Editor.range(editor, []);
      const allSelected = Range.equals(editor.selection, range);

      if (allSelected) {
        container.scrollTo({ top: 0 });
      }
    }

    deleteFragment(direction);
  };

  return editor;
};

export default withStream;
