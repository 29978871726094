import { useCallback } from "react";
import { Range } from "slate";
import {
  isPointAtWordEnd,
  isWordAfterTrigger,
  getPointBefore,
} from "@udecode/plate-core";

import { EmojiComponent } from "components/slate/plugins/emoji/components/EmojiComponent";
import { insertEmoji } from "components/slate/plugins/emoji/transforms";
import { trigger } from "components/slate/plugins/emoji/constants";
import useMemoObject from "hooks/useMemoObject";
import { UseSlatePlugin } from "components/slate/types";
import createState from "components/slate/plugins/emoji/state";
import useZustandCreateStore from "hooks/useZustandCreateStore";
import { emojiIndex } from "components/slate/plugins/emoji/emojiIndex";

let localBeforeMatch = [""];

const useEmojiPlugin: UseSlatePlugin = () => {
  const { state, getState } = useZustandCreateStore(createState, []);

  const onChange = useCallback(
    (editor) => () => {
      const {
        setSearch,
        setValueIndex,
        setTargetRange,
        prevBeforeMatch,
        setPrevBeforeMatch,
      } = getState();

      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        const cursor = Range.start(selection);
        const { match: beforeMatch } = isWordAfterTrigger(editor, {
          at: cursor,
          trigger,
        });
        const startMarkup = trigger;
        const startMarkupPointBefore = getPointBefore(
          editor,
          selection.anchor,
          {
            matchString: startMarkup,
            skipInvalid: true,
          }
        );
        const endMarkupPointBefore = selection.anchor;
        const range = {
          anchor: startMarkupPointBefore,
          focus: endMarkupPointBefore,
        };
        if (
          beforeMatch &&
          localBeforeMatch &&
          prevBeforeMatch &&
          localBeforeMatch[0] !== beforeMatch[0] &&
          isPointAtWordEnd(editor, { at: cursor })
        ) {
          const [, word] = beforeMatch;
          setTargetRange(range);
          setSearch(word);
          setValueIndex(0);
          localBeforeMatch = beforeMatch;
          setPrevBeforeMatch(false);
          return;
        }

        setTargetRange(null);
      }
    },
    [getState, trigger]
  );

  const getFrictionalValue = (arr: any, lastIndex: any) => {
    for (let i = lastIndex; i > 0; i--) {
      if (arr[i] !== "empty") {
        return i;
      }
    }
  };

  const selectEmoji = useCallback(
    (editor, valueIndex) => {
      const { flatEmojis, targetRange, setTargetRange } = getState();

      const item = flatEmojis[valueIndex] as string;
      const pickedEmoji = emojiIndex.search(item)[0];

      if (pickedEmoji) {
        insertEmoji(editor, targetRange, pickedEmoji);
      }

      setTargetRange(null);
    },
    [getState]
  );
  const onKeyDown = useCallback(
    (editor) => (e: any) => {
      const {
        valueIndex,
        setTargetRange,
        setValueIndex,
        targetRange,
        flatEmojis,
        setCurRow,
        setPrevBeforeMatch,
      } = getState();

      const curRow = Math.ceil(valueIndex / 9) + 1;

      setCurRow(curRow);
      const totalRow =
        flatEmojis && flatEmojis.length > 0
          ? Math.ceil(flatEmojis.length / 9) + 1
          : 0;
      const prevRowLastItemIndx =
        curRow > 0 ? getFrictionalValue(flatEmojis, 9 * (curRow - 1) - 1) : 0;

      const nextRowFirstItemIndx =
        curRow < totalRow && flatEmojis && flatEmojis.length > 0
          ? 9 * curRow
          : flatEmojis.length - 1;

      if (targetRange) {
        switch (e.key) {
          case "ArrowDown":
            e.preventDefault();
            if (curRow < totalRow - 1) {
              if (flatEmojis[valueIndex + 9] === "empty") {
                setValueIndex(nextRowFirstItemIndx);
              } else {
                setValueIndex(valueIndex + 9);
              }
            }
            break;
          case "ArrowUp":
            e.preventDefault();
            if (curRow > 1) {
              if (flatEmojis[valueIndex - 9] === "empty") {
                setValueIndex(prevRowLastItemIndx);
              } else {
                setValueIndex(valueIndex - 9);
              }
            }
            break;
          case "Tab":
          case "Enter":
            e.preventDefault();
            selectEmoji(editor, valueIndex);
            break;
          case "Escape":
            e.preventDefault();

            setTargetRange(null);
            break;
          case "ArrowLeft":
            e.preventDefault();

            if (valueIndex > 0) {
              if (flatEmojis[valueIndex - 1] === "empty") {
                setValueIndex(prevRowLastItemIndx);
              } else {
                setValueIndex(valueIndex - 1);
              }
            }
            break;
          case "ArrowRight":
            e.preventDefault();

            if (valueIndex < flatEmojis.length - 1) {
              if (flatEmojis[valueIndex + 1] === "empty") {
                setValueIndex(nextRowFirstItemIndx);
              } else {
                setValueIndex(valueIndex + 1);
              }
            }
            break;
          default:
            break;
        }
      }

      setPrevBeforeMatch(true);
    },
    [getState, selectEmoji]
  );

  return {
    handlers: useMemoObject({
      onKeyDown,
    }),
    onChange,
    state: useMemoObject({
      ...state,
      selectEmoji,
    }),
  };
};

export { useEmojiPlugin, EmojiComponent };
