import AuthPageContainer from "components/AuthPageContainer";

import { Route, Switch } from "react-router-dom";

import NotFoundPage from "pages/other/NotFoundPage";
import Insight from "./Steps/Insight";
import SimpleRoute from "routing/SimpleRoute";
import DailyTemplate from "./Steps/DailyTemplate";
import CalReminder from "./Steps/CalReminder";
import Success from "./Steps/Success";
import YouHateFun from "./Steps/YouHateFun";

const OnboardingTemplate = () => {
  return (
    <Switch>
      <SimpleRoute
        layout={AuthPageContainer}
        path="/onboarding"
        exact
        component={Insight}
      />
      <SimpleRoute
        layout={AuthPageContainer}
        path="/onboarding/daily"
        exact
        component={DailyTemplate}
      />
      <SimpleRoute
        layout={AuthPageContainer}
        path="/onboarding/reminder"
        component={CalReminder}
      />
      <SimpleRoute
        layout={AuthPageContainer}
        path="/onboarding/success"
        exact
        component={Success}
      />
      <SimpleRoute
        layout={AuthPageContainer}
        path="/onboarding/badmascots"
        exact
        component={YouHateFun}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default OnboardingTemplate;
