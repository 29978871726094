import React from "react";

import styles from "./index.module.scss";

type Props = {
  count: number;
  total: number;
  name: string;
};

const TableCount = ({ count, total, name }: Props) => {
  return (
    <span className={styles.container}>
      <span className={styles.count}>{count}</span> of{" "}
      <span className={styles.count}>{total}</span> {name}
    </span>
  );
};

export default TableCount;
