import React, { memo } from "react";

import { BeatLoader } from "react-spinners";
import useLoadingTimeout from "hooks/useLoadingTimeout";

import styles from "./index.module.scss";

type Props = {
  loading?: boolean;
  loadingTimeout?: number;
};

const CardSpinner = memo((props: Props) => {
  const { loadingTimeout = 0 } = props;
  const loading = useLoadingTimeout(props.loading || true, loadingTimeout);

  if (!loading) {
    return null;
  }

  return (
    <div className={styles.spinnerContainer}>
      <BeatLoader color="var(--primary-color)" />
    </div>
  );
});

export default CardSpinner;
