import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import { Node } from "slate";
import { useFocused, useSlate } from "slate-react";
import isHotkey from "is-hotkey";

import FormattingMenuToolbar from "components/slate/plugins/menus/formatting/FormattingMenu/FormattingMenuToolbar";
import SelectionToolbar from "components/slate/plugins/menus/formatting/FormattingMenu/SelectionToolbar";
import { formattingMenuModes } from "components/slate/plugins/menus/formatting/constants";
import useReactNativeWebView from "hooks/useReactNativeWebView";
import { UseSlatePlugin } from "components/slate/types";
import useZustandCreateStore from "hooks/useZustandCreateStore";
import createState, { FormattingMenuState } from "./state";
import useMemoObject from "hooks/useMemoObject";
import useOnClickOutside from "hooks/useOnClickOutside";

const widths = {
  [formattingMenuModes.formatting]: 237,
  [formattingMenuModes.links]: 300,
  [formattingMenuModes.snippets]: 300,
};

export const useFormattingMenuPlugin: UseSlatePlugin<
  {},
  FormattingMenuState
> = () => {
  const { state, getState } = useZustandCreateStore(createState, []);

  const onKeyDown = useCallback(
    (editor) => (e: any) => {
      const { setMode } = getState();

      if (isHotkey("mod+k", e)) {
        setMode(formattingMenuModes.links);
      }
    },
    [getState]
  );

  return {
    handlers: { onKeyDown },
    state: useMemoObject<FormattingMenuState>({
      mode: state.mode,
      setMode: state.setMode,
    }),
  };
};

const FormattingMenuComponent = memo((props: FormattingMenuState) => {
  const ref = useRef(null);
  const { mode, setMode } = props;

  const editor = useSlate();
  const focused = useFocused();

  const selection = editor.selection;
  const [lastSelection, setLastSelection] = useState(selection);

  useEffect(() => {
    selection && setLastSelection(selection);
  }, [selection]);

  useEffect(() => {
    if (mode === formattingMenuModes.formatting) {
      setLastSelection(selection);
    }
  }, [mode]);

  useOnClickOutside(ref, (e: any) => {
    if (mode !== formattingMenuModes.formatting) {
      setMode(formattingMenuModes.formatting);
    }
  });

  const isSelectionValid =
    !selection || Node.has(editor, selection.anchor.path);

  if (!isSelectionValid) {
    // without this check isMarkActive causes error while calling Node.leaf function
    return null;
  }

  return (
    <SelectionToolbar
      ref={ref}
      hidden={!focused && mode === formattingMenuModes.formatting}
      width={widths[mode]}
      selection={
        mode === formattingMenuModes.formatting ? selection : lastSelection
      }
    >
      <FormattingMenuToolbar
        editor={editor}
        mode={mode}
        setMode={setMode}
        lastSelection={lastSelection}
        {...props}
      />
    </SelectionToolbar>
  );
});

export const FormattingMenu = memo((props: FormattingMenuState) => {
  const { isReactNativeWebView } = useReactNativeWebView();

  if (isReactNativeWebView) {
    return null;
  }

  return <FormattingMenuComponent {...props} />;
});
