import React from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import { Document } from "thunk-core";
import Button from "framework/components/form/Button";
import useEventListener from "hooks/useEventListener";
import SimpleTooltip from "components/SimpleTooltip";
import { useResizeDetector } from "react-resize-detector";
import { ToTopIcon } from "thunk-icons";
import { HeaderImagePicker } from "components/HeaderImagePicker";
import { useDocumentsStore } from "stores/store";

import styles from "./index.module.scss";

const MAXIMUM_HEADER_HEIGHT = 240;
const MINIMUM_HEADER_HEIGHT = 60;
const SHOW_TITLE_START_THRESHOLD = -30;

type CardNavigationActionsProps = {
  leftActions?: React.ReactNode;
  pageTitle?: string;
  isBacklinksCard?: boolean;
  isMiniHeader?: boolean;
  rightActions?: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
  isHovered?: boolean;
  toolbar?: React.ReactNode;
  document?: Document;
};

const CardNavigationActions = observer(
  ({
    leftActions,
    pageTitle = "",
    isBacklinksCard,
    isMiniHeader,
    rightActions,
    containerRef,
    toolbar,
    document,
  }: CardNavigationActionsProps) => {
    const [actualHeightDifference, setActualHeightDifference] = React.useState(
      MAXIMUM_HEADER_HEIGHT
    );
    const ticking = React.useRef(false);
    const { width, ref } = useResizeDetector();
    const documentsStore = useDocumentsStore(document.type);

    const onUpdateHeaderImage = React.useCallback(
      (newUrl?: string) => {
        documentsStore.updateDocument(document.id, {
          headerImage: newUrl,
        });
      },
      [document.id, documentsStore]
    );

    useEventListener(
      containerRef?.current,
      "scroll",
      (event) => {
        setIsHovered(false); // if we are already hovering over the header image when we start scrolling, we need to hide the change image button
        const { scrollTop } = event.target as HTMLDivElement;
        if (!ticking.current) {
          window.requestAnimationFrame(() => {
            setActualHeightDifference(MAXIMUM_HEADER_HEIGHT - scrollTop);
            ticking.current = false;
          });

          ticking.current = true;
        }
      },
      [containerRef?.current, ticking]
    );

    const heightToSet = Math.max(
      Math.min(actualHeightDifference, MAXIMUM_HEADER_HEIGHT),
      MINIMUM_HEADER_HEIGHT
    );

    const marginToSet = Math.max(10, 100 + actualHeightDifference);

    // Handle hiding and showing of button to change header image
    const [isHovered, setIsHovered] = React.useState(false);
    const showButton = (e) => {
      e.preventDefault();
      if (containerRef?.current.scrollTop == 0) {
        setIsHovered(true);
      } else {
        return;
      }
    };

    const hideButton = (e) => {
      e.preventDefault();
      setIsHovered(false);
    };
    // End handle hiding and showing of button to change header image

    return (
      <div
        ref={ref}
        className={cn(styles.container, {
          [styles.backlinksCardContainer]: isBacklinksCard, // backlinks card container shouldn't be sticky
          [styles.miniCardContainer]: isMiniHeader, // backlinks card container shouldn't be sticky
        })}
        data-header-md={width <= 789 ? true : undefined}
        data-header-sm={width <= 630 ? true : undefined}
        data-header-xs={width <= 500 ? true : undefined}
        data-header-xxs={width <= 420 ? true : undefined}
      >
        <div
          className={cn(styles.inner, {
            [styles.backlinksCardInner]: isBacklinksCard, // backlinks card inner should only be 60px tall
            [styles.miniCardInner]: isMiniHeader, // backlinks card container shouldn't be sticky
          })}
          style={{ height: heightToSet }}
          // Handlers for hiding and showing button
          onMouseEnter={(e) => showButton(e)}
          onMouseLeave={(e) => hideButton(e)}
        >
          {document?.headerImage && (
            <img
              alt="Card Header"
              className={styles.todayImage}
              src={document?.headerImage}
            />
          )}
          <div className={styles.leftActions}>{leftActions}</div>
          <div
            className={styles.centerActions}
            style={{ marginTop: marginToSet }}
          >
            <SimpleTooltip
              content={"Scroll to top"}
              animation="fade"
              duration={[300, 100]}
            >
              <Button
                variant="circularCardHeader"
                size="large"
                onClick={
                  () =>
                    containerRef?.current.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    }) // scroll card to top when title button is clicked
                }
                btnClass={cn(styles.topTitleButton, {
                  [styles.visible]:
                    actualHeightDifference < SHOW_TITLE_START_THRESHOLD,
                  [styles.hasNoTitle]: !pageTitle,
                })}
              >
                {" "}
                {
                  <ToTopIcon
                    className={cn(styles.toTopIcon, {
                      [styles.visible]: !pageTitle, // only show this icon if there is no page title
                    })}
                    size={22}
                  />
                }
                <span className={styles.titleText}>{pageTitle}</span>
              </Button>
            </SimpleTooltip>
          </div>
          <div className={styles.rightActions}>
            {/* Button for changing header image */}
            <div
              className={cn(styles.changeHeaderImageButton, {
                [styles.visible]: isHovered,
              })}
            >
              <HeaderImagePicker onUpdateHeaderImage={onUpdateHeaderImage} />
            </div>
            {/* End for changing header image */}
            {toolbar}
          </div>
        </div>
      </div>
    );
  }
);

export default CardNavigationActions;
