import crawl from "tree-crawl";

import { ListTypes } from "components/slate/plugins/list/types";

export const crawlDOM = (
  nodes: Iterable<Node>,
  fn: (node: Node, context: crawl.Context<Node>) => void
) =>
  crawl({ childNodes: nodes } as Node, fn, {
    getChildren: (node) => Array.from(node.childNodes),
  });

export const makeListItemAttributes = ({
  depth,
  listType,
  index = 0,
  checked = false,
  dueDate = null,
}: {
  depth: number;
  listType: ListTypes;
  index?: number;
  checked?: boolean;
  dueDate?: string;
}) => {
  return {
    "data-slate-list-item-depth": depth,
    "data-slate-list-item-type": listType,
    "data-slate-list-item-index": index,
    "data-slate-list-item-checked": checked,
    "data-slate-list-item-due-date": dueDate || "",
  };
};

export const getListItemProps = (domNode: Element) => {
  const depth = Number(domNode.getAttribute("data-slate-list-item-depth"));
  const listType = domNode.getAttribute(
    "data-slate-list-item-type"
  ) as ListTypes;
  const index = Number(domNode.getAttribute("data-slate-list-item-index"));
  const checked =
    domNode.getAttribute("data-slate-list-item-checked") === "true";
  const dueDate = domNode.getAttribute("data-slate-list-item-due-date") || null;

  return {
    depth,
    listType,
    index,
    checked,
    dueDate,
  };
};

export const isDOMList = (node: Node) =>
  node.nodeType === 1 && (node.nodeName === "UL" || node.nodeName === "OL");
export const isDOMListItemComponent = (node: Node) =>
  node.nodeType === 1 &&
  (node as HTMLElement).getAttribute("data-slate-node-type") === "list_item";
