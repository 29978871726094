import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSlateStatic } from "slate-react";
import { useResizeDetector } from "react-resize-detector";
import { Transforms } from "slate";
import { descend, sortBy, sortWith } from "ramda";
import { observer } from "mobx-react-lite";

import { AtMenuProps } from "components/slate/plugins/menus/atMenu/types";
import RangePopper from "components/slate/components/RangePopper";
import SectionMenuList from "components/slate/plugins/menus/atMenu/components/SectionMenuList";
import SectionMenuListItem from "components/slate/plugins/menus/atMenu/components/SectionMenuListItem";
import SectionMenuListHeader from "components/slate/plugins/menus/atMenu/components/SectionMenuListHeader";
import {
  AtMenuItem,
  makePageBacklinkMeuItem,
  makeNoteBacklinkMenuItem,
  makeTimestampMenuItem,
  highlightMatch,
  makeNewPageBacklinkMenuItem,
} from "components/slate/plugins/menus/atMenu/items/utils";
import { useStaticProps } from "components/slate/hooks/useStaticProps";
import { useCurrentUserId } from "db/currentUser";
import { getTimestampItems } from "components/slate/plugins/menus/atMenu/items/getTimestampItems";
import { getDateItems } from "components/slate/plugins/menus/atMenu/items/getDateItems";
import { makeDateId } from "helpers";
import { useDocumentsStore } from "stores/store";
import { Document, DocumentType } from "thunk-core";

import styles from "components/slate/plugins/menus/atMenu/components/AtMenu.module.scss";

const AtMenu = memo((props: AtMenuProps) => {
  const { initialTarget } = props;
  const [height, setHeight] = useState(0);

  return (
    <RangePopper
      isVisible={initialTarget != null}
      range={initialTarget}
      className={styles.menuTooltip}
      style={{ height: height }}
    >
      {initialTarget != null && (
        <AtMenuContent {...props} onHeightChange={setHeight} />
      )}
    </RangePopper>
  );
});

export default AtMenu;

const AtMenuContent = observer(
  (props: AtMenuProps & { onHeightChange: (height: number) => void }) => {
    const { height = 0, ref: containerRef } = useResizeDetector();
    const { initialTarget, target, search, closeMenu, onHeightChange } = props;
    const editor = useSlateStatic();
    const { documentId, documentType } = useStaticProps();
    const userId = useCurrentUserId();
    const pagesStore = useDocumentsStore(DocumentType.PAGE);

    const documents = pagesStore.getDocuments();

    const getBreadcrumbHint = (page: Document, forNewPage = false) => {
      const titles = [page.title];
      const build = (parentId: string | null) => {
        if (parentId) {
          const parent = pagesStore.getDocument(parentId);
          if (parent) {
            titles.unshift(parent.title);
            build(parent.parentId);
          }
        }
      };

      build(page.parentId);

      const result =
        !forNewPage && titles.length === 1
          ? "All Notes"
          : titles.length > 2
          ? `${titles[0]} > ... > ${titles[titles.length - 1]}`
          : titles.join(" > ");

      return result;
    };

    const filteredDocuments = useMemo(() => {
      if (!documents) {
        return null;
      }

      const sorted = sortDocuments(search, documents);

      const result: Document[] = [];
      for (const document of sorted) {
        if (document.title && highlightMatch(document.title, search).length) {
          document.id !== documentId && result.push(document);
        }

        if (result.length >= 10) {
          return result;
        }
      }

      return result;
    }, [documents, search, documentId]);

    useEffect(() => {
      onHeightChange(height);
    }, [height, onHeightChange]);

    const handleSelect = useCallback(
      (e: Event, item: AtMenuItem) => {
        Transforms.delete(editor, { at: target });
        Transforms.select(editor, editor.selection);
        item.applyItem(editor);
        closeMenu();
      },
      [editor, target, closeMenu]
    );

    const handleClose = useCallback(() => {
      closeMenu();
    }, [editor, closeMenu]);

    const sections = useMemo(() => {
      const backlinkExists = filteredDocuments?.find(
        (b) => b.title.toLowerCase() === search.toLowerCase()
      );
      const timestampItems = getTimestampItems(search);
      const dateItems = getDateItems(search);

      const document = pagesStore.getDocument(documentId);
      const doShowBacklinks = filteredDocuments && filteredDocuments.length > 0;
      const doShowCreateSubpage =
        search.trim() !== "" &&
        documentType === DocumentType.PAGE &&
        backlinkExists?.parentId !== documentId &&
        search;
      const doShowCreatingNewBacklink = search;
      const doSnowTimestamps = timestampItems.length > 0;
      const doShowDates = dateItems.length > 0;

      const sections = [
        doShowDates && {
          sort: 0,
          title: "Dates",
          items: dateItems.map((item) =>
            makeNoteBacklinkMenuItem(
              item.title,
              `@${item.hint}`,
              makeDateId(item.date),
              userId
            )
          ),
        },
        doShowBacklinks && {
          sort: 0,
          title: "Notes",
          items: filteredDocuments.map((d) =>
            makePageBacklinkMeuItem(d.title, getBreadcrumbHint(d), d.id, userId)
          ),
        },
        doSnowTimestamps && {
          sort: ["now", "curr", "current"].includes(search) ? -1 : 0,
          title: "Timestamps",
          items: timestampItems.map((item) =>
            makeTimestampMenuItem(item.title, `@${item.hint}`)
          ),
        },
        doShowCreatingNewBacklink && {
          sort: 0,
          title: "Create Note",
          className: styles.createNoteHeader,
          items: [
            ...(doShowCreateSubpage
              ? [
                  makeNewPageBacklinkMenuItem(
                    search,
                    `${getBreadcrumbHint(document, true)} > `,
                    userId,
                    documentId,
                    documentType
                  ),
                ]
              : []),
            makeNewPageBacklinkMenuItem(
              search,
              `All Notes >`,
              userId,
              null,
              documentType
            ),
          ],
        },
      ].filter(Boolean);

      return sortBy((x) => x.sort, sections);
    }, [search, filteredDocuments, documentId, documentType, userId]);

    return (
      <SectionMenuList
        containerRef={containerRef}
        isOpened={initialTarget != null}
        sections={sections}
        renderListItem={({ item, isActive }) => (
          <SectionMenuListItem
            search={search}
            item={item}
            isActive={isActive}
            onSelect={handleSelect}
          />
        )}
        renderSectionHeader={({ section }) => (
          <SectionMenuListHeader section={section} />
        )}
        onSelect={handleSelect}
        onCancel={handleClose}
      />
    );
  }
);

const sortDocuments = (search: string, documents: Document[]) =>
  sortWith(
    [
      descend<Document>(
        (b) => b.title?.toLowerCase() === search?.toLowerCase()
      ),
    ],
    documents
  );
