import { Element } from "slate";

import { ListItemType } from "components/slate/plugins/list/types";
import { HorizontalRuleType } from "components/slate/plugins/horizontalRule/types";
import { CodeBlockType } from "components/slate/plugins/codeBlock/types";
import { FileType, ImageType } from "components/slate/plugins/file/types";
import { BlockquoteType } from "components/slate/plugins/blockquote/types";
import { ParagraphType } from "components/slate/plugins/paragraph/types";
import {
  Heading1Type,
  Heading2Type,
  Heading3Type,
} from "components/slate/plugins/heading/types";
import { MediaEmbedType } from "components/slate/plugins/mediaEmbed/types";

export const ELEMENTS_TEXT_BLOCKS = new Set<Element["type"]>([
  Heading1Type,
  Heading2Type,
  Heading3Type,
  BlockquoteType,
  ParagraphType,
  ListItemType,
]);

export const ELEMENTS_NON_TEXT = new Set<Element["type"]>([
  CodeBlockType,
  ImageType,
  FileType,
  MediaEmbedType,
  HorizontalRuleType,
]);
