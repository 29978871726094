import { renderLeaf } from "./renderLeaf";
import * as handlers from "./handlers";
import { UseSlatePlugin } from "components/slate/types";

const useMarksPlugin: UseSlatePlugin = () => {
  return {
    handlers,
    renderLeaf,
  };
};

export default useMarksPlugin;
