import { Descendant } from "slate";

export type ImageType = "img";
export const ImageType: ImageType = "img";

export type ImageElement = {
  id: string;
  type: ImageType;
  children: Descendant[];
  url: string;
};

export type FileType = "file";
export const FileType: FileType = "file";

export type FileElement = {
  id: string;
  type: FileType;
  children: Descendant[];
  extension: string;
  fileName: string;
  fileSize: number;
  url: string;
};
