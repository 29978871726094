import React from "react";
import cn from "classnames";

import styles from "./index.module.scss";

const HelpDrawer = ({ isOpened, children }: any) => {
  return (
    <div>
      <div
        className={cn(styles.drawer, {
          [styles.drawerClosed]: !isOpened,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default HelpDrawer;
