import React, { createContext, useContext, useEffect } from "react";
import isHotkey from "is-hotkey";
import { Editor } from "slate";

import StreamOverlay from "./StreamOverlay";
import useWindowEventListener from "hooks/useWindowEventListener";
import useZustandCreateStore from "hooks/useZustandCreateStore";
import {
  createState,
  StreamState,
} from "components/slate/plugins/stream/state";

import styles from "./index.module.scss";
import { createPortal } from "react-dom";
import StreamCloseButton from "components/slate/plugins/stream/components/StreamCloseButton";
import StreamScreen from "components/slate/plugins/stream/components/StreamScreen";

const StreamContext = createContext<StreamState>({} as StreamState);
export const useStreamContext = () => useContext(StreamContext);

const StreamStateContext = createContext<{ getState: () => StreamState }>(
  {} as any
);
export const useStreamStateContext = () => useContext(StreamStateContext);

type Props = {
  editor: Editor;
  children: React.ReactNode;
};

const EDITOR_TO_STREAM_STATE = new WeakMap<Editor, () => StreamState>();

export const toggleStreamMode = (editor: Editor) => {
  const getState = EDITOR_TO_STREAM_STATE.get(editor);
  if (getState) {
    const { isStreamActive, setIsStreamActive } = getState();
    setIsStreamActive(!isStreamActive);
  }
};

const StreamProvider = ({ editor, children }: Props) => {
  const { state, getState } = useZustandCreateStore(createState, []);
  const { isStreamActive, setIsStreamActive } = state;

  useEffect(() => {
    isStreamActive
      ? document.body.classList.add(styles.noScroll)
      : document.body.classList.remove(styles.noScroll);
  }, [isStreamActive]);

  useWindowEventListener("keydown", (e) => {
    if (isHotkey("Escape", e) && !e.defaultPrevented) {
      setIsStreamActive(false);
    }
  });

  useEffect(() => {
    return () => {
      editor && EDITOR_TO_STREAM_STATE.delete(editor);
    };
  }, []);

  editor && EDITOR_TO_STREAM_STATE.set(editor, getState);

  return (
    <StreamStateContext.Provider value={{ getState }}>
      <StreamContext.Provider value={state}>
        <StreamOverlay />
        <StreamScreen />
        {children}
      </StreamContext.Provider>
    </StreamStateContext.Provider>
  );
};

export default StreamProvider;
