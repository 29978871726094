import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ArrowLeftIcon } from "thunk-icons";

import { Document } from "thunk-core";
import { useDocumentsStore } from "stores/store";
import { EditorType } from "components/slate/types";
import { useSaveNotification } from "hooks/useSaveNotification";
import useScrollToTop from "hooks/useScrollToTop";
import Helmet from "components/Helmet";
import CardNavigationActions from "components/editor/CardNavigationActions";
import CardPageLayout from "components/layout/CardPageLayout";
import EditorCard from "components/editor/EditorCard";
import AppResult from "components/results/AppResult";
import Button from "framework/components/form/Button";
import CardTemplateToolbar from "components/editor/toolbar/CardTemplateToolbar";
import { getEditorPathname } from "helpers";
import { useCurrentUserId } from "db/currentUser";
import DocumentEditor from "components/editor/DocumentEditor";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import { useEditingSession } from "stores/hooks/useEditingSession";
import { useDocumentUserContext } from "providers/DocumentUserProvider";
import { DocumentType } from "thunk-core";

import styles from "./index.module.scss";

type Params = { snippetId: string };

const TemplateEditingPage = () => {
  const { snippetId } = useParams<Params>();
  const [ready, setReady] = useState(false);
  const history = useHistory();

  //stop body scrolling on this page
  useEffect(() => {
    document.body.removeAttribute("data-not-public");
    document.body.removeAttribute("daily-note");
    document.body.setAttribute("no-side-panel-page", "true");
  });

  const { isDocumentOwner } = useDocumentUserContext();
  const userId = useCurrentUserId();
  const documentType = DocumentType.SNIPPET;
  const documentsStore = useDocumentsStore(documentType);

  useSaveNotification();
  useScrollToTop([snippetId]);

  useEffect(() => {
    if (snippetId === "new" && isDocumentOwner) {
      setReady(false);
      const id = documentsStore.createNewDocument({
        type: documentType,
        userId: userId,
      });
      history.push(getEditorPathname(documentType, id, null));
    } else {
      setReady(true);
    }
  }, [snippetId]);

  if (!ready) {
    return <EditorCard title={""} loading={true} loadingTimeout={300} />;
  }

  return <TemplateMainEditor />;
};

export default TemplateEditingPage;

const TemplateMainEditor = observer(() => {
  const params = useParams<Params>();
  const { snippetId: documentId } = params;
  const editorType = EditorType.MainEditor;
  const documentType = DocumentType.SNIPPET;

  const { documentUserId } = useDocumentUserContext();

  const {
    loading,
    error,
    title,
    slateId,
    content,
    document,
  } = useEditingSession({
    userId: documentUserId,
    editorType,
    documentId,
    dateId: null,
    documentType,
  });

  const handleCopy = async () => {
    // const { title } = template;
    //
    // const notificationId = notify.loading(
    //     `The template "${title}" is being added`
    // );
    //
    // try {
    //   await copyTemplateMutation.mutateAsync({
    //     template,
    //   });
    //   notify.success(`The template "${title}" is added`);
    //
    //   history.push("/templates");
    // } catch (error) {
    //   console.error(error);
    //   notify.error(`The template "${title}" is not added`);
    // } finally {
    //   notify.remove(notificationId);
    // }
  };

  return (
    <TemplateEditingPageLayout
      title={title}
      handleCopy={() => handleCopy()}
      document={document}
    >
      {(function () {
        if (loading) {
          return (
            <EditorCard title={title} loading={true} loadingTimeout={300} />
          );
        }

        if (error) {
          console.error(error);
          return (
            <EditorCard title={title}>
              <AppResult resultType="fetching" isCenteredContent={true} />
            </EditorCard>
          );
        }

        if (!document) {
          return (
            <EditorCard title={title}>
              <AppResult resultType="notFound" isCenteredContent={true} />
            </EditorCard>
          );
        }

        return (
          <DocumentEditor
            slateId={slateId}
            editorType={EditorType.MainEditor}
            documentId={documentId}
            documentType={documentType}
            document={document}
            content={content}
            className={styles.templateEditor}
          />
        );
      })()}
    </TemplateEditingPageLayout>
  );
});

const TemplateEditingPageLayout = ({
  children,
  title,
  handleCopy,
  document,
}: React.PropsWithChildren<{
  title: string | null;
  handleCopy?: () => void;
  document: Document;
}>) => {
  const { isDocumentOwner } = useDocumentUserContext();

  const actions = (
    <div style={{ display: "flex" }}>
      <Button
        as={Link}
        to="/snippets"
        size="large"
        variant="circularCardHeader"
        icon={ArrowLeftIcon}
        iconSize={12}
      >
        All Templates
      </Button>
    </div>
  );

  const rightActions = !isDocumentOwner && (
    <Button variant="primary" onClick={handleCopy}>
      + Add to My Templates
    </Button>
  );

  return (
    <>
      <Helmet pageTitle={title} />
      <CardPageLayout>
        {document && (
          <CardNavigationActions
            document={document}
            isMiniHeader={true}
            leftActions={actions}
            rightActions={rightActions}
            toolbar={
              <CardTemplateToolbar
                readOnly={!isDocumentOwner}
                document={document}
              />
            }
          />
        )}
        <WidgetErrorBoundary>{children}</WidgetErrorBoundary>
      </CardPageLayout>
    </>
  );
};
