import React, { useEffect, useState } from "react";
import { Editor, Range } from "slate";
import { ReactEditor, useSlateStatic } from "slate-react";
import * as PopperJS from "@popperjs/core";
import { Modifier, usePopper } from "react-popper";
import cn from "classnames";

import { PortalBody } from "framework/components/Portal";
import useOnClickOutside from "hooks/useOnClickOutside";

import styles from "components/slate/components/RangePopper/index.module.scss";

type RangePopperProps = {
  children: React.ReactNode;
  isVisible: boolean;
  className?: string;
  style?: React.CSSProperties;
  range: Range | null;
  popperOptions?: Omit<Partial<PopperJS.Options>, "modifiers"> & {
    createPopper?: typeof PopperJS.createPopper;
    modifiers?: ReadonlyArray<Modifier<any, any>>;
  };
  onClickOutside?: (e: MouseEvent) => void;
};

const RangePopper = ({
  isVisible,
  className,
  style,
  range,
  children,
  popperOptions = {},
  onClickOutside,
}: RangePopperProps) => {
  const editor = useSlateStatic();
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [virtualElement, setVirtualElement] = useState(null);

  const updateVirtualElement = (editor: Editor, range: Range) => {
    let virtualElement;
    if (!range) {
      virtualElement = null;
    } else {
      virtualElement = {
        getBoundingClientRect: () => {
          const domRange = ReactEditor.toDOMRange(editor, range);
          const rect = domRange.getBoundingClientRect();
          return rect;
        },
      };
    }

    setVirtualElement(virtualElement);
  };

  useEffect(() => {
    updateVirtualElement(editor, range);
  }, [editor, range]);

  const popper = usePopper(virtualElement, popperElement, {
    strategy: "fixed",
    ...popperOptions,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [130, 5],
        },
      },
      ...(popperOptions.modifiers || []),
    ],
  });

  useOnClickOutside([{ current: popperElement }], onClickOutside);

  return (
    <PortalBody>
      <div
        ref={setPopperElement}
        style={{ ...popper.styles.popper, ...style }}
        {...popper.attributes.popper}
        className={cn(styles.rangePopper, className, {
          [styles.visible]: isVisible && virtualElement,
        })}
      >
        {children}
      </div>
    </PortalBody>
  );
};

export default RangePopper;
