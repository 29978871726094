import React from "react";
import { Link as RRLink } from "react-router-dom";

import styles from "./index.module.scss";

function Link(props: any) {
  if (props.href) {
    return (
      <a href={props.href} className={styles.link}>
        {props.children}
      </a>
    );
  }

  return <RRLink {...props} className={styles.link} />;
}

export default Link;
