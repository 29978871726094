import { useAuth } from "auth/AuthProvider";
import { AuthAccessTypes } from "auth/types";

export const useAppReadOnly = () => {
  const { authAccessType } = useAuth();

  const readOnly =
    authAccessType === AuthAccessTypes.subscriptionExpired ||
    authAccessType === AuthAccessTypes.trialExpired;

  return readOnly;
};
