import { createContext, useCallback, useContext } from "react";
import { Editor } from "slate";

import useMemoObject from "hooks/useMemoObject";

type ComponentsMenuProviderProps = {
  currentElement: HTMLElement | null;
  openComponentsMenu: (editor: Editor, ref: HTMLElement | null) => void;
  closeComponentsMenu: (
    editor: Editor,
    { focus }?: { focus?: boolean }
  ) => void;
};

type ComponentsMenuContextValue = ComponentsMenuProviderProps & {
  getIsMenuOpened: (domElement: HTMLElement) => boolean;
};

const ComponentsMenuContext = createContext<ComponentsMenuContextValue>(null);

export const useComponentsMenuContext = () => useContext(ComponentsMenuContext);

export const ComponentsMenuProvider = ({
  children,
  value,
}: React.PropsWithChildren<{ value: ComponentsMenuProviderProps }>) => {
  const getIsMenuOpened = useCallback(
    (domElement: HTMLElement) => {
      return value.currentElement === domElement;
    },
    [value.currentElement]
  );

  const memoizedValue = useMemoObject({ ...value, getIsMenuOpened });

  return (
    <ComponentsMenuContext.Provider value={memoizedValue}>
      {children}
    </ComponentsMenuContext.Provider>
  );
};
