import { Descendant } from "slate";

export type CodeBlockType = "code_block";
export const CodeBlockType: CodeBlockType = "code_block";

export type CodeLineType = "code_line";
export const CodeLineType: CodeLineType = "code_line";

export type CodeBlockElement = {
  id: string;
  type: CodeBlockType;
  children: Descendant[];
};

export type CodeLineElement = {
  id: string;
  type: CodeLineType;
  children: Descendant[];
};
