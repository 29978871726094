import { UseSlatePlugin } from "components/slate/types";
import { withLinkBasedElement } from "components/slate/plugins/pasteLink/withLinkBasedElement";

const usePasteLinkPlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withLinkBasedElement,
  };
};

export default usePasteLinkPlugin;
