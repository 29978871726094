import * as d3 from "d3";

import { GraphData } from "pages/app/GraphPage/types";
import { Selection } from "d3-selection";
import { GraphLink, GraphNode } from "stores/types";

const rectWidth = 30;
const rectHeight = 44;

const ticked = (link: any, node: any) => () => {
  link
    .attr("x1", (d: any) => d.source.x + 4)
    .attr("y1", (d: any) => d.source.y + 6.5)
    .attr("x2", (d: any) => d.target.x + 4)
    .attr("y2", (d: any) => d.target.y + 6.5);

  node.attr("transform", function (d: any) {
    return "translate(" + d.x + "," + d.y + ")";
  });
};

const createGraph = (container: any, data: any, props: any) => {
  // constants
  const graphHeight = container.offsetHeight;
  const graphWidth = container.offsetWidth;

  // props based variables
  let isMobileScreen: any;
  let onClickOutside: any;
  let onNodeClick: any;
  let center: any;
  let defaultScale: any;
  let scale: any;

  // ui
  let linksContainer: Selection<SVGElement, unknown, null, undefined> = null;
  let nodesContainer: Selection<SVGElement, unknown, null, undefined> = null;
  let linksSelection: Selection<
    SVGElement,
    GraphLink,
    SVGElement,
    undefined
  > = null;
  let nodesSelection: Selection<
    SVGElement,
    GraphNode,
    SVGElement,
    undefined
  > = null;

  const draw = () => {
    updateProps(props);
    scale = defaultScale;

    const zoom = createZoom();
    const simulation = createSimulation();
    const svg: Selection<SVGSVGElement, unknown, null, undefined> = createSvg();
    const root: Selection<SVGElement, unknown, null, undefined> = createRoot(
      svg
    );

    initializeLinks(root);
    initializeNodes(root);

    const {
      selectNode,
      deselectNodes,
      handleMouseOver,
      handleMouseOut,
    } = makeActions();

    const update = makeUpdateFunction({
      simulation,
      zoom,
      svg,
      root,
      handleMouseOver,
      handleMouseOut,
    });

    svg.call(zoomEvents({ zoom, root }));
    svg.call(zoom.scaleBy, scale);
    svg.on("dblclick.zoom", null);
    svg.on("dblclick", (e: any) => {
      if (e.target === e.currentTarget) {
        svg
          .transition()
          .duration(500)
          .call(zoom.transform, d3.zoomIdentity.scale(defaultScale));
      }
    });
    svg.on("click", (e: any) => {
      if (e.target === e.currentTarget) {
        onClickOutside && onClickOutside(e);
      }
    });

    return {
      simulation,
      zoom,
      root,
      svg,
      selectNode,
      deselectNodes,
      update,
      updateProps,
      dispose: () => {
        simulation.stop();
      },
    };
  };

  const updateProps = (props: any) => {
    isMobileScreen = props.isMobileScreen;
    onClickOutside = props.onClickOutside;
    onNodeClick = props.onNodeClick;
    center = isMobileScreen ? [0, -50] : [0, -0.03 * graphHeight - 30];
    defaultScale = isMobileScreen ? 0.5 : 0.6;
  };

  const makeUpdateFunction = ({
    simulation,
    svg,
    root,
    zoom,
    handleMouseOver,
    handleMouseOut,
  }: any) => (data: GraphData) => {
    const { nodes, links } = data;
    // Make a shallow copy to protect against mutation, while
    // recycling old nodes to preserve position and velocity.
    const old = new Map(nodesSelection.data().map((d: any) => [d.id, d]));
    const newNodes = nodes.map((d: any) =>
      Object.assign(old.get(d.id) || {}, d)
    );
    const newLinks = links.map((d: any) => Object.assign({}, d));

    nodesSelection = nodesSelection
      .data(newNodes, (d) => d.id)
      .join((enter) => {
        const nodesSelection = enter
          .append("g")
          .attr("class", "graphNode")
          .attr("id", (d) => d.id);

        nodesSelection
          .append("rect")
          .attr("x", -rectWidth / 2 + 4)
          .attr("y", -rectHeight / 2 + 8)
          .attr("rx", 7);

        nodesSelection
          .append("foreignObject")
          .attr("class", "nodeLabelContainer")
          .attr("width", 150)
          .attr("height", 1)
          .attr("y", 37)
          .attr("x", -70)
          .append("xhtml:div")
          .attr("class", "nodeLabel")
          .html((d) => d.title);

        return nodesSelection;
      });

    nodesSelection.call(dragEvents({ simulation }));
    let clickTimeout: any = null;
    nodesSelection.on("click", (e: any, node: any) => {
      onNodeClick && onNodeClick(e, node);

      if (!isMobileScreen) {
        clickTimeout = setTimeout(() => {
          const { x } = node;

          const rootRect = root.node().getBoundingClientRect();
          const centerX =
            (getSidePanelWidth() -
              (window.innerWidth - rootRect.x - rootRect.width / 2)) /
            scale;
          const offset = 150 / scale;

          if (x + centerX > -offset) {
            svg
              .transition()
              .duration(500)
              .call(zoom.translateBy, -x - centerX - offset, 0);
          }
        }, 250);
      }
    });

    nodesSelection.on("dblclick", (e: any, node: any) => {
      const { x, y } = node;

      clearTimeout(clickTimeout);

      svg
        .transition()
        .duration(800)
        .call(
          zoom.transform,
          d3.zoomIdentity
            .translate(center[0] - getSidePanelWidth() / 2, center[1])
            .scale(2)
            .translate(-x, -y)
        );
    });

    nodesSelection.on("mouseover", (e: any, node: any) =>
      handleMouseOver(node.id)
    );
    nodesSelection.on("mouseout", () => handleMouseOut());

    linksSelection = linksSelection.data(newLinks).join("line");

    simulation.nodes(newNodes);
    simulation.force("link").links(newLinks);
    simulation.alpha(0.65).restart();
    simulation.on("tick", ticked(linksSelection, nodesSelection));
  };

  const getSidePanelWidth = () => {
    return isMobileScreen
      ? 0
      : Math.min(600, Math.max(450, window.innerWidth * 0.4));
  };

  const initializeLinks = (
    root: Selection<SVGElement, unknown, null, undefined>
  ) => {
    linksContainer = root.append("g").attr("class", "graphLinksContainer");
    linksSelection = linksContainer.selectAll("line");
  };

  const initializeNodes = (
    root: Selection<SVGElement, unknown, null, undefined>
  ) => {
    nodesContainer = root.append("g").attr("class", "graphNodesContainer");
    nodesSelection = nodesContainer.selectAll(`g.graphNode`);
  };

  const createSimulation = () => {
    return (
      d3
        .forceSimulation()
        .force(
          "link",
          d3
            .forceLink()
            .id((d: any) => d.id)
            .distance(95)
        )
        .force("charge", d3.forceManyBody().strength(-250))
        // .force("center", d3.forceCenter(...center))
        .force(
          "collision",
          d3
            .forceCollide()
            .radius((d: any) => {
              return d.title.length * 0.75 + 48;
            })
            .strength(0.2)
            .iterations(2)
        )
        .force("x", d3.forceX().strength(0.06))
        .force("y", d3.forceY().strength(0.06))
    );
  };

  const createSvg = () => {
    return d3
      .select(container)
      .append("svg")
      .attr("viewBox", [
        -graphWidth / 2,
        -graphHeight / 2,
        graphWidth,
        graphHeight,
      ] as any);
  };

  const createRoot = (
    svg: Selection<SVGSVGElement, unknown, null, undefined>
  ) => {
    const root = svg.append("g");
    return root;
  };

  const createZoom = () => {
    const zoom = d3.zoom();
    return zoom;
  };

  const dragEvents = ({ simulation }: any) => {
    const dragstarted = (event: any, d: any) => {
      if (!event.active) {
        simulation.alphaTarget(0.01).restart();
      }

      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = (event: any, d: any) => {
      d.fx = event.x;
      d.fy = event.y;
    };

    const dragended = (event: any, d: any) => {
      if (!event.active) {
        simulation.alphaTarget(0);
      }

      d.fx = null;
      d.fy = null;
    };

    return d3
      .drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  };

  const zoomEvents = ({ zoom, root }: any) => {
    return zoom.scaleExtent([0.27, 3]).on("zoom", (event: any) => {
      root.attr("transform", event.transform);
      scale = event.transform.k;
    });
  };

  const makeActions = () => {
    const isLinkConnected = (link: any, nodeId: any) =>
      link.source.id === nodeId || link.target.id === nodeId;
    const isSibling = (connectedLinks: any, _node: any) =>
      connectedLinks.some((link: any) => isLinkConnected(link, _node.id));

    const handleMouseOver = (selectedId: any) => {
      handleMouseOut();

      nodesContainer.select(`[id="${selectedId}"]`).classed("hovered", true);
      linksContainer.classed("hasHovered", true);
      nodesContainer.classed("hasHovered", true);

      const connectedLinksSelection = linksSelection
        .filter((link: any) => isLinkConnected(link, selectedId))
        .classed("hoveredConnected", true);
      connectedLinksSelection.raise();

      const connectedLinks = connectedLinksSelection.data();

      nodesSelection
        .filter((_node: any) => isSibling(connectedLinks, _node))
        .classed("hoveredSibling", true);
    };

    const handleMouseOut = () => {
      linksContainer.classed("hasHovered", false);
      nodesContainer.classed("hasHovered", false);

      nodesSelection.classed("hovered", false);
      nodesSelection.classed("hoveredSibling", false);

      linksSelection.classed("hoveredConnected", false);
    };

    const selectNode = (selectedId: any) => {
      deselectNodes();

      nodesContainer.select(`[id="${selectedId}"]`).classed("selected", true);
      linksContainer.classed("hasSelection", true);
      nodesContainer.classed("hasSelection", true);

      const connectedLinksSelection = linksSelection
        .filter((link: any) => isLinkConnected(link, selectedId))
        .classed("selectedConnected", true);
      connectedLinksSelection.raise();

      const connectedLinks = connectedLinksSelection.data();

      nodesSelection
        .filter((_node: any) => isSibling(connectedLinks, _node))
        .classed("selectedSibling", true);
    };

    const deselectNodes = () => {
      linksContainer.classed("hasSelection", false);
      nodesContainer.classed("hasSelection", false);

      nodesSelection.classed("selected", false);
      nodesSelection.classed("selectedSibling", false);

      linksSelection.classed("selectedConnected", false);
    };

    return { selectNode, deselectNodes, handleMouseOver, handleMouseOut };
  };

  return draw();
};

export default createGraph;
