import { useCallback, useEffect, useState } from "react";

import { GraphState } from "pages/app/GraphPage/types";
import { useStore } from "stores/store";
import { useCurrentUserId } from "db/currentUser";
import { useLoadable } from "hooks/useLoadable";
import { GraphLink, GraphNode } from "stores/types";
import { dateIdToDate } from "helpers";
import { formatDate } from "utils/dateUtils";
import { ExtractedBacklink } from "components/slate/plugins/backlink/types";
import { DocumentType } from "thunk-core";

const useGraphData = (): [
  GraphState | null,
  boolean,
  Error,
  { add: any; remove: any }
] => {
  const userId = useCurrentUserId();

  const { notesStore, pagesStore, blocksStore } = useStore();
  const [graphState, setGraphState] = useState<GraphState | null>(null);
  const [{ loading: _loading, error: _error }, loadable] = useLoadable<Error>();
  const loading = _loading || notesStore.loading;
  const error = _error || notesStore.error;

  const initialize = useCallback(async () => {
    try {
      loadable.start();
      await notesStore.loadAllDocuments(userId);
      await blocksStore.loadGraphData(userId);
      setGraphState(blocksStore.getGraphData());
      loadable.success();
    } catch (error) {
      console.error(error);
      loadable.error(error);
    }
  }, [userId]);

  const add = (added: ExtractedBacklink[]) => {
    setGraphState((state) => {
      const nodesMap = new Map<string, GraphNode>(state.nodesMap);
      const linksMap = new Map<string, GraphLink[]>(state.linksMap);

      for (const backlink of added) {
        const block = blocksStore.getBlock(backlink.blockId);

        if (backlink.targetType === DocumentType.NOTE) {
          const note = notesStore.getDocumentByDateId(backlink.targetId);
          nodesMap.set(backlink.targetId, {
            documentId: note?.id,
            id: backlink.targetId,
            title: formatDate(dateIdToDate(backlink.targetId)),
            documentType: backlink.targetType,
            isFavorite: false,
          });
        } else if (backlink.targetType === DocumentType.PAGE) {
          const page = pagesStore.getDocument(backlink.targetId);
          nodesMap.set(backlink.targetId, {
            documentId: page.id,
            id: backlink.targetId,
            title: page?.title,
            documentType: backlink.targetType,
            isFavorite: false,
          });
        }

        const source =
          block.pageType === DocumentType.NOTE ? block.dateId : block.pageId;
        const target = backlink.targetId;
        const current = linksMap.get(block.id) || [];
        linksMap.set(block.id, [
          ...current,
          {
            id: block.id,
            source,
            target,
          },
        ]);
      }

      return {
        nodesMap,
        linksMap,
      };
    });
  };

  const remove = (removed: ExtractedBacklink[]) => {
    setGraphState((state) => {
      const linksMap = new Map<string, GraphLink[]>(state.linksMap);

      for (const backlink of removed) {
        const block = blocksStore.getBlock(backlink.blockId);
        const current = linksMap.get(block.id) || [];
        linksMap.set(block.id, [
          ...current.filter((link) => link.target !== backlink.targetId),
        ]);
      }

      return {
        ...state,
        linksMap,
      };
    });
  };

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (loading || error) {
    return [null, loading, error, { add, remove }];
  }

  return [graphState, loading, error, { add, remove }];
};

export default useGraphData;
