import { Editor } from "slate";

import { useMemo } from "react";
import { composePlugins } from "components/slate/plugins/utils";
import { SlatePlugin } from "components/slate/types";

const useEditor = (createEditor: () => Editor, plugins: SlatePlugin[]) => {
  return useMemo(
    () =>
      composePlugins(
        plugins.filter((x) => x.withOverrides).map((x) => x.withOverrides!),
        createEditor()
      ),
    []
  );
};

export default useEditor;
