import { useEffect } from "react";
import { Editor, Element, Transforms } from "slate";
import { clone } from "ramda";
import { getEditorPathname } from "helpers";
import { ReactEditor } from "slate-react";
import { useHistory } from "react-router-dom";

import { Document, DocumentType } from "thunk-core";
import useEvent from "hooks/useEvent";
import { useStoreCallback } from "state/utils";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { EditorsStore } from "components/slate/state/EditorsStore";
import { moveDndTransform } from "components/slate/slate-extended/transforms/moveDndTransform";
import { useStore } from "stores/store";
import useNotify from "hooks/useNotify";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import { removeSkippedElements } from "components/slate/plugins/serialization/withSerialize/removeSkippedElements";
import { getClipboardPlainText } from "components/slate/plugins/serialization/withSerialize/getClipboardPlainText";
import { patchCopiedClipboardHtml } from "components/slate/plugins/serialization/withSerialize/patchCopiedClipboardHtml";
import { swapIds } from "components/slate/plugins/backlink/utils";
import { useCurrentUserId } from "db/currentUser";
import { getActionsOptions } from "components/slate/actions";
import { scrollParentToElement } from "utils/scrollParentToElement";
import {
  addBlockAbove,
  addBlockBelow,
} from "components/slate/plugins/exitBreak/transforms";
import { isTrailingLine } from "components/slate/plugins/trailingLine/withTrailingLine";
import Button from "framework/components/form/Button";
import { isMobile } from "react-device-detect";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";

export const useStartTerminal = () => {
  const { isMobileScreen } = useDeviceDetector();
  const terminalActions = TerminalStore.useTerminalActions();

  const setSelection = (editor: Editor, elementId?: string) => {
    if (elementId) {
      const index = editor.children.findIndex(
        (element) => element.id === elementId
      );
      if (index !== -1) {
        Transforms.select(editor, Editor.end(editor, [index]));
        return;
      }
    }

    if (!editor.selection) {
      Transforms.select(editor, Editor.end(editor, [0]));
      return;
    }

    Transforms.select(editor, Editor.end(editor, editor.selection));
  };

  return useStoreCallback((get) => (slateId: string, elementId?: string) => {
    const editor = get(EditorsStore.editor(slateId));

    if (editor && editor.isTerminalEditor) {
      setSelection(editor, elementId);

      terminalActions.openTerminal(slateId);

      const activeElement = document.activeElement as any;
      if (activeElement && isMobileScreen) {
        // dismiss keyboard on mobile
        activeElement.blur && activeElement.blur();
      }
    }
  });
};

export const useEndTerminal = () => {
  const terminalActions = TerminalStore.useTerminalActions();

  return useStoreCallback((get) => () => {
    const editor = get(TerminalStore.activeEditor);

    terminalActions.closeTerminal();

    Promise.resolve().then(() => {
      ReactEditor.focus(editor);
      Transforms.select(editor, Editor.end(editor, editor.selection));
    });
  });
};

export const useCopyToNewNote = () => {
  const history = useHistory();
  const terminalActions = TerminalStore.useTerminalActions();
  const userId = useCurrentUserId();
  const { documentsManager } = useStore();

  return useEvent(
    useStoreCallback((get) => async (doRemoveContent?: boolean) => {
      const editor = get(TerminalStore.activeEditor);

      const options = getActionsOptions(get);
      const elements = Array.from(Editor.nodes(editor, options)).map(
        ([element]) => element as Element
      );

      const documentType = DocumentType.PAGE;

      const documentId = documentsManager.createNewDocumentWithContent(
        elements,
        {
          userId,
          documentType,
        }
      );

      if (doRemoveContent) {
        Transforms.removeNodes(editor, options);
      }

      await terminalActions.closeTerminal();

      history.push(getEditorPathname(documentType, documentId, null));
    })
  );
};

export const useCopyToExistingNote = () => {
  const history = useHistory();
  const terminalActions = TerminalStore.useTerminalActions();
  const { documentsManager } = useStore();
  const notify = useNotify();

  return useEvent(
    useStoreCallback(
      (get) => (document: Document, doRemoveContent?: boolean) => {
        const editor = get(TerminalStore.activeEditor);

        const options = getActionsOptions(get);
        const elements = Array.from(Editor.nodes(editor, options)).map(
          ([element]) => element as Element
        );

        const documentId = document.id;
        const documentType = DocumentType.PAGE;

        documentsManager.addContentToDocument(elements, {
          documentId,
          documentType,
        });

        if (doRemoveContent) {
          Transforms.removeNodes(editor, options);
        }

        terminalActions.closeTerminal();

        const title = getDocumentTitle(document) || "[no title]";

        notify.custom({
          type: "success",
          text: <div>{`Blocks successfully copied to ${title}`}</div>,
          buttons: (
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                history.push(getEditorPathname(documentType, documentId, null));
              }}
            >
              Open note
            </Button>
          ),
          duration: 8000,
        });
      }
    )
  );
};

const getSelectionIndex = (editor: Editor) =>
  Editor.path(editor, editor.selection, { depth: 1 })[0];
export const useDuplicate = () => {
  return useEvent(
    useStoreCallback((get) => () => {
      const editor = get(TerminalStore.activeEditor);

      const options = getActionsOptions(get);
      const elements = Array.from(Editor.nodes(editor, options)).map(
        ([element]) => element as Element
      );

      if (elements.length > 0) {
        const firstIndex = editor.children.indexOf(elements[0]);

        const newContent = clone(elements).map(swapIds);
        Transforms.insertNodes(editor, newContent, { at: [firstIndex] });
      }
    })
  );
};

export const useCopyBlocksHandler = () => {
  const handleCopy = useEvent(
    useStoreCallback((get) => (e: ClipboardEvent) => {
      const isTerminalActive = get(TerminalStore.isTerminalActive);

      if (!isTerminalActive) {
        return;
      }

      e.preventDefault();

      const terminalSlateId = get(TerminalStore.terminalSlateId);
      const terminalSelection = get(TerminalStore.activeSelection);
      const pointerId = get(TerminalStore.activePointer);
      const editor = get(TerminalStore.activeEditor);

      const options = getActionsOptions(get);
      const elements = Array.from(Editor.nodes(editor, options)).map(
        ([element]) => element as Element
      );

      const editable = document.querySelector(
        `[data-slate-id="${terminalSlateId}"]`
      );

      const domElements = Array.from(editable.children).filter(
        (child) =>
          terminalSelection.has(child.getAttribute("data-slate-node-id")) ||
          pointerId === child.getAttribute("data-slate-node-id")
      );

      const string = JSON.stringify(elements);
      const encoded = window.btoa(encodeURIComponent(string));

      const clipboardNode = document.createElement("div");
      domElements.forEach((x) => clipboardNode.appendChild(x.cloneNode(true)));

      clipboardNode.setAttribute("hidden", "true");
      document.body.appendChild(clipboardNode);

      removeSkippedElements(clipboardNode);

      const plainText = getClipboardPlainText(clipboardNode);
      patchCopiedClipboardHtml(clipboardNode);

      e.clipboardData.setData("application/x-slate-fragment", encoded);
      e.clipboardData.setData("text/plain", plainText);
      e.clipboardData.setData("text/html", clipboardNode.innerHTML);

      document.body.removeChild(clipboardNode);
    })
  );

  return handleCopy;
};

export const useCopyBlocksEffect = () => {
  const copyHandler = useCopyBlocksHandler();

  useEffect(() => {
    document.addEventListener("copy", copyHandler);
    return () => document.removeEventListener("copy", copyHandler);
  }, []);
};

export const useCopyBlocks = () => {
  return useEvent(
    useStoreCallback((get) => () => {
      document.execCommand("copy");
    })
  );
};

export const usePasteBlocks = () => {
  return useEvent(
    useStoreCallback((get) => () => {
      document.execCommand("paste");
    })
  );
};

export const usePasteBlocksHandler = () => {
  const terminalActions = TerminalStore.useTerminalActions();

  const handlePaste = useEvent(
    useStoreCallback((get) => (e: ClipboardEvent) => {
      const isTerminalActive = get(TerminalStore.isTerminalActive);

      if (!isTerminalActive) {
        return;
      }

      e.preventDefault();

      const terminalSlateId = get(TerminalStore.terminalSlateId);
      const pointerId = get(TerminalStore.terminalPointer(terminalSlateId));
      const terminalSelection = get(
        TerminalStore.terminalSelection(terminalSlateId)
      );
      const editor = get(EditorsStore.editor(terminalSlateId));

      if (editor?.selection) {
        const selected = editor.children.filter(
          (element) =>
            terminalSelection.has(element.id) || element.id === pointerId
        );
        const index = editor.children.indexOf(selected[selected.length - 1]);

        Transforms.select(editor, Editor.end(editor, [index]));
        Editor.insertBreak(editor);
        ReactEditor.insertData(editor, e.clipboardData);

        const newIndex = getSelectionIndex(editor);

        const toSelect = editor.children.slice(index + 1, newIndex);

        for (const element of toSelect) {
          terminalActions.selectElement(terminalSlateId, element.id);
        }
      }
    })
  );

  return handlePaste;
};

export const usePasteBlocksEffect = () => {
  const pasteHandle = usePasteBlocksHandler();

  useEffect(() => {
    document.addEventListener("paste", pasteHandle);
    return () => document.removeEventListener("paste", pasteHandle);
  }, []);
};

const limitIndex = (editor: Editor, index: number) =>
  Math.min(Math.max(index, 0), editor.children.length - 1);

export const useMoveBlocks = () => {
  const terminalActions = TerminalStore.useTerminalActions();

  return useEvent(
    useStoreCallback((get) => (direction: "up" | "down") => {
      const terminalSlateId = get(TerminalStore.terminalSlateId);
      const editor = get(TerminalStore.activeEditor);

      const step = direction === "down" ? 1 : -1;

      const terminalSelection = new Set(get(TerminalStore.activeSelection));

      const pointerId = get(TerminalStore.activePointer);
      if (terminalSelection.size > 0) {
        // add pointer id to selection if selection is not empty
        terminalActions.selectElement(terminalSlateId, pointerId);
      }

      terminalSelection.add(pointerId);

      const options = getActionsOptions(get);
      const selected = Array.from(Editor.nodes(editor, options)).map(
        ([element]) => element as Element
      );
      const index = editor.children.indexOf(
        step > 0 ? selected[selected.length - 1] : selected[0]
      );

      const element = editor.children[index];

      let newIndex: number;
      if (step < 0) {
        newIndex = limitIndex(editor, index + step);
      } else {
        const semanticDescendants = ExtendedEditor.semanticDescendants(
          editor,
          element
        );
        newIndex = limitIndex(editor, index + semanticDescendants.length + 1);
      }

      const newElement = editor.children[newIndex];

      moveDndTransform({
        editor,
        activeId: element.id,
        activeIndex: index,
        selectedIds: terminalSelection,
        overId: newElement.id,
        overIndex: newIndex,
      });

      const domElement = ReactEditor.toDOMNode(editor, newElement);
      scrollParentToElement(domElement);
    })
  );
};

export const useMoveBlocksToEdge = () => {
  const terminalActions = TerminalStore.useTerminalActions();

  return useEvent(
    useStoreCallback((get) => (direction: "top" | "bottom") => {
      const terminalSlateId = get(TerminalStore.terminalSlateId);
      const editor = get(TerminalStore.activeEditor);

      const pointerId = get(TerminalStore.activePointer);
      terminalActions.selectElement(terminalSlateId, pointerId);

      const terminalSelection = new Set(get(TerminalStore.activeSelection));
      terminalSelection.add(pointerId);

      const options = getActionsOptions(get);
      const selected = Array.from(Editor.nodes(editor, options)).map(
        ([element]) => element as Element
      );
      const index = editor.children.indexOf(
        direction === "bottom" ? selected[selected.length - 1] : selected[0]
      );

      let newIndex: number;
      if (direction === "bottom") {
        const isLastLineTrailing = isTrailingLine(
          editor.children[editor.children.length - 1]
        );
        newIndex = Math.max(
          0,
          editor.children.length - (isLastLineTrailing ? 2 : 1)
        );
      } else {
        newIndex = 0;
      }

      const element = editor.children[index];
      const newElement = editor.children[newIndex];

      moveDndTransform({
        editor,
        activeId: element.id,
        activeIndex: index,
        selectedIds: terminalSelection,
        overId: newElement.id,
        overIndex: newIndex,
      });

      setTimeout(() => {
        const domElement = ReactEditor.toDOMNode(
          editor,
          direction === "bottom"
            ? editor.children[editor.children.length - 1]
            : newElement
        );
        scrollParentToElement(domElement);
      }, 100);
    })
  );
};

export const useMoveSelectionToTop = () => {
  return useEvent(
    useStoreCallback((get) => () => {
      const editor = get(TerminalStore.activeEditor);

      Transforms.select(editor, Editor.end(editor, [0]));

      const element = editor.children[0];
      if (element) {
        const domElement = ReactEditor.toDOMNode(editor, element);
        scrollParentToElement(domElement);
      }
    })
  );
};

export const useMoveSelectionToBottom = () => {
  return useEvent(
    useStoreCallback((get) => () => {
      const editor = get(TerminalStore.activeEditor);

      Transforms.select(editor, Editor.end(editor, []));

      const element = editor.children[editor.children.length - 1];
      if (element) {
        const domElement = ReactEditor.toDOMNode(editor, element);
        scrollParentToElement(domElement);
      }
    })
  );
};

export const useAddEmptyBlock = () => {
  const terminalActions = TerminalStore.useTerminalActions();

  return useEvent(
    useStoreCallback((get) => (placement: "above" | "below") => {
      const terminalSlateId = get(TerminalStore.terminalSlateId);
      const editor = get(TerminalStore.activeEditor);

      terminalActions.deselectAllElements(terminalSlateId);

      if (placement === "above") {
        addBlockAbove(editor);
      } else {
        addBlockBelow(editor);
      }
    })
  );
};
