import styles from "./dueDateCalendarAction.module.scss";

export interface DueDateCalendarActionProps {
  text: string;
  hintText?: string;
  icon: React.ComponentType<any>;
  onClick: React.MouseEventHandler;
}

const DueDateCalendarAction = (props: DueDateCalendarActionProps) => {
  const { text, hintText, icon: Icon, onClick } = props;
  return (
    <div className={styles.calendarAction} onClick={onClick}>
      <div className={styles.icon}>
        <Icon size={16} />
      </div>
      <div className={styles.text}>{text}</div>
      {hintText && <div className={styles.hint}>{hintText}</div>}
    </div>
  );
};

export default DueDateCalendarAction;
