import { useObserver } from "mobx-react-lite";
import { useStore } from "stores/store";
import { Document } from "thunk-core";

const useBreadcrumb = (page: Document) => {
  const { pagesStore } = useStore();
  return useObserver(() => {
    if (!page) return [[], []];
    const titles = [page.title];
    const ids = [page.id];

    const build = (parentId: string | null) => {
      if (parentId) {
        const parent = pagesStore.getDocument(parentId);
        if (parent) {
          titles.unshift(parent.title);
          ids.unshift(parent.id);
          build(parent.parentId);
        }
      }
    };

    build(page.parentId);

    return [titles, ids];
  });
};

export default useBreadcrumb;
