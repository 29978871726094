import { FetchError } from "providers/AppFetchProvider";
import { FirebaseError } from "firebase/app";

const messagesByCodes = {
  "auth/email-already-exists": "sign_up.errors.email_exists",
  "auth/network-request-failed": "errors.common.connection_error",
  "thunk/connection-error": "errors.common.connection_error",
  "thunk/abort-error": "errors.common.connection_error",
};

export type ErrorInfo = { message: string; code: string };

export const getErrorInfo = (error: FetchError | FirebaseError): ErrorInfo => {
  const message = messagesByCodes[error.code];

  if (message != null) {
    return {
      message,
      code: error.code,
    };
  }

  if (error.message?.includes("IDBDatabase")) {
    return {
      message: "sign_up.errors.idbdatabase",
      code: "thunk/signup/idbdatabase",
    };
  }

  return {
    message: "sign_up.errors.unknown",
    code: error.code,
  };
};
