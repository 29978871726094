import { useCollectionData } from "react-firebase-hooks/firestore";
import { startOfMonth, endOfMonth } from "date-fns";
import { where, query, collection } from "firebase/firestore";

import { db } from "firebaseInstance";
import { makeDateId } from "helpers";
import { DocumentType } from "thunk-core";

export const useFilledDays = (userId: any, date: any) => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);

  const result = useCollectionData(
    query(
      collection(db, `pages`),
      where("userId", "==", userId),
      where("type", "==", DocumentType.NOTE),
      where("dateId", ">=", makeDateId(start)),
      where("dateId", "<=", makeDateId(end)),
      where("isEmpty", "==", false)
    ),
    {
      idField: "id",
    }
  );

  return result;
};
