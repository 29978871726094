import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";
import {
  KbdTerminalEnter,
  KbdTerminalSpace,
  KbdTerminalSelect,
  DeleteNoteIcon,
  KbdTerminalA,
  KbdTerminalSlash,
  KbdTerminalEsc,
  KbdTerminalPeriod,
  UndoIcon,
  RedoIcon,
} from "thunk-icons";
import isHotkey from "is-hotkey";

import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import useEvent from "hooks/useEvent";
import { useStoreCallback, useStoreValue } from "state/utils";
import { EditorActions } from "components/slate/actions";

import styles from "./ShortcutHint.module.scss";
import { openTerminalMenu } from "components/slate/plugins/menus/terminalMenu/components/TerminalMenu";
import { openFormattingMenu } from "components/slate/plugins/menus/terminalMenu/components/TerminalFormattingMenu";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import { HistoryEditor } from "slate-history";

const TerminalShortcutHint = () => {
  const [hidden, setHidden] = useState(false);
  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);
  const terminalSlateId = useStoreValue(TerminalStore.terminalSlateId);
  const terminalSelection = useStoreValue(TerminalStore.activeSelection);
  const terminalActions = TerminalStore.useTerminalActions();
  const visible = !hidden;

  const { isMobileScreen } = useDeviceDetector();

  const onKeyDown = useEvent(
    useStoreCallback((get) => (e: KeyboardEvent) => {
      if (get(TerminalStore.isTerminalActive)) {
        if (isHotkey(".", e)) {
          setHidden((state) => !state);
        }
      }
    })
  );

  const undo = useStoreCallback((get) => () => {
    const isTerminalActive = get(TerminalStore.isTerminalActive);

    if (isTerminalActive) {
      const editor = get(TerminalStore.activeEditor);
      HistoryEditor.undo(editor);
    }
  });

  const redo = useStoreCallback((get) => () => {
    const isTerminalActive = get(TerminalStore.isTerminalActive);

    if (isTerminalActive) {
      const editor = get(TerminalStore.activeEditor);
      HistoryEditor.redo(editor);
    }
  });

  const removeBlocks = useStoreCallback((get) => () => {
    const isTerminalActive = get(TerminalStore.isTerminalActive);

    if (isTerminalActive) {
      const editor = get(TerminalStore.activeEditor);
      EditorActions.removeHighestBlock({ editor });
    }
  });

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  if (isMobileScreen) {
    return createPortal(
      <div
        data-temrinal-shortcuts={true}
        className={cn(styles.container, {
          [styles.visible]: isTerminalActive && visible,
        })}
      >
        <div>
          <MobileButton
            icon={terminalSelection.size > 0 ? "Deselect" : "Close"}
            className={styles.closeButton}
            onClick={() => {
              if (terminalSelection.size > 0) {
                terminalActions.deselectAllElements(terminalSlateId);
              } else {
                EditorActions.endTerminal();
              }
            }}
          />
          <MobileButton
            icon={UndoIcon}
            onClick={() => {
              undo();
            }}
          />
          <MobileButton
            icon={RedoIcon}
            onClick={() => {
              redo();
            }}
          />
        </div>
        <div className={styles.centerContainer} />
        <div>
          <MobileButton
            icon={KbdTerminalSlash}
            onClick={() => openFormattingMenu()}
          />
          <MobileButton
            icon={KbdTerminalA}
            onClick={() => openTerminalMenu()}
          />
          <MobileButton icon={DeleteNoteIcon} onClick={() => removeBlocks()} />
        </div>
      </div>,
      document.body
    );
  }

  return (
    <>
      {createPortal(
        <div
          data-temrinal-shortcuts={true}
          className={cn(styles.container, {
            [styles.visible]: isTerminalActive && visible,
          })}
        >
          <div>
            <ClickableShortcut
              text={terminalSelection.size > 0 ? "Deselect" : "Close"}
              icons={[KbdTerminalEsc]}
              onClick={() => {
                if (terminalSelection.size > 0) {
                  terminalActions.deselectAllElements(terminalSlateId);
                } else {
                  EditorActions.endTerminal();
                }
              }}
            />
          </div>
          <div className={styles.centerContainer}></div>
          <div className={styles.rightContainer}>
            <Shortcut
              className={styles.keyboardHint}
              text="edit"
              icons={[KbdTerminalEnter]}
            />
            <div className={cn(styles.verticalRule, styles.keyboardHint)}></div>
            <Shortcut
              className={styles.keyboardHint}
              text="select"
              icons={[KbdTerminalSpace]}
            />
            <Shortcut
              className={styles.keyboardHint}
              text="or"
              icons={[KbdTerminalSelect]}
            />
            <div className={cn(styles.verticalRule, styles.keyboardHint)}></div>
            <ClickableShortcut
              text="Commands"
              icons={[KbdTerminalSlash]}
              onClick={() => openFormattingMenu()}
            />
            <ClickableShortcut
              text="Actions"
              icons={[KbdTerminalA]}
              onClick={() => openTerminalMenu()}
            />
            <ClickableShortcut
              text="Delete"
              icons={[DeleteNoteIcon]}
              onClick={() => removeBlocks()}
            />
            <div className={styles.verticalRule}></div>
            <ClickableShortcut
              text="Hide"
              icons={[KbdTerminalPeriod]}
              onClick={() => setHidden(true)}
            />
          </div>
        </div>,
        document.body
      )}
      {createPortal(
        <div
          className={cn(styles.showHints, {
            [styles.visible]: isTerminalActive && !visible,
          })}
        >
          <div>
            <ClickableShortcut
              text="Show hints"
              icons={[KbdTerminalPeriod]}
              onClick={() => setHidden(false)}
            />
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default TerminalShortcutHint;

type ShortcutProps = {
  text: React.ReactNode;
  icons: (React.ComponentType<any> | string)[];
  onClick?: React.MouseEventHandler;
  className?: string;
};

const Shortcut = (props: ShortcutProps) => {
  const { text, icons, onClick, className } = props;

  return (
    <div
      className={cn(styles.shortcut, className, {
        [styles.clickable]: onClick,
      })}
      onClick={onClick}
    >
      <div className={styles.shortcutText}>{text}</div>
      <div className={styles.shortcutIcons}>
        {icons.map((Icon, index) =>
          typeof Icon === "string" ? (
            Icon
          ) : (
            <Icon key={index} width={null} height={24} fill="inherit" />
          )
        )}
      </div>
    </div>
  );
};

const ClickableShortcut = (props: ShortcutProps) => {
  const { text, icons, onClick } = props;

  return (
    <div
      className={cn(styles.clickableShortcut, { [styles.clickable]: onClick })}
      onClick={onClick}
    >
      <div className={styles.shortcutIcons}>
        {icons.map((Icon, index) =>
          typeof Icon === "string" ? (
            Icon
          ) : (
            <Icon key={index} width={null} height={28} fill="inherit" />
          )
        )}
      </div>
      <div className={styles.shortcutText}>{text}</div>
    </div>
  );
};

type MobileButtonProps = {
  icon: React.ComponentType<any> | string;
  onClick?: React.MouseEventHandler;
  className?: string;
};

const MobileButton = (props: MobileButtonProps) => {
  const { icon: Icon, onClick, className } = props;

  return (
    <div
      className={cn(styles.clickableMobileShortcut, className, {
        [styles.clickable]: onClick,
      })}
      onClick={onClick}
    >
      {typeof Icon === "string" ? Icon : <Icon fill="inherit" />}
    </div>
  );
};
