import DueDateCalendarAction, {
  DueDateCalendarActionProps,
} from "./DueDateCalendarAction";

import styles from "./dueDateCalendarAction.module.scss";

export interface DueDateCalendarActionsProps {
  actions: DueDateCalendarActionProps[];
}

const DueDateCalendarActions = (props: DueDateCalendarActionsProps) => {
  const { actions } = props;

  return (
    <div className={styles.calendarActions}>
      {actions.map((action, index) => {
        return <DueDateCalendarAction key={index} {...action} />;
      })}
    </div>
  );
};

export default DueDateCalendarActions;
