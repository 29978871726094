import { Route } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { HeaderLayout } from "components/Header";
import Link from "framework/components/Link";
import { createHrefLandingSite } from "routing/utils/redirects";
import { useAuth } from "auth/AuthProvider";
import { isElectron } from "react-device-detect";

import styles from "./index.module.scss";

const AuthLayout = ({ children }: any) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const pricingLink = !isElectron && (
    <Link href={createHrefLandingSite("/pricing")}>Pricing</Link>
  );
  const signInLink = !auth.isAuthenticated && (
    <Route path={["/signup", "/reset", "/reset-password"]}>
      <Link to="/signin">{t("menu.sign_in")}</Link>
    </Route>
  );
  const signUpLink = !auth.isAuthenticated && (
    <Route path="/signin">
      <Link to="/signup">{t("menu.sign_up")}</Link>
    </Route>
  );

  return (
    <div className={styles.container}>
      <div className={styles.electronHeader} />
      <HeaderLayout>
        {pricingLink}
        {signInLink}
        {signUpLink}
      </HeaderLayout>

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default AuthLayout;
