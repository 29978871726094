/* eslint react-hooks/rules-of-hooks: 0 */
import { atom, atomFamily, selector, selectorFamily } from "recoil";
import { useMemo } from "react";
import produce from "immer";
import { Editor } from "slate";

import { useStoreCallback } from "state/utils";
import { EditorsStore } from "components/slate/state/EditorsStore";

class TerminalStoreClass {
  isTerminalActive = atom<boolean>({
    key: "TerminalStore_isTerminalActive",
    default: false,
  });

  isMenuOpened = atom<boolean>({
    key: "TerminalStore_isMenuOpened",
    default: false,
  });

  terminalSlateId = atom<string | null>({
    key: "TerminalStore_terminalSlateId",
    default: null,
  });

  terminalPointer = atomFamily<string | null, string>({
    key: "TerminalStore_terminalPointer",
    default: null,
  });

  terminalSelection = atomFamily<Set<string>, string>({
    key: "TerminalStore_terminalSelection",
    default: new Set<string>(),
  });

  isElementSelected = selectorFamily<
    boolean,
    { slateId: string; elementId: string }
  >({
    key: "TerminalStore_isElementSelected",
    get: ({ slateId, elementId }) => ({ get }) => {
      const terminalSelection = get(this.terminalSelection(slateId));
      return terminalSelection.has(elementId);
    },
  });

  activeEditor = selector<Editor>({
    key: "TerminalStore_terminalEditor",
    dangerouslyAllowMutability: true,
    get: ({ get }) => {
      const terminalSlateId = get(this.terminalSlateId);
      const terminalEditor = get(EditorsStore.editor(terminalSlateId));

      return terminalEditor;
    },
  });

  activeSelection = selector<Set<string>>({
    key: "TerminalStore_activeSelection",
    get: ({ get }) => {
      const terminalSlateId = get(this.terminalSlateId);
      const activeSelection = get(this.terminalSelection(terminalSlateId));

      return activeSelection;
    },
  });

  activePointer = selector<string>({
    key: "TerminalStore_activePointer",
    get: ({ get }) => {
      const terminalSlateId = get(this.terminalSlateId);
      const activePointer = get(this.terminalPointer(terminalSlateId));

      return activePointer;
    },
  });

  useTerminalActions = () => {
    const openTerminal = useStoreCallback((get, set) => (slateId: string) => {
      set(this.isTerminalActive, true);
      set(this.terminalSlateId, slateId);

      const terminalSlateId = get(this.terminalSlateId);

      if (terminalSlateId !== slateId) {
        set(this.terminalSelection(slateId), new Set<string>());
      }
    });

    const closeTerminal = useStoreCallback((get, set) => () => {
      const terminalSlateId = get(this.terminalSlateId);

      set(this.isTerminalActive, false);
      set(this.terminalSelection(terminalSlateId), new Set<string>());
      set(this.terminalSlateId, null);
    });

    const setPointer = useStoreCallback(
      (get, set) => (slateId: string, elementId: string) => {
        set(this.terminalPointer(slateId), elementId);
      }
    );

    const setIsMenuOpened = useStoreCallback((get, set) => (value: boolean) => {
      set(this.isMenuOpened, value);
    });

    const selectElement = useStoreCallback(
      (get, set) => (slateId: string, elementId: string) => {
        set(
          this.terminalSelection(slateId),
          produce((draft: any) => {
            draft.add(elementId);
          })
        );
      }
    );

    const selectAllElements = useStoreCallback(
      (get, set) => (slateId: string, ids: string[]) => {
        set(
          this.terminalSelection(slateId),
          produce((draft: any) => {
            for (const id of ids) {
              draft.add(id);
            }
          })
        );
      }
    );

    const deselectElement = useStoreCallback(
      (get, set) => (slateId: string, elementId: string) => {
        set(
          this.terminalSelection(slateId),
          produce((draft: any) => {
            draft.delete(elementId);
          })
        );
      }
    );

    const deselectAllElements = useStoreCallback(
      (get, set) => (slateId: string) => {
        set(
          this.terminalSelection(slateId),
          produce((draft: any) => {
            draft.clear();
          })
        );
      }
    );

    const toggleElement = useStoreCallback(
      (get, set) => (slateId: string, elementId: string) => {
        set(
          this.terminalSelection(slateId),
          produce((draft: any) => {
            const selected = draft.has(elementId);

            if (selected) {
              draft.delete(elementId);
            } else {
              draft.add(elementId);
            }
          })
        );
      }
    );

    return useMemo(
      () => ({
        openTerminal,
        closeTerminal,
        setPointer,
        setIsMenuOpened,
        selectElement,
        selectAllElements,
        deselectElement,
        deselectAllElements,
        toggleElement,
      }),
      []
    );
  };
}

export const TerminalStore = new TerminalStoreClass();
