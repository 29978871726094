import { UseSlatePlugin } from "components/slate/types";
import { withSerialize } from "components/slate/plugins/serialization/withSerialize";

const useSerializePlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withSerialize,
  };
};

export default useSerializePlugin;
