import React, { forwardRef } from "react";
import { createPortal } from "react-dom";
import cn from "classnames";

import { AppPopperProps } from "framework/components/poppers/useAppPopper";
import PopperArrow from "framework/components/poppers/PopperArrow";

import styles from "./index.module.scss";

const AppPopper = (
  props: AppPopperProps &
    JSX.IntrinsicElements["div"] & {
      content: React.ReactNode;
      color?: "default" | "primary";
      fromPopper?: boolean;
      onlyLight?: boolean;
    }
) => {
  const {
    content,
    color = "default",
    fromPopper = false,
    onlyLight = false,
    children,
    // popper props
    hasArrow,
    popper,
    isOpened,
    openPopper,
    closePopper,
    targetElement,
    contentElement,
    arrowElement,
    setTargetElement,
    setContentElement,
    setArrowElement,
    ...rest
  } = props;

  const className = cn(styles.popper, props.className, colorClasses[color], {
    [styles.visible]: isOpened,
    [styles.fromPopper]: fromPopper,
    [styles.onlyLight]: onlyLight,
  });

  const contentAttributes = fromPopper
    ? { "data-not-close-popper": true }
    : null;

  return (
    <>
      {children}
      {createPortal(
        <div
          ref={setContentElement}
          {...popper.attributes.popper}
          {...rest}
          {...contentAttributes}
          style={{ ...popper.styles.popper, ...rest.style }}
          className={className}
        >
          {content}
          {hasArrow && (
            <PopperArrow ref={setArrowElement} style={popper.styles.arrow} />
          )}
        </div>,
        document.body
      )}
    </>
  );
};

export default AppPopper;

const colorClasses = {
  default: styles.colorDefault,
  primary: styles.colorPrimary,
};

export const PopperDivider = () => {
  return <hr className={styles.divider} />;
};

export const PopperMenuItem = forwardRef(
  (
    {
      children,
      itemClass,
      onClick,
    }: {
      children: React.ReactNode;
      itemClass?: string;
      onClick?: React.MouseEventHandler;
    },
    ref
  ) => {
    return (
      <div
        // @ts-ignore
        ref={ref}
        className={cn(styles.popperItem, itemClass)}
        onClick={onClick}
      >
        {children}
      </div>
    );
  }
);
