import React from "react";
import { OfflineIcon, OnlineIcon } from "thunk-icons";
import { ref, onValue, off } from "firebase/database";

import { realtimeDb } from "firebaseInstance";
import useNotifications from "hooks/useNotifications";

class ConnectionStatusHandler extends React.Component {
  initTimeout: any = null;
  initialized = false;

  visibilityTimeout: any = null;

  isOnline = true;

  onlineId = null;
  offlineId = null;

  isOfflinePeriod = false;

  componentDidMount() {
    this.initTimeout = setTimeout(() => {
      this.initialized = true;
      this.checkStatus();
    }, 3500);

    window.addEventListener("visibilitychange", this.handleVisibilityChange);
    onValue(ref(realtimeDb, ".info/connected"), this.handleStatusChange);
  }

  componentWillUnmount() {
    this.initTimeout && clearTimeout(this.initTimeout);
    this.visibilityTimeout && clearTimeout(this.visibilityTimeout);

    window.removeEventListener("visibilitychange", this.handleVisibilityChange);
    off(ref(realtimeDb, ".info/connected"), "value", this.handleStatusChange);
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {}

  handleVisibilityChange = () => {
    const isVisible = document.visibilityState === "visible";

    if (isVisible) {
      this.visibilityTimeout = setTimeout(() => {
        this.initialized = true;
        this.checkStatus();
      }, 1500);
    } else {
      this.visibilityTimeout && clearTimeout(this.visibilityTimeout);
      this.visibilityTimeout = null;
      this.initialized = false;
    }
  };

  handleStatusChange = (snapshot: any) => {
    this.isOnline = snapshot.val();

    if (this.initialized) {
      this.checkStatus();
    }
  };

  checkStatus = () => {
    if (this.isOnline) {
      this.handleOnline();
    }

    if (!this.isOnline) {
      this.handleOffline();
    }
  };

  handleOnline = () => {
    // @ts-ignore @ts-ignore ts-migrate(2339) FIXME: Property 'removeNotification' does not exist on ty... Remove this comment to see the full error message
    this.offlineId && this.props.removeNotification(this.offlineId);
    this.offlineId = null;

    if (!this.isOfflinePeriod) return;

    this.isOfflinePeriod = false;

    if (this.onlineId) return;

    // @ts-ignore @ts-ignore ts-migrate(2339) FIXME: Property 'addNotification' does not exist on type ... Remove this comment to see the full error message
    this.onlineId = this.props.addNotification({
      duration: 2500,
      type: "success",
      text: "Your internet connection was restored",
      icon: OnlineIcon,
      doShowCloseIcon: true,
      onRemoval: () => {
        this.onlineId = null;
      },
    });
  };

  handleOffline = () => {
    // @ts-ignore @ts-ignore ts-migrate(2339) FIXME: Property 'removeNotification' does not exist on ty... Remove this comment to see the full error message
    this.onlineId && this.props.removeNotification(this.onlineId);
    this.onlineId = null;

    this.isOfflinePeriod = true;

    if (this.offlineId) return;

    // @ts-ignore @ts-ignore ts-migrate(2339) FIXME: Property 'addNotification' does not exist on type ... Remove this comment to see the full error message
    this.offlineId = this.props.addNotification({
      duration: 30000,
      type: "error",
      text: "You are currently offline",
      icon: OfflineIcon,
      doShowCloseIcon: false,
      onRemoval: () => {
        this.offlineId = null;
      },
    });
  };

  render() {
    return null;
  }
}

function ConnectionStatusHandlerWrapper() {
  const { addNotification, removeNotification } = useNotifications();
  return (
    <ConnectionStatusHandler
      // @ts-ignore @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
      addNotification={addNotification}
      removeNotification={removeNotification}
    />
  );
}

export default ConnectionStatusHandlerWrapper;
