import React from "react";
import { SignOutIcon } from "thunk-icons";
import { Link } from "react-router-dom";

import FormGroupLabel from "framework/components/form/FormGroupLabel";
import Button from "framework/components/form/Button";
import { auth } from "firebaseInstance";

const SignOut = () => {
  return (
    <div>
      <FormGroupLabel labelText="Sign out of Thunk" />

      <Button
        as={Link}
        to="/"
        variant="secondary"
        icon={SignOutIcon}
        onClick={(e: any) => {
          e.preventDefault();
          auth.signOut();
        }}
      >
        Sign out
      </Button>
    </div>
  );
};

export { SignOut };
