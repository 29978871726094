import { flatten } from "ramda";
import { format, set, add } from "date-fns";

import { formatTime } from "utils/dateUtils";

const getAmPm = () => {
  return format(new Date(), "aaaaa");
};

const defaultSearch = ["now", "5m", "1h", "3p"];

type TimestampConfigItem<T> = {
  isMatch: (search: string) => T;
  getOptions: (match: T) => TimestampItem[];
};

type TimestampItem = {
  title: string;
  hint: string;
  date: Date;
};

const config: TimestampConfigItem<
  boolean | [number, number, string] | RegExpMatchArray | undefined
>[] = [
  {
    isMatch: (search: string) => "now".indexOf(search) === 0,
    getOptions: (match: any) => {
      const date = new Date();
      return [
        {
          title: formatTime(date),
          hint: "now",
          date,
        },
      ];
    },
  },
  {
    isMatch: (search: string) => "current time".indexOf(search) === 0,
    getOptions: (match: any) => {
      const date = new Date();
      return [
        {
          title: formatTime(date),
          hint: "current time",
          date,
        },
      ];
    },
  },
  {
    isMatch: (search: string) => {
      const separatorMatch = search
        .replace(/[-.]/g, ":")
        .match(/^(\d{1,2}):(\d{1,2})(a|p)?$/);
      if (separatorMatch) {
        const [, h1, m1, unit] = separatorMatch;
        const hours = Number(h1 || 0);
        const minutes = Number(m1 || 0);

        return [hours, minutes, unit];
      }

      const onlyDigitsMatch = search.match(
        /^(?:(\d)|(\d{2})|(\d)(\d{2})|(\d{2})(\d{2}))(a|p)?$/
      );
      if (onlyDigitsMatch) {
        const [, h1, h2, h3, m3, h4, m4, unit] = onlyDigitsMatch;
        const hours = Number(h1 || h2 || h3 || h4 || 0);
        const minutes = Number(m3 || m4 || 0);

        return [hours, minutes, unit];
      }
    },
    getOptions: (match: any) => {
      const [hours, minutes, unit] = match;

      if (hours > 24 || minutes > 59) {
        return null;
      }

      const considerUnit = hours <= 12;
      const normalizedUnit = considerUnit ? unit || getAmPm() : null;

      const hoursForDate = hours === 12 ? 0 : hours;
      const date = set(new Date(), {
        hours: normalizedUnit === "p" ? hoursForDate + 12 : hoursForDate,
        minutes,
      });
      const digits =
        hours + (minutes > 0 ? String(minutes).padStart(2, "0") : "");
      const hint = `${digits}${normalizedUnit || ""}`;

      return [
        {
          title: formatTime(date),
          hint,
          date,
        },
      ];
    },
  },
  {
    isMatch: (search: string) => search.match(/^(\d{1,3})(m|h)?$/),
    getOptions: (match: any) => {
      const [, digits, unit] = match;
      const amount = Number(digits);

      const normalizedUnit = unit || "m";
      const date = add(
        new Date(),
        normalizedUnit === "m" ? { minutes: amount } : { hours: amount }
      );
      const hint = `${digits}${normalizedUnit || ""}`;

      return [
        {
          title: formatTime(date),
          hint,
          date,
        },
      ];
    },
  },
];

const _getTimestampItems = (search: string): TimestampItem[] => {
  let options: TimestampItem[] = [];

  for (const { isMatch, getOptions } of config) {
    const match = isMatch(search);

    if (match) {
      const newOptions = getOptions(match);
      if (newOptions) {
        options = [...options, ...newOptions];
      }
    }
  }

  return options;
};

export const getTimestampItems = (search: string): TimestampItem[] => {
  if (!search || search.trim() === "") {
    const defaultOptions = flatten(defaultSearch.map(_getTimestampItems));
    return defaultOptions;
  }

  return _getTimestampItems(search);
};
