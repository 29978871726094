import { Feedback } from "db/feedback/feedback.types";
import { formatDateTime } from "utils/dateUtils";

export const sendHeapFeedbackAsProperties = (feedback: Feedback) => {
  const properties: Record<string, any> = {
    feedback_user_name: `${feedback.firstName || ""} ${
      feedback.lastName || ""
    }`.trim(),
    feedback_auth_access_type: feedback.authAccessType,
    feedback_updated_at: feedback.updatedAt?.toString(),
  };

  if (feedback.dismiss) {
    properties.feedback_dismiss = feedback.dismiss;
  }

  if (feedback.mood) {
    properties.feedback_mood = feedback.mood;
  }

  if (feedback.text) {
    properties.feedback_text = feedback.text;
  }

  window.heap?.addUserProperties(properties);
};

export const sendFeedbackToHeapAsEvent = (feedback: Feedback) => {
  const properties: Record<string, any> = {
    feedback_event_user_name: `${feedback.firstName || ""} ${
      feedback.lastName || ""
    }`.trim(),
    feedback_event_auth_access_type: feedback.authAccessType,
    feedback_event_updated_at: feedback.updatedAt?.toString(),
  };

  if (feedback.dismiss) {
    properties.feedback_event_dismiss = feedback.dismiss;
  }

  if (feedback.mood) {
    properties.feedback_event_mood = feedback.mood;
  }

  if (feedback.text) {
    properties.feedback_event_text = feedback.text;
  }

  window.heap?.track("Feedback", properties);
};
