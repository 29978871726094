import {
  Descendant,
  Element,
  Editor,
  Node,
  Transforms,
  Path,
  Selection,
  Range,
  Location,
  Point,
} from "slate";
import { ReactEditor } from "slate-react";

import countWords from "utils/countWords";
import { makeNodeId } from "components/slate/config/makeNodeId";
import {
  ExtractedBacklink,
  BacklinkElement,
} from "components/slate/plugins/backlink/types";
import { isBacklinkElement } from "components/slate/plugins/backlink/utils";
import { crawlContent } from "helpers";
import { isParagraphElement } from "components/slate/plugins/paragraph/utils";
import { ParagraphType } from "components/slate/plugins/paragraph/types";
import { isTagElement } from "components/slate/plugins/tag/utils";
import { TagElement } from "components/slate/plugins/tag/types";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";

export const isEmptyContent = (content: Descendant[]) => {
  if (!content) {
    return true;
  }

  for (const element of content) {
    if (Node.string(element) !== "" || !isParagraphElement(element)) {
      return false;
    }
  }

  return true;
};

export const isEmptyNode = (node: Element) => {
  const result =
    node &&
    Node.string(node) === "" &&
    node.children.length === 1 &&
    !Element.isElement(node.children[0]);
  return result;
};

export const isEmptyNodeTrimmed = (node: any) => {
  const result = node && Node.string(node).trim() === "";
  return result;
};

export const contentToString = (content: Descendant[]): string => {
  if (!content) {
    return "";
  }

  return content.map(Node.string).join("");
};

export const includesEnd = (
  editor: Editor,
  path: Path,
  selection: Selection = editor.selection
) => {
  return Range.includes(selection, Editor.end(editor, path));
};

export const countContentWords = (content: Descendant[]) => {
  let count = 0;

  if (!content) return count;

  content.forEach((node) => {
    for (let [child] of Node.texts(node)) {
      count += countWords(child.text);
    }
  });

  return count;
};

export const findBacklinkNodesInEditor = (
  content: Descendant[]
): ExtractedBacklink[] => {
  if (!content) {
    return [];
  }

  const nodes: ExtractedBacklink[] = [];

  crawlContent(content, (node, context) => {
    if (isBacklinkElement(node)) {
      // it is supposed to receive whole editor content to get block node
      const blockNode = (context as any).cursor.stack.xs[2].node;

      nodes.push({
        ...node,
        blockId: blockNode?.id,
      });
    }
  });

  return nodes;
};

export const findBacklinkNodes = (content: Descendant[]): BacklinkElement[] => {
  if (!content) {
    return [];
  }

  const nodes: BacklinkElement[] = [];

  crawlContent(content, (node, context) => {
    if (isBacklinkElement(node)) {
      nodes.push(node);
    }
  });

  return nodes;
};

export const findTagsNodes = (content: Descendant[]): TagElement[] => {
  if (!content) {
    return [];
  }

  const nodes: TagElement[] = [];

  crawlContent(content, (node, context) => {
    if (isTagElement(node)) {
      nodes.push(node);
    }
  });

  return nodes;
};

export const focusEditorAtSelection = (editor: Editor, selection: Location) => {
  ReactEditor.focus(editor);
  Transforms.select(editor, selection);
};

export const focusEditorAtStart = (editor: Editor) => {
  focusEditorAtSelection(editor, Editor.start(editor, []));
};

export const focusEditorAtEnd = (editor: Editor) => {
  focusEditorAtSelection(
    editor,
    Editor.end(editor, [editor.children.length - 1])
  );
};

const castArray = (array: any) => {
  return Array.isArray(array) ? array : [array];
};

const castChild = (element: any) => {
  return typeof element === "string" ? { text: element } : element;
};

export const createElement = (type: any, _children = "", rest: any) => {
  const children = castArray(_children).map(castChild);

  return {
    id: makeNodeId(true),
    type,
    children,
    ...rest,
  };
};

export const getInitialValue = (text = ""): Element[] => {
  return [
    {
      id: makeNodeId(true),
      type: ParagraphType,
      children: [
        {
          text,
        },
      ],
    },
  ];
};

/**
 * If `at` is not defined, return an empty string.
 */
export const getText = (editor: any, at = null) =>
  (at && Editor.string(editor, at)) ?? "";
export const escapeRegExp = (text: any) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, "\\$&");
};
