import React from "react";

import styles from "./index.module.scss";

type OverviewPageLayout = {
  title?: string;
  children?: React.ReactNode;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  filter?: React.ReactNode;
};

const OverviewPageLayout = ({
  title,
  leftActions,
  rightActions,
  filter,
  children,
}: OverviewPageLayout) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>

        <div className={styles.actions}>
          <div className={styles.leftActions}>{leftActions}</div>
          <div className={styles.rightActions}>{rightActions}</div>
        </div>
      </div>
      <div className={styles.main}>
        {filter && <div className={styles.filter}>{filter}</div>}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default OverviewPageLayout;
