import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { Descendant, Element } from "slate";
import { Document, DocumentType } from "thunk-core";

import SlateDocumentEditor from "components/slate/editors/SlateDocumentEditor";
import EditorCard from "components/editor/EditorCard";
import DocumentEditorTitle from "components/editor/DocumentEditorTitle";
import { isEmptyContent } from "components/slate/utils";
import { EditorType } from "components/slate/types";
import { EditorErrorBoundary } from "components/errors/EditorErrorBoundary";
import EditorPlaceholder from "components/slate/components/EditorPlaceholder";
import useEditorFocused from "components/editor/hooks/useEditorFocused";
import EmptyNoteSuggestions from "components/editor/EmptyNoteSuggestions";
import { useDocumentsStore } from "stores/store";
import { useAppReadOnly } from "auth/hooks/useAppReadOnly";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import DocumentReferences from "components/editor/DocumentReferences";
import { useDocumentUserContext } from "providers/DocumentUserProvider";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import DocumentTags from "components/editor/DocumentTags";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { EditorsStore } from "components/slate/state/EditorsStore";
import { useStoreValue } from "state/utils";

import cn from "classnames";

import styles from "./index.module.scss";

export type DocumentEditorProps = {
  slateId: string;
  editorType: EditorType;
  documentId: string;
  documentType: DocumentType;
  className?: string;
  date?: Date;
  content: Element[];
  document: Document;
  readOnly?: boolean;
  onBacklinksChange?: (referencesDiff: any) => void;
  onBacklinkClick?: (element: BacklinkElement) => void;
};

const DocumentEditor = observer((props: DocumentEditorProps) => {
  const {
    slateId,
    editorType,
    documentId,
    documentType,
    className,
    date,
    content,
    document,
    onBacklinksChange,
    onBacklinkClick,
  } = props;

  const { isMobileScreen } = useDeviceDetector();

  const { documentUserId, isDocumentOwner } = useDocumentUserContext();
  const documentsStore = useDocumentsStore(documentType);
  const editor = useStoreValue(EditorsStore.editor(slateId));
  const title = getDocumentTitle(document);
  const appReadOnly = useAppReadOnly();
  const readOnly = props.readOnly || appReadOnly || !isDocumentOwner;

  const handleTitleChange = useCallback(
    (title: string) => {
      documentsStore.updateDocument(documentId, { title });
    },
    [documentId]
  );

  const handleContentChange = useCallback(
    (value: Descendant[]) => {
      documentsStore.updateContent({
        slateId,
        userId: documentUserId,
        documentId,
        nextContent: value as Element[],
      });
    },
    [documentId, slateId, documentUserId]
  );

  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);

  const { focused, onFocus, onBlur } = useEditorFocused(editor);
  const showPlaceholder =
    !readOnly && isEmptyContent(content) && !focused && !isTerminalActive;
  const showSuggestions =
    !readOnly &&
    documentType !== DocumentType.SNIPPET &&
    isEmptyContent(content) &&
    !focused &&
    !isTerminalActive;

  if (!document || !content) {
    return null;
  }

  return (
    <EditorCard
      isTransparent={isTerminalActive}
      date={date}
      className={cn(className, {
        [styles.notMainEditor]: editorType != EditorType.MainEditor,
      })}
      title={
        <DocumentEditorTitle
          isMainEditor={editorType === EditorType.MainEditor}
          readOnly={readOnly}
          editor={editor}
          title={title}
          handleTitleChange={handleTitleChange}
        />
      }
      tags={
        documentType === DocumentType.PAGE && (
          <DocumentTags document={document} />
        )
      }
      footer={
        <DocumentReferences
          document={document}
          onBacklinkClick={onBacklinkClick}
          onBacklinksChange={onBacklinksChange}
        />
      }
    >
      <EditorErrorBoundary isMainEditor={editorType === EditorType.MainEditor}>
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            position: "relative",
          }}
        >
          <SlateDocumentEditor
            slateId={slateId}
            userId={documentUserId}
            value={content}
            onChange={handleContentChange}
            editorType={editorType}
            readOnly={readOnly}
            onFocus={onFocus}
            onBlur={onBlur}
            documentId={documentId}
            documentType={documentType}
            onBacklinksChange={onBacklinksChange}
            onBacklinkClick={onBacklinkClick}
          />

          {showPlaceholder && <EditorPlaceholder />}
          {showSuggestions && (
            <EmptyNoteSuggestions
              editor={editor}
              minified={
                editorType === EditorType.SidePanelEditor ||
                editorType === EditorType.GraphEditor ||
                isMobileScreen
              }
            />
          )}
        </div>
      </EditorErrorBoundary>
    </EditorCard>
  );
});

export default DocumentEditor;
