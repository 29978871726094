import React, { useState } from "react";
import { isMobile } from "react-device-detect";

import {
  FilterButton,
  TableFilterToolbar,
} from "framework/components/table/TableFilterMobile";
import TextInput from "framework/components/form/TextInput";
import FilterDrawer from "framework/components/table/FilterDrawer";
import TagsFilterSection from "pages/app/DocumentsOverviewPage/TagsFilterSection";
import { Tag as TagDb } from "thunk-core";

type Props = {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  selectedTags: TagDb[];
  setSelectedTitles: React.Dispatch<React.SetStateAction<string[]>>;
};

const DocumentsFilterMobile = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const { search, setSearch, setSelectedTitles, selectedTags } = props;

  return (
    <TableFilterToolbar>
      <TextInput
        name="search"
        autoFocus={!isMobile}
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <FilterButton
        filtersCount={selectedTags.length}
        onClick={() => setIsOpened(true)}
      />
      <FilterDrawer isOpened={isOpened} setIsOpened={setIsOpened}>
        <TagsFilterSection
          selectedTags={selectedTags}
          setSelectedTitles={setSelectedTitles}
        />
      </FilterDrawer>
    </TableFilterToolbar>
  );
};

export default DocumentsFilterMobile;
