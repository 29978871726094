import React, { useContext, useEffect, useRef } from "react";

import usePersistedState from "hooks/usePersistedState";
import { useCurrentUserData } from "db/currentUser";
import { useAuth } from "auth/AuthProvider";

type ThemeProviderProps = {
  children: React.ReactNode;
};

type ThemeContextValue = {
  setTheme: (Themes) => void;
  darkMode: boolean;
  theme: ThemeTypes;
};

export enum ThemeTypes {
  light = "light-theme",
  dark = "dark-theme",
  auto = "auto-theme",
}

export const ThemeValues = [ThemeTypes.light, ThemeTypes.dark, ThemeTypes.auto];

function toggleTheme({ matches }: MediaQueryListEvent) {
  if (matches) {
    document.documentElement.setAttribute("theme", "dark");
  } else {
    document.documentElement.removeAttribute("theme");
  }
}

const ThemeContext = React.createContext<ThemeContextValue>(null);
export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = usePersistedState(
    "theme",
    (restored) => restored || ThemeTypes.auto
  );

  const match = useRef(
    window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)")
  );

  useEffect(() => {
    theme === ThemeTypes.dark
      ? document.documentElement.setAttribute("theme", "dark")
      : document.documentElement.removeAttribute("theme");

    if (theme === ThemeTypes.auto) {
      match.current?.removeEventListener("change", toggleTheme);
      match.current?.addEventListener("change", toggleTheme);
      toggleTheme({
        matches:
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)")?.matches,
      } as any);
    } else {
      match.current?.removeEventListener("change", toggleTheme);
    }
  }, [theme]);

  return (
    <ThemeContext.Provider
      value={{
        setTheme,
        darkMode: theme === ThemeTypes.dark,
        theme: theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const ThemeHandler = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <PrivateThemeHandler />;
  }

  return null;
};

const PrivateThemeHandler = () => {
  const { setTheme } = useThemeContext();
  const [user] = useCurrentUserData();

  useEffect(() => {
    if (user) {
      const theme = user.appTheme ?? ThemeTypes.auto;
      setTheme(theme);
    }
  }, [setTheme, user]);

  return null;
};
