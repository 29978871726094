import { makeAutoObservable } from "mobx";

export enum SyncStatus {
  Pending = "pending",
  Failed = "failed",
  Succeed = "succeed",
}

export default class SyncMonitorStore {
  private states: Map<string, SyncStatus> = new Map<string, SyncStatus>();

  constructor() {
    makeAutoObservable(this);
  }

  get status(): SyncStatus {
    let status: SyncStatus = SyncStatus.Succeed;
    for (const state of this.states.values()) {
      if (state === SyncStatus.Failed) {
        status = SyncStatus.Failed;
        break;
      }

      if (state === SyncStatus.Pending) {
        status = SyncStatus.Pending;
      }
    }

    return status;
  }

  getDocSyncState(documentId: string): SyncStatus {
    return this.states.get(documentId);
  }

  syncPending(documentId: string) {
    this.states.set(documentId, SyncStatus.Pending);
  }

  syncFailed(documentId: string) {
    this.states.set(documentId, SyncStatus.Failed);
  }

  syncSucceed(documentId: string) {
    return this.states.delete(documentId);
  }
}
