import { useMemo } from "react";
import stringFilter from "utils/stringFilter";
import { sortBy } from "ramda";
import { sortRecursively } from "utils/sortRecursively";
import { treeFlatMap } from "helpers";
import { useStore } from "stores/store";
import { TableComparator } from "framework/components/table/useComparator";
import { Tag as TagDb } from "thunk-core";

type TreeNode<T> = {
  item: T;
  level: number;
  children: TreeNode<T>[];
};

type Props = {
  loading: boolean;
  error: any;
  search: string;
  comparator: TableComparator<TagDb>;
};

const useFilteredTags = ({
  loading,
  error,
  search,
  comparator,
}: Props): TreeNode<TagDb>[] => {
  const { tagsStore } = useStore();

  const tags = tagsStore.getTags();

  const filteredTags = useMemo(() => {
    if (loading || error) {
      return [];
    }

    if (search.trim() != "") {
      return tags
        .filter((tag) => stringFilter(tag.title, search))
        .sort(comparator)
        .map((item) => ({
          item: item,
          level: 0,
          children: [],
        }));
    }

    const docs = buildTagsTree(tags, comparator);

    return docs;
  }, [tags, loading, error, search, comparator]);

  return filteredTags;
};

export const buildTagsTree = (
  tags: TagDb[],
  comparator?: TableComparator<TagDb>
): TreeNode<TagDb>[] => {
  const sorted = sortBy((x) => x.title, tags);

  const tree: TreeNode<TagDb>[] = [];
  const path: TreeNode<TagDb>[] = [];

  for (const tag of sorted) {
    const parentIndex = path.findIndex((p) => {
      const isParent =
        p.item.title === tag.title.substring(0, tag.title.lastIndexOf("/"));
      return isParent;
    });

    path.splice(parentIndex !== -1 ? parentIndex + 1 : 0);

    const item = {
      item: tag,
      children: [],
      level: path.length,
    };

    path.push(item);

    const last = path[path.length - 1];
    const parent = path[path.length - 2];
    const children = parent ? parent.children : tree;

    children.push(last);
  }

  comparator && sortRecursively(tree, (x, y) => comparator(x.item, y.item));

  const docs = treeFlatMap(tree, (item) => {
    return { ...item };
  });

  return docs;
};

export default useFilteredTags;
