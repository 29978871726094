import { AppUser } from "./users.types";
import { FirestoreDataConverter } from "../types";
import { toNullableDate } from "../utils";

export const USER_CONVERTER: FirestoreDataConverter<AppUser> = {
  toFirestore: (user: AppUser) => {
    return {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,

      avatarStoragePath: user.avatarStoragePath,
      avatarUrl: user.avatarUrl,

      availableInvitesCount: user.availableInvitesCount,
      darkMode: user.darkMode,
      appTheme: user.appTheme,
      isFocusModeNotificationSeen: user.isFocusModeNotificationSeen,
      isHelpSeen: user.isHelpSeen,
      isTemplateTipSeen: user.isTemplateTipSeen,
      recents: user.recents ?? [],
      sidebarSections: user.sidebarSections && {
        favorites: user.sidebarSections.favorites,
        recentNotes: user.sidebarSections.recentNotes,
      },

      twitterAccessToken: user.twitterAccessToken,
      twitterHandle: user.twitterHandle,
      twitterSecret: user.twitterSecret,
      twitterSignatureEnabled: user.twitterSignatureEnabled,

      createdByUserInvitation: user.createdByUserInvitation,
      inviterEmail: user.inviterEmail,
    };
  },
  fromFirestore: (snapshot): AppUser => {
    const data = snapshot.data();

    return {
      uid: snapshot.ref.id,
      email: data.email,
      firstName: data.firstName ?? null,
      lastName: data.lastName ?? null,
      createdAt: toNullableDate(data.createdAt),
      updatedAt: toNullableDate(data.updatedAt),

      avatarStoragePath: data.avatarStoragePath ?? null,
      avatarUrl: data.avatarUrl ?? null,

      availableInvitesCount: data.availableInvitesCount ?? null,
      darkMode: data.darkMode ?? null,
      appTheme: data.appTheme ?? null,
      isFocusModeNotificationSeen: data.isFocusModeNotificationSeen ?? null,
      isHelpSeen: data.isHelpSeen ?? null,
      isTemplateTipSeen: data.isTemplateTipSeen ?? null,
      recents: data.recents ?? [],
      sidebarSections: {
        favorites: data.sidebarSections?.favorites,
        recentNotes: data.sidebarSections?.recentNotes,
      },

      twitterAccessToken: data.twitterAccessToken ?? null,
      twitterHandle: data.twitterHandle ?? null,
      twitterSecret: data.twitterSecret ?? null,
      twitterSignatureEnabled: data.twitterSignatureEnabled ?? null,

      createdByUserInvitation: data.createdByUserInvitation ?? null,
      inviterEmail: data.inviterEmail ?? null,
    };
  },
};
