import { Editor, Transforms, Element, Node } from "slate";

import { EmbedType } from "components/slate/plugins/embed/types";

const WITH_DEFAULT_DELETE: Element["type"][] = [];

// handle default behaviour cases when it is impossible to remove empty line after/before void element (like image)
const handleDelete = (editor: any, isBackward: any) => {
  const { selection } = editor;
  const getSibling = (editor: any, selection: any) => {
    return isBackward
      ? Editor.previous(editor, selection)
      : Editor.next(editor, selection);
  };

  if (selection) {
    const [node, path] = Editor.node(editor, selection);

    // get node only inside top level node
    if (path.length === 2 && path[1] === 0) {
      const result = getSibling(editor, selection);
      if (result && result.length > 0) {
        const [sibling, sibling_path] = result;
        if (
          Editor.isBlock(editor, sibling) &&
          Editor.isVoid(editor, sibling) &&
          WITH_DEFAULT_DELETE.indexOf(sibling.type) === -1
        ) {
          if (Node.string(node) === "")
            Transforms.removeNodes(editor, selection);
          if (!isBackward) {
            Transforms.select(editor, path);
          } else {
            Transforms.select(editor, sibling_path);
          }
          return true;
        }
      }
    }
  }
};

export const withVoidsDeletion = (editor: any) => {
  const { deleteBackward, deleteForward } = editor;

  editor.deleteBackward = (unit: any) => {
    if (handleDelete(editor, true)) {
      return;
    }

    deleteBackward(unit);
  };

  editor.deleteForward = () => {
    if (handleDelete(editor, false)) {
      return;
    }

    deleteForward();
  };

  return editor;
};
