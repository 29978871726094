import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from "firebase/firestore";

import { Feedback } from "db/feedback/feedback.types";
import { toNullableDate } from "db/utils";

export const FEEDBACK_CONVERTER: FirestoreDataConverter<Feedback> = {
  toFirestore: (feedback: Feedback) => {
    const { id, ...converted } = feedback;
    return converted;
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot): Feedback => {
    const data = snapshot.data();

    return {
      id: snapshot.ref.id,
      userId: data.userId,
      email: data.email,
      firstName: data.firstName ?? null,
      lastName: data.lastName ?? null,
      dismiss: data.dismiss ?? false,
      mood: data.mood ?? null,
      text: data.text ?? null,
      userCreatedAt: toNullableDate(data.updatedAt),
      updatedAt: toNullableDate(data.updatedAt),
      authAccessType: data.authAccessType,
      type: data.type,
    };
  },
};
