import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from "firebase/firestore";

import { DocumentSettings } from "./documentsSettings.types";

export const DOCUMENTS_SETTINGS_CONVERTER: FirestoreDataConverter<DocumentSettings> =
  {
    toFirestore: (page: DocumentSettings) => {
      return {
        ...page,
        pinned: Array.from(page.pinned ?? []),
        referencesCollapsed: Array.from(page.referencesCollapsed ?? []),
      };
    },
    fromFirestore: (snapshot: QueryDocumentSnapshot): DocumentSettings => {
      const data = snapshot.data();
      return {
        id: snapshot.ref.id,
        userId: data.userId,
        pinned: new Set(data.pinned ?? []),
        referencesOpened: data.referencesOpened ?? true,
        referencesCollapsed: new Set(data.referencesCollapsed ?? []),
      };
    },
  };

export const makeDocumentSettings = (
  userId: string,
  documentId: string
): DocumentSettings => {
  return {
    id: documentId,
    userId: userId,
    pinned: new Set(),
    referencesOpened: true,
    referencesCollapsed: new Set(),
  };
};
