import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { usePopper } from "react-popper";
import { Options } from "@popperjs/core";

import useDebouncedValue from "hooks/useDebouncedValue";
import useWindowEventListener from "hooks/useWindowEventListener";
import isHotkey from "is-hotkey";
import { useSidebarContext } from "providers/SidebarProvider";

export type AppPopperProps = ReturnType<typeof useAppPopper>;

type Props = {
  targetElement?: HTMLElement | null;
  placement?: Options["placement"];
  offset?: [number, number];
  hasArrow?: boolean;
};

const useAppPopper = (props: Props = {}) => {
  const { placement, offset, hasArrow = false } = props;

  const [isOpened, setIsOpened] = useState(false);
  const openPopper = useCallback(() => setIsOpened(true), []);
  const closePopper = useCallback(() => setIsOpened(false), []);

  const isOpenedDebounced = useDebouncedValue(isOpened, 300);

  const { isSidebarOpened } = useSidebarContext();

  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [contentElement, setContentElement] = useState<HTMLElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const popper = usePopper(
    props.targetElement ?? targetElement,
    contentElement,
    {
      placement: placement ?? "bottom",
      strategy: "fixed",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: offset ?? (hasArrow ? [0, 15] : [0, 5]),
          },
        },
        hasArrow && {
          name: "arrow",
          options: {
            element: arrowElement,
            padding: 20,
          },
        },
      ].filter(Boolean),
    }
  );

  useLayoutEffect(() => {
    isOpened && popper.update?.();
  }, [isOpened, targetElement]);

  useEffect(() => {
    setTimeout(() => {
      popper.update?.();
    }, 300);
  }, [isSidebarOpened]);

  useWindowEventListener(
    "keydown",
    (e) => isHotkey("Escape", e) && closePopper()
  );

  return {
    hasArrow,
    popper,
    isOpened,
    isOpenedDebounced,
    openPopper,
    closePopper,
    targetElement,
    contentElement,
    arrowElement,
    setTargetElement,
    setContentElement,
    setArrowElement,
  };
};

export default useAppPopper;
