import { useSelected } from "slate-react";
import cn from "classnames";

import { ElementProps } from "components/slate/types";
import { MediaEmbedElement } from "components/slate/plugins/mediaEmbed/types";
import { MediaEmbedUrlComponent } from "./MediaEmbedUrlComponent";
import { getFigmaUrl } from "../utils";

import styles from "./index.module.scss";

const getAdditionalIframeAttributes = (url: string) => {
  let parsed;
  try {
    parsed = new URL(url);
  } catch {
    return {};
  }
  if (getFigmaUrl(parsed)) {
    return {
      allowFullScreen: true,
    };
  }
  return {};
};

const MediaEmbedComponent = (
  props: ElementProps & { element: MediaEmbedElement }
) => {
  const { children, attributes, element } = props;
  const { url } = element;
  const querySeparator = url.includes("?") ? "" : "?";
  const selected = useSelected();

  if (!url) {
    return <MediaEmbedUrlComponent {...props} />;
  }

  return (
    <div
      className={cn(styles.container, { [styles.selected]: selected })}
      {...attributes}
    >
      <iframe
        className={styles.embedIframe}
        contentEditable={false}
        title="embed"
        src={`${url}${querySeparator}&title=0&byline=0&portrait=0`}
        frameBorder="0"
        {...getAdditionalIframeAttributes(url)}
      />
      {children}
    </div>
  );
};

export default MediaEmbedComponent;
