import { doc, setDoc, getDoc } from "firebase/firestore";

import { db } from "firebaseInstance";
import { DOCUMENTS_SETTINGS_CONVERTER } from "db/documentsSettings/documentsSettings.mapping";
import { DocumentSettings } from "db/documentsSettings/documentsSettings.types";
import { getDocumentsSettingsPath } from "thunk-core";

export const getDocumentSettings = async (
  documentId: string
): Promise<DocumentSettings | null> => {
  const pageSnapshot = await getDoc(
    doc(db, getDocumentsSettingsPath(documentId)).withConverter(
      DOCUMENTS_SETTINGS_CONVERTER
    )
  );

  if (!pageSnapshot.exists()) {
    return null;
  }

  return pageSnapshot.data();
};

export const upsertDocumentSettings = async ({
  userId,
  documentId,
  updates,
}: {
  userId: string;
  documentId: string;
  updates: Partial<DocumentSettings>;
}) => {
  await setDoc(
    doc(db, getDocumentsSettingsPath(documentId)).withConverter(
      DOCUMENTS_SETTINGS_CONVERTER
    ),
    { userId: userId, ...updates },
    { merge: true }
  );
};
