import { RenderElementProps, RenderLeafProps } from "slate-react";
import { Editor, Descendant, NodeEntry, Range } from "slate";
import React, { DOMAttributes, SyntheticEvent } from "react";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";

export type ElementProps = {
  element: RenderElementProps["element"];
  children: RenderElementProps["children"];
  attributes?: RenderElementProps["attributes"];
  listeners?: SyntheticListenerMap | undefined;
};

export type Handler = React.EventHandler<SyntheticEvent> | undefined;
type KeysMatching<T extends object, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];
export type EditorHandler = (editor: Editor) => Handler;
export type DOMHandlersKeys = KeysMatching<DOMAttributes<Element>, Handler>;

export type UseSlatePlugin<Options = {}, State = {}> = (
  options: Options
) => SlatePlugin<State>;

export type SlatePlugin<State = {}> = {
  withOverrides?: (editor: Editor) => Editor;
  handlers?: Partial<Record<DOMHandlersKeys, EditorHandler>>;
  onChange?: (editor: Editor) => (value: Descendant[]) => void;
  onMount?: (editor: Editor) => void;
  onUnmount?: (editor: Editor) => void;
  renderElement?: (props: ElementProps) => JSX.Element | null;
  renderLeaf?: (props: RenderLeafProps) => JSX.Element | null;
  state?: State;
  decorate?: (editor: Editor) => (entry: NodeEntry) => Range[];
};

export enum EditorType {
  MainEditor = "main-editor",
  SidePanelEditor = "side-panel-editor",
  GraphEditor = "graph-editor",
  HelpPageEditor = "help-page-editor",
  ReferenceEditor = "reference-editor",
  SharingPageEditor = "sharing-page-editor",
}
