import React from "react";
import { Editor } from "slate";
import isHotkey from "is-hotkey";

import {
  BoldMarkType,
  CodeMarkType,
  HighlightMarkType,
  ItalicMarkType,
  StrikethroughMarkType,
  UnderlineMarkType,
} from "components/slate/plugins/marks/types";
import { EditorActions } from "components/slate/actions";
import { ListTypes } from "components/slate/plugins/list/types";

export const onKeyDown = (editor: Editor) => (e: React.KeyboardEvent) => {
  if (!editor.selection) {
    return;
  }

  // elements
  if (isHotkey(["mod+opt+0", "mod+shift+0"], e)) {
    e.preventDefault();
    EditorActions.applyParagraph({ editor });
    return;
  }

  if (isHotkey(["mod+opt+1", "mod+shift+1"], e)) {
    e.preventDefault();
    EditorActions.applyHeading({ editor }, { level: 1 });
    return;
  }

  if (isHotkey(["mod+opt+2", "mod+shift+2"], e)) {
    e.preventDefault();
    EditorActions.applyHeading({ editor }, { level: 2 });
    return;
  }

  if (isHotkey(["mod+opt+3", "mod+shift+3"], e)) {
    e.preventDefault();
    EditorActions.applyHeading({ editor }, { level: 3 });
    return;
  }

  if (isHotkey(["mod+opt+6", "mod+shift+6"], e)) {
    e.preventDefault();
    EditorActions.applyImage({ editor }, { url: "" });
    return;
  }

  if (isHotkey(["mod+opt+7", "mod+shift+7"], e)) {
    e.preventDefault();
    EditorActions.applyList({ editor }, { listType: ListTypes.Numbered });
    return;
  }

  if (isHotkey(["mod+opt+8", "mod+shift+8"], e)) {
    e.preventDefault();
    EditorActions.applyList({ editor }, { listType: ListTypes.Bulleted });
    return;
  }

  if (isHotkey(["mod+opt+9", "mod+shift+9"], e)) {
    e.preventDefault();
    EditorActions.applyList({ editor }, { listType: ListTypes.TodoList });
    return;
  }

  if (isHotkey(["mod+opt+.", "mod+shift+."], e)) {
    e.preventDefault();
    EditorActions.applyBlockquote({ editor });
    return;
  }

  if (isHotkey(["mod+opt+0", "mod+shift+0"], e)) {
    e.preventDefault();
    EditorActions.applyParagraph({ editor });
    return;
  }

  if (isHotkey(["mod+opt+0", "mod+shift+0"], e)) {
    e.preventDefault();
    EditorActions.applyParagraph({ editor });
    return;
  }

  // marks
  if (isHotkey("mod+b", e)) {
    e.preventDefault();
    EditorActions.applyMark({ editor }, { markType: BoldMarkType });
    return;
  }

  if (isHotkey("mod+i", e)) {
    e.preventDefault();
    EditorActions.applyMark({ editor }, { markType: ItalicMarkType });
    return;
  }

  if (isHotkey("mod+shift+s", e)) {
    e.preventDefault();
    EditorActions.applyMark({ editor }, { markType: StrikethroughMarkType });
    return;
  }

  if (isHotkey("mod+e", e)) {
    e.preventDefault();
    EditorActions.applyMark({ editor }, { markType: CodeMarkType });
    return;
  }

  if (isHotkey("mod+shift+h", e)) {
    e.preventDefault();
    EditorActions.applyMark({ editor }, { markType: HighlightMarkType });
    return;
  }

  if (isHotkey("mod+u", e)) {
    e.preventDefault();
    EditorActions.applyMark({ editor }, { markType: UnderlineMarkType });
    return;
  }
};
