import React, { createContext, useContext } from "react";

type HideCompletedContextValue = boolean;

const HideCompletedContext = createContext<HideCompletedContextValue>(false);

export const useHideCompleted = () => useContext(HideCompletedContext);

type HideCompletedProviderProps = {
  children: React.ReactNode;
  value: HideCompletedContextValue;
};

export const HideCompletedProvider = ({
  children,
  value,
}: HideCompletedProviderProps) => {
  return (
    <HideCompletedContext.Provider value={value}>
      {children}
    </HideCompletedContext.Provider>
  );
};
