import { doc, updateDoc } from "firebase/firestore";

import { db } from "firebaseInstance";
import { getDocumentData } from "db/utils";
import { AppUser, getUserPath, USER_CONVERTER } from "thunk-core";

export const getUser = async (userId: string) => {
  return await getDocumentData(
    doc(db, getUserPath(userId)).withConverter(USER_CONVERTER)
  );
};

export const updateUser = async (userId: string, updates: Partial<AppUser>) => {
  await updateDoc(
    doc(db, getUserPath(userId)).withConverter(USER_CONVERTER),
    updates
  );
};
