import { Descendant } from "slate";

export type TagType = "tag";
export const TagType: TagType = "tag";

export type TagElement = {
  id: string;
  type: TagType;
  userId: string;
  tagId: string;
  children: Descendant[];
};

export type ExtractedTag = TagElement & {
  blockId: string;
};
