import {
  PdfIcon,
  DocIcon,
  DocxIcon,
  XlsIcon,
  XlsxIcon,
  PptIcon,
  PptxIcon,
  TxtIcon,
  CsvIcon,
  RtfIcon,
  GenericFileIcon,
} from "thunk-icons";
import cn from "classnames";
import { useReadOnly, useSelected } from "slate-react";

import { ElementProps } from "components/slate/types";
import { FileElement } from "../../types";
import { getHumanReadableFileSize } from "../../utils";
import { FileUploadComponent } from "./FileUploadComponent";

import styles from "./index.module.scss";

const iconComponents = {
  pdf: PdfIcon,
  doc: DocIcon,
  docx: DocxIcon,
  xls: XlsIcon,
  xlsx: XlsxIcon,
  ppt: PptIcon,
  pptx: PptxIcon,
  txt: TxtIcon,
  csv: CsvIcon,
  rtf: RtfIcon,
};

const fileTypeDescriptions = {
  pdf: "PDF document",
  doc: "Word document",
  docx: "Word document",
  xls: "Excel spreadsheet",
  xlsx: "Excel spreadsheet",
  ppt: "Powerpoint presentation",
  pptx: "Powerpoint presentation",
  txt: "Text file",
  csv: "CSV file",
  rtf: "RTF document",
};

const FileIcon = ({ element }: { element: FileElement }) => {
  const { extension } = element;
  const Icon = iconComponents[extension] || GenericFileIcon;
  return <Icon className={styles.icon} size={28} />;
};

export const FileComponent = ({
  children,
  attributes,
  element,
}: ElementProps & { element: FileElement }) => {
  const onClick = () => {
    window.open(element.url, "_blank");
  };

  const selected = useSelected();
  const isReadOnly = useReadOnly();

  if (!element.url) {
    if (isReadOnly) {
      return <div contentEditable={false}>{children}</div>;
    }
    return (
      <div
        {...attributes}
        className={cn(styles.root, { [styles.selected]: selected })}
      >
        <div className={styles.content} contentEditable={false}>
          <FileUploadComponent element={element} />
        </div>
        {children}
      </div>
    );
  }

  return (
    <div
      {...attributes}
      className={cn(styles.root, { [styles.selected]: selected })}
      onClick={onClick}
    >
      <div className={styles.content} contentEditable={false}>
        <FileIcon element={element} />
        <div className={styles.fileName}>{element.fileName}</div>
        <div className={styles.meta}>
          <div>
            {fileTypeDescriptions[element.extension] ||
              element.extension.toUpperCase()}
          </div>
          <div>{getHumanReadableFileSize(element.fileSize)}</div>
        </div>
      </div>
      {children}
    </div>
  );
};
