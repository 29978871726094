import { useMutation, useQuery, useQueryClient } from "react-query";

import { getUser, updateUser } from "db/user/user.queries";
import { useCurrentUserId } from "db/currentUser";
import { AppUser } from "thunk-core";

export const useCurrentUserData = () => {
  const userId = useCurrentUserId();
  return useQuery(["user", userId], () => getUser(userId));
};

export const useUpdateCurrentUser = () => {
  const queryClient = useQueryClient();
  const userId = useCurrentUserId();
  return useMutation(
    ({ updates }: { updates: Partial<AppUser> }) => updateUser(userId, updates),
    {
      onMutate: ({ updates }) => {
        queryClient.setQueryData(["user", userId], (user: AppUser) => {
          const updated = {
            ...user,
            ...updates,
          };
          return updated;
        });
      },
    }
  );
};
