import React, { useCallback } from "react";
import { CSSTransition } from "react-transition-group";

import { dateIdToDate, getEditorPathname } from "helpers";
import { PortalBody } from "framework/components/Portal";
import EditorCard from "components/editor/EditorCard";
import AppResult from "components/results/AppResult";
import { useCurrentUserId } from "db/currentUser";
import { GraphNode } from "stores/types";
import { EditorType } from "components/slate/types";
import { useEditingSession } from "stores/hooks/useEditingSession";
import CardSidePanelToolbar from "components/editor/toolbar/CardSidePanelToolbar";
import DocumentEditor from "components/editor/DocumentEditor";

import styles from "./index.module.scss";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { DocumentType } from "thunk-core";

import CardNavigationActions from "components/editor/CardNavigationActions";

type GraphEditorProps = {
  selectedItem: GraphNode;
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
  onBacklinksChange: any;
};

const GraphEditor = ({
  selectedItem,
  setSelectedId,
  onBacklinksChange,
}: GraphEditorProps) => {
  return (
    <PortalBody>
      <CSSTransition
        in={selectedItem != null}
        timeout={{
          appear: 1000,
          enter: 400,
          exit: 400,
        }}
        classNames={{
          enterActive: styles.visible,
          enterDone: styles.visible,
        }}
        unmountOnExit
      >
        <div className={styles.container}>
          <div className={styles.cardWrapper} data-card-type="graph-card">
            {selectedItem != null ? (
              <GraphEditorComponent
                selectedItem={selectedItem}
                setSelectedId={setSelectedId}
                onBacklinksChange={onBacklinksChange}
              />
            ) : (
              <EditorCard />
            )}
          </div>
        </div>
      </CSSTransition>
    </PortalBody>
  );
};

export default GraphEditor;

const GraphEditorComponent = ({
  selectedItem,
  setSelectedId,
  onBacklinksChange,
}: GraphEditorProps) => {
  const editorType = EditorType.GraphEditor;
  const { id, documentType } = selectedItem;

  const userId = useCurrentUserId();

  const { loading, error, slateId, content, document } = useEditingSession({
    userId,
    editorType,
    documentId: documentType === DocumentType.PAGE ? id : null,
    dateId: documentType === DocumentType.NOTE ? id : null,
    documentType,
  });

  const cardProps = {
    date: documentType === DocumentType.NOTE && dateIdToDate(id),
    title: documentType === DocumentType.PAGE && document?.title,
    isSidePanelEditor: true,
  };

  const handleBacklinkClick = useCallback((backlink: BacklinkElement) => {
    setSelectedId(backlink.targetId);
  }, []);

  if (loading) {
    return <EditorCard {...cardProps} />;
  }

  if (error) {
    console.error(error);
    return (
      <EditorCard {...cardProps}>
        <AppResult resultType="fetching" isCenteredContent={true} />
      </EditorCard>
    );
  }

  const documentId = document.id; // get documentId after session started

  const toolbar = (
    <CardSidePanelToolbar
      href={getEditorPathname(documentType, documentId, document.dateId)}
    />
  );

  return (
    <div className={styles.cardContentWrapper}>
      <CardNavigationActions
        isBacklinksCard={true}
        toolbar={toolbar}
        document={document}
      />
      <DocumentEditor
        slateId={slateId}
        editorType={editorType}
        documentId={documentId}
        documentType={documentType}
        date={documentType === DocumentType.NOTE ? dateIdToDate(id) : null}
        content={content}
        document={document}
        onBacklinksChange={onBacklinksChange}
        onBacklinkClick={handleBacklinkClick}
      />
    </div>
  );
};
