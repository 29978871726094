import {
  Heading1Component,
  Heading2Component,
  Heading3Component,
} from "components/slate/plugins/heading/components/HeadingComponent";
import {
  isHeading1Element,
  isHeading2Element,
  isHeading3Element,
} from "components/slate/plugins/heading/utils";
import { UseSlatePlugin } from "components/slate/types";
import withHeading from "components/slate/plugins/heading/withHeading";

const useHeadingPlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withHeading,
    renderElement: (props) => {
      if (isHeading1Element(props.element)) {
        return <Heading1Component {...props} element={props.element} />;
      }

      if (isHeading2Element(props.element)) {
        return <Heading2Component {...props} element={props.element} />;
      }

      if (isHeading3Element(props.element)) {
        return <Heading3Component {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useHeadingPlugin;
