import React, { useMemo, useRef, useState } from "react";

import useDebouncedSwitch from "hooks/useDebouncedSwitch";
import { filterGraphData } from "pages/app/GraphPage/utils";
import usePersistedUserState from "hooks/usePersistedUserState";
import useGraphData from "pages/app/GraphPage/useGraphData";
import { graphFilterTabs } from "pages/app/GraphPage/constants";
import { FilteredGraphData } from "pages/app/GraphPage/types";
import { GraphNode } from "stores/types";
import { ExtractedBacklink } from "components/slate/plugins/backlink/types";

type UseGraphPageHook = {
  loading: boolean;
  error: Error;
  graphRef: any;
  isEmptyPage: boolean;
  graphData: FilteredGraphData | null;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  filters: Record<string, boolean>;
  applyFilters: (changes: Record<string, boolean>) => void;
  selectedId: string[];
  setSelectedId: React.Dispatch<React.SetStateAction<string[]>>;
  selectedItem: GraphNode;
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  handleBacklinksChange;
};

const useGraphPage = (): UseGraphPageHook => {
  const graphRef = useRef({});

  const [selectedId, setSelectedId] = useState(null);
  const [search, setSearch] = useState("");

  const searchDebounced = useDebouncedSwitch(search, 300, Boolean(search));
  const [filters, setFilters] = usePersistedUserState(
    "graphFiltersState",
    (restored) =>
      restored || {
        orphans: true,
        dailyNotes: true,
      }
  );
  const [activeTab, setActiveTab] = useState(graphFilterTabs.allNotes);
  const applyFilters = (changes: any) =>
    setFilters((state: any) => ({
      ...state,
      ...changes,
    }));

  const [graphState, loading, error, { add, remove }] = useGraphData();

  const filteredGraphData = useMemo<FilteredGraphData | null>(() => {
    if (loading || error) {
      return null;
    }

    return filterGraphData(graphState, searchDebounced, activeTab, filters);
  }, [graphState, loading, error, searchDebounced, activeTab, filters]);

  const selectedItem = useMemo(
    () => graphState != null && graphState.nodesMap.get(selectedId),
    [graphState, selectedId]
  );

  const handleBacklinksChange = (result: {
    added: ExtractedBacklink[];
    removed: ExtractedBacklink[];
  }) => {
    const { added, removed } = result;

    if (added.length > 0) {
      add(added);
    }
    if (removed.length > 0) {
      remove(removed);
    }
  };

  return {
    loading,
    error,
    graphRef,
    isEmptyPage: !loading && graphState?.nodesMap.size === 0,
    graphData: filteredGraphData,
    search,
    setSearch,
    filters,
    applyFilters,
    selectedId,
    setSelectedId,
    selectedItem,
    activeTab,
    setActiveTab,
    handleBacklinksChange,
  };
};

export default useGraphPage;
