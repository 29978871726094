import Sidebar from "components/layout/Sidebar";
import AppNavigation from "layout/AppLayout/AppNavigation";
import AppFavorites from "layout/AppLayout/AppFavorites";
import AppRecentPages from "layout/AppLayout/AppRecentPages";
import AppSidebarActions from "layout/AppLayout/AppSidebarActions";

const AppSidebar = () => {
  return (
    <Sidebar
      navigation={<AppNavigation />}
      favorites={<AppFavorites />}
      recentPages={<AppRecentPages />}
      actions={<AppSidebarActions />}
    />
  );
};

export default AppSidebar;
