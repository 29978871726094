import React, { forwardRef } from "react";
import cn from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, LinkProps } from "react-router-dom";

import styles from "./index.module.scss";
import variants from "./variants.module.scss";
import sizes from "./sizes.module.scss";
import "./resetButton.scss";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

export type ButtonProps = {
  as?: "a" | "button" | typeof Link;
  loading?: boolean;
  isSelected?: boolean;
  variant?:
    | "primary"
    | "secondary"
    | "circular"
    | "circularElectronHeader"
    | "circularCardHeader"
    | "headerImage"
    | "ghost"
    | "pink"
    | "error";
  size?: "regular" | "small" | "large" | "symbol" | "icon" | "headerImageSize";
  children?: React.ReactNode;
  btnClass?: string;
  icon?: React.ComponentType<any>;
  mobileIcon?: React.ComponentType<any>;
  iconSize?: number;
  fullWidth?: boolean;

  // elements props
  to?: LinkProps<any>["to"]; // support "to" for Link
  href?: string; // support "href" for a
  style?: React.CSSProperties;
  autoFocus?: boolean;
  disabled?: boolean;
  tabIndex?: number | undefined;
  type?: "button" | "reset" | "submit";
  onKeyDown?: any;
  onClick?: React.MouseEventHandler;
  onMouseDown?: React.MouseEventHandler;
  onMouseEnter?: React.MouseEventHandler;
};

const Button = (props: ButtonProps, ref) => {
  const {
    as = "button",
    loading = false,
    variant = "ghost",
    size = "regular",
    isSelected = false,
    children,
    btnClass,
    icon,
    mobileIcon,
    iconSize = 22,
    fullWidth,

    // elements props
    to,
    href,
    style,
    autoFocus,
    disabled,
    tabIndex,
    type,
    onKeyDown,
    onClick,
    onMouseDown,
    onMouseEnter,
  } = props;

  const { isMobileScreen } = useDeviceDetector();

  const Icon = isMobileScreen && mobileIcon ? mobileIcon : icon;
  const renderText = !(isMobileScreen && mobileIcon);

  const variantClass = variantClasses[variant];
  const sizeClass = sizeClasses[size];

  const className = cn(styles.button, variantClass, sizeClass, {
    [styles.disabled]: props.disabled,
    [variants.disabled]: props.disabled,
    [styles.fullWidth]: fullWidth,
    [styles.selected]: isSelected,
  });

  const Component = as as any;

  return (
    <Component
      ref={ref}
      style={style}
      className={cn(className, btnClass, {
        [styles.noMargin]: !children || !renderText,
      })}
      to={to}
      href={href}
      autoFocus={autoFocus}
      disabled={disabled}
      tabIndex={tabIndex}
      type={type}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onKeyDown={onKeyDown}
    >
      <span className={styles.loader}>
        <ClipLoader size={20} loading={loading} color="inherit" />
      </span>
      {Icon && <Icon size={iconSize} className={styles.buttonIcon} />}
      {renderText && children}
    </Component>
  );
};

export default forwardRef<any, ButtonProps>(Button);

const variantClasses = {
  primary: variants.variantPrimary,
  secondary: variants.variantSecondary,
  circular: variants.variantCircular,
  circularElectronHeader: variants.variantCircularElectronHeader,
  circularCardHeader: variants.variantCircularCardHeader,
  headerImage: variants.variantHeaderImage,
  ghost: variants.variantGhost,
  pink: variants.variantPink,
  error: variants.variantError,
};

const sizeClasses = {
  regular: sizes.sizeRegular,
  small: sizes.sizeSmall,
  large: sizes.sizeLarge,
  symbol: sizes.sizeSymbol,
  icon: sizes.sizeIcon,
  headerImageSize: sizes.sizeHeaderImageSize,
};
