import { ReactNotificationOptions, store } from "react-notifications-component";
import { omit } from "ramda";

import Notification, {
  NotificationProps,
} from "framework/components/Notification";

const defaultOptions: ReactNotificationOptions = {
  type: "default",
  insert: "top",
  container: "bottom-left",
  animationIn: ["animated", "fadeIn"],
  animationOut: ["animated", "fadeOut"],
};

export const addNotification = (
  options: Partial<Omit<ReactNotificationOptions, "type">> & {
    duration: number;
  } & NotificationProps
) => {
  return store.addNotification({
    ...defaultOptions,
    dismiss: {
      duration: options.duration,
    },
    ...omit(["type"], options),
    message: (
      <Notification
        container={options.container || defaultOptions.container}
        type={options.type}
        buttons={options.buttons}
        text={options.text}
        icon={options.icon}
        doShowCloseIcon={options.doShowCloseIcon}
        doShowLogo={options.doShowLogo}
      />
    ),
  });
};

export const removeNotification = (id: any) => {
  return store.removeNotification(id);
};

const useNotifications = () => {
  return { addNotification, removeNotification };
};

export default useNotifications;
