import React, { useCallback, useEffect, useRef, useState } from "react";

import { useStore } from "stores/store";
import useAppPopper from "framework/components/poppers/useAppPopper";
import AppPopper, {
  PopperDivider,
  PopperMenuItem,
} from "framework/components/poppers/AppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import ColorPicker from "components/tags/ColorPicker";
import TitleTextInput from "components/tags/TItleTextInput";
import { makeTag } from "db/tags/tags.mapping";
import { useCurrentUserId } from "db/currentUser";
import EmojiPicker from "components/tags/EmojiPicker";
import { Tag } from "thunk-core";

type Values = {
  title: string;
  color: Tag["color"];
  variant: Tag["variant"];
  emoji: Tag["emoji"];
};

const initialValue: Values = {
  title: "",
  color: "default",
  variant: "outlined",
  emoji: null,
};

const CreateTagMenu = (props: {
  children: React.FC<{ ref: (ref: HTMLElement) => void }>;
}) => {
  const { children } = props;

  const userId = useCurrentUserId();

  const [values, setValues] = useState<Values>(initialValue);
  const updateValues = useCallback(
    (updates: Partial<Values>) =>
      setValues((state) => ({ ...state, ...updates })),
    []
  );

  const { tagsStore } = useStore();
  const popperProps = useAppPopper({
    placement: "bottom-start",
    hasArrow: true,
  });
  useClickPopper(popperProps);

  const { isOpened } = popperProps;

  useEffect(() => {
    if (isOpened) {
      setValues(initialValue);
    }
  }, [isOpened]);

  const handleSubmit = () => {
    tagsStore.createNewTag(makeTag({ userId, ...values }));
    popperProps.closePopper();
  };

  const handleTitleChange = (title: string) => {
    updateValues({ title });
  };

  const handleColorChange = (color: Tag["color"], variant: Tag["variant"]) => {
    updateValues({ color, variant });
  };

  const handleEmojiChange = (emoji: Tag["emoji"]) => {
    updateValues({ emoji });
  };

  return (
    <AppPopper
      {...popperProps}
      content={
        <CreateTagMenuContent
          isOpened={popperProps.isOpened}
          title={values.title}
          color={values.color}
          variant={values.variant}
          emoji={values.emoji}
          onSubmit={handleSubmit}
          onTitleChange={handleTitleChange}
          onColorChange={handleColorChange}
          onEmojiChange={handleEmojiChange}
        />
      }
    >
      {children({ ref: popperProps.setTargetElement })}
    </AppPopper>
  );
};
export default CreateTagMenu;

const CreateTagMenuContent = ({
  isOpened,
  title,
  color,
  variant,
  emoji,
  onSubmit,
  onTitleChange,
  onColorChange,
  onEmojiChange,
}: {
  isOpened: boolean;
  onSubmit: () => void;
  onTitleChange: (title: string) => void;
  onColorChange: (color: Tag["color"], variant: Tag["variant"]) => void;
  onEmojiChange: (emoji: Tag["emoji"]) => void;
} & Values) => {
  const inputRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (isOpened) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [isOpened]);

  return (
    <div>
      <PopperMenuItem>
        <ColorPicker color={color} variant={variant} onChange={onColorChange} />
      </PopperMenuItem>

      <PopperDivider />

      <PopperMenuItem>
        <EmojiPicker emoji={emoji} onChange={onEmojiChange} />
        <TitleTextInput
          inputRef={inputRef}
          initialValue={initialValue.title}
          value={title}
          buttonTitle="Create"
          onChange={(e) => onTitleChange(e.target.value)}
          onSubmit={() => onSubmit()}
        />
      </PopperMenuItem>
    </div>
  );
};
