import { Editor, Transforms, Range } from "slate";

import { isHeadingElement } from "components/slate/plugins/heading/utils";
import { ParagraphType } from "components/slate/plugins/paragraph/types";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { makeNodeId } from "components/slate/config/makeNodeId";

export const withResetType = (editor: Editor) => {
  const { insertBreak } = editor;

  /**
   * Reset type to paragraph if inserting break from heading element
   */
  editor.insertBreak = () => {
    const [headerEntry] = Editor.nodes(editor, {
      match: (node, path) =>
        isHeadingElement(node) &&
        !!editor.selection &&
        Range.includes(editor.selection, Editor.end(editor, path)),
    });

    if (headerEntry) {
      const [node, path] = headerEntry;

      if (
        ExtendedEditor.isFoldingElement(editor, node) &&
        !!node.folded &&
        !!editor.selection &&
        Range.includes(editor.selection, Editor.end(editor, path))
      ) {
        // keep it for extended editor plugin, to handle folded elements
        insertBreak();
        return;
      }

      Transforms.insertNodes(editor, {
        id: makeNodeId(true),
        type: ParagraphType,
        children: [
          {
            text: "",
          },
        ],
      });

      return;
    }

    insertBreak();
  };

  return editor;
};
