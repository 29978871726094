import { useCallback } from "react";
import { Editor, Node } from "slate";
import { toggleMark } from "@udecode/plate-core";
import { HistoryEditor } from "slate-history";
import { clone } from "ramda";
import { v4 as uuidv4 } from "uuid";

import { wrapFragmentInDoubleBrackets } from "components/slate/plugins/backlink/transforms";
import { applyLinkToSelection } from "components/slate/plugins/link/transforms";
import useNotify from "hooks/useNotify";
import { focusEditorAtSelection } from "components/slate/utils";
import { FormattingTypes } from "components/slate/types/formatting";
import { store } from "stores/store";
import { mapElementToBlock } from "db/blocks/blocks.mapping";
import { CustomElement } from "components/slate/types/elements";
import { useAuth } from "auth/AuthProvider";
import { swapIds } from "components/slate/plugins/backlink/utils";
import { DocumentType } from "thunk-core";

// the reason to create the function to handle all formatting types is that it can receive serialized parameters and handle it
const useApplyFormatting = () => {
  const notify = useNotify();
  const { currentUser } = useAuth();

  const toggleBacklink = (editor: any) => {
    Editor.normalize(editor, { force: true }); // merge sibling nodes before wrapping (e.g. after removing backlinks)

    const { selection } = editor;
    const selectedFragment = Node.fragment(editor, selection);
    // Only allow wrapping content within one text node
    if (
      selectedFragment.length === 1 &&
      //  @ts-ignore ts-migrate(2339) FIXME: Property 'children' does not exist on type 'Descen... Remove this comment to see the full error message
      selectedFragment[0].children.length === 1
    ) {
      wrapFragmentInDoubleBrackets(editor, selectedFragment[0]);
    }
  };

  const toggleTemplate = async (editor: any, payload: any) => {
    const { templatesStore } = store;
    const userId = currentUser?.uid;
    const { title, selection } = payload;

    // const savingNotificationId = notify.loading("Creating template");
    const fragment = clone(Editor.fragment(editor, selection));

    templatesStore.createNewDocumentWithContent(fragment, {
      userId,
      title,
      documentType: DocumentType.SNIPPET,
    });

    notify.success("Template created");
  };

  const applyFormatting = useCallback(
    async (editor, type: string, payload = {}) => {
      switch (type) {
        case FormattingTypes.ELEMENT_SNIPPET: {
          return await toggleTemplate(editor, payload);
        }
        case FormattingTypes.ELEMENT_LINK: {
          const { url, selection } = payload;
          HistoryEditor.withoutMerging(editor, () => {
            focusEditorAtSelection(editor, selection);
          });
          applyLinkToSelection(editor, url);
          return;
        }
        case FormattingTypes.ELEMENT_BACKLINK: {
          toggleBacklink(editor);
          return;
        }
        default:
          toggleMark(editor, { key: type });
          return;
      }
    },
    []
  );

  return applyFormatting;
};

export default useApplyFormatting;
