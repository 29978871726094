import { Editor, Element, Node } from "slate";
import { clone } from "ramda";

import { makeNodeId } from "components/slate/config/makeNodeId";

export const assignIdRecursively = (node: Node, isTopLevel: boolean) => {
  if (Element.isElement(node)) {
    node.id = makeNodeId(isTopLevel);

    node.children.forEach((x) => assignIdRecursively(x, false));
  }
};

export const withNodeId = (editor: Editor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    if (operation.type === "insert_node") {
      // clone to be able to write (read-only)
      const node = clone(operation.node);
      const path = operation.path;

      assignIdRecursively(node, path.length === 1);

      return apply({
        ...operation,
        node,
      });
    }

    if (operation.type === "split_node") {
      const properties = operation.properties;
      const path = operation.path;

      // only for elements (node with a type)
      if ("type" in properties && properties.type != null) {
        let id = makeNodeId(path.length === 1);

        return apply({
          ...operation,
          properties: {
            ...operation.properties,
            id,
          },
        });
      }
    }

    return apply(operation);
  };

  return editor;
};
