import { useEffect } from "react";

import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import OverviewPageLayout from "components/layout/OverviewPageLayout";
import UserTemplatesSection from "pages/app/TemplatesOverviewPage/UserTemplatesSection";
import Helmet from "components/Helmet";
import Button from "framework/components/form/Button";
import { useStore } from "stores/store";

import styles from "./index.module.scss";

const TemplatesOverviewPage = observer(() => {
  const pageTitle = "Templates";

  //allow body scrolling on this page
  useEffect(() => {
    document.body.removeAttribute("data-not-public");
    document.body.removeAttribute("daily-note");
    document.body.setAttribute("no-side-panel-page", "true");
  });

  const { templatesStore } = useStore();
  const { loading } = templatesStore;
  const templates = templatesStore.getDocuments();

  const actions = (
    <Button as={Link} to={"/snippets/new"} variant="primary" size="large">
      + New Template
    </Button>
  );

  if (loading) {
    return <OverviewPageLayout title={pageTitle} rightActions={actions} />;
  }

  return (
    <div className={styles.templatePageContainer}>
      <OverviewPageLayout title={pageTitle} rightActions={actions}>
        <Helmet pageTitle={pageTitle} />

        <UserTemplatesSection title="My templates" templates={templates} />
      </OverviewPageLayout>
    </div>
  );
});

export default TemplatesOverviewPage;
