import React, { forwardRef, useRef, useState, useEffect } from "react";
import { Modifier, usePopper } from "react-popper";
import cn from "classnames";
import useOnClickOutside from "hooks/useOnClickOutside";
import * as PopperJS from "@popperjs/core";

import { PortalBody } from "framework/components/Portal";
import { useSidebarContext } from "providers/SidebarProvider";
import { useSidePanelContext } from "providers/SidePanelProvider";

import styles from "components/editor/toolbar/CardMainToolbar/index.module.scss";

type Props = {
  options?: Omit<Partial<PopperJS.Options>, "modifiers"> & {
    createPopper?: typeof PopperJS.createPopper;
    modifiers?: ReadonlyArray<Modifier<any>>;
  };
};

export const useMenuPopper = (props: Props = {}) => {
  const { options = {} } = props;
  const targetRef = useRef(null);
  const popperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const { isSidebarOpened } = useSidebarContext();
  const { isSidePanelOpened } = useSidePanelContext();

  const popper = usePopper(targetRef.current, popperRef.current, {
    strategy: "fixed",
    ...options,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
      {
        name: "computeStyles",
        options: {
          // fix warning, because of css transition: transform;
          adaptive: false,
        },
      },
      ...(options.modifiers || []),
    ],
  });

  useEffect(() => {
    setTimeout(() => {
      popper.update && popper.update();
    }, 300);
  }, [isSidebarOpened, isSidePanelOpened]);

  useEffect(() => {
    setTimeout(() => popper.update && popper.update(), 300);
  }, [isSidebarOpened, isSidePanelOpened]);

  const closePopper = () => setIsVisible(false);

  const handleTargetClick = () => {
    setIsVisible((state) => !state);
  };

  const handleClickOutside = () => {
    closePopper();
  };

  useOnClickOutside([targetRef, popperRef], handleClickOutside);

  return [
    { ref: targetRef, onClick: handleTargetClick },
    { ref: popperRef, isVisible, closePopper, popper },
  ];
};

// @ts-ignore @ts-ignore ts-migrate(2339) FIXME: Property 'isVisible' does not exist on type '{ chi... Remove this comment to see the full error message
const MenuPopper = forwardRef(({ children, isVisible, popper }, ref) => {
  return (
    <PortalBody>
      <div
        ref={ref}
        style={popper.styles.popper}
        {...popper.attributes.popper}
        className={cn(styles.menuPopper, {
          [styles.visible]: isVisible,
        })}
      >
        {children}
      </div>
    </PortalBody>
  );
});

export default MenuPopper;
