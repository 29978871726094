import React from "react";

import Tag from "framework/components/Tag";
import { colors, Tag as TagDb } from "thunk-core";

type Props = {
  color: TagDb["color"];
  variant: TagDb["variant"];
  onChange: (color: TagDb["color"], variant: TagDb["variant"]) => void;
};

const ColorPicker = (props: Props) => {
  const { onChange } = props;

  return (
    <div style={{ margin: "0 auto" }}>
      <div>
        {colors.map((color) => {
          return (
            <Tag
              style={{ margin: 5, display: "inline-flex" }}
              key={color}
              color={color}
              variant="outlined"
              size="circle"
              readOnly={true}
              isSelected={props.color === color && props.variant === "outlined"}
              onClick={() => onChange(color, "outlined")}
            >
              {" "}
            </Tag>
          );
        })}
      </div>
      <div>
        {colors.map((color) => {
          return (
            <Tag
              style={{ margin: 5, display: "inline-flex" }}
              key={color}
              color={color}
              variant="filled"
              size="circle"
              readOnly={true}
              isSelected={props.color === color && props.variant === "filled"}
              onClick={() => onChange(color, "filled")}
            >
              {" "}
            </Tag>
          );
        })}
      </div>
    </div>
  );
};

export default ColorPicker;
