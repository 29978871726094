import React from "react";

import { HeaderLayout } from "components/Header";
import Link from "framework/components/Link";
import { createHrefLandingSite } from "routing/utils/redirects";

import styles from "./index.module.scss";

const PublicLayout = ({ children }: any) => {
  return (
    <div className={styles.container}>
      <HeaderLayout>
        <Link href={createHrefLandingSite("/pricing")}>Pricing</Link>
        <Link href={createHrefLandingSite("/blog")}>Blog</Link>
      </HeaderLayout>

      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>
        © Copyright {new Date().getFullYear()} Thunk Software, LLC
      </div>
    </div>
  );
};

export default PublicLayout;
