import { makeAutoObservable, runInAction } from "mobx";
import { descend, sortWith, uniq } from "ramda";

import {
  getAllReferencesBlocks,
  getReferencesBlocks,
  getSnippetsBlocks,
  getTasksBlocks,
} from "db/blocks/blocks.queries";
import { findBacklinkNodes } from "components/slate/utils";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { store } from "stores/store";
import { dateIdToDate, mapContentFromJSON } from "helpers";
import { isTaskBlock } from "db/blocks/block.utils";
import { getBlocksContent } from "db/blocks/blocks.mapping";
import { GraphLink, GraphNode, GroupedBlocks, TodosResult } from "stores/types";
import { formatDate } from "utils/dateUtils";
import { Block, DocumentType } from "thunk-core";
import { sortDocumentsByDate } from "stores/utils/sortDocumentByDate";

export default class BlockStore {
  blocks = new Map<string, Block>();

  constructor() {
    makeAutoObservable(this);
  }

  getBlocks(ids: string[]) {
    return ids.map((id) => this.blocks.get(id)).filter(Boolean);
  }

  getBlock(id: string) {
    return this.blocks.get(id);
  }

  removeBlock(id: string) {
    this.blocks.delete(id);
  }

  setBlock(id: string, block: Block) {
    this.blocks.set(id, block);
  }

  setOnlyNewBlocks(blocks: Block[]) {
    for (const block of blocks) {
      if (!this.blocks.has(block.id)) {
        this.blocks.set(block.id, block);
      }
    }
  }

  setBlocks(blocks: Block[]) {
    blocks.forEach((x) => this.blocks.set(x.id, x));
  }

  loadGraphData = async (userId: string) => {
    const { documentsManager } = store;

    const blocks = await getAllReferencesBlocks(userId);

    runInAction(() => {
      blocks.forEach((x) => this.blocks.set(x.id, x));
    });

    await documentsManager.loadDocumentsLazy(uniq(blocks.map((x) => x.pageId)));

    return blocks;
  };

  getGraphData = () => {
    const { notesStore, pagesStore } = store;

    const nodesMap = new Map<string, GraphNode>();

    for (const page of pagesStore.getDocuments()) {
      nodesMap.set(page.id, {
        id: page.id,
        documentId: page.id,
        title: page.title,
        documentType: page.type,
        isFavorite: page.isFavorite,
      });
    }

    for (const note of notesStore.getDocuments()) {
      nodesMap.set(note.dateId, {
        id: note.dateId,
        documentId: note.id,
        title: formatDate(dateIdToDate(note.dateId)),
        documentType: note.type,
        isFavorite: note.isFavorite,
      });
    }

    const blocks = Array.from(this.blocks.values());

    const linksMap = new Map<string, GraphLink[]>();

    for (const block of blocks) {
      const backlinks = findBacklinkNodes(
        mapContentFromJSON(block.contentJSON)
      );

      for (const backlink of backlinks) {
        const targetId = backlink.targetId;
        if (targetId.length === 10 && !nodesMap.has(targetId)) {
          nodesMap.set(targetId, {
            id: targetId,
            documentId: null,
            title: formatDate(dateIdToDate(targetId)),
            documentType: backlink.targetType,
            isFavorite: false,
          });
        }

        const source =
          block.pageType === DocumentType.NOTE ? block.dateId : block.pageId;
        const target = targetId;

        if (nodesMap.get(source) && nodesMap.get(target)) {
          if (target === source) continue;
          const current = linksMap.get(block.id) || [];
          linksMap.set(block.id, [
            ...current,
            {
              id: block.id,
              source,
              target,
            },
          ]);
        }
      }
    }

    return { nodesMap, linksMap };
  };

  loadReferences = async (userId: string, targetId: string) => {
    const { documentsManager } = store;

    const blocks = await getReferencesBlocks(userId, targetId);

    runInAction(() => {
      this.setBlocks(blocks);
    });

    await documentsManager.loadDocumentsLazy(uniq(blocks.map((x) => x.pageId)));
  };

  getReferences = (targetId: string): GroupedBlocks[] => {
    const { documentsManager } = store;

    const blocksWithReferences = Array.from(this.blocks.values()).filter((x) =>
      x.targets.includes(targetId)
    );

    const documentIds = uniq(blocksWithReferences.map((x) => x.pageId));
    const result: GroupedBlocks[] = [];
    for (const documentId of documentIds) {
      const document = documentsManager.getDocument(documentId);
      if (!document) continue;
      const blocks = document.blocks
        .map((x) => {
          const block = this.blocks.get(x);
          if (block && block.targets.includes(targetId)) {
            return block;
          }
          return null;
        })
        .filter(Boolean);

      result.push({ document, blocks });
    }

    const sorted = sortWith(
      [
        descend((x) =>
          x.document.dateId
            ? dateIdToDate(x.document.dateId)
            : x.document.createdAt.toDate()
        ),
      ],
      result
    );

    return sorted;
  };

  loadTasks = async (userId: string) => {
    const { documentsManager } = store;

    const blocks = await getTasksBlocks(userId);

    runInAction(() => {
      this.setBlocks(blocks);
    });

    await documentsManager.loadDocumentsLazy(uniq(blocks.map((x) => x.pageId)));
  };

  loadSnippets = async (userId: string) => {
    const blocks = await getSnippetsBlocks(userId);

    runInAction(() => {
      this.setBlocks(blocks);
    });
  };

  getGroupedTodos(): TodosResult {
    const { documentsManager } = store;

    const blocks = Array.from(this.blocks.values())
      .filter(isTaskBlock)
      .filter((x) => x.pageType !== DocumentType.SNIPPET);

    const blockByPage = new Map(blocks.map((x) => [x.pageId, x]));
    const documents = Array.from(blockByPage.values())
      .map((x) => documentsManager.getDocumentByType(x.pageType, x.pageId))
      .filter(Boolean);
    const sorted = sortDocumentsByDate(documents);

    const result: TodosResult = [];
    let wasMatch = true;

    for (const document of sorted) {
      for (const [_index, blockId] of Object.entries(document.blocks)) {
        const index = Number(_index);
        const block = this.blocks.get(blockId);
        const match = block && isTaskBlock(block);

        if (match) {
          let last = result[result.length - 1];
          const nextDocument = last?.document !== document;
          if (!last || nextDocument || !wasMatch) {
            last = {
              document,
              blocks: [],
              listIndex: nextDocument ? 0 : last.listIndex + 1,
              startIndex: index,
              endIndex: index,
              checkedCount: 0,
              content: [], // calculate later
            };
            result.push(last);
          }

          last.blocks.push(block);
          if (block.properties.checked) {
            last.checkedCount++;
          }
          last.endIndex = index;
        }

        wasMatch = match;
      }
    }

    return result;
  }

  getBlocksBacklinks(blocks: Block[]): BacklinkElement[] {
    const content = getBlocksContent(blocks);

    const backlinks = findBacklinkNodes(content) as BacklinkElement[]; // NewBacklinkElement just workaround for now

    return backlinks;
  }
}
