import React, { useCallback, useContext, useState } from "react";
import { startOfMonth } from "date-fns";

import { DueDateCalendarProps } from "components/tasks/DueDateCalendar/DueDateCalendar";
import { DueDateCalendarActionsProps } from "components/tasks/DueDateCalendarAction/DueDateCalendarActions";

type ContextValue = {
  isVisible: boolean;
  openCalendar: (props: MenuProps) => void;
  closeCalendar: () => void;
  setVisible: () => void;
};

type MenuProps = {
  targetElement: HTMLElement | null;
} & DueDateCalendarActionsProps &
  DueDateCalendarProps;

type DueDateSelectCalendarMenuContextValue = ContextValue & MenuProps;

const DueDateSelectCalendarMenuContext =
  React.createContext<DueDateSelectCalendarMenuContextValue>(null);

export const useDueDateSelectCalendarMenuContext = () => {
  return useContext(DueDateSelectCalendarMenuContext);
};

const initialValue = {
  targetElement: null,
  actions: [],
  date: new Date(),
  activeStartDate: startOfMonth(new Date()),
  onDateChange: () => {},
  onActiveDateChange: () => {},
};

export const DueDateSelectCalendarMenuProvider = ({ children }: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const [props, setProps] = useState<MenuProps>(initialValue);

  const openCalendar = useCallback((props: MenuProps) => {
    setProps(props);
    setIsVisible(true);
  }, []);

  const setVisible = useCallback(() => {
    setIsVisible(true);
  }, []);

  const closeCalendar = useCallback(() => {
    setIsVisible(false);
    setProps(initialValue);
  }, []);

  return (
    <DueDateSelectCalendarMenuContext.Provider
      value={{
        ...props,
        isVisible,
        openCalendar,
        closeCalendar,
        setVisible,
      }}
    >
      {children}
    </DueDateSelectCalendarMenuContext.Provider>
  );
};
