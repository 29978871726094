import { Auth, User } from "@firebase/auth";
import { differenceInDays } from "date-fns";

import { hasSubscriptions } from "db/stripe";
import { TrialPeriodDays } from "auth/constants";
import { AuthAccessTypes, AuthClaims, SpecialUsers } from "auth/types";

export const getSpecialUser = (
  pathname: string,
  currentUser: User | null
): SpecialUsers | null => {
  const firstLocationChunk = pathname.split("/")[1];
  const specialUser =
    currentUser && firstLocationChunk === SpecialUsers.ContentManger.toString()
      ? SpecialUsers.ContentManger
      : null;

  return specialUser;
};

// this function needs to get user id including special user id in places where we don't have access to react auth context
export const getUserId = (pathname: string, auth: Auth): string | null => {
  const currentUser = auth.currentUser;
  const specialUser = getSpecialUser(pathname, currentUser);
  const userId =
    specialUser != null ? specialUser.toString() : currentUser?.uid;

  return userId ?? null;
};

const isAdmin = (claims: AuthClaims): boolean => {
  return claims.admin || false;
};

const isEarlyAccessUser = (claims: AuthClaims): boolean => {
  return claims.createdByInvitation || false;
};

const isSubscriber = (claims: AuthClaims): boolean => {
  return claims.stripeRole === "subscriber";
};

// check order is important here
export const getAuthAccessType = async (
  userId: string,
  user: User,
  claims: AuthClaims
): Promise<AuthAccessTypes> => {
  if (isAdmin(claims)) {
    return AuthAccessTypes.admin;
  }

  if (isEarlyAccessUser(claims)) {
    return AuthAccessTypes.earlyAccess;
  }

  if (isSubscriber(claims)) {
    return AuthAccessTypes.subscription;
  }

  if (trialDaysAvailable(user, claims) > 0) {
    return AuthAccessTypes.trial;
  }

  const _hasSubscriptions = await hasSubscriptions(userId);
  if (_hasSubscriptions) {
    return AuthAccessTypes.subscriptionExpired;
  }

  return AuthAccessTypes.trialExpired;
};

export const trialDaysAvailable = (user: User, claims: AuthClaims) => {
  const createdAt = claims.createdAt
    ? new Date(claims.createdAt)
    : new Date(user.metadata.creationTime as any);

  const now = new Date();

  const difference = differenceInDays(now, createdAt);
  const daysAvailable = TrialPeriodDays - difference;

  return daysAvailable;
};
