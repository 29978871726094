import { formatDifferenceInDays } from "utils/dateUtils";
import { dateIdToDate } from "helpers";
import { Document, DocumentType } from "thunk-core";

export const getDocumentTitle = (document: Document) => {
  if (!document) {
    // in case document still not loaded
    return "";
  }

  if (document.type === DocumentType.NOTE) {
    return document.dateId
      ? formatDifferenceInDays(dateIdToDate(document.dateId))
      : "[no date]";
  }

  return document.title == null ? "[no title]" : document.title;
};
