import { Editor } from "slate";

import makeDeleteBackward from "./makeDeleteBackward";
import makeInsertBreak from "./makeInsertBreak";
import makeInsertFragment from "./makeInsertFragment";
import {
  isListItemElement,
  isTodoListItemElement,
} from "components/slate/plugins/list/utils";
import makeNormalizeNode from "./makeNormalizeNode";

const withList = (editor: Editor) => {
  editor.insertBreak = makeInsertBreak(editor);
  editor.deleteBackward = makeDeleteBackward(editor);
  editor.insertFragment = makeInsertFragment(editor);
  editor.normalizeNode = makeNormalizeNode(editor);

  const { isNestingElement, isFoldingElement, isProgressElement } = editor;

  editor.isNestingElement = (element) => {
    return isListItemElement(element) || isNestingElement(element);
  };

  editor.isFoldingElement = (element) => {
    return isListItemElement(element) || isFoldingElement(element);
  };

  editor.isProgressElement = (element) => {
    return isTodoListItemElement(element) || isProgressElement(element);
  };

  return editor;
};

export default withList;
