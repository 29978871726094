import React from "react";
import { useFocused, useSelected } from "slate-react";
import cn from "classnames";

import { ElementProps } from "components/slate/types";
import { HorizontalRuleElement } from "components/slate/plugins/horizontalRule/types";

import styles from "./HorizontalRule.module.scss";

export const HorizontalRuleComponent = (
  props: ElementProps & { element: HorizontalRuleElement }
) => {
  const { attributes, children } = props;

  const selected = useSelected();
  const focused = useFocused();

  return (
    <div className={styles.container} {...attributes}>
      {children}

      <hr
        contentEditable={false}
        className={cn(styles.hr, {
          [styles.highlighted]: selected && focused,
        })}
      />
    </div>
  );
};
