import { v4 as uuidv4 } from "uuid";

export const swapAllIds = (obj: any) => {
  if (!obj.children) {
    return obj;
  }
  if (obj.children.some((c: any) => c.children)) {
    obj.children = obj.children.map((child: any) => swapAllIds(child));
  }
  if (obj.id) {
    obj.id = uuidv4();
  }
  return obj;
};
