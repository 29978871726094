import React from "react";

import FormattingToolbar from "components/slate/plugins/menus/formatting/FormattingMenu/FormattingMenuToolbar/FormattingToolbar";
import LinksToolbar from "components/slate/plugins/menus/formatting/FormattingMenu/FormattingMenuToolbar/LinksToolbar";
import TemplatesToolbar from "components/slate/plugins/menus/formatting/FormattingMenu/FormattingMenuToolbar/TemplatesToolbar";
import { formattingMenuModes } from "components/slate/plugins/menus/formatting/constants";
import { ToolbarMenuContainer } from "components/slate/plugins/menus/formatting/_shared/ToolbarMenuContainer";

const FormattingMenuToolbar = (props: any) => {
  const { mode } = props;

  return (
    <ToolbarMenuContainer {...props}>
      {mode === formattingMenuModes.formatting && (
        <FormattingToolbar {...props} />
      )}
      {mode === formattingMenuModes.links && <LinksToolbar {...props} />}
      {mode === formattingMenuModes.snippets && <TemplatesToolbar {...props} />}
    </ToolbarMenuContainer>
  );
};

export default FormattingMenuToolbar;
