import React, { Fragment, memo, useEffect, useMemo, useState } from "react";
import { Editable, Slate } from "slate-react";
import { createEditor, Descendant } from "slate";
import cn from "classnames";

import { EditorType, ElementProps } from "components/slate/types";
import usePlugins from "components/slate/hooks/usePlugins";
import useEditor from "components/slate/hooks/useEditor";
import useHandlers from "components/slate/hooks/useHandlers";
import { useWrappedRenderElement } from "components/slate/hooks/useRenderElement";
import useRenderLeaf from "components/slate/hooks/useRenderLeaf";
import SlashMenu from "components/slate/plugins/menus/slashMenu/components/SlashMenu";
import { StaticPropsProvider } from "components/slate/hooks/useStaticProps";
import AtMenu from "components/slate/plugins/menus/atMenu/components/AtMenu";
import HashMenu from "components/slate/plugins/menus/hashMenu/components/HashMenu";
import { FormattingMenu } from "components/slate/plugins/menus/formatting/FormattingMenu";
import DndPluginContext from "components/slate/slate-extended/dnd/DndPluginContext";
import SlateExtended from "components/slate/slate-extended/SlateExtended";
import DragOverlayContent from "components/slate/wrapper/DragOverlayContent";
import {
  ElementContextMenu,
  ElementContextMenuProvider,
} from "components/slate/plugins/menus/formatting/ElementContextMenu";
import useDecorate from "components/slate/hooks/useDecorate";
import usePlateEditorPlugins from "components/slate/hooks/usePlateEditorPlugins";
import {
  ImageUploaderModal,
  ImageUploaderProvider,
} from "components/slate/plugins/file/components/ImageUploader";
import {
  ImagesViewer,
  ImagesViewerProvider,
} from "components/slate/plugins/file/components/ImagesViewer";
import { ComponentsMenuProvider } from "components/slate/hooks/useComponentsMenuContext";
import ComponentMenu from "components/slate/plugins/menus/componentMenu/components/ComponentMenu";
import BacklinkMenu from "components/slate/plugins/menus/backlinkMenu/components/BacklinkMenu";
import DelayedRender from "components/slate/editors/DelayedRender";
import { indentationWidth } from "components/slate/wrapper/constants";
import { EmojiComponent } from "components/slate/plugins/emoji/components/EmojiComponent";
import { StreamEditorPortal } from "components/slate/plugins/stream/components/StreamScreen";
import { getReferencesDiffs } from "components/slate/plugins/backlink/db";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { DocumentType } from "thunk-core";
import { SyncSlateValue } from "components/slate/editors/SyncSlateValue";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { useEditorsMapEffects } from "components/slate/state/EditorsStore";
import { useStoreValue } from "state/utils";
import { setSelectedBacklinks } from "components/slate/plugins/backlink/utils";

import "components/slate/editors/index.scss";

type SlateEditorProps = {
  slateId?: string;
  editorType: EditorType;
  loading?: boolean;
  readOnly?: boolean;
  onChange?: (value: Descendant[]) => void;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  onBacklinksChange?: (referencesDiff: any) => void;
  documentId?: string;
  documentType?: DocumentType;
  userId: string;
  isSharing?: boolean;
  isSnippet?: boolean;
  isHelpDocs?: boolean;
  placeholder?: string;
  value?: Descendant[];
  pageTitle?: string;
  renderElement?: (props: ElementProps) => JSX.Element;
  onBacklinkClick?: (element: BacklinkElement) => void;
};

const SlateDocumentEditor = memo((props: SlateEditorProps) => {
  const {
    slateId,
    editorType,
    isSharing,
    isSnippet,
    isHelpDocs,
    pageTitle,
    documentId,
    documentType,
    onChange,
    onBacklinksChange,
    readOnly,
    userId,
    onFocus,
    onBlur,
    onBacklinkClick,
  } = props;

  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);
  const terminalSelection = useStoreValue(
    TerminalStore.terminalSelection(slateId)
  );

  const isMainEditor = editorType === EditorType.MainEditor;

  const content = props.value;

  const [, forceRerender] = useState(0);

  const {
    plugins,
    slashMenuPlugin,
    atMenuPlugin,
    hashMenuPlugin,
    backlinkMenuPlugin,
    formattingMenuPlugin,
    componentsMenuPlugin,
    // emojiPlugin,
  } = usePlugins({
    isMainEditor,
    userId,
    slateId: slateId,
  });

  const editor = useEditor(createEditor, plugins);
  editor.slateId = slateId;
  editor.isTerminalEditor = !isHelpDocs && !isSharing;
  const handlers = useHandlers(editor, [
    ...plugins,
    {
      handlers: {
        onKeyDown: () => () => {
          forceRerender((x) => x + 1); // after dnd ends then ReactEditor.focus call, to continue typing
        },
      },
    },
  ]);

  const renderElement = useWrappedRenderElement(editor, plugins);
  const renderLeaf = useRenderLeaf(editor, plugins);
  const decorate = useDecorate(editor, plugins);

  const handleChange = (content: Descendant[]) => {
    if (readOnly) {
      return;
    }

    if (onBacklinksChange) {
      const referencesDiff = getReferencesDiffs(value, content);
      onBacklinksChange(referencesDiff);
    }

    if (value !== content && onChange) {
      onChange(content);
    }
    setValue(content);

    plugins.filter((x) => x.onChange).map((x) => x.onChange(editor)(content));
  };

  const [isDragOver, setIsDragOver] = useState(false);
  const [dragCount, setDragCount] = useState(0);

  const onDrag: React.DragEventHandler = (e) => {
    if (readOnly) {
      return;
    }

    setDragCount(dragCount + 1);
    if (!isDragOver) {
      setIsDragOver(true);
    }
  };

  const onDragEnd: React.DragEventHandler = (e) => {
    if (readOnly) {
      return;
    }

    const newCount = Math.max(dragCount - 1, 0);
    setDragCount(newCount);
    if (newCount === 0) {
      setIsDragOver(false);
    }
  };

  const [dragging, setDragging] = useState(false);
  const [value, setValue] = useState(content);

  usePlateEditorPlugins(editor);

  useEditorsMapEffects(slateId, editor);

  useEffect(() => {
    plugins.filter((x) => x.onMount).map((x) => x.onMount(editor));

    return () => {
      plugins.filter((x) => x.onUnmount).map((x) => x.onUnmount(editor));
    };
  }, []);

  useEffect(() => {
    if (!isTerminalActive && isMainEditor) {
      setTimeout(() => {
        setSelectedBacklinks(editor);
      }, 100);
    }
  }, [isTerminalActive, isMainEditor]);

  const selectedIds = useMemo(() => {
    return isTerminalActive ? terminalSelection : new Set<string>();
  }, [isTerminalActive, terminalSelection]);

  return (
    <DelayedRender isDelayed={value.length > 100}>
      <Slate editor={editor} value={value} onChange={handleChange}>
        <ElementContextMenuProvider editor={editor}>
          <ComponentsMenuProvider
            value={{
              currentElement: componentsMenuPlugin.state.currentElement,
              openComponentsMenu: componentsMenuPlugin.state.openComponentsMenu,
              closeComponentsMenu:
                componentsMenuPlugin.state.closeComponentsMenu,
            }}
          >
            <StaticPropsProvider
              value={{
                readOnly,
                slateId: slateId,
                editorType,
                documentId,
                isSharing,
                isSnippet,
                isMainEditor,
                isHelpDocs,
                noSortable: false,
                pageTitle,
                documentType,
                onBacklinkClick,
              }}
            >
              <ImageUploaderProvider editor={editor}>
                <ImagesViewerProvider content={value as any}>
                  <SlateExtended>
                    <DndPluginContext
                      onDragStart={() => {
                        setDragging(true);
                      }}
                      onDragEnd={() => {
                        setDragging(false);
                        forceRerender((x) => x + 1); // after dnd ends to provide the right DragOverlay drop animation
                      }}
                      onDragCancel={() => {
                        setDragging(false);
                      }}
                      editor={editor}
                      renderDragOverlay={(props) => {
                        return <DragOverlayContent {...props} />;
                      }}
                      indentationWidth={indentationWidth}
                      selectedIds={selectedIds}
                    >
                      <SyncSlateValue
                        value={content}
                        setValue={setValue}
                        dragging={dragging}
                      >
                        <StreamEditorPortal disabled={isSnippet}>
                          <Editable
                            data-slate-id={isHelpDocs ? undefined : slateId}
                            data-slate-horizontal-index={isMainEditor ? 0 : 1}
                            data-slate-terminal={!isHelpDocs && !isSharing}
                            className={cn("slate-editor", {
                              readOnly,
                              snippet: isSnippet,
                              dragActive: isDragOver && !readOnly,
                              mainEditorTrue: isMainEditor,
                            })}
                            readOnly={readOnly || isTerminalActive}
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            decorate={decorate}
                            {...handlers}
                            onFocus={(e) => {
                              forceRerender((x) => x + 1); // make useFocused work for Placeholder component in wrapper
                              onFocus && onFocus(e);
                              handlers.onFocus && handlers.onFocus(e);
                            }}
                            onBlur={(e) => {
                              onBlur && onBlur(e);
                              handlers.onBlur && handlers.onBlur(e);
                            }}
                            onDragEnter={(e) => {
                              handlers.onDragEnter && handlers.onDragEnter(e);
                              onDrag(e);
                            }}
                            onDragLeave={(e) => {
                              handlers.onDragLeave && handlers.onDragLeave(e);
                              onDragEnd(e);
                            }}
                            onDrop={(e) => {
                              handlers.onDrop && handlers.onDrop(e);
                              onDragEnd(e);
                            }}
                          />
                        </StreamEditorPortal>
                      </SyncSlateValue>

                      {!readOnly && (
                        <Fragment>
                          <ComponentMenu {...componentsMenuPlugin.state} />
                          <SlashMenu {...slashMenuPlugin.state} />
                          <AtMenu {...atMenuPlugin.state} />
                          <HashMenu {...hashMenuPlugin.state} />
                          <BacklinkMenu {...backlinkMenuPlugin.state} />
                          <FormattingMenu {...formattingMenuPlugin.state} />
                          {/* <EmojiComponent {...emojiPlugin.state} /> */}
                          <ElementContextMenu />
                          <ImageUploaderModal />
                        </Fragment>
                      )}
                    </DndPluginContext>
                  </SlateExtended>
                  <ImagesViewer />
                </ImagesViewerProvider>
              </ImageUploaderProvider>
            </StaticPropsProvider>
          </ComponentsMenuProvider>
        </ElementContextMenuProvider>
      </Slate>
    </DelayedRender>
  );
});

export default SlateDocumentEditor;
