const idsFilter = <T extends string>(
  array: T[],
  ids: string[],
  apply: boolean = true
) => {
  if (!apply) {
    return array;
  }

  return array.some((id) => ids.some((x) => x === id));
};

export default idsFilter;
