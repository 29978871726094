import React, { useEffect, useRef, useState } from "react";

import { HashMenuContent } from "components/slate/plugins/menus/hashMenu/components/HashMenu";
import useAppPopper from "framework/components/poppers/useAppPopper";
import AppPopper, {
  PopperDivider,
  PopperMenuItem,
} from "framework/components/poppers/AppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import { useStore } from "stores/store";
import { HashMenuItem } from "components/slate/plugins/menus/hashMenu/items/utils";
import { makeTag } from "db/tags/tags.mapping";
import { useCurrentUserId } from "db/currentUser";
import TextInput from "framework/components/form/TextInput";
import { DocumentType } from "thunk-core";

const AddTagMenu = (props: {
  documentId: string;
  documentType: DocumentType;
  children: React.FC<{ ref: (ref: HTMLElement) => void }>;
}) => {
  const { documentId, documentType, children } = props;

  const userId = useCurrentUserId();

  const inputRef = useRef<HTMLElement | null>(null);
  const { pagesStore, tagsStore } = useStore();

  const [search, setSearch] = useState<string>("");
  const popperProps = useAppPopper();
  useClickPopper(popperProps);

  const handleSelect = (e: Event, item: HashMenuItem) => {
    const action = item.applyInCard();

    // if (action.type === "insertTag") {
    //   pagesStore.addTag(documentId, action.payload.tag.id);
    // }
    //
    // if (action.type === "insertNewTag") {
    //   const tag = makeTag({ userId, title: search });
    //   tagsStore.createNewTag(tag);
    //
    //   pagesStore.addTag(documentId, tag.id);
    // }

    popperProps.closePopper();
  };

  useEffect(() => {
    if (popperProps.isOpened) {
      setSearch("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [popperProps.isOpened]);

  return (
    <AppPopper
      {...popperProps}
      content={
        <div style={{ paddingBottom: 20 }}>
          <PopperMenuItem>
            <TextInput
              placeholder="Search tag..."
              style={{ minWidth: 270 }}
              inputRef={inputRef}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </PopperMenuItem>
          <PopperDivider />
          <HashMenuContent
            documentId={documentId}
            documentType={documentType}
            isOpened={popperProps.isOpened && popperProps.targetElement != null}
            search={search}
            closeMenu={popperProps.closePopper}
            onSelect={handleSelect}
          />
        </div>
      }
    >
      {children({ ref: popperProps.setTargetElement })}
    </AppPopper>
  );
};

export default AddTagMenu;
