import React from "react";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import cn from "classnames";

import styles from "./index.module.scss";

export type TextareaProps = TextareaAutosizeProps &
  React.RefAttributes<HTMLTextAreaElement>;

const Textarea = (props: TextareaProps) => {
  return (
    <TextareaAutosize
      {...props}
      className={cn(styles.textarea, props.className)}
    />
  );
};

export default Textarea;
