import { Descendant } from "slate";

import { findBacklinkNodesInEditor } from "components/slate/utils";
import { BacklinkElement } from "components/slate/plugins/backlink/types";

const diffLinks = (arrA: BacklinkElement[], arrB: BacklinkElement[]) =>
  arrA.filter((x: any) => !arrB.some((y) => x.id === y.id));

export const getReferencesDiffs = (
  prevContent: Descendant[],
  newContent: Descendant[]
) => {
  const newStateLinks = findBacklinkNodesInEditor(newContent);
  const oldStateLinks = findBacklinkNodesInEditor(prevContent);
  const added = diffLinks(newStateLinks, oldStateLinks);
  const removed = diffLinks(oldStateLinks, newStateLinks);

  return { added, removed, newStateLinks, oldStateLinks };
};
