import { useEffect, useState } from "react";

import { useStore } from "stores/store";

export const useAppInitialize = (userId: string) => {
  const {
    pagesStore,
    templatesStore,
    notesStore,
    blocksStore,
    tagsStore,
  } = useStore();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      notesStore.loadAllDocuments(userId); // without await

      await Promise.all([
        pagesStore.loadAllDocuments(userId),
        templatesStore.loadAllDocuments(userId),
        blocksStore.loadSnippets(userId),
        tagsStore.loadAllTags(userId),
      ]);

      await blocksStore.loadTasks(userId); // load tasks after so tasks can use cached documents

      setLoading(false);
    };

    if (userId) {
      load();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return loading;
};
