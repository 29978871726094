import React, { memo } from "react";
import { NavLink } from "react-router-dom";
import { RemoveTagIcon } from "thunk-icons";

import styles from "./index.module.scss";

type Props<T> = {
  item: T;
  title: string;
  href: string;
  onRemove?: (e: React.SyntheticEvent, document: T) => void;
};

const AppSidebarSectionItem = memo(
  <T,>({ item, title, href, onRemove }: Props<T>) => {
    return (
      <NavLink
        isActive={(_: any, { pathname, search }: any) => {
          const currentHref = pathname + search;
          return currentHref === href;
        }}
        className={styles.favoritesItem}
        activeClassName={styles.active}
        to={href}
      >
        <div className={styles.itemTitle}>{title}</div>
        {onRemove && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemove(e, item);
            }}
          >
            <RemoveTagIcon className={styles.removeIcon} size={15} />
          </button>
        )}
      </NavLink>
    );
  }
);

export default AppSidebarSectionItem;
