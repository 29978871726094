import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import ReactSwitch from "react-switch";

import { useThemeContext } from "providers/ThemeProvider";
import { getStylesProps } from "./styles";

//  @ts-ignore ts-migrate(2307) FIXME: Cannot find module './index.module.scss' or its co... Remove this comment to see the full error message
import styles from "./index.module.scss";

const Switch = ({
  id,
  checked,
  disabled,
  color,
  editable,
  onChange,
  onClick,
}: any) => {
  //  @ts-ignore ts-migrate(2339) FIXME: Property 'theme' does not exist on type 'unknown'.
  const { theme } = useThemeContext();

  const stylesProps = getStylesProps(theme, color);

  return (
    <div className={styles.container} onClick={onClick}>
      <ReactSwitch
        id={id}
        disabled={disabled || !editable}
        checked={checked}
        onChange={(...args) => onChange && onChange(...args)}
        handleDiameter={26}
        onColor={stylesProps.onColor}
        offColor={stylesProps.offColor}
        onHandleColor={stylesProps.onHandleColor}
        offHandleColor={stylesProps.offHandleColor}
        activeBoxShadow={stylesProps.activeBoxShadow}
        height={30}
        width={48}
        uncheckedIcon={null}
        checkedIcon={null}
        className={cn({
          [styles.notEditable]: !editable,
        })}
      />
    </div>
  );
};

Switch.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(["primary", "purple"]),
};

Switch.defaultProps = {
  editable: true,
  color: "primary",
};

export default Switch;
