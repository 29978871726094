import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";

import { Card, CardTitle } from "framework/components/Card";
import TextInput from "framework/components/form/TextInput";
import Button from "framework/components/form/Button";
import { EMAIL_REGEX } from "helpers";
import { useUserData } from "hooks/useUserData";
import useNotifications from "hooks/useNotifications";
import { getErrorInfo } from "./errors";
import { defaultAvailableInvitesCount } from "constants/invites";
import invitationImage from "images/invitation.png";
import { InvitationsIcon } from "thunk-icons";

import styles from "./index.module.scss";
import CardPageLayout from "components/layout/CardPageLayout";
import AppResult from "components/results/AppResult";
import { useCurrentUserId } from "db/currentUser";
import { env } from "thunk-env";

const defaultValues = {
  email: "",
};

const InvitesPage = () => {
  const userId = useCurrentUserId();
  const [user, loading, error] = useUserData(userId);
  const [submitting, setSubmitting] = useState(false);

  //allow body scrolling on this page
  useEffect(() => {
    document.body.setAttribute("no-side-panel-page", "true");
  });

  const { t } = useTranslation();
  const { addNotification } = useNotifications();

  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues,
  });

  const sendInviteReq = useFetch("/invitations/sendUserInvitation", {
    // @ts-ignore
    cachePolicy: "no-cache",
  });

  const onCancel = () => {
    reset();
  };

  const handleError = (error: any) => {
    const errorInfo = getErrorInfo(error);

    setSubmitting(false);
    addNotification({
      duration: 5000,
      type: "error",
      text: t(errorInfo.message, {
        email: env.REACT_APP_SUPPORT_EMAIL,
      }),
    });
  };

  const onSubmit = async (values: any) => {
    const { email } = values;
    setSubmitting(true);

    try {
      const response = await sendInviteReq.post(``, { email });

      if (!sendInviteReq.response.ok) {
        handleError(response || { code: "thunk/connection-error" });
        return;
      }

      setSubmitting(false);
      addNotification({
        duration: 4000,
        type: "success",
        text: `Invitation sent: ${email}`,
      });
      reset();
    } catch (error) {
      handleError(error);
    }
  };

  const pageTitle = "Invite a Friend to Thunk";

  if (loading) {
    return (
      <CardPageLayout>
        <Card
          className={styles.container}
          title={<CardTitle title={pageTitle} />}
        />
      </CardPageLayout>
    );
  }

  if (error) {
    console.error(error);
    return (
      <CardPageLayout>
        <Card
          className={styles.container}
          title={<CardTitle title={pageTitle} />}
        >
          <AppResult resultType="fetching" isCenteredContent={true} />
        </Card>
      </CardPageLayout>
    );
  }

  const availableInvitesCount =
    //  @ts-ignore ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    user.availableInvitesCount ?? defaultAvailableInvitesCount;

  return (
    <CardPageLayout>
      <Card
        className={styles.container}
        title={<CardTitle title={pageTitle} />}
      >
        <div className={styles.content}>
          <img
            className={styles.image}
            src={invitationImage}
            alt="invitation"
          />
          {/*<Alert status="gift">*/}
          {/*  You have <strong>{availableInvitesCount}</strong> invites left*/}
          {/*</Alert>*/}
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className={styles.form}
          >
            <TextInput
              type="email"
              error={errors.email?.message}
              name="email"
              placeholder="Your friend's email"
              inputRef={register({
                required: "Email address required",
                pattern: {
                  value: EMAIL_REGEX,
                  message: "Invalid email address",
                },
              })}
            />
            <div className={styles.actions}>
              <span className={styles.hint}>
                {/*Your guests will skip the waitlist automatically.*/}
              </span>
              <div className={styles.buttons}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                  loading={submitting}
                  disabled={Boolean(errors.email?.message) || submitting}
                  variant="secondary"
                  type="submit"
                  icon={InvitationsIcon}
                >
                  Invite
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Card>
    </CardPageLayout>
  );
};

export default InvitesPage;
