import React from "react";
import {
  createCodeBlockPlugin,
  decorateCodeLine,
} from "@udecode/plate-code-block";
import {
  CodeBlockElement,
  CodeLineElement,
} from "@udecode/plate-ui-code-block";
import { useSlate } from "slate-react";

import {
  EditorHandler,
  ElementProps,
  UseSlatePlugin,
} from "components/slate/types";
import {
  isCodeBlockElement,
  isCodeLineElement,
} from "components/slate/plugins/codeBlock/utils";
import { CodeBlockType } from "components/slate/plugins/codeBlock/types";
import { withCodeBlock } from "components/slate/plugins/codeBlock/withCodeBlock";

const plugin = createCodeBlockPlugin();

const useCodeBlockPlugin: UseSlatePlugin = () => {
  return {
    withOverrides: (editor) => {
      return withCodeBlock(plugin.withOverrides(editor as any, {} as any));
    },
    handlers: {
      onKeyDown: plugin.handlers.onKeyDown as EditorHandler,
    },
    renderElement: (props) => {
      if (
        isCodeBlockElement(props.element) ||
        isCodeLineElement(props.element)
      ) {
        return <RenderCodeBlock {...props} />;
      }

      return null;
    },
    decorate: (editor) => (entry) =>
      decorateCodeLine(editor as any, { type: CodeBlockType } as any)(entry),
  };
};

export default useCodeBlockPlugin;

const RenderCodeBlock = (props: ElementProps) => {
  const editor = useSlate() as any;

  if (isCodeBlockElement(props.element)) {
    return (
      <CodeBlockElement
        {...props}
        attributes={props.attributes!}
        element={props.element}
        editor={editor}
        plugins={editor.plugins}
      />
    );
  }

  if (isCodeLineElement(props.element)) {
    return (
      <CodeLineElement
        {...props}
        attributes={props.attributes!}
        element={props.element}
        editor={editor}
        plugins={editor.plugins}
      />
    );
  }

  return null;
};
