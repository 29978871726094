import React from "react";
import { Link } from "react-router-dom";

import thumbnailPlaceholderImage from "images/thumbnail_placeholder.svg";
import { useThemeContext } from "providers/ThemeProvider";

import styles from "./index.module.scss";
import { Document } from "thunk-core";

type TemplatePreviewCardProps = {
  template: Document;
  path: string;
};

export const TemplatePreviewCard = ({
  template,
  path,
}: TemplatePreviewCardProps) => {
  const { darkMode } = useThemeContext();

  const imageUrl = darkMode
    ? template.imageUrlDark || template.imageUrl
    : template.imageUrl;

  return (
    <div className={styles.container}>
      <div className={styles.thumbnail}>
        <Link className={styles.titleLink} to={path}>
          {template.title}
        </Link>
        {imageUrl ? (
          <img
            className={styles.thumbnailImage}
            src={imageUrl}
            alt={template.title}
          />
        ) : (
          <img
            className={styles.thumbnailImagePlaceholder}
            alt={template.title}
            src={thumbnailPlaceholderImage}
          />
        )}
      </div>
    </div>
  );
};
