import create from "zustand";

import { formattingMenuModes } from "components/slate/plugins/menus/formatting/constants";

export type FormattingMenuState = {
  mode: string;
  setMode: (mode: string) => void;
};

const createState = () =>
  create<FormattingMenuState>((set) => ({
    mode: formattingMenuModes.formatting,
    setMode: (mode) => set(() => ({ mode })),
  }));

export default createState;
