import React from "react";
import cn from "classnames";

import {
  AtMenuItem,
  highlightMatch,
  highlightParse,
} from "components/slate/plugins/menus/atMenu/items/utils";

import styles from "./index.module.scss";

type SectionMenuListItemProps = {
  search: string;
  item: AtMenuItem;
  isActive: boolean;
  onSelect: (e: Event, item: any) => void;
};

const SectionMenuListItem = ({
  search,
  item,
  isActive,
  onSelect,
}: SectionMenuListItemProps) => {
  const Icon = item.icon;

  const isVertical = item.layout === "vertical";
  return (
    <button
      className={cn(styles.menuItem, {
        [styles.focused]: isActive,
        [styles.vertical]: isVertical,
        [styles.createNoteItem]: item.createAction,
      })}
      onMouseDown={(e) => {
        e.preventDefault();
        const event = e as any; // rewrite this
        onSelect(event, item);
      }}
    >
      {!isVertical && (
        <>
          {item.icon && <Icon className={styles.itemIcon} />}
          {item.createAction && (
            <span className={styles.createText}>Create: </span>
          )}
          {renderText(item, search)}
          <div className={styles.hint}>{item.hint}</div>
        </>
      )}
      {isVertical && (
        <>
          <div className={styles.hint}>{item.hint}</div>
          <div className={styles.textResult}>
            {item.icon && <Icon className={styles.itemIcon} />}
            {item.createAction && (
              <span className={styles.createText}>Create: &nbsp;</span>
            )}
            {item.createAction ? (
              <div
                className={cn(styles.text, {
                  [styles.plainText]: item.nonLink,
                })}
              >
                <span>{item.text}</span>
              </div>
            ) : (
              renderText(item, search)
            )}
          </div>
        </>
      )}
    </button>
  );
};

export default SectionMenuListItem;

const renderText = (item: AtMenuItem, search: string) => {
  const parsed = highlightParse(item.text, highlightMatch(item.text, search));

  return (
    <div className={cn(styles.text, { [styles.timestamp]: item.nonLink })}>
      {parsed.map((chunk) => {
        if (chunk.highlight) {
          return <span className={styles.mark}>{chunk.text}</span>;
        } else {
          return <span>{chunk.text}</span>;
        }
      })}
    </div>
  );
};
