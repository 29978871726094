import React from "react";
import { createEditor, Descendant } from "slate";
import { Editable, Slate } from "slate-react";

import useRenderLeaf from "components/slate/hooks/useRenderLeaf";
import useEditor from "components/slate/hooks/useEditor";
import SlateExtended from "components/slate/slate-extended/SlateExtended";
import usePlateEditorPlugins from "components/slate/hooks/usePlateEditorPlugins";
import useDecorate from "components/slate/hooks/useDecorate";
import { ElementContextMenuProvider } from "components/slate/plugins/menus/formatting/ElementContextMenu";
import { useBlockRenderElement } from "components/slate/hooks/useRenderElement";
import useSimplePlugins from "components/slate/hooks/useSimplePlugins";

type Props = {
  value?: Descendant[];
};

const handleChange = () => {};

const SimpleReadOnlyEditor = (props: Props) => {
  const { value } = props;

  const { plugins } = useSimplePlugins({
    isMainEditor: false,
  });
  const editor = useEditor(createEditor, plugins);

  const renderElement = useBlockRenderElement(editor, plugins);
  const renderLeaf = useRenderLeaf(editor, plugins);
  const decorate = useDecorate(editor, plugins);

  usePlateEditorPlugins(editor);

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <ElementContextMenuProvider editor={editor}>
        <SlateExtended>
          <Editable
            className="slate-editor"
            readOnly={true}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            decorate={decorate}
          />
        </SlateExtended>
      </ElementContextMenuProvider>
    </Slate>
  );
};

export default SimpleReadOnlyEditor;
