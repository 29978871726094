import React, { useEffect, useState } from "react";

import { useLoadable } from "hooks/useLoadable";
import { getMaintenanceVariable } from "db/variables/variables.queries";
import { MaintenanceVariable } from "db/variables/variables.types";
import AppResult from "components/results/AppResult";
import PublicLayout from "layout/PublicLayout";

type Props = {
  children: React.ReactNode;
};

const MaintenanceGateway = ({ children }: Props) => {
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    // @ts-ignore
    window.__DISABLE_MAINTENANCE_MESSAGE__ = setIsHidden;
  }, []);

  const [{ loading, error }, loadable] = useLoadable();
  const [variable, setVariable] = useState<MaintenanceVariable | null>();

  useEffect(() => {
    const fetchMaintenanceVariable = async () => {
      loadable.start();
      setVariable(null);

      try {
        const variable = await getMaintenanceVariable();
        setVariable(variable);

        loadable.success();
      } catch (error) {
        console.error(error);
        loadable.error(error);
      }
    };

    fetchMaintenanceVariable();
  }, []);

  if (
    !isHidden &&
    !loading &&
    !error &&
    variable != null &&
    variable.pending === true
  ) {
    // render null only if we get result without error and pending is true
    // excluding admins
    return (
      <PublicLayout>
        <div style={{ paddingTop: "20vh" }}>
          <AppResult resultType="maintenance" isCenteredContent={true} />
        </div>
      </PublicLayout>
    );
  }

  return <>{children}</>;
};

export default MaintenanceGateway;
