import React from "react";
import {
  SidepanelCollapseIcon,
  SidepanelCollapseFilledIcon,
} from "thunk-icons";

import { useSidePanelContext } from "providers/SidePanelProvider";
import SimpleTooltip from "components/SimpleTooltip";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

import Button from "framework/components/form/Button";

import styles from "components/layout/SidePanelToggle/index.module.scss";

interface Props {
  className?: string;
}

const SidePanelToggle = ({ className }: Props) => {
  const { isWindows, isMobileScreen } = useDeviceDetector();
  const { toggleSidePanel } = useSidePanelContext();

  const metaKey = isWindows ? "Ctrl" : "⌘";

  const iconSize = 28;

  return (
    <SimpleTooltip
      className={className}
      content={`Toggle Side Panel (${metaKey}+')`}
      placement="bottom"
      delay={[400, 0]}
      hideTooltip={isMobileScreen}
    >
      <Button
        size="symbol"
        variant="circularElectronHeader"
        btnClass={styles.sidePanelToggle}
        onClick={() => toggleSidePanel((state) => !state)}
      >
        <div className={styles.iconContainer}>
          <SidepanelCollapseFilledIcon
            className={styles.icon}
            size={iconSize}
          />
          <SidepanelCollapseIcon
            className={styles.activeIcon}
            size={iconSize}
          />
        </div>
      </Button>
    </SimpleTooltip>
  );
};

export default SidePanelToggle;
