import crawl from "tree-crawl";
import { clone } from "ramda";
import { Descendant } from "slate";
import {
  ChevronLeftIcon,
  BackArrowIcon,
  CopyLinkIcon,
  BulletedListIcon,
  CodeblockIcon,
  DeleteNoteIcon,
  DividerIcon,
  FigmaIcon,
  CodepenIcon,
  HeadingsMenuIcon,
  H1Icon,
  H2Icon,
  H3Icon,
  ImageIcon,
  LoomIcon,
  KbdBulletedListIcon,
  KbdCodeblockIcon,
  KbdDividerIcon,
  KbdH1Icon,
  KbdH2Icon,
  KbdH3Icon,
  KbdImageIcon,
  KbdImageWindowsIcon,
  KbdNumberedListIcon,
  KbdParagraphIcon,
  KbdParagraphWindowsIcon,
  KbdQuoteIcon,
  KbdToDoListIcon,
  KbdCheckListIcon,
  NumberedListIcon,
  PasteHtmlIcon,
  PasteIcon,
  PasteMarkdownIcon,
  PastePlainTextIcon,
  PilcrowIcon,
  QuoteIcon,
  TemplateIcon,
  TodoListIcon,
  CheckListIcon,
  FocusModeIcon,
  GenericFileIconFilled,
  YoutubeIcon,
} from "thunk-icons";

import { EditorActions } from "components/slate/actions";
import {
  GroupItem,
  MenuItem,
} from "components/slate/plugins/menus/componentMenu/types";
import MenuIcon from "components/slate/plugins/menus/componentMenu/components/MenuIcon";
import MenuShortcut from "components/slate/plugins/menus/componentMenu/components/MenuShortcut";
import { ListItemType, ListTypes } from "components/slate/plugins/list/types";
import { ParagraphType } from "components/slate/plugins/paragraph/types";
import { HorizontalRuleType } from "components/slate/plugins/horizontalRule/types";
import { CodeBlockType } from "components/slate/plugins/codeBlock/types";
import { BlockquoteType } from "components/slate/plugins/blockquote/types";
import { FileType, ImageType } from "components/slate/plugins/file/types";
import {
  Heading1Type,
  Heading2Type,
  Heading3Type,
} from "components/slate/plugins/heading/types";
import { ELEMENTS_TEXT_BLOCKS } from "components/slate/plugins/menus/componentMenu/constants";
import {
  pasteHtml,
  pasteMarkdown,
  pastePlainText,
} from "components/slate/plugins/serialization/actions";
import { copyBlocklink } from "components/slate/plugins/backlink/actions";
import { MediaEmbedType } from "components/slate/plugins/mediaEmbed/types";

const _MenuItems = {
  heading1: {
    itemType: "ActionMenuItem",
    elementType: Heading1Type,
    text: "Heading 1",
    alias: ["h1"],
    icon: () => <MenuIcon component={H1Icon} />,
    style: { fontSize: 22, fontWeight: 700 },
    keyboardShortcut: () => <MenuShortcut component={KbdH1Icon} />,
    applyMenuItem: ({ editor }) =>
      EditorActions.applyHeading({ editor }, { level: 1 }),
  },
  heading2: {
    itemType: "ActionMenuItem",
    elementType: Heading2Type,
    text: "Heading 2",
    alias: ["h2"],
    icon: () => <MenuIcon component={H2Icon} />,
    style: { fontSize: 19, fontWeight: 700 },
    keyboardShortcut: () => <MenuShortcut component={KbdH2Icon} />,
    applyMenuItem: ({ editor }) =>
      EditorActions.applyHeading({ editor }, { level: 2 }),
  },
  heading3: {
    itemType: "ActionMenuItem",
    elementType: Heading3Type,
    text: "Heading 3",
    alias: ["h3"],
    icon: () => <MenuIcon component={H3Icon} />,
    style: { fontSize: 16, fontWeight: 700 },
    keyboardShortcut: () => <MenuShortcut component={KbdH3Icon} />,
    applyMenuItem: ({ editor }) =>
      EditorActions.applyHeading({ editor }, { level: 3 }),
  },
  bulletedList: {
    itemType: "ActionMenuItem",
    elementType: ListItemType,
    text: "Bulleted List",
    icon: () => <MenuIcon component={BulletedListIcon} />,
    keyboardShortcut: () => <MenuShortcut component={KbdBulletedListIcon} />,
    applyMenuItem: ({ editor }) =>
      EditorActions.applyList({ editor }, { listType: ListTypes.Bulleted }),
  },
  numberedList: {
    itemType: "ActionMenuItem",
    elementType: ListItemType,
    text: "Numbered List",
    icon: () => <MenuIcon component={NumberedListIcon} />,
    keyboardShortcut: () => <MenuShortcut component={KbdNumberedListIcon} />,
    applyMenuItem: ({ editor }) =>
      EditorActions.applyList({ editor }, { listType: ListTypes.Numbered }),
  },
  checkList: {
    itemType: "ActionMenuItem",
    elementType: ListItemType,
    text: "Checklist",
    icon: () => <MenuIcon component={CheckListIcon} />,
    keyboardShortcut: () => <MenuShortcut component={KbdCheckListIcon} />,
    applyMenuItem: ({ editor }) =>
      EditorActions.applyList({ editor }, { listType: ListTypes.CheckList }),
  },
  todoList: {
    itemType: "ActionMenuItem",
    elementType: ListItemType,
    text: "To Do List",
    icon: () => <MenuIcon component={TodoListIcon} />,
    keyboardShortcut: () => <MenuShortcut component={KbdToDoListIcon} />,
    applyMenuItem: ({ editor }) =>
      EditorActions.applyList({ editor }, { listType: ListTypes.TodoList }),
  },
  stream: {
    // Giving focus mode an id so I can add a line above it
    id: "focusMode",
    itemType: "ActionMenuItem",
    text: "Focus mode",
    icon: () => <MenuIcon component={FocusModeIcon} />,
    applyMenuItem: ({ editor }) => EditorActions.toggleStream({ editor }),
  },
  image: {
    itemType: "ActionMenuItem",
    elementType: ImageType,
    text: "Image",
    icon: () => <MenuIcon component={ImageIcon} />,
    keyboardShortcut: () => (
      <MenuShortcut
        component={KbdImageIcon}
        windowsComponent={KbdImageWindowsIcon}
      />
    ),
    applyMenuItem: ({ editor }) => {
      EditorActions.applyImage({ editor }, { url: "" });
    },
  },
  file: {
    itemType: "ActionMenuItem",
    elementType: FileType,
    text: "Upload File",
    alias: ["file"],
    icon: () => <MenuIcon component={GenericFileIconFilled} size={24} />,
    applyMenuItem: ({ editor }) => {
      EditorActions.applyFile({ editor });
    },
  },
  figma: {
    itemType: "ActionMenuItem",
    elementType: MediaEmbedType,
    text: "Figma",
    alias: ["figma"],
    icon: () => <MenuIcon component={FigmaIcon} size={24} />,
    applyMenuItem: ({ editor }) => {
      EditorActions.applyMediaEmbed({ editor });
    },
  },
  codepen: {
    itemType: "ActionMenuItem",
    elementType: MediaEmbedType,
    text: "Codepen",
    alias: ["codepen"],
    icon: () => <MenuIcon component={CodepenIcon} size={24} />,
    applyMenuItem: ({ editor }) => {
      EditorActions.applyMediaEmbed({ editor });
    },
  },
  youtube: {
    itemType: "ActionMenuItem",
    elementType: MediaEmbedType,
    text: "Youtube",
    alias: ["youtube", "yt"],
    icon: () => <MenuIcon component={YoutubeIcon} size={24} />,
    applyMenuItem: ({ editor }) => {
      EditorActions.applyMediaEmbed({ editor });
    },
  },
  loom: {
    itemType: "ActionMenuItem",
    elementType: MediaEmbedType,
    text: "Loom",
    alias: ["loom"],
    icon: () => <MenuIcon component={LoomIcon} size={24} />,
    applyMenuItem: ({ editor }) => {
      EditorActions.applyMediaEmbed({ editor });
    },
  },
  blockquote: {
    itemType: "ActionMenuItem",
    elementType: BlockquoteType,
    text: "Quote",
    icon: () => <MenuIcon component={QuoteIcon} />,
    keyboardShortcut: () => <MenuShortcut component={KbdQuoteIcon} />,
    applyMenuItem: ({ editor }) => EditorActions.applyBlockquote({ editor }),
  },
  codeBlock: {
    itemType: "ActionMenuItem",
    elementType: CodeBlockType,
    text: "Code block",
    icon: () => <MenuIcon component={CodeblockIcon} />,
    keyboardShortcut: () => <MenuShortcut component={KbdCodeblockIcon} />,
    applyMenuItem: ({ editor }) => EditorActions.applyCodeBlock({ editor }),
  },
  divider: {
    itemType: "ActionMenuItem",
    elementType: HorizontalRuleType,
    text: "Divider",
    icon: () => <MenuIcon component={DividerIcon} />,
    keyboardShortcut: () => <MenuShortcut component={KbdDividerIcon} />,
    applyMenuItem: ({ editor }) =>
      EditorActions.applyHorizontalRule({ editor }),
  },
  paragraph: {
    itemType: "ActionMenuItem",
    elementType: ParagraphType,
    text: "Paragraph",
    icon: () => <MenuIcon component={PilcrowIcon} />,
    keyboardShortcut: () => (
      <MenuShortcut
        component={KbdParagraphIcon}
        windowsComponent={KbdParagraphWindowsIcon}
      />
    ),
    applyMenuItem: ({ editor }) => EditorActions.applyParagraph({ editor }),
  },
  pastePlainText: {
    itemType: "ActionMenuItem",
    text: "Paste Plain Text",
    icon: () => <MenuIcon component={PastePlainTextIcon} />,
    applyMenuItem: ({ editor }) => pastePlainText(editor),
  },
  pasteHtml: {
    itemType: "ActionMenuItem",
    text: "Paste Html",
    icon: () => <MenuIcon component={PasteHtmlIcon} />,
    applyMenuItem: ({ editor }) => pasteHtml(editor),
  },
  pasteMarkdown: {
    itemType: "ActionMenuItem",
    alias: ["md"],
    text: "Paste Markdown",
    icon: () => <MenuIcon component={PasteMarkdownIcon} />,
    applyMenuItem: ({ editor }) => pasteMarkdown(editor),
  },
  copyBlocklink: {
    itemType: "ActionMenuItem",
    alias: ["block"],
    text: "Copy Block Link",
    singleBlockAction: true,
    icon: () => <MenuIcon component={CopyLinkIcon} />,
    applyMenuItem: ({ editor }) => copyBlocklink(editor),
  },
  back: {
    variant: "back",
    text: "Back",
    icon: () => <MenuIcon component={ChevronLeftIcon} size={20} />,
    applyMenuItem: ({ history }) => {
      history.goBack();
    },
  },
  delete: {
    variant: "delete",
    text: "Delete Block",
    icon: () => <MenuIcon component={DeleteNoteIcon} />,
    applyMenuItem: ({ editor }) => EditorActions.removeHighestBlock({ editor }),
  },
};

export const MenuItems = _MenuItems as {
  [K in keyof typeof _MenuItems]: MenuItem;
};

export const makeTemplateMenuItem = (
  title: string,
  content: Descendant[]
): MenuItem => ({
  text: title,
  icon: () => <MenuIcon component={TemplateIcon} />,
  applyMenuItem: ({ editor }) =>
    EditorActions.insertSnippet({ editor }, { content }),
});

export const defaultMenuConfig: MenuItem[] = [
  {
    id: "headings",
    text: "Headings",
    icon: () => <MenuIcon component={HeadingsMenuIcon} />,
    children: [MenuItems.heading1, MenuItems.heading2, MenuItems.heading3],
    applyMenuItem: ({ history }) => {
      history.push("/headings");
    },
  },
  {
    id: "lists",
    text: "Lists",
    icon: () => <MenuIcon component={BulletedListIcon} />,
    children: [
      MenuItems.bulletedList,
      MenuItems.numberedList,
      MenuItems.checkList,
      MenuItems.todoList,
    ],
    applyMenuItem: ({ history }) => {
      history.push("/lists");
    },
  },
  {
    id: "decorations",
    text: "Decorations",
    icon: () => <MenuIcon component={QuoteIcon} />,
    children: [MenuItems.codeBlock, MenuItems.divider, MenuItems.blockquote],
    applyMenuItem: ({ history }) => {
      history.push("/decorations");
    },
  },
  {
    id: "media",
    text: "Insert media",
    icon: () => <MenuIcon component={ImageIcon} />,
    children: [
      MenuItems.image,
      MenuItems.file,
      MenuItems.figma,
      MenuItems.youtube,
      MenuItems.loom,
      MenuItems.codepen,
    ],
    applyMenuItem: ({ history }) => {
      history.push("/media");
    },
  },
  {
    id: "snippets",
    text: "Insert template",
    icon: () => <MenuIcon component={TemplateIcon} />,
    children: [],
    dynamicChildren: true,
    applyMenuItem: ({ history }) => {
      history.push("/snippets");
    },
  },
  {
    id: "clipboard",
    text: "Paste",
    icon: () => <MenuIcon component={PasteIcon} />,
    children: [
      MenuItems.pastePlainText,
      MenuItems.pasteHtml,
      MenuItems.pasteMarkdown,
    ],
    applyMenuItem: ({ history }) => {
      history.push("/clipboard");
    },
  },
  MenuItems.paragraph,
  MenuItems.stream,
];

const _textMenuConfig = {
  children: clone(defaultMenuConfig),
};

crawl<any>(
  _textMenuConfig,
  (item: MenuItem, context) => {
    if (item.elementType && !ELEMENTS_TEXT_BLOCKS.has(item.elementType)) {
      const parent = context.parent as GroupItem;
      parent.children.splice(context.index, 1);

      context.remove();
      return;
    }

    if ("children" in item && item.children.length === 0) {
      const parent = context.parent;
      parent.children.splice(context.index, 1);

      context.remove();
    }
  },
  { order: "post" }
);

export const textMenuConfig: MenuItem[] = [
  ..._textMenuConfig.children,
  MenuItems.copyBlocklink,
  MenuItems.delete,
];

export const nonTextConfig: MenuItem[] = [
  MenuItems.copyBlocklink,
  MenuItems.delete,
];
