import { UseSlatePlugin } from "components/slate/types";
import { withTag } from "components/slate/plugins/tag/withTag";
import React from "react";

import { isTagElement } from "components/slate/plugins/tag/utils";
import TagComponent from "components/slate/plugins/tag/components/TagComponent";

const useTagPlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withTag(),
    renderElement: (props) => {
      if (isTagElement(props.element)) {
        return <TagComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useTagPlugin;
