import React, { useRef } from "react";

import {
  GraphIcon,
  GraphFilledIcon,
  FavoritesIcon,
  FavoritesFilledIcon,
} from "thunk-icons";
import GraphSearch from "pages/app/GraphPage/GraphToolbar/GraphSearch";
import GraphFilterTab from "pages/app/GraphPage/GraphToolbar/GraphFilterTab";
import GraphFilters from "pages/app/GraphPage/GraphToolbar/GraphFilters";
import { graphFilterTabs } from "pages/app/GraphPage/constants";
import {
  HorizontalTabPointer,
  useHorizontalTabPointer,
} from "framework/components/Pointer";
import FiltersTooltip from "pages/app/GraphPage/GraphToolbar/FiltersTooltip";

import styles from "./index.module.scss";

const GraphToolbar = ({
  search,
  setSearch,
  filters,
  applyFilters,
  activeTab,
  setActiveTab,
}: any) => {
  const toolbarRef = useRef(null);
  const activeClassName = "active";
  const tabPointer = useHorizontalTabPointer(toolbarRef, activeClassName, [
    activeTab,
  ]);

  return (
    <div ref={toolbarRef} className={styles.container}>
      <GraphSearch
        value={search}
        onChange={(e: any) => setSearch(e.target.value)}
      />
      <div style={{ display: "flex" }}>
        <FiltersTooltip
          content={
            <GraphFilters
              filters={filters}
              onChange={(changes: any) => {
                setActiveTab(graphFilterTabs.allNotes);
                applyFilters(changes);
              }}
            />
          }
        >
          <GraphFilterTab
            isActive={activeTab === graphFilterTabs.allNotes}
            onClick={() => setActiveTab(graphFilterTabs.allNotes)}
            activeClassName={activeClassName}
            title="All Notes"
            svgComponent={GraphIcon}
            activeSvgComponent={GraphFilledIcon}
          />
        </FiltersTooltip>
        <GraphFilterTab
          isActive={activeTab === graphFilterTabs.favourites}
          onClick={() => setActiveTab(graphFilterTabs.favourites)}
          activeClassName={activeClassName}
          title="Favorites"
          svgComponent={FavoritesIcon}
          activeSvgComponent={FavoritesFilledIcon}
        />
      </div>
      <HorizontalTabPointer className={styles.pointer} {...tabPointer} />
    </div>
  );
};

export default GraphToolbar;
