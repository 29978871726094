import { useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";

import { useDocumentsStore } from "stores/store";
import { useLoadable } from "hooks/useLoadable";
import { EditorType } from "components/slate/types";
import { DocumentType } from "thunk-core";
import { countContentWords } from "components/slate/utils";

export const useEditingSession = (props: {
  userId: string;
  editorType: EditorType;
  documentId: string | null;
  dateId: string | null;
  documentType: DocumentType;
}) => {
  const { userId, dateId, documentType } = props;

  const documentsStore = useDocumentsStore(documentType);

  // document id for DocumentType.NOTE can be null, so we will set it inside state after session init
  const state = useMemo(
    () => ({ documentId: props.documentId, slateId: uuid() }),
    [props.documentId]
  );

  const [{ loading, error }, loadable] = useLoadable();

  useEffect(() => {
    const abortController = new AbortController();

    const initialize = async () => {
      loadable.start();

      try {
        if (documentType === DocumentType.NOTE) {
          state.documentId = await documentsStore.startDailyEditingSession(
            userId,
            state.slateId,
            dateId!,
            {
              signal: abortController.signal,
            }
          );
        } else {
          await documentsStore.startEditingSession(
            userId,
            state.slateId,
            props.documentId!,
            props.editorType,
            {
              signal: abortController.signal,
            }
          );
        }

        loadable.success();
      } catch (error) {
        loadable.error(error);
      }
    };

    initialize();

    return () => {
      abortController.abort();
      if (state.documentId) {
        documentsStore.endEditingSession({
          slateId: state.slateId,
          documentId: state.documentId,
          editorType: props.editorType,
        });
      }
    };
  }, [props.documentId, dateId]);

  const document = documentsStore.getDocument(state.documentId);
  const content = documentsStore.getEditingSession({
    slateId: state.slateId,
  });
  const wordCount = useMemo(() => countContentWords(content), [content]);
  const title = document?.title;

  return {
    loading: !error && (loading || document == null || content == null),
    error,
    title,
    content,
    document,
    wordCount,
    slateId: state.slateId,
  };
};
