import { Editor } from "slate";
import { insertImage } from "@udecode/plate-image";

import { addNotification } from "hooks/useNotifications";
import {
  acceptedFileMimeTypes,
  bytesToMegabytes,
  isFileElement,
  isImageElement,
  MAX_SIZE_FILE_MB,
} from "components/slate/plugins/file/utils";
import { FileElement } from "./types";
import { insertFileElement } from "./transforms";

export const withFileUpload = ({
  isReferenceEditor,
  userId,
  uploadImage,
  uploadFile,
}: {
  isReferenceEditor: boolean;
  userId?: string;
  uploadImage: (
    userId: string | null,
    file: any,
    result: any
  ) => Promise<string>;
  uploadFile: (userId: string | null, file: any) => Promise<FileElement>;
}) => (editor: Editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) =>
    isImageElement(element) || isFileElement(element) || isVoid(element);

  editor.insertData = async (data) => {
    const { files } = data;

    if (files && files.length > 0) {
      if (isReferenceEditor) {
        addNotification({
          duration: 2000,
          type: "error",
          text: `Files can only be dropped in the body of a note`,
        });
        return;
      }

      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", async () => {
            if (!reader.result) {
              return;
            }
            const uploadedUrl = uploadImage
              ? await uploadImage(userId, file, reader.result)
              : reader.result;

            insertImage(editor as any, uploadedUrl);
          });

          reader.readAsDataURL(file);
        } else if (acceptedFileMimeTypes.indexOf(file.type) !== -1) {
          if (bytesToMegabytes(file.size) > MAX_SIZE_FILE_MB) {
            addNotification({
              duration: 2000,
              type: "error",
              text: `Whoops! ${file.name} is too big. Please upload a file less than ${MAX_SIZE_FILE_MB}MB`,
            });
            continue;
          }
          const fileElemData = await uploadFile(userId, file);
          insertFileElement(editor, fileElemData);
        } else {
          addNotification({
            duration: 2000,
            type: "error",
            text: "Invalid file type",
          });
        }
      }

      return;
    }

    insertData(data);
  };

  return editor;
};
