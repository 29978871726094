import React from "react";

import Button, { ButtonProps } from "framework/components/form/Button";

import cn from "classnames";
import styles from "./index.module.scss";

export const TableFilterContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className={styles.filterContainer}>{children}</div>;
};

export const TableFilterSection = ({
  className,
  title,
  actions,
  children,
}: {
  className?: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div className={cn(styles.filterSection, className)}>
      {(title || actions) && (
        <div className={styles.header}>
          {title && <div className={styles.title}>{title}</div>}
          {actions && <div className={styles.actions}>{actions}</div>}
        </div>
      )}
      {children}
    </div>
  );
};

export const TableFilterClearButton = (props: ButtonProps) => {
  return (
    <Button style={{ fontSize: 13 }} size="small" variant="ghost" {...props}>
      Clear All
    </Button>
  );
};

export const TableFilterDivider = () => {
  return <hr className={styles.divider} />;
};
