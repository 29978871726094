import React from "react";
import cn from "classnames";

import styles from "./index.module.scss";

type Props = {
  hasError?: boolean;
  labelText?: string;
  labelContentRight?: React.ReactNode;
} & JSX.IntrinsicElements["label"];

const FormGroupLabel = ({
  hasError,
  labelText,
  labelContentRight,
  ...rest
}: Props) => {
  return (
    <label
      className={cn(styles.formGroupLabel, {
        [styles.formGroupLabelWithError]: hasError,
      })}
      {...rest}
    >
      {labelText}
      {labelContentRight && (
        <span className={cn(styles.labelEditButtons)}>{labelContentRight}</span>
      )}
    </label>
  );
};

export default FormGroupLabel;
