import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import isHotkey from "is-hotkey";

import { CardEditableTitle } from "framework/components/Card";
import { focusEditorAtStart } from "components/slate/utils";
import { useSidebarContext } from "providers/SidebarProvider";
import { useSidePanelContext } from "providers/SidePanelProvider";

const DocumentEditorTitle = ({
  isMainEditor,
  readOnly,
  editor,
  duplicatedId,
  duplicatedPage,
  titleSaving,
  title,
  handleTitleChange,
  onBlur,
}: any) => {
  const ref = useRef<HTMLTextAreaElement | null>(null);
  useEffect(() => {
    if (!readOnly && isMainEditor && !title) {
      ref.current && ref.current.focus();
    }
  }, [readOnly]);

  const [, forceRerender] = useState(0);

  const { isSidebarOpened } = useSidebarContext();
  const { isSidePanelOpened } = useSidePanelContext();

  useEffect(() => {
    setTimeout(() => forceRerender((x) => x + 1), 200);
  }, [isSidebarOpened, isSidePanelOpened]);

  return (
    <CardEditableTitle
      ref={ref}
      isSidePanel={!isMainEditor}
      disabled={readOnly}
      placeholder="Add a title"
      title={title || ""}
      onChange={(e: any) => handleTitleChange(e.target.value)}
      onKeyDown={(e: any) => {
        if (editor) {
          if (isHotkey("Enter", e)) {
            e.preventDefault();
            focusEditorAtStart(editor);
          }
        }
      }}
      onBlur={onBlur}
      showHint={duplicatedId == null || !titleSaving}
      hint={
        titleSaving
          ? "Saving title..."
          : duplicatedId != null && (
              <>
                You already have this page{" "}
                <Link to={`/page/${duplicatedId}`}>
                  "{duplicatedPage.title}"
                </Link>
              </>
            )
      }
    />
  );
};

export default DocumentEditorTitle;
