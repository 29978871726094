import { useCallback, useRef, useState } from "react";

export type UseDialogHook<T extends {} = {}, R = any> = {
  isOpen: boolean;
  modalData: T;
  open: (modalData?: T) => Promise<R>;
  close: (result?: R) => void;
};

const useDialog = <T, R>(
  initialIsOpen: boolean = false,
  initialModalData: T = {} as T
): UseDialogHook<T, R> => {
  const resolveRef = useRef(null);
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [modalData, setModalData] = useState<T>(initialModalData);

  const open = useCallback((initialData: T) => {
    setModalData(initialData || ({} as T));
    setIsOpen(true);
    return new Promise<R>((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  const close = useCallback((payload) => {
    setIsOpen(false);
    resolveRef.current && resolveRef.current(payload);
  }, []);

  return { isOpen, modalData, open, close };
};

export default useDialog;
