import React, { useState } from "react";

import Button, { ButtonProps } from "framework/components/form/Button";

const LoadingButton = (props: ButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      loading={isLoading}
      {...props}
      onClick={async (e) => {
        if (props.onClick) {
          setIsLoading(true);
          await props.onClick(e);
          setIsLoading(false);
        }
      }}
    />
  );
};

export default LoadingButton;
