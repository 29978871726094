import React, { memo, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import Helmet from "components/Helmet";
import OverviewPageLayout from "components/layout/OverviewPageLayout";
import CardSpinner from "framework/components/Card/CardSpinner";
import AppResult from "components/results/AppResult";
import { useStore } from "stores/store";
import { useCurrentUserId } from "db/currentUser";
import {
  Table,
  TableBody,
  TableCell,
  TableCheckboxCell,
  TableHead,
  TableHeaderCell,
  TableHeaderCheckboxCell,
  TableRow,
  TableRowEmpty,
  TableSelectionProvider,
} from "framework/components/table";
import useSorting from "framework/components/table/useSorting";
import useComparator from "framework/components/table/useComparator";
import Button from "framework/components/form/Button";
import CreateTagMenu from "components/tags/CreateTagMenu";
import useSelection from "framework/components/table/useSelection";
import { formatDate } from "utils/dateUtils";
import { DocumentTag } from "components/editor/DocumentTags";
import DeleteTagsButton from "pages/app/TagsOverviewPage/DeleteTagsButton";
import useFilteredTags from "pages/app/TagsOverviewPage/useFilteredTags";
import TableCount from "framework/components/table/TableCount";
import TagsFilter from "pages/app/TagsOverviewPage/TagsFilter";
import TagsFilterMobile from "pages/app/TagsOverviewPage/TagsFilterMobile";

import styles from "./index.module.scss";
import { Tag as TagDb, Tag } from "thunk-core";

type TreeNode<T> = T & {
  level: number;
  children: TreeNode<T>[];
};

const indentationWidth = 25;

const TagsOverviewPage = observer(() => {
  const userId = useCurrentUserId();
  const { tagsStore } = useStore();
  const [search, setSearch] = useState("");

  //allow body scrolling on this page
  useEffect(() => {
    document.body.removeAttribute("data-not-public");
    document.body.removeAttribute("daily-note");
    document.body.setAttribute("no-side-panel-page", "true");
  });

  useEffect(() => {
    tagsStore.loadAllTags(userId);
  }, [userId]);

  const tags = tagsStore.getTags();
  const allIds = useMemo(() => tags.map((x) => x.id), [tags]);

  const loading = tagsStore.loading;
  const error = tagsStore.error;

  const [sorting, updateSorting] = useSorting<TagDb>("tagsOverviewSorting", {
    field: "title",
    order: "asc",
  });
  const comparator = useComparator(sorting);
  const selection = useSelection(allIds);

  const filteredTags = useFilteredTags({
    loading,
    error,
    search,
    comparator,
  });

  const filteredIds = filteredTags.map((x) => x.item.id);

  const rightActions = (
    <>
      {selection.selectedIds.size > 0 && (
        <DeleteTagsButton selection={selection} />
      )}
      <CreateTagMenu>
        {({ ref }) => (
          <Button ref={ref} variant="primary" size="large">
            + New Tag
          </Button>
        )}
      </CreateTagMenu>
    </>
  );

  return (
    <OverviewPageLayout title="Tags" rightActions={rightActions}>
      <Helmet pageTitle="Tags" />
      <TagsFilterMobile search={search} setSearch={setSearch} />
      {(function () {
        if (loading) {
          return <CardSpinner loading={loading} loadingTimeout={300} />;
        }

        if (error) {
          console.error(error);
          return <AppResult resultType="fetching" isCenteredContent={true} />;
        }

        if (tags.length === 0) {
          return (
            <AppResult
              isCenteredContent={true}
              title="You don't have any tags yet. Let's make some!"
            />
          );
        }

        return (
          <TableSelectionProvider filteredIds={filteredIds} {...selection}>
            <Table
              filter={<TagsFilter search={search} setSearch={setSearch} />}
              filterCount={
                <TableCount
                  count={filteredTags.length}
                  total={tags.length}
                  name="Tags"
                />
              }
            >
              <TableHead>
                <TableRow className={styles.tr}>
                  <TableHeaderCheckboxCell />
                  <TableHeaderCell
                    sortingKey={"title"}
                    sorting={sorting}
                    className={styles.title}
                    onClick={() => updateSorting("title", "asc")}
                  >
                    Title
                  </TableHeaderCell>
                  <TableHeaderCell
                    sortingKey={"createdAt"}
                    sorting={sorting}
                    onClick={() => updateSorting("createdAt", "desc")}
                  >
                    Created
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(function () {
                  if (filteredTags.length === 0) {
                    return <TableRowEmpty colSpan={2} />;
                  }

                  return filteredTags.map(({ item, level }) => {
                    return (
                      <TagTableRow key={item.id} tag={item} level={level} />
                    );
                  });
                })()}
              </TableBody>
            </Table>
          </TableSelectionProvider>
        );
      })()}
    </OverviewPageLayout>
  );
});

export default TagsOverviewPage;

const TagTableRow = observer<{ tag: Tag; level: number }>(({ tag, level }) => {
  return (
    <TableRow key={tag.id} className={styles.tr}>
      <TableCheckboxCell itemId={tag.id} />
      <TableCell
        style={
          {
            "--spacing": `${level * indentationWidth}px`,
          } as React.CSSProperties
        }
        className={styles.title}
      >
        <div className={styles.tagContainer}>
          <DocumentTag tag={tag} readOnly={true} />
        </div>
      </TableCell>
      <TableCell className={styles.date}>
        {formatDate(tag.createdAt?.toDate())}
      </TableCell>
    </TableRow>
  );
});
