import React, { useCallback, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import usePersistedUserState from "hooks/usePersistedUserState";
import useMemoObject from "hooks/useMemoObject";
import useWindowEventListener from "hooks/useWindowEventListener";
import isHotkey from "is-hotkey";
import { appShortcuts } from "appShortcuts";

export enum SidePanelTabType {
  Backlinks = "backlinks",
  Tasks = "tasks",
}

type SidePanelContextValue = {
  isSidePanelOpened: boolean;
  toggleSidePanel: (isOpened: React.SetStateAction<boolean>) => void;
  activeTab: SidePanelTabType | null;
  setActiveTab: React.Dispatch<React.SetStateAction<SidePanelTabType | null>>;
};

const SidePanelContext = React.createContext<SidePanelContextValue>(
  {} as SidePanelContextValue
);

export const useSidePanelContext = () => {
  return useContext(SidePanelContext);
};

export const SidePanelProvider = ({ children }: any) => {
  const location = useLocation();
  const { isMobileScreen } = useDeviceDetector();

  const [isSidePanelOpened, setIsSidePanelOpened] = usePersistedUserState(
    "sidePanelState",
    (restored: any) => {
      if (isMobileScreen) {
        return false;
      }

      return restored ?? true;
    }
  );

  const [activeTab, setActiveTab] = usePersistedUserState(
    "sidePanelActiveTab",
    (restored) => restored || SidePanelTabType.Backlinks
  );

  const toggleSidePanel = useCallback((isOpened: boolean) => {
    setIsSidePanelOpened(isOpened);
  }, []);

  useEffect(() => {
    isMobileScreen && toggleSidePanel(false);
  }, [location]);

  useEffect(() => {
    isMobileScreen && toggleSidePanel(false);
  }, [isMobileScreen]);

  useEffect(() => {
    isSidePanelOpened
      ? document.body.setAttribute("data-side-panel-opened", "true")
      : document.body.removeAttribute("data-side-panel-opened");
  }, [isSidePanelOpened]);

  useWindowEventListener(
    "keydown",
    (e: any) => {
      if (isHotkey(appShortcuts.toggleSidePanel, e)) {
        setIsSidePanelOpened((state) => !state);
      }
      if (isSidePanelOpened && isHotkey(appShortcuts.toggleSidepanelTab, e)) {
        setActiveTab(
          activeTab === SidePanelTabType.Backlinks
            ? SidePanelTabType.Tasks
            : SidePanelTabType.Backlinks
        );
      }
    },
    [activeTab, setActiveTab, isSidePanelOpened]
  );

  return (
    <SidePanelContext.Provider
      value={useMemoObject({
        isSidePanelOpened,
        toggleSidePanel,
        activeTab,
        setActiveTab,
      })}
    >
      {children}
    </SidePanelContext.Provider>
  );
};
