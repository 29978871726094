import React from "react";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { RefreshIcon, InvitationsIcon, NewNoteIcon } from "thunk-icons";
import Result, { ResultProps } from "framework/components/Result";
import Button, { ButtonProps } from "framework/components/form/Button";

import { ReactComponent as ErrorResultImage } from "images/error-result-image.svg";
import { ReactComponent as ConnectionResultImage } from "images/connection-result-image.svg";
import { ReactComponent as MaintenanceResultImage } from "images/maintenance-result.svg";
import { env } from "thunk-env";

type ResultType =
  | "error"
  | "fetching"
  | "notShared"
  | "notFound"
  | "maintenance"
  | undefined;

type AppResultProps = {
  resultType?: ResultType;
  eventId?: string | null;
} & ResultProps;

const AppResult = ({
  resultType,
  eventId,
  image,
  title,
  text,
  contacts,
  actions,
  isCenteredContent,
}: AppResultProps) => {
  const { title: defaultTitle, text: defaultText } =
    getDefaultTextProps(resultType);

  return (
    <Result
      image={image || renderDefaultImage(resultType)}
      title={title || defaultTitle}
      text={text || defaultText}
      contacts={contacts || renderContacts(resultType)}
      actions={actions || renderDefaultActions(resultType, eventId)}
      isCenteredContent={isCenteredContent}
    />
  );
};

export default AppResult;

const renderDefaultImage = (resultType: ResultType): React.ReactNode => {
  switch (resultType) {
    case "maintenance": {
      return <MaintenanceResultImage />;
    }
    case "fetching": {
      return <ConnectionResultImage />;
    }
    case "error": {
      return <ErrorResultImage />;
    }
    default: {
      return undefined;
    }
  }
};

const renderDefaultActions = (
  resultType: ResultType,
  eventId?: string | null
) => {
  switch (resultType) {
    case "error": {
      return (
        <>
          {eventId !== undefined && <ProvideFeedbackButton eventId={eventId} />}
          <RefreshPageButton />
        </>
      );
    }
    case "fetching": {
      return (
        <>
          <RefreshPageButton />
        </>
      );
    }
    case "notShared": {
      return <GoHomeButton />;
    }
    case "notFound": {
      return <GoHomeButton />;
    }
    default: {
      return undefined;
    }
  }
};

const renderContacts = (resultType: ResultType) => {
  if (
    resultType === "error" ||
    resultType === "fetching" ||
    resultType === "notFound"
  ) {
    return <SupportContacts />;
  }

  return undefined;
};

const getDefaultTextProps = (
  resultType: ResultType
): { title?: string; text?: string } => {
  switch (resultType) {
    case "maintenance": {
      return {
        title: "Be right back.",
        text: "We're busy updating Thunk Notes for you. We'll be back soon.",
      };
    }
    case "fetching": {
      return {
        title: "Loading data error",
        text: "We are having trouble connecting to load your data. Make sure you have an internet connection.",
      };
    }
    case "error": {
      return {
        title: "Oops! We've had an error.",
        text: "Something went wrong and we've been notified. Try refreshing the page. If that doesn't work—let us know and we will help you.",
      };
    }
    case "notShared": {
      return {
        title: "This note is no longer shared",
      };
    }
    case "notFound": {
      return {
        title: "404 - Not Found",
      };
    }
    default: {
      return {};
    }
  }
};

export const SupportContacts = () => {
  return (
    <>
      <a href={`mailto:${env.REACT_APP_SUPPORT_EMAIL}`}>
        {env.REACT_APP_SUPPORT_EMAIL}
      </a>
      {" | "}
      <a href={env.REACT_APP_DISCORD_SUPPORT_LINK} target="_blank">
        Discord support channel
      </a>
    </>
  );
};

export const RefreshPageButton = (props: ButtonProps) => {
  return (
    <Button
      icon={RefreshIcon}
      variant="secondary"
      size="large"
      onClick={() => window.location.replace(window.location.href)}
      {...props}
    >
      {props.children || "Refresh page"}
    </Button>
  );
};

export const ProvideFeedbackButton = (
  props: ButtonProps & { eventId: string | null }
) => {
  return (
    <Button
      icon={InvitationsIcon}
      variant="secondary"
      size="large"
      onClick={() => {
        Sentry.showReportDialog({ eventId: props.eventId });
      }}
      {...props}
    >
      {props.children || "Provide feedback"}
    </Button>
  );
};

export const GoHomeButton = (props: ButtonProps) => {
  return (
    <Button variant="secondary" size="large" as={Link} to="/" {...props}>
      {props.children || "Go Home"}
    </Button>
  );
};

export const CreateItemButton = (props: ButtonProps) => {
  return (
    <Button
      icon={NewNoteIcon}
      variant="secondary"
      size="large"
      as={Link}
      to="/"
      {...props}
    />
  );
};

export const ResetErrorButton = (
  props: ButtonProps & { onReset: () => void }
) => {
  return (
    <Button
      icon={RefreshIcon}
      variant="secondary"
      size="large"
      {...props}
      onClick={props.onReset}
    >
      {props.children || "Refresh"}
    </Button>
  );
};
