import { Node, Editor, Element, Transforms } from "slate";

import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";

type DndTransformOptions = {
  editor: Editor;
  activeId: string;
  activeIndex: number;
  selectedIds: Set<string>;
  overId: string;
  overIndex: number;
  dragDepth?: number;
};

export const moveDndTransform = (options: DndTransformOptions) => {
  const { editor, activeId, overId, dragDepth, selectedIds } = options;

  if (activeId !== overId) {
    // move only if ids different (unlike depth changes)
    const overIndex = getAdjustedOverIndex(options);
    moveDndElements(editor, activeId, selectedIds, overIndex);
  }

  const element = editor.children.find((x) => x.id === activeId);

  if (
    ExtendedEditor.isNestingElement(editor, element) &&
    dragDepth != null &&
    element.depth !== dragDepth
  ) {
    updateDndDepth(editor, activeId, selectedIds, dragDepth);
  }
};

const getAdjustedOverIndex = (options: DndTransformOptions) => {
  const { editor, activeIndex } = options;
  let { overIndex } = options;

  if (activeIndex < overIndex) {
    const semanticChildren = ExtendedEditor.getSemanticChildren(
      editor,
      editor.children
    );

    const droppableIntervals = ExtendedEditor.getDroppableIntervals(
      editor,
      semanticChildren,
      editor.children.length
    );
    const droppableEnds = new Set(droppableIntervals.map((x) => x[1]));

    // adjust over index in case it is outside droppable elements
    for (const end of droppableEnds) {
      if (overIndex <= end) {
        overIndex = end;
        break;
      }
    }
  }

  return overIndex;
};

export const moveDndElements = (
  editor: Editor,
  activeId: string,
  selectedIds: Set<string>,
  overIndex: number
) => {
  const element = editor.children.find((x) => x.id === activeId);

  if (!element) {
    return;
  }

  const foldedCount = ExtendedEditor.isFoldingElement(editor, element)
    ? element.foldedCount || 0
    : 0;
  const semanticDescendants = ExtendedEditor.isNestingElement(editor, element)
    ? ExtendedEditor.semanticDescendants(editor, element)
    : ExtendedEditor.semanticDescendants(editor, element)?.slice(
        0,
        foldedCount
      );
  const descendantsIds = new Set(semanticDescendants.map((x) => x.element.id));

  const match = (node: Node) =>
    Element.isElement(node) &&
    (node === element ||
      descendantsIds.has(node.id) ||
      selectedIds.has(node.id));

  Transforms.moveNodes(editor, {
    at: [],
    match,
    to: [overIndex],
  });
};

export const updateDndDepth = (
  editor: Editor,
  activeId: string,
  selectedIds: Set<string>,
  dragDepth: number = 0
) => {
  Editor.withoutNormalizing(editor, () => {
    const element = editor.children.find((x) => x.id === activeId);
    const selection = editor.children.filter(
      (x) => selectedIds.has(x.id) || x.id === activeId
    );
    const minDepth = Math.min(
      ...selection.map((x) =>
        ExtendedEditor.isNestingElement(editor, x) ? x.depth : 0
      )
    );

    if (ExtendedEditor.isNestingElement(editor, element)) {
      const foldedCount = ExtendedEditor.isFoldingElement(editor, element)
        ? element.foldedCount || 0
        : 0;
      const semanticDescendants = ExtendedEditor.isNestingElement(
        editor,
        element
      )
        ? ExtendedEditor.semanticDescendants(editor, element)
        : ExtendedEditor.semanticDescendants(editor, element)?.slice(
            0,
            foldedCount
          );

      const depthDiff = minDepth - dragDepth;

      const match = (node: Node) =>
        node === element ||
        (Element.isElement(node) &&
          (selectedIds.has(node.id) ||
            semanticDescendants.some((x) => x.element.id === node.id)));

      const entries = Editor.nodes(editor, { at: [], match });

      for (let [node] of entries) {
        if (ExtendedEditor.isNestingElement(editor, node)) {
          Transforms.setNodes(
            editor,
            {
              depth: Math.max(0, node.depth - depthDiff),
            },
            {
              at: [],
              match: (_node) => _node === node,
            }
          );
        }
      }
    }
  });
};
