export type BoldMarkType = "bold";
export const BoldMarkType = "bold";

export type ItalicMarkType = "italic";
export const ItalicMarkType = "italic";

export type CodeMarkType = "code";
export const CodeMarkType = "code";

export type UnderlineMarkType = "underline";
export const UnderlineMarkType = "underline";

export type StrikethroughMarkType = "strikethrough";
export const StrikethroughMarkType = "strikethrough";

export type HighlightMarkType = "highlight";
export const HighlightMarkType = "highlight";

export type KbdMarkType = "kbd";
export const KbdMarkType = "kbd";

export type CodeSyntaxMarkType = "code_syntax";
export const CodeSyntaxMarkType = "code_syntax";

export type MarkType =
  | BoldMarkType
  | ItalicMarkType
  | CodeMarkType
  | UnderlineMarkType
  | StrikethroughMarkType
  | HighlightMarkType
  | KbdMarkType
  | CodeSyntaxMarkType;
type FormattedTextMarks = Partial<Record<MarkType, boolean>>;

export type FormattedText = {
  text: string;
} & FormattedTextMarks;
