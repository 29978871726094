const errors = {
  "storage/unauthorized": "account_form.profile_photo.errors.unauthorized",
  "storage/retry-limit-exceeded":
    "account_form.profile_photo.errors.connection",
};

export const getErrorInfo = (error = {}) => {
  //  @ts-ignore ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const message = errors[error.code];

  if (message != null) {
    return {
      message,
      //  @ts-ignore ts-migrate(2339) FIXME: Property 'code' does not exist on type '{}'.
      code: error.code,
    };
  }

  return {
    message: "account_form.profile_photo.errors.unknown",
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'code' does not exist on type '{}'.
    code: error.code,
  };
};
