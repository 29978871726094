import {
  BacklinkType,
  BacklinkElement,
} from "components/slate/plugins/backlink/types";
import { makeNodeId } from "components/slate/config/makeNodeId";
import {
  DocumentType,
  MakeNoteBacklinkParams,
  MakePageBacklinkParams,
} from "thunk-core";

export const makePageBacklink = (
  userId: string,
  params: MakePageBacklinkParams
) => {
  const element: BacklinkElement = {
    id: makeNodeId(false),
    type: BacklinkType,
    userId,
    targetId: params.documentId,
    targetType: DocumentType.PAGE,
    targetBlockId: params.targetBlockId || null,
    blockText: params.blockText || null,
    children: [{ text: params.initialText }], // mb we can keep text empty, Node.string result depends on it
  };

  return element;
};

export const makeNoteBacklink = (
  userId: string,
  params: MakeNoteBacklinkParams
) => {
  const element: BacklinkElement = {
    id: makeNodeId(false),
    type: BacklinkType,
    userId,
    targetId: params.dateId,
    targetType: DocumentType.NOTE,
    targetBlockId: params.targetBlockId || null,
    blockText: params.blockText || null,
    children: [{ text: `[[${params.dateId}]]` }], // mb we can keep text empty, Node.string result depends on it
  };

  return element;
};
