import { Editor } from "slate";

import insertHtml from "components/slate/plugins/serialization/transforms/insertHtml";

export const withDeserialize = (editor: Editor) => {
  const { insertFragmentData } = editor;

  editor.insertFragmentData = (data) => {
    if (insertFragmentData(data)) {
      return true;
    }

    const html = data.getData("text/html");

    if (insertHtml(editor, html)) {
      return true;
    }

    return false;
  };

  return editor;
};
