import { Element } from "slate";

import { makeListItemAttributes } from "components/slate/plugins/serialization/utils";
import {
  isListItemElement,
  isCheckableListItem,
  isTodoListItemElement,
} from "components/slate/plugins/list/utils";
import { SemanticNode } from "components/slate/slate-extended/types";

export const getComponentWrapperClass = (element: Element) => {
  return `slate-component-${element.type}`;
};

export const getComponentWrapperAttributes = (
  element: Element,
  semanticNode: SemanticNode
) => {
  if (isListItemElement(element)) {
    const { listIndex } = semanticNode;

    return makeListItemAttributes({
      depth: element.depth,
      listType: element.listType,
      index: listIndex,
      checked: isCheckableListItem(element) && element.checked,
      dueDate: (isTodoListItemElement(element) && element.dueDate) || null,
    });
  }

  return {};
};
