import React from "react";
import { BaseEmoji, Picker } from "emoji-mart";
import { EmojiIcon } from "thunk-icons";

import Button from "framework/components/form/Button";
import useAppPopper from "framework/components/poppers/useAppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import AppPopper from "framework/components/poppers/AppPopper";
import { useThemeContext } from "providers/ThemeProvider";

import "./index.scss";
import { Tag as TagDb } from "thunk-core";

type Props = {
  emoji: TagDb["emoji"];
  onChange: (emoji: TagDb["emoji"]) => void;
};

const EmojiPicker = (props: Props) => {
  const { emoji, onChange } = props;

  const { darkMode } = useThemeContext();

  const popperProps = useAppPopper({
    placement: "bottom",
    hasArrow: true,
  });
  useClickPopper(popperProps);

  return (
    <div style={{ alignSelf: "center" }}>
      <AppPopper
        {...popperProps}
        fromPopper={true}
        content={
          (popperProps.isOpened || popperProps.isOpenedDebounced) && (
            <div>
              <Picker
                theme={darkMode ? "dark" : "light"}
                showPreview={false}
                onSelect={(data) => {
                  const { id, native } = data as BaseEmoji;
                  onChange({ id, native });
                }}
              />
              <div style={{ padding: 10, width: "100%" }}>
                <Button
                  fullWidth={true}
                  variant="secondary"
                  onClick={() => onChange(null)}
                >
                  Clear
                </Button>
              </div>
            </div>
          )
        }
      />

      <Button ref={popperProps.setTargetElement} size="symbol" variant="ghost">
        {emoji ? (
          <div style={{ fontSize: 22, padding: "0 10px 0 0" }}>
            {emoji?.native}
          </div>
        ) : (
          <EmojiIcon size={22} />
        )}
      </Button>
    </div>
  );
};

export default EmojiPicker;
