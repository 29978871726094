import { Descendant, Editor, Element, Transforms } from "slate";
import { insertImage } from "@udecode/plate-image";
import { PlateEditor, toggleMark } from "@udecode/plate-core";
import { insertEmptyCodeBlock } from "@udecode/plate-code-block";
import { RecoilValue } from "recoil";

import { toggleList } from "components/slate/plugins/list/transforms";
import { insertTemplate as _insertTemplate } from "components/slate/plugins/templates/transforms";
import { insertBacklink } from "components/slate/plugins/backlink/transforms";
import { insertDate } from "components/slate/plugins/timestamp/transforms";
import { insertHorizontalRule } from "components/slate/plugins/horizontalRule/transforms";
import { isImageElement } from "components/slate/plugins/file/utils";
import { ListTypes } from "components/slate/plugins/list/types";
import { BlockquoteType } from "components/slate/plugins/blockquote/types";
import { CodeLineType } from "components/slate/plugins/codeBlock/types";
import { ParagraphType } from "components/slate/plugins/paragraph/types";
import { getHeadingType } from "components/slate/plugins/heading/utils";
import { openImageUploader } from "components/slate/plugins/file/components/ImageUploader";
import { MarkType } from "components/slate/plugins/marks/types";
import { toggleStreamMode } from "components/slate/plugins/stream/components/StreamProvider";
import { insertMediaEmbed } from "../plugins/mediaEmbed/transforms/insertMediaEmbed";
import {
  makeNoteBacklink,
  makePageBacklink,
} from "db/backlinks/backlinks.mapping";
import { getDocumentsStore, store } from "stores/store";
import { insertTagElement } from "components/slate/plugins/tag/transforms";
import { makeTag, mapTagToTagElement } from "db/tags/tags.mapping";
import { insertEmptyFileElement } from "components/slate/plugins/file/transforms";
import {
  DocumentType,
  MakeDocumentAsBacklinkParams,
  MakeNoteBacklinkParams,
  MakePageBacklinkParams,
  Tag,
} from "thunk-core";
import { useStoreCallback } from "state/utils";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { ActionOptions } from "components/slate/actions/types";
import {
  useAddEmptyBlock,
  useCopyBlocks,
  useCopyBlocksEffect,
  useCopyToExistingNote,
  useCopyToNewNote,
  useDuplicate,
  useEndTerminal,
  useMoveBlocks,
  useMoveBlocksToEdge,
  useMoveSelectionToBottom,
  useMoveSelectionToTop,
  usePasteBlocks,
  usePasteBlocksHandler,
  useStartTerminal,
} from "components/slate/plugins/terminal/actions";

/* eslint-disable  react-hooks/rules-of-hooks */
const useActionsHooks = () => {
  return {
    applyParagraph: () => {
      return useStoreCallback((get) => ({ editor }: { editor: Editor }) => {
        const options = getActionsOptions(get);

        Transforms.setNodes(editor, { type: ParagraphType }, options);
      });
    },
    applyHeading: () => {
      return useStoreCallback(
        (get) => (
          { editor }: { editor: Editor },
          payload: { level: 1 | 2 | 3 }
        ) => {
          const { level } = payload;
          const options = getActionsOptions(get);

          Transforms.setNodes(editor, { type: getHeadingType(level) }, options);
        }
      );
    },
    applyList: () => {
      return useStoreCallback(
        (get) => (
          { editor }: { editor: Editor },
          payload: { listType: ListTypes }
        ) => {
          const { listType } = payload;
          const options = getActionsOptions(get);

          toggleList(editor, { listType }, options);
        }
      );
    },
    toggleStream: () => {
      return ({ editor }: { editor: Editor }) => {
        toggleStreamMode(editor);
      };
    },
    applyImage: () => {
      return ({ editor }: { editor: Editor }, payload: { url: string }) => {
        const { url } = payload;

        insertImage(editor as PlateEditor, url);
        const [entry] = Editor.nodes(editor, { match: isImageElement });
        if (entry) {
          openImageUploader(editor, entry[0]);
        }
      };
    },
    applyFile: () => {
      return ({ editor }: { editor: Editor }) => {
        insertEmptyFileElement(editor);
      };
    },
    applyMediaEmbed: () => {
      return ({ editor }: { editor: Editor }) => {
        insertMediaEmbed(editor, {});
      };
    },
    applyBlockquote: () => {
      return useStoreCallback((get) => ({ editor }: { editor: Editor }) => {
        const options = getActionsOptions(get);
        Transforms.setNodes(editor, { type: BlockquoteType }, options);
      });
    },
    applyCodeBlock: () => {
      return ({ editor }: { editor: Editor }) => {
        insertEmptyCodeBlock(editor as PlateEditor, {
          insertNodesOptions: { select: true },
          defaultType: CodeLineType,
        });
      };
    },
    applyHorizontalRule: () => {
      return ({ editor }: { editor: Editor }) => {
        insertHorizontalRule(editor);
      };
    },
    removeHighestBlock: () => {
      return useStoreCallback((get) => ({ editor }: { editor: Editor }) => {
        const options = getActionsOptions(get);

        Transforms.removeNodes(editor, {
          mode: "highest",
          match: (node, path) =>
            options.match
              ? Editor.isBlock(editor, node) && options.match(node, path)
              : Editor.isBlock(editor, node),
          at: options.at,
        });
      });
    },
    insertSnippet: () => {
      return (
        { editor }: { editor: Editor },
        payload: { content: Descendant[] }
      ) => {
        const { content } = payload;

        _insertTemplate(editor, editor.selection, content);
      };
    },
    insertNewPageBacklink: () => {
      return (
        { editor }: { editor: Editor },
        payload: {
          params: MakeDocumentAsBacklinkParams;
        }
      ) => {
        const { pagesStore } = store;

        pagesStore.createNewDocumentAsBacklink(payload.params);

        const element = makePageBacklink(payload.params.userId, {
          documentId: payload.params.id,
          initialText: payload.params.title,
        });
        insertBacklink(editor, element);
      };
    },
    insertPageBacklink: () => {
      return (
        { editor }: { editor: Editor },
        payload: {
          userId: string;
          params: MakePageBacklinkParams;
        }
      ) => {
        const element = makePageBacklink(payload.userId, payload.params);
        insertBacklink(editor, element);
      };
    },
    insertNoteBacklink: () => {
      return (
        { editor }: { editor: Editor },
        payload: {
          params: MakeNoteBacklinkParams;
          userId: string;
        }
      ) => {
        const element = makeNoteBacklink(payload.userId, payload.params);
        insertBacklink(editor, element);
      };
    },
    insertTag: () => {
      return (
        { editor }: { editor: Editor },
        payload: {
          documentId: string;
          documentType: DocumentType;
          tag: Tag;
        }
      ) => {
        const { documentId, documentType, tag } = payload;

        const documentsStore = getDocumentsStore(documentType);

        const tagElement = mapTagToTagElement(tag);
        documentsStore.addTag(documentId, tag.id);

        insertTagElement(editor, tagElement);
      };
    },
    insertNewTag: () => {
      return (
        { editor }: { editor: Editor },
        payload: {
          documentId: string;
          documentType: DocumentType;
          userId: string;
          title: string;
        }
      ) => {
        const { documentId, documentType, userId, title } = payload;

        const { tagsStore } = store;
        const documentsStore = getDocumentsStore(documentType);

        const tag = makeTag({ userId, title });
        tagsStore.createNewTag(tag);

        documentsStore.addTag(documentId, tag.id);
        const tagElement = mapTagToTagElement(tag);

        insertTagElement(editor, tagElement);
      };
    },
    insertTimestamp: () => {
      return ({ editor }: { editor: Editor }, payload: { text: string }) => {
        insertDate(editor, payload.text);
      };
    },
    applyMark: () => {
      return (
        { editor }: { editor: Editor },
        payload: { markType: MarkType }
      ) => {
        toggleMark(editor, { key: payload.markType });
      };
    },
    startTerminal: () => {
      return useStartTerminal();
    },
    endTerminal: () => {
      return useEndTerminal();
    },
    copyToNewNote: () => {
      return useCopyToNewNote();
    },
    copyToExistingNote: () => {
      return useCopyToExistingNote();
    },
    duplicate: () => {
      return useDuplicate();
    },
    copyBlocks: () => {
      return useCopyBlocks();
    },
    pasteBlocks: () => {
      return usePasteBlocks();
    },
    moveBlocks: () => {
      return useMoveBlocks();
    },
    moveBlocksToEdge: () => {
      return useMoveBlocksToEdge();
    },
    moveSelectionToTop: () => {
      return useMoveSelectionToTop();
    },
    moveSelectionToBottom: () => {
      return useMoveSelectionToBottom();
    },
    addEmptyBlock: () => {
      return useAddEmptyBlock();
    },
  };
};
/* eslint-enable  react-hooks/rules-of-hooks */

type Hooks = ReturnType<typeof useActionsHooks>;

type EditorActions = {
  [K in keyof Hooks]: (...params: Parameters<ReturnType<Hooks[K]>>) => void;
};

export const EditorActions = {} as EditorActions;

export const EditorActionsHandler = () => {
  const hooks = useActionsHooks();

  const entries = Object.entries(hooks).sort((a, b) =>
    a[0].localeCompare(b[0])
  );

  for (const [key, hook] of entries) {
    EditorActions[key] = hook();
  }

  useCopyBlocksEffect();

  return null;
};

export const getActionsOptions = (get: <T>(value: RecoilValue<T>) => T) => {
  const isTerminalActive = get(TerminalStore.isTerminalActive);

  const options: ActionOptions = {};
  if (isTerminalActive) {
    const selection = get(TerminalStore.activeSelection);
    const pointer = get(TerminalStore.activePointer);

    options.match = (element) =>
      Element.isElement(element) &&
      (selection.has(element.id) || element.id === pointer);
    options.at = [];
  }

  return options;
};
