import { AuthAccessTypes } from "auth/types";

export type Feedback = {
  id: string;
  userId: string;
  email: string;
  firstName: string | null;
  lastName: string | null;
  userCreatedAt: Date | null;
  updatedAt?: Date | null;
  authAccessType: AuthAccessTypes;
  type: "poll" | "feedback";
} & FeedbackResult;

export type FeedbackResult = {
  dismiss?: boolean;
  mood?: FeedbackMood | null;
  text?: string | null;
};

export enum FeedbackMood {
  Excited = "Excited",
  Happy = "Happy",
  Neutral = "Neutral",
  Confused = "Confused",
  Upset = "Upset",
  Angry = "Angry",
}
