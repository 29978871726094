import React, { useState, useRef, useCallback } from "react";
//  @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";

import Button from "framework/components/form/Button";
import useNotifications from "hooks/useNotifications";
import Avatar from "framework/components/Avatar";
import { getErrorInfo } from "./errors";
import { getScaledImage } from "./utils";
import * as db from "./db";

import styles from "./index.module.scss";

const ProfilePhoto = ({ user }: any) => {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [saving, setSaving] = useState(false);

  const { addNotification } = useNotifications();
  const { t } = useTranslation();

  const handleSave = useCallback(async () => {
    const editor = editorRef.current;
    if (!editorRef.current) {
      return;
    }

    setSaving(true);

    const image = getScaledImage(editor).toDataURL();

    try {
      await db.uploadImage(user.id, image);

      if (inputRef.current) {
        //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
        inputRef.current.value = "";
      }
      setFile(null);

      addNotification({
        duration: 2500,
        type: "success",
        text: "Your profile photo successfully uploaded",
      });
      setSaving(false);
    } catch (error) {
      console.error(error);
      const errorInfo = getErrorInfo(error);

      addNotification({
        duration: 2500,
        type: "error",
        text: t(errorInfo.message),
      });
      setSaving(false);
    }
  }, [file, user]);

  const handleRemove = useCallback(async () => {
    await db.removeImage(user.id);
  }, [user]);

  return (
    <div>
      <Dropzone
        onDrop={(acceptedFiles) => {
          //  @ts-ignore ts-migrate(2345) FIXME: Argument of type 'T' is not assignable to paramete... Remove this comment to see the full error message
          setFile(acceptedFiles[0]);
        }}
        noClick
        multiple={false}
        //  @ts-ignore ts-migrate(2322) FIXME: Type '{ children: ({ getRootProps, getInputProps }... Remove this comment to see the full error message
        style={{
          width: 200,
          height: 200,
          marginBottom: "35px",
        }}
      >
        {({ getRootProps, getInputProps }) => {
          const inputProps = getInputProps();

          return (
            <div className={styles.container} {...getRootProps()}>
              <div className={styles.avatarContainer}>
                {file ? (
                  <AvatarEditor
                    ref={editorRef}
                    image={file}
                    width={135}
                    height={135}
                    border={15}
                    borderRadius={150}
                    color={[70, 70, 70, 0.5]}
                    scale={1}
                    rotate={0}
                  />
                ) : (
                  <div className={styles.avatarContainer}>
                    <Avatar type="profile" avatarUrl={user.avatarUrl} />
                  </div>
                )}
              </div>

              <div className={styles.buttons}>
                <label className={styles.customFileUpload}>
                  <input
                    type="file"
                    //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
                    onChange={(e) => setFile(e.target.files[0])}
                    accept="image/*"
                    {...inputProps}
                    ref={(ref) => {
                      //  @ts-ignore ts-migrate(2339) FIXME: Property 'ref' does not exist on type 'DropzoneInp... Remove this comment to see the full error message
                      inputProps.ref.current = ref;
                      //  @ts-ignore ts-migrate(2322) FIXME: Type 'HTMLInputElement | null' is not assignable t... Remove this comment to see the full error message
                      inputRef.current = ref;
                    }}
                  />
                  <div className={styles.uploadButton}>
                    <Button
                      variant="secondary"
                      onClick={(e: any) => e.preventDefault()}
                    >
                      {user.avatarUrl ? "Upload New" : "Upload Photo"}
                    </Button>
                  </div>
                </label>
                {file && (
                  <Button
                    variant="secondary"
                    onClick={handleSave}
                    loading={saving}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          );
        }}
      </Dropzone>
    </div>
  );
};

export default ProfilePhoto;
