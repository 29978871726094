const DEFAULT_MB = 5;
const DEFAULT_IMAGE_FILE_SIZE = DEFAULT_MB * 1024 * 1024;
const DEFAULT_GIF_MB = 15;
const DEFAULT_GIF_FILE_SIZE = DEFAULT_GIF_MB * 1024 * 1024;

const defaultValidator = (file: any) => {
  if (file.type === "image/gif") {
    if (file.size > DEFAULT_GIF_FILE_SIZE) {
      return {
        code: "file-too-big",
        message: `Gif must be less than ${DEFAULT_GIF_MB}MB`,
      };
    }
  } else if (file.size > DEFAULT_IMAGE_FILE_SIZE) {
    return {
      code: "file-too-big",
      message: `File must be less than ${DEFAULT_MB}MB`,
    };
  }
};

const DEFAULT_ACCEPT = ["image/jpeg", "image/png", "image/gif"];

export { defaultValidator, DEFAULT_ACCEPT };
