import React from "react";
import ReactModal from "react-modal";
import cn from "classnames";

import logoSm from "images/logo_sm.svg";
import logoNigelSm from "images/logo_sm_nigel.svg";

import styles from "./index.module.scss";

ReactModal.setAppElement("#root");

export type ModalProps = {
  title?: string;
  isOpen?: boolean;
  close?: (payload: any) => void;
  contentClass?: string;
  overlayClass?: string;
  titleClass?: string;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  children?: React.ReactNode;
  mascot?: "julian" | "nigel" | null;
} & ReactModal.Props;

const Modal = (props: ModalProps) => {
  const {
    title,
    isOpen = false,
    close,
    contentClass,
    overlayClass,
    titleClass,
    leftActions,
    rightActions,
    children,
    mascot,
    closeTimeoutMS = 300,
    ...rest
  } = props;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => close(null)}
      className={cn(styles.modalContent, contentClass)}
      overlayClassName={cn(styles.modalOverlay, overlayClass)}
      closeTimeoutMS={closeTimeoutMS}
      {...rest}
    >
      {mascot === "julian" && <img className={styles.mascot} src={logoSm} />}
      {mascot === "nigel" && (
        <img className={styles.mascot} src={logoNigelSm} />
      )}
      <div className={styles.modalScroll}>
        {title && <div className={cn(styles.title, titleClass)}>{title}</div>}
        {typeof children === "function" ? children(props) : children}
        <div className={styles.actions}>
          <div>{leftActions}</div>
          <div>{rightActions}</div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;
