import {
  collection,
  doc,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { db } from "firebaseInstance";
import {
  deleteTransaction,
  getCollectionData,
  getDocumentData,
  updateTransaction,
} from "db/utils";
import { getTaggedBlocks } from "db/blocks/blocks.queries";
import { crawlContent, mapContentFromJSON, mapContentToJSON } from "helpers";
import { Element, Text } from "slate";
import { getTaggedDocument } from "db/documents/documents.queries";
import { isTagElement } from "components/slate/plugins/tag/utils";
import {
  Block,
  BLOCK_CONVERTER,
  Document,
  DOCUMENT_CONVERTER,
  getBlockPath,
  getDocumentPath,
  getTagPath,
  getTagsPath,
  Tag,
  TAG_CONVERTER,
} from "thunk-core";

export const getUserTagsQuery = (userId: string) =>
  query(
    collection(db, getTagsPath()),
    where("userId", "==", userId)
  ).withConverter(TAG_CONVERTER);

export const getTag = async (tagId: string): Promise<Tag | null> => {
  return await getDocumentData(
    doc(db, getTagPath(tagId)).withConverter(TAG_CONVERTER)
  );
};

export const getUserTags = async (userId: string) => {
  return getCollectionData(getUserTagsQuery(userId));
};

export const setTag = async ({ tag }: { tag: Tag }) => {
  await setDoc(doc(db, getTagPath(tag.id)).withConverter(TAG_CONVERTER), tag);
};

export const updateTag = async ({
  tagId,
  userId,
  updates,
}: {
  tagId: string;
  userId: string;
  updates: Partial<Tag>;
}) => {
  await updateDoc(doc(db, getTagPath(tagId)).withConverter(TAG_CONVERTER), {
    userId,
    ...updates,
  });
};

export const removeTags = async (userId: string, tags: Tag[]) => {
  const removed: string[] = [];
  const notRemoved: string[] = [];
  for (const tag of tags) {
    const { id: tagId } = tag;
    try {
      const documents = await getTaggedDocument(userId, tagId);
      const blocks = await getTaggedBlocks(userId, tagId);

      await runTransaction(db, async (trx) => {
        deleteTransaction(trx, doc(db, getTagPath(tagId)));

        for (const document of documents) {
          const updates: Partial<Document> = {};
          const { tags } = document;

          updates.tags = tags.filter((x) => x !== tagId);

          updateTransaction(
            trx,
            doc(db, getDocumentPath(document.id)),
            updates,
            DOCUMENT_CONVERTER
          );
        }

        for (const block of blocks) {
          const updates: Partial<Block> = {};
          const { tags, contentJSON } = block;

          updates.tags = tags.filter((x) => x !== tagId);
          const content = mapContentFromJSON(contentJSON);

          crawlContent(content, (node, context) => {
            if (
              Element.isElement(context.parent) &&
              isTagElement(node) &&
              node.tagId === tagId
            ) {
              const newNode: Text = { text: tag.title };
              context.parent.children[context.index] = newNode;
              context.replace(newNode);
            }
          });

          updates.contentJSON = mapContentToJSON(content);

          updateTransaction(
            trx,
            doc(db, getBlockPath(block.id)),
            updates,
            BLOCK_CONVERTER
          );
        }
      });

      removed.push(tagId);
    } catch (error) {
      notRemoved.push(tagId);
    }
  }

  return { removed, notRemoved };
};
