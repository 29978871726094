import React from "react";
import cn from "classnames";
import { ArrowRightIcon } from "thunk-icons";

import ButtonGroup from "framework/components/form/ButtonGroup";
import Button from "framework/components/form/Button";
import TasksPopperContent from "./TasksPopperContent";
import useAppPopper from "framework/components/poppers/useAppPopper";
import AppPopper from "framework/components/poppers/AppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import SimpleTooltip from "components/SimpleTooltip";

import commonStyles from "../../index.module.scss";
import styles from "./index.module.scss";

type Props = {
  isActive: boolean;
  setActive: () => void;
  hideCompleted: boolean;
  setHideCompleted: React.Dispatch<React.SetStateAction<boolean>>;
};

const BacklinksTabButton = (props: Props) => {
  const { hideCompleted, setHideCompleted, isActive, setActive } = props;

  const popperProps = useAppPopper({ offset: [0, 13], hasArrow: true });
  useClickPopper(popperProps);

  return (
    <div style={{ order: 2 }}>
      <AppPopper
        {...popperProps}
        className={styles.popperContainer}
        content={
          <TasksPopperContent
            hideCompletedTasks={hideCompleted}
            setHideCompletedTasks={setHideCompleted}
          />
        }
      />

      <ButtonGroup
        className={cn({
          [commonStyles.activeTab]: isActive,
        })}
      >
        <SimpleTooltip content="Switch Tab (Ctrl+7)">
          <Button
            variant="circular"
            size="large"
            onClick={setActive}
            btnClass={styles.tabButton}
          >
            To Do
          </Button>
        </SimpleTooltip>

        <Button
          btnClass={cn(styles.tabButton, styles.carat, {
            [styles.hovered]: popperProps.isOpened,
          })}
          variant="circular"
          size="symbol"
          ref={popperProps.setTargetElement}
          icon={ArrowRightIcon}
          iconSize={14}
          onMouseDown={() => {
            setActive();
          }}
        />
      </ButtonGroup>
    </div>
  );
};

export default BacklinksTabButton;
