import { Editor, Transforms, Path } from "slate";

import { getRangeBefore } from "../queries/getRangeBefore";
import { isUrl } from "utils/isUrl";
import { createLinkElement, isLinkElement } from "../utils";

const matchUrlRegex = /(?:^|\s)(((https?:\/\/)|(www\.))[^\s]+)$/;

const withProtocol = (url: any) => {
  if (url.indexOf("http://") !== 0 && url.indexOf("https://") !== 0) {
    return `http://${url}`;
  }

  return url;
};

export const insertLink = (editor: Editor, url: string) => {
  Transforms.insertNodes(editor, createLinkElement({ url, text: url }));
  // move selection offset to continue editing text instead a link
  Transforms.move(editor, { unit: "offset" });
};

export const applyLinkToSelection = (editor: Editor, url: string) => {
  url = withProtocol(url);

  // check if selection includes inline elements, but not links (we just unwrap it before new wrapping)
  const [inlinesMatch] = Editor.nodes(editor, {
    mode: "lowest",
    match: (node) => Editor.isInline(editor, node) && !isLinkElement(node),
  });

  if (!inlinesMatch && editor.selection) {
    let at;
    at = Editor.unhangRange(editor, editor.selection);
    // prevent nested links
    Transforms.unwrapNodes(editor, {
      match: isLinkElement,
      at,
    });

    at = Editor.unhangRange(editor, editor.selection);
    Transforms.wrapNodes(editor, createLinkElement({ url }), {
      split: true,
      at,
    });
    Transforms.collapse(editor, { edge: "end" });
  }
};

export const applyLinkToTextBefore = (editor: Editor) => {
  // get range before current selection which is match regex
  const result = getRangeBefore(editor, {
    regexp: matchUrlRegex,
    capturedGroupIndex: 1,
  });

  if (result) {
    const { range } = result;

    // check if selection includes inline elements
    const [inlinesMatch] = Editor.nodes(editor, {
      at: range,
      mode: "lowest",
      match: (node) => Editor.isInline(editor, node),
    });

    const url = Editor.string(editor, range).trim();

    if (!inlinesMatch && isUrl(url)) {
      // wrap matched range into link
      Transforms.wrapNodes(editor, createLinkElement({ url }), {
        split: true,
        at: range,
      });
      // move selection offset to continue editing text instead a link
      Transforms.move(editor, { unit: "offset" });
    }
  }
};

export const updateLinkAtPath = (editor: Editor, path: Path, url: string) => {
  Transforms.setNodes(editor, { url }, { at: path, match: isLinkElement });
};

export const removeLinkAtPath = (editor: Editor, path: Path) => {
  Transforms.unwrapNodes(editor, { match: isLinkElement, at: path });
};
