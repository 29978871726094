export const appShortcuts = {
  search: "mod+p",
  toggleSidebar: "ctrl+0",
  toggleHelpSection: "ctrl+6",
  toggleComponentsMenu: "",
  toggleTerminal: "mod+/",
  saveNotification: "mod+s",
  toggleSidePanel: "mod+'",
  toggleSidepanelTab: "ctrl+7",
  toggleTodoItem: "mod+enter",
};
