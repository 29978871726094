import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAuth } from "auth/AuthProvider";
import { AuthAccessTypes } from "auth/types";
import useDialog from "hooks/useDialog";
import Modal from "framework/components/Modal";
import Button from "framework/components/form/Button";

const ExpiredModal = React.memo(() => {
  const { t } = useTranslation();
  const { authAccessType } = useAuth();
  const expiredDialog = useDialog(true);

  const expired =
    authAccessType === AuthAccessTypes.subscriptionExpired ||
    authAccessType === AuthAccessTypes.trialExpired;

  if (!expired) {
    return null;
  }

  return (
    <Modal
      {...expiredDialog}
      title={t("subscription.expired_modal.title")}
      mascot="nigel"
      rightActions={
        <Button variant="primary" as={Link} to="/pricing">
          {t("subscription.buttons.choose_plan")}
        </Button>
      }
    >
      <div>
        {authAccessType === AuthAccessTypes.trialExpired
          ? t("subscription.expired_modal.message_trial")
          : t("subscription.expired_modal.message")}
      </div>
    </Modal>
  );
});

export default ExpiredModal;
