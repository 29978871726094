import React from "react";
import { Element } from "slate";
import { useFocused } from "slate-react";

import { isParagraphElement } from "components/slate/plugins/paragraph/utils";
import DefaultPlaceholder from "components/slate/components/DefaultPlaceholder";
import {
  getHeaderLevel,
  isHeadingElement,
} from "components/slate/plugins/heading/utils";

import styles from "./index.module.scss";

type PlaceholderProps = {
  element: Element;
};

export const Placeholder = (props: PlaceholderProps) => {
  const { element } = props;

  const focused = useFocused();

  const showPlaceholder =
    focused && (isParagraphElement(element) || isHeadingElement(element));

  if (!showPlaceholder) {
    return null;
  }

  return (
    <div
      contentEditable={false}
      data-slate-clipboard-skip={true}
      className={styles.placeholder}
    >
      {isParagraphElement(element) && <DefaultPlaceholder />}
      {isHeadingElement(element) && (
        <div className={`placeholder-h${getHeaderLevel(element)}`}>Heading</div>
      )}
    </div>
  );
};
