import React, { useRef, useEffect } from "react";
import isHotkey from "is-hotkey";

import { formattingMenuModes } from "components/slate/plugins/menus/formatting/constants";
import { LinksToolbarInput } from "components/slate/plugins/menus/formatting/_shared/LinksToolbarInput";
import { focusEditorAtSelection } from "components/slate/utils";
import useApplyFormatting from "components/slate/plugins/menus/formatting/FormattingMenu/useApplyFormatting";
import { FormattingTypes } from "components/slate/types/formatting";

const LinksToolbar = ({ lastSelection, setMode, editor }: any) => {
  const ref = useRef(null);
  const applyFormatting = useApplyFormatting();

  const handleKeyDown = (e: any) => {
    const value = e.target.value;
    if (isHotkey("Enter", e)) {
      if (value.trim() !== "") {
        e.preventDefault();
        applyFormatting(editor, FormattingTypes.ELEMENT_LINK, {
          selection: lastSelection,
          url: value,
        });
      }
      setMode(formattingMenuModes.formatting);
    } else if (isHotkey("Escape", e)) {
      setMode(formattingMenuModes.formatting);
      focusEditorAtSelection(editor, lastSelection);
    }
  };

  const handleRemove = () => {
    setMode(formattingMenuModes.formatting);
    focusEditorAtSelection(editor, lastSelection);
  };

  useEffect(() => {
    //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
    ref.current && ref.current.focus();
  }, []);

  return (
    <LinksToolbarInput
      ref={ref}
      //  @ts-ignore ts-migrate(2322) FIXME: Type '{ ref: MutableRefObject<null>; onKeyDown: (e... Remove this comment to see the full error message
      onKeyDown={handleKeyDown}
      onRemove={handleRemove}
    />
  );
};

export default LinksToolbar;
