import { OnboardingProvider } from "pages/app/OnboardingPage/OnboardingProvider";

import styles from "./index.module.scss";

const OnboardingLayout = ({ children }: any) => {
  return (
    <OnboardingProvider>
      <div className={styles.container}>{children}</div>
    </OnboardingProvider>
  );
};

export default OnboardingLayout;
