import { v4 as uuid } from "uuid";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "firebaseInstance";

const getImagePath = (userId: string, imageId: string, ext: string) => {
  return `users/${userId}/media/${imageId}${ext}`;
};

export const uploadImage = async (userId: string, image: any) => {
  const extensionPattern = /\.[0-9a-z]+$/i;

  const ext = image?.name?.match(extensionPattern)[0];
  const id = uuid();
  const path = getImagePath(userId, id, ext);

  const imageRef = ref(storage, path);

  const snapshot = await uploadBytes(imageRef, image);
  const url = await getDownloadURL(snapshot.ref);

  return { url, id };
};
