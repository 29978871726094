import { makeAutoObservable, runInAction, toJS } from "mobx";

import { getUserTags, removeTags, setTag } from "db/tags/tags.queries";
import { Timestamp } from "firebase/firestore";
import TagsSynchronizer, { TagSyncData } from "stores/tagsSynchronizer";
import { makeTag } from "db/tags/tags.mapping";
import { Tag } from "thunk-core";

export default class TagsStore {
  tags = new Map<string, Tag>();
  loading = true;
  error = null;

  private synchronizer: TagsSynchronizer;

  constructor() {
    this.synchronizer = new TagsSynchronizer(
      (tagId: string, data: TagSyncData) => this.synchronize(tagId, data)
    );
    makeAutoObservable(this);
  }

  getTagsByIds(tagIds: string[]) {
    return tagIds.map((id) => this.getTag(id)).filter(Boolean);
  }

  getTags() {
    return Array.from(this.tags.values());
  }

  getTag(tagId: string) {
    return this.tags.get(tagId);
  }

  setTag(tag: Tag) {
    this.tags.set(tag.id, tag);
  }

  async loadAllTags(userId) {
    try {
      this.tags.clear();
      this.loading = true;
      const tags = await getUserTags(userId);

      runInAction(() => {
        for (const tag of tags) {
          this.tags.set(tag.id, tag);
        }
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = error;
        this.loading = false;
      });
    }
  }

  createNewTag(tag: Tag): string {
    const { title } = tag;

    const tags = this.getTags();
    const parts = title.split("/").filter((x) => x.trim() !== "");

    let base = "";
    // automatically create parent tags if they don't exist
    for (const part of parts.slice(0, -1)) {
      const title = base + part;
      if (tags.every((x) => x.title !== title)) {
        const parentTag = makeTag({ userId: tag.userId, title });
        this.setTag(parentTag);
        this.synchronizer.notifyWorker(parentTag.id, { tagChanged: true });
      }
      base += part + "/";
    }

    this.setTag(tag);
    this.synchronizer.notifyWorker(tag.id, { tagChanged: true });
    return tag.id;
  }

  updateTag(tagId: string, updates: Partial<Tag>) {
    const tag = this.getTag(tagId);

    if (!tag) {
      return;
    }

    Object.assign(tag, updates, { updatedAt: Timestamp.now() });
    this.synchronizer.notifyWorker(tag.id, { tagChanged: true });
  }

  async removeTagsRemote(userId: string, ids: string[]) {
    const tags: Tag[] = [];
    for (const tagId of ids) {
      tags.push(this.getTag(tagId));
    }

    const result = await removeTags(userId, tags.filter(Boolean));

    runInAction(() => {
      for (const tagId of result.removed) {
        this.tags.delete(tagId);
      }
    });

    return result;
  }

  async synchronize(tagId: string, data: TagSyncData) {
    const tag = this.getTag(tagId);

    if (!tag) {
      return;
    }

    if (data.tagChanged) {
      await setTag({ tag: toJS(tag) });
    }
  }
}
