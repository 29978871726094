import React from "react";

import AppResult, { GoHomeButton } from "components/results/AppResult";

const NotFoundPage = () => {
  return (
    <div style={{ height: "calc(100vh - 80px)" }}>
      <AppResult
        isCenteredContent={true}
        resultType="notFound"
        actions={<GoHomeButton />}
      />
    </div>
  );
};

export default NotFoundPage;
