import { createContext, useContext } from "react";

import PagesStore from "./pagesStore";
import BlockStore from "./blocksStore";
import NotesStore from "stores/notesStore";
import TemplatesStore from "stores/templatesStore";
import SidePanelStore from "stores/sidePanelStore";
import DocumentsManager from "stores/documentsManager";
import SyncMonitorStore from "stores/syncMonitorStore";
import TagsStore from "stores/tagsStore";
import { DocumentType } from "thunk-core";

interface Store {
  pagesStore: PagesStore;
  blocksStore: BlockStore;
  notesStore: NotesStore;
  templatesStore: TemplatesStore;
  sidePanelStore: SidePanelStore;
  tagsStore: TagsStore;
  documentsManager: DocumentsManager;
  syncMonitorStore: SyncMonitorStore;
}

export const store: Store = {
  pagesStore: new PagesStore(),
  blocksStore: new BlockStore(),
  notesStore: new NotesStore(),
  templatesStore: new TemplatesStore(),
  sidePanelStore: new SidePanelStore(),
  tagsStore: new TagsStore(),
  documentsManager: new DocumentsManager(),
  syncMonitorStore: new SyncMonitorStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}

export function getDocumentsStore(documentType: DocumentType) {
  switch (documentType) {
    case DocumentType.PAGE:
      return store.pagesStore;
    case DocumentType.NOTE:
      return store.notesStore;
    case DocumentType.SNIPPET:
      return store.templatesStore;
    default:
      throw new Error("To get store right document type must be specified");
  }
}

export function useDocumentsStore(documentType: DocumentType) {
  return getDocumentsStore(documentType);
}
