import { useCallback, useMemo, useState } from "react";

export type LoadableState<E = any> = {
  loading: boolean;
  error: E | null;
};

type Loadable<E = any> = {
  start: () => void;
  success: () => void;
  error: (error) => void;
  reset: () => void;
};

export const useLoadable = <E>(
  initialLoading: boolean = true
): [LoadableState<E>, Loadable<E>] => {
  const [{ loading, error }, setState] = useState<LoadableState<E>>({
    loading: initialLoading,
    error: null,
  });

  const updateState = useCallback(
    (updates: Partial<LoadableState<E>>) =>
      setState((state) => ({ ...state, ...updates })),
    []
  );

  const loadable = useMemo<Loadable<E>>(() => {
    return {
      start: () => updateState({ loading: true, error: null }),
      success: () => updateState({ loading: false }),
      error: (error) => updateState({ loading: false, error }),
      reset: () => updateState({ loading: false, error: null }),
    };
  }, []);

  return [{ loading, error }, loadable];
};
