import { Range } from "slate";
import create from "zustand";

export type SlashMenuState = {
  initialTarget: Range | null;
  target: Range | null;
  openMenu: (target: Range | null) => void;
  closeMenu: () => void;

  search: string;
  setSearch: (search: string, target: Range | null) => void;
};

const createState = () =>
  create<SlashMenuState>((set) => ({
    initialTarget: null,
    target: null,
    openMenu: (target) => set({ target, initialTarget: target }),
    closeMenu: () => set({ target: null, initialTarget: null, search: "" }),

    search: "",
    setSearch: (search, target) => set(() => ({ search, target })),
  }));

export default createState;
