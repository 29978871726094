import React from "react";
import TweetEmbed from "react-tweet-embed";

import { useThemeContext } from "providers/ThemeProvider";
import { ElementProps } from "components/slate/types";
import { EmbedElement } from "components/slate/plugins/embed/types";
import { EmbedTypes } from "components/slate/plugins/embed/constants";

const EmbedComponent = ({
  attributes,
  children,
  element,
}: ElementProps & { element: EmbedElement }) => {
  const { darkMode } = useThemeContext();

  switch (element.embed_type) {
    case EmbedTypes.Tweet:
      return (
        <div
          {...attributes}
          contentEditable={false}
          style={{ textAlign: "center" }}
        >
          <TweetEmbed
            id={element.data.tweetId}
            options={{ theme: darkMode ? "dark" : "light" }}
          />
          {children}
        </div>
      );
    default:
      return (
        <span {...attributes} contentEditable={false}>
          {children}
        </span>
      );
  }
};

export default EmbedComponent;
