/* eslint react-hooks/rules-of-hooks: 0 */
import { atom, selectorFamily } from "recoil";
import { Editor } from "slate";
import { useEffect, useMemo } from "react";
import produce from "immer";

import { useStoreCallback } from "state/utils";

class EditorsStoreClass {
  editors = atom<Map<string, Editor>>({
    key: "EditorsStore_editors",
    default: new Map<string, Editor>(),
    dangerouslyAllowMutability: true,
  });

  editor = selectorFamily<Editor | undefined, string>({
    key: "EditorsStore_editor",
    get: (slateId: string) => ({ get }) => {
      const editors = get(this.editors);
      return editors.get(slateId);
    },
    dangerouslyAllowMutability: true,
  });

  useEditorActions = () => {
    const setEditor = useStoreCallback(
      (get, set) => (slateId: string, editor: Editor) => {
        set(
          this.editors,
          produce((draft: any) => {
            draft.set(slateId, editor);
          })
        );
      }
    );

    const unsetEditor = useStoreCallback((get, set) => (slateId: string) => {
      set(
        this.editors,
        produce((draft: any) => {
          draft.delete(slateId);
        })
      );
    });

    return useMemo(
      () => ({
        setEditor,
        unsetEditor,
      }),
      []
    );
  };
}

export const EditorsStore = new EditorsStoreClass();

export const useEditorsMapEffects = (slateId: string, editor: Editor) => {
  const editorsActions = EditorsStore.useEditorActions();

  useEffect(() => {
    editorsActions.setEditor(slateId, editor);

    return () => {
      editorsActions.unsetEditor(slateId);
    };
  }, [slateId]);
};
