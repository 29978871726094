import { Editor, NodeEntry, Element, Node, Transforms } from "slate";
import { isListItemElement } from "../utils";

const makeNormalizeNode = (editor: Editor) => {
  const { normalizeNode } = editor;

  return (entry: NodeEntry) => {
    const [node, path] = entry;

    // If the element is a list, ensure its children are valid.
    // Currently, that means ensuring that all children are text nodes, or inline
    // elements. No block children are allowed.
    if (Element.isElement(node) && isListItemElement(node)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && !editor.isInline(child)) {
          Transforms.unwrapNodes(editor, { at: childPath, voids: true });
          return;
        }
      }
    }

    // Fall back to the original normalizeNode to enforce other constraints.
    normalizeNode(entry);
  };
};

export default makeNormalizeNode;
