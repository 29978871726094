import { withHistory } from "slate-history";
import { withReact } from "slate-react";

import useParagraphPlugin from "components/slate/plugins/paragraph/useParagraphPlugin";
import useHeadingPlugin from "components/slate/plugins/heading/useHeadingPlugin";
import useMarksPlugin from "components/slate/plugins/marks/useMarksPlugin";
import useBacklinkPlugin from "components/slate/plugins/backlink/useBacklinkPlugin";
import useHorizontalRulePlugin from "components/slate/plugins/horizontalRule/useHorizontalRulePlugin";
import useBlockquotePlugin from "components/slate/plugins/blockquote/useBlockquotePlugin";
import useExtendedPlugin from "components/slate/slate-extended/useExtendedPlugin";
import { compareLevels } from "components/slate/hooks/utils";
import useListPlugin from "components/slate/plugins/list/useListPlugin";
import useLinkPlugin from "components/slate/plugins/link/useLinkPlugin";
import useCodeBlockPlugin from "components/slate/plugins/codeBlock/useCodeBlockPlugin";
import useFilePlugin from "components/slate/plugins/file/useFilePlugin";
import useEmbedPlugin from "components/slate/plugins/embed/useEmbedPlugin";
import useMediaEmbedPlugin from "components/slate/plugins/mediaEmbed/useMediaEmbedPlugin";
import useTagPlugin from "components/slate/plugins/tag/useTagPlugin";
import useBlockPlugin from "components/slate/plugins/block/useBlockPlugin";

const useSimplePlugins = ({
  isMainEditor,
  userId,
}: {
  isMainEditor: boolean;
  userId?: string | null;
}) => {
  const plugins = [
    // block plugins
    useBlockPlugin({}),

    // elements
    useEmbedPlugin({}),
    useHorizontalRulePlugin({}),
    useBlockquotePlugin({}),
    useLinkPlugin({}),
    useCodeBlockPlugin({}),
    useFilePlugin({ userId }),
    useBacklinkPlugin({ isMainEditor }),
    useTagPlugin({}),
    useMarksPlugin({}),
    useListPlugin({}),
    useHeadingPlugin({}),
    useParagraphPlugin({}),
    useMediaEmbedPlugin({}),

    // core
    useExtendedPlugin({
      compareLevels,
    }),
    {
      withOverrides: withHistory,
    },
    {
      withOverrides: withReact,
    },
  ];

  return {
    plugins,
  };
};

export default useSimplePlugins;
