import { Descendant } from "slate";

import { EmbedTypes } from "components/slate/plugins/embed/constants";

export type EmbedType = "embed";
export const EmbedType: EmbedType = "embed";

export type EmbedElement = {
  id: string;
  type: EmbedType;
  data: any;
  embed_type: EmbedTypes;
  children: Descendant[];
};
