import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";

//  @ts-ignore ts-migrate(2307) FIXME: Cannot find module './index.module.scss' or its co... Remove this comment to see the full error message
import styles from "./index.module.scss";

const HelpSpinner = () => {
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsOpened(true), 100);

    return () => clearTimeout(timeout);
  }, []);

  if (!isOpened) {
    return null;
  }

  return (
    <div className={styles.spinnerContainer}>
      <BeatLoader color="var(--primary-color)" />
    </div>
  );
};

export default HelpSpinner;
