import React from "react";
import { useFocused, useSelected } from "slate-react";
import { observer } from "mobx-react-lite";

import { TagElement } from "components/slate/plugins/tag/types";
import { ElementProps } from "components/slate/types";
import Tag from "framework/components/Tag";
import UpdateTagMenu from "components/tags/UpdateTagMenu";
import { useStore } from "stores/store";
import { toJS } from "mobx";
import { useSharingContext } from "providers/SharingProvider";

const TagComponent = observer(
  ({
    attributes,
    children,
    element,
  }: ElementProps & { element: TagElement }) => {
    const sharing = useSharingContext();

    const selected = useSelected();
    const focused = useFocused();

    const { tagsStore } = useStore();

    return (
      <span
        {...attributes}
        contentEditable={false}
        style={{
          margin: "0 1px",
          verticalAlign: "baseline",
          fontSize: "1em",
          borderRadius: "3px",
          boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
        }}
      >
        {(function () {
          if (sharing) {
            const tag = sharing.tagsMap[element.tagId];

            if (tag) {
              return (
                <Tag
                  color={tag.color}
                  variant={tag.variant}
                  emoji={tag.emoji}
                  readOnly={true}
                  inline={true}
                >
                  {tag.title}
                </Tag>
              );
            }
          } else {
            const tag = toJS(tagsStore.tags.get(element.tagId));

            if (tag) {
              return (
                <UpdateTagMenu tag={tag}>
                  {({ ref }) => (
                    <Tag
                      containerRef={ref}
                      color={tag.color}
                      variant={tag.variant}
                      emoji={tag.emoji}
                      readOnly={true}
                      inline={true}
                    >
                      {tag.title}
                    </Tag>
                  )}
                </UpdateTagMenu>
              );
            }
          }

          return <span>[no title tag]</span>;
        })()}

        {children}
      </span>
    );
  }
);

export default TagComponent;
