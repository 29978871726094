import { useEffect } from "react";

function useOnClickOutside(_refs: any, handler: any) {
  const refs = Array.isArray(_refs) ? _refs : [_refs];

  useEffect(() => {
    const listener = (event: any) => {
      for (let ref of refs) {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
      }

      handler && handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [...refs, handler]);
}

export default useOnClickOutside;
