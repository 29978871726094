import React, { ReactHTML } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { useAuth } from "auth/AuthProvider";
import { RouteErrorBoundary } from "components/errors/RouteErrorBoundary";
import { AuthAccessTypes } from "auth/types";

type Props = Omit<RouteProps, "component"> & {
  layout: React.ComponentType<any>;
  component: React.ComponentType<any> | keyof ReactHTML;
  isAdminPage?: boolean;
  noDefaultStyles?: boolean;
  fullWidth?: boolean;
};

const AppRoute = ({
  layout: Layout,
  component: Component,
  isAdminPage,
  noDefaultStyles,
  fullWidth,
  ...rest
}: Props) => {
  const { isAuthenticated, authAccessType } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/signin" />;
  }

  if (isAdminPage && authAccessType !== AuthAccessTypes.admin) {
    return <Redirect to="/writing" />;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <Layout
            isAdminPage={isAdminPage}
            noDefaultStyles={noDefaultStyles}
            fullWidth={fullWidth}
          >
            <RouteErrorBoundary key={matchProps.match.path}>
              <Component {...matchProps} />
            </RouteErrorBoundary>
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
