import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import useWindowEventListener from "hooks/useWindowEventListener";

import styles from "./index.module.scss";

export const useVerticalTabPointer = (
  containerRef: any,
  activeClassName: any,
  deps: any
) => {
  const [top, setTop] = useState(null);
  const [height, setHeight] = useState(null);

  const updateState = useCallback((containerRef, activeClassName) => {
    const activeElement = containerRef.current.querySelector(
      `.${activeClassName}`
    );

    if (activeElement) {
      setTop(activeElement.offsetTop);
      setHeight(activeElement.offsetHeight);
    } else {
      setTop(null);
      setHeight(null);
    }
  }, []);

  useEffect(() => {
    updateState(containerRef, activeClassName);
  }, [updateState, containerRef, activeClassName, ...deps]);

  return { top, height };
};

export const VerticalTabPointer = ({ top, height, className }: any) => {
  if (!height) {
    return null;
  }

  return (
    <span
      style={{
        top: top == null ? "unset" : top - 1,
        height: height == null ? 0 : height,
      }}
      className={cn(styles.pointer, styles.verticalPointer, className)}
    />
  );
};

VerticalTabPointer.propTypes = {
  top: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export const useHorizontalTabPointer = (
  containerRef: any,
  activeClassName: any,
  deps: any
) => {
  const [left, setLeft] = useState(null);
  const [width, setWidth] = useState(null);

  const updateState = useCallback(() => {
    const activeElement = containerRef.current.querySelector(
      `.${activeClassName}`
    );

    if (activeElement) {
      setLeft(activeElement.offsetLeft);
      setWidth(activeElement.offsetWidth);
    } else {
      setLeft(null);
      setWidth(null);
    }
  }, [containerRef, activeClassName]);

  useWindowEventListener("resize", updateState, [updateState]);

  useEffect(() => {
    updateState();
  }, [updateState, ...deps]);

  return { left, width };
};

export const HorizontalTabPointer = ({ left, width, className }: any) => {
  if (!width) {
    return null;
  }

  return (
    <span
      style={{
        left: left == null ? "unset" : left,
        width: width == null ? 0 : width,
      }}
      className={cn(styles.pointer, styles.horizontalPointer, className)}
    />
  );
};

HorizontalTabPointer.propTypes = {
  left: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};
