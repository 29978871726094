import {
  Transforms,
  Editor,
  Element,
  Path,
  Range,
  Node,
  NodeEntry,
} from "slate";
import { ReactEditor } from "slate-react";

import { ListItemType, ListTypes } from "./types";
import { isListItemElement, isTodoListItemElement } from "./utils";
import { NestingElement } from "components/slate/slate-extended/types";
import { isParagraphElement } from "components/slate/plugins/paragraph/utils";
import { PieCheckboxStates } from "framework/components/form/PieCheckbox";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { ActionOptions } from "components/slate/actions/types";

export const moveItemsForward = (editor: Editor, _entries: NodeEntry[]) => {
  const entries = _entries.filter((entry) =>
    ExtendedEditor.isNestingElement(editor, entry[0])
  ) as NodeEntry<Element & NestingElement>[];

  const [firstEntry] = entries;
  if (firstEntry) {
    const path = firstEntry[1];

    const prevEntry = Path.hasPrevious(path)
      ? Editor.previous(editor, { at: path })!
      : null;

    if (prevEntry && isListItemElement(prevEntry[0])) {
      const [prevNode] = prevEntry;

      if (prevNode.depth - firstEntry[0].depth >= 0) {
        for (const entry of entries) {
          moveItemForward(editor, entry[0], entry[1]);
        }
      }
    }
  }
};

export const moveItemForward = (
  editor: Editor,
  node: NestingElement,
  path: Path
) => {
  Transforms.setNodes(editor, { depth: node.depth + 1 }, { at: path });
};

export const moveItemsBack = (editor: Editor, _entries: NodeEntry[]) => {
  const entries = _entries.filter((entry) =>
    ExtendedEditor.isNestingElement(editor, entry[0])
  ) as NodeEntry<Element & NestingElement>[];

  for (const entry of entries) {
    moveItemBack(editor, entry[0], entry[1]);
  }
};

export const moveItemBack = (
  editor: Editor,
  node: NestingElement,
  path: Path
) => {
  Transforms.setNodes(
    editor,
    { depth: Math.max(0, node.depth - 1) },
    { at: path }
  );
};

export const checkTodoItem = (
  editor: Editor,
  element: Element,
  checked: boolean
) => {
  const semanticDescendants = ExtendedEditor.semanticDescendants(
    editor,
    element
  );

  const path = ReactEditor.findPath(editor, element);
  const descendantsMatch = (node: Node) =>
    isTodoListItemElement(node) &&
    semanticDescendants.some((x) => x.element.id === node.id);

  Transforms.setNodes(
    editor,
    { checked },
    { match: (node) => node === element, at: path }
  );

  Transforms.setNodes(editor, { checked }, { at: [], match: descendantsMatch });
};

export const checkCheckListItem = (
  editor: Editor,
  element: Element,
  checked: boolean
) => {
  const path = ReactEditor.findPath(editor, element);

  Transforms.setNodes(
    editor,
    { checked },
    { match: (node) => node === element, at: path }
  );
};

export const assignDueDate = (
  editor: Editor,
  element: Element,
  dueDate: string | null
) => {
  const path = ReactEditor.findPath(editor, element);

  Transforms.setNodes(
    editor,
    { dueDate },
    { match: (node) => node === element, at: path }
  );
};

export const toggleList = (
  editor: Editor,
  { listType }: { listType: ListTypes },
  _options?: ActionOptions
) => {
  Editor.withoutNormalizing(editor, () => {
    const { selection } = editor;

    if (!selection) {
      return;
    }

    const options = _options ?? {};

    Transforms.setNodes(
      editor,
      { type: ListItemType, listType },
      {
        match: (element, path) =>
          isListItemElement(element) &&
          (options?.match ? options.match(element, path) : true),
        at: options.at,
      }
    );

    Transforms.setNodes(
      editor,
      { type: ListItemType, depth: 0, listType, state: PieCheckboxStates.auto },
      {
        match: (node, path) =>
          !isListItemElement(node) &&
          path.length === 1 &&
          (options?.match
            ? options.match(node, path)
            : isParagraphElement(node)),
        at: options.at,
      }
    );
  });
};
