import React, { useEffect, useRef } from "react";
import cn from "classnames";
import isHotkey from "is-hotkey";

import useWindowEventListener from "hooks/useWindowEventListener";

import styles from "./index.module.scss";

type Props = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
};

const FilterDrawer = ({ isOpened, setIsOpened, children }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useWindowEventListener(
    "keydown",
    (e) => {
      if ((isHotkey("Escape"), e)) {
        setIsOpened(false);
      }
    },
    []
  );

  useEffect(() => {
    isOpened && containerRef.current?.scrollTo(0, 0);
  }, [isOpened]);

  return (
    <div>
      <div
        className={cn(styles.drawerOverlay, {
          [styles.drawerOverlayClosed]: !isOpened,
        })}
        onClick={() => setIsOpened(false)}
      />
      <div
        ref={containerRef}
        className={cn(styles.drawer, {
          [styles.drawerClosed]: !isOpened,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default FilterDrawer;
