import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import cn from "classnames";
import { Editor, Transforms } from "slate";
import isHotkey from "is-hotkey";
import { isMobile } from "react-device-detect";

import { TodoListItemElement } from "components/slate/plugins/list/types";
import ToDoItemDueDateButton from "components/slate/plugins/list/components/ToDoItemDueDateButton";
import { ElementProps } from "components/slate/types";
import {
  ReactEditor,
  useFocused,
  useSelected,
  useSlate,
  useSlateStatic,
} from "slate-react";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import PieCheckbox, {
  PieCheckboxStates,
} from "framework/components/form/PieCheckbox";
import { checkTodoItem } from "components/slate/plugins/list/transforms";
import { appShortcuts } from "appShortcuts";
import { useHideCompleted } from "components/slate/hooks/useHideCompleted";
import { useActualValueSubscription } from "components/slate/editors/SyncSlateValue";

import styles from "./index.module.scss";

type TodoItemProps = ElementProps & {
  element: TodoListItemElement;
  readOnly: boolean;
};

export const TodoItem = (props: TodoItemProps) => {
  const editor = useSlate();
  useActualValueSubscription();

  const semanticNode = ExtendedEditor.semanticNode(editor, props.element);
  const dimmed = semanticNode.dimmed;
  const progress = semanticNode.progress;
  const progressState = semanticNode.progressState;
  const hideCompleted = useHideCompleted();

  return (
    <TodoItemMemoized
      {...props}
      dimmed={dimmed}
      progress={progress}
      progressState={progressState}
      hideCompleted={hideCompleted}
    />
  );
};

const TodoItemMemoized = memo(
  (
    props: TodoItemProps & {
      dimmed: boolean;
      progress: number;
      progressState: PieCheckboxStates;
      hideCompleted: boolean;
    }
  ) => {
    const editor = useSlateStatic();
    const focused = useFocused();
    const selected = useSelected();
    const timeoutRef = useRef<any>(null);
    const elementChecked = Boolean(props.element.checked);
    const [checked, setChecked] = useState(elementChecked);

    useLayoutEffect(() => {
      setChecked(elementChecked);
    }, [elementChecked]);

    const handleCheck = (checked: boolean) => {
      if (props.readOnly) {
        return;
      }

      clearTimeout(timeoutRef.current);
      setChecked(checked);

      if (props.hideCompleted && checked) {
        // if hideCompleted do check with timeout in order to display checked item for some time
        timeoutRef.current = setTimeout(() => {
          checkTodoItem(editor, props.element, checked);
        }, 240);
      } else {
        checkTodoItem(editor, props.element, checked);
      }

      if (!isMobile) {
        const path = ReactEditor.findPath(editor, props.element);

        // select and focus editor, to activate undo/redo, it also keeps sync editor and checkbox focused state
        Transforms.select(editor, Editor.end(editor, path));
        ReactEditor.focus(editor);
      }
    };

    const keydownListener = useRef<(e: KeyboardEvent) => void | null>(null);
    useEffect(() => {
      keydownListener.current = (e) => {
        if (focused && selected && isHotkey(appShortcuts.toggleTodoItem, e)) {
          handleCheck(!checked);
        }
      };
    });

    useEffect(() => {
      const listener = (e: KeyboardEvent) => keydownListener.current(e);
      window.addEventListener("keydown", listener);

      return () => window.removeEventListener("keydown", listener);
    }, []);

    return (
      <div
        {...props.attributes}
        className={cn(
          styles.listItem,
          styles[`listItem_${props.element.listType}`],
          {
            [styles.checked]: checked || props.dimmed,
          }
        )}
      >
        <span
          data-slate-clipboard-skip={true}
          contentEditable={false}
          className={cn(styles.checkboxPointer)}
        >
          <PieCheckbox
            checked={checked}
            state={props.progressState}
            progress={props.progress}
            onChange={(e, checked) => handleCheck(checked)}
          />
        </span>

        <div style={{ display: "flex" }}>
          <div className={styles.listItemText}>{props.children}</div>
          {/*<div contentEditable={false}>*/}
          {/*  {props.progressState} {String(props.element.checked)}{" "}*/}
          {/*  {props.progress}{" "}*/}
          {/*</div>*/}
          <DueDateButton element={props.element} />
        </div>
      </div>
    );
  }
);

const DueDateButton = (props: { element: TodoListItemElement }) => {
  return (
    <ToDoItemDueDateButton
      element={props.element}
      btnClassName={styles.dueDateButton}
    />
  );
};
