import { Editor, Node } from "slate";

import { store } from "stores/store";
import { addNotification } from "hooks/useNotifications";
import { mapContentFromJSON } from "helpers";
import { Block } from "thunk-core";

const getPathForBlock = (block: Block) => {
  if (block.dateId) {
    return `/writing?date=${block.dateId}`;
  } else {
    return `/page/${block.pageId}`;
  }
};

export const copyBlocklink = async (editor: Editor) => {
  try {
    const { blocksStore } = store;
    const [blockMatch] = Editor.nodes(editor, {
      mode: "highest",
      match: (node) => Editor.isBlock(editor, node),
    });
    if (blockMatch) {
      const [block] = blockMatch;
      const { id } = block as any;
      const blockData = blocksStore.getBlock(id);
      if (blockData) {
        const origin = window.location.origin;
        const content = mapContentFromJSON(blockData.contentJSON);
        const text = Node.string({ children: content } as any);
        let blockText = "";
        if (text.trim().length > 0) {
          blockText = text.length > 40 ? `${text.substring(0, 40)}...` : text;
        }
        const path = `${origin}${getPathForBlock(blockData)}#block:${
          blockData.id
        }${blockText.length > 0 ? `:${blockText}` : ""}`;
        await navigator.clipboard.writeText(path);
      }
    }
  } catch (error) {
    addNotification({
      type: "error",
      duration: 2500,
      text: "Your permission is required to write to clipboard.",
    });
  }
};
