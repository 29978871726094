import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAuth } from "auth/AuthProvider";
import { AuthAccessTypes } from "auth/types";
import { trialDaysAvailable } from "auth/helpers";

import styles from "./index.module.scss";

const ExpiredNotification = () => {
  const { authAccessType, currentUser, claims } = useAuth();
  const { t } = useTranslation();

  const getMessage = () => {
    if (authAccessType === AuthAccessTypes.trial) {
      return t("subscription.expired_notification.message_trialing", {
        days: trialDaysAvailable(currentUser, claims),
      });
    }

    if (authAccessType === AuthAccessTypes.trialExpired) {
      return t("subscription.expired_notification.message_trial_expired");
    }

    if (authAccessType === AuthAccessTypes.subscriptionExpired) {
      return t(
        "subscription.expired_notification.message_subscription_expired"
      );
    }

    return null;
  };

  const message = getMessage();

  useEffect(() => {
    message
      ? document.body.setAttribute("data-has-expired-notification", "true")
      : document.body.removeAttribute("data-has-expired-notification");
  }, [message]);

  useEffect(() => {
    return () => document.body.removeAttribute("data-has-expired-notification");
  }, []);

  if (!message) {
    return null;
  }

  return (
    <Link className={styles.container} to="/pricing">
      <span className={styles.link}>{message}</span>
    </Link>
  );
};

export default ExpiredNotification;
