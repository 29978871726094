import { Element, Node, Transforms, Text, Editor } from "slate";

import { makeNodeId } from "components/slate/config/makeNodeId";
import { CodeLineType } from "components/slate/plugins/codeBlock/types";
import {
  isCodeBlockElement,
  isCodeLineElement,
} from "components/slate/plugins/codeBlock/utils";

// this code fixes html pasting to codeblock
export const withCodeBlock = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (isCodeBlockElement(node)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && !isCodeLineElement(child)) {
          Transforms.setNodes(
            editor,
            { type: CodeLineType },
            { at: childPath }
          );
        }

        if (Text.isText(child)) {
          Transforms.wrapNodes(
            editor,
            // @ts-ignore
            { id: makeNodeId(false), type: CodeLineType, children: [] },
            { at: childPath }
          );
        }
      }
    }

    normalizeNode([node, path]);
  };

  return editor;
};
