import { useContext, useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import useFetch, { CachePolicies, FetchContext } from "use-http";

import { env } from "thunk-env";
import { useUserData } from "hooks/useUserData";
import { useCurrentUserId } from "db/currentUser";

const ENABLED = env.REACT_APP_ENV === "production";

export const IntercomConfig = () => {
  const userId = useCurrentUserId();
  const [user] = useUserData(userId);
  const fetchContext = useContext(FetchContext);
  const { boot } = useIntercom();
  const [key, setKey] = useState<string | undefined>(undefined);
  const [booted, setBooted] = useState(false);

  const getVerificationKey = useFetch("/auth/getIntercomIdentityKey", {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  useEffect(() => {
    async function getKey() {
      const verificationKey = await getVerificationKey.get();
      if (!verificationKey || verificationKey.error) return;
      localStorage.setItem(`${userId}_intercomIdentityKey`, verificationKey);
      setKey(verificationKey);
    }
    if (ENABLED && userId) {
      const cachedKey = localStorage.getItem(`${userId}_intercomIdentityKey`);
      if (cachedKey) {
        setKey(cachedKey);
      } else if (fetchContext.options.headers["Authorization"]) {
        getKey();
      }
    }
  }, [fetchContext.options.headers, userId]);

  useEffect(() => {
    if (ENABLED && user && key && !booted) {
      let fullName = "";
      if (user?.firstName) {
        fullName = user.firstName;
        if (user?.lastName) {
          fullName += ` ${user.lastName}`;
        }
      }
      fullName = !!fullName.trim() ? fullName : "No Name Set In App";

      boot({
        userId: user.uid,
        name: fullName,
        email: user.email,
        createdAt: user.createdAt,
        userHash: key,
      });
      setBooted(true);
    }
  }, [user, key, booted, boot]);

  return null;
};
