import { forwardRef } from "react";
import { Link } from "react-router-dom";

import Avatar from "framework/components/Avatar";
import { useCurrentUserData } from "db/user/user.hooks";

const ElectronAvatar = forwardRef<HTMLAnchorElement, {}>((props, ref) => {
  const { isLoading, data } = useCurrentUserData();

  const avatarUrl = isLoading ? null : data?.avatarUrl;

  return (
    <Link innerRef={ref} to="/account" style={{ height: 30, paddingLeft: 3 }}>
      <Avatar type="header" avatarUrl={avatarUrl} />
    </Link>
  );
});

export default ElectronAvatar;
