import React, { useRef, useEffect } from "react";
import isHotkey from "is-hotkey";

import { TemplatesToolbarInput } from "components/slate/plugins/menus/formatting/FormattingMenu/FormattingMenuToolbar/TemplatesToolbar/TemplatesToolbarInput";
import { formattingMenuModes } from "components/slate/plugins/menus/formatting/constants";
import { focusEditorAtSelection } from "components/slate/utils";
import useApplyFormatting from "components/slate/plugins/menus/formatting/FormattingMenu/useApplyFormatting";
import useDialog from "hooks/useDialog";
import { TemplatesTipModal } from "components/slate/plugins/templates/components/TemplatesTipModal";
import { FormattingTypes } from "components/slate/types/formatting";

const TemplatesToolbar = ({ lastSelection, setMode, editor }: any) => {
  const templatesTipDialog = useDialog();
  const ref = useRef(null);
  const applyFormatting = useApplyFormatting();

  const closeTooltip = () => {
    setMode(formattingMenuModes.formatting);
    focusEditorAtSelection(editor, lastSelection);
  };

  const saveTemplate = async (title: any) => {
    await applyFormatting(editor, FormattingTypes.ELEMENT_SNIPPET, {
      title,
      selection: lastSelection,
    });
    templatesTipDialog.open({ title });
    setMode(formattingMenuModes.formatting);
  };

  const handleKeyDown = async (e: any) => {
    if (isHotkey("Enter", e)) {
      saveTemplate(e.target.value);
    } else if (isHotkey("Escape", e)) {
      closeTooltip();
    }
  };

  useEffect(() => {
    //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
    ref.current && ref.current.focus();
  }, []);

  return (
    <>
      <TemplatesToolbarInput
        ref={ref}
        //  @ts-ignore ts-migrate(2322) FIXME: Type '{ ref: MutableRefObject<null>; onKeyDown: (e... Remove this comment to see the full error message
        onKeyDown={handleKeyDown}
        onRemove={closeTooltip}
      />
      <TemplatesTipModal templatesTipDialog={templatesTipDialog} />
    </>
  );
};

export default TemplatesToolbar;
