import React, { useState, useEffect, forwardRef } from "react";
import { Range, Selection } from "slate";
import { ReactEditor, useFocused, useSlate } from "slate-react";
import cn from "classnames";

import { PortalBody } from "framework/components/Portal";

import styles from "./index.module.scss";

type Props = {
  hidden?: boolean;
  width: number;
  selection: Selection;
  children: React.ReactNode;
};

const SelectionToolbar = forwardRef<HTMLDivElement, Props>(
  ({ hidden = false, width, selection, children }, ref) => {
    const editor = useSlate();

    const offsetY = 10;
    const offsetHeight = 32;
    const [position, setPosition] = useState<[number, number] | null>(null);

    useEffect(() => {
      if (!selection || !Range.isExpanded(selection)) {
        setPosition(null);
        return;
      }

      try {
        const domRange = ReactEditor.toDOMRange(editor, selection); // after formatting this line can throw an exception
        const rect = domRange?.getBoundingClientRect();

        if (!rect) {
          return;
        }

        const x = window.pageXOffset + rect.x + rect.width / 2 - width / 2;
        const y = window.pageYOffset + rect.y - offsetHeight - offsetY;

        setPosition([x, y]);
      } catch (error) {
        return;
      }
    }, [selection, width]);
    const [x, y] = position || ["unset", "unset"];

    return (
      <PortalBody>
        <div
          onMouseDown={(e) => e.preventDefault()}
          ref={ref}
          style={{
            width,
            height: position ? "auto" : 0,
            top: y,
            left: x,
          }}
          className={cn(styles.selectionToolbar, {
            [styles.visible]: position && !hidden,
          })}
        >
          {children}
        </div>
      </PortalBody>
    );
  }
);

export default SelectionToolbar;
