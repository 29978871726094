import React, { useCallback, useEffect, useMemo } from "react";
import { FavoritesIcon } from "thunk-icons";
import { observer } from "mobx-react-lite";
import { ascend, descend, sortWith } from "ramda";

import AppSidebarSection, {
  AppSidebarSectionEmpty,
} from "layout/AppLayout/_shared/AppSidebarSection";
import AppSidebarSectionItem from "layout/AppLayout/_shared/AppSidebarSectionItem";
import { useCurrentUserId } from "db/currentUser";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import { getDocumentsStore, useStore } from "stores/store";
import useNotify from "hooks/useNotify";
import { getEditorPathname } from "helpers";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import { Document, DocumentType } from "thunk-core";

const AppFavoritesComponent = observer(() => {
  const { documentsManager } = useStore();
  const userId = useCurrentUserId();
  const notify = useNotify();

  useEffect(() => {
    documentsManager.loadFavouriteDocuments(userId);
  }, []);

  const handleRemove = useCallback((e: any, document: Document) => {
    const documentsStore = getDocumentsStore(document.type);

    documentsStore.updateDocument(document.id, { isFavorite: false });
    notify.success("The page is removed from favorites");
  }, []);

  const documents = useMemo(() => {
    return sortWith(
      [
        descend<Document>((x) => x.type === DocumentType.NOTE),
        ascend<Document>((x) => x.title.toLowerCase()),
      ],
      documentsManager.favoriteDocuments
    );
  }, [documentsManager.favoriteDocuments]);

  return (
    <div>
      {documents && documents.length > 0 ? (
        documents.map((document) => {
          return (
            <AppSidebarSectionItem
              key={document.id}
              item={document}
              title={getDocumentTitle(document)}
              href={getEditorPathname(
                document.type,
                document.id,
                document.dateId
              )}
              onRemove={handleRemove}
            />
          );
        })
      ) : (
        <AppSidebarSectionEmpty />
      )}
    </div>
  );
});

const AppFavorites = () => {
  return (
    <WidgetErrorBoundary isEmptyFallback={true}>
      <AppSidebarSection
        title="Favorites"
        sectionId="favorites"
        icon={FavoritesIcon}
      >
        <AppFavoritesComponent />
      </AppSidebarSection>
    </WidgetErrorBoundary>
  );
};

export default AppFavorites;
