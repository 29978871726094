import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import { createPortal } from "react-dom";
import { descend, intersection, sortWith, uniqBy } from "ramda";

import NoLinksEmptyState from "components/editor/SidePanel/backlinks/empty/NoLinksEmptyState";
import NoBlockLinksEmptyState from "components/editor/SidePanel/backlinks/empty/NoBlockLinksEmptyState";
import BacklinksCard from "components/editor/SidePanel/backlinks/BacklinksCard";
import { useDocumentsStore, useStore } from "stores/store";
import CardSpinner from "framework/components/Card/CardSpinner";
import AppResult from "components/results/AppResult";
import { useCurrentUserId } from "db/currentUser";
import { useDocumentMatch } from "stores/hooks/useDocumentMatch";
import BacklinksTabButton from "components/editor/SidePanel/backlinks/BacklnksTabButton";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import {
  SidePanelTabType,
  useSidePanelContext,
} from "providers/SidePanelProvider";
import { useDocumentSettingsState } from "db/documentsSettings/documentsSettings.hooks";
import BacklinksPopperContent from "components/editor/SidePanel/backlinks/BacklnksTabButton/BacklinksPopperContent";
import { toggleSet } from "utils/toggleCollections";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { DocumentType } from "thunk-core";

import styles from "./index.module.scss";

type Props = {
  tabsContainer: HTMLDivElement | null;
};

const BacklinksContent = observer(({ tabsContainer }: Props) => {
  const userId = useCurrentUserId();

  const { activeTab, setActiveTab } = useSidePanelContext();
  const { matched, documentId, documentType } = useDocumentMatch();
  const { sidePanelStore } = useStore();
  const documentsStore = useDocumentsStore(documentType);

  const [
    { data: pinned, isError, error },
    setPinned,
  ] = useDocumentSettingsState(userId, documentId, "pinned");

  const backlinks = sortWith<BacklinkElement>(
    [descend((x) => pinned.has(x.targetId))],
    uniqBy((x) => x.targetId, documentsStore.getBacklinks(documentId))
  );

  const isLoading = documentsStore.getDocument(documentId) == null;

  const handleDocumentPin = useCallback(
    (targetId: string, isPinned: boolean) =>
      setPinned((pinned) => toggleSet(pinned, targetId, !isPinned)),
    [setPinned]
  );

  const handleBatchPin = useCallback(
    (hasPinned: boolean) => {
      setPinned(
        () => new Set(hasPinned ? [] : backlinks.map((x) => x.targetId))
      );
    },
    [setPinned, backlinks]
  );

  if (!matched) {
    return null;
  }

  return (
    <>
      {tabsContainer &&
        createPortal(
          <WidgetErrorBoundary isEmptyFallback={true}>
            <BacklinksTabButton
              isActive={activeTab === SidePanelTabType.Backlinks}
              setActive={() => setActiveTab(SidePanelTabType.Backlinks)}
              hasPopper={backlinks.length > 0}
              renderPopperContent={() => {
                return (
                  <BacklinksPopperContent
                    backlinks={backlinks}
                    pinned={pinned}
                    onDocumentPin={handleDocumentPin}
                    onBatchPin={handleBatchPin}
                  />
                );
              }}
            />
          </WidgetErrorBoundary>,
          tabsContainer
        )}
      {(function () {
        if (activeTab !== SidePanelTabType.Backlinks) {
          return null;
        }

        if (isLoading) {
          return <CardSpinner loading={isLoading} loadingTimeout={500} />;
        }

        if (isError) {
          console.error(error);
          return <AppResult resultType="fetching" isCenteredContent={true} />;
        }

        if (backlinks.length === 0) {
          return <NoLinksEmptyState />;
        }

        const { selectedBacklinks } = sidePanelStore;

        const filteredIds = new Set(
          intersection(
            [...selectedBacklinks, ...pinned],
            backlinks.map((x) => x.targetId)
          )
        );

        if (backlinks.length > 0 && filteredIds.size === 0) {
          return <NoBlockLinksEmptyState />;
        }

        return backlinks.map((backlink) => {
          const isVisible = filteredIds.has(backlink.targetId);
          const isPinned = pinned.has(backlink.targetId);

          return (
            <div
              className={styles.sidePanelCard}
              key={backlink.targetId}
              data-card-type="side-panel-card"
              style={{
                display: isVisible ? "block" : "none",
              }}
            >
              <BacklinksCard
                targetId={backlink.targetId}
                documentType={backlink.targetType || DocumentType.PAGE}
                isVisible={isVisible}
                isPinned={isPinned}
                onDocumentPin={() =>
                  handleDocumentPin(backlink.targetId, isPinned)
                }
              />
            </div>
          );
        });
      })()}
    </>
  );
});

export default BacklinksContent;
