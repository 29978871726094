import Button from "framework/components/form/Button";
import mascotsSuccess from "images/mascots_success.svg";
import { useOnboardingContext } from "../OnboardingProvider";
import { useHistory } from "react-router-dom";

import styles from "../index.module.scss";

const Success = () => {
  const history = useHistory();
  const { handleSubmit, submitting } = useOnboardingContext();
  return (
    <div className={styles.onboardingContainerSm}>
      <figure className={styles.onboardingImageContainer}>
        <img
          src={mascotsSuccess}
          alt="Nigel and Julian celebration"
          className={styles.onboardingImage}
        />
      </figure>

      <h3 className={styles.onboardingHeadlineLg}>Woohooooo!!!</h3>
      <p className={styles.onboardingCopy}>
        That's all for now, you're ready to get started.
      </p>

      <div style={{ marginTop: 50 }}>
        <Button
          variant="secondary"
          autoFocus
          onClick={handleSubmit}
          loading={submitting}
          disabled={submitting}
        >
          Let's get started
        </Button>
      </div>
      <div className={styles.easterEggLink}>
        <Button
          variant="ghost"
          onClick={() => history.push("/onboarding/badmascots")}
          btnClass={styles.linkBtn}
        >
          I hate stupid bubbly mascots!
        </Button>
      </div>
    </div>
  );
};

export default Success;
