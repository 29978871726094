import copyPreview from "images/note_copy.svg";
import { useLocation } from "react-router-dom";
import getForkData from "utils/getForkData";

import styles from "./index.module.scss";

const CopyPreview = () => {
  const location = useLocation();
  let forkData = getForkData();

  if (!forkData) {
    return null;
  }

  if (location.pathname.startsWith("/signup")) {
    if (!forkData.isNewUser) {
      forkData = {
        ...forkData,
        isNewUser: true,
      };
      window.sessionStorage.setItem("forkPage", JSON.stringify(forkData));
    }
  } else if (location.pathname.startsWith("/signin")) {
    if (forkData.isNewUser) {
      forkData = {
        ...forkData,
        isNewUser: false,
      };
      window.sessionStorage.setItem("forkPage", JSON.stringify(forkData));
    }
  }

  const previewText = forkData.isNewUser ? (
    <span>
      Set up an account to save your copy of <i>{`"${forkData.forkTitle}"`} </i>
    </span>
  ) : (
    <span>
      Sign in to save your copy of <i>{`"${forkData.forkTitle}"`} </i>
    </span>
  );

  return (
    <div className={styles.root}>
      <figure className={styles.copyPreviewImg}>
        <img
          src={copyPreview}
          alt={forkData.forkTitle}
          className={styles.onboardingImage}
        />
      </figure>
      <h3 className={styles.copyPreviewTitle}>{previewText}</h3>
    </div>
  );
};

export default CopyPreview;
