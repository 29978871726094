import { Editor } from "slate";

import { isTagElement, swapIds } from "./utils";

const withTag = () => (editor: Editor) => {
  const { insertFragment, isInline, isVoid } = editor;

  editor.insertFragment = (fragment: any) => {
    /**
     * If someone copies text from an editor that contains links
     * we need to give the pasted links new IDs and source information
     */
    const withReplacedLinkIds = swapIds({ children: fragment });
    insertFragment(withReplacedLinkIds.children);
  };

  editor.isInline = (element) => isTagElement(element) || isInline(element);
  editor.isVoid = (element) => isTagElement(element) || isVoid(element);

  return editor;
};

export { withTag };
