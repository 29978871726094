import { Descendant, Transforms, Element } from "slate";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { useSlateStatic } from "slate-react";

import { mapContentToJSON } from "helpers";

const ActualValueSubscriptionContext = createContext<Descendant[]>([]);

export const useActualValueSubscription = (): void => {
  const value = useContext(ActualValueSubscriptionContext);
  const [, forceRender] = useReducer((s) => s + 1, 0);

  useEffect(() => {
    forceRender();
  }, [value]);
};

export const SyncSlateValue = (props: {
  value: Descendant[];
  setValue: React.Dispatch<React.SetStateAction<Descendant[]>>;
  dragging: boolean;
  children: React.ReactNode;
}) => {
  const { value, setValue, children } = props;

  const editor = useSlateStatic();

  useEffect(() => {
    const isChanged =
      mapContentToJSON(editor.children) !== mapContentToJSON(value);

    if (isChanged) {
      // deselect to prevent errors after changing children
      Transforms.deselect(editor);

      // workaround: set children manually since value is used as initialValue in Slate 0.67.0 https://github.com/ianstormtaylor/slate/pull/4540
      editor.children = value as Element[];

      setValue(value);
    }
  }, [value]);

  return (
    <ActualValueSubscriptionContext.Provider value={value}>
      {children}
    </ActualValueSubscriptionContext.Provider>
  );
};
