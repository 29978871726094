import { useEffect, useMemo } from "react";

import Button from "framework/components/form/Button";
import { useSignUpContext } from "../SignUpProvider";
import julianLaptop from "images/julian_laptop.svg";

import formStyles from "../index.module.scss";

const names = [
  "Pork Chop",
  "Tater Tot",
  "Baby Face",
  "Buttercup",
  "Fuzzball",
  "Tickly Boo",
  "Giggles",
  "Coconut",
  "Dimples",
  "Cuddlebear",
  "Cutie Patootie",
];

const getRandomName = (usersName: string) => {
  const randomName = names[Math.floor(Math.random() * names.length)];
  if (usersName.toLowerCase() === randomName.toLowerCase()) {
    return getRandomName(usersName);
  }
  return randomName;
};

const SillyName = () => {
  const {
    formValues,
    handleClickNoHumor,
    handleSubmitSillyName,
    requiresName,
  } = useSignUpContext();

  const randomName = useMemo(() => getRandomName(formValues.firstName), [
    formValues.firstName,
  ]);

  useEffect(() => {
    requiresName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formStyles.signUpForm}>
      <figure className={formStyles.signUpFormImageContainer}>
        <img
          src={julianLaptop}
          alt="Julian"
          className={formStyles.signUpFormImage}
        />
      </figure>

      <h3 className={formStyles.signUpFormHeadline}>
        That's a silly name. I'll just put you in as {randomName}
      </h3>

      <div style={{ textAlign: "center" }}>
        <Button variant="secondary" onClick={handleSubmitSillyName} autoFocus>
          Just Kidding 😉
        </Button>
      </div>
      <div className={formStyles.easterEggLink}>
        <Button
          variant="ghost"
          onClick={handleClickNoHumor}
          btnClass={formStyles.linkBtn}
        >
          That's not funny!
        </Button>
      </div>
    </div>
  );
};

export default SillyName;
