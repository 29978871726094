import React, { useEffect, useState } from "react";
import { equals } from "ramda";

import usePrevious from "hooks/usePrevious";

export type TableSelection = {
  selectedIds: Set<string>;
  setSelectedIds: React.Dispatch<React.SetStateAction<Set<string>>>;
};

const useSelection = (allIds: string[]): TableSelection => {
  const [selectedIds, setSelectedIds] = useState(new Set<string>());

  const prevAllIds = usePrevious(allIds);

  useEffect(() => {
    if (!equals(allIds, prevAllIds)) {
      setSelectedIds(new Set<string>());
    }
  }, [allIds]);

  return { selectedIds, setSelectedIds };
};

export default useSelection;
