import { useEffect } from "react";

import { useAuth } from "auth/AuthProvider";
import injectScript from "./injectScript";
import { env } from "thunk-env";

if (env.REACT_APP_ENV === "staging" || env.REACT_APP_ENV === "production") {
  injectScript(env.REACT_APP_HEAP_APP_ID);
}

const HeapAnalytics = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      window.heap?.identify(currentUser.email!);
    } else {
      window.heap?.resetIdentity();
    }
  }, [currentUser]);

  return null;
};

export default HeapAnalytics;
