import React from "react";

import Modal from "framework/components/Modal";
import Button from "framework/components/form/Button";
import { UseDialogHook } from "hooks/useDialog";

import styles from "./index.module.scss";

type ConfirmModalProps<T, R> = UseDialogHook<T, R> & {
  title?: string;
  okText?: string;
  cancelText?: string;
  children?:
    | React.ReactNode
    | ((props: ConfirmModalProps<T, R>) => React.ReactNode);
};

const DeleteConfirmModal = <T,>(props: ConfirmModalProps<T, boolean>) => {
  const {
    title,
    okText = "Delete",
    cancelText = "Cancel",
    children,
    close,
    ...rest
  } = props;

  return (
    <Modal
      {...rest}
      contentClass={styles.modalContainer}
      titleClass={styles.title}
      close={close}
      title={title}
      rightActions={
        <div>
          <Button
            variant="circular"
            size="large"
            btnClass={styles.cancelButton}
            onClick={() => close(false)}
          >
            {cancelText}
          </Button>
          <Button variant="error" size="large" onClick={() => close(true)}>
            {okText}
          </Button>
        </div>
      }
    >
      <div className={styles.messageContainer}>
        {typeof children === "function" ? children(props) : children}
      </div>
    </Modal>
  );
};

export default DeleteConfirmModal;
