import React, { useMemo, useState } from "react";
import { Editor, Transforms, Element } from "slate";
import {
  AutoScrollActivator,
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  TraversalOrder,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  verticalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { DragStartEvent } from "@dnd-kit/core/dist/types";
import { ReactEditor } from "slate-react";
import ReactDOM, { createPortal } from "react-dom";
import { isIOS, isMobile, isMobileSafari } from "react-device-detect";

import { DndStateProvider } from "./useDndState";
import { sortableCollisionDetection } from "./sortableCollisionDetection";
import { moveDndTransform } from "../transforms/moveDndTransform";
import { ExtendedEditor } from "../extendedEditor";
import { useDragDepth } from "components/slate/slate-extended/dnd/useDragDepth";
import { useDragOverlayHeight } from "components/slate/slate-extended/dnd/useDragOverlayHeight";
import { restrictToVerticalWindowEdges } from "components/slate/slate-extended/dnd/utilities";
import useEvent from "hooks/useEvent";

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

type DndPluginContextProps = {
  editor: Editor;
  onDragStart?(event: DragStartEvent): void;
  onDragEnd?(event: DragEndEvent): void;
  onDragCancel?(event: DragCancelEvent): void;
  renderDragOverlay: (props: {
    editor: Editor;
    activeId: string;
    onHeightChange: (height: number) => void;
  }) => React.ReactElement;
  indentationWidth: number;
  selectedIds: Set<string>;
};

const lineHeight = 30;

const DndPluginContext = ({
  editor,
  onDragStart,
  onDragEnd,
  onDragCancel,
  renderDragOverlay,
  indentationWidth,
  selectedIds,
  children,
}: React.PropsWithChildren<DndPluginContextProps>) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeElement, setActiveElement] = useState<Element | null>(null);
  const semanticNode =
    activeElement && ExtendedEditor.semanticNode(editor, activeElement);
  const [overId, setOverId] = useState<string | null>(null);
  const [offsetLeft, setOffsetLeft] = useState<number>(0);

  const dragDepth = useDragDepth(
    editor,
    activeElement,
    overId,
    offsetLeft,
    indentationWidth
  );

  const { dragOverlayHeight, setDragOverlayHeight } = useDragOverlayHeight(
    editor,
    activeElement,
    semanticNode,
    selectedIds,
    lineHeight
  );

  const items = useMemo(
    () => editor.children.map((item) => item.id!).filter(Boolean),
    [editor.children]
  );

  const clearSelection = () => {
    ReactEditor.blur(editor);
    Transforms.deselect(editor);
    window.getSelection()?.empty();
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 0.5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    })
  );

  const hasAutoscroll = true;
  const edgeRestriction = isMobileSafari && isIOS;
  const keepSelection = isMobile;

  const handleDragStart = useEvent((event: DragStartEvent) => {
    const { active } = event;

    if (active) {
      !keepSelection && clearSelection();
      onDragStart && onDragStart(event);

      document.body.setAttribute("data-slate-dragging", "true");

      ReactDOM.unstable_batchedUpdates(() => {
        setActiveId(active.id);
        setActiveElement(
          editor.children.find((x) => x.id === active.id) || null
        );
        setOverId(active.id);
      });
    }
  });

  const handleDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId(over?.id ?? null);
  };

  const handleDragEnd = useEvent((event: DragEndEvent) => {
    onDragEnd && onDragEnd(event);
    const { active, over } = event;

    if (over) {
      moveDndTransform({
        editor,
        activeId: active.id,
        activeIndex: active.data.current?.sortable.index,
        selectedIds: selectedIds,
        overId: over.id,
        overIndex: over.data.current?.sortable.index,
        dragDepth,
      });
    }

    if (!keepSelection) {
      const selectIndex = editor.children.findIndex((x) => x.id === active.id);
      ReactEditor.focus(editor);
      Transforms.select(editor, Editor.end(editor, [selectIndex]));
    }

    resetState();
  });

  const handleDragCancel = (e: DragCancelEvent) => {
    onDragCancel && onDragCancel(e);
    resetState();
  };

  const resetState = () => {
    setActiveId(null);
    setActiveElement(null);
    setOffsetLeft(0);

    document.body.removeAttribute("data-slate-dragging");
  };

  return (
    <DndStateProvider
      value={useMemo(
        () => ({
          activeId,
          activeElement,
          dragDepth,
          dragOverlayHeight,
        }),
        [activeId, activeElement, dragDepth, dragOverlayHeight]
      )}
    >
      <DndContext
        collisionDetection={sortableCollisionDetection}
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        modifiers={edgeRestriction ? [restrictToVerticalWindowEdges] : []}
        sensors={sensors}
        measuring={measuring}
        autoScroll={
          hasAutoscroll
            ? {
                threshold: {
                  x: 0.18,
                  y: 0.18,
                },
                interval: 5,
                acceleration: 20,
                activator: AutoScrollActivator.Pointer,
                order: TraversalOrder.TreeOrder,
              }
            : false
        }
      >
        <SortableContext strategy={verticalListSortingStrategy} items={items}>
          {children}
        </SortableContext>
        {createPortal(
          <DragOverlay
            adjustScale={false}
            // dropAnimation={{
            //   duration: 220,
            //   easing: "cubic-bezier(.43,.96,.36,1.13)",
            //   dragSourceOpacity: 0,
            // }}
            dropAnimation={null}
          >
            {activeId &&
              renderDragOverlay({
                editor,
                activeId,
                onHeightChange: (height) => setDragOverlayHeight(height),
              })}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </DndStateProvider>
  );
};

export default DndPluginContext;
