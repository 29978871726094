import { Editor, Point } from "slate";

const getAfterText = (
  editor: Editor,
  start: Point,
  options?: {
    distance?: number;
    unit?: "offset" | "character" | "word" | "line" | "block";
    voids?: boolean;
  }
): string | null => {
  const before = Editor.after(editor, start, options);
  if (!before) return null;

  const beforeRange = Editor.range(editor, before, start);
  const beforeText = Editor.string(editor, beforeRange);

  return beforeText || null;
};

export default getAfterText;
