import queryString from "query-string";
import { Element } from "slate";

import {
  MediaEmbedElement,
  MediaEmbedType,
} from "components/slate/plugins/mediaEmbed/types";

export const isMediaEmbedElement = (value: any): value is MediaEmbedElement => {
  return Element.isElementType<MediaEmbedElement>(value, MediaEmbedType);
};

export const getYouTubeUrl = (location: any) => {
  const { hostname, search, pathname, href } = location;

  const makeUrl = (id: any) => `https://www.youtube.com/embed/${id}`;
  const isEmbed = href.includes("embed");

  if (hostname.includes("youtube.com")) {
    if (isEmbed) {
      return href;
    }

    const { v: id } = queryString.parse(search);
    return makeUrl(id);
  }

  if (hostname.includes("youtu.be")) {
    if (isEmbed) {
      return href;
    }

    const id = pathname.split("/")[1];
    return makeUrl(id);
  }
};

export const getLoomUrl = (location: any) => {
  const { hostname, pathname, href } = location;

  const makeUrl = (id: any) => `https://www.loom.com/embed/${id}`;
  const isEmbed = href.includes("embed");

  if (hostname.includes("loom.com")) {
    if (isEmbed) {
      return href;
    }

    const id = pathname.split("/")[2];
    return makeUrl(id);
  }
};

export const getFigmaUrl = (location: any) => {
  const { href } = location;

  const makeUrl = (url: any) =>
    `https://www.figma.com/embed?embed_host=thunknotes&url=${url}`;
  const isEmbed = href.includes("embed");
  const expression = /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/;

  if (expression.test(href)) {
    if (isEmbed) {
      return href;
    }

    return makeUrl(href);
  }
};

export const getCodepenUrl = (location: any) => {
  const { href } = location;

  const makeUrl = (url: string) => url.replace("/pen/", "/embed/");
  const isEmbed = href.includes("embed");
  const expression = /https?.*codepen.io\/.*\/(pen|embed)\/(.*)?$/;

  if (expression.test(href)) {
    if (isEmbed) {
      return href;
    }

    return makeUrl(href);
  }
};

export const getMediaUrl = (url: any) => {
  if (!url) {
    return null;
  }

  try {
    const location = new URL(url);
    const adjustedUrl =
      getYouTubeUrl(location) ||
      getLoomUrl(location) ||
      getFigmaUrl(location) ||
      getCodepenUrl(location);
    return adjustedUrl;
  } catch (e) {
    return null;
  }
};
