import React from "react";
import cn from "classnames";
import { ArrowRightIcon } from "thunk-icons";

import styles from "./index.module.scss";

const CollapseButton = ({
  icon: SvgIcon = null,
  className,
  arrowClassName,
  isOpened,
  onClick,
  children,
  arrowPosition = "left",
  style,
}: any) => {
  return (
    <button
      style={style}
      className={cn(className, styles.button)}
      onClick={onClick}
    >
      {SvgIcon && <SvgIcon size={28} />}
      <div
        className={cn(styles.labelAndArrow, {
          [styles.reverse]: arrowPosition === "right",
        })}
      >
        <div className={cn(arrowClassName, styles.arrow)}>
          <ArrowRightIcon
            size={11}
            className={cn(styles.arrowIcon, {
              [styles.opened]: isOpened,
            })}
          />
        </div>
        <div className={styles.label}>{children}</div>
      </div>
    </button>
  );
};

export default CollapseButton;
