import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react-lite";
import { uniq } from "ramda";
import debounce from "lodash.debounce";

import { Document } from "thunk-core";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import { Card, CardTitle } from "framework/components/Card";
import { useDocumentsStore, useStore } from "stores/store";
import { useCurrentUserId } from "db/currentUser";
import AppResult from "components/results/AppResult";
import NoTasksEmptyState from "components/editor/SidePanel/todos/empty/NoTasksEmptyState";
import CardSpinner from "framework/components/Card/CardSpinner";
import usePersistedUserState from "hooks/usePersistedUserState";
import TasksTabButton from "components/editor/SidePanel/todos/TasksTabButton";
import {
  SidePanelTabType,
  useSidePanelContext,
} from "providers/SidePanelProvider";
import { useLoadable } from "hooks/useLoadable";
import TasksSection from "components/editor/SidePanel/todos/TasksSection";
import SlateTodosEditor from "components/slate/editors/SlateTodosEditor";
import { useEditingSession } from "stores/hooks/useEditingSession";
import { EditorType } from "components/slate/types";
import { Descendant, Element } from "slate";
import { useDocumentUserContext } from "providers/DocumentUserProvider";
import useMemoArray from "hooks/useMemoArray";
import { EditorErrorBoundary } from "components/errors/EditorErrorBoundary";
import CompleteTasks from "components/editor/SidePanel/todos/empty/CompleteTasks";

import styles from "../index.module.scss";

type Props = {
  container: HTMLDivElement | null;
  tabsContainer: HTMLDivElement | null;
};

const TodosContent = (props: Props) => {
  const { container, tabsContainer } = props;

  const { activeTab, setActiveTab } = useSidePanelContext();
  const { blocksStore } = useStore();
  const userId = useCurrentUserId();

  const [
    hideCompleted,
    setHideCompleted,
  ] = usePersistedUserState("hideCompletedTasks", (restored: boolean) =>
    restored == null ? false : restored
  );

  const [{ loading, error }, loadable] = useLoadable();

  useEffect(() => {
    const initialize = async (userId: string) => {
      loadable.start();
      try {
        await blocksStore.loadTasks(userId);
        loadable.success();
      } catch (error) {
        console.error(error);
        loadable.error(error);
      }
    };

    initialize(userId);
  }, [userId]);

  if (loading) {
    return <CardSpinner />;
  }

  if (error) {
    console.error(error);
    return <AppResult resultType="fetching" isCenteredContent={true} />;
  }

  return (
    <WidgetErrorBoundary>
      {tabsContainer &&
        createPortal(
          <WidgetErrorBoundary isEmptyFallback={true}>
            <TasksTabButton
              hideCompleted={hideCompleted}
              setHideCompleted={setHideCompleted}
              isActive={activeTab === SidePanelTabType.Tasks}
              setActive={() => setActiveTab(SidePanelTabType.Tasks)}
            />
          </WidgetErrorBoundary>,
          tabsContainer
        )}
      {(function () {
        if (activeTab !== SidePanelTabType.Tasks) {
          return null;
        }

        return (
          <TodosList container={container} hideCompleted={hideCompleted} />
        );
      })()}
    </WidgetErrorBoundary>
  );
};

export default TodosContent;

type TodosListProps = {
  hideCompleted: boolean;
  container: HTMLDivElement | null;
};

const TodosList = observer(({ container, hideCompleted }: TodosListProps) => {
  const { blocksStore } = useStore();

  const [collapsed, setCollapsed] = usePersistedUserState<string[]>(
    "collapsedTasks",
    (restored: string[]) => restored || []
  );

  const pageSize = 15;
  const [limit, setLimit] = useState(pageSize);

  const result = blocksStore.getGroupedTodos();
  const total = result.length;
  const filtered = result.filter((x) => {
    return hideCompleted ? x.blocks.length > x.checkedCount : true;
  });
  const filteredTotal = filtered.length;
  const limited = filtered.slice(0, limit);

  const scrollListener = useRef<() => void | null>(null);
  useEffect(() => {
    scrollListener.current = () => {
      const scrolled =
        container.scrollTop + container.offsetHeight + 100 >=
        container.scrollHeight;

      if (scrolled && limit <= filteredTotal) {
        setLimit((x) => x + pageSize);
      }
    };
  });

  useEffect(() => {
    scrollListener.current();
  }, [total]);

  useEffect(() => {
    const listener = debounce(
      (e: KeyboardEvent) => scrollListener.current(),
      300
    );

    // add some time for render first editors before add listener
    const timeout = setTimeout(() => {
      container?.addEventListener("scroll", listener);
    }, 1000);

    return () => {
      clearTimeout(timeout);
      container?.removeEventListener("scroll", listener);
    };
  }, [container]);

  if (!total) {
    return <NoTasksEmptyState />;
  }

  if (filteredTotal === 0) {
    return <CompleteTasks />;
  }

  return (
    <div
      className={styles.sidePanelCard}
      data-card-type="side-panel-card"
    >
    <Card title={<CardTitle title="To Do" />} noPadding={true}>
      {limited.map(
        ({
          document,
          blocks,
          listIndex,
          startIndex,
          endIndex,
          checkedCount,
        }) => {
          const collapsedId = `${document.id}${
            listIndex > 0 ? `-${listIndex}` : ""
          }`;

          return (
            <div
              className={styles.tasksContainer}
              key={`${document.id}-${blocks[0].id}`}
            >
              <div className={styles.taskSection}>
                <TasksSection
                  document={document}
                  isCollapsed={collapsed.includes(collapsedId)}
                  onCollapse={(isCollapsed) =>
                    setCollapsed((state) =>
                      isCollapsed
                        ? uniq([...state, collapsedId])
                        : state.filter((x) => x !== collapsedId)
                    )
                  }
                  checkedCount={checkedCount}
                  totalCount={blocks.length}
                />
              </div>
              {!collapsed.includes(collapsedId) && (
                <div className={styles.editableToDos}>
                  <TodosEditor
                    document={document}
                    startIndex={startIndex}
                    endIndex={endIndex}
                    hideCompleted={hideCompleted}
                  />
                </div>
              )}
            </div>
          );
        }
      )}
    </Card>
    </div>
  );
});

type TodoEditorProps = {
  document: Document;
  startIndex: number;
  endIndex: number;
  hideCompleted: boolean;
};

const TodosEditor = observer((props: TodoEditorProps) => {
  const { document, startIndex, endIndex, hideCompleted } = props;
  const userId = useCurrentUserId();

  const documentId = document.id;
  const documentType = document.type;

  const documentsStore = useDocumentsStore(documentType);

  const { documentUserId } = useDocumentUserContext();

  const { loading, error, slateId, content } = useEditingSession({
    userId,
    editorType: EditorType.ReferenceEditor,
    documentId: documentId,
    dateId: document.dateId,
    documentType: documentType,
  });

  const handleContentChange = useCallback(
    (value: Descendant[]) => {
      documentsStore.updateContent({
        slateId,
        userId: documentUserId,
        documentId,
        nextContent: value as Element[],
      });
    },
    [documentId, slateId, documentUserId]
  );

  const contextInterval = useMemoArray([
    document.blocks[startIndex],
    document.blocks[endIndex],
  ]) as [string, string];

  const contextIntervalIndexes = useMemoArray([startIndex, endIndex]) as [
    number,
    number
  ];

  if (loading) {
    return null;
  }

  return (
    <EditorErrorBoundary>
      <SlateTodosEditor
        slateId={slateId}
        userId={userId}
        contextInterval={contextInterval}
        contextIntervalIndexes={contextIntervalIndexes}
        value={content}
        onChange={handleContentChange}
        readOnly={false}
        documentId={documentId}
        documentType={documentType}
        hideCompleted={hideCompleted}
      />
    </EditorErrorBoundary>
  );
});
