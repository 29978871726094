import { useCallback, useEffect } from "react";
import create from "zustand";
import isHotkey from "is-hotkey";

import useZustandCreateStore from "hooks/useZustandCreateStore";
import { NavigationKeys } from "constants/navigationKeys";

type KeyboardNavigationState = {
  index: number;
  setIndex: (index: number) => void;
};

const createState = () =>
  create<KeyboardNavigationState>((set) => ({
    index: 0,
    setIndex: (index) => set({ index }),
  }));

type UseKeyboardNavigationProps<T> = {
  listening: boolean;
  items: ReadonlyArray<T>;
  onSelect: (e: KeyboardEvent, item: T) => void;
  onCancel: (e: KeyboardEvent) => void;
};

const useKeyboardNavigation = <T>({
  listening,
  items,
  onSelect,
  onCancel,
}: UseKeyboardNavigationProps<T>) => {
  const { state, getState } = useZustandCreateStore(createState, []);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const { index, setIndex } = getState();

      if (isHotkey(NavigationKeys.ArrowDown, e)) {
        e.preventDefault();
        const newIndex = normalizeIndex(items, index + 1);
        setIndex(newIndex);
        return;
      }

      if (isHotkey(NavigationKeys.ArrowUp, e)) {
        e.preventDefault();
        const newIndex = normalizeIndex(items, index - 1);
        setIndex(newIndex);
        return;
      }

      if (
        isHotkey(NavigationKeys.Tab, e) ||
        isHotkey(NavigationKeys.Enter, e)
      ) {
        e.preventDefault();
        const item = items[index];
        if (item) {
          // @ts-ignore
          item.onSelect ? item.onSelect(e, item) : onSelect(e, item);
        }
        return;
      }

      if (isHotkey(NavigationKeys.Escape, e)) {
        e.preventDefault();
        onCancel(e);
        return;
      }
    },
    [items, getState, onSelect, onCancel]
  );

  useEffect(() => {
    if (listening) {
      document.addEventListener("keydown", onKeyDown);
    } else {
      document.removeEventListener("keydown", onKeyDown);
    }

    return () => document.removeEventListener("keydown", onKeyDown);
  }, [onKeyDown, listening]);

  return state;
};

export default useKeyboardNavigation;

const normalizeIndex = (config: ReadonlyArray<any>, index: number) => {
  if (index < 0) {
    return config.length + index;
  } else {
    return index % config.length;
  }
};
