import { createContext, useContext, useState, useEffect } from "react";
import {
  isWindows,
  isSafari,
  isAndroid,
  isMobile,
  isTablet,
  isIOS,
  isElectron,
} from "react-device-detect";

import useWindowEventListener from "hooks/useWindowEventListener";

import variables from "styles/variables.module.scss";

const checkIsMobileScreen = () => {
  return window.innerWidth <= Number.parseInt(variables.mediaSmBreakpoint);
};

type Value = {
  isMobileScreen: boolean;
};
const DeviceDetectorContext = createContext<Value>({
  isMobileScreen: false,
});

export const DeviceDetectorProvider = ({ children }: any) => {
  const [isMobileScreen, setIsMobileScreen] = useState(checkIsMobileScreen());

  useWindowEventListener("resize", () =>
    setIsMobileScreen(checkIsMobileScreen())
  );

  useEffect(() => {
    if (isWindows) {
      document.body.setAttribute("data-windows-device", "true");
    }
    if (isMobile) {
      document.body.setAttribute("data-mobile-device", "true");
    }
    if (isTablet) {
      document.body.setAttribute("data-tablet-device", "true");
    }
    if (isElectron) {
      document.body.setAttribute("data-electron-app", "true");
    }
  }, []);

  return (
    <DeviceDetectorContext.Provider value={{ isMobileScreen }}>
      {children}
    </DeviceDetectorContext.Provider>
  );
};

export const useDeviceDetector = () => {
  const { isMobileScreen } = useContext(DeviceDetectorContext);

  return {
    isMobileScreen,
    isMobile,
    isTablet,
    isWindows,
    isSafari,
    isAndroid,
    isIOS,
    isElectron,
  };
};
