import React, { memo, useCallback, useEffect, useState } from "react";
import {
  RenderElementProps,
  useSelected,
  useSlate,
  useSlateStatic,
} from "slate-react";
import { Range } from "slate";
import cn from "classnames";
import { isIOS } from "react-device-detect";

import useWrapperIntersectionObserver from "components/slate/wrapper/useWrapperIntersectionObserver";
import { CodeBlockType } from "components/slate/plugins/codeBlock/types";
import { Item, ItemProps } from "components/slate/wrapper/Item";
import { Sortable } from "components/slate/wrapper/Sortable";
import {
  getComponentWrapperAttributes,
  getComponentWrapperClass,
} from "components/slate/wrapper/utils";
import { useDndState } from "components/slate/slate-extended/dnd/useDndState";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { useComponentsMenuContext } from "components/slate/hooks/useComponentsMenuContext";
import { indentationWidth } from "components/slate/wrapper/constants";
import { foldElement } from "components/slate/slate-extended/transforms/foldElement";
import { ElementProps } from "components/slate/types";
import useQuery from "hooks/useQuery";
import { useStaticProps } from "../hooks/useStaticProps";
import { useActualValueSubscription } from "components/slate/editors/SyncSlateValue";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { useStoreValue } from "state/utils";

import styles from "./index.module.scss";
import "./components.scss";

export const ElementWrapper = (
  props: RenderElementProps & {
    renderElement: (props: ElementProps) => JSX.Element;
  }
) => {
  const { element } = props;
  const id = element.id;

  const editor = useSlate();
  useActualValueSubscription();
  const { activeId, activeElement } = useDndState();

  const semanticNode = ExtendedEditor.semanticNode(editor, element);
  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);
  const terminalSelection = useStoreValue(
    TerminalStore.terminalSelection(editor.slateId)
  );

  const isHiddenById =
    (ExtendedEditor.isNestingElement(editor, activeElement) &&
      activeId !== id &&
      ExtendedEditor.isHiddenById(editor, element, activeId)) ||
    (isTerminalActive &&
      activeId != null &&
      activeId !== id &&
      terminalSelection.has(id));
  const hidden = semanticNode.hidden || isHiddenById;

  const semanticChildrenCount = semanticNode.children.length;

  return (
    <MemoizedElementWrapper
      {...props}
      hidden={hidden}
      semanticChildrenCount={semanticChildrenCount}
    />
  );
};

export const MemoizedElementWrapper = memo(
  (
    props: RenderElementProps & {
      hidden: boolean;
      semanticChildrenCount: number;
      renderElement: (props: ElementProps) => JSX.Element;
    }
  ) => {
    const editor = useSlateStatic();
    const query = useQuery();
    const {
      attributes,
      children,
      element,
      hidden,
      semanticChildrenCount,
      renderElement,
    } = props;
    const [highlight, setHighlight] = useState(false);
    const {
      isMainEditor,
      isSnippet,
      noSortable,
      readOnly,
      slateId,
    } = useStaticProps();

    const { id } = element;

    const semanticNode = ExtendedEditor.semanticNode(editor, element);

    const selected = useSelected();

    const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);
    const terminalSlateId = useStoreValue(TerminalStore.terminalSlateId);
    const isElementSelected = useStoreValue(
      TerminalStore.isElementSelected({ slateId, elementId: id })
    );

    const { activeId, dragDepth, dragOverlayHeight } = useDndState();

    const { getIsMenuOpened } = useComponentsMenuContext();
    const isMenuOpened = getIsMenuOpened(attributes.ref.current);

    const isInViewport = useWrapperIntersectionObserver(
      attributes.ref,
      activeId != null,
      [hidden]
    );

    useEffect(() => {
      const isBlock = query.get("block") && query.get("block") === element.id;
      if (isBlock) {
        setHighlight(true);
        attributes.ref.current &&
          window.scrollTo({
            top: attributes.ref.current.offsetTop,
          });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (selected && highlight) {
        setHighlight(false);
      }
    }, [highlight, selected]);

    const sortableEnabled =
      !noSortable &&
      !readOnly &&
      !hidden &&
      (selected || isInViewport || isMenuOpened || activeId === element.id);

    const isDragging = activeId === id;
    const realSpacing = ExtendedEditor.isNestingElement(editor, element)
      ? indentationWidth * element.depth
      : 0;
    const dragSpacing = indentationWidth * dragDepth;

    const handleFold = useCallback(() => {
      foldElement(editor, element);
    }, [editor, element]);

    const itemProps: ItemProps = {
      children: children,
      element: element,
      elementRef: attributes.ref,
      renderElement: renderElement,
      semanticChildrenCount: semanticChildrenCount,
      onFold: handleFold,
      isInViewport: isInViewport,
    };

    const spellCheck =
      selected && element.type !== CodeBlockType ? "true" : "false";

    return (
      <div
        {...attributes}
        {...getComponentWrapperAttributes(element, semanticNode)}
        spellCheck={spellCheck}
        data-slate-node-id={element.id}
        data-slate-node-type={element.type}
        data-slate-clipboard-skip-linebreak={true}
        className={cn(
          styles.elementContainer,
          getComponentWrapperClass(element),
          {
            [styles.hidden]: hidden,
            [styles.selected]: selected && Range.isCollapsed(editor.selection!),
            [styles.nestingElement]: ExtendedEditor.isNestingElement(
              editor,
              element
            ),
            [styles.dragging]: activeId === id,
            [styles.folded]:
              ExtendedEditor.isFoldingElement(editor, element) &&
              element.folded,
            [styles.disableSelection]: isIOS && activeId != null,
            [styles.highlight]: highlight && isMainEditor && !isSnippet,
            [styles.terminal]: isTerminalActive,
            [styles.terminalActive]:
              isTerminalActive && terminalSlateId === slateId,
            [styles.terminalSelected]: isElementSelected,
          }
        )}
        style={
          {
            "--spacing": `${isDragging ? dragSpacing : realSpacing}px`,
            "--drag-overlay-height": dragOverlayHeight
              ? `${dragOverlayHeight}px`
              : "auto",
          } as React.CSSProperties
        }
      >
        {sortableEnabled ? (
          <Sortable id={element.id} {...itemProps} />
        ) : (
          <Item {...itemProps} />
        )}
      </div>
    );
  },
  (prev, next) => {
    return (
      prev.element === next.element &&
      prev.renderElement === next.renderElement &&
      prev.hidden === next.hidden &&
      prev.semanticChildrenCount === next.semanticChildrenCount
    );
  }
);
