import React, { useCallback } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import queryString from "query-string";

import { getEditorPathname, makeDateId, useDateFromUrl } from "helpers";
import CardPageLayout from "components/layout/CardPageLayout";
import CardNavigationActions from "components/editor/CardNavigationActions";
import { EditorType } from "components/slate/types";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import EditorCard from "components/editor/EditorCard";
import AppResult from "components/results/AppResult";
import SidePanelToggle from "components/layout/SidePanelToggle";
import { useSaveNotification } from "hooks/useSaveNotification";
import useScrollToTop from "hooks/useScrollToTop";
import Helmet from "components/Helmet";
import { formatDate } from "utils/dateUtils";
import DocumentEditor from "components/editor/DocumentEditor";
import { useCurrentUserId } from "db/currentUser";
import { useEditingSession } from "stores/hooks/useEditingSession";
import StreamProvider from "components/slate/plugins/stream/components/StreamProvider";
import CardMainToolbar from "components/editor/toolbar/CardMainToolbar";
import { DocumentType } from "thunk-core";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";

import { useEffect } from "react";

import styles from "./index.module.scss";

// THIS IS THE DAILY NOTE
import { EditorsStore } from "components/slate/state/EditorsStore";
import { useStoreValue } from "state/utils";

const NoteEditingPage = () => {
  const location = useLocation();
  const search = queryString.parse(location.search);

  const date = useDateFromUrl(search.date as string);

  //stop body scrolling on this page
  useEffect(() => {
    document.body.removeAttribute("data-not-public");
    document.body.setAttribute("daily-note", "true");
    document.body.removeAttribute("no-side-panel-page");
  });

  useSaveNotification();
  useScrollToTop([date]);

  if (isNaN(date.getTime())) {
    return <Redirect to="/writing" />;
  }

  return (
    <>
      <Helmet pageTitle={formatDate(date)} />
      <NoteMainEditor date={date} />
    </>
  );
};

export default NoteEditingPage;

const NoteMainEditor = observer(({ date }: { date: Date }) => {
  const history = useHistory();
  const userId = useCurrentUserId();
  const editorType = EditorType.MainEditor;
  const documentType = DocumentType.NOTE;
  const dateId = makeDateId(date);

  const {
    loading,
    error,
    slateId,
    content,
    document,
    wordCount,
  } = useEditingSession({
    userId,
    editorType,
    documentId: null,
    dateId,
    documentType,
  });

  const editor = useStoreValue(EditorsStore.editor(slateId));

  const containerRef = React.useRef<HTMLDivElement>(null);

  const afterRemove = useCallback(() => {
    // reload page
    history.replace("/empty");
    setTimeout(() => {
      history.replace(getEditorPathname(documentType, null, dateId));
    }, 10);
  }, [documentType, dateId]);

  return (
    <NoteEditingPageLayout documentTitle={getDocumentTitle(document)}>
      <StreamProvider editor={editor}>
        {(function () {
          if (loading) {
            return (
              <EditorCard
                className={styles.dailyNoteEditor}
                date={date}
                loading={true}
                loadingTimeout={300}
              />
            );
          }

          if (error) {
            console.error(error);
            return (
              <EditorCard className={styles.dailyNoteEditor} date={date}>
                <AppResult resultType="fetching" isCenteredContent={true} />
              </EditorCard>
            );
          }

          // returns null for the header title if it's not today so that we can use an icon to scroll to top on the mobile header for pages that aren't today
          const getHeaderTitle = () => {
            if (getDocumentTitle(document) === "Today") {
              return "Today";
            } else {
              return null;
            }
          };

          return (
            <div className={styles.cardContentWrapper} ref={containerRef}>
              <CardNavigationActions
                document={document}
                containerRef={containerRef}
                pageTitle={getHeaderTitle()}
                rightActions={<SidePanelToggle />}
                toolbar={
                  <CardMainToolbar
                    document={document}
                    onAfterRemove={afterRemove}
                    wordCount={wordCount}
                  />
                }
              />
              <DocumentEditor
                slateId={slateId}
                editorType={editorType}
                documentId={document.id}
                documentType={documentType}
                className={styles.dailyNoteEditor}
                date={date}
                document={document}
                content={content}
              />
            </div>
          );
        })()}
      </StreamProvider>
    </NoteEditingPageLayout>
  );
});

interface NoteEditingPageLayoutProps {
  documentTitle: string | undefined;
}

const NoteEditingPageLayout = ({
  children,
  documentTitle,
}: React.PropsWithChildren<NoteEditingPageLayoutProps>) => {
  return (
    <CardPageLayout>
      <WidgetErrorBoundary>{children}</WidgetErrorBoundary>
    </CardPageLayout>
  );
};
