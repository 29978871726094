import React from "react";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import Button from "framework/components/form/Button";
import Result from "framework/components/Result";
import { getErrorInfo } from "../errors";

const HelpErrorComponent = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const search = queryString.parse(location.search);
  const code = search.code;

  const errorInfo = getErrorInfo({ code });

  return (
    <Result
      isCenteredContent={true}
      title={t(errorInfo.message)}
      actions={
        <Button variant="secondary" as={Link} to="/">
          Try again
        </Button>
      }
    />
  );
};

export default HelpErrorComponent;
