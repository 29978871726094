import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import { doc } from "firebase/firestore";

import ProfilePhoto from "./ProfilePhoto";
import FirstName from "./FirstName";
import LastName from "./LastName";
import Email from "./Email";
import Subscription from "./Subscription";
import ExportData from "./Export";
import { Card, CardTitle } from "framework/components/Card";
import { SignOut } from "./SignOut";
import FormGroupLabel from "framework/components/form/FormGroupLabel";
import CardPageLayout from "components/layout/CardPageLayout";
import AppResult from "components/results/AppResult";
import { db } from "firebaseInstance";
import { useCurrentUserId } from "db/currentUser";

import { useEffect } from "react";

import styles from "./index.module.scss";

const AccountPage = () => {
  const { t } = useTranslation();
  const userId = useCurrentUserId();

  //allow body scrolling on this page
  useEffect(() => {
    document.body.removeAttribute("data-not-public");
    document.body.removeAttribute("daily-note");
    document.body.setAttribute("no-side-panel-page", "true");
  });

  const [user, loading, error] = useDocumentData(doc(db, `users/${userId}`), {
    idField: "id",
  });

  const pageTitle = t("profile.title");

  if (loading) {
    return (
      <CardPageLayout>
        <Card
          className={styles.container}
          title={<CardTitle title={pageTitle} />}
        />
      </CardPageLayout>
    );
  }

  if (error) {
    console.error(error);
    return (
      <CardPageLayout>
        <Card
          className={styles.container}
          title={<CardTitle title={pageTitle} />}
        >
          <AppResult resultType="fetching" isCenteredContent={true} />
        </Card>
      </CardPageLayout>
    );
  }

  return (
    <CardPageLayout>
      <Card
        className={styles.container}
        title={<CardTitle title={t("profile.title")} />}
      >
        <ProfilePhoto user={user} />
        <div className={styles.profileName}>
          <FirstName user={user} />
          <LastName user={user} />
        </div>
        <Email user={user} />
        <div className={styles.accountPageItem}>
          <Subscription />
        </div>
        <div className={styles.accountPageItem}>
          <ExportData />
        </div>
        {(window as any).ELECTRON_CONFIG && (
          <div className={styles.accountPageItem}>
            <FormGroupLabel labelText="Web Application" />
            <div>
              {(window as any).ELECTRON_CONFIG.ELECTRON_APP_WEB_ENDPOINT}
            </div>
          </div>
        )}
        <div className={styles.accountPageItem}>
          <SignOut />
        </div>
      </Card>
    </CardPageLayout>
  );
};

export default AccountPage;
