import {
  collection,
  doc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";

import { db } from "firebaseInstance";
import { FEEDBACK_CONVERTER } from "db/feedback/feedback.mapping";
import { Feedback } from "db/feedback/feedback.types";
import { getCollectionData } from "db/utils";
import { getFeedbackCollectionPath, getFeedbackPath } from "thunk-core";

export const hasFeedback = async (userId: string): Promise<boolean> => {
  const snapshot = await getDocs(
    query(
      collection(db, getFeedbackCollectionPath()),
      where("userId", "==", userId),
      limit(1)
    ).withConverter(FEEDBACK_CONVERTER)
  );

  return snapshot.docs.length > 0;
};

export const getAllUsersFeedback = async (): Promise<Feedback[]> => {
  return getCollectionData(
    query(collection(db, getFeedbackCollectionPath())).withConverter(
      FEEDBACK_CONVERTER
    )
  );
};

export const upsertFeedback = async ({ feedback }: { feedback: Feedback }) => {
  await setDoc(
    doc(db, getFeedbackPath(feedback.id)).withConverter(FEEDBACK_CONVERTER),
    feedback,
    { merge: true }
  );
};
