import { useEffect } from "react";
import * as Sentry from "@sentry/react";

import { useCurrentUserData } from "db/currentUser";
import { useAuth } from "auth/AuthProvider";
import { env } from "thunk-env";

Sentry.init({
  dsn: env.REACT_APP_SENTRY_DSN,
  environment: env.REACT_APP_ENV,
});

const SentryAnalytics = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <PrivateSentryAnalytics />;
  }

  return <PublicSentryAnalytics />;
};

export default SentryAnalytics;

const PrivateSentryAnalytics = () => {
  const [user] = useCurrentUserData();

  useEffect(() => {
    Sentry.setUser(user || null);

    return () => {
      Sentry.setUser(null);
    };
  }, [user]);

  return null;
};

const PublicSentryAnalytics = () => {
  useEffect(() => {
    Sentry.setUser(null);
  }, []);

  return null;
};
