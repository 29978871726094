import { Transforms, Range, Node, Editor } from "slate";

import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { getText } from "components/slate/utils";

export const insertBacklink = (editor: Editor, element: BacklinkElement) => {
  Transforms.insertNodes(editor, element);
  Transforms.insertText(editor, " ");
  Transforms.move(editor, { unit: "character" });
};

export const insertBrackets = (editor: any) => {
  editor.insertText("[]");
  Transforms.move(editor, { distance: 1, reverse: true });
};

export const wrapFragmentInBrackets = (editor: Editor, fragment: Node) => {
  const { selection } = editor;
  const text = Node.string(fragment);
  Transforms.insertText(editor, `[${text}]`);
  Transforms.select(editor, selection);
  Transforms.move(editor, { distance: 1, reverse: false });
  const start = Editor.after(editor, Range.end(selection), {
    distance: 1,
  });

  const end = Editor.after(editor, start, { distance: 2 });
  const rangeToCheckForDoubleBrackets = Editor.range(editor, start, end);
  const afterText = getText(editor, rangeToCheckForDoubleBrackets);
  if (afterText === "]]") {
    if (Range.isForward(selection)) {
      Transforms.collapse(editor, { edge: "end" });
    } else {
      Transforms.collapse(editor);
    }
  }
};

export const wrapFragmentInDoubleBrackets = (
  editor: Editor,
  fragment: Node
) => {
  const { selection } = editor;
  const text = Node.string(fragment);
  Transforms.insertText(editor, `[[${text}]]`);
  Transforms.select(editor, selection);
  Transforms.move(editor, { distance: 2, reverse: false });
  if (Range.isForward(selection)) {
    Transforms.collapse(editor, { edge: "end" });
  } else {
    Transforms.collapse(editor);
  }
};
