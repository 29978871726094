import Button from "framework/components/form/Button";
import mascotsLove from "images/mascots_love.svg";
import { useOnboardingContext } from "../OnboardingProvider";

import formStyles from "../index.module.scss";

const YouHateFun = () => {
  const { handleSubmit, submitting } = useOnboardingContext();

  return (
    <div
      className={formStyles.onboardingContainerSm}
      style={{ textAlign: "center" }}
    >
      <figure className={formStyles.onboardingImageContainer}>
        <img
          src={mascotsLove}
          alt="Mascots love you"
          className={formStyles.onboardingImage}
        />
      </figure>

      <h3
        className={formStyles.onboardingHeadline}
        style={{ textAlign: "center" }}
      >
        That's ok, we still love you!
      </h3>

      <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
        <Button
          variant="secondary"
          onClick={handleSubmit}
          loading={submitting}
          disabled={submitting}
          autoFocus
        >
          Let's get started
        </Button>
      </div>
    </div>
  );
};

export default YouHateFun;
