import { env } from "thunk-env";

export const createHrefLandingSite = (path = "") => {
  return env.REACT_APP_LANDING_SITE_HOST + path;
};

export const toLandingSite = (path = "") => {
  window.location.href = createHrefLandingSite(path);
};

export const toLandingSiteReplace = (path = "") => {
  window.location.replace(createHrefLandingSite(path));
};
