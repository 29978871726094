import React, { forwardRef, memo } from "react";
import cn from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { useResizeDetector } from "react-resize-detector";

import CardSpinner from "framework/components/Card/CardSpinner";
import { TextareaAutosizeProps } from "react-textarea-autosize/dist/declarations/src";

import styles from "./index.module.scss";

export const CardToolbar = ({ children }: any) => {
  return <div className={styles.toolbar}>{children}</div>;
};

export const CardFooter = ({ children }: any) => {
  return <div className={styles.footer}>{children}</div>;
};

type CardEditableTitleProps = {
  showHint: boolean;
  hint: string | React.ReactNode;
  isSidePanel: boolean;
} & TextareaAutosizeProps;

export const CardEditableTitle = memo<
  CardEditableTitleProps & React.RefAttributes<HTMLTextAreaElement>
>(
  forwardRef<HTMLTextAreaElement, CardEditableTitleProps>(
    (
      {
        title,
        onChange,
        onKeyDown,
        onBlur,
        placeholder,
        disabled,
        showHint,
        hint,
        isSidePanel,
      },
      ref
    ) => {
      return (
        <div className={styles.title}>
          <TextareaAutosize
            ref={ref}
            disabled={disabled}
            className={styles.titleTextarea}
            placeholder={placeholder}
            value={title || ""}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
          />

          <div
            className={cn(styles.titleHint, {
              [styles.titleHintHidden]: !showHint,
            })}
          >
            {hint}
          </div>
        </div>
      );
    }
  )
);

type CardTitleProps = {
  title: string;
  isSidePanel?: boolean;
  hoverHint?: string;
};

export const CardTitle = memo<CardTitleProps>(
  ({ title, isSidePanel, hoverHint = "Page title" }) => {
    return (
      <div className={styles.header}>
        <h1
          className={cn(styles.title, {
            [styles.sidePanelTitleTextarea]: isSidePanel,
          })}
          title={hoverHint}
        >
          {title}
        </h1>
      </div>
    );
  }
);

type CardProps = {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
  title?: React.ReactNode;
  tags?: React.ReactNode;
  toolbar?: React.ReactNode;
  calendarControls?: React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
  loadingTimeout?: number;
  noPadding?: boolean;
  fullScreen?: boolean;
};

export const Card = ({
  style,
  className,
  children,
  title,
  tags,
  toolbar,
  calendarControls,
  footer,
  loading,
  loadingTimeout,
  noPadding,
  fullScreen,
}: CardProps) => {
  const { width, ref } = useResizeDetector();

  return (
    <div
      ref={ref}
      style={style}
      className={cn(styles.card, className, {
        [styles.noPadding]: noPadding,
        [styles.fullScreen]: fullScreen,
      })}
      data-card-lg={width <= 989 ? true : undefined}
      data-card-md={width <= 789 ? true : undefined}
      data-card-sm={width <= 650 ? true : undefined}
      data-card-xs={width <= 500 ? true : undefined}
      data-card-xxs={width <= 420 ? true : undefined}
    >
      {calendarControls}
      {loading && (
        <CardSpinner loading={loading} loadingTimeout={loadingTimeout} />
      )}
      {toolbar}
      <div className={styles.layout}>
        <div className={styles.content}>
          {title}
          {tags && <div className={styles.tags}>{tags}</div>}
          {children}
        </div>
      </div>
      {footer}
    </div>
  );
};
