import React from "react";
import cn from "classnames";

import CardToolbarPopupMenuButton from "framework/components/CardToolbarPopupMenuButton";

import styles from "components/editor/toolbar/CardMainToolbar/index.module.scss";

export const ToolbarMenuItem = ({
  title,
  icon,
  textColor,
  onClick,
  className,
}: any) => {
  return (
    <div onClick={onClick} className={cn(styles.selectItem, className)}>
      {icon && <CardToolbarPopupMenuButton icon={icon} />}
      <div className={styles.selectItemTitle} style={{ color: textColor }}>
        {title}
      </div>
    </div>
  );
};

const ToolbarMenu = ({ children }: any) => {
  return <div className={styles.selectContainer}>{children}</div>;
};

export default ToolbarMenu;
