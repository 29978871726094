import { useCallback, useState } from "react";
import { format, startOfMonth } from "date-fns";
import Calendar from "react-calendar";
import { CalendarNextIcon, CalendarPrevIcon } from "thunk-icons";

import "./dueDateCalendar.scss";

export interface DueDateCalendarProps {
  onDateChange: (date: Date) => void;
  date: Date;
}

const getActiveStartDate = (date: Date) =>
  date ? startOfMonth(date) : startOfMonth(new Date());

const DueDateCalendar = (props: DueDateCalendarProps) => {
  const { onDateChange, date } = props;

  const [activeStartDate, setActiveStartDate] = useState(() =>
    getActiveStartDate(date)
  );

  const onActiveDateChange = useCallback(
    (props: any) => {
      const { activeStartDate } = props;
      setActiveStartDate(activeStartDate);
    },
    [setActiveStartDate]
  );

  return (
    <div className="dueDateCalendar">
      <Calendar
        onChange={onDateChange}
        value={date}
        formatShortWeekday={(locale: any, date: any) => format(date, "EEEEE")}
        activeStartDate={activeStartDate}
        calendarType={"US"}
        onActiveStartDateChange={onActiveDateChange}
        prevLabel={<CalendarPrevIcon size={10} />}
        nextLabel={<CalendarNextIcon size={10} />}
        minDetail="month"
      />
    </div>
  );
};

export default DueDateCalendar;
