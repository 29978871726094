import React from "react";

import { ErrorBoundaryBase } from "components/errors/ErrorBoundaryBase";
import AppResult, {
  ProvideFeedbackButton,
  ResetErrorButton,
} from "components/results/AppResult";

type RootErrorBoundaryProps = {
  children: React.ReactNode;
};

export const RootErrorBoundary = ({ children }: RootErrorBoundaryProps) => {
  return (
    <ErrorBoundaryBase
      fallback={({ resetError, eventId }) => (
        <div style={{ width: "100vw", height: "100vh" }}>
          <AppResult
            isCenteredContent={true}
            resultType="error"
            eventId={eventId}
            actions={
              <>
                <ProvideFeedbackButton eventId={eventId} />
                <ResetErrorButton onReset={() => resetError()} />
              </>
            }
          />
        </div>
      )}
    >
      {children}
    </ErrorBoundaryBase>
  );
};
