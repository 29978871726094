import React, { memo, useCallback, useRef, useEffect } from "react";
import { ReactEditor } from "slate-react";
import isHotkey from "is-hotkey";
import { useHistory } from "react-router-dom";
import { History } from "history";

import { PortalBody } from "framework/components/Portal";
import { appShortcuts } from "appShortcuts";
import ElementPopper from "components/slate/components/ElementPopper";
import ComponentMenuContent from "./ComponentMenuContent";
import { useComponentsMenuPlugin } from "../useComponentsMenuPlugin";
import useEvent from "hooks/useEvent";
import { useStoreCallback, useStoreValue } from "state/utils";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { MenuItem } from "components/slate/plugins/menus/componentMenu/types";

import styles from "./ComponentMenu.module.scss";
import { scrollParentToElement } from "utils/scrollParentToElement";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

let _openTerminalMenu = null;
let _closeTerminalMenu = null;
export const openTerminalMenu = () => {
  _openTerminalMenu && _openTerminalMenu();
};
export const closeTerminalMenu = () => {
  _openTerminalMenu && _openTerminalMenu();
  _closeTerminalMenu && _closeTerminalMenu();
};

const TerminalMenu = memo(() => {
  const history = useHistory();
  const { state } = useComponentsMenuPlugin({});
  const {
    currentElement: targetElement,
    search,
    setSearch,
    isOpened,
    openComponentsMenu,
    closeComponentsMenu,
  } = state;

  const { isMobileScreen } = useDeviceDetector();

  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);
  useEffect(() => {
    if (!isTerminalActive) {
      closeComponentsMenu();
    }
  }, [isTerminalActive]);

  const handleSelect = useStoreCallback(
    (get) => (e: Event, history: History, item: MenuItem) => {
      const editor = get(TerminalStore.activeEditor);

      item.applyMenuItem && item.applyMenuItem({ editor, history });

      const keepOpened =
        "children" in item || item.variant === "back" || item.keepMenuOpened;
      if (!keepOpened) {
        setTimeout(() => {
          // setTimeout because we need to keep it opened for some time to prevent terminal deselection, see data-terminal-menu
          closeComponentsMenu();
        }, 10);
      }
    },
    []
  );
  const handleClose = useCallback(() => {
    closeComponentsMenu();
  }, []);

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    isOpened && setTimeout(() => inputRef.current.focus(), 0);
  }, [isOpened]);

  const openTerminalMenu = useStoreCallback(
    (get) => () => {
      const terminalSelection = get(TerminalStore.activeSelection);
      const pointerId = get(TerminalStore.activePointer);
      const editor = get(TerminalStore.activeEditor);

      if (editor.selection) {
        const index = editor.children.findIndex(
          (x) => terminalSelection.has(x.id) || x.id === pointerId
        );
        const element = editor.children[index];

        const domElement = ReactEditor.toDOMNode(editor, element);

        openComponentsMenu(domElement);

        if (isMobileScreen) {
          scrollParentToElement(domElement, {
            toTop: true,
            topEdge: 60,
          });
        }
      }
    },
    []
  );

  _openTerminalMenu = openTerminalMenu;
  _closeTerminalMenu = closeComponentsMenu;

  const onKeyDown = useEvent(
    useStoreCallback((get) => (e: KeyboardEvent) => {
      const isTerminalActive = get(TerminalStore.isTerminalActive);
      const isMenuOpened = get(TerminalStore.isMenuOpened);

      if (isTerminalActive) {
        if (isHotkey("A", e) && !isMenuOpened) {
          if (isOpened) {
            closeComponentsMenu();
          } else {
            openTerminalMenu();
          }
        }
      }
    })
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <PortalBody>
      <ElementPopper
        isVisible={targetElement != null}
        element={targetElement}
        className={styles.menuTooltip}
        onClickOutside={handleClose}
      >
        <div data-terminal-menu={true}>
          <input
            className={styles.searchInput}
            placeholder="Type to filter actions menu"
            ref={inputRef}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (isHotkey(appShortcuts.toggleComponentsMenu, e)) {
                e.preventDefault();
                closeComponentsMenu();
              }
            }}
          />
          <ComponentMenuContent
            history={history}
            isOpened={targetElement != null}
            setSearch={setSearch}
            search={search}
            onSelect={handleSelect}
            onCancel={handleClose}
          />
        </div>
      </ElementPopper>
    </PortalBody>
  );
});

export default TerminalMenu;
