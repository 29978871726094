import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";

import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import { useSidePanelContext } from "providers/SidePanelProvider";
import { useSidebarContext } from "providers/SidebarProvider";
import DueDateSelectCalendarMenu from "components/tasks/DueDateSelectCalendarMenu/DueDateSelectCalendarMenu";

import styles from "./index.module.scss";

type LayoutProps = {
  notification?: React.ReactNode;
  sidebar?: React.ReactNode;
  sidePanel?: React.ReactNode;
  noDefaultStyles?: boolean;
  fullWidth?: boolean;
  children: React.ReactNode;
};

const Layout = ({
  notification,
  sidebar,
  sidePanel,
  noDefaultStyles,
  fullWidth,
  children,
}: LayoutProps) => {
  const location = useLocation();
  const [animationDisabled, setAnimationDisabled] = useState(true);
  const { isMobileScreen } = useDeviceDetector();
  const { isSidebarOpened, toggleSidebar } = useSidebarContext();
  const { isSidePanelOpened, toggleSidePanel } = useSidePanelContext();

  useEffect(() => {
    isMobileScreen && isSidebarOpened && toggleSidePanel(false);
  }, [isMobileScreen, isSidebarOpened]);

  useEffect(() => {
    isMobileScreen && isSidePanelOpened && toggleSidebar(false);
  }, [isMobileScreen, isSidePanelOpened]);

  useEffect(() => {
    setAnimationDisabled(true);
    const timeout = setTimeout(() => {
      setAnimationDisabled(false);
    }, 700);

    return () => clearTimeout(timeout);
  }, [location]);

  return (
    <>
      {notification}
      <div className={styles.pageContainer}>
        {sidebar}

        {isMobileScreen ? sidePanel : null}

        <div
          className={cn(styles.pageLayout, {
            [styles.noDefaultStyles]: noDefaultStyles,
            [styles.fullWidth]: fullWidth,
          })}
        >
          <div
            className={cn(styles.mainContent, {
              [styles.animationDisabled]: animationDisabled,
            })}
          >
            {children}
            {isMobileScreen ? null : sidePanel}
            <div
              className={styles.mainContentOverlay}
              style={{
                display:
                  (isSidebarOpened || isSidePanelOpened) && isMobileScreen
                    ? "block"
                    : "none",
              }}
              onClick={() => {
                toggleSidePanel(false);
                toggleSidebar(false);
              }}
            />
          </div>
        </div>
      </div>
      <DueDateSelectCalendarMenu />
    </>
  );
};

export default Layout;
