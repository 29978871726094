import React, { Fragment, useEffect, useState } from "react";

import CardSpinner from "framework/components/Card/CardSpinner";

type Props = {
  isDelayed: boolean;
};

const DelayedRender = ({
  isDelayed,
  children,
}: React.PropsWithChildren<Props>) => {
  const [isRendered, setIsRendered] = useState(!isDelayed);

  useEffect(() => {
    if (isDelayed) {
      setTimeout(() => {
        setIsRendered(true);
      }, 50);
    }
  }, []);

  if (!isRendered) {
    return (
      <div style={{ flex: 1 }}>
        <CardSpinner />
      </div>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default DelayedRender;
