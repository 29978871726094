import React, { forwardRef } from "react";
import cn from "classnames";

import styles from "./index.module.scss";

const GraphFilterTab = forwardRef(
  (
    {
      isActive,
      onClick,
      activeClassName,
      svgComponent: SvgComponent,
      activeSvgComponent: ActiveSvgComponent = SvgComponent,
      title,
    }: any,
    ref
  ) => {
    return (
      <button
        ref={ref as any}
        className={cn(styles.tab, {
          [activeClassName]: isActive,
          [styles.active]: isActive,
        })}
        onClick={onClick}
      >
        <div className={styles.iconContainer}>
          <SvgComponent className={styles.icon} size={28} />
          <ActiveSvgComponent className={styles.activeIcon} size={28} />
        </div>
        <span className={styles.text}>{title}</span>
      </button>
    );
  }
);

export default GraphFilterTab;
