import { useEffect } from "react";

const useWindowEventListener = <K extends keyof WindowEventMap>(
  eventName: K,
  fn: (this: Window, ev: WindowEventMap[K]) => any,
  dependencies: any[] = []
) => {
  useEffect(() => {
    window.addEventListener(eventName, fn);

    return () => window.removeEventListener(eventName, fn);
  }, [...dependencies]);
};

export default useWindowEventListener;
