import { UseSlatePlugin } from "components/slate/types";
import * as handlers from "components/slate/plugins/shortcuts/handlers";

const useShortcutsPlugin: UseSlatePlugin = () => {
  return {
    handlers,
  };
};

export default useShortcutsPlugin;
