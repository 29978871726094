import React from "react";
import { useTranslation } from "react-i18next";

import Button from "framework/components/form/Button";
import { env } from "thunk-env";

const HelpContent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h3>Join Our Discord for Help</h3>
      <p style={{ marginBottom: 30, fontSize: 14 }}>
        {t("help_and_feedback.dialog.copy")}
        <a href={`mailto:${env.REACT_APP_SUPPORT_EMAIL}`}>
          {env.REACT_APP_SUPPORT_EMAIL}
        </a>
      </p>
      <a
        href="https://discord.com/invite/nsNqeVjvfz"
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="secondary">Join for Help</Button>
      </a>
    </div>
  );
};

export default HelpContent;
