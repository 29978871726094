import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { Editor, Element } from "slate";

import {
  CheckableListItem,
  CheckListItemElement,
  ListItemElement,
  ListItemType,
  ListTypes,
  TodoListItemElement,
} from "./types";

export const isListItemElement = (value: any): value is ListItemElement => {
  return Element.isElementType<ListItemElement>(value, ListItemType);
};

export const isListItemType = (type: Element["type"]) => {
  return type === ListItemType;
};

export const isCheckableListItem = (value: any): value is CheckableListItem => {
  return (
    (Element.isElementType<CheckableListItem>(value, ListItemType) &&
      value.listType === ListTypes.TodoList) ||
    value.listType === ListTypes.CheckList
  );
};

export const isTodoListItemElement = (
  value: any
): value is TodoListItemElement => {
  return (
    Element.isElementType<TodoListItemElement>(value, ListItemType) &&
    value.listType === ListTypes.TodoList
  );
};

export const isCheckListItemElement = (
  value: any
): value is TodoListItemElement => {
  return (
    Element.isElementType<CheckListItemElement>(value, ListItemType) &&
    value.listType === ListTypes.CheckList
  );
};

export const getInheritedDueDate = (editor: Editor, el: Element) => {
  const parent = ExtendedEditor.semanticParent(editor, el);
  if (!parent) {
    return null;
  }
  const parentEl = parent.element;

  if (isTodoListItemElement(parentEl)) {
    if (parentEl.dueDate) {
      return parentEl.dueDate;
    }
  }

  return getInheritedDueDate(editor, parentEl);
};

export const getInheritedChecked = (editor: Editor, el: Element) => {
  const parent = ExtendedEditor.semanticParent(editor, el);
  if (!parent) {
    if (isCheckableListItem(el)) {
      return el.checked;
    } else {
      return false;
    }
  }
  const parentEl = parent.element;
  if (isCheckableListItem(parentEl)) {
    if (parentEl.checked) {
      return true;
    } else {
      return getInheritedChecked(editor, parentEl);
    }
  }
  return getInheritedChecked(editor, parentEl);
};
