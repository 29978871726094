import React from "react";

import { ErrorBoundaryBase } from "components/errors/ErrorBoundaryBase";
import AppResult, {
  ProvideFeedbackButton,
  RefreshPageButton,
  ResetErrorButton,
} from "components/results/AppResult";

type EditorErrorBoundaryProps = {
  children: React.ReactNode;
  isMainEditor?: boolean;
};

export const EditorErrorBoundary = ({
  isMainEditor,
  children,
}: EditorErrorBoundaryProps) => {
  return (
    <ErrorBoundaryBase
      fallback={({ resetError, eventId }) => (
        <AppResult
          isCenteredContent={true}
          resultType="error"
          eventId={eventId}
          actions={
            <>
              <ProvideFeedbackButton eventId={eventId} />
              {isMainEditor ? (
                <RefreshPageButton />
              ) : (
                <ResetErrorButton onReset={() => resetError()} />
              )}
            </>
          }
        />
      )}
    >
      {children}
    </ErrorBoundaryBase>
  );
};
