import React, { memo, useCallback, useMemo } from "react";
import { Descendant, Element } from "slate";
import { observer } from "mobx-react-lite";
import { omit } from "ramda";

import { Block, Document } from "thunk-core";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import ReferenceGroupTitle from "components/editor/DocumentReferences/ReferenceGroup/ReferenceGroupTitle";
import { EditorType } from "components/slate/types";
import { useEditingSession } from "stores/hooks/useEditingSession";
import { useCurrentUserId } from "db/currentUser";
import { useDocumentUserContext } from "providers/DocumentUserProvider";
import { useDocumentsStore } from "stores/store";
import SlateReferenceEditor from "components/slate/editors/SlateReferenceEditor";
import { mapBlockToElement } from "db/blocks/blocks.mapping";
import SlateSimpleReadOnlyEditor from "components/slate/editors/SlateSimpleReadOnlyEditor";
import { useAppReadOnly } from "auth/hooks/useAppReadOnly";
import BlockEditor from "components/editor/BlockEditor";
import { isHeadingType } from "components/slate/plugins/heading/utils";
import { isListItemType } from "components/slate/plugins/list/utils";

import styles from "./index.module.scss";

type Props = {
  referenceTargetId: string;
  isSharing: boolean;
  document: Document;
  blocks: Block[];
  isOpened: boolean;
  handleToggle: (targetId: string, isCollapsed: boolean) => void;
  onBacklinksChange?: (referencesDiff: any) => void;
  onBacklinkClick?: (element: BacklinkElement) => void;
};

const ReferenceGroup = observer((props: Props) => {
  const {
    referenceTargetId,
    isSharing,
    document,
    blocks,
    isOpened,
    handleToggle,
    onBacklinkClick,
  } = props;

  return (
    <div className={styles.referenceGroup}>
      <ReferenceGroupTitle
        referenceTargetId={referenceTargetId}
        document={document}
        isSharing={isSharing}
        isOpened={isOpened}
        handleToggle={handleToggle}
        onBacklinkClick={onBacklinkClick}
      />
      {blocks.map((block) => {
        if (
          isHeadingType(block.type as Element["type"]) ||
          isListItemType(block.type as Element["type"])
        ) {
          return (
            <ReferenceEditor
              key={block.id}
              {...omit(["blocks"], props)}
              block={block}
            />
          );
        }

        return (
          <ReferenceBlockEditor
            key={block.id}
            {...omit(["blocks"], props)}
            block={block}
          />
        );
      })}
    </div>
  );
});

export default ReferenceGroup;

const ReferenceEditor = (props: Omit<Props, "blocks"> & { block: Block }) => {
  const {
    document,
    block,
    isOpened,
    onBacklinksChange,
    onBacklinkClick,
  } = props;

  const userId = useCurrentUserId();

  const documentId = document.id;
  const documentType = document.type;

  const documentsStore = useDocumentsStore(documentType);

  const { documentUserId } = useDocumentUserContext();

  const readOnly = useAppReadOnly();

  const { loading, slateId, content } = useEditingSession({
    userId,
    editorType: EditorType.ReferenceEditor,
    documentId: documentId,
    dateId: document.dateId,
    documentType: documentType,
  });

  const handleContentChange = useCallback(
    (value: Descendant[]) => {
      documentsStore.updateContent({
        slateId,
        userId: documentUserId,
        documentId,
        nextContent: value as Element[],
      });
    },
    [documentId, slateId, documentUserId]
  );

  return (
    <div style={{ display: isOpened ? "block" : "none" }}>
      {(function () {
        if (loading) {
          // render lighter block editor for performance optimization
          return (
            <div className={styles.placeholderBlock}>
              <SlateSimpleReadOnlyEditor value={[mapBlockToElement(block)]} />
            </div>
          );
        }

        return (
          <SlateReferenceEditor
            slateId={slateId}
            userId={userId}
            contextId={block.id}
            contextType={block.type as Element["type"]}
            contextDepth={block.properties.depth ?? 0}
            value={content}
            onChange={handleContentChange}
            readOnly={readOnly}
            documentId={documentId}
            documentType={documentType}
            onBacklinksChange={onBacklinksChange}
            onBacklinkClick={onBacklinkClick}
          />
        );
      })()}
    </div>
  );
};

const ReferenceBlockEditor = memo(
  (props: Omit<Props, "blocks"> & { block: Block }) => {
    const { isOpened, block, onBacklinksChange, onBacklinkClick } = props;

    const content = useMemo(() => [mapBlockToElement(block)], [block]);

    return (
      <div
        className={styles.blockEditorWrapper}
        style={{ display: isOpened ? "block" : "none" }}
      >
        <BlockEditor
          block={block}
          content={content}
          onBacklinksChange={onBacklinksChange}
          onBacklinkClick={onBacklinkClick}
        />
      </div>
    );
  }
);
