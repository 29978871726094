import React from "react";

import { useDeviceDetector } from "providers/DeviceDetectorProvider";

type MenuShortcutProps = {
  component: React.ComponentType<any>;
  windowsComponent?: React.ComponentType<any>;
};

const MenuShortcut = ({
  component: Component,
  windowsComponent: WindowsComponent,
}: MenuShortcutProps) => {
  const { isWindows } = useDeviceDetector();

  if (isWindows && WindowsComponent) {
    return <WindowsComponent width={null} height={24} fill="inherit" />;
  }

  return <Component width={null} height={24} fill="inherit" />;
};

export default MenuShortcut;
