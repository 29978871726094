import { useMemo } from "react";
import { format, isToday, parse, startOfDay } from "date-fns";
import crawl from "tree-crawl";
import slugify from "slugify";
import { Descendant } from "slate";
import { isEmpty } from "ramda";

import { getInitialValue } from "components/slate/utils";
import { formatDifferenceInDays } from "utils/dateUtils";
import { DocumentType } from "thunk-core";

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const DATE_ID_FORMAT = "MM-dd-yyyy";
export const makeDateId = (date: Date): string => {
  return format(date, DATE_ID_FORMAT);
};
export const dateIdToDate = (noteId: string): Date => {
  return parse(noteId, DATE_ID_FORMAT, new Date());
};
export const useDateFromUrl = (searchDate: string) => {
  return useMemo<Date>(() => getDateFromUrl(searchDate), [searchDate]);
};

export const getDateFromUrl = (searchDate: string) => {
  return startOfDay(searchDate == null ? new Date() : dateIdToDate(searchDate));
};

// TODO: check if it is case insensitive
export const makeSharedId = (id: string): string => {
  const result = slugify(id);
  return result;
};

export const mapContentFromJSON = (
  contentJSON: string | null = null
): Descendant[] => {
  try {
    const result = JSON.parse(contentJSON) as Descendant | Descendant[];

    if (result === null || isEmpty(result)) {
      return getInitialValue();
    }

    if (!Array.isArray(result)) {
      // handle only one node storing, for snippets in refs
      return [result];
    }

    return result;
  } catch (error) {
    console.error(error);
    return getInitialValue();
  }
};

export const mapContentToJSON = (
  content: Descendant | Descendant[]
): string => {
  const result = JSON.stringify(content);
  return result;
};

export const crawlContent = (
  content: Descendant | Descendant[],
  fn: (node: Descendant, context: crawl.Context<Descendant>) => void
) => {
  if (!content) {
    return;
  }

  const isArray = Array.isArray(content); // for content (nodes array) and reference snippet (one node)
  const root = { children: isArray ? content : [content] } as Descendant;

  crawl<Descendant>(
    root,
    (node, context) => {
      if (node === root) {
        return;
      }

      fn(node, context);
    },
    {}
  );
};

export const treeFlatMap = <T extends { children: T[] }, U>(
  items: T[],
  map: (node: T, context: crawl.Context<T>) => U
) => {
  const newItems: U[] = [];

  const root = { children: items } as T;

  crawl<T>(
    root,
    (node, context) => {
      if (node === root) {
        return;
      }

      const newNode = map(node, context);

      newItems.push(newNode);
    },
    {}
  );

  return newItems;
};

export const getBacklinkTitle = (
  type: DocumentType,
  id: string,
  title: string
) => {
  return type === DocumentType.NOTE
    ? formatDifferenceInDays(dateIdToDate(id))
    : title;
};

export const getEditorPathname = (
  type: DocumentType,
  id: string | null,
  dateId: string | null,
  blockId: string = ""
) => {
  type = type || DocumentType.PAGE;

  switch (type) {
    case DocumentType.NOTE:
      return !dateId || isToday(dateIdToDate(dateId))
        ? "/writing"
        : `/writing?date=${dateId}${blockId ? `&block=${blockId}` : ""}`;
    case DocumentType.PAGE:
      return `/page/${id}${blockId ? `?block=${blockId}` : ""}`;
    case DocumentType.SNIPPET:
      return `/snippets/${id}${blockId ? `?block=${blockId}` : ""}`;
    default:
      return "";
  }
};

export const getCommonEditorPathname = (
  userId: string,
  type: DocumentType,
  id: string,
  dateId: string | null
) => {
  type = type || DocumentType.PAGE;

  switch (type) {
    case DocumentType.NOTE:
      // not implemented
      return !dateId || isToday(dateIdToDate(dateId))
        ? "/writing"
        : `/writing?date=${dateId}`;
    case DocumentType.PAGE:
      return `/users/${userId}/page/${id}`;
    case DocumentType.SNIPPET:
      return `/users/${userId}/snippets/${id}`;
    default:
      return "";
  }
};
