import React from "react";

import HeaderLogo from "components/layout/HeaderLogo";

import styles from "./index.module.scss";

export const HeaderLayout = ({ children }: any) => {
  return (
    <header className={styles.header}>
      <HeaderLogo className={styles.logo} />

      <div className={styles.menu}>{children}</div>
    </header>
  );
};
