const getForkData = () => {
  const forkPage = window.sessionStorage.getItem("forkPage");

  let parsedData;
  try {
    parsedData = JSON.parse(forkPage);
  } catch {
    parsedData = null;
  }

  return parsedData;
};

export default getForkData;
