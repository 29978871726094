import usePersistedUserState from "hooks/usePersistedUserState";

export type Order = "asc" | "desc";

export type SortingState<T extends object> = {
  field: keyof T;
  order: Order;
};

const useSorting = <T extends object>(
  id: string,
  initialState: SortingState<T>
): [SortingState<T>, (field: keyof T, order: Order) => void] => {
  const [sorting, setSorting] = usePersistedUserState<SortingState<T>>(
    id,
    (restored) => restored ?? initialState
  );

  const updateSorting = (field: keyof T, order: Order) => {
    return setSorting((state) => {
      if (state.field === field) {
        return {
          ...state,
          order: state.order === "asc" ? "desc" : "asc",
        };
      }

      return {
        field,
        order,
      };
    });
  };

  return [sorting, updateSorting];
};

export default useSorting;
