import { Editor, Element, Text } from "slate";

const withBlock = (editor: Editor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    const isInsertableNode =
      "node" in operation &&
      ((Element.isElement(operation.node) && editor.isInline(operation.node)) ||
        Text.isText(operation.node));

    switch (operation.type) {
      case "insert_node":
        if (isInsertableNode) {
          apply(operation);
        }
        return;
      case "merge_node":
        apply(operation);
        return;
      case "move_node":
        return;
      case "remove_node":
        if (isInsertableNode) {
          apply(operation);
        }
        return;
      case "set_node":
        if (
          "type" in operation.properties &&
          "type" in operation.newProperties &&
          operation.properties.type !== operation.newProperties.type
        ) {
          // don't allow changing type
          return;
        }
        apply(operation);
        return;
      case "split_node":
        apply(operation);
        return;
      case "set_selection":
        apply(operation);
        return;
      case "insert_text":
        apply(operation);
        return;
      case "remove_text":
        apply(operation);
        return;
    }
  };

  return editor;
};

export default withBlock;
