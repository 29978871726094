import { Element } from "slate";
import { omit } from "ramda";

import { mapContentFromJSON, mapContentToJSON } from "helpers";
import {
  findBacklinkNodes,
  findTagsNodes,
  getInitialValue,
} from "components/slate/utils";
import { Block, DocumentType } from "thunk-core";

export const mapElementToBlock = (
  {
    userId,
    pageId,
    pageType,
    dateId,
    lastModifiedSession = null,
  }: {
    userId: string;
    pageId: string;
    pageType: DocumentType;
    dateId: string;
    lastModifiedSession?: string | null;
  },
  element: Element
): Block => {
  return {
    id: element.id,
    userId,
    pageId,
    pageType,
    dateId,
    type: element.type,
    contentJSON: mapContentToJSON(element.children),
    properties: omit(["id", "type", "children"], element),
    targets: findBacklinkNodes(element.children).map((x) => x.targetId),
    tags: findTagsNodes(element.children).map((x) => x.tagId),
    lastModifiedSession,
  };
};

export const mapBlockToElement = (block: Block): Element => {
  return {
    id: block.id,
    type: block.type,
    children: mapContentFromJSON(block.contentJSON),
    ...block.properties,
  } as Element;
};

export const getBlocksContent = (blocks: Block[]): Element[] => {
  const content = blocks.length
    ? blocks.map(mapBlockToElement)
    : getInitialValue("");

  return content as Element[];
};
