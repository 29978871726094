import { useEffect } from "react";

const useScrollToTop = (deps: any) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, deps);
};

export default useScrollToTop;
