import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useCurrentUserId } from "db/currentUser";
import { useUserData } from "./useUserData";

const useForceOnboarding = () => {
  const history = useHistory();
  const userId = useCurrentUserId();
  const [currentUserProfile, isLoading] = useUserData(userId);

  useEffect(() => {
    if (isLoading || !currentUserProfile) {
      return;
    }

    if (
      currentUserProfile.shouldSeeOnboarding &&
      !currentUserProfile.hasCompletedOnboarding
    ) {
      history.push("/onboarding");
    }
  }, [currentUserProfile, history, isLoading]);

  return { isLoading };
};

export default useForceOnboarding;
