import { FocusEvent, KeyboardEvent, useCallback } from "react";
import { Editor, Range, Point } from "slate";
import isHotkey from "is-hotkey";

import { UseSlatePlugin } from "components/slate/types";
import { AtMenuProps } from "components/slate/plugins/menus/atMenu/types";
import useZustandCreateStore from "hooks/useZustandCreateStore";
import createState from "components/slate/plugins/menus/atMenu/state";
import useMemoObject from "hooks/useMemoObject";
import { ELEMENTS_TEXT_BLOCKS } from "components/slate/plugins/menus/componentMenu/constants";
import getBeforeText from "components/slate/queries/getBeforeText";
import getAfterText from "components/slate/queries/getAfterText";
import { NavigationKeys } from "constants/navigationKeys";

const useAtMenuPlugin: UseSlatePlugin<{}, AtMenuProps> = () => {
  const { state, getState } = useZustandCreateStore(createState, []);

  const onChange = useCallback(
    (editor) => () => {
      const { target, setSearch, closeMenu, openMenu } = getState();
      const { selection } = editor;

      if (target != null && selection && Range.isCollapsed(selection)) {
        const newTarget = {
          anchor: Range.start(target),
          focus: Range.start(selection),
        };

        const isForward =
          Range.isForward(newTarget) && !Range.isCollapsed(newTarget);
        const isSameBlock =
          newTarget.anchor.path[0] === newTarget.focus.path[0];

        if (isForward && isSameBlock) {
          const skipTrigger = Editor.after(editor, Range.start(target));
          const searchTarget = {
            anchor: skipTrigger,
            focus: Range.start(selection),
          };
          const search = Editor.string(editor, searchTarget);

          setSearch(search, newTarget);

          if (search === "") {
            // workaround, change initial target after @ symbol is typed
            // fix when menu called after soft break (\n symbol)
            openMenu(newTarget);
          }

          return;
        }

        closeMenu();
      }
    },
    [getState]
  );

  const onKeyDown = useCallback(
    (editor: Editor) => (e: KeyboardEvent) => {
      const { target, openMenu } = getState();

      const { selection } = editor;

      if (target == null) {
        if (e.key === "@" && selection) {
          // check if it is right block
          const blockEntry = Editor.above(editor, {
            mode: "lowest",
            match: (node) => {
              return (
                Editor.isBlock(editor, node) &&
                ELEMENTS_TEXT_BLOCKS.has(node.type)
              );
            },
          });

          // check if at pressed not in inline
          const [inlinesMatch] = Editor.nodes(editor, {
            mode: "lowest",
            match: (node) => Editor.isInline(editor, node),
          });

          const blockRange = Editor.range(editor, blockEntry[1]);
          const [start, end] = Editor.edges(editor, selection);
          const isStart =
            Point.equals(blockRange.anchor, start) ||
            [" ", "\n"].includes(
              getBeforeText(editor, start, { unit: "character" })
            );
          const isEnd =
            Point.equals(blockRange.focus, end) ||
            [" ", "\n"].includes(
              getAfterText(editor, end, { unit: "character" })
            );

          if (blockEntry && !inlinesMatch && isStart && isEnd) {
            // open at menu on at pressed
            openMenu({ anchor: selection.focus, focus: selection.focus });
          }
        }
      } else {
        if (isHotkey(NavigationKeys.Enter, e)) {
          e.preventDefault();
          return;
        }
      }
    },
    [getState]
  );

  const onBlur = useCallback(
    (editor: Editor) => (e: FocusEvent) => {
      const { closeMenu } = getState();
      closeMenu();
    },
    [getState]
  );

  return {
    handlers: {
      onKeyDown,
      onBlur,
    },
    onChange,
    state: useMemoObject<AtMenuProps>({
      initialTarget: state.initialTarget,
      target: state.target,
      search: state.search,
      closeMenu: state.closeMenu,
    }),
  };
};

export default useAtMenuPlugin;
