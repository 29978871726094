import { Element } from "slate";

import {
  CodeBlockElement,
  CodeBlockType,
  CodeLineElement,
  CodeLineType,
} from "components/slate/plugins/codeBlock/types";

export const isCodeBlockElement = (value: any): value is CodeBlockElement => {
  return Element.isElementType<CodeBlockElement>(value, CodeBlockType);
};

export const isCodeLineElement = (value: any): value is CodeLineElement => {
  return Element.isElementType<CodeLineElement>(value, CodeLineType);
};
