type TreeNode<T> = { item: T; children: TreeNode<T>[]; level: number };

const buildTree = <T extends { id: string; parentId: string }>(
  array: T[]
): TreeNode<T>[] => {
  const list: TreeNode<T>[] = [];
  const idToIndex = new Map<string, number>();

  for (let i = 0; i < array.length; i++) {
    const item = array[i];

    idToIndex.set(item.id, i);
    list.push({ item, children: [], level: 0 });
  }

  for (const page of list) {
    if (page.item.parentId) {
      const parent = list[idToIndex.get(page.item.parentId)];

      if (parent) {
        parent.children.push(page);
      }
    }
  }

  return list;
};

export default buildTree;
