import React, { memo, useCallback, useEffect, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import {
  MemoryRouter,
  Route,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { History } from "history";

import MenuList from "../MenuList";
import MenuListItem from "../MenuListItem";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import {
  useFormattingMenuConfig,
  useMenuConfig,
} from "components/slate/plugins/menus/terminalMenu/items";
import TransitionSwitch from "components/slate/plugins/menus/componentMenu/components/TransitionSwitch";
import { MenuItem } from "components/slate/plugins/menus/componentMenu/types";

export type ComponentMenuContentProps = {
  history: History;
  isOpened: boolean;
  search: string;
  setSearch: (search: string) => void;
  onSelect: (e: Event, history: History, item: MenuItem) => void;
  onCancel: () => void;
};

const ComponentMenuContent = memo((props: ComponentMenuContentProps) => {
  const [height, setHeight] = useState(0);

  if (!props.isOpened) {
    // reset router
    return null;
  }

  return (
    <MemoryRouter>
      <TransitionSwitch height={height + 5}>
        <Route exact path="/:groupId?">
          <WidgetErrorBoundary isEmptyFallback={true}>
            <Menu {...props} onHeightChange={setHeight} />
          </WidgetErrorBoundary>
        </Route>
      </TransitionSwitch>
    </MemoryRouter>
  );
});

const Menu = (
  props: ComponentMenuContentProps & {
    onHeightChange: (height: number) => void;
  }
) => {
  const history = useHistory();
  const location = useLocation();

  const {
    isOpened,
    search,
    setSearch,
    onSelect,
    onCancel,
    onHeightChange,
  } = props;
  const { groupId } = useParams<{ groupId: string }>();
  const { height = 0, ref: containerRef } = useResizeDetector();

  useEffect(() => {
    onHeightChange(height);
  }, [height]);

  useEffect(() => {
    setSearch("");
  }, [location.pathname]);

  const handleSelect = useCallback(
    (e: Event, item: MenuItem) => {
      onSelect(e, history, item);
    },
    [onSelect]
  );

  const config = useFormattingMenuConfig({ search, groupId });

  return (
    <WidgetErrorBoundary isEmptyFallback={true}>
      <MenuList
        containerRef={containerRef}
        isOpened={isOpened}
        items={config}
        renderListItem={({ item, isActive }) => (
          <MenuListItem
            item={item}
            isActive={isActive}
            onClick={(e) => handleSelect(e, item)}
          />
        )}
        onSelect={handleSelect}
        onCancel={onCancel}
      />
    </WidgetErrorBoundary>
  );
};

export default ComponentMenuContent;
