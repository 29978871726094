import React, { ForwardedRef } from "react";
import cn from "classnames";
import { CrossIcon } from "thunk-icons";

import styles from "./index.module.scss";
import { Tag as TagDb } from "thunk-core";

type TagProps = {
  style?: React.CSSProperties;
  containerRef?: ForwardedRef<HTMLDivElement>;
  as?: React.ComponentType<any> | React.ElementType;
  to?: string;
  emoji?: TagDb["emoji"];
  color?: TagDb["color"];
  variant?: "outlined" | "filled";
  size?: "default" | "circle";
  isSelected?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  inline?: boolean;
  onClick?: React.MouseEventHandler;
  onRemove?: React.MouseEventHandler;
  children?: React.ReactNode;
};

const Tag = (props: TagProps) => {
  const {
    style,
    containerRef,
    as = "span",
    to,
    emoji = null,
    color = "default",
    variant = "outlined",
    size = "default",
    isSelected = false,
    readOnly = false,
    inline = false,
    disabled = false,
    onClick,
    onRemove,
    children,
  } = props;

  const className = cn(
    styles.container,
    colorClasses[color],
    variantClasses[variant],
    sizeClasses[size],
    styles.test,
    {
      [styles.readOnly]: readOnly,
      [styles.inline]: inline,
      [styles.disabled]: disabled,
    }
  );

  const Component = as;

  return (
    <div
      ref={containerRef}
      style={style}
      className={className}
      onClick={onClick}
    >
      {isSelected && <span className={styles.check}>✓</span>}
      <Component
        className={styles.label}
        to={to || "#"}
        onClick={(e) => {
          if (disabled) {
            e.preventDefault();
          }
        }}
      >
        {emoji && <span className={styles.emoji}>{emoji.native}</span>}
        {typeof children === "string" ? getTagName(children) : children}
      </Component>
      {!readOnly && (
        <button className={styles.remove} onClick={onRemove}>
          <CrossIcon size={14} />
        </button>
      )}
    </div>
  );
};

export default Tag;

const colorClasses = {
  default: styles.colorDefault,
  green: styles.colorGreen,
  "light-blue": styles.colorLightBlue,
  blue: styles.colorBlue,
  pink: styles.colorPink,
  red: styles.colorRed,
  orange: styles.colorOrange,
  yellow: styles.colorYellow,
  black: styles.colorBlack,
};

const variantClasses = {
  outlined: styles.variantOutlined,
  filled: styles.variantFilled,
};

const sizeClasses = {
  default: styles.sizeDefault,
  circle: styles.sizeCircle,
};

const getTagName = (title: string) => {
  if (title.lastIndexOf("/") !== -1) {
    return title.substring(title.lastIndexOf("/") + 1);
  }

  return title;
};
