import { UseSlatePlugin } from "components/slate/types";
import { withVoidsDeletion } from "components/slate/plugins/voidsDeletion/withVoidsDeletion";

const useVoidsDeletionPlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withVoidsDeletion,
  };
};

export default useVoidsDeletionPlugin;
