import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Node } from "slate";
import { MemoryRouter, Route, useHistory, useParams } from "react-router-dom";
import { useResizeDetector } from "react-resize-detector";
import { History } from "history";

import {
  makeTemplateMenuItem,
  MenuItems,
} from "components/slate/plugins/menus/componentMenu/items";
import { isEmptyNodeTrimmed } from "components/slate/utils";
import MenuList from "components/slate/plugins/menus/componentMenu/components/MenuList";
import MenuListItem from "components/slate/plugins/menus/componentMenu/components/MenuListItem";
import TransitionSwitch from "components/slate/plugins/menus/componentMenu/components/TransitionSwitch";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import {
  getDefaultConfig,
  getGroupConfig,
  getSearchConfig,
} from "components/slate/plugins/menus/componentMenu/items/utils";
import { useDocumentsStore } from "stores/store";
import { DocumentType } from "thunk-core";
import { MenuItem } from "components/slate/plugins/menus/componentMenu/types";

export type ComponentMenuContentProps = {
  isOpened: boolean;
  node: Node | null;
  search: string;
  onSelect: (e: Event, history: History, item: MenuItem) => void;
  onCancel: () => void;
};

const ComponentMenuContent = memo((props: ComponentMenuContentProps) => {
  const [height, setHeight] = useState(0);

  if (!props.isOpened) {
    // reset router
    return null;
  }

  return (
    <MemoryRouter>
      <TransitionSwitch height={height}>
        <Route exact path="/:groupId?">
          <WidgetErrorBoundary isEmptyFallback={true}>
            <Menu {...props} onHeightChange={setHeight} />
          </WidgetErrorBoundary>
        </Route>
      </TransitionSwitch>
    </MemoryRouter>
  );
});

const Menu = (
  props: ComponentMenuContentProps & {
    onHeightChange: (height: number) => void;
  }
) => {
  const { isOpened, node, search, onSelect, onCancel, onHeightChange } = props;

  const history = useHistory();
  const { groupId } = useParams<{ groupId: string }>();
  const documentsStore = useDocumentsStore(DocumentType.SNIPPET);
  const templates = documentsStore.getDocuments();
  const { height = 0, ref: containerRef } = useResizeDetector();

  useEffect(() => {
    onHeightChange(height);
  }, [height]);

  const handleSelect = useCallback(
    (e: Event, item: MenuItem) => {
      onSelect(e, history, item);
    },
    [onSelect]
  );

  const config = useMemo(() => {
    if (!node) {
      return [];
    }

    const isSameAsTrigger = Node.string(node).trim() === "/";
    const isEmpty = isEmptyNodeTrimmed(node) || isSameAsTrigger;

    if (search) {
      const isSameAsSearch = Node.string(node).trim().indexOf(search) === 1; // inline editor search case
      const isEmptyForSearch = isEmpty || isSameAsSearch;

      return [
        ...getSearchConfig([node], isEmptyForSearch, search),
        ...templates
          .map((template) =>
            makeTemplateMenuItem(
              template.title,
              documentsStore.getDocumentContent(template.id)
            )
          )
          .filter((t) => t.text.toLowerCase().includes(search.toLowerCase())),
      ];
    }

    if (groupId === "snippets") {
      return [
        MenuItems.back,
        ...templates.map((template) =>
          makeTemplateMenuItem(
            template.title,
            documentsStore.getDocumentContent(template.id)
          )
        ),
      ];
    }

    if (groupId) {
      return getGroupConfig([node], isEmpty, groupId);
    }

    return getDefaultConfig([node], isEmpty);
  }, [groupId, node, search]);

  return (
    <MenuList
      containerRef={containerRef}
      isOpened={isOpened}
      items={config}
      renderListItem={({ item, isActive }) => (
        <MenuListItem
          item={item}
          isActive={isActive}
          onClick={(e) => handleSelect(e, item)}
        />
      )}
      onSelect={handleSelect}
      onCancel={onCancel}
    />
  );
};

export default ComponentMenuContent;
