import { doc, updateDoc } from "firebase/firestore";

import { db } from "firebaseInstance";
import { getUserPath, USER_CONVERTER } from "thunk-core";

export const setTemplatesTipSeen = async (userId: string) => {
  await updateDoc(doc(db, getUserPath(userId)).withConverter(USER_CONVERTER), {
    isTemplateTipSeen: true,
  });
};
