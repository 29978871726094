import React from "react";
import cn from "classnames";
import { ArrowRightIcon } from "thunk-icons";
import { observer } from "mobx-react-lite";

import ButtonGroup from "framework/components/form/ButtonGroup";
import Button from "framework/components/form/Button";
import useAppPopper from "framework/components/poppers/useAppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import AppPopper from "framework/components/poppers/AppPopper";

import commonStyles from "../../index.module.scss";
import styles from "./index.module.scss";
import SimpleTooltip from "components/SimpleTooltip";

type Props = {
  isActive: boolean;
  setActive: () => void;
  hasPopper: boolean;
  renderPopperContent: () => React.ReactNode;
};

const BacklinksTabButton = observer((props: Props) => {
  const { renderPopperContent, hasPopper, isActive, setActive } = props;

  const popperProps = useAppPopper({ offset: [0, 13], hasArrow: true });
  useClickPopper(popperProps);

  return (
    <div style={{ order: 1 }} className={styles.root}>
      <AppPopper
        {...popperProps}
        className={styles.popperContainer}
        content={renderPopperContent()}
      />

      <ButtonGroup
        className={cn({
          [commonStyles.activeTab]: isActive,
        })}
      >
        <SimpleTooltip content="Switch Tab (Ctrl+7)">
          <Button
            variant="circularCardHeader"
            size="large"
            onClick={setActive}
            btnClass={styles.tabButton}
          >
            Links
          </Button>
        </SimpleTooltip>
        {hasPopper && (
          <Button
            btnClass={cn(styles.tabButton, styles.carat, {
              [styles.hovered]: popperProps.isOpened,
            })}
            variant="circularCardHeader"
            size="symbol"
            ref={popperProps.setTargetElement}
            icon={ArrowRightIcon}
            iconSize={14}
            onMouseDown={() => {
              setActive();
            }}
          />
        )}
      </ButtonGroup>
    </div>
  );
});

export default BacklinksTabButton;
