import React, { useState } from "react";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Trans } from "react-i18next";
import { confirmPasswordReset } from "firebase/auth";

import { auth } from "firebaseInstance";
import NewPasswordForm from "pages/auth/NewPasswordPage/NewPasswordForm";
import Button from "framework/components/form/Button";
import { getErrorInfo } from "./errors";

import styles from "./index.module.scss";
import { env } from "thunk-env";

const NewPasswordPage = ({ location }: any) => {
  const parsedSearch = queryString.parse(location.search);
  const oobCode = Array.isArray(parsedSearch.oobCode)
    ? parsedSearch.oobCode[1]
    : parsedSearch.oobCode;

  const [submitError, setSubmitError] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (data: any) => {
    setSubmitting(true);
    try {
      const { password } = data;
      await confirmPasswordReset(auth, oobCode, password);

      setPasswordChanged(true);
      setSubmitting(false);
    } catch (error) {
      console.error(error);

      const errorInfo = getErrorInfo(error);
      //  @ts-ignore ts-migrate(2345) FIXME: Argument of type '{ message: any; code: any; }' is... Remove this comment to see the full error message
      setSubmitError(errorInfo);
      setSubmitting(false);
    }
  };

  const error = submitError && {
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'code' does not exist on type 'string'.
    code: submitError.code,
    message: (
      <Trans
        //  @ts-ignore ts-migrate(2339) FIXME: Property 'message' does not exist on type 'string'... Remove this comment to see the full error message
        i18nKey={submitError.message}
        values={{ email: env.REACT_APP_SUPPORT_EMAIL }}
        components={{
          email: <a href={`mailto:${env.REACT_APP_SUPPORT_EMAIL}`} />,
        }}
      />
    ),
  };

  return (
    <div className={styles.container}>
      {passwordChanged ? (
        <div className={styles.success}>
          <h3>Password Changed</h3>
          <p>You can now sign in with your new password</p>
          <Link to="/signin">
            <Button variant="secondary">Sign in</Button>
          </Link>
        </div>
      ) : (
        <NewPasswordForm
          onSubmit={onSubmit}
          submitting={submitting}
          //  @ts-ignore ts-migrate(2322) FIXME: Type '"" | { code: any; message: Element; }' is no... Remove this comment to see the full error message
          submitError={error}
        />
      )}
    </div>
  );
};

export default NewPasswordPage;
