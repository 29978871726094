import {
  useDocumentData,
  DocumentDataHook,
} from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { AppUser, USER_CONVERTER } from "thunk-core";

import { useAuth } from "auth/AuthProvider";
import { db } from "firebaseInstance";

export const useCurrentUserId = (): string => {
  const { userId } = useAuth();
  return userId;
};

export const useCurrentUserData = () => {
  const userId = useCurrentUserId();

  const reference = userId
    ? doc(db, `users/${userId}`).withConverter(USER_CONVERTER)
    : null;

  return useDocumentData(reference, {
    idField: "uid",
  }) as DocumentDataHook<AppUser, "uid">;
};
