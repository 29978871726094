import { Descendant, Node, Text } from "slate";
import { isListItemElement } from "components/slate/plugins/list/utils";

export const normalizeClipboardFragment = (fragment: Descendant[]) => {
  const a = Array.from(Node.nodes({ children: fragment } as any));
  a.forEach(([node]) => {
    if (isListItemElement(node)) {
      const [last] = Node.last(node, []);

      if (Text.isText(last)) {
        // remove linebreaks inside last leaf in lists (notion nesting lists includes them)
        last.text = last.text.replace(/\n$/, "");
      }
    }
  });
};
