import React, { useState, useEffect, memo } from "react";
import Calendar from "react-calendar";
import { format, startOfMonth } from "date-fns";
import { CalendarNextIcon, CalendarPrevIcon } from "thunk-icons";

import { useFilledDays } from "pages/app/NoteEditingPage/AppCalendar/db";
import TileContent from "pages/app/NoteEditingPage/AppCalendar/TileContent";
import { useCurrentUserId } from "db/currentUser";
import Button from "framework/components/form/Button";

import styles from "pages/app/NoteEditingPage/AppCalendar/index.module.scss";
import "pages/app/NoteEditingPage/AppCalendar/index.scss";

const getActiveStartDate = (date: Date) =>
  date ? startOfMonth(date) : startOfMonth(new Date());

type AppCalendarProps = {
  onSelectDate: (date: Date) => void;
  onTodayClick: (date: Date) => void;
  date: Date;
};

const AppCalendar = memo(
  ({ onSelectDate, onTodayClick, date }: AppCalendarProps) => {
    const userId = useCurrentUserId();

    const [activeStartDate, setActiveStartDate] = useState(() =>
      getActiveStartDate(date)
    );

    useEffect(() => {
      setActiveStartDate(getActiveStartDate(date));
    }, [date]);

    const [filledDays, loading, error] = useFilledDays(userId, activeStartDate);

    if (error) {
      console.error(error);
    }

    return (
      <div>
        <Calendar
          onChange={onSelectDate}
          value={date}
          formatShortWeekday={(locale: any, date: any) => format(date, "EEEEE")}
          activeStartDate={activeStartDate}
          calendarType={"US"}
          onActiveStartDateChange={(props: any) => {
            const { activeStartDate } = props;
            setActiveStartDate(activeStartDate);
          }}
          prevLabel={<CalendarPrevIcon size={10} />}
          nextLabel={<CalendarNextIcon size={10} />}
          minDetail="month"
          tileContent={(props: any) => {
            const { date } = props;
            return <TileContent date={date} filledDays={filledDays} />;
          }}
        />
        <div style={{ marginTop: 20 }}>
          <Button
            btnClass={styles.todayButton}
            fullWidth={true}
            variant="secondary"
            onClick={() => {
              const now = new Date();
              onTodayClick(now);
              setActiveStartDate(now);
            }}
          >
            Today
          </Button>
        </div>
      </div>
    );
  }
);

export default AppCalendar;
