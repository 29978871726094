import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useFocused, useSelected } from "slate-react";
import { observer } from "mobx-react-lite";

import { useStaticProps } from "components/slate/hooks/useStaticProps";
import { getCommonEditorPathname, getEditorPathname } from "helpers";
import { useCurrentUserId } from "db/currentUser";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { ElementProps } from "components/slate/types";
import { useBacklinkTitle } from "stores/hooks/useBacklinkTitle";

import styles from "./index.module.scss";
import cn from "classnames";

const BacklinkComponent = observer(
  ({
    attributes,
    children,
    element,
  }: ElementProps & { element: BacklinkElement }) => {
    const userId = useCurrentUserId();

    const history = useHistory();
    const selected = useSelected();
    const focused = useFocused();

    const {
      isSharing,
      isHelpDocs,
      pageTitle,
      onBacklinkClick,
    } = useStaticProps();

    const title = useBacklinkTitle(element);

    let href =
      userId === element.userId || isHelpDocs
        ? getEditorPathname(
            element.targetType,
            element.targetId,
            element.targetId
          )
        : getCommonEditorPathname(
            element.userId,
            element.targetType,
            element.targetId,
            element.targetId
          );

    let search = "";
    const dateParam = href.split("?")[1];
    if (element.targetBlockId) {
      if (dateParam) {
        href = href.split("?")[0];
        search = `?${dateParam}&block=${element.targetBlockId}`;
      } else {
        search = `?block=${element.targetBlockId}`;
      }
    } else {
      if (dateParam) {
        href = href.split("?")[0];
        search = `?${dateParam}`;
      }
    }

    let link;
    if (isSharing) {
      link = <span>{title}</span>; // todo: setup shared page backlinks
    } else {
      link = (
        <Link
          className={cn(styles.backlink, {
            [styles.helpBacklink]: isHelpDocs,
          })}
          onClick={(e) => {
            e.preventDefault();

            if (onBacklinkClick) {
              // handle backlink different way (for graph page)
              onBacklinkClick(element);
            } else {
              history.push({
                pathname: href,
                search,
                state: { fromPageTitle: pageTitle },
              }); // workaround: by some reason "to" prop doesn't work with dates backlinks
            }
          }}
          to={{
            pathname: href,
            search,
            state: { fromPageTitle: pageTitle },
          }}
        >
          {title}
        </Link>
      );
    }

    return (
      <span
        {...attributes}
        contentEditable={false}
        className={cn(styles.backlink, {
          [styles.selectedBacklink]: selected && focused,
        })}
      >
        {link}
        {children}
      </span>
    );
  }
);

export default BacklinkComponent;
