import React from "react";
import { Redirect } from "react-router-dom";
import queryString from "query-string";

const getSingleParam = (param: string | string[]): string => {
  return typeof param === "string" ? param : param[0];
};

const pathsByModes = {
  resetPassword: "/reset-password",
};

// handle firebase specific callback urls which mode=action param (e.g.: https://app.thunkjournal.com/auth-actions?mode=action&oobCode=code)
// could be used for reset password, email address verification, email address change etc..
const AuthActionRouter = ({ location }: any) => {
  const { search } = location;
  const { mode } = queryString.parse(search);

  const path = pathsByModes[getSingleParam(mode)] || "/";
  const href = path + search;

  return <Redirect to={href} />;
};

export default AuthActionRouter;
