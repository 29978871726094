import React from "react";
import { DeleteNoteIcon } from "thunk-icons";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";

import { CardToolbar } from "framework/components/Card";
import CardToolbarButton from "framework/components/CardToolbarButton";
import DeleteConfirmModal from "components/modals/DeleteConfirmModal";
import useDialog from "hooks/useDialog";
import useNotify from "hooks/useNotify";
import { useDocumentsStore } from "stores/store";
import { useAppReadOnly } from "auth/hooks/useAppReadOnly";
import { Document } from "thunk-core";

type CardTemplateToolbar = {
  readOnly: boolean;
  document: Document;
};

const CardTemplateToolbar = observer((props: CardTemplateToolbar) => {
  const { document } = props;
  const { title } = document;
  const documentsStore = useDocumentsStore(document.type);

  const history = useHistory();
  const notify = useNotify();
  const removeDialog = useDialog<{ title: string }, boolean>();

  const appReadOnly = useAppReadOnly();
  const readOnly = props.readOnly || appReadOnly;

  const handleRemove = async () => {
    const isConfirmed = await removeDialog.open({ title });
    if (isConfirmed) {
      const removingNotificationId = notify.loading(
        `Removing template "${title}"`
      );
      try {
        await documentsStore.removeDocumentRemote(document.id);
        history.replace("/snippets");
        notify.success(`"${title}" Template successfully removed`);
      } catch (error) {
        console.error(error);
        notify.error(`Error removing "${title}" template`);
      } finally {
        notify.remove(removingNotificationId);
      }
    }
  };

  const removeIconProps = {
    id: "removeIconProps",
    title: "Delete note",
    icon: DeleteNoteIcon,
    onClick: handleRemove,
  };

  return (
    <CardToolbar>
      <div className={styles.templateLabel}>Template</div>
      {[!readOnly && removeIconProps].filter(Boolean).map((props) => (
        <CardToolbarButton key={props.id} {...props} />
      ))}
      {/* <ConfirmModal {...removeDialog}>
        {({ modalData }) =>
          `Do you want to remove "${modalData.title}" template?`
        }
      </ConfirmModal> */}
      <DeleteConfirmModal
        {...removeDialog}
        title="Are you sure?"
        okText="Delete"
      >
        {({ modalData }) =>
          `Your template, "${modalData.title}" will be deleted forever.`
        }
      </DeleteConfirmModal>
    </CardToolbar>
  );
});

export default CardTemplateToolbar;
