import { Descendant } from "slate";

export type HorizontalRuleType = "hr";
export const HorizontalRuleType: HorizontalRuleType = "hr";

export type HorizontalRuleElement = {
  id: string;
  type: HorizontalRuleType;
  children: Descendant[];
};
