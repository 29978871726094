import React from "react";

import { ListSection } from "components/slate/plugins/menus/hashMenu/components/types";

import styles from "./index.module.scss";

type SectionMenuListSectionProps<T> = {
  section: ListSection<T>;
};

const SectionMenuListHeader = <T,>({
  section,
}: SectionMenuListSectionProps<T>) => {
  return <div className={styles.header}>{section.title}</div>;
};

export default SectionMenuListHeader;
