import mascotsSuccess from "images/mascots_success.svg";

import styles from "./index.module.scss";

const CompleteTasks = () => {
  return (
    <div className={styles.root}>
      <img src={mascotsSuccess} alt="Success" className={styles.image} />
      <h2 className={styles.title}>Woohoo!!!</h2>
      <p className={styles.content}>All your to-dos are complete, great job!</p>
    </div>
  );
};

export default CompleteTasks;
