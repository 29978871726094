import { useEffect } from "react";
import { Auth, User, onIdTokenChanged } from "firebase/auth";

import useLoadingValue from "hooks/useLoadingValue";
import useMemoArray from "hooks/useMemoArray";
import { LoadingHook } from "hooks/types";

export type AuthStateHook = LoadingHook<User, Error>;

const useAuthStateWithToken = (auth: Auth): AuthStateHook => {
  const { error, loading, setError, setValue, value } = useLoadingValue<
    User | null,
    Error
  >(() => auth.currentUser);

  useEffect(() => {
    // We want to update current user when the token changes via refresh etc.
    // onAuthStateChanged => After 4.0.0, the observer is only triggered on sign-in or sign-out.
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#onauthstatechanged
    //
    // onIdTokenChanged => Adds an observer for changes to the signed-in user's ID token,
    // which includes sign-in, sign-out, and token refresh events. This method has the same
    // behavior as firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0.
    // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#onidtokenchanged
    const unsubscribe = onIdTokenChanged(auth, setValue, setError);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return useMemoArray([value ?? null, loading, error]);
};

export default useAuthStateWithToken;
