import React from "react";

import { UseSlatePlugin } from "components/slate/types";
import EmbedComponent from "components/slate/plugins/embed/elements";
import { isEmbedElement } from "components/slate/plugins/embed/utils";

const useParagraphPlugin: UseSlatePlugin = () => {
  return {
    renderElement: (props) => {
      if (isEmbedElement(props.element)) {
        return <EmbedComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useParagraphPlugin;
