import { useRecoilSnapshot } from "recoil";
import { useEffect } from "react";
import { env } from "thunk-env";

const RecoilDebugger = () => {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    if (env.NODE_ENV === "production") {
      return;
    }

    console.log("The following atoms were modified:");
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.log(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
};

export default RecoilDebugger;
