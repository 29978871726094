import React from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import cn from "classnames";

import { useDeviceDetector } from "providers/DeviceDetectorProvider";

import "./index.module.scss";

const SimpleTooltip = ({
  children,
  hideTooltip = false,
  placement,
  noStyles = false,
  ...rest
}: TippyProps & { hideTooltip?: boolean; noStyles?: boolean }) => {
  const { isMobile, isTablet } = useDeviceDetector();

  const shouldRenderTooltip = !isMobile && !isTablet && !hideTooltip;

  if (!shouldRenderTooltip) {
    return children!;
  }

  return (
    <Tippy
      content=""
      arrow={true}
      offset={[0, 12]}
      delay={0}
      animation="scale"
      duration={[100, 50]}
      hideOnClick={false}
      {...rest}
      placement={placement || "bottom"}
      className={cn({ simpleTooltip: !noStyles }, rest.className)}
    >
      {children}
    </Tippy>
  );
};

export default SimpleTooltip;
