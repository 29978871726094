import { makeAutoObservable, runInAction } from "mobx";
import { Descendant } from "slate";

import { getDocumentsStore, store } from "./store";
import DocumentsStore from "stores/documentsStore";
import { getDocument, getFavoriteNotes } from "db/documents/documents.queries";
import { Document, DocumentType } from "thunk-core";

// DocumentsManager handles operation when DocumentType unknown or any
export default class DocumentsManager {
  constructor() {
    makeAutoObservable(this);
  }

  get documentStores(): DocumentsStore[] {
    const { pagesStore, notesStore, templatesStore } = store;
    return [pagesStore, notesStore, templatesStore];
  }

  getDocumentsStore(documentType: DocumentType) {
    return getDocumentsStore(documentType);
  }

  // lazy means they weren't loaded before
  async loadDocumentsLazy(ids: string[]) {
    const missedIds: string[] = [];

    for (const documentId of ids) {
      const document = this.getDocument(documentId);

      if (!document) {
        missedIds.push(documentId);
      }
    }

    const documents = await Promise.all(missedIds.map((id) => getDocument(id)));

    runInAction(() => {
      documents
        .filter(Boolean)
        .forEach((document) => this.setDocument(document));
    });
  }

  async loadFavouriteDocuments(userId: string) {
    // load only notes, because it supposed that pages and templates already loaded
    const { notesStore } = store;

    const notes = await getFavoriteNotes(userId);

    notesStore.setDocuments(notes);
  }

  get favoriteDocuments(): Document[] {
    const documents: Document[] = [];
    for (const documentsStore of this.documentStores) {
      documents.push(
        ...documentsStore.getDocuments().filter((x) => x.isFavorite)
      );
    }

    return documents;
  }

  getDocument(documentId: string) {
    for (const documentStore of this.documentStores) {
      const document = documentStore.getDocument(documentId);

      if (document) {
        return document;
      }
    }

    return null;
  }

  getDocumentByType(documentType: DocumentType, documentId: string) {
    const documentStore = getDocumentsStore(documentType);
    const document = documentStore.getDocument(documentId);

    if (document) {
      return document;
    }

    return null;
  }

  setDocument(document: Document) {
    const documentsStore = this.getDocumentsStore(document.type);
    documentsStore.setDocument(document);
  }

  createNewDocumentWithContent(
    content: Descendant[],
    options: {
      userId: string;
      title?: string;
      dateId?: string;
      documentType: DocumentType;
    }
  ) {
    const documentsStore = this.getDocumentsStore(options.documentType);

    const documentId = documentsStore.createNewDocumentWithContent(
      content,
      options
    );

    return documentId;
  }

  addContentToDocument(
    content: Descendant[],
    options: {
      documentId: string;
      dateId?: string;
      documentType: DocumentType;
    }
  ) {
    const documentsStore = this.getDocumentsStore(options.documentType);

    const documentId = documentsStore.addContentToDocument(content, options);

    return documentId;
  }
}
