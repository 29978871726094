import React, { useMemo } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import useFetch, { CachePolicies } from "use-http";
import { nanoid } from "nanoid";

import HelpSpinner from "layout/AppLayout/AppHelp/HelpSpinner";
import SlateDocumentEditor from "components/slate/editors/SlateDocumentEditor";
import { mapContentFromJSON } from "helpers";
import { EditorErrorBoundary } from "components/errors/EditorErrorBoundary";
import { EditorType } from "components/slate/types";

import styles from "../index.module.scss";
import { getBlocksContent } from "db/blocks/blocks.mapping";

export const HelpBreadcrumbs = ({ location }: any) => {
  const history = useHistory();
  const fromPageTitle = location.state?.fromPageTitle;

  return (
    <a
      href="#"
      className={styles.breadcrumbs}
      onClick={(e) => {
        e.preventDefault();
        history.goBack();
      }}
    >
      {`< `}
      {fromPageTitle || "Back"}
    </a>
  );
};

const HelpSlateEditor = ({ slateId, title, content }: any) => {
  return (
    <div className={styles.helpSection} data-card-type="help-section">
      <EditorErrorBoundary>
        <SlateDocumentEditor
          slateId={slateId}
          userId={null}
          editorType={EditorType.HelpPageEditor}
          value={content}
          readOnly={true}
          isHelpDocs={true}
          pageTitle={title}
        />
      </EditorErrorBoundary>
    </div>
  );
};

const HelpPageContainer = (_props: any) => {
  const _location = useLocation();

  const location = useMemo(() => _location, []);
  const { match, mainPageId, mainUserId } = useMemo(() => _props, []);

  const { params } = match;
  const pageId = params.pageId;
  const path = `${mainUserId}/${pageId}`;
  const isMainPage = pageId === mainPageId;

  const slateId = useMemo(() => {
    // preventing rendering several components with the same id, it could break slate editor (in case of fast multiple clicks on the same link)
    return `help-editor-${nanoid(5)}`;
  }, [path]);

  const { loading, error, data } = useFetch(
    `/helpDocs/${path}`,
    { cachePolicy: CachePolicies.CACHE_FIRST },
    [path]
  );

  if (loading) {
    return <HelpSpinner />;
  }

  if (error) {
    console.error(error);
    const isConnectionError = data == null;

    if (!isConnectionError && isMainPage) {
      return <Redirect to="/default-help-page" />;
    }

    return <Redirect to={`/error?code=${data?.code}`} />;
  }

  const { document, blocks } = data;
  const title = document.title;
  const content = getBlocksContent(blocks);

  return (
    <>
      {isMainPage || <HelpBreadcrumbs location={location} />}

      <h3 className={styles.title}>{title}</h3>
      <HelpSlateEditor slateId={slateId} title={title} content={content} />
    </>
  );
};

export default HelpPageContainer;
