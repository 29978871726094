import { useEffect, useMemo } from "react";
import { Editor, Transforms, Element, NodeEntry } from "slate";
import isHotkey from "is-hotkey";
import { ReactEditor } from "slate-react";
import { useHistory } from "react-router-dom";
import { HistoryEditor } from "slate-history";

import { useAppReadOnly } from "auth/hooks/useAppReadOnly";
import { EditorsStore } from "components/slate/state/EditorsStore";
import {
  getElementByIndex,
  getElementSlateId,
  getFirstSlateId,
  getFocusedSlateId,
  getNextEditorId,
} from "components/slate/workspace/utils/editors";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { focusEditorAtEnd, focusEditorAtStart } from "components/slate/utils";
import { NavigationKeys } from "constants/navigationKeys";
import { useStoreCallback } from "state/utils";
import useEvent from "hooks/useEvent";
import { scrollParentToElement } from "utils/scrollParentToElement";
import { usePasteBlocksEffect } from "components/slate/plugins/terminal/actions";
import {
  moveItemsBack,
  moveItemsForward,
} from "components/slate/plugins/list/transforms";
import { EditorActions } from "components/slate/actions";
import { appShortcuts } from "appShortcuts";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import { closeFormattingMenu } from "components/slate/plugins/menus/terminalMenu/components/TerminalFormattingMenu";
import { closeTerminalMenu } from "components/slate/plugins/menus/terminalMenu/components/TerminalMenu";

const getSelectionIndex = (editor: Editor) =>
  Editor.path(editor, editor.selection, { depth: 1 })[0];
const limitIndex = (editor: Editor, index: number) =>
  Math.min(Math.max(index, 0), editor.children.length - 1);

export const useTerminalPluginListeners = () => {
  const history = useHistory();
  const terminalActions = TerminalStore.useTerminalActions();
  const appReadOnly = useAppReadOnly();

  const isIndexInside = (slateId: string, editor: Editor, newIndex: number) => {
    const { contextStart, contextEnd } = ExtendedEditor.editorContext(
      editor,
      {}
    );

    return newIndex >= contextStart.path[0] && newIndex <= contextEnd.path[0];
  };

  const moveEditorSelection = (
    slateId: string,
    editor: Editor,
    newIndex: number
  ) => {
    Transforms.select(
      editor,
      Editor.end(editor, [limitIndex(editor, newIndex)])
    );

    const element = editor.children[newIndex];
    if (element) {
      const domElement = ReactEditor.toDOMNode(editor, element);
      scrollParentToElement(domElement);
    }
  };

  const switchEditor = useStoreCallback(
    (get) => (slateId: string, editor: Editor, key: string) => {
      const nextSlateId = getNextEditorId(slateId, key as NavigationKeys);
      const nextEditor = get(EditorsStore.editor(nextSlateId)) as any;

      if (nextEditor) {
        if (key === NavigationKeys.ArrowDown) {
          focusEditorAtStart(nextEditor);
        } else if (key === NavigationKeys.ArrowUp) {
          focusEditorAtEnd(nextEditor);
        }

        EditorActions.startTerminal(nextSlateId);
      }
    }
  );

  const moveSelectionTo = useStoreCallback(
    (get) => (slateId: string, editor: Editor, newIndex: number) => {
      if (!editor.selection) {
        return;
      }

      const index = getSelectionIndex(editor);

      if (index !== newIndex && isIndexInside(slateId, editor, newIndex)) {
        moveEditorSelection(slateId, editor, newIndex);
      }
    }
  );

  const moveSelectionBy = useStoreCallback(
    (get) => (slateId: string, editor: Editor, key: string) => {
      if (!editor.selection) {
        return;
      }

      const step = key === "ArrowDown" ? 1 : -1;
      const newIndex = getSelectionIndex(editor) + step;

      if (isIndexInside(slateId, editor, newIndex)) {
        moveEditorSelection(slateId, editor, newIndex);
      } else {
        switchEditor(slateId, editor, key);
      }
    }
  );

  const digits = useMemo(
    () => ({
      timeout: null,
      lineNumber: "",
    }),
    []
  );

  const onKeyDown = useEvent(
    useStoreCallback((get, set, snapshot) => (e: KeyboardEvent) => {
      if (appReadOnly) {
        return;
      }

      const isTerminalActive = get(TerminalStore.isTerminalActive);
      const isMenuOpened = get(TerminalStore.isMenuOpened);

      if (!isTerminalActive) {
        /* HANDLE START TERMINAL */
        if (isHotkey(appShortcuts.toggleTerminal, e)) {
          const slateId = getFocusedSlateId() || getFirstSlateId();

          if (slateId) {
            e.preventDefault();
            EditorActions.startTerminal(slateId);
          }
        }
      }

      if (isTerminalActive) {
        const terminalSlateId = get(TerminalStore.terminalSlateId);

        /* HANDLE DESELECT ELEMENTS */
        if (isHotkey("Escape", e) && !isMenuOpened) {
          const terminalSelection = get(
            TerminalStore.terminalSelection(terminalSlateId)
          );

          if (terminalSelection.size > 0) {
            e.preventDefault();
            terminalActions.deselectAllElements(terminalSlateId);

            return;
          }
        }

        /* HANDLE END TERMINAL */
        if (
          (isHotkey(["Escape", "Enter"], e) && !isMenuOpened) ||
          isHotkey([appShortcuts.toggleTerminal], e)
        ) {
          e.preventDefault();
          EditorActions.endTerminal();
          return;
        }

        const editor = get(TerminalStore.activeEditor);

        /* HANDLE SELECTION COMMANDS */
        if (editor?.selection) {
          if (isHotkey(["ArrowDown", "ArrowUp"], e) && !isMenuOpened) {
            e.preventDefault();
            // terminalActions.deselectAllElements(terminalSlateId);
            moveSelectionBy(terminalSlateId, editor, e.key);
            return;
          }

          if (isHotkey(["mod+ArrowDown", "mod+ArrowUp"], e) && !isMenuOpened) {
            e.preventDefault();
            EditorActions.moveBlocks(e.key === "ArrowDown" ? "down" : "up");
            return;
          }

          if (isHotkey(["ArrowLeft", "ArrowRight"], e) && !isMenuOpened) {
            e.preventDefault();
            switchEditor(terminalSlateId, editor, e.key);
            return;
          }

          if (
            isHotkey(["Shift+ArrowDown", "Shift+ArrowUp"], e) &&
            !isMenuOpened
          ) {
            if (!editor.selection) {
              return;
            }

            const step = e.key === "ArrowDown" ? 1 : -1;
            const index = getSelectionIndex(editor);
            const newIndex = limitIndex(editor, index + step);

            if (isIndexInside(terminalSlateId, editor, newIndex)) {
              e.preventDefault();

              moveEditorSelection(terminalSlateId, editor, newIndex);

              const element = editor.children[index];
              const newElement = editor.children[newIndex];
              const elementSelected = get(
                TerminalStore.isElementSelected({
                  slateId: terminalSlateId,
                  elementId: element.id,
                })
              );
              const newElementSelected = get(
                TerminalStore.isElementSelected({
                  slateId: terminalSlateId,
                  elementId: newElement.id,
                })
              );

              if (elementSelected) {
                if (newElementSelected) {
                  terminalActions.deselectElement(terminalSlateId, element.id);
                } else {
                  terminalActions.selectElement(terminalSlateId, newElement.id);
                }
              } else {
                if (newElementSelected) {
                  terminalActions.selectElement(terminalSlateId, element.id);
                } else {
                  terminalActions.selectElement(terminalSlateId, element.id);
                  terminalActions.selectElement(terminalSlateId, newElement.id);
                }
              }
            }
          }

          const keyNumber = Number(e.key) ?? null;
          if (
            keyNumber != null &&
            0 <= keyNumber &&
            keyNumber <= 9 &&
            !isMenuOpened
          ) {
            e.preventDefault();

            clearTimeout(digits.timeout);
            digits.lineNumber = digits.lineNumber + e.key;
            const release = snapshot.retain();
            digits.timeout = setTimeout(() => {
              const domElement = getElementByIndex(
                Number(digits.lineNumber) - 1
              );
              const slateId = domElement && getElementSlateId(domElement);
              const newEditor = get(EditorsStore.editor(slateId));

              if (newEditor) {
                const editable =
                  domElement && domElement.closest("[data-slate-id]");
                const index = Array.from(editable.children).indexOf(domElement);

                terminalActions.deselectAllElements(terminalSlateId);
                if (newEditor !== editor) {
                  ReactEditor.focus(newEditor);
                  EditorActions.startTerminal(slateId);
                }

                moveSelectionTo(terminalSlateId, newEditor, index);
                digits.lineNumber = "";
              }

              release();
            }, 170);
          }
        }

        /* HANDLE OTHER COMMANDS */

        /* SELECT ALL */
        if (isHotkey("mod+a", e) && !isMenuOpened) {
          e.preventDefault();
          terminalActions.selectAllElements(
            terminalSlateId,
            editor.children.map((x) => x.id)
          );
          return;
        }

        /* UNDO */
        if (isHotkey("mod+z", e) && !isMenuOpened) {
          e.preventDefault();
          HistoryEditor.undo(editor);
          return;
        }

        /* REDO */
        if (isHotkey("mod+Shift+z", e) && !isMenuOpened) {
          e.preventDefault();
          HistoryEditor.redo(editor);
          return;
        }

        /* CHANGE LIST ITEMS DEPTHS */
        if (isHotkey(["tab", "shift+tab"], e) && !isMenuOpened) {
          e.preventDefault();

          const editor = get(TerminalStore.activeEditor);
          const terminalSelection = get(TerminalStore.activeSelection);
          const pointerId = get(TerminalStore.activePointer);
          const entries = editor.children
            .map((element, index): NodeEntry => [element, [index]])
            .filter(
              ([element]) =>
                Element.isElement(element) &&
                (terminalSelection.has(element.id) || element.id === pointerId)
            );

          if (e.shiftKey) {
            moveItemsBack(editor, entries);
          } else {
            moveItemsForward(editor, entries);
          }

          return;
        }

        /* MOVE SELECTION TO TOP (ONLY SELECTION, NOT ELEMENTS) */
        if (isHotkey("t", e) && !isMenuOpened) {
          e.preventDefault();

          EditorActions.moveSelectionToTop();
          return;
        }

        /* MOVE SELECTION TO BOTTOM (ONLY SELECTION, NOT ELEMENTS) */
        if (isHotkey("b", e) && !isMenuOpened) {
          e.preventDefault();

          EditorActions.moveSelectionToBottom();
          return;
        }

        /* REMOVE ELEMENTS */
        if (isHotkey(["Backspace", "Delete"], e) && !isMenuOpened) {
          e.preventDefault();
          const terminalSlateId = get(TerminalStore.terminalSlateId);
          const editor = get(EditorsStore.editor(terminalSlateId));

          const terminalSelection = get(
            TerminalStore.terminalSelection(terminalSlateId)
          );

          if (editor?.selection) {
            const index = getSelectionIndex(editor);
            Transforms.removeNodes(editor, {
              at: [],
              match: (node, path) =>
                (Element.isElement(node) && terminalSelection.has(node.id)) ||
                (path.length === 1 && index === path[0]),
            });
          }
          return;
        }

        /* SELECT ELEMENTS WITH SPACE */
        if (isHotkey(["Space"], e)) {
          e.preventDefault();

          if (!editor.selection) {
            return;
          }

          const index = getSelectionIndex(editor);
          const element = editor.children[index];

          terminalActions.toggleElement(terminalSlateId, element.id);
        }
      }
    })
  );

  const onSelectionChange = useEvent(
    useStoreCallback((get) => (e: Event) => {
      const isTerminalActive = get(TerminalStore.isTerminalActive);

      if (!isTerminalActive) {
        return;
      }

      // if terminal mode is already started and selection goes to another editor, call start terminal with the new slateId
      const slateId = getFocusedSlateId();

      if (slateId) {
        e.preventDefault();
        EditorActions.startTerminal(slateId);
      }
    })
  );

  const { isMobileScreen } = useDeviceDetector();

  const onMouseDown = useEvent(
    useStoreCallback((get) => (e: MouseEvent) => {
      const isTerminalActive = get(TerminalStore.isTerminalActive);
      const isMenuOpened = get(TerminalStore.isMenuOpened);
      const terminalSlateId = get(TerminalStore.terminalSlateId);

      if (!isTerminalActive) {
        return;
      }

      const target = e.target as any;
      const domElement = target.closest('[data-slate-node="element"]');

      const terminalMenu = target.closest(
        "[data-terminal-menu], [data-temrinal-shortcuts]"
      );

      if (domElement) {
        if (isMenuOpened) {
          e.preventDefault();

          closeFormattingMenu();
          closeTerminalMenu();
          return;
        }

        const elementId = domElement.getAttribute("data-slate-node-id");
        const slateId = domElement && getElementSlateId(domElement);
        const editor = get(EditorsStore.editor(terminalSlateId));
        const newEditor = get(EditorsStore.editor(slateId));

        if (isMobileScreen) {
          /* HANDLE ELEMENT CLICK IN MOBILE */
          e.preventDefault(); // prevent text selection

          if (editor === newEditor && editor.selection) {
            const index = getSelectionIndex(editor);
            const editable =
              domElement && domElement.closest("[data-slate-id]");
            const newIndex = Array.from(editable.children).indexOf(domElement);

            // const element = editor.children[index];
            // terminalActions.toggleElement(slateId, element.id);

            const newElement = editor.children[newIndex];
            terminalActions.toggleElement(slateId, newElement.id);

            Transforms.select(editor, Editor.end(editor, [newIndex]));
          }
        } else if (e.shiftKey) {
          /* HANDLE MOUSE CLICK WITH SHIFT */
          e.preventDefault(); // prevent text selection

          if (editor === newEditor && editor.selection) {
            const index = getSelectionIndex(editor);
            const editable =
              domElement && domElement.closest("[data-slate-id]");
            const newIndex = Array.from(editable.children).indexOf(domElement);

            const slice =
              newIndex > index ? [index, newIndex + 1] : [newIndex, index + 1];

            editor.children.slice(...slice).forEach((element) => {
              terminalActions.selectElement(slateId, element.id);
            });

            Transforms.select(editor, Editor.end(editor, [newIndex]));
          }
        } else if (e.metaKey) {
          /* HANDLE MOUSE CLICK WITH COMMAND */
          e.preventDefault(); // prevent text selection

          if (editor === newEditor && editor.selection) {
            const index = getSelectionIndex(editor);
            const editable =
              domElement && domElement.closest("[data-slate-id]");
            const newIndex = Array.from(editable.children).indexOf(domElement);

            const element = editor.children[index];
            terminalActions.toggleElement(slateId, element.id);

            const newElement = editor.children[newIndex];
            terminalActions.toggleElement(slateId, newElement.id);
          }
        } else {
          /* HANDLE MOUSE CLICK WITHOUT ANY KEY PRSSED */
          if (editor === newEditor && editor.selection) {
            const terminalSelection = get(
              TerminalStore.terminalSelection(slateId)
            );

            if (terminalSelection.has(elementId)) {
              const index = getSelectionIndex(editor);
              const element = editor.children[index];

              terminalActions.selectElement(slateId, element.id);
            } else {
              terminalActions.deselectAllElements(slateId);
            }
          }
        }
      } else {
        if (!terminalMenu) {
          /* CLICK OUTSIDE SHOULD CLEAR SELECTION */
          e.preventDefault(); // prevent default slate selection handling

          const terminalSlateId = get(TerminalStore.terminalSlateId);
          terminalActions.deselectAllElements(terminalSlateId);
        }
      }
    })
  );

  useEffect(() => {
    return () => {
      clearTimeout(digits.timeout);
    };
  }, []);

  useEffect(() => {
    const listener = () => {
      terminalActions.closeTerminal();
    };

    const unsubscribe = history.listen(listener);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, []);

  useEffect(() => {
    document.addEventListener("selectionchange", onSelectionChange);
    return () =>
      document.removeEventListener("selectionchange", onSelectionChange);
  }, []);

  useEffect(() => {
    window.addEventListener("mousedown", onMouseDown);
    return () => window.removeEventListener("mousedown", onMouseDown);
  }, []);

  usePasteBlocksEffect();
};
