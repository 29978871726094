import React from "react";

import { useTerminalPluginListeners } from "components/slate/plugins/terminal/hooks";
import TerminalShortcutHint from "components/slate/plugins/terminal/components/TerminalShortcutHint";
import TerminalMenu from "components/slate/plugins/menus/terminalMenu/components/TerminalMenu";
import TerminalFormattingMenu from "components/slate/plugins/menus/terminalMenu/components/TerminalFormattingMenu";

type Props = {
  children: React.ReactNode;
};

const SlateWorkspace = (props: Props) => {
  const { children } = props;

  useTerminalPluginListeners();

  return (
    <>
      <TerminalShortcutHint />
      <TerminalMenu />
      <TerminalFormattingMenu />
      {children}
    </>
  );
};

export default SlateWorkspace;
