import React, { useEffect } from "react";
import isHotkey from "is-hotkey";

import usePrevious from "hooks/usePrevious";
import useEventListener from "hooks/useEventListener";
import { appShortcuts } from "appShortcuts";
import * as db from "./db";
import { useUserData } from "hooks/useUserData";
import { useCurrentUserId } from "db/currentUser";

const useHelpDrawerEffects = ({ isHelpOpened, setIsHelpOpened }: any) => {
  const userId = useCurrentUserId();
  const [user] = useUserData(userId);

  const isHelpOpenedPrev = usePrevious(isHelpOpened);

  useEventListener(window, "keydown", (e: React.KeyboardEvent) => {
    if (isHotkey(appShortcuts.toggleHelpSection, e)) {
      setIsHelpOpened((state: any) => !state);
      return;
    }

    if (isHotkey("Escape", e) && !e.defaultPrevented) {
      setIsHelpOpened(false);
      return;
    }
  });

  useEffect(() => {
    if (user) {
      setIsHelpOpened(user.completedOnboarding && !user.isHelpSeen);
    }
  }, [user]);

  useEffect(() => {
    if (user && !user.isHelpSeen && isHelpOpenedPrev && !isHelpOpened) {
      db.setIsHelpSeen(user.uid);
    }
  }, [user, isHelpOpened]);
};

export default useHelpDrawerEffects;
