import { Editor, Element } from "slate";
import { isMobile } from "react-device-detect";
import { ReactEditor } from "slate-react";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";

const highlightElement = (editor: Editor, element: Element) => {
  try {
    const domElement = ReactEditor.toDOMNode(editor, element);
    domElement?.classList.add("highlightedChild");
  } catch (error) {
    console.error(error);
  }
};

let hasHighlighted = false;

export const highlightChildrenElements = (
  editor: Editor,
  element: Element,
  folded: boolean
) => {
  if (isMobile) {
    return;
  }

  hasHighlighted = true;

  if (folded) {
    unhighlightChildrenElements();
  } else {
    highlightElement(editor, element);

    const descendants =
      ExtendedEditor.semanticDescendants(editor, element) || [];

    for (const descendant of descendants) {
      highlightElement(editor, descendant.element);
    }
  }
};

export const unhighlightChildrenElements = () => {
  if (isMobile) {
    return;
  }

  if (hasHighlighted) {
    const highlighted = document.querySelectorAll(".highlightedChild");
    for (const highlightedElement of highlighted) {
      highlightedElement.classList.remove("highlightedChild");
    }
  }

  hasHighlighted = false;
};
