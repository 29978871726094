import { makeAutoObservable } from "mobx";

export default class SidePanelStore {
  selectedBacklinks: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedBacklinks(ids: string[]) {
    this.selectedBacklinks = ids;
  }
}
