import { useMemo } from "react";
import { sortBy } from "ramda";
import { Node } from "slate";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CopyIcon,
  DuplicateBlocksIcon,
  MoveBlocksIcon,
  NewNoteIcon,
  NoteIcon,
  ToTopIcon,
  ToBottomIcon,
  KbdTerminalSelect,
  KbdTerminalMoveUp,
  KbdTerminalMoveDown,
} from "thunk-icons";
import crawl from "tree-crawl";

import { useDocumentsStore } from "stores/store";
import { Document, DocumentType } from "thunk-core";
import MenuIcon from "components/slate/plugins/menus/componentMenu/components/MenuIcon";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import { MenuItems } from "components/slate/plugins/menus/componentMenu/items";
import { MenuItem } from "components/slate/plugins/menus/componentMenu/types";
import {
  filterConfig,
  getDefaultConfig,
  getGroupConfig,
} from "components/slate/plugins/menus/componentMenu/items/utils";
import { EditorActions } from "components/slate/actions";
import { useStoreCallback } from "state/utils";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import MenuShortcut from "components/slate/plugins/menus/componentMenu/components/MenuShortcut";

const terminalMenuConfig: MenuItem[] = [
  {
    text: "Copy to a new note",
    icon: () => <MenuIcon component={NewNoteIcon} />,
    applyMenuItem: () => {
      EditorActions.copyToNewNote();
    },
  },
  {
    id: "copyTo",
    text: "Copy to...",
    icon: () => <MenuIcon component={NewNoteIcon} />,
    children: [],
    dynamicChildren: true,
    applyMenuItem: ({ history }) => {
      history.push("/copyTo");
    },
  },
  {
    text: "Duplicate",
    icon: () => <MenuIcon component={DuplicateBlocksIcon} />,
    applyMenuItem: () => {
      EditorActions.duplicate();
    },
    divider: true,
  },
  {
    text: "Move to a new note",
    icon: () => <MenuIcon component={MoveBlocksIcon} />,
    applyMenuItem: () => {
      EditorActions.copyToNewNote(true);
    },
  },
  {
    id: "moveTo",
    text: "Move to...",
    icon: () => <MenuIcon component={MoveBlocksIcon} />,
    children: [],
    dynamicChildren: true,
    applyMenuItem: ({ history }) => {
      history.push("/moveTo");
    },
  },
  {
    text: "Move Up",
    icon: () => <MenuIcon component={ArrowUpIcon} />,
    // keyboardShortcut just an example
    keyboardShortcut: () => (
      <MenuShortcut
        component={KbdTerminalMoveUp}
        windowsComponent={KbdTerminalSelect}
      />
    ),
    applyMenuItem: () => {
      EditorActions.moveBlocks("up");
    },
    keepMenuOpened: true,
  },
  {
    text: "Move Down",
    icon: () => <MenuIcon component={ArrowDownIcon} />,
    // keyboardShortcut just an example
    keyboardShortcut: () => (
      <MenuShortcut
        component={KbdTerminalMoveDown}
        windowsComponent={KbdTerminalSelect}
      />
    ),
    applyMenuItem: () => {
      EditorActions.moveBlocks("down");
    },
    keepMenuOpened: true,
  },
  {
    text: "Move To Top",
    icon: () => <MenuIcon component={ToTopIcon} />,
    applyMenuItem: () => {
      EditorActions.moveBlocksToEdge("top");
    },
  },
  {
    text: "Move To Bottom",
    icon: () => <MenuIcon component={ToBottomIcon} />,
    applyMenuItem: () => {
      EditorActions.moveBlocksToEdge("bottom");
    },
    divider: true,
  },
  {
    text: "Add block above",
    icon: () => <MenuIcon component={ArrowUpIcon} />,
    applyMenuItem: () => {
      EditorActions.addEmptyBlock("above");
    },
    singleBlockAction: true,
  },
  {
    text: "Add block below",
    icon: () => <MenuIcon component={ArrowDownIcon} />,
    applyMenuItem: () => {
      EditorActions.addEmptyBlock("below");
    },
    singleBlockAction: true,
  },
  {
    text: "Copy",
    icon: () => <MenuIcon component={CopyIcon} />,
    applyMenuItem: () => {
      EditorActions.copyBlocks();
    },
    divider: true,
  },
  MenuItems.delete,
];

const getTerminalMenuConfig = (nodes: Node[]) => {
  const expanded = nodes.length > 1;

  const filtered = filterConfig(terminalMenuConfig, (item) =>
    expanded ? !item.singleBlockAction : true
  );

  return filtered;
};

export const getSearchConfig = (
  nodes: Node[],
  isEmpty: boolean,
  search: string
): MenuItem[] => {
  const nestedConfig = [
    ...getDefaultConfig(nodes, isEmpty),
    ...getTerminalMenuConfig(nodes),
  ];

  const items: MenuItem[] = [];
  crawl<any>(
    { children: nestedConfig },
    (item: MenuItem) => {
      if (
        !("children" in item) ||
        (!item.dynamicChildren && item.children.length === 0)
      ) {
        items.push({ ...item, divider: false });
      }
    },
    {}
  );

  return items.filter(
    (item: any) =>
      prepareToSearch(item.text).includes(search.toLowerCase()) ||
      item.alias?.some((a) => prepareToSearch(a).includes(search.toLowerCase()))
  );
};

export const useMenuConfig = ({
  search,
  groupId,
}: {
  search: string;
  groupId: string;
}): MenuItem[] => {
  const documentsStore = useDocumentsStore(DocumentType.PAGE);
  const pages = documentsStore.getDocuments();

  const getSelectedNodes = useStoreCallback((get) => () => {
    const terminalSelection = get(TerminalStore.activeSelection);
    const pointerId = get(TerminalStore.activePointer);
    const editor = get(TerminalStore.activeEditor);

    const elements = editor.children.filter(
      (element) => terminalSelection.has(element.id) || element.id === pointerId
    );

    return elements;
  });

  const config = useMemo(() => {
    const nodes = getSelectedNodes();
    const isEmpty = nodes.every((x) => Node.string(x).trim() === "");

    if (groupId === "copyTo" || groupId === "moveTo") {
      return [
        MenuItems.back,
        ...sortBy((x) => x.title, pages)
          .map((page) =>
            makePageMenuItem(page, () =>
              EditorActions.copyToExistingNote(page, groupId === "moveTo")
            )
          )
          .filter((t) => t.text.toLowerCase().includes(search.toLowerCase())),
      ];
    }

    if (groupId === "convertTo") {
      return [MenuItems.back, ...getDefaultConfig(nodes, isEmpty)];
    }

    if (search) {
      return getSearchConfig(nodes, isEmpty, search);
    }

    if (groupId) {
      return getGroupConfig(nodes, isEmpty, groupId);
    }

    return getTerminalMenuConfig(nodes);
  }, [groupId, search]);

  return config;
};

export const useFormattingMenuConfig = ({
  search,
  groupId,
}: {
  search: string;
  groupId: string;
}): MenuItem[] => {
  const getSelectedNodes = useStoreCallback((get) => () => {
    const terminalSelection = get(TerminalStore.activeSelection);
    const pointerId = get(TerminalStore.activePointer);
    const editor = get(TerminalStore.activeEditor);

    const elements = editor.children.filter(
      (element) => terminalSelection.has(element.id) || element.id === pointerId
    );

    return elements;
  });

  const config = useMemo(() => {
    const nodes = getSelectedNodes();
    const isEmpty = nodes.every((x) => Node.string(x).trim() === "");

    if (search) {
      const isEmptyForSearch = isEmpty;

      return [...getSearchConfig(nodes, isEmptyForSearch, search)];
    }

    if (groupId === "snippets") {
      return [MenuItems.back];
    }

    if (groupId) {
      return getGroupConfig(nodes, isEmpty, groupId);
    }

    return getDefaultConfig(nodes, isEmpty);
  }, [search, groupId]);

  return config;
};

const prepareToSearch = (text: string) => text.replace(/\s/g, "").toLowerCase();

export const makePageMenuItem = (
  document: Document,
  applyMenuItem: () => void
): MenuItem => ({
  text: getDocumentTitle(document) || "[no title]",
  icon: () => <MenuIcon component={NoteIcon} />,
  applyMenuItem: () => {
    applyMenuItem();
  },
});
