import { useCallback } from "react";

import useNotifications from "hooks/useNotifications";

const useNotify = () => {
  const { addNotification, removeNotification } = useNotifications();

  const success = useCallback(
    (text, duration = 2500, onRemoval = null) =>
      addNotification({
        onRemoval,
        type: "success",
        duration,
        text,
      }),
    []
  );
  const warning = useCallback(
    (text, duration = 2500, onRemoval = null) =>
      addNotification({
        onRemoval,
        type: "warning",
        duration,
        text,
      }),
    []
  );
  const error = useCallback(
    (text, duration = 2500, onRemoval = null) =>
      addNotification({
        onRemoval,
        type: "error",
        duration,
        text,
      }),
    []
  );
  const loading = useCallback(
    (text, duration = 0) =>
      addNotification({
        type: "loading",
        duration,
        text,
      }),
    []
  );

  return {
    success,
    error,
    warning,
    loading,
    custom: addNotification,
    remove: removeNotification,
  };
};

export default useNotify;
