import React from "react";

import { SearchIcon } from "thunk-icons";

import styles from "./index.module.scss";

const GraphSearch = ({ value, onChange }: any) => {
  return (
    <div className={styles.searchInputContainer}>
      <SearchIcon
        className={styles.searchIcon}
        color="var(--purple-color)"
        size={28}
      />
      <input
        placeholder="Search graph..."
        className={styles.searchInput}
        type="search"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default GraphSearch;
