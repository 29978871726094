import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import {
  ReactEditor,
  useReadOnly,
  useSelected,
  useSlateStatic,
} from "slate-react";
import { Transforms } from "slate";

import { ElementProps } from "components/slate/types";
import { MediaEmbedElement } from "../types";
import TextInput from "framework/components/form/TextInput";
import { getMediaUrl } from "../utils";
import Button from "framework/components/form/Button";

import styles from "./MediaEmbedElement.module.scss";

const INVALID_MESSAGE = "Whoops, looks like that's not a valid embed url";

export const MediaEmbedUrlComponent = ({
  children,
  attributes,
  element,
}: ElementProps & { element: MediaEmbedElement }) => {
  const { inputText } = element;
  const selected = useSelected();
  const editor = useSlateStatic();
  const readOnly = useReadOnly();
  const isValid = getMediaUrl(inputText);
  const [error, setError] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const elPath = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
      editor,
      {
        inputText: e.target.value || "",
      },
      { at: elPath }
    );
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validUrl = getMediaUrl(inputText);
    if (!validUrl) {
      setError(INVALID_MESSAGE);
      return;
    }
    const elPath = ReactEditor.findPath(editor, element);
    Transforms.setNodes(
      editor,
      {
        url: validUrl,
      },
      { at: elPath }
    );
  };

  useEffect(() => {
    if (inputRef.current && selected) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      {...attributes}
      className={cn(styles.root, {
        [styles.selected]: selected,
        [styles.readOnly]: readOnly,
      })}
    >
      <div className={styles.content} contentEditable={false}>
        {!readOnly && (
          <form className={styles.inputForm} onSubmit={onSubmit}>
            <TextInput
              inputRef={inputRef}
              type="text"
              name="url"
              error={error}
              placeholder={"Paste embed url here"}
              value={inputText}
              onChange={onInputChange}
            />
            <Button
              type="submit"
              variant="primary"
              disabled={!isValid}
              btnClass={styles.submit}
            >
              Embed
            </Button>
          </form>
        )}
        {readOnly && <div className={styles.readOnly}>Embedded Media</div>}
      </div>
      {children}
    </div>
  );
};
