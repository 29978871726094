import { differenceInCalendarDays, format } from "date-fns";

export const formatDate = (date: Date) => {
  if (isNaN(date as any)) {
    return null;
  }

  return format(date, "MMMM d, yyyy");
};

export const formatDifferenceInDays = (date: Date) => {
  const daysDifference = differenceInCalendarDays(new Date(), date);

  if (daysDifference === -1) {
    return "Tomorrow";
  } else if (daysDifference === 0) {
    return "Today";
  } else if (daysDifference === 1) {
    return "Yesterday";
  } else if (daysDifference > 1 && daysDifference < 7) {
    return `${daysDifference} Days Ago`;
  } else {
    return formatDate(date);
  }
};

export function formatTime(date: Date) {
  return format(date, "p");
}

export const formatDateTime = (date: Date): string => {
  return format(date, "Pp");
};
