import { useRef } from "react";
import {
  TodayIcon,
  GraphIcon,
  TodayFilledIcon,
  GraphFilledIcon,
  TemplateIcon,
  TemplateFilledIcon,
  SignatureIcon,
  TagsIcon,
  TagsFilledIcon,
  AllNotesIcon,
  AllNotesFilledIcon,
} from "thunk-icons";
import { useLocation } from "react-router-dom";
import { BsHeadset } from "react-icons/bs";

import NavigationItem from "components/layout/NavigationItem";
import {
  useVerticalTabPointer,
  VerticalTabPointer,
} from "framework/components/Pointer";
import { useAuth } from "auth/AuthProvider";
import { AuthAccessTypes, SpecialUsers } from "auth/types";


import styles from "./index.module.scss";

const Divider = () => {
  return <div className={styles.divider} />;
};

const AppNavigation = () => {
  const { specialUser, authAccessType } = useAuth();
  const location = useLocation();
  const navRef = useRef(null);
  const activeClassName = "active";

  const tabPointer = useVerticalTabPointer(navRef, activeClassName, [location]);

  return (
    <nav ref={navRef} className={styles.nav}>
      {specialUser ? (
        <>
          <NavigationItem
            activeClassName={activeClassName}
            to="/overview"
            title="All Notes"
            svgComponent={AllNotesIcon}
            activeSvgComponent={AllNotesFilledIcon}
          />
          <NavigationItem
            activeClassName={activeClassName}
            to="/snippets"
            title="Templates"
            svgComponent={TemplateIcon}
            activeSvgComponent={TemplateFilledIcon}
          />
          <NavigationItem
            activeClassName={activeClassName}
            to="/tags"
            title="Tags"
            svgComponent={TagsIcon}
            activeSvgComponent={TagsFilledIcon}
          />
          <Divider />
          <NavigationItem
            to={"/"}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/";
            }}
            title={`Back to My User`}
            svgComponent={SignatureIcon}
          />
        </>
      ) : (
        <>
          <NavigationItem
            activeClassName={activeClassName}
            to="/writing"
            title="Today"
            kbdShortcut="Ctrl+1"
            svgComponent={TodayIcon}
            activeSvgComponent={TodayFilledIcon}
          />
          <NavigationItem
            activeClassName={activeClassName}
            to="/graph"
            title="Explore"
            kbdShortcut="Ctrl+2"
            svgComponent={GraphIcon}
            activeSvgComponent={GraphFilledIcon}
          />
          <NavigationItem
            activeClassName={activeClassName}
            to="/overview"
            title="All Notes"
            kbdShortcut="Ctrl+3"
            svgComponent={AllNotesIcon}
            activeSvgComponent={AllNotesFilledIcon}
          />
          <NavigationItem
            activeClassName={activeClassName}
            to="/tags"
            title="Tags"
            kbdShortcut="Ctrl+4"
            svgComponent={TagsIcon}
            activeSvgComponent={TagsFilledIcon}
          />
          <NavigationItem
            activeClassName={activeClassName}
            to="/snippets"
            title="Templates"
            kbdShortcut="Ctrl+5"
            svgComponent={TemplateIcon}
            activeSvgComponent={TemplateFilledIcon}
          />
          <Divider />
          {authAccessType === AuthAccessTypes.admin && (
            <NavigationItem
              activeClassName={activeClassName}
              to={"/feedback"}
              title={`Feedback`}
              svgComponent={BsHeadset}
            />
          )}
          {authAccessType === AuthAccessTypes.admin && (
            <NavigationItem
              to={"/"}
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "/" + SpecialUsers.ContentManger.toString() + "/snippets";
              }}
              title={`Content Manager`}
              svgComponent={SignatureIcon}
            />
          )}
        </>
      )}
      <VerticalTabPointer
        className={styles.pointer}
        {...tabPointer}
        height={tabPointer.height && 32}
      />
    </nav>
  );
};

export default AppNavigation;
