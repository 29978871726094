import React, { useState } from "react";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";

import AuthForm from "components/AuthForm";
import AuthPageContainer from "components/AuthPageContainer";
import useNotifications from "hooks/useNotifications";
import { getErrorInfo } from "./errors";
import { env } from "thunk-env";

const ForgotPasswordPage = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const { addNotification } = useNotifications();
  //  @ts-ignore ts-migrate(2345) FIXME: Argument of type '{ cachePolicy: "no-cache"; }' is... Remove this comment to see the full error message
  const passwordReq = useFetch("/email/resetPassword", {
    // @ts-ignore
    cachePolicy: "no-cache",
  });

  const { t } = useTranslation();

  const onSubmit = async (data: any) => {
    const handleError = (error: any) => {
      const errorInfo = getErrorInfo(error);

      addNotification({
        duration: 4500,
        type: "error",
        text: t(errorInfo.message, {
          email: env.REACT_APP_SUPPORT_EMAIL,
        }),
      });

      setSuccessMessage("Password reset email sent"); // For reset we show success even if email not found
      setSubmitting(false);
    };

    setSubmitting(true);

    try {
      const { email } = data;
      const response = await passwordReq.post("", {
        email,
      });

      if (!passwordReq.response.ok) {
        handleError(response || { code: "thunk/connection-error" });
        return;
      }

      addNotification({
        duration: 4500,
        type: "success",
        text: `Password reset email sent: ${email}`,
      });

      setSuccessMessage("Password reset email sent");
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  return (
    <AuthPageContainer>
      <AuthForm
        onSubmit={onSubmit}
        submitting={submitting}
        successMessage={successMessage}
        primaryText="Reset Password"
        actionText="Send Reset Email"
        mode="reset"
      />
    </AuthPageContainer>
  );
};

export default ForgotPasswordPage;
