import React from "react";
//  @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Link } from "react-router-dom";

import Button from "framework/components/form/Button";
import { useSidebarContext } from "providers/SidebarProvider";

//  @ts-ignore ts-migrate(2307) FIXME: Cannot find module './index.module.scss' or its co... Remove this comment to see the full error message
import styles from "./index.module.scss";
import SimpleTooltip from "components/SimpleTooltip";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";

const AppSidebarActions = () => {
  const { isWindows } = useDeviceDetector();
  const metaKey = isWindows ? "Ctrl" : "⌘";
  const optionKey = isWindows ? "Alt" : "⌥";
  //  @ts-ignore ts-migrate(2339) FIXME: Property 'isSidebarOpened' does not exist on type ... Remove this comment to see the full error message
  const { isSidebarOpened } = useSidebarContext();

  return null;

  // return (
  //   <SimpleTooltip
  //     content={`New note (${metaKey}+${optionKey}+N)`}
  //     placement="top"
  //     offset={[0, 17]}
  //   >
  //     <Button
  //       as={Link}
  //       to="/page/new"
  //       variant="secondary"
  //       size={isSidebarOpened ? "large" : "small"}
  //       btnClass={styles.button}
  //     >
  //       {isSidebarOpened ? "+ New note" : "+"}
  //     </Button>
  //   </SimpleTooltip>
  // );
};
export default AppSidebarActions;
