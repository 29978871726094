import React from "react";
import { FeedbackMood } from "db/feedback/feedback.types";
import Button from "framework/components/form/Button";

import styles from "components/feedback/index.module.scss";

type Props = {
  selectedMood?: FeedbackMood;
  onFeedback: (mood: FeedbackMood) => void;
};

const FeedbackMoodSwitch = ({ selectedMood, onFeedback }: Props) => {
  return (
    <div className={styles.moodSwitch}>
      <FeedbackButton
        icon="🤩"
        selectedMood={selectedMood}
        onFeedback={onFeedback}
        buttonMood={FeedbackMood.Excited}
      />
      <FeedbackButton
        icon="😀"
        selectedMood={selectedMood}
        onFeedback={onFeedback}
        buttonMood={FeedbackMood.Happy}
      />
      <FeedbackButton
        icon="😐"
        selectedMood={selectedMood}
        onFeedback={onFeedback}
        buttonMood={FeedbackMood.Neutral}
      />
      <FeedbackButton
        icon="😕"
        selectedMood={selectedMood}
        onFeedback={onFeedback}
        buttonMood={FeedbackMood.Confused}
      />
      <FeedbackButton
        icon="🙁"
        selectedMood={selectedMood}
        onFeedback={onFeedback}
        buttonMood={FeedbackMood.Upset}
      />
      <FeedbackButton
        icon="😡"
        selectedMood={selectedMood}
        onFeedback={onFeedback}
        buttonMood={FeedbackMood.Angry}
      />
    </div>
  );
};

export default FeedbackMoodSwitch;

const FeedbackButton = ({
  icon,
  selectedMood,
  buttonMood,
  onFeedback,
}: {
  icon: string;
  selectedMood: FeedbackMood;
  buttonMood: FeedbackMood;
  onFeedback: (option: FeedbackMood) => void;
}) => {
  return (
    <Button
      variant="secondary"
      size="small"
      isSelected={selectedMood === buttonMood}
      onClick={() => onFeedback(buttonMood)}
    >
      <span className={styles.buttonIcon}>{icon}</span>
      <span className={styles.buttonText}>&nbsp;{buttonMood}</span>
    </Button>
  );
};
