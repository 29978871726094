import React, { useCallback, useState } from "react";
import { ReactEditor } from "slate-react";
import { Editor } from "slate";

const useEditorFocused = (editor: Editor) => {
  const [, forceRerender] = useState(0);
  const focused = ReactEditor.isFocused(editor);

  const onFocus = useCallback((e: React.FocusEvent) => {
    forceRerender((x) => x + 1);
  }, []);

  const onBlur = useCallback((e: React.FocusEvent) => {
    forceRerender((x) => x + 1);
  }, []);

  return { focused, onFocus, onBlur };
};

export default useEditorFocused;
