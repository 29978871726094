import React from "react";
import cn from "classnames";
import { TippyProps } from "@tippyjs/react";

import SimpleTooltip from "components/SimpleTooltip";

import styles from "./styles.module.scss";

type Props = {
  active?: boolean;
  icon: React.ReactNode;
  onMouseDown: React.MouseEventHandler;
  tooltip?: TippyProps;
};

const ToolbarButton = (props: Props) => {
  const { active = false, icon, onMouseDown, tooltip } = props;

  const content = (
    <span
      className={cn(styles.toolbarButton, {
        [styles.toolbarButtonActive]: active,
      })}
      onMouseDown={onMouseDown}
    >
      {icon}
    </span>
  );

  if (tooltip) {
    return (
      <SimpleTooltip {...tooltip} className={styles.tooltip} noStyles={true}>
        {content}
      </SimpleTooltip>
    );
  }

  return content;
};

export default ToolbarButton;
