import React, { useState } from "react";
import { Editor } from "slate";
import { observer } from "mobx-react-lite";

import { TemplatePreviewCard } from "./TemplatePreviewCard";
import TemplatesGrid from "./TemplatesGrid";
import Button from "framework/components/form/Button";
import { useStore } from "stores/store";

import styles from "./index.module.scss";

type EmptyNoteSuggestionsProps = {
  editor: Editor;
  minified: boolean;
};

const EmptyNoteSuggestions = observer(
  ({ editor, minified }: EmptyNoteSuggestionsProps) => {
    const [showTemplates, setShowTemplates] = useState(!minified);
    const [hasLimit, setHasLimit] = useState(true);

    const { templatesStore } = useStore();

    const allTemplates = templatesStore.getDocuments();
    const isLoading = templatesStore.loading;

    const limit = 3;

    if (isLoading) {
      return null;
    }

    const title = "Insert template:";

    const templates = hasLimit ? allTemplates.slice(0, limit) : allTemplates;

    if (templates.length === 0) {
      return null;
    }

    if (!showTemplates) {
      return (
        <Button
          btnClass={styles.templatesButton}
          variant="secondary"
          onClick={() => setShowTemplates(true)}
        >
          Templates
        </Button>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.templatesSection}>
          <div className={styles.insertTemplateTitle}>{title}</div>
          <TemplatesGrid
            items={templates}
            renderGridItem={({ item }) => (
              <TemplatePreviewCard
                key={item.id}
                editor={editor}
                template={item}
              />
            )}
          />
          {allTemplates.length > limit && (
            <Button
              variant="ghost"
              btnClass={styles.moreButton}
              onClick={() => {
                setHasLimit((state) => !state);
              }}
            >
              {hasLimit ? "more" : "less"}...
            </Button>
          )}
        </div>
      </div>
    );
  }
);

export default EmptyNoteSuggestions;
