import { Timestamp } from "firebase/firestore";

import { makeSharedId } from "helpers";
import { v4 as uuid } from "uuid";
import {
  Document,
  DocumentType,
  MakeDocumentAsBacklinkParams,
  MakeDocumentParams,
} from "thunk-core";

export const makeDocument = (params: MakeDocumentParams): Document => {
  const id = uuid();

  const document: Document = {
    id: params.id || id,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    type: params.type,
    userId: params.userId,
    dateId: params.dateId ?? null,
    parentId: params.parentId ?? null,
    sharedId: null,
    originPageId: null,
    originUserId: null,
    title: params.title || "",
    lowercaseTitle: params.title ? params.title.toLowerCase() : "",
    blocks: params.blocks || [],
    tags: [],
    isShared: false,
    isFavorite: false,
    isEmpty: true,
    showWordCount: false,
    hasSyncedRemote: false,
    headerImage: params.headerImage ?? null,
  };

  return document;
};

export const makeDocumentAsBacklink = (
  params: MakeDocumentAsBacklinkParams
) => {
  const document: Document = {
    id: params.id,
    createdAt: Timestamp.now(),
    updatedAt: Timestamp.now(),
    type: DocumentType.PAGE,
    userId: params.userId,
    dateId: null,
    parentId: params.parentId ?? null,
    sharedId: makeSharedId(params.title),
    originPageId: null,
    originUserId: null,
    title: params.title,
    lowercaseTitle: params.title.toLowerCase(),
    blocks: [],
    tags: [],
    isShared: false,
    isFavorite: false,
    isEmpty: true,
    showWordCount: false,
    hasSyncedRemote: false,
    headerImage: params.headerImage ?? null,
  };

  return document;
};
