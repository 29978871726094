import React, { useContext, useState, useEffect } from "react";
import useFetch, { CachePolicies, FetchContext } from "use-http";

import { useCurrentUserId } from "db/currentUser";

const SearchContext = React.createContext<{ key: string | undefined }>(null);

export function useSearch() {
  return useContext(SearchContext);
}

export const SearchProvider = ({ children }: any) => {
  const userId = useCurrentUserId();
  const fetchContext = useContext(FetchContext);
  const [key, setKey] = useState<string | undefined>(undefined);

  const getSearchKey = useFetch("/search/getScopedKey", {
    cachePolicy: CachePolicies.NO_CACHE,
  });

  useEffect(() => {
    async function getKey() {
      const searchKey = await getSearchKey.get();
      if (!searchKey || searchKey.error) return;
      localStorage.setItem(`${userId}_secureSearchKey`, searchKey);
      setKey(searchKey);
    }
    if (userId) {
      const cachedKey = localStorage.getItem(`${userId}_secureSearchKey`);
      if (cachedKey) {
        setKey(cachedKey);
      } else if (fetchContext.options.headers["Authorization"]) {
        getKey();
      }
    }
  }, [userId, fetchContext.options.headers["Authorization"]]);

  return (
    <SearchContext.Provider
      value={{
        key,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
