import {
  collection,
  query,
  doc,
  where,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import { db } from "firebaseInstance";
import { getCollectionData } from "db/utils";
import { ListItemType, ListTypes } from "components/slate/plugins/list/types";
import {
  Block,
  BLOCK_CONVERTER,
  DocumentType,
  getBlockPath,
  getBlocksPath,
} from "thunk-core";

export const getPageBlocksQuery = (userId: string, documentId: string) => {
  return query(
    collection(db, getBlocksPath()),
    where("userId", "==", userId), // not sure that we need user id here
    where("pageId", "==", documentId)
  ).withConverter(BLOCK_CONVERTER);
};

export const getPageBlocks = async (userId: string, documentId: string) => {
  return await getCollectionData(getPageBlocksQuery(userId, documentId));
};

export const getAllBlocks = async (userId: string) => {
  return await getCollectionData(
    query(
      collection(db, getBlocksPath()),
      where("userId", "==", userId)
    ).withConverter(BLOCK_CONVERTER)
  );
};

export const getSnippetsBlocks = async (userId: string) => {
  const result = await getCollectionData(
    query(
      collection(db, getBlocksPath()),
      where("userId", "==", userId),
      where("pageType", "==", DocumentType.SNIPPET)
    ).withConverter(BLOCK_CONVERTER)
  );

  return result;
};

export const getAllReferencesBlocks = async (userId: string) => {
  const result = await getCollectionData(
    query(
      collection(db, getBlocksPath()),
      where("userId", "==", userId),
      where("targets", "!=", [])
    ).withConverter(BLOCK_CONVERTER)
  );

  return result;
};

export const getReferencesBlocks = async (userId: string, targetId: string) => {
  const result = await getCollectionData(
    query(
      collection(db, getBlocksPath()),
      where("userId", "==", userId),
      where("targets", "array-contains", targetId)
    ).withConverter(BLOCK_CONVERTER)
  );

  return result;
};

export const getTaggedBlocks = async (userId: string, tagId: string) => {
  const result = await getCollectionData(
    query(
      collection(db, getBlocksPath()),
      where("userId", "==", userId),
      where("tags", "array-contains", tagId)
    ).withConverter(BLOCK_CONVERTER)
  );

  return result;
};

export const getTasksBlocks = async (userId: string) => {
  const data = await getCollectionData(
    query(
      collection(db, getBlocksPath()),
      where("userId", "==", userId),
      where("pageType", "!=", DocumentType.SNIPPET),
      where("type", "==", ListItemType),
      where("properties.listType", "==", ListTypes.TodoList)
    ).withConverter(BLOCK_CONVERTER)
  );

  return data;
};

export const updateBlock = async (blockId: string, updates: Partial<Block>) => {
  await updateDoc(
    doc(db, getBlockPath(blockId)).withConverter(BLOCK_CONVERTER),
    updates
  );
};

export const removeBlock = async (blockId: string) => {
  await deleteDoc(doc(db, getBlockPath(blockId)));
};
