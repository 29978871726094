import React, { memo } from "react";
import { useSlateStatic } from "slate-react";

import RangePopper from "components/slate/components/RangePopper";
import EmojiSelect from "components/slate/plugins/emoji/components/EmojiComponent/EmojiSelect";

export const EmojiComponent = memo((props: any) => {
  const { targetRange, setTargetRange } = props;
  const editor = useSlateStatic();
  const popperOptions = {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [160, 5],
        },
      },
    ],
  };

  return (
    <RangePopper
      range={targetRange}
      onClickOutside={() => targetRange && setTargetRange(null)}
      isVisible={targetRange}
      popperOptions={popperOptions}
    >
      <EmojiSelect editor={editor} {...props} />
    </RangePopper>
  );
});
