import { useEffect } from "react";

import Button from "framework/components/form/Button";
import { useSignUpContext } from "../SignUpProvider";
import nigelNervous from "images/nigel_nervous.svg";

import formStyles from "../index.module.scss";

const NoHumor = () => {
  const {
    formValues,
    handleSubmitSillyName,
    requiresName,
  } = useSignUpContext();

  useEffect(() => {
    requiresName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={formStyles.signUpForm}>
      <figure className={formStyles.signUpFormImageContainer}>
        <img
          src={nigelNervous}
          alt="Nigel"
          className={formStyles.signUpFormImage}
        />
      </figure>

      <h3 className={formStyles.signUpFormHeadline}>
        Thanks for the feedback {formValues.firstName}. We'll just stick to
        making software.
      </h3>

      <div style={{ textAlign: "center" }}>
        <Button variant="secondary" onClick={handleSubmitSillyName} autoFocus>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default NoHumor;
