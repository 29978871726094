import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { useCurrentUserId } from "db/currentUser";
import { useUserData } from "hooks/useUserData";
import { createDailyTemplate } from "./utils";
import { updateUser } from "db/user/user.queries";

export type DailyTemplateFormValues = {
  morningRoutine: boolean;
  gratitudes: boolean;
  dailyLog: boolean;
  dailySummary: boolean;
};

export type ReminderFormValues = {
  action: string;
  customCue: string;
  time: string;
  days: string;
};

export interface OnboardingContextProps {
  submitting: boolean;
  currentUser: any;
  userLoading: boolean;
  formValues: DailyTemplateFormValues;
  setFormValues: (values: DailyTemplateFormValues) => void;
  reminderFormValues: ReminderFormValues;
  setReminderFormValues: (values: ReminderFormValues) => void;
  handleReminderSubmit: () => Promise<void>;
  handleSubmit: () => Promise<void>;
}

export interface OnboardingProviderProps {}

const OnboardingContext = React.createContext<OnboardingContextProps | null>(
  null
);

export function useOnboardingContext() {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error(
      "useOnboardingContext must be used within an OnboardingProvider"
    );
  }
  return context;
}

function useOnboardingProvider() {
  const userId = useCurrentUserId();
  const [currentUser, userLoading] = useUserData(userId);
  const history = useHistory();
  const [formValues, setFormValues] = useState<DailyTemplateFormValues>({
    morningRoutine: true,
    gratitudes: true,
    dailyLog: true,
    dailySummary: true,
  });
  const [reminderFormValues, setReminderFormValues] = useState<
    ReminderFormValues
  >({
    action: null,
    customCue: null,
    time: null,
    days: null,
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  // If user refreshes page, reset to beginning
  useEffect(() => {
    history.push("/onboarding");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.hasCompletedOnboarding &&
      !window.sessionStorage.getItem("forkPage")
    ) {
      history.push("/");
    }
  }, [currentUser, history]);

  const handleSubmit = useCallback(async () => {
    if (currentUser.hasCompletedOnboarding) {
      history.push("/");
      return;
    }
    setSubmitting(true);
    const { morningRoutine, gratitudes, dailyLog, dailySummary } = formValues;
    const dailyTemplateOptions = {
      morningRoutine,
      gratitudes,
      dailyLog,
      dailySummary,
    };
    createDailyTemplate(userId, dailyTemplateOptions);
    await updateUser(userId, { hasCompletedOnboarding: true });
  }, [currentUser, formValues, history, userId]);

  const handleReminderSubmit = useCallback(async () => {
    setSubmitting(true);
    const { action, customCue, time, days } = reminderFormValues;
    try {
      await updateUser(userId, {
        onboardingReminderAction: action,
        onboardingReminderCue: customCue || "",
        onboardingReminderTime: time,
        onboardingReminderDays: days,
      });
      history.push("/onboarding/success");
      setSubmitting(false);
    } catch {
      setSubmitting(false);
    }
  }, [history, reminderFormValues, userId]);

  const value = useMemo(
    () => ({
      formValues,
      submitting,
      currentUser,
      userLoading,
      reminderFormValues,
      handleReminderSubmit,
      setReminderFormValues,
      setFormValues,
      handleSubmit,
    }),
    [
      formValues,
      submitting,
      currentUser,
      userLoading,
      reminderFormValues,
      handleReminderSubmit,
      setReminderFormValues,
      setFormValues,
      handleSubmit,
    ]
  );
  return value;
}

export const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const value = useOnboardingProvider();
  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};
