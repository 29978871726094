export const getScaledImage = (editor: any) => {
  const canvas = editor.getImage();

  const baseSize = Math.min(canvas.width, canvas.height);
  const resultSize = 500;

  if (baseSize > resultSize) {
    // redraw with scaling to restrict image size
    const newCanvas = document.createElement("canvas");

    newCanvas.width = resultSize;
    newCanvas.height = resultSize;

    const context = newCanvas.getContext("2d");
    const scaleFactor = resultSize / baseSize;
    //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
    context.scale(scaleFactor, scaleFactor);

    //  @ts-ignore ts-migrate(2531) FIXME: Object is possibly 'null'.
    context.drawImage(canvas, 0, 0);

    return newCanvas;
  }

  return canvas;
};
