import { UseSlatePlugin } from "components/slate/types";
import { withFileUpload } from "components/slate/plugins/file/withFileUpload";
import ImageComponent from "components/slate/plugins/file/components/Image";
import {
  isFileElement,
  isImageElement,
} from "components/slate/plugins/file/utils";
import { uploadImage } from "components/slate/plugins/file/uploadImage";
import { uploadFile } from "./uploadFile";
import { FileComponent } from "./components/File";

const useFilePlugin: UseSlatePlugin<{
  userId?: string;
  isReferenceEditor?: boolean;
}> = ({ userId, isReferenceEditor = false }) => {
  return {
    withOverrides: withFileUpload({
      isReferenceEditor,
      userId,
      uploadImage,
      uploadFile,
    }),
    renderElement: (props) => {
      if (isImageElement(props.element)) {
        return <ImageComponent {...props} element={props.element} />;
      }
      if (isFileElement(props.element)) {
        return <FileComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useFilePlugin;
