import { uniq } from "ramda";

import delay from "utils/delay";
import { store } from "stores/store";

export type DocumentSyncData = {
  documentChanged: boolean;
  blocksChanged: string[];
  blocksRemoved: string[];
};

type Synchronize = (
  documentId: string,
  data: DocumentSyncData
) => Promise<void>;

export default class DocumentsSynchronizer {
  workers = new Map<string, DocumentSyncData>();
  synchronize: Synchronize = null;

  constructor(synchronize: Synchronize) {
    this.synchronize = synchronize;
  }

  async runWorker(documentId: string): Promise<void> {
    const { syncMonitorStore } = store;
    syncMonitorStore.syncPending(documentId);
    await delay(1); // be sure it runs async way

    while (true) {
      await delay(500);

      const workerData = this.workers.get(documentId);

      if (!workerData) {
        throw new Error("Worker data can't be null at this moment");
      }

      if (!this.isEmpty(workerData)) {
        const extracted = this.extractData(documentId);
        try {
          await this.synchronize(documentId, extracted);

          syncMonitorStore.syncSucceed(documentId);
        } catch (error) {
          console.error(error);

          syncMonitorStore.syncFailed(documentId);

          await delay(3000);
          this.assignUpdates(this.workers.get(documentId), extracted); // revert
        }
      } else {
        return;
      }
    }
  }

  async notifyWorker(
    documentId: string,
    updates: Partial<DocumentSyncData>
  ): Promise<void> {
    if (this.isEmpty(updates)) {
      return;
    }

    if (this.workers.has(documentId)) {
      const workerData = this.workers.get(documentId);

      if (!workerData) {
        throw new Error("Worker data can't be null at this moment");
      }

      this.assignUpdates(workerData, updates);

      return;
    }

    this.workers.set(
      documentId,
      this.assignUpdates(this.getInitialData(), updates)
    );

    await this.runWorker(documentId);

    this.workers.delete(documentId);
  }

  assignUpdates(data: DocumentSyncData, updates: Partial<DocumentSyncData>) {
    const { documentChanged, blocksChanged, blocksRemoved } = updates;

    if (documentChanged != null) {
      data.documentChanged = documentChanged;
    }

    if (blocksChanged != null) {
      data.blocksChanged = uniq([...data.blocksChanged, ...blocksChanged]);
    }

    if (blocksRemoved != null) {
      data.blocksRemoved = uniq([...data.blocksRemoved, ...blocksRemoved]);
    }

    return data;
  }

  isEmpty(data: DocumentSyncData | Partial<DocumentSyncData>) {
    return (
      !data.documentChanged &&
      (!data.blocksChanged || data.blocksChanged.length === 0) &&
      (!data.blocksRemoved || data.blocksRemoved.length === 0)
    );
  }

  extractData(documentId: string): DocumentSyncData {
    const data = this.workers.get(documentId);
    this.workers.set(documentId, this.getInitialData());

    return data;
  }

  getInitialData(): DocumentSyncData {
    return {
      documentChanged: false,
      blocksChanged: [],
      blocksRemoved: [],
    };
  }
}
