import React from "react";
import cn from "classnames";

import styles from "framework/components/form/Button/index.module.scss";

const ButtonGroup = ({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) => {
  return <div className={cn(styles.buttonGroup, className)}>{children}</div>;
};

export default ButtonGroup;
