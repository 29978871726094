import React from "react";
import { MenuNoteIcon } from "thunk-icons";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import { EditorActions } from "components/slate/actions";
import { DocumentType, Tag } from "thunk-core";
import { Editor } from "slate";
import { store } from "stores/store";
import { makeTag } from "db/tags/tags.mapping";

export type HashMenuItem = {
  text: string;
  hint?: string;
  tag?: Tag;
  icon?: React.ComponentType<any>;
  applyInEditor: ({ editor }: { editor: Editor }) => void;
  applyInCard: () => void;
};

export const makeTagMenuItem = ({
  text,
  hint,
  documentId,
  documentType,
  tag,
}: {
  text: string;
  hint: string;
  documentId: string;
  documentType: DocumentType;
  tag: Tag;
}): HashMenuItem => {
  return {
    text,
    hint,
    tag,
    icon: () => <MenuNoteIcon size={17} />,
    applyInEditor: ({ editor }) =>
      EditorActions.insertTag({ editor }, { documentId, documentType, tag }),
    applyInCard: () => {
      const { pagesStore } = store;
      pagesStore.addTag(documentId, tag.id);
    },
  };
};

export const makeNewTagMenuItem = ({
  text,
  hint,
  documentId,
  documentType,
  userId,
  title,
}: {
  text: string;
  hint: string;
  documentId: string;
  documentType: DocumentType;
  userId: string;
  title: string;
}): HashMenuItem => {
  return {
    text,
    hint,
    icon: () => <MenuNoteIcon size={17} />,
    applyInEditor: ({ editor }) =>
      EditorActions.insertNewTag(
        { editor },
        { documentId, documentType, userId, title }
      ),
    applyInCard: () => {
      const { pagesStore, tagsStore } = store;

      const tag = makeTag({ userId, title: text });
      tagsStore.createNewTag(tag);

      pagesStore.addTag(documentId, tag.id);
    },
  };
};

export const highlightMatch = (value: string, search: string) => {
  return match(value, search, { requireMatchAll: true }) as [number, number][];
};

export const highlightParse = (
  value: string,
  matchResult: [number, number][]
) => {
  return parse(value, matchResult) as { text: string; highlight: boolean }[];
};
