import { Editor, Transforms } from "slate";

import { HorizontalRuleType } from "components/slate/plugins/horizontalRule/types";
import { ParagraphType } from "components/slate/plugins/paragraph/types";
import { makeNodeId } from "components/slate/config/makeNodeId";

export const insertHorizontalRule = (editor: Editor) => {
  Transforms.setNodes(editor, { type: HorizontalRuleType });
  Transforms.insertNodes(editor, {
    id: makeNodeId(true),
    type: ParagraphType,
    children: [{ text: "" }],
  });
};
