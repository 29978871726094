import { memo } from "react";
import {
  CopyLinkIcon,
  DeleteNoteIcon,
  FavoritesIcon,
  MoreIcon,
  ShareIcon,
  TagsIcon,
  UnshareIcon,
  Number3,
} from "thunk-icons";
import { observer } from "mobx-react-lite";

import CardToolbarButton from "framework/components/CardToolbarButton";
import MenuPopper, {
  useMenuPopper,
} from "components/editor/toolbar/CardMainToolbar/MenuPopper";
import ToolbarMenu, {
  ToolbarMenuItem,
} from "components/editor/toolbar/CardMainToolbar/ToolbarMenu";
import { CardToolbar } from "framework/components/Card";
import SimpleTooltip from "components/SimpleTooltip";
import useNotify from "hooks/useNotify";
import useDialog from "hooks/useDialog";
import DeleteConfirmModal from "components/modals/DeleteConfirmModal";
import { useDocumentsStore } from "stores/store";
import { useCurrentUserId } from "db/currentUser";
import { makeSharedId } from "helpers";
import { useAppReadOnly } from "auth/hooks/useAppReadOnly";
import AddTagMenu from "components/tags/AddTagMenu";
import { Document, DocumentType } from "thunk-core";

import styles from "./index.module.scss";

const ToolbarComponent = observer(
  (props: {
    document: Document;
    readOnly?: boolean;
    wordCount?: number;
    onAfterRemove?: () => void;
  }) => {
    return (
      <ToolbarComponentMemoized
        documentId={props.document.id}
        documentType={props.document.type}
        dateId={props.document.dateId}
        sharedId={props.document.sharedId}
        isShared={props.document.isShared}
        title={props.document.title}
        isFavorite={props.document.isFavorite}
        isWordCountVisible={props.document.showWordCount}
        wordCount={props.wordCount}
        readOnly={props.readOnly}
        onAfterRemove={props.onAfterRemove}
      />
    );
  }
);

const ToolbarComponentMemoized = memo(
  (props: {
    documentType: Document["type"];
    documentId: string;
    dateId: string;
    sharedId: string;
    isShared: boolean;
    title: string;
    isFavorite: Document["isFavorite"];
    readOnly?: boolean;
    isWordCountVisible?: boolean;
    wordCount?: number;
    onAfterRemove?: () => void;
  }) => {
    const {
      documentId,
      isFavorite,
      documentType,
      dateId,
      sharedId,
      isShared,
      title,
      wordCount,
      isWordCountVisible,
      onAfterRemove,
    } = props;

    const documentsStore = useDocumentsStore(documentType);
    const userId = useCurrentUserId();

    const notify = useNotify();
    const removeDialog = useDialog<{}, boolean>();

    const appReadOnly = useAppReadOnly();
    const readOnly = props.readOnly || appReadOnly;

    const [menuTargetProps, menuPopperProps] = useMenuPopper({
      options: {
        placement: "bottom-end",
      },
    });
    const { closePopper } = menuPopperProps;

    const copyLink = async (sharedId: string) => {
      const url = `${window.location.origin}/${userId}/${sharedId}`;
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(url);
        notify.success(
          "Link copied to your clipboard, opening in a new tab...",
          1500,
          () => {
            window.open(url, "_blank");
          }
        );
      } else {
        window.open(url, "_blank");
      }
    };

    const handleFavorite = async () => {
      closePopper();
      if (!isFavorite) {
        documentsStore.updateDocument(documentId, { isFavorite: true });
        notify.success("Added to favorites");
      } else {
        documentsStore.updateDocument(documentId, { isFavorite: false });
        notify.success("Removed from favorites");
      }
    };

    const handleAddTag = async () => {
      closePopper();
    };

    const handleToggleWordCount = async () => {
      closePopper();
      const newVisibility = !props.isWordCountVisible;
      documentsStore.updateDocument(documentId, {
        showWordCount: newVisibility,
      });
      const onOff = newVisibility ? "on" : "off";
      notify.success(`Word count ${onOff}`);
    };

    const handleUnshare = async () => {
      closePopper();
      await documentsStore.updateDocumentRemote(documentId, {
        sharedId: null,
        isShared: false,
      });
      notify.success("Unshared");
    };

    const handleShare = async () => {
      closePopper();
      await documentsStore.updateDocumentRemote(documentId, {
        sharedId: makeSharedId(
          documentType === DocumentType.NOTE ? dateId : title
        ),
        isShared: true,
      });

      const updated = documentsStore.getDocument(documentId);

      copyLink(updated.sharedId);
    };

    const handleRemove = async () => {
      const isConfirmed = await removeDialog.open(
        "Do you want to remove this page?"
      );
      if (isConfirmed) {
        closePopper();
        const removingNotificationId = notify.loading("Deleting...");

        try {
          await documentsStore.removeDocumentRemote(documentId);
          onAfterRemove();
          notify.success("Note deleted.");
        } catch (error) {
          console.error(error);
          notify.error("We weren't able to delete this note.");
        } finally {
          notify.remove(removingNotificationId);
        }
      }
    };

    const favoriteIconProps = {
      id: "favoriteIconProps",
      title: "Add to favorites",
      icon: FavoritesIcon,
      color: isFavorite ? "var(--yellow-color)" : undefined,
      onClick: handleFavorite,
    };

    const tagIconProps = {
      id: "tagIconProps",
      title: "Add tag",
      icon: TagsIcon,
      color: undefined,
      onClick: handleAddTag,
    };

    const copyLinkIconProps = {
      id: "copyLinkIconProps",
      title: "Copy public link",
      icon: CopyLinkIcon,
      color: "var(--baby-blue-color)",
      onClick: () => copyLink(sharedId),
    };

    const shareIconProps = {
      id: "shareIconProps",
      title: isShared ? "Unshare" : "Share",
      icon: isShared ? UnshareIcon : ShareIcon,
      onClick: isShared ? handleUnshare : handleShare,
    };

    const wordCountItemProps = {
      id: "wordCountItemProps",
      title: isWordCountVisible ? "Hide word count" : "Show word count",
      icon: Number3,
      onClick: handleToggleWordCount,
    };

    const removeIconProps = {
      id: "removeIconProps",
      title: "Delete note",
      icon: DeleteNoteIcon,
      onClick: handleRemove,
      className: styles.deleteItem,
    };

    return (
      <>
        <CardToolbar>
          {isWordCountVisible && (
            <SimpleTooltip
              content="Word count"
              placement="top"
              delay={[400, 0]}
            >
              <div className={styles.wordCount}>{wordCount}</div>
            </SimpleTooltip>
          )}
          {[
            !readOnly && favoriteIconProps,
            documentType === DocumentType.PAGE && tagIconProps,
            isShared && copyLinkIconProps,
            !readOnly && shareIconProps,
          ]
            .filter(Boolean)
            .map((props) => {
              if (props.id === "tagIconProps") {
                return (
                  <AddTagMenu
                    documentId={documentId}
                    documentType={documentType}
                  >
                    {({ ref }) => (
                      <SimpleTooltip
                        key={props.id}
                        content={props.title}
                        placement="top"
                        delay={[400, 0]}
                      >
                        <div className={styles.otherButton}>
                          <CardToolbarButton {...props} ref={ref} />
                        </div>
                      </SimpleTooltip>
                    )}
                  </AddTagMenu>
                );
              }

              return (
                <SimpleTooltip
                  key={props.id}
                  content={props.title}
                  placement="top"
                  delay={[400, 0]}
                >
                  <div className={styles.otherButton}>
                    <CardToolbarButton {...props} />
                  </div>
                </SimpleTooltip>
              );
            })}
          {!readOnly && (
            <>
              <SimpleTooltip
                content="Open Card Menu"
                placement="top"
                delay={[400, 0]}
              >
                <div className={styles.moreButton}>
                  <CardToolbarButton icon={MoreIcon} {...menuTargetProps} />
                </div>
              </SimpleTooltip>
              <MenuPopper {...menuPopperProps}>
                <ToolbarMenu>
                  {[
                    shareIconProps,
                    isShared && copyLinkIconProps,
                    favoriteIconProps,
                    wordCountItemProps,
                    removeIconProps,
                  ]
                    .filter(Boolean)
                    .map((props) => (
                      <ToolbarMenuItem key={props.id} {...props} />
                    ))}
                </ToolbarMenu>
              </MenuPopper>
            </>
          )}
          <DeleteConfirmModal
            {...removeDialog}
            title="Are you sure?"
            okText="Delete"
          >
            This note will be deleted forever.
          </DeleteConfirmModal>
        </CardToolbar>
      </>
    );
  }
);

export default ToolbarComponent;
