type TreeNode<T> = T & { children: TreeNode<T>[] };

export const sortRecursively = <T>(
  items: TreeNode<T>[],
  comparator: (a: TreeNode<T>, b: TreeNode<T>) => number
) => {
  items.sort(comparator);

  for (const item of items) {
    sortRecursively(item.children, comparator);
  }
};
