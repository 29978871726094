import { useFunctionsFetch } from "providers/AppFetchProvider";
import React, {
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "firebaseInstance";
import { ErrorInfo, getErrorInfo } from "./errors";
import { useCurrentUserId } from "db/currentUser";
import { useUserData } from "hooks/useUserData";

export type SignUpFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

export enum SignUpStep {
  Name = "name",
  SillyName = "sillyname",
  NoHumor = "oopsname",
  Email = "email",
  Password = "password",
}

export interface SignUpContextProps {
  error: ErrorInfo | null;
  submitting: boolean;
  formValues: SignUpFormValues;
  goBackPassword: () => void;
  handleSubmitName: (
    values: Pick<SignUpFormValues, "firstName" | "lastName">
  ) => void;
  handleClickNoHumor: () => void;
  handleSubmitSillyName: () => void;
  handleSubmitEmail: (values: Pick<SignUpFormValues, "email">) => void;
  handleSubmitPassword: (values: Pick<SignUpFormValues, "password">) => void;
  requiresName: () => void;
  requiresEmail: () => void;
}

export interface SignUpProviderProps {}

const SignUpContext = React.createContext<SignUpContextProps | null>(null);

export function useSignUpContext() {
  const context = useContext(SignUpContext);
  if (!context) {
    throw new Error("useSignUpContext must be used within a SignUpProvider");
  }
  return context;
}

function useSignUpProvider() {
  const functionsFetch = useFunctionsFetch();
  const history = useHistory();
  const [formValues, setFormValues] = useState<SignUpFormValues>({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  });
  const [error, setError] = useState<ErrorInfo | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const userId = useCurrentUserId();
  const [currentUser] = useUserData(userId);

  // Redirect to onboarding after account creation
  useEffect(() => {
    if (
      currentUser &&
      currentUser.shouldSeeOnboarding &&
      !currentUser.hasCompletedOnboarding
    ) {
      history.push("/onboarding");
    }
  }, [currentUser, history]);

  const handleSubmitName = useCallback(
    (values: Pick<SignUpFormValues, "firstName" | "lastName">) => {
      const { firstName, lastName } = values;
      setFormValues((prev) => ({ ...prev, firstName, lastName }));
      history.push(`/signup/${SignUpStep.SillyName}`);
    },
    [history]
  );

  const handleClickNoHumor = useCallback(() => {
    history.push(`/signup/${SignUpStep.NoHumor}`);
  }, [history]);

  const handleSubmitSillyName = useCallback(() => {
    history.push(`/signup/${SignUpStep.Email}`);
  }, [history]);

  const handleSubmitEmail = useCallback(
    (values: Pick<SignUpFormValues, "email">) => {
      const { email } = values;
      setFormValues((prev) => ({ ...prev, email }));
      history.push(`/signup/${SignUpStep.Password}`);
    },
    [setFormValues, history]
  );

  const handleSubmitPassword = useCallback(
    async (values: Pick<SignUpFormValues, "password">) => {
      const { password } = values;
      setFormValues((prev) => ({ ...prev, password }));

      const { email, firstName, lastName } = formValues;

      setSubmitting(true);

      try {
        await functionsFetch("/auth/signup", {
          method: "post",
          body: JSON.stringify({
            email,
            firstName,
            lastName,
            password,
          }),
        });

        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        const errorInfo = getErrorInfo(error);

        setError(errorInfo);
        auth.signOut();
        setSubmitting(false);
      }
    },
    [formValues, functionsFetch]
  );

  const goBackPassword = useCallback(() => {
    setError(null);
    setFormValues((prev) => ({ ...prev, password: "" }));
    history.push(`/signup/${SignUpStep.Email}`);
  }, [setFormValues, history]);

  const requiresName = useCallback(() => {
    if (!formValues.firstName || !formValues.lastName) {
      history.push(`/signup`);
    }
  }, [formValues.firstName, formValues.lastName, history]);

  const requiresEmail = useCallback(() => {
    if (!formValues.email) {
      history.push(`/signup/${SignUpStep.Email}`);
    }
  }, [formValues.email, history]);

  const value = useMemo(
    () => ({
      error,
      formValues,
      submitting,
      goBackPassword,
      handleSubmitName,
      handleClickNoHumor,
      handleSubmitSillyName,
      handleSubmitEmail,
      handleSubmitPassword,
      requiresName,
      requiresEmail,
    }),
    [
      error,
      formValues,
      submitting,
      goBackPassword,
      handleSubmitName,
      handleClickNoHumor,
      handleSubmitSillyName,
      handleSubmitEmail,
      handleSubmitPassword,
      requiresName,
      requiresEmail,
    ]
  );
  return value;
}

export const SignUpProvider = ({ children }: { children: ReactNode }) => {
  const value = useSignUpProvider();
  return (
    <SignUpContext.Provider value={value}>{children}</SignUpContext.Provider>
  );
};
