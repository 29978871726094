import React from "react";
import ReactDOM from "react-dom";
import { enableMapSet, setAutoFreeze } from "immer";

import App from "./App";
import "./i18n";

import "styles/index.scss";

enableMapSet();
setAutoFreeze(false);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
