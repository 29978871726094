import React from "react";
import { CSSTransition } from "react-transition-group";

import { Card, CardTitle } from "framework/components/Card";

import { PortalBody } from "framework/components/Portal";
import CardSidePanelToolbar from "components/editor/toolbar/CardSidePanelToolbar";
import { getEditorPathname } from "helpers";

import styles from "./index.module.scss";

const MobileGraphEditorComponent = ({ selectedItem }: any) => {
  const { id, documentType, title } = selectedItem;

  return (
    <Card
      className={styles.card}
      title={<CardTitle title={title} />}
      toolbar={
        <CardSidePanelToolbar
          href={getEditorPathname(documentType, id, null)}
        />
      }
    />
  );
};

const MobileGraphEditor = ({ selectedItem }: any) => {
  return (
    <PortalBody>
      <CSSTransition
        in={selectedItem != null}
        timeout={400}
        classNames={{
          enterActive: styles.visible,
          enterDone: styles.visible,
        }}
        unmountOnExit
      >
        <div className={styles.container} data-card-type="graph-card">
          {selectedItem != null && (
            <MobileGraphEditorComponent selectedItem={selectedItem} />
          )}
        </div>
      </CSSTransition>
    </PortalBody>
  );
};

export default MobileGraphEditor;
