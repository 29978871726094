import { useEffect, useMemo } from "react";

const useMemoizedObject = <T extends object>(props: T): T => {
  const memoized = useMemo(() => props, []);

  useEffect(() => {
    Object.assign(memoized, props);
  }, [...Object.values(props)]);

  return memoized;
};

export default useMemoizedObject;
