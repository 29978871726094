import { useMemo } from "react";

import { SortingState } from "./useSorting";
import { Timestamp } from "firebase/firestore";

export type TableComparator<T extends object> = (x: T, y: T) => number;

const useComparator = <T extends object>(
  sorting: SortingState<T>
): TableComparator<T> =>
  useMemo(() => {
    const { field, order } = sorting;

    const sign = order === "desc" ? -1 : 1;

    return (x: T, y: T) => {
      const xValue = x[field];
      const yValue = y[field];

      let result = 0;
      if (typeof xValue === "string" && typeof yValue === "string") {
        result = xValue.toLowerCase().localeCompare(yValue.toLowerCase());
      } else if (xValue instanceof Timestamp && yValue instanceof Timestamp) {
        result = xValue.toDate().getTime() - yValue.toDate().getTime();
      }

      return sign * result;
    };
  }, [sorting]);

export default useComparator;
