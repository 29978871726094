import React from "react";

import ParagraphComponent from "./components/ParagraphComponent";
import { isParagraphElement } from "components/slate/plugins/paragraph/utils";
import { UseSlatePlugin } from "components/slate/types";

const useParagraphPlugin: UseSlatePlugin = () => {
  return {
    renderElement: (props) => {
      if (isParagraphElement(props.element)) {
        return <ParagraphComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useParagraphPlugin;
