import isHotkey from "is-hotkey";

import DueDateCalendarActions from "../DueDateCalendarAction/DueDateCalendarActions";
import DueDateCalendar from "../DueDateCalendar/DueDateCalendar";
import useAppPopper from "framework/components/poppers/useAppPopper";
import AppPopper from "framework/components/poppers/AppPopper";
import { useDueDateSelectCalendarMenuContext } from "providers/DueDateSelectCalendarMenuProvider";
import useOnClickOutside from "hooks/useOnClickOutside";
import useDebouncedValue from "hooks/useDebouncedValue";
import useWindowEventListener from "hooks/useWindowEventListener";

import styles from "./dueDateSelectCalendarMenu.module.scss";

const DueDateSelectCalendarMenu = () => {
  const props = useDueDateSelectCalendarMenuContext();

  const popperProps = useAppPopper({
    targetElement: props.targetElement,
    offset: [0, 10],
    hasArrow: true,
  });

  useOnClickOutside(
    [{ current: props.targetElement }, { current: popperProps.contentElement }],
    (e: any) => {
      props.closeCalendar();
    }
  );

  const isVisible = props.isVisible || props.targetElement;
  const isVisibleDebounced = useDebouncedValue(isVisible, 300);
  const debouncedActions = useDebouncedValue(props.actions, 300);

  useWindowEventListener(
    "keydown",
    (e) => isHotkey("Escape", e) && props.closeCalendar()
  );

  return (
    <AppPopper
      {...popperProps}
      onlyLight={true}
      isOpened={props.isVisible && props.targetElement != null}
      hasArrow={true}
      className={styles.popperContainer}
      content={
        (isVisible || isVisibleDebounced) && (
          <>
            <DueDateCalendarActions
              actions={props.actions.length ? props.actions : debouncedActions}
            />
            <hr className={styles.separator} />
            <DueDateCalendar
              onDateChange={props.onDateChange}
              date={props.date}
            />
          </>
        )
      }
    />
  );
};

export default DueDateSelectCalendarMenu;
