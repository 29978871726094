import React from "react";

import julianSlash from "images/julian_sidepanel_empty.svg";

import styles from "components/editor/SidePanel/backlinks/empty/index.module.scss";

const NoLinksEmptyState = () => {
  return (
    <div className={styles.root}>
      <img src={julianSlash} alt="Julian" className={styles.image} />
      <h2 className={styles.title}>Type "@"</h2>
      <p className={styles.content}>Type "@" to create and link new notes.</p>
    </div>
  );
};

export default NoLinksEmptyState;
