import {
  collection,
  doc,
  limit,
  query,
  runTransaction,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { Element, Text } from "slate";

import { db } from "firebaseInstance";
import {
  deleteTransaction,
  getCollectionData,
  getDocumentData,
  getFirstDocumentData,
  updateTransaction,
} from "db/utils";
import { getReferencesBlocks } from "db/blocks/blocks.queries";
import { crawlContent, mapContentFromJSON, mapContentToJSON } from "helpers";
import { isBacklinkElement } from "components/slate/plugins/backlink/utils";
import {
  Block,
  BLOCK_CONVERTER,
  Document,
  DOCUMENT_CONVERTER,
  DocumentType,
  getBlockPath,
  getDocumentPath,
  getDocumentsPath,
} from "thunk-core";

export const getUserDocumentsQuery = (userId: string, type: DocumentType) =>
  query(
    collection(db, getDocumentsPath()),
    where("userId", "==", userId),
    where("type", "==", type)
  ).withConverter(DOCUMENT_CONVERTER);

export const getAllDocuments = async (userId: string) => {
  return await getCollectionData(
    query(
      collection(db, getDocumentsPath()),
      where("userId", "==", userId)
    ).withConverter(DOCUMENT_CONVERTER)
  );
};

export const getDocument = async (
  documentId: string
): Promise<Document | null> => {
  return await getDocumentData(
    doc(db, getDocumentPath(documentId)).withConverter(DOCUMENT_CONVERTER)
  );
};

export const getDocumentByDateId = async (
  userId: string,
  dateId: string
): Promise<Document | null> => {
  return await getFirstDocumentData(
    query(
      collection(db, getDocumentsPath()),
      where("userId", "==", userId),
      where("dateId", "==", dateId),
      limit(1)
    ).withConverter(DOCUMENT_CONVERTER)
  );
};

export const getFavoriteNotes = async (
  userId: string
): Promise<Document[] | null> => {
  return await getCollectionData(
    query(
      collection(db, getDocumentsPath()),
      where("userId", "==", userId),
      where("isFavorite", "==", true),
      where("type", "==", DocumentType.NOTE)
    ).withConverter(DOCUMENT_CONVERTER)
  );
};

export const getUserDocuments = async (userId: string, type: DocumentType) => {
  return getCollectionData(getUserDocumentsQuery(userId, type));
};

export const getTaggedDocument = async (userId: string, tagId: string) => {
  return getCollectionData(
    query(
      collection(db, getDocumentsPath()),
      where("userId", "==", userId),
      where("tags", "array-contains", tagId)
    ).withConverter(DOCUMENT_CONVERTER)
  );
};

export const getUserNotes = async (userId: string) => {
  return getCollectionData(
    query(
      collection(db, getDocumentsPath()),
      where("userId", "==", userId),
      where("type", "==", DocumentType.NOTE),
      where("isEmpty", "!=", true)
    ).withConverter(DOCUMENT_CONVERTER)
  );
};

export const getChildDocuments = async (
  userId: string,
  parentId: string
): Promise<Document[] | null> => {
  return await getCollectionData(
    query(
      collection(db, getDocumentsPath()),
      where("userId", "==", userId),
      where("parentId", "==", parentId)
    ).withConverter(DOCUMENT_CONVERTER)
  );
};

export const setDocument = async (document: Document) => {
  await setDoc(
    doc(db, getDocumentPath(document.id)).withConverter(DOCUMENT_CONVERTER),
    document
  );
};

export const updateDocument = async (
  documentId: string,
  updates: Partial<Document>
) => {
  await updateDoc(
    doc(db, getDocumentPath(documentId)).withConverter(DOCUMENT_CONVERTER),
    updates
  );
};

export const removeDocument = async (document: Document) => {
  const isPage = document.type === DocumentType.PAGE;

  const referencesBlocks = isPage
    ? await getReferencesBlocks(document.userId, document.id)
    : [];
  const childDocuments = isPage
    ? await getChildDocuments(document.userId, document.id)
    : [];

  return await runTransaction(db, async (trx) => {
    await deleteTransaction(trx, doc(db, getDocumentPath(document.id)));
    const blockDeletes = [];
    for (const blockId of document.blocks) {
      blockDeletes.push(deleteTransaction(trx, doc(db, getBlockPath(blockId))));
    }
    await Promise.all(blockDeletes);

    if (isPage) {
      const constentUpdates = [];
      for (const block of referencesBlocks) {
        const updates: Partial<Block> = {};
        const { targets, contentJSON } = block;

        updates.targets = targets.filter((x) => x !== document.id);
        const content = mapContentFromJSON(contentJSON);

        crawlContent(content, (node, context) => {
          if (
            Element.isElement(context.parent) &&
            isBacklinkElement(node) &&
            node.targetId === document.id
          ) {
            const newNode: Text = { text: document.title };
            context.parent.children[context.index] = newNode;
            context.replace(newNode);
          }
        });

        updates.contentJSON = mapContentToJSON(content);

        constentUpdates.push(
          updateTransaction(
            trx,
            doc(db, getBlockPath(block.id)),
            updates,
            BLOCK_CONVERTER
          )
        );
      }
      await Promise.all(constentUpdates);

      const childDocumentUpdates = [];
      for (const childDocument of childDocuments) {
        childDocumentUpdates.push(
          updateTransaction(
            trx,
            doc(db, getDocumentPath(childDocument.id)),
            { parentId: document.parentId },
            DOCUMENT_CONVERTER
          )
        );
      }
      await Promise.all(childDocumentUpdates);
    }
  });
};
