import React, { memo, useEffect, useMemo, useState } from "react";
import { Element } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { getClientRect } from "@dnd-kit/core";
import { Transform } from "@dnd-kit/utilities";
import debounce from "lodash.debounce";

import { useDndState } from "components/slate/slate-extended/dnd/useDndState";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import useWindowEventListener from "hooks/useWindowEventListener";
import { useSidePanelContext } from "providers/SidePanelProvider";
import { useSidebarContext } from "providers/SidebarProvider";
import { isCheckableListItem } from "components/slate/plugins/list/utils";
import useDebouncedValue from "hooks/useDebouncedValue";
import { useHideCompleted } from "components/slate/hooks/useHideCompleted";
import { useActualValueSubscription } from "components/slate/editors/SyncSlateValue";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";
import { useStoreValue } from "state/utils";
import { MainDocumentWidthState } from "state/layout";

import styles from "../index.module.scss";

type Props = {
  semanticChildrenCount: number;
  onFold?: React.MouseEventHandler;
  transform?: Transform | null;
};

const FoldingLine = (props: Props & { element: Element }) => {
  const editor = useSlate();
  useActualValueSubscription(); // rerender if value changed
  useHideCompleted(); // rerender if hide completed changed
  const mainDocWidth = useStoreValue(MainDocumentWidthState); // rerender if main doc width resized
  const isTerminalActive = useStoreValue(TerminalStore.isTerminalActive);
  const isTerminalActiveDebounced = useDebouncedValue(isTerminalActive, 250);

  const { activeId } = useDndState();
  const { element, semanticChildrenCount, onFold, transform } = props;
  const [height, setHeight] = useState(0);

  const { isSidebarOpened } = useSidebarContext();
  const { isSidePanelOpened } = useSidePanelContext();

  const hideCompleted = useHideCompleted();

  const hasFoldingLine =
    ExtendedEditor.isNestingElement(editor, element) &&
    ExtendedEditor.isFoldingElement(editor, element) &&
    !element.folded &&
    semanticChildrenCount > 0 &&
    !(isTerminalActiveDebounced || isTerminalActive);

  const [width, setWidth] = useState(0);
  const setWidthDebounced = useMemo(() => debounce(setWidth, 100), []);
  const basePadding = isCheckableListItem(element) ? 2 : 7;

  useWindowEventListener(
    "resize",
    () => {
      setWidthDebounced(window.innerWidth);
    },
    []
  );

  useEffect(() => {
    if (!hasFoldingLine) {
      height && setHeight(0);
      return;
    }

    try {
      const semanticDescendants = ExtendedEditor.semanticDescendants(
        editor,
        element
      ).filter((descendant) => {
        const semanticPath = ExtendedEditor.semanticPath(
          editor,
          descendant.element
        );

        if (descendant.hidden || descendant.outsideContext) {
          return false;
        }

        if (hideCompleted) {
          const completed = semanticPath.some(
            (node) =>
              ExtendedEditor.isProgressElement(editor, node.element) &&
              node.progress === 1
          );

          if (completed) {
            return false;
          }
        }

        return true;
      });

      const lastDescendant =
        semanticDescendants[semanticDescendants.length - 1]?.element;

      if (!lastDescendant) {
        return;
      }

      const elementDom = ReactEditor.toDOMNode(editor, element);
      const lastDescendantDom = ReactEditor.toDOMNode(editor, lastDescendant);

      const byNextSibling = lastDescendant.id === activeId;

      Promise.resolve().then(() => {
        const rect1 = getClientRect(elementDom.querySelector("div")!);
        const top = rect1.top + 26;

        let bottom;
        if (byNextSibling && lastDescendantDom.nextElementSibling) {
          const rect2 = getClientRect(
            lastDescendantDom.nextElementSibling.querySelector("div")!
          );
          bottom = rect2.top;
        } else {
          const rect2 = getClientRect(lastDescendantDom.querySelector("div")!);
          bottom = rect2.top + rect2.height;
        }

        const newHeight = Math.floor(bottom - top) - 18;
        setHeight(newHeight);
      });
    } catch (error) {
      console.error(error);
    }
  }, [
    hasFoldingLine,
    transform,
    editor.children,
    width,
    useDebouncedValue(isSidebarOpened, 400),
    useDebouncedValue(isSidePanelOpened, 400),
    isTerminalActiveDebounced,
    mainDocWidth,
  ]);

  if (hasFoldingLine && activeId == null) {
    return (
      <FoldingLineMemoized
        semanticChildrenCount={semanticChildrenCount}
        basePadding={basePadding}
        height={height}
        onFold={onFold}
      />
    );
  }

  return null;
};

const FoldingLineMemoized = memo(
  ({
    basePadding,
    height,
    onFold,
  }: Props & { basePadding: number; height: number }) => {
    return (
      <div
        contentEditable={false}
        data-slate-clipboard-skip={true}
        className={styles.listLine}
        onClick={onFold}
        style={
          {
            "--height": `${height}px`,
            "--base-padding": `${basePadding}px`,
          } as React.CSSProperties
        }
      />
    );
  }
);

export default FoldingLine;
