import React, { useEffect } from "react";

import useKeyboardNavigation from "components/slate/plugins/menus/componentMenu/hooks/useKeyboardNavigation";

import styles from "components/slate/plugins/menus/componentMenu/components/MenuList/index.module.scss";

export type MenuListProps<T> = {
  containerRef?: any;
  isOpened: boolean;
  items: any[];
  renderListItem: ({
    item,
    isActive,
  }: {
    item: T;
    isActive: boolean;
  }) => React.ReactNode;
  onSelect: (e: KeyboardEvent, item: T) => void;
  onCancel: (e: KeyboardEvent) => void;
};

const MenuList = <T,>(props: MenuListProps<T>) => {
  const {
    containerRef,
    isOpened,
    items,
    renderListItem,
    onSelect,
    onCancel,
  } = props;

  const { index, setIndex } = useKeyboardNavigation({
    listening: isOpened,
    items,
    onSelect,
    onCancel,
  });

  useEffect(() => {
    if (isOpened) {
      setIndex(0);
    }
  }, [isOpened, items]);

  return (
    <div ref={containerRef} className={styles.container}>
      {items.length > 0 ? (
        items.map((item, itemIndex) =>
          renderListItem({ item, isActive: index === itemIndex })
        )
      ) : (
        <div className={styles.placeholder}>No results.</div>
      )}
    </div>
  );
};

export default MenuList;
