import { useCallback } from "react";

import useMemoObject from "hooks/useMemoObject";
import createState from "components/slate/plugins/menus/componentMenu/state";
import useZustandCreateStore from "hooks/useZustandCreateStore";
import { UseSlatePlugin } from "components/slate/types";
import { ComponentMenuProps } from "./types";
import { TerminalStore } from "components/slate/plugins/terminal/TerminalStore";

export const useComponentsMenuPlugin: UseSlatePlugin<
  {},
  ComponentMenuProps
> = () => {
  const { state, getState } = useZustandCreateStore(createState, []);
  const terminalActions = TerminalStore.useTerminalActions();

  const openComponentsMenu = useCallback((ref: HTMLElement | null) => {
    const { openMenu } = getState();

    terminalActions.setIsMenuOpened(true);
    openMenu(ref);
  }, []);

  const closeComponentsMenu = useCallback(() => {
    const { closeMenu } = getState();

    terminalActions.setIsMenuOpened(false);
    closeMenu();
  }, []);

  return {
    state: useMemoObject<ComponentMenuProps>({
      currentElement: state.currentElement,
      isOpened: state.currentElement != null,
      openComponentsMenu,
      closeComponentsMenu,
      search: state.search,
      setSearch: state.setSearch,
    }),
  };
};
