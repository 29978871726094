import { useEffect } from "react";
import isHotkey from "is-hotkey";
import cn from "classnames";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import GraphArea from "./GraphArea";
import GraphEditor from "./GraphEditor";
import GraphToolbar from "./GraphToolbar";
import useWindowEventListener from "hooks/useWindowEventListener";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import useGraphPage from "pages/app/GraphPage/useGraphPage";
import MobileGraphEditor from "pages/app/GraphPage/MobileGraphEditor";
import AppResult, { CreateItemButton } from "components/results/AppResult";
import { TodayIcon } from "thunk-icons";
import Helmet from "components/Helmet";
import CardSpinner from "framework/components/Card/CardSpinner";

import styles from "./index.module.scss";

const GraphPage = observer(() => {
  const graphPage = useGraphPage();

  //allow body scrolling on this page
  useEffect(() => {
    document.body.setAttribute("no-side-panel-page", "true");
  });

  if (graphPage.loading) {
    return <CardSpinner loading={graphPage.loading} loadingTimeout={300} />;
  }

  if (graphPage.error) {
    return <AppResult resultType="fetching" isCenteredContent={true} />;
  }

  if (graphPage.isEmptyPage) {
    return (
      <AppResult
        isCenteredContent={true}
        title="You don't have any notes yet. Let's make some!"
        actions={
          <>
            <CreateItemButton icon={TodayIcon} as={Link} to="/writing">
              Write Daily note
            </CreateItemButton>
            <CreateItemButton as={Link} to="/page/new">
              Create new note
            </CreateItemButton>
          </>
        }
      />
    );
  }

  return <GraphPageComponent {...graphPage} />;
});

export default GraphPage;

const GraphPageComponent = (props: any) => {
  const {
    graphRef,
    graphData,
    search,
    setSearch,
    filters,
    applyFilters,
    selectedId,
    setSelectedId,
    selectedItem,
    activeTab,
    setActiveTab,
    handleBacklinksChange,
  } = props;

  const { isMobileScreen } = useDeviceDetector();

  useWindowEventListener("keydown", (e: any) => {
    if (isHotkey("Escape", e)) {
      setSelectedId(null);
    }
  });

  return (
    <div className={styles.container}>
      <Helmet pageTitle="Explore" />
      <h3
        className={cn(styles.noItemsMessage, {
          [styles.visible]: graphData.nodes.length === 0,
        })}
      >
        No items found
      </h3>

      <GraphToolbar
        search={search}
        setSearch={setSearch}
        filters={filters}
        applyFilters={applyFilters}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {isMobileScreen && <MobileGraphEditor selectedItem={selectedItem} />}
      {!isMobileScreen && <div className={styles.borderFade} />}
      {!isMobileScreen && (
        <GraphEditor
          selectedItem={selectedItem}
          setSelectedId={setSelectedId}
          onBacklinksChange={handleBacklinksChange}
        />
      )}
      <GraphArea
        graphRef={graphRef}
        data={graphData}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
      />
    </div>
  );
};
