import { action, makeObservable, observable } from "mobx";

import DocumentsStore from "stores/documentsStore";
import { DocumentType } from "thunk-core";

export default class PagesStore extends DocumentsStore {
  activeDocumentId: string | null = null;

  constructor() {
    super(DocumentType.PAGE);

    makeObservable(this, {
      activeDocumentId: observable,
      setActiveDocumentId: action.bound,
    });
  }

  setActiveDocumentId(documentId: string) {
    this.activeDocumentId = documentId;
  }
}
