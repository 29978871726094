import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";

import { useAuth } from "auth/AuthProvider";
import { env } from "thunk-env";

type CustomFetch = (
  input: string,
  init?: RequestInit & { method: "get" | "post" | "patch" | "put" | "delete" }
) => Promise<{ data: any }>;

type FetchRequestContextValue = {
  functionsFetch: CustomFetch;
};

const FetchRequestContext = createContext<FetchRequestContextValue>(null);

const FetchTimout = 12000;
const FunctionBaseEndpoint = env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT;

// access to firebase functions
export const useFunctionsFetch = () =>
  useContext(FetchRequestContext).functionsFetch;

export const AppFetchProvider = ({ children }: PropsWithChildren<{}>) => {
  const { token } = useAuth();

  const functionsFetch = useMemo(() => {
    const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};
    return makeFetch(FunctionBaseEndpoint, FetchTimout, authHeaders);
  }, [token]);

  return (
    <FetchRequestContext.Provider value={{ functionsFetch }}>
      {children}
    </FetchRequestContext.Provider>
  );
};

// make fetch with defaults and custom params (headers, timeout support)
const makeFetch =
  (baseEndpoint: string, timoutMs: number, headers: HeadersInit): CustomFetch =>
  async (input, init) => {
    const fetchController = new AbortController();
    const timeoutId = setTimeout(() => fetchController.abort(), timoutMs);

    const options = {
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      ...init,
      signal: fetchController.signal,
    };

    let response: Response;
    try {
      response = await fetch(baseEndpoint + input, options);
    } catch (error) {
      console.error(error);

      if (error.message === "Failed to fetch") {
        throw new FetchError(error, "thunk/connection-error");
      }

      if (error.name === "AbortError") {
        throw new FetchError(error, "thunk/abort-error");
      }

      throw error;
    }

    if (!response.ok) {
      let code: string;
      try {
        // attempt to get error code from body
        const data = await response.json();
        code = data.code;
      } catch (error) {
        code = String(response.status);
        console.error(error);
      }

      throw new FetchError(response, code);
    }

    let json: any;
    try {
      // attempt to get json body
      json = await response.json();
    } catch (error) {}

    clearTimeout(timeoutId);

    return { data: json };
  };

export class FetchError extends Error {
  response?: Response;
  code?: string;

  constructor(errorOrResponse: Error | Response, code?: string) {
    if (isError(errorOrResponse)) {
      super(errorOrResponse.message);
      this.name = errorOrResponse.name;
      this.stack = errorOrResponse.stack;
    } else {
      super(String(errorOrResponse.status));
      this.response = errorOrResponse;
    }

    this.code = code;
  }
}

const isError = (error: any): error is Error => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof error.message === "string"
  );
};
