const imageUploadErrors = {
  "storage/unauthorized": "images_uploading.errors.unauthorized",
  "storage/retry-limit-exceeded": "images_uploading.errors.connection",
};

const fileUplaodErrors = {
  "storage/unauthorized":
    "Either you do not have access, or the file is too large. The maximum allowed size is 20MB.",
  "storage/retry-limit-exceeded":
    "Shoot, is your internet connected? We are having trouble uploading that file.",
};

export const getErrorInfo = (error: Record<string, any> = {}) => {
  const message = imageUploadErrors[error.code];

  if (message != null) {
    return {
      message,
      code: error.code,
    };
  }

  return {
    message: "images_uploading.errors.unknown",
    code: error.code,
  };
};

export const getErrorInfoForFiles = (error: Record<string, any> = {}) => {
  const message = fileUplaodErrors[error.code];

  if (message != null) {
    return {
      message,
      code: error.code,
    };
  }

  return {
    message:
      "We are trying to upload that file, but something is going wrong. Give it another try! Email us at support@thunknotes.com if this keeps happening.",
    code: error.code,
  };
};
