import React from "react";

import "./index.scss";

type LinearProgressProps = {
  style?: React.CSSProperties;
};

const LinearProgress = ({ style }: LinearProgressProps) => {
  return <progress style={style} className="pure-material-progress-linear" />;
};

export default LinearProgress;
