import React, { memo, useCallback } from "react";
import {
  RenderElementProps,
  useSelected,
  useSlate,
  useSlateStatic,
} from "slate-react";
import cn from "classnames";

import { ElementProps } from "components/slate/types";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { indentationWidth } from "components/slate/wrapper/constants";
import { getComponentWrapperAttributes } from "components/slate/wrapper/utils";
import { isCodeBlockElement } from "components/slate/plugins/codeBlock/utils";
import { CodeBlockType } from "components/slate/plugins/codeBlock/types";
import { FoldingArrow } from "components/slate/wrapper/FoldingArrow";
import FoldingLine from "components/slate/wrapper/FoldingLine";
import { foldElement } from "components/slate/slate-extended/transforms/foldElement";
import { useHideCompleted } from "components/slate/hooks/useHideCompleted";
import { useActualValueSubscription } from "components/slate/editors/SyncSlateValue";

import styles from "components/slate/wrapper/index.module.scss";

const ReferenceWrapper = (
  props: RenderElementProps & {
    contextDepth: number;
    renderElement: (props: ElementProps) => JSX.Element;
  }
) => {
  const { element } = props;

  const editor = useSlate();
  useActualValueSubscription();
  const hideCompleted = useHideCompleted();
  const semanticPath = ExtendedEditor.semanticPath(editor, element);
  const semanticNode = ExtendedEditor.semanticNode(editor, element);

  const outsideContext = semanticNode.outsideContext;

  if (outsideContext) {
    return null;
  }

  const completed = semanticPath.some(
    (node) =>
      ExtendedEditor.isProgressElement(editor, node.element) &&
      node.progress === 1
  );

  if (hideCompleted && completed) {
    return null;
  }

  const hidden = semanticNode.hidden;

  const semanticChildrenCount = semanticNode.children.length;

  return (
    <MemoizedReferenceWrapper
      {...props}
      hidden={hidden}
      semanticChildrenCount={semanticChildrenCount}
    />
  );
};

export default ReferenceWrapper;

const MemoizedReferenceWrapper = memo(
  (
    props: RenderElementProps & {
      contextDepth: number;
      hidden: boolean;
      semanticChildrenCount: number;
      renderElement: (props: ElementProps) => JSX.Element;
    }
  ) => {
    const editor = useSlateStatic();
    const {
      contextDepth,
      attributes,
      element,
      children,
      renderElement,
      hidden,
      semanticChildrenCount,
    } = props;

    const selected = useSelected();

    const semanticNode = ExtendedEditor.semanticNode(editor, element);

    const realSpacing = ExtendedEditor.isNestingElement(editor, element)
      ? indentationWidth * (element.depth - contextDepth)
      : 0;

    const doRenderFoldingArrow = ExtendedEditor.isFoldingElement(
      editor,
      element
    );
    const doRenderFoldingLine =
      ExtendedEditor.isFoldingElement(editor, element) &&
      ExtendedEditor.isNestingElement(editor, element);

    const spellCheck =
      selected && element.type !== CodeBlockType ? "true" : "false";

    const handleFold = useCallback(() => {
      foldElement(editor, element);
    }, [editor, element]);

    return (
      <div
        {...attributes}
        {...getComponentWrapperAttributes(element, semanticNode)}
        spellCheck={spellCheck}
        data-slate-node-type={element.type}
        data-slate-clipboard-skip-linebreak={true}
        style={
          {
            "--spacing": `${realSpacing}px`,
          } as React.CSSProperties
        }
        className={cn(styles.elementContainer, {
          [styles.hidden]: hidden,
          [styles.nestingElement]: ExtendedEditor.isNestingElement(
            editor,
            element
          ),
          [styles.folded]:
            ExtendedEditor.isFoldingElement(editor, element) && element.folded,
        })}
      >
        <div
          data-slate-clipboard-skip-linebreak={true}
          className={cn(styles.item, {
            [styles.noPadding]: isCodeBlockElement(element),
          })}
        >
          {doRenderFoldingArrow && (
            <FoldingArrow element={element} onFold={handleFold} />
          )}
          {doRenderFoldingLine && (
            <FoldingLine
              element={element}
              semanticChildrenCount={semanticChildrenCount}
              onFold={handleFold}
            />
          )}
          {renderElement({
            element,
            children,
          })}
        </div>
      </div>
    );
  },
  (prev, next) => {
    return (
      prev.element === next.element &&
      prev.renderElement === next.renderElement &&
      prev.hidden === next.hidden &&
      prev.contextDepth === next.contextDepth &&
      prev.semanticChildrenCount === next.semanticChildrenCount
    );
  }
);
