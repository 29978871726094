import { withHistory } from "slate-history";
import { withReact } from "slate-react";

import useParagraphPlugin from "components/slate/plugins/paragraph/useParagraphPlugin";
import useHeadingPlugin from "components/slate/plugins/heading/useHeadingPlugin";
import useMarksPlugin from "components/slate/plugins/marks/useMarksPlugin";
import useBacklinkPlugin from "components/slate/plugins/backlink/useBacklinkPlugin";
import { useFormattingMenuPlugin } from "components/slate/plugins/menus/formatting/FormattingMenu";
import useAtMenuPlugin from "components/slate/plugins/menus/atMenu/useAtMenuPlugin";
import useHorizontalRulePlugin from "components/slate/plugins/horizontalRule/useHorizontalRulePlugin";
import useBlockquotePlugin from "components/slate/plugins/blockquote/useBlockquotePlugin";
import useExtendedPlugin from "components/slate/slate-extended/useExtendedPlugin";
import { compareLevels } from "components/slate/hooks/utils";
import useNodeIdPlugin from "components/slate/plugins/nodeId/useNodeIdPlugin";
import useListPlugin from "components/slate/plugins/list/useListPlugin";
import useAutoformatPlugin from "components/slate/plugins/autoformat/useAutoformatPlugin";
import { optionsAutoformat } from "components/slate/plugins/autoformat/autoformatRules";
import useSoftBreakPlugin from "components/slate/plugins/softBreak/useSoftBreakPlugin";
import useResetTypePlugin from "components/slate/plugins/resetType/useResetTypePlugin";
import useExitBreakPlugin from "components/slate/plugins/exitBreak/useExitBreakPlugin";
import useLinkPlugin from "components/slate/plugins/link/useLinkPlugin";
import usePasteLinkPlugin from "components/slate/plugins/pasteLink/usePasteLinkPlugin";
import useCodeBlockPlugin from "components/slate/plugins/codeBlock/useCodeBlockPlugin";
import useFilePlugin from "components/slate/plugins/file/useFilePlugin";
import useSerializePlugin from "components/slate/plugins/serialization/useSerializePlugin";
import useDeserializePlugin from "components/slate/plugins/serialization/useDeserializePlugin";
import useShortcutsPlugin from "components/slate/plugins/shortcuts/useShortcutsPlugin";
import useBacklinkMenuPlugin from "components/slate/plugins/menus/backlinkMenu/useBacklinkMenuPlugin";
import useEmbedPlugin from "components/slate/plugins/embed/useEmbedPlugin";
import { useEmojiPlugin } from "components/slate/plugins/emoji/useEmojiPlugin";
import useVoidsDeletionPlugin from "components/slate/plugins/voidsDeletion/useVoidsDeletionPlugin";
import useMediaEmbedPlugin from "components/slate/plugins/mediaEmbed/useMediaEmbedPlugin";
import useHashMenuPlugin from "components/slate/plugins/menus/hashMenu/useHashMenuPlugin";
import useTagPlugin from "components/slate/plugins/tag/useTagPlugin";
import useBlockPlugin from "components/slate/plugins/block/useBlockPlugin";
import useArrowNavigationPlugin from "components/slate/plugins/arrowNavigation/useArrowNavigationPlugin";

const useBlockPlugins = ({
  isMainEditor,
  userId,
  slateId,
}: {
  isMainEditor: boolean;
  userId?: string | null;
  slateId: string;
}) => {
  const atMenuPlugin = useAtMenuPlugin({});
  const hashMenuPlugin = useHashMenuPlugin({});
  const backlinkMenuPlugin = useBacklinkMenuPlugin({});
  const formattingMenuPlugin = useFormattingMenuPlugin({});
  const emojiPlugin = useEmojiPlugin({});

  const plugins = [
    // with state
    atMenuPlugin,
    hashMenuPlugin,
    backlinkMenuPlugin,
    formattingMenuPlugin,
    emojiPlugin,

    // editing
    useExitBreakPlugin({}),
    useSoftBreakPlugin({}),
    useResetTypePlugin({}),
    usePasteLinkPlugin({}),
    useVoidsDeletionPlugin({}),

    // block plugins
    useBlockPlugin({}),

    // elements
    useEmbedPlugin({}),
    useHorizontalRulePlugin({}),
    useBlockquotePlugin({}),
    useLinkPlugin({}),
    useCodeBlockPlugin({}),
    useFilePlugin({ userId, isReferenceEditor: true }),
    useBacklinkPlugin({ isMainEditor }),
    useTagPlugin({}),
    useMarksPlugin({}),
    useListPlugin({}),
    useHeadingPlugin({}),
    useParagraphPlugin({}),
    useShortcutsPlugin({}),
    useMediaEmbedPlugin({}),

    // core
    useAutoformatPlugin({ rules: optionsAutoformat }),
    useSerializePlugin({}),
    useDeserializePlugin({}),
    useArrowNavigationPlugin({ slateId }),
    useExtendedPlugin({
      compareLevels,
    }),
    useNodeIdPlugin({}),
    {
      withOverrides: withHistory,
    },
    {
      withOverrides: withReact,
    },
  ];

  return {
    plugins,
    atMenuPlugin,
    hashMenuPlugin,
    backlinkMenuPlugin,
    formattingMenuPlugin,
    emojiPlugin,
  };
};

export default useBlockPlugins;
