import React from "react";
import { Route } from "react-router-dom";

import { RouteErrorBoundary } from "components/errors/RouteErrorBoundary";

const SimpleRoute = ({ layout: Layout, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        return (
          <Layout>
            <RouteErrorBoundary key={matchProps.match.path}>
              <Component {...matchProps} />
            </RouteErrorBoundary>
          </Layout>
        );
      }}
    />
  );
};

export default SimpleRoute;
