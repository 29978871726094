import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Switch, useHistory, useLocation } from "react-router-dom";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import styles from "layout/AppLayout/AppHelp/index.module.scss";
import stylesVariables from "layout/AppLayout/AppHelp/variables.module.scss";

const HelpTransitionSwitch = ({ helpPageConfig, children }: any) => {
  const history = useHistory();
  const location = useLocation();
  const [isPopAction, setIsPopAction] = useState(false);
  useEffect(() => {
    setIsPopAction(history.action === "POP");
  }, [location]);

  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    helpPageConfig != null && setTimeout(() => setShouldAnimate(true), 100);
  }, [helpPageConfig]);

  return (
    <div
      className={cn(styles.container, {
        backward: shouldAnimate && isPopAction,
        forward: shouldAnimate && !isPopAction,
      })}
    >
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="helpPageSlide"
          timeout={
            shouldAnimate
              ? Number.parseInt(stylesVariables.helpPageTransitionDelay) +
                Number.parseInt(stylesVariables.helpPageTransitionDuration)
              : 0
          }
          unmountOnExit
        >
          <div className={cn(styles.page)}>
            <Switch location={location}>{children}</Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default HelpTransitionSwitch;
