import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Switch, useHistory, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import styles from "./index.module.scss";
import stylesVariables from "./variables.module.scss";
import "./index.scss";

type TransitionSwitchProps = {
  children: React.ReactNode;
  height: number;
};

const TransitionSwitch = ({ children, height }: TransitionSwitchProps) => {
  const history = useHistory();
  const location = useLocation();
  const [isPopAction, setIsPopAction] = useState(false);
  useEffect(() => {
    setIsPopAction(history.action === "POP");
  }, [location]);

  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => setShouldAnimate(true), 100);
  }, []);

  return (
    <div
      style={{ height }}
      className={cn(styles.container, {
        backward: shouldAnimate && isPopAction,
        forward: shouldAnimate && !isPopAction,
      })}
    >
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="componentMenuTransition"
          timeout={
            shouldAnimate
              ? Number.parseInt(stylesVariables.componentMenuTransitionDelay) +
                Number.parseInt(stylesVariables.componentMenuTransitionDuration)
              : 0
          }
          unmountOnExit
        >
          <div className={styles.page}>
            <Switch location={location}>{children}</Switch>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default TransitionSwitch;
