import React, { useState } from "react";
import { Link, MemoryRouter, Redirect, Route } from "react-router-dom";

import { SidepanelCollapseIcon } from "thunk-icons";

import HelpFloatingButton from "layout/AppLayout/AppHelp/HelpFloatingButton";
import HelpDrawer from "layout/AppLayout/AppHelp/HelpDrawer";
import * as db from "layout/AppLayout/AppHelp/db";
import HelpErrorComponent from "layout/AppLayout/AppHelp/HelpErrorComponent";
import HelpSpinner from "layout/AppLayout/AppHelp/HelpSpinner";
import HelpContent from "components/HelpContent";
import HelpPageContainer from "layout/AppLayout/AppHelp/HelpPageContainer";
import HelpTransitionSwitch from "layout/AppLayout/AppHelp/HelpTransitionSwitch";
import useHelpDrawerEffects from "layout/AppLayout/AppHelp/useHelpDrawerEffects";
import HelpPageErrorBoundary from "layout/AppLayout/AppHelp/HelpPageErrorBoundary";
import Button from "framework/components/form/Button";
import AppResult from "components/results/AppResult";
import HelpFeedback from "layout/AppLayout/AppHelp/HelpFeedback";
import { useHelpSectionState } from "state/layout";

import styles from "./index.module.scss";
import "./index.scss";

const HelpConfigResolver = ({
  mainPageId,
  helpPageConfigLoading: loading,
  helpPageConfigError: error,
}: any) => {
  if (loading) {
    return <HelpSpinner />;
  }

  if (error) {
    console.error(error);
    return <Redirect to="/error" />;
  }

  return mainPageId ? (
    <Redirect to={`/page/${mainPageId}`} />
  ) : (
    <Redirect to="/default-help-page" />
  );
};

const AppHelpComponent = () => {
  const [isHelpOpened, setIsHelpOpened] = useHelpSectionState();
  useHelpDrawerEffects({ isHelpOpened, setIsHelpOpened });

  const [
    helpPageConfig,
    helpPageConfigLoading,
    helpPageConfigError,
  ] = db.useHelpPageConfig();

  const helpPagePath = helpPageConfig?.helpPagePath; // path in format "userId/pageId"
  const [mainUserId, mainPageId] = helpPagePath ? helpPagePath.split("/") : [];

  return (
    <>
      {/*<HelpFloatingButton*/}
      {/*  isHelpOpened={isHelpOpened}*/}
      {/*  onClick={() => setIsHelpOpened((state) => !state)}*/}
      {/*/>*/}
      <HelpDrawer isOpened={isHelpOpened}>
        <HelpTransitionSwitch helpPageConfig={helpPageConfig}>
          <Route path="/feedback">
            <HelpPageErrorBoundary>
              <HelpFeedback />
            </HelpPageErrorBoundary>
          </Route>
          <Route path="/error">
            <HelpErrorComponent />
          </Route>
          <Route path="/default-help-page">
            <div style={{ padding: "var(--card-padding-horizontal)" }}>
              <HelpContent />
            </div>
          </Route>
          <Route exact path="/">
            <HelpConfigResolver
              mainPageId={mainPageId}
              helpPageConfigLoading={helpPageConfigLoading}
              helpPageConfigError={helpPageConfigError}
            />
          </Route>
          <Route path={`/page/:pageId`}>
            {({ match }: any) => {
              return (
                <HelpPageErrorBoundary>
                  <Button
                    variant="circularElectronHeader"
                    size={"large"}
                    icon={SidepanelCollapseIcon}
                    iconSize={28}
                    btnClass={styles.helpButton}
                    onClick={() => setIsHelpOpened((state) => !state)}
                  >
                    Close Help
                  </Button>
                  <HelpPageContainer
                    mainUserId={mainUserId}
                    mainPageId={mainPageId}
                    match={match}
                  />
                </HelpPageErrorBoundary>
              );
            }}
          </Route>
          <Route>
            <AppResult
              resultType="notFound"
              isCenteredContent={true}
              title="We can't seem to find this page."
              actions={
                <Link to="/">
                  <Button variant="secondary">Go Back</Button>
                </Link>
              }
            />
          </Route>
        </HelpTransitionSwitch>
      </HelpDrawer>
    </>
  );
};

const AppHelp = () => {
  return (
    <MemoryRouter>
      <AppHelpComponent />
    </MemoryRouter>
  );
};

export default AppHelp;
