import React from "react";

import Switch from "framework/components/form/Switch";

//  @ts-ignore ts-migrate(2307) FIXME: Cannot find module './index.module.scss' or its co... Remove this comment to see the full error message
import styles from "./index.module.scss";

const GraphFilters = ({ filters, onChange }: any) => {
  return (
    <div>
      <label className={styles.control}>
        <Switch
          color="purple"
          checked={filters.orphans}
          onChange={() => onChange({ orphans: !filters.orphans })}
        />
        <div className={styles.label}>Orphans</div>
      </label>

      <label className={styles.control}>
        <Switch
          color="purple"
          checked={filters.dailyNotes}
          onChange={() => onChange({ dailyNotes: !filters.dailyNotes })}
        />
        <div className={styles.label}>Daily Notes</div>
      </label>
    </div>
  );
};
export default GraphFilters;
