import React from "react";
import { observer } from "mobx-react-lite";
import { DeleteNoteIcon } from "thunk-icons";

import Button from "framework/components/form/Button";
import { tagsToString } from "db/tags/tags.utils";
import useNotify from "hooks/useNotify";
import useDialog from "hooks/useDialog";
import DeleteConfirmModal from "components/modals/DeleteConfirmModal";
import { useStore } from "stores/store";
import { useCurrentUserId } from "db/currentUser";
import { TableSelection } from "framework/components/table/useSelection";
import styles from "../index.module.scss";

type Props = {
  selection: TableSelection;
};

const DeleteTagsButton = observer((props: Props) => {
  const { selection } = props;

  const userId = useCurrentUserId();
  const { tagsStore } = useStore();

  const removeDialog = useDialog<{}, boolean>();
  const notify = useNotify();

  const handleRemove = async () => {
    const selectedIds = [...selection.selectedIds];

    const titles = tagsToString(tagsStore.getTagsByIds(selectedIds));

    const isConfirmed = await removeDialog.open(
      `This will permanently delete your tag(s) for: ${titles}.`
    );

    if (isConfirmed) {
      const removingNotificationId = notify.loading(
        "Selected tags are being removed"
      );

      try {
        const { removed } = await tagsStore.removeTagsRemote(
          userId,
          selectedIds
        );

        if (removed.length === 0) {
          notify.error("Tags are not removed");
        } else {
          selection.setSelectedIds((state) => {
            const newState = new Set(state);
            removed.forEach((id) => newState.delete(id));
            return newState;
          });
          notify.success(`Selected tags was removed`);
        }
      } catch (error) {
        console.error(error);
        notify.error("Tags are not removed");
      } finally {
        notify.remove(removingNotificationId);
      }
    }
  };

  return (
    <>
      <Button
        mobileIcon={DeleteNoteIcon}
        variant="error"
        size="large"
        onClick={handleRemove}
      >
        Delete tags
      </Button>
      <DeleteConfirmModal
        {...removeDialog}
        title="Are you sure?"
        okText="Delete"
      >
        {({ modalData }) => modalData}
      </DeleteConfirmModal>
    </>
  );
});

export default DeleteTagsButton;
