import React, { useEffect } from "react";
import { BeatLoader } from "react-spinners";
import { useHistory } from "react-router-dom";

import { useAuth } from "auth/AuthProvider";
import { auth } from "firebaseInstance";

import styles from "./index.module.scss";

// this page is used only to allow sign out user by external site, placing link to this page there
const SignOutPage = () => {
  const history = useHistory();

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    auth.signOut();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      history.replace("/");
    }
  }, [isAuthenticated]);

  return (
    <div className={styles.container}>
      <BeatLoader color="var(--blue-color)" />
    </div>
  );
};

export default SignOutPage;
