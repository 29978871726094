import React from "react";
import cn from "classnames";
import { ChevronRightIcon } from "thunk-icons";
import { isMobile } from "react-device-detect";

import { MenuItem } from "components/slate/plugins/menus/componentMenu/types";

import styles from "./index.module.scss";

type MenuListItemProps = {
  item: MenuItem;
  isActive: boolean;
  onClick: (e: Event) => void;
};

const MenuListItem = ({ item, isActive, onClick }: MenuListItemProps) => {
  const Icon = item.icon;
  const KeyboardShortcut = item.keyboardShortcut;

  return (
    <button
      className={cn(styles.menuItem, {
        [styles.focused]: isActive,
        [styles.delete]: item.variant === "delete",
        [styles.divider]: item.divider,
      })}
      onMouseDown={(e) => {
        e.preventDefault();
        const event = (e as unknown) as Event; // rewrite this
        onClick(event);
      }}
    >
      <div className={styles.menuIcon}>
        {item.icon && <Icon selected={isActive} />}
      </div>
      <div className={styles.menuItemText} style={item.style}>
        {item.text}
      </div>
      {!isMobile && KeyboardShortcut && (
        <div className={styles.menuItemKeyboardShortcut} style={item.style}>
          <KeyboardShortcut selected={isActive} />
        </div>
      )}
      {"children" in item && (
        <div
          className={cn(styles.menuItemText, styles.rightArrow)}
          style={item.style}
        >
          <ChevronRightIcon />
        </div>
      )}
    </button>
  );
};

export default MenuListItem;
