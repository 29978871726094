import React, { ErrorInfo } from "react";
import * as Sentry from "@sentry/react";

import { env } from "thunk-env";

type FallbackRender = (errorData: {
  resetError: () => void;
  error: Error | null;
  eventId: string | null;
}) => React.ReactElement;

type ErrorBoundaryProps = {
  fallback: React.ReactElement | FallbackRender;
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
};

class DevelopmentErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error);
  }

  resetError = () => {
    this.setState({ hasError: false, error: null });
  };

  render() {
    const { children, fallback } = this.props;
    const { hasError, error } = this.state;

    if (hasError) {
      return typeof fallback === "function"
        ? fallback({ resetError: this.resetError, error, eventId: null })
        : fallback;
    }

    return typeof children === "function" ? children() : children;
  }
}

const ProductionErrorBoundary = ({
  children,
  fallback,
}: ErrorBoundaryProps) => {
  return (
    <Sentry.ErrorBoundary fallback={fallback}>{children}</Sentry.ErrorBoundary>
  );
};

export const ErrorBoundaryBase =
  env.NODE_ENV === "production"
    ? ProductionErrorBoundary
    : DevelopmentErrorBoundary;
