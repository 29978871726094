import React from "react";

import styles from "./index.module.scss";

type TemplatesGridProps<T> = {
  items: T[];
  renderGridItem: ({ item }: { item: T }) => React.ReactNode;
};

const TemplatesGrid = <T,>({
  items,
  renderGridItem,
}: TemplatesGridProps<T>) => {
  return (
    <div className={styles.container}>
      {items.length > 0 ? (
        items.map((item, itemIndex) => renderGridItem({ item }))
      ) : (
        <div className={styles.placeholder}>No results.</div>
      )}
    </div>
  );
};

export default TemplatesGrid;
