import { Editor, Transforms, BaseEditor, Range } from "slate";

import { moveItemBack } from "../transforms";
import { isEmptyNode } from "components/slate/utils";
import {
  isListItemElement,
  isCheckableListItem,
} from "components/slate/plugins/list/utils";
import { ParagraphType } from "components/slate/plugins/paragraph/types";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";

const makeInsertBreak = (editor: Editor): BaseEditor["insertBreak"] => {
  const { insertBreak } = editor;

  return () => {
    const [entry] = Editor.nodes(editor, {
      match: isListItemElement,
      mode: "lowest",
    });

    if (entry) {
      const [node, path] = entry;

      const nextEntry = Editor.next(editor, { at: path })!;
      const nextElement = nextEntry ? nextEntry[0] : null;
      const nextDepth = ExtendedEditor.isNestingElement(editor, nextElement)
        ? nextElement.depth
        : 0;

      if (isEmptyNode(node) && node.depth - nextDepth >= 0) {
        if (node.depth > 0) {
          moveItemBack(editor, entry[0], entry[1]);
          return;
        } else {
          // turn list item into paragraph if it is empty
          Transforms.setNodes(editor, { type: ParagraphType });
          // Transforms.unwrapNodes(editor, { match: isListItemElement });
          return;
        }
      }

      if (
        ExtendedEditor.isFoldingElement(editor, node) &&
        !!node.folded &&
        !!editor.selection &&
        Range.includes(editor.selection, Editor.end(editor, path))
      ) {
        // keep it for extended editor plugin, to handle folded elements
        insertBreak();
        return;
      }

      // handle operations in list item level instead of its children
      if (Range.isCollapsed(editor.selection)) {
        Transforms.splitNodes(editor, {
          match: isListItemElement,
          always: true,
        });

        const [entry] = Editor.nodes(editor, { match: isListItemElement });
        if (entry) {
          const [node] = entry;
          if (isCheckableListItem(node)) {
            Transforms.setNodes(
              editor,
              { checked: false, dueDate: null },
              { match: isCheckableListItem }
            );
          }
        }

        return;
      }
    }

    insertBreak();
  };
};

export default makeInsertBreak;
