import React, { useCallback, useMemo } from "react";
import { v4 as uuid } from "uuid";

import SlateBlockEditor from "components/slate/editors/SlateBlockEditor";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { Descendant, Element } from "slate";
import { useDocumentsStore } from "stores/store";
import { useAppReadOnly } from "auth/hooks/useAppReadOnly";
import { useDocumentUserContext } from "providers/DocumentUserProvider";
import { Block } from "thunk-core";

export type BlockEditorProps = {
  block: Block;
  content: Descendant[];
  readOnly?: boolean;
  onChange?: (value: Descendant[]) => void;
  onSync?: () => void;
  onBacklinksChange?: (referencesDiff: any) => void;
  onBacklinkClick?: (element: BacklinkElement) => void;
};

const BlockEditor = (props: BlockEditorProps) => {
  const {
    block,
    content,
    onBacklinkClick,
    onChange,
    onSync,
    onBacklinksChange,
  } = props;
  const documentsStore = useDocumentsStore(block.pageType);

  const appReadOnly = useAppReadOnly();

  const { isDocumentOwner, documentUserId } = useDocumentUserContext();

  const readOnly = props.readOnly || appReadOnly || !isDocumentOwner;

  const handleContentChange = useCallback(
    (value: Descendant[]) => {
      const element = { ...value[0] } as Element;

      documentsStore.updateBlockContent({
        documentId: block.pageId,
        blockId: block.id,
        element,
      });
      onChange && onChange(value);
    },
    [block.pageId, block.id, block.userId, onChange]
  );

  const slateId = useMemo(() => uuid(), [block.id]);

  return (
    <SlateBlockEditor
      slateId={slateId}
      documentId={block.pageId}
      documentType={block.pageType}
      userId={documentUserId}
      value={content}
      onChange={handleContentChange}
      onSync={onSync}
      readOnly={readOnly}
      onBacklinkClick={onBacklinkClick}
      onBacklinksChange={onBacklinksChange}
    />
  );
};

export default BlockEditor;
