import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import queryString from "query-string";

import { makeDateId, useDateFromUrl } from "helpers";
import { useDocumentsStore } from "stores/store";
import { DocumentType } from "thunk-core";

export const useDocumentMatch = (): {
  matched: boolean;
  documentId: string | null;
  documentType: DocumentType | null;
} => {
  const location = useLocation();
  const search = queryString.parse(location.search);
  const date = useDateFromUrl(search.date as string);

  const noteMatch = useRouteMatch("/writing");
  const pageMatch = useRouteMatch([
    "/page/:pageId",
    "/users/:userId/page/:pageId",
  ]);

  const matched = noteMatch != null || pageMatch != null;
  const documentType = noteMatch ? DocumentType.NOTE : DocumentType.PAGE;

  const { pageId } = useParams<{ pageId: string }>();

  const documentStore = useDocumentsStore(documentType);
  const documentId =
    documentType === DocumentType.NOTE
      ? documentStore.getDocumentByDateId(makeDateId(date))?.id
      : pageId;

  return {
    matched,
    documentId,
    documentType,
  };
};
