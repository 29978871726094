import { useEffect } from "react";

function useEventListener(
  element: any,
  eventName: any,
  fn: any,
  dependencies = []
) {
  useEffect(() => {
    element && element.addEventListener(eventName, fn);

    return () => element && element.removeEventListener(eventName, fn);
  }, [element, ...dependencies]);
}

export default useEventListener;
