import React from "react";
import cn from "classnames";

import styles from "./index.module.scss";

export type ResultProps = {
  style?: React.CSSProperties;
  image?: React.ReactNode;
  title?: string;
  text?: React.ReactNode;
  contacts?: React.ReactNode;
  actions?: React.ReactNode;
  isCenteredContent?: boolean;
};

const Result = ({
  style,
  image,
  title,
  text,
  contacts,
  actions,
  isCenteredContent,
}: ResultProps) => {
  return (
    <div
      style={style}
      className={cn(styles.container, { [styles.centered]: isCenteredContent })}
    >
      {image && <div className={styles.imageContainer}>{image}</div>}
      {title && <div className={styles.title}>{title}</div>}
      {text && <div className={styles.text}>{text}</div>}
      {contacts && <div className={styles.contacts}>{contacts}</div>}
      <div className={styles.actions}>{actions}</div>
    </div>
  );
};

export default Result;
