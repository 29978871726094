import { NavPanelIcon } from "thunk-icons";
import { useSidebarContext } from "providers/SidebarProvider";

import Button from "framework/components/form/Button";

import styles from "./index.module.scss";

const SidebarToggle = () => {
  const { toggleSidebar } = useSidebarContext();
  const iconSize = 28;

  return (
    <Button
      variant="circularElectronHeader"
      size="symbol"
      btnClass={styles.sidebarToggle}
      onClick={() => toggleSidebar((state) => !state)}
    >
      <NavPanelIcon size={iconSize} />
    </Button>
  );
};

export default SidebarToggle;
