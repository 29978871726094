import { Node } from "slate";

import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { useDocumentsStore } from "stores/store";
import { formatDifferenceInDays } from "utils/dateUtils";
import { dateIdToDate } from "helpers";
import { useSharingContext } from "providers/SharingProvider";
import { DocumentType } from "thunk-core";

export const useBacklinkTitle = (backlink: BacklinkElement) => {
  const sharing = useSharingContext();
  const documentType = backlink.targetType || DocumentType.PAGE;
  const documentsStore = useDocumentsStore(documentType);

  const title =
    documentType === DocumentType.NOTE
      ? formatDifferenceInDays(dateIdToDate(backlink.targetId))
      : documentsStore.getDocument(backlink.targetId)?.title;
  let text: string = title;

  if (sharing && backlink.targetType !== DocumentType.NOTE) {
    const page = sharing.pagesMap[backlink.targetId];

    if (page) {
      text = page.title;
    }
  }

  if (!text) {
    const str = Node.string(backlink);
    if (str && str.trim()) {
      text = str;
    } else {
      text = "[no title backlink]";
    }
  }

  if (backlink.targetBlockId) {
    text += ` → ${
      backlink.blockText || backlink.targetBlockId.substring(0, 6)
    }`;
  }

  return text;
};
