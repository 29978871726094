import React, { memo, useEffect, useState } from "react";
import { Element } from "slate";
import { useSlate } from "slate-react";
import { ArrowRightIcon } from "thunk-icons";

import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import {
  highlightChildrenElements,
  unhighlightChildrenElements,
} from "components/slate/wrapper/FoldingArrow/utils";
import { useActualValueSubscription } from "components/slate/editors/SyncSlateValue";

import styles from "../index.module.scss";

type Props = {
  onFold?: React.MouseEventHandler;
};

export const FoldingArrow = (props: Props & { element: Element }) => {
  const [, forceRerender] = useState(0);
  const editor = useSlate();
  useActualValueSubscription();
  const { element, onFold } = props;
  const timeOutId = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    forceRerender((state) => state + 1);
  }, [editor.children]);

  if (
    ExtendedEditor.isFoldingElement(editor, element) &&
    ExtendedEditor.semanticNode(editor, element).children.length > 0
  ) {
    return (
      <FoldingArrowMemoized
        folded={Boolean(element.folded)}
        onMouseDown={(e) => {
          e.preventDefault();
          onFold && onFold(e);

          timeOutId.current = setTimeout(() => {
            highlightChildrenElements(
              editor,
              element,
              !(
                ExtendedEditor.isFoldingElement(editor, element) &&
                element.folded
              )
            );
          }, 100);
        }}
        onMouseEnter={() => {
          highlightChildrenElements(
            editor,
            element,
            ExtendedEditor.isFoldingElement(editor, element) && element.folded
          );
        }}
        onMouseLeave={() => {
          clearTimeout(timeOutId.current);
          unhighlightChildrenElements();
        }}
      />
    );
  }

  return null;
};

const FoldingArrowMemoized = memo(
  (props: {
    folded: boolean;
    onMouseDown?: React.MouseEventHandler;
    onMouseEnter?: React.MouseEventHandler;
    onMouseLeave?: React.MouseEventHandler;
  }) => {
    const { onMouseDown, onMouseEnter, onMouseLeave } = props;

    return (
      <button
        contentEditable={false}
        data-slate-clipboard-skip={true}
        className={styles.foldingArrow}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ArrowRightIcon size={11} />
      </button>
    );
  }
);
