import React, { useEffect, useMemo, useState } from "react";
//  @ts-ignore ts-migrate(7016) FIXME: Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import debounce from "lodash.debounce";

import CollapseButton from "framework/components/CollapseButton";
import * as db from "./db";
import { useAuth } from "auth/AuthProvider";
import { useUserData } from "hooks/useUserData";
import { useSidebarContext } from "providers/SidebarProvider";
import SimpleTooltip from "components/SimpleTooltip";

//  @ts-ignore ts-migrate(2307) FIXME: Cannot find module './index.module.scss' or its co... Remove this comment to see the full error message
import styles from "./index.module.scss";
import { useCurrentUserId } from "db/currentUser";

const AppSidebarSection = ({
  sectionId,
  title,
  actions,
  children,
  icon,
  darkIcon,
}: any) => {
  const userId = useCurrentUserId();
  const [user, loading] = useUserData(userId);
  const [initialized, setInitialized] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  //  @ts-ignore ts-migrate(2339) FIXME: Property 'isSidebarOpened' does not exist on type ... Remove this comment to see the full error message
  const { isSidebarOpened, toggleSidebar } = useSidebarContext();

  const setSectionOpened = useMemo(
    () => debounce(db.setSectionOpened, 500),
    []
  );

  useEffect(() => {
    if (!loading && user) {
      if (user.sidebarSections && sectionId) {
        setIsOpened(Boolean(user.sidebarSections[sectionId]));
      }
      setInitialized(true);
    }
  }, [loading, sectionId]);

  useEffect(() => {
    if (initialized && sectionId) {
      setSectionOpened(userId, sectionId, isOpened);
    }
  }, [initialized, sectionId, isOpened]);

  return (
    <SimpleTooltip
      hideTooltip={isSidebarOpened}
      content={title}
      placement="right"
    >
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          {title && (
            <CollapseButton
              className={styles.button}
              isOpened={isOpened}
              arrowPosition="right"
              onClick={() => {
                if (isSidebarOpened) {
                  setIsOpened((state) => !state);
                } else {
                  setIsOpened(true);
                  toggleSidebar(true);
                }
              }}
              icon={icon}
              darkIcon={darkIcon}
            >
              {title}
            </CollapseButton>
          )}
          {actions && <div className={styles.actions}>{actions}</div>}
        </div>
        <div
          style={{ display: isOpened ? "block" : "none" }}
          className={styles.content}
        >
          {children}
        </div>
      </div>
    </SimpleTooltip>
  );
};

export const AppSidebarSectionEmpty = () => {
  return <div className={styles.emptyText}>No items</div>;
};

export default AppSidebarSection;
