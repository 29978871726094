import { Element } from "slate";

import {
  HorizontalRuleElement,
  HorizontalRuleType,
} from "components/slate/plugins/horizontalRule/types";

export const isHorizontalRuleType = (
  value: any
): value is HorizontalRuleElement => {
  return Element.isElementType<HorizontalRuleElement>(
    value,
    HorizontalRuleType
  );
};
