import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { equals } from "ramda";

import { toArray } from "utils/toArray";

const useUrlQueryState = (
  key: string
): [string[], (newState: string[]) => void] => {
  const location = useLocation();
  const history = useHistory();

  const getParsedState = () => {
    const parsed = queryString.parse(location.search)[key];
    const state = toArray(parsed || []);
    return state;
  };

  const ref = useRef<string[]>();
  const [_state, _setState] = useState<string[]>(() => getParsedState());

  useEffect(() => {
    const state = getParsedState();

    if (!equals(state, ref.current)) {
      ref.current = state;
      _setState(state);
    }
  }, [location.search]);

  const setState = (newState: string[]) => {
    const str = queryString.stringify({ [key]: newState });
    history.replace({ search: `?${str}` });
  };

  return [_state, setState];
};

export default useUrlQueryState;
