import { ExtendedOptions, withExtended } from "./withExtended";
import { UseSlatePlugin } from "components/slate/types";

const useExtendedPlugin: UseSlatePlugin<ExtendedOptions> = (options) => {
  return {
    withOverrides: withExtended(options),
  };
};

export default useExtendedPlugin;
