import React from "react";
import { isMobile } from "react-device-detect";
import { observer } from "mobx-react-lite";

import {
  TableFilterContainer,
  TableFilterSection,
} from "framework/components/table/TableFilter";
import TextInput from "framework/components/form/TextInput";

type Props = {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

const TagsFilter = observer((props: Props) => {
  const { search, setSearch } = props;

  return (
    <TableFilterContainer>
      <TableFilterSection>
        <TextInput
          name="search"
          autoFocus={!isMobile}
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </TableFilterSection>
    </TableFilterContainer>
  );
});

export default TagsFilter;
