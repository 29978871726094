import { forwardRef, useRef } from "react";
import { use100vh } from "react-div-100vh";
import cn from "classnames";
import { Resizable } from "re-resizable";

import { useStoreState } from "state/utils";
import { MainDocumentWidthState } from "state/layout";

import styles from "./index.module.scss";
import { useSidePanelContext } from "providers/SidePanelProvider";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import useDebouncedSwitch from "hooks/useDebouncedSwitch";

const CardPageLayout = forwardRef<HTMLDivElement, React.PropsWithChildren<{}>>(
  ({ children }, ref) => {
    const height = use100vh();

    const { isMobileScreen } = useDeviceDetector();
    const { isSidePanelOpened } = useSidePanelContext();
    const isSidePanelOpenedDebounced = useDebouncedSwitch(
      isSidePanelOpened,
      300,
      isSidePanelOpened
    );
    const [width, setWidth] = useStoreState(MainDocumentWidthState);

    const content = (
      <div
        ref={ref}
        className={cn(styles.container)}
        // style={{
        //   height: height - 71,
        // }}
      >
        {children}
        {/* <div className={styles.footer}><FeedbackPollWidget /></div> */}
      </div>
    );

    if (isMobileScreen) {
      return <>{content}</>;
    }

    return (
      <Resizable
        style={{
          flexBasis: `${width}%`,
        }}
        defaultSize={{
          width: `${width}%`,
          height: "auto",
        }}
        onResizeStop={(e, direction, ref, d) => {
          setWidth(parseInt(ref.style.width));
        }}
        enable={{
          right: isSidePanelOpened,
        }}
        minWidth={"30%"}
        maxWidth={isSidePanelOpenedDebounced ? "70%" : undefined}
      >
        {content}
      </Resizable>
    );
  }
);

export default CardPageLayout;
