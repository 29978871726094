import { useCallback, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useCurrentUserId } from "db/currentUser";
import {
  getDocumentSettings,
  upsertDocumentSettings,
} from "db/documentsSettings/documentsSettings.queries";
import { DocumentSettings } from "db/documentsSettings/documentsSettings.types";
import { makeDocumentSettings } from "db/documentsSettings/documentsSettings.mapping";

export const useDocumentSettings = (documentId: string) => {
  return useQuery(["documents-settings", documentId], () =>
    getDocumentSettings(documentId)
  );
};

export const useUpsertDocumentSettings = (documentId: string) => {
  const queryClient = useQueryClient();
  const userId = useCurrentUserId();

  return useMutation(
    ({ updates }: { updates: Partial<DocumentSettings> }) =>
      upsertDocumentSettings({
        userId,
        documentId,
        updates,
      }),
    {
      onMutate: ({ updates }) => {
        queryClient.setQueryData(
          ["documents-settings", documentId],
          (document: DocumentSettings) => {
            const updated = {
              ...(document || makeDocumentSettings(userId, documentId)),
              ...updates,
            };
            return updated;
          }
        );
      },
    }
  );
};

export const useDocumentSettingsState = <T extends keyof DocumentSettings>(
  userId: string,
  documentId: string,
  key: T
): [
  {
    data: DocumentSettings[T];
    isLoading: boolean;
    isError: boolean;
    error: any;
  },
  (updateFn: (state: DocumentSettings[T]) => DocumentSettings[T]) => void
] => {
  const {
    data: documentSettings,
    isLoading,
    isError,
    error,
  } = useDocumentSettings(documentId);
  const settings = documentSettings || makeDocumentSettings(userId, documentId);

  const state = settings[key];
  const stateRef = useRef(state);
  stateRef.current = state;

  const { mutate: upsertDocumentSettings } =
    useUpsertDocumentSettings(documentId);

  const update = useCallback(
    (updateFn: (state: DocumentSettings[T]) => DocumentSettings[T]) => {
      const updated = updateFn(stateRef.current);

      upsertDocumentSettings({
        updates: { [key]: updated },
      });
    },
    [upsertDocumentSettings]
  );

  return [{ data: state, isLoading, isError, error }, update];
};
