import { useMutation } from "react-query";

import { queryClient } from "db/queryClient";
import { useCurrentUserId } from "db/currentUser";
import { setTemplatesTipSeen } from "db/user/mutations";

const useSetTemplatesTipSeen = () => {
  const userId = useCurrentUserId();

  return useMutation((variables) => setTemplatesTipSeen(userId), {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["user", userId]);
    },
  });
};

const usersQueries = {
  useSetTemplatesTipSeen,
};

export default usersQueries;
