import React from "react";

import Textarea, { TextareaProps } from "framework/components/form/Textarea";

const FeedbackTextarea = (props: TextareaProps) => {
  return (
    <Textarea
      style={{ marginTop: 10 }}
      minRows={3}
      maxRows={10}
      placeholder="How can we improve?"
      {...props}
    />
  );
};

export default FeedbackTextarea;
