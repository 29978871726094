import { Tag } from "./tags.types";
import { FirestoreDataConverter } from "../types";

export const TAG_CONVERTER: FirestoreDataConverter<Tag> = {
  toFirestore: (document: Tag) => {
    const { id, ...converted } = document;
    return converted;
  },
  fromFirestore: (snapshot): Tag => {
    const data = snapshot.data();

    return {
      id: snapshot.ref.id,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      userId: data.userId,
      title: data.title ?? "",
      color: data.color ?? "default",
      variant: data.variant ?? "outlined",
      emoji: data.emoji ?? null,
    };
  },
};
