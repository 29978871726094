import { useCallback } from "react";
import { Editor, Range, Transforms, Node } from "slate";
import { ReactEditor } from "slate-react";
import isHotkey from "is-hotkey";

import useMemoObject from "hooks/useMemoObject";
import createState from "components/slate/plugins/menus/componentMenu/state";
import useZustandCreateStore from "hooks/useZustandCreateStore";
import { UseSlatePlugin } from "components/slate/types";
import { ComponentMenuProps } from "components/slate/plugins/menus/componentMenu/types";
import { appShortcuts } from "appShortcuts";

import wrapperStyles from "components/slate/wrapper/index.module.scss";

export const useComponentsMenuPlugin: UseSlatePlugin<
  {},
  ComponentMenuProps
> = () => {
  const { state, getState } = useZustandCreateStore(createState, []);

  const openComponentsMenu = useCallback(
    (editor: Editor, ref: HTMLElement | null) => {
      const { openMenu } = getState();

      const node = ReactEditor.toSlateNode(editor, ref);
      const path = ReactEditor.findPath(editor, node);

      if (
        !editor.selection ||
        !Range.includes(Editor.range(editor, path), editor.selection)
      ) {
        // select current ref if it isn't selected
        Transforms.select(editor, Editor.start(editor, path));
      }

      openMenu(ref);
      setTimeout(() => {
        ref?.classList.add(wrapperStyles.opened);
      });
    },
    [getState]
  );

  const closeComponentsMenu = useCallback(
    (editor: Editor, { focus }: { focus?: boolean } = {}) => {
      const { closeMenu, currentElement } = getState();

      currentElement?.classList.remove(wrapperStyles.opened);
      closeMenu();

      if (focus) {
        ReactEditor.focus(editor);
      }
    },
    [getState]
  );

  const onKeyDown = useCallback(
    (editor: Editor) => (e: any) => {
      // const [streamMatch] = Editor.nodes(editor, {
      //   match: isStreamElement,
      // });

      if (
        isHotkey(appShortcuts.toggleComponentsMenu, e) &&
        editor.selection &&
        Range.isCollapsed(editor.selection)
      ) {
        e.preventDefault();

        const path = Editor.path(editor, editor.selection, { depth: 1 });
        const node = Node.descendant(editor, path);

        const ref = ReactEditor.toDOMNode(editor, node);

        openComponentsMenu(editor, ref);
      }
    },
    [openComponentsMenu]
  );

  return {
    handlers: {
      onKeyDown,
    },
    state: useMemoObject<ComponentMenuProps>({
      currentElement: state.currentElement,
      isOpened: state.currentElement != null,
      openComponentsMenu,
      closeComponentsMenu,
      search: state.search,
      setSearch: state.setSearch,
    }),
  };
};
