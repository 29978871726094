import React from "react";
import { Editor, Path } from "slate";
import isHotkey from "is-hotkey";

import { moveItemsBack, moveItemsForward } from "./transforms";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";

export const onKeyDown = (editor: Editor) => (e: React.KeyboardEvent) => {
  if (isHotkey(["tab"], e)) {
    e.preventDefault();

    const entries = Array.from(
      Editor.nodes(editor, {
        match: ExtendedEditor.isNestingElementCurried(editor),
      })
    );

    moveItemsForward(editor, entries);
  }

  if (isHotkey(["shift+tab"], e)) {
    e.preventDefault();
    const entries = Array.from(
      Editor.nodes(editor, {
        match: ExtendedEditor.isNestingElementCurried(editor),
      })
    );
    moveItemsBack(editor, entries);
  }
};
