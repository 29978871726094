import React, { MutableRefObject } from "react";
import { useSlateStatic } from "slate-react";
import { DraggableSyntheticListeners } from "@dnd-kit/core";
import { followCursor } from "tippy.js";
import { isWindows } from "react-device-detect";

import SimpleTooltip from "components/SimpleTooltip";
import { useDndState } from "components/slate/slate-extended/dnd/useDndState";
import { useComponentsMenuContext } from "components/slate/hooks/useComponentsMenuContext";
import { EditorActions } from "components/slate/actions";

import { openFormattingMenu } from "components/slate/plugins/menus/terminalMenu/components/TerminalFormattingMenu";

import styles from "../index.module.scss";
import { Editor } from "slate";

type DragHandleProps = {
  elementRef: MutableRefObject<HTMLElement | null>;
  listeners: DraggableSyntheticListeners | undefined;
  elementId: string;
};

export const DragHandle = (props: DragHandleProps) => {
  const { elementRef, listeners, elementId } = props;
  const metaKey = isWindows ? "Ctrl" : "⌘";
  const editor = useSlateStatic();
  const { activeId } = useDndState();

  const {
    getIsMenuOpened,
    openComponentsMenu,
    closeComponentsMenu,
  } = useComponentsMenuContext();

  const handleMouseUp: React.MouseEventHandler = (e) => {
    e.preventDefault();

    EditorActions.startTerminal(editor.slateId, elementId);

    // console.log("monkey" + editor.selection);

    openFormattingMenu();

    // if (!getIsMenuOpened(elementRef.current)) {
    //   openComponentsMenu(editor, elementRef.current);
    // } else {
    //   closeComponentsMenu(editor, { focus: true });
    // }
  };

  return (
    <SimpleTooltip
      content={
        <div contentEditable={false}>
          Drag <span style={{ opacity: 0.5 }}>to move</span>
          <br />
          Click <span style={{ opacity: 0.5 }}>to format</span>
          <br />
          While typing <span style={{ opacity: 0.5 }}>{metaKey} + /</span>
        </div>
      }
      offset={[0, 20]}
      animation="fade"
      duration={[300, 50]}
      delay={[1000, 0]}
      followCursor={true}
      plugins={[followCursor]}
      trigger="mouseenter"
    >
      <button
        contentEditable={false}
        data-slate-clipboard-skip={true}
        type="button"
        className={styles.handle}
        onClick={handleMouseUp}
        {...listeners}
        onMouseEnter={() => {
          activeId === null &&
            elementRef.current.classList.add(styles.highlighted);
        }}
        onMouseLeave={() => {
          elementRef.current.classList.remove(styles.highlighted);
        }}
      >
        <span data-slate-component-menu-target={true}>⠿</span>
      </button>
    </SimpleTooltip>
  );
};
