import React from "react";
import isHotkey from "is-hotkey";
import { Editor, Point, Range } from "slate";

import { UseSlatePlugin } from "components/slate/types";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";
import { focusEditorAtEnd, focusEditorAtStart } from "components/slate/utils";
import { EditorsStore } from "components/slate/state/EditorsStore";
import useMemoObject from "hooks/useMemoObject";
import { getNextEditorId } from "components/slate/workspace/utils/editors";
import { NavigationKeys } from "constants/navigationKeys";
import useEvent from "hooks/useEvent";
import { useStoreCallback } from "state/utils";

const useArrowNavigationPlugin: UseSlatePlugin<{
  slateId: string;
  contextId?: string;
  contextInterval?: [string, string];
}> = (props) => {
  const { slateId, contextId, contextInterval } = props;

  const onKeyDown = useEvent(
    useStoreCallback((get) => (editor: Editor) => (e: React.KeyboardEvent) => {
      if (
        isHotkey(["ArrowUp", "ArrowDown"], e) &&
        editor.selection &&
        Range.isCollapsed(editor.selection)
      ) {
        let key: NavigationKeys | null = null;

        const { contextStart, contextEnd } = ExtendedEditor.editorContext(
          editor,
          {
            contextId,
            contextInterval,
          }
        );

        if (isHotkey("ArrowUp", e)) {
          if (Point.equals(contextStart, editor.selection.anchor)) {
            key = NavigationKeys.ArrowUp;
          }
        }

        if (isHotkey("ArrowDown", e)) {
          if (Point.equals(contextEnd, editor.selection.focus)) {
            key = NavigationKeys.ArrowDown;
          }
        }

        const nextSlateId = getNextEditorId(slateId, key);

        if (nextSlateId) {
          const nextEditor = get(EditorsStore.editor(nextSlateId));

          if (nextEditor) {
            e.preventDefault();

            key === NavigationKeys.ArrowDown
              ? focusEditorAtStart(nextEditor)
              : focusEditorAtEnd(nextEditor);
          }
        }
      }
    })
  );

  return {
    handlers: useMemoObject({
      onKeyDown,
    }),
  };
};

export default useArrowNavigationPlugin;
