import React, { useEffect, useRef, useState } from "react";
import isHotkey from "is-hotkey";
import { ReactEditor } from "slate-react";

import { LinksToolbarInput } from "components/slate/plugins/menus/formatting/_shared/LinksToolbarInput";
import useOnClickOutside from "hooks/useOnClickOutside";
import {
  removeLinkAtPath,
  updateLinkAtPath,
} from "components/slate/plugins/link/transforms";

const LinksContextMenu = (props: any) => {
  const {
    closeElementContextMenu,
    setIsCurrentMenuActive,
    path,
    editor,
    element,
    target,
    popperRef,
  } = props;
  const inputRef = useRef(null);
  const [value, setValue] = useState(null);

  const saveValue = () => {
    if (inputRef.current && element) {
      //  @ts-ignore ts-migrate(2339) FIXME: Property 'value' does not exist on type 'null'.
      const { value } = inputRef.current;
      if (value.trim() !== "") {
        const path = ReactEditor.findPath(editor, element);
        updateLinkAtPath(editor, path, value);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (isHotkey("Enter", e)) {
      const value = e.target.value;
      if (value.trim() === "") {
        removeLinkAtPath(editor, path);
      } else {
        updateLinkAtPath(editor, path, value);
      }
      closeElementContextMenu();
    } else if (isHotkey("Escape", e)) {
      closeElementContextMenu();
    }
  };

  const handleRemove = () => {
    closeElementContextMenu();
    removeLinkAtPath(editor, path);
  };

  useEffect(() => {
    setValue(element?.url);
  }, [element, setValue]);

  useEffect(() => {
    setIsCurrentMenuActive(false);
  }, []);

  useOnClickOutside([{ current: target }, popperRef], () => {
    saveValue();
    closeElementContextMenu();
  });

  return (
    <LinksToolbarInput
      ref={inputRef}
      //  @ts-ignore ts-migrate(2322) FIXME: Type '{ ref: MutableRefObject<null>; value: null; ... Remove this comment to see the full error message
      value={value}
      onFocus={() => setIsCurrentMenuActive(true)}
      onBlur={() => setIsCurrentMenuActive(false)}
      onChange={(e: any) => setValue(e.target.value)}
      onKeyDown={handleKeyDown}
      onRemove={handleRemove}
    />
  );
};

export default LinksContextMenu;
