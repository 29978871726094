import { Provider } from "use-http";

import { useAuth } from "auth/AuthProvider";
import { env } from "thunk-env";

function FetchProvider(props: any) {
  const { token } = useAuth();

  const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};

  return (
    <Provider
      url={env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT}
      options={{
        headers: { ...authHeaders },
        timeout: 20000,
      }}
      {...props}
    />
  );
}

export default FetchProvider;
