import React from "react";
import { useTranslation } from "react-i18next";

import FormGroupLabel from "framework/components/form/FormGroupLabel";
import SubscriptionSettings from "components/subscriptions/SubscriptionSettings";
import { useAuth } from "auth/AuthProvider";
import { AuthAccessTypes } from "auth/types";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";

import styles from "./index.module.scss";

const Subscription = () => {
  const { t } = useTranslation();

  return (
    <WidgetErrorBoundary isEmptyFallback={true}>
      <div>
        <FormGroupLabel labelText={t("subscription.account.title")} />
        <div className={styles.productContainer}>
          <SubscriptionComponent />
        </div>
      </div>
    </WidgetErrorBoundary>
  );
};

export default Subscription;

const SubscriptionComponent = () => {
  const { authAccessType } = useAuth();
  const { t } = useTranslation();

  if (authAccessType === AuthAccessTypes.admin) {
    return <div>{t("subscription.account.messages.admin")}</div>;
  }

  if (authAccessType === AuthAccessTypes.earlyAccess) {
    return <div>{t("subscription.account.messages.early_access")}</div>;
  }

  return <SubscriptionSettings />;
};
