import { Descendant } from "slate";

import {
  FoldingElement,
  NestingElement,
} from "components/slate/slate-extended/types";
import { PieCheckboxStates } from "framework/components/form/PieCheckbox";

export type ListItemType = "list_item";
export const ListItemType: ListItemType = "list_item";

type BaseListItemElement = {
  id: string;
  type: ListItemType;
  children: Descendant[];
} & NestingElement &
  FoldingElement;

export enum ListTypes {
  Bulleted = "bulleted",
  Numbered = "numbered",
  TodoList = "todoList",
  CheckList = "checkList",
}

export type BulletedListItemElement = BaseListItemElement & {
  listType: ListTypes.Bulleted;
};

export type NumberedListItemElement = BaseListItemElement & {
  listType: ListTypes.Numbered;
};

export type TodoListItemElement = BaseListItemElement & {
  listType: ListTypes.TodoList;
  checked: boolean;
  state?: PieCheckboxStates;
  dueDate?: string;
};

export type CheckListItemElement = BaseListItemElement & {
  listType: ListTypes.CheckList;
  checked: boolean;
};

export type CheckableListItem = TodoListItemElement | CheckListItemElement;

export type ListItemElement =
  | BulletedListItemElement
  | NumberedListItemElement
  | CheckableListItem;
