import { useMemo } from "react";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import SearchkitInstantsearchClient from "@searchkit/instantsearch-client";

import AutoSuggest from "./AutoSuggest";
import { env } from "thunk-env";
import { useAuth } from "auth/AuthProvider";

const AppSearch = () => {
  const { token } = useAuth();

  const searchClient = useMemo(
    () =>
      SearchkitInstantsearchClient({
        url: env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT + "/search",
        headers: () => ({
          Authorization: `Bearer ${token}`,
        }),
      }),
    [token]
  );

  return (
    <InstantSearch searchClient={searchClient} indexName={"search-blocks"}>
      <AutoSuggest defaultRefinement="" />
      <Configure hitsPerPage={6} distinct />
    </InstantSearch>
  );
};

export { AppSearch };
