import { useHistory } from "react-router-dom";

import Button from "framework/components/form/Button";
import { useOnboardingContext } from "../OnboardingProvider";
import TextInput from "framework/components/form/TextInput";

import styles from "../index.module.scss";
import reminderStyles from "./CalReminder.module.scss";

const CalReminderCue = () => {
  const history = useHistory();
  const { reminderFormValues, setReminderFormValues } = useOnboardingContext();
  return (
    <>
      <h3 className={styles.onboardingHeadline}>Stay consistent</h3>
      <p className={styles.onboardingCopy}>
        Research shows it helps to have a cue that triggers you to think and
        write.
      </p>
      <div className={reminderStyles.calOption}>
        <h3 className={styles.onboardingHeadlineSm}>
          What happens right before you have time to think and write?
        </h3>
        <TextInput
          type="text"
          name="customCue"
          placeholder="I have time to think and write after..."
          value={reminderFormValues.customCue}
          onChange={(e) => {
            setReminderFormValues({
              ...reminderFormValues,
              customCue: e.target.value,
            });
          }}
        />
        <div style={{ textAlign: "right", marginTop: "1rem" }}>
          <Button
            variant="secondary"
            disabled={
              !reminderFormValues.customCue ||
              !reminderFormValues.customCue.trim().length
            }
            onClick={() => history.push("/onboarding/reminder/time")}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default CalReminderCue;
