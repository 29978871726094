import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import TextInput from "framework/components/form/TextInput";
import Button from "framework/components/form/Button";
import { SignUpFormValues, useSignUpContext } from "../SignUpProvider";
import nigelSerious from "images/nigel_serious.svg";
import { EMAIL_REGEX } from "helpers";

import styles from "../index.module.scss";

const EmailForm = () => {
  const history = useHistory();
  const { formValues, handleSubmitEmail, requiresName } = useSignUpContext();

  const { register, handleSubmit, errors } = useForm<
    Pick<SignUpFormValues, "email">
  >({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      email: formValues.email,
    },
  });

  useEffect(() => {
    requiresName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className={styles.signUpForm}
      onSubmit={handleSubmit((values) => {
        handleSubmitEmail(values);
        return false;
      })}
      noValidate
    >
      <figure className={styles.signUpFormImageContainer}>
        <img
          src={nigelSerious}
          alt="Nigel"
          className={styles.signUpFormImage}
        />
      </figure>

      <h3 className={styles.signUpFormHeadline}>
        I'll take it from here, {formValues.firstName}. What's your email
        address?
      </h3>

      <TextInput
        type="email"
        error={errors.email?.message}
        name="email"
        placeholder="example@email.com"
        labelText="Email Address"
        inputRef={register({
          required: "Email address required",
          pattern: { value: EMAIL_REGEX, message: "Invalid email address" },
        })}
      />

      <div style={{ textAlign: "right" }}>
        <Button
          variant="ghost"
          type="button"
          onClick={() => history.push(`/signup`)}
        >
          Back
        </Button>
        <Button variant="secondary" type="submit">
          Continue
        </Button>
      </div>
    </form>
  );
};

export default EmailForm;
