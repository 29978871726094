import React from "react";
import { someNode } from "@udecode/plate-core";
import {
  BoldIcon,
  ItalicIcon,
  CodeIcon,
  LinkIcon,
  AtIcon,
  StrikeThroughIcon,
  TemplateFilledIcon,
  MarkHighlight,
} from "thunk-icons";
import { TippyProps } from "@tippyjs/react";

import ToolbarButton from "./ToolbarButton";
import { ToolbarMark } from "./ToolbarMark";
import { formattingMenuModes } from "components/slate/plugins/menus/formatting/constants";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import useApplyFormatting from "components/slate/plugins/menus/formatting/FormattingMenu/useApplyFormatting";
import {
  BoldMarkType,
  CodeMarkType,
  HighlightMarkType,
  ItalicMarkType,
  StrikethroughMarkType,
} from "components/slate/plugins/marks/types";
import { LinkType } from "components/slate/plugins/link/types";
import { BacklinkType } from "components/slate/plugins/backlink/types";

const FormattingToolbar = (
  { setMode, editor }: any,
  type: LinkType = LinkType
) => {
  const tooltip: TippyProps = {
    arrow: true,
    delay: 0,
    duration: [100, 100],
    animation: "fade",
    hideOnClick: false,
    offset: [0, 17],
    placement: "top",
  };

  const { isWindows } = useDeviceDetector();
  const metaKey = isWindows ? "Ctrl" : "⌘";
  const shiftKey = isWindows ? "Shift" : "⇧";

  const applyFormatting = useApplyFormatting();

  return (
    <div
      style={{
        display: "flex",
        padding: "0 7px",
      }}
    >
      <ToolbarMark
        type={BoldMarkType}
        icon={<BoldIcon />}
        tooltip={{ content: `Bold (${metaKey}+B)`, ...tooltip }}
      />
      <ToolbarMark
        type={ItalicMarkType}
        icon={<ItalicIcon />}
        tooltip={{ content: `Italic (${metaKey}+I)`, ...tooltip }}
      />
      <ToolbarMark
        type={StrikethroughMarkType}
        icon={<StrikeThroughIcon />}
        tooltip={{
          content: `Strike through (${metaKey}+${shiftKey}+S)`,
          ...tooltip,
        }}
      />
      <ToolbarMark
        type={CodeMarkType}
        icon={<CodeIcon />}
        tooltip={{ content: `Code (${metaKey}+E)`, ...tooltip }}
      />
      <ToolbarMark
        type={HighlightMarkType}
        icon={<MarkHighlight />}
        tooltip={{
          content: `Highlight (${metaKey}+${shiftKey}+h)`,
          ...tooltip,
        }}
      />
      <ToolbarButton
        active={someNode(editor, {
          match: { type: type },
        })}
        icon={<LinkIcon />}
        tooltip={{ content: `Link (${metaKey}+K)`, ...tooltip }}
        onMouseDown={(e) => {
          e.preventDefault();
          setTimeout(() => {
            setMode(formattingMenuModes.links);
          }, 100);
        }}
      />
      <ToolbarButton
        tooltip={{ content: "Create backlink", ...tooltip }}
        onMouseDown={(e) => {
          e.preventDefault();
          applyFormatting(editor, BacklinkType, {});
        }}
        icon={<AtIcon />}
      />
      <ToolbarButton
        active={someNode(editor, {
          match: { type: LinkType },
        })}
        icon={<TemplateFilledIcon />}
        tooltip={{ content: `Make template`, ...tooltip }}
        onMouseDown={(e) => {
          e.preventDefault();
          setTimeout(() => {
            setMode(formattingMenuModes.snippets);
          }, 100);
        }}
      />
    </div>
  );
};

export default FormattingToolbar;
