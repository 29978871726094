import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AllNotesIcon } from "thunk-icons";
import { observer } from "mobx-react-lite";
import cn from "classnames";

import { useStore } from "stores/store";
import useAppPopper from "framework/components/poppers/useAppPopper";
import AppPopper, {
  PopperDivider,
  PopperMenuItem,
} from "framework/components/poppers/AppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import TextInput from "framework/components/form/TextInput";
import stringFilter from "utils/stringFilter";
import { TableSelection } from "framework/components/table/useSelection";
import getDocumentPathText from "utils/getDocumentPathText";
import {
  highlightMatch,
  highlightParse,
} from "components/slate/plugins/menus/hashMenu/items/utils";
import useKeyboardNavigation from "components/slate/plugins/menus/componentMenu/hooks/useKeyboardNavigation";

import css from "./MovePagesMenu.module.scss";
import { Document } from "thunk-core";

const MovePagesMenu = observer(
  (props: {
    children: React.FC<{ ref: (ref: HTMLElement) => void }>;
    selection: TableSelection;
  }) => {
    const { children } = props;
    const { pagesStore } = useStore();

    const popperProps = useAppPopper({
      placement: "bottom-start",
      hasArrow: true,
    });
    useClickPopper(popperProps);

    const { isOpened } = popperProps;

    const handleSubmit = useCallback(
      (id: string | null) => {
        props.selection.selectedIds.forEach((pageId) => {
          pagesStore.updateDocumentRemote(pageId, {
            parentId: id,
          });
        });
        props.selection.setSelectedIds(new Set());
        popperProps.closePopper();
      },
      [pagesStore, popperProps, props.selection]
    );

    const handleClose = useCallback(() => {
      popperProps.closePopper();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <AppPopper
        {...popperProps}
        content={
          <MovePagesMenuContent
            handleSubmit={handleSubmit}
            selection={props.selection}
            isOpened={isOpened}
            handleClose={handleClose}
          />
        }
      >
        {children({ ref: popperProps.setTargetElement })}
      </AppPopper>
    );
  }
);
export default MovePagesMenu;

const MovePagesMenuContent = observer(
  ({
    isOpened,
    selection,
    handleSubmit,
    handleClose,
  }: {
    isOpened: boolean;
    selection: TableSelection;
    handleSubmit: (id: string | null) => void;
    handleClose: () => void;
  }) => {
    const inputRef = useRef<HTMLElement | null>(null);
    const [search, setSearch] = useState<string>("");
    const { pagesStore } = useStore();
    const docs = pagesStore.documents;
    const pages = useMemo(() => Array.from(docs.values()), [docs]);

    useEffect(() => {
      if (isOpened) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 50);
      }
    }, [isOpened]);

    // Only show pages that are not already in the selection
    // or that match the search query
    const pagesToDisplay = useMemo(() => {
      const findChildren = (id: string) => {
        const children = pages.filter((page) => page.parentId === id);
        return children.reduce((acc, child) => {
          return [...acc, child.id, ...findChildren(child.id)];
        }, []);
      };

      const childrenIds = Array.from(selection.selectedIds).reduce(
        (acc, id) => [...acc, ...findChildren(id)],
        [] as string[]
      );

      return pages.filter((page) => {
        return (
          stringFilter(page.title, search) &&
          !selection.selectedIds.has(page.id) &&
          childrenIds.indexOf(page.id) === -1
        );
      });
    }, [pages, search, selection.selectedIds]);

    const placeholderText = `Move ${selection.selectedIds.size} ${
      selection.selectedIds.size === 1 ? "note" : "notes"
    } to...`;

    const handleAllClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      handleSubmit(null);
    };

    const handlePageClick = (page: any) => (
      e: React.MouseEvent<HTMLDivElement>
    ) => {
      e.preventDefault();
      handleSubmit(page.id);
    };
    const handleSelect = (e: KeyboardEvent, page: any) => {
      e.preventDefault();
      handleSubmit(page.id);
    };

    const { index, setIndex } = useKeyboardNavigation<any>({
      listening: isOpened,
      items: [{ id: null }, ...pagesToDisplay],
      onSelect: handleSelect,
      onCancel: handleClose,
    });

    useEffect(() => {
      if (isOpened) {
        pagesToDisplay.length > 0 ? setIndex(1) : setIndex(0);
      }
    }, [isOpened, pagesToDisplay, setIndex]);

    return (
      <div>
        <PopperMenuItem>
          <TextInput
            placeholder={placeholderText}
            style={{ minWidth: 270, width: "100%" }}
            inputRef={inputRef}
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </PopperMenuItem>
        <PopperDivider />
        <PopperMenuItem
          itemClass={cn(css.allNotesItem, { [css.activeItem]: index === 0 })}
          onClick={handleAllClick}
        >
          <span>
            <AllNotesIcon />
            All Notes
          </span>
        </PopperMenuItem>
        {pagesToDisplay.length > 0 && (
          <>
            <PopperDivider />
            <div tabIndex={0} className={css.pageResults}>
              {pagesToDisplay.map((page, idx) => (
                <PageResultItem
                  key={idx}
                  page={page}
                  search={search}
                  pathText={getDocumentPathText(pages, page)}
                  handlePageClick={handlePageClick}
                  isActive={index === idx + 1}
                />
              ))}
            </div>
          </>
        )}
      </div>
    );
  }
);

const PageResultItem = ({
  page,
  isActive,
  pathText,
  handlePageClick,
  search,
}: {
  page: Document;
  pathText: string;
  isActive: boolean;
  handlePageClick: (
    page: Document
  ) => (e: React.MouseEvent<HTMLDivElement>) => void;
  search?: string;
}) => {
  const [elem, setElem] = useState<HTMLElement | null>(null);
  const parsed = highlightParse(
    page.title || "[no title]",
    highlightMatch(page.title || "[no title]", search)
  );

  useEffect(() => {
    if (isActive) {
      elem && elem.scrollIntoView({ block: "end" });
    }
  }, [elem, isActive]);

  return (
    <PopperMenuItem
      ref={setElem}
      itemClass={cn(css.pageItem, {
        [css.activeItem]: isActive,
      })}
      onClick={handlePageClick(page)}
    >
      <div className={css.pageItemPath}>{pathText}</div>
      <div className={css.pageItemTitle}>
        {parsed.map((chunk) => {
          if (chunk.highlight) {
            return <span className={css.mark}>{chunk.text}</span>;
          } else {
            return <span>{chunk.text}</span>;
          }
        })}
      </div>
    </PopperMenuItem>
  );
};
