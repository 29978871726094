import { useEffect, useMemo } from "react";
import { collection, query, getDocs } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import {
  getStripePayments,
  getProducts,
  Product,
  CommonSessionCreateParams,
  Session,
  createCheckoutSession as _createCheckoutSession,
  getCurrentUserSubscriptions,
  SessionCreateParams,
  Subscription,
} from "@stripe/firestore-stripe-payments";
import { ascend, descend, flatten, sortWith, filter } from "ramda";

import { db, firebaseApp, functions } from "firebaseInstance";
import useLoadingValue from "hooks/useLoadingValue";
import { LoadingHook } from "hooks/types";
import { BillingPortalSession, PriceWithProduct } from "db/types";

declare global {
  interface Window {
    Rewardful: any;
  }
}

const payments = getStripePayments(firebaseApp, {
  productsCollection: "products",
  customersCollection: "customers",
});

export const hasSubscriptions = async (userId: string): Promise<boolean> => {
  const snapshot = await getDocs(
    query(collection(db, `customers/${userId}/subscriptions`))
  );

  return !snapshot.empty;
};

export const useProducts = (): LoadingHook<Product[], Error> => {
  const { value, loading, error, setValue, setError } = useLoadingValue<
    Product[] | null,
    Error
  >(() => null);

  useEffect(() => {
    getProducts(payments, {
      includePrices: true,
      activeOnly: true,
    })
      .then(setValue)
      .catch(setError);
  }, []);

  return [value, loading, error];
};

export const usePrices = (): LoadingHook<PriceWithProduct[], Error> => {
  const [products, loading, error] = useProducts();

  const prices = useMemo(() => {
    if (!products) {
      return null;
    }

    return filter(
      (price) => price.active,
      flatten(
        products.map((product) =>
          product.prices.map((price) => ({ ...price, productData: product }))
        )
      )
    );
  }, [products]);

  return [prices, loading, error];
};

function getClientReferenceId() {
  return (
    (window.Rewardful && window.Rewardful.referral) ||
    "checkout_" + new Date().getTime()
  );
}

export const createCheckoutSession = async (
  priceId: string,
  _params?: CommonSessionCreateParams
): Promise<Session> => {
  const params: SessionCreateParams = {
    price: priceId,
    allow_promotion_codes: true,
    client_reference_id: getClientReferenceId(),
    success_url: window.location.href,
    cancel_url: window.location.href,
    ..._params,
  };

  return await _createCheckoutSession(payments, params);
};

export const createBillingPortalSession = async (): Promise<any> => {
  const functionRef = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );

  const response = await functionRef({
    returnUrl: window.location.href,
  });

  const data = response.data as BillingPortalSession;

  return data;
};

export const useCurrentUserSubscriptions = (
  expired: boolean = false
): LoadingHook<Subscription[], Error> => {
  const { value, loading, error, setValue, setError } = useLoadingValue<
    Subscription[] | null,
    Error
  >(() => null);

  useEffect(() => {
    getCurrentUserSubscriptions(payments, {
      status: expired
        ? ["canceled", "incomplete", "incomplete_expired", "past_due", "unpaid"]
        : ["active", "trialing"],
    })
      .then(setValue)
      .catch(setError);
  }, []);

  const subscriptions = useMemo(() => {
    if (!value) {
      return null;
    }

    return sortWith(
      [
        ascend((s) => s.cancel_at_period_end),
        descend((s) => new Date(s.created)),
      ],
      value
    );
  }, [value]);

  return [subscriptions, loading, error];
};
