import { insertImage } from "@udecode/plate-image";
import { Editor } from "slate";

import { isImageUrl } from "components/slate/plugins/file/utils";
import { getMediaUrl } from "components/slate/plugins/mediaEmbed/utils";
import { insertMediaEmbed } from "components/slate/plugins/mediaEmbed/transforms/insertMediaEmbed";

export const withLinkBasedElement = (editor: Editor) => {
  const { insertData } = editor;

  editor.insertData = (data: any) => {
    const text = data.getData("text/plain");

    if (isImageUrl(text)) {
      insertData(data);
      insertImage(editor as any, text);
      return;
    }

    const mediaUrl = getMediaUrl(text);
    if (mediaUrl) {
      insertData(data);
      insertMediaEmbed(editor, { url: mediaUrl });
      return;
    }

    insertData(data);
  };

  return editor;
};
