import { Editor } from "slate";

import thumbnailPlaceholderImage from "images/thumbnail_placeholder.svg";
import useNotify from "hooks/useNotify";
import { useThemeContext } from "providers/ThemeProvider";
import { focusEditorAtStart } from "components/slate/utils";
import { EditorActions } from "components/slate/actions";
import { useDocumentsStore } from "stores/store";
import Button from "framework/components/form/Button";

import styles from "./index.module.scss";
import { Document, DocumentType } from "thunk-core";

type TemplatePreviewCardProps = {
  editor: Editor;
  template: Document;
};

export const TemplatePreviewCard = ({
  editor,
  template,
}: TemplatePreviewCardProps) => {
  const notify = useNotify();
  const { darkMode } = useThemeContext();
  const store = useDocumentsStore(DocumentType.SNIPPET);

  const imageUrl = darkMode
    ? template.imageUrlDark || template.imageUrl
    : template.imageUrl;

  const handleApply = async () => {
    try {
      focusEditorAtStart(editor);
      EditorActions.insertSnippet(
        { editor },
        { content: store.getDocumentContent(template.id) }
      );
    } catch (error) {
      console.error(error);
      notify.error(`An error occurred while adding template`);
    }
  };

  return (
    <Button
      variant="circular"
      size="large"
      onClick={handleApply}
      btnClass={styles.tabButton}
    >
      {template.title}
    </Button>
  );
};
