import React, { useEffect, useRef, useState, memo } from "react";

import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import createGraph from "pages/app/GraphPage/createGraph";
import useMemoObject from "hooks/useMemoObject";

import styles from "./index.module.scss";

const GraphArea = memo(({ graphRef, data, selectedId, setSelectedId }: any) => {
  const [initialized, setInitialized] = useState(false);
  const { isMobileScreen } = useDeviceDetector();

  const getGraph = () => graphRef.current;
  const setGraphRef = (ref: any) => {
    graphRef.current = ref;
    setInitialized(true);
  };

  const handleClickOutside = () => {
    setSelectedId(null);
  };

  const handleNodeClick = (e: any, node: any) => {
    setSelectedId(node.id);
  };

  useEffect(() => {
    const { selectNode, deselectNodes } = getGraph();

    selectedId ? selectNode(selectedId) : deselectNodes();
  }, [selectedId, data]);

  return (
    <Graph
      data={data}
      graphRef={graphRef}
      setGraphRef={setGraphRef}
      isMobileScreen={isMobileScreen}
      onClickOutside={handleClickOutside}
      onNodeClick={handleNodeClick}
    />
  );
});

export default GraphArea;

const Graph = ({
  graphRef,
  setGraphRef,
  data,
  isMobileScreen,
  onClickOutside,
  onNodeClick,
}: any) => {
  const containerRef = useRef(null);

  const props = useMemoObject({
    isMobileScreen,
    onClickOutside,
    onNodeClick,
  });

  useEffect(() => {
    const container = containerRef.current;

    const { dispose, ...graph } = createGraph(container, data, props);

    const ref = {
      container,
      ...graph,
    };

    setGraphRef(ref);

    return dispose;
  }, []);

  useEffect(() => {
    const { update } = graphRef.current;
    update(data);
  }, [data]);

  useEffect(() => {
    const { updateProps } = graphRef.current;
    updateProps(props);
  }, [props]);

  return <div ref={containerRef} className={styles.container} />;
};
