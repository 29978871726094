import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import AppSidebarSectionItem from "layout/AppLayout/_shared/AppSidebarSectionItem";
import { AppSidebarSectionEmpty } from "layout/AppLayout/_shared/AppSidebarSection";
import { useCurrentUserData, useUpdateCurrentUser } from "db/user/user.hooks";
import { useRecentItems } from "layout/AppLayout/AppRecentPages/utils";
import { getEditorPathname, makeDateId } from "helpers";
import { DocumentType } from "thunk-core";

const AppRecentPagesSection = observer(() => {
  const location = useLocation();
  const { isLoading, isError, data: user } = useCurrentUserData();
  const { mutate: updateCurrentUser } = useUpdateCurrentUser();

  const items = useRecentItems(user?.recents);

  useEffect(() => {
    if (!isLoading) {
      let href = location.pathname + location.search;

      if (href === "/writing") {
        href = getEditorPathname(
          DocumentType.NOTE,
          null,
          makeDateId(new Date())
        );
      }

      const recentPages = user?.recents || [];
      const recents = [href, ...recentPages.filter((x) => x !== href)].slice(
        0,
        10
      );

      updateCurrentUser({ updates: { recents } });
    }
  }, [isLoading, location]);

  if (isLoading || isError) {
    return null;
  }

  return (
    <div>
      {items.length > 0 ? (
        items.map((item) => (
          <AppSidebarSectionItem
            key={item.href}
            item={item}
            title={item.text}
            href={item.href}
          />
        ))
      ) : (
        <AppSidebarSectionEmpty />
      )}
    </div>
  );
});

export default AppRecentPagesSection;
