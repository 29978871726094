import { Editor } from "slate";

import { addNotification } from "hooks/useNotifications";
import insertPlainText from "components/slate/plugins/serialization/transforms/insertPlainText";
import insertHtml from "components/slate/plugins/serialization/transforms/insertHtml";
import insertMarkdown from "components/slate/plugins/serialization/transforms/insertMarkdown";

export const pastePlainText = async (editor: Editor) => {
  try {
    insertPlainText(editor, await navigator.clipboard.readText());
  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      duration: 2500,
      text: "Your permission is required for clipboard pasting.",
    });
  }
};

export const pasteHtml = async (editor: Editor) => {
  try {
    const clipboardItems = await navigator.clipboard.read();
    const clipboardItem = clipboardItems[0];

    if (clipboardItem) {
      try {
        const html = await (await clipboardItem.getType("text/html")).text();

        if (html) {
          insertHtml(editor, html);
        }
      } catch (error) {
        insertPlainText(editor, await navigator.clipboard.readText());
      }
    }
  } catch (error) {
    addNotification({
      type: "error",
      duration: 2500,
      text: "Your permission is required for clipboard pasting.",
    });
  }
};

export const pasteMarkdown = async (editor: Editor) => {
  try {
    const text = await navigator.clipboard.readText();
    insertMarkdown(editor, text);
  } catch (error) {
    console.error(error);
    addNotification({
      type: "error",
      duration: 2500,
      text: "Your permission is required for clipboard pasting.",
    });
  }
};
