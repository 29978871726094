import React from "react";
import { RecentIcon } from "thunk-icons";

import AppSidebarSection from "layout/AppLayout/_shared/AppSidebarSection";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import AppRecentPagesSwitch from "layout/AppLayout/AppRecentPages/AppRecentPagesSwitch";
import AppRecentPagesSection from "layout/AppLayout/AppRecentPages/AppRecentPagesSection";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";

const AppRecentPages = () => {
  const { isWindows } = useDeviceDetector();

  return (
    <WidgetErrorBoundary isEmptyFallback={true}>
      <AppSidebarSection
        title="Recent Notes"
        sectionId="recentNotes"
        actions={!isWindows && "⌥+tab"}
        icon={RecentIcon}
      >
        <AppRecentPagesSection />
      </AppSidebarSection>
      <AppRecentPagesSwitch />
    </WidgetErrorBoundary>
  );
};

export default AppRecentPages;
