import withBlock from "./withBlock";
import * as handlers from "./handlers";
import { UseSlatePlugin } from "components/slate/types";

const useBockPlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withBlock,
    handlers,
  };
};

export default useBockPlugin;
