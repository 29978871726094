import { useMemo } from "react";
import { v4 as uuid } from "uuid";

import { useCurrentUserData } from "db/currentUser";
import { useUpsertUserFeedback } from "db/feedback/feedback.hooks";
import usePersistedUserState from "hooks/usePersistedUserState";

export const useFeedback = () => {
  const feedbackId = useMemo(() => uuid(), []);
  const [user] = useCurrentUserData();
  const { mutateAsync: upsertUserFeedback } = useUpsertUserFeedback();
  const [hasFeedback, setHasFeedback] = usePersistedUserState(
    "hasFeedback",
    (restored) => (restored != null ? restored : false)
  );

  return {
    feedbackId,
    upsertUserFeedback,
    user,
    hasFeedback,
    setHasFeedback,
  };
};
