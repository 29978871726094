import React, { createContext, useContext } from "react";

import useMemoObject from "hooks/useMemoObject";
import { EditorType } from "components/slate/types";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { DocumentType } from "thunk-core";

type StaticPropsContextValue = {
  readOnly: boolean;
  slateId: string;
  editorType: EditorType;
  isSharing: boolean;
  isSnippet: boolean;
  isMainEditor: boolean;
  isHelpDocs: boolean;
  noSortable: boolean;
  pageTitle: string;
  documentId: string;
  documentType: DocumentType;
  onBacklinkClick?: (element: BacklinkElement) => void;
};

const StaticPropsContext = createContext<StaticPropsContextValue>({
  readOnly: false,
  slateId: "", // todo: check what is better to use as a default value here
  editorType: null,
  isSharing: false,
  isSnippet: false,
  isMainEditor: false,
  isHelpDocs: false,
  noSortable: false,
  pageTitle: "",
  documentId: null,
  documentType: null,
});

export const useStaticProps = () => useContext(StaticPropsContext);

type StaticPropsProviderProps = {
  children: React.ReactNode;
  value: StaticPropsContextValue;
};

export const StaticPropsProvider = ({
  children,
  value,
}: StaticPropsProviderProps) => {
  const memoizedValue = useMemoObject(value);

  return (
    <StaticPropsContext.Provider value={memoizedValue}>
      {children}
    </StaticPropsContext.Provider>
  );
};
