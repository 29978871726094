import { Element } from "slate";

import {
  Heading1Type,
  Heading1Element,
  Heading2Type,
  Heading2Element,
  Heading3Type,
  Heading3Element,
} from "./types";

export const isHeading1Element = (value: any): value is Heading1Element => {
  return Element.isElementType<Heading1Element>(value, Heading1Type);
};

export const isHeading2Element = (value: any): value is Heading2Element => {
  return Element.isElementType<Heading2Element>(value, Heading2Type);
};

export const isHeading3Element = (value: any): value is Heading3Element => {
  return Element.isElementType<Heading3Element>(value, Heading3Type);
};

export const isHeadingElement = (
  value: any
): value is Heading1Element | Heading2Element | Heading3Element => {
  return (
    isHeading1Element(value) ||
    isHeading2Element(value) ||
    isHeading3Element(value)
  );
};

export const isHeadingType = (type: Element["type"]) => {
  return (
    type === Heading1Type || type === Heading2Type || type === Heading3Type
  );
};

export const getHeaderLevel = (
  element: Heading1Element | Heading2Element | Heading3Element
) => {
  if (isHeading1Element(element)) {
    return 1;
  }

  if (isHeading2Element(element)) {
    return 2;
  }

  if (isHeading3Element(element)) {
    return 3;
  }

  return null;
};

export const getHeadingType = (level: number) => {
  const types = {
    1: Heading1Type,
    2: Heading2Type,
    3: Heading3Type,
  };

  return types[level];
};
