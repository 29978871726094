import { AutoformatRule } from "@udecode/plate-autoformat";

import { withAutoformat } from "./withAutoformat";
import { UseSlatePlugin } from "components/slate/types";

type Options = { rules: AutoformatRule[] };

const useAutoformatPlugin: UseSlatePlugin<Options> = ({ rules }) => {
  return {
    withOverrides: withAutoformat(rules),
  };
};

export default useAutoformatPlugin;
