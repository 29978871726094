import React from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { useSidebarContext } from "providers/SidebarProvider";
import SimpleTooltip from "components/SimpleTooltip";

import styles from "components/layout/NavigationItem/index.module.scss";

type NavigationItemProps = {
  className?: string;
  activeClassName?: string;
  svgComponent?: React.ComponentType<any>;
  activeSvgComponent?: React.ComponentType<any>;
  title: string;
  kbdShortcut?: string;
  to?: string;
  onClick?: React.MouseEventHandler;
};

const NavigationItem = ({
  className,
  activeClassName,
  svgComponent: SvgComponent,
  activeSvgComponent: ActiveSvgComponent = SvgComponent,
  title,
  kbdShortcut = "",
  to,
  onClick,
}: NavigationItemProps) => {
  const { isSidebarOpened } = useSidebarContext();

  return (
    <SimpleTooltip
      hideTooltip={isSidebarOpened}
      content={
        kbdShortcut ? (
          <span>
            {title} <span style={{ opacity: 0.5 }}>({kbdShortcut})</span>
          </span>
        ) : (
          title
        )
      }
      placement="right"
    >
      <NavLink
        className={cn(styles.link, className)}
        activeClassName={cn(activeClassName, styles.active)}
        to={to}
        onClick={onClick}
      >
        <div className={styles.iconContainer}>
          {SvgComponent && <SvgComponent className={styles.icon} size={24} />}
          {ActiveSvgComponent && (
            <ActiveSvgComponent className={styles.activeIcon} size={24} />
          )}
        </div>
        <span className={styles.text}>
          {title}
          {kbdShortcut && <span className={styles.kbd}>({kbdShortcut})</span>}
        </span>
      </NavLink>
    </SimpleTooltip>
  );
};

export default NavigationItem;
