import React from "react";
import cn from "classnames";

import { ElementProps } from "components/slate/types";
import { ListItemElement } from "components/slate/plugins/list/types";
import { getBulletedPointerContent } from "components/slate/plugins/list/getPointerContent";

import styles from "./index.module.scss";

export const renderBulletedItem = (
  props: ElementProps & { element: ListItemElement }
) => {
  return (
    <div
      {...props.attributes}
      className={cn(
        styles.listItem,
        styles[`listItem_${props.element.listType}`]
      )}
    >
      <span
        contentEditable={false}
        data-slate-clipboard-skip={true}
        className={styles.pointer}
        style={
          {
            "--pointer-content": `"${getBulletedPointerContent(
              props.element.depth
            )}"`,
          } as React.CSSProperties
        }
        {...props.listeners}
      />
      <div className={styles.listItemText}>{props.children}</div>
    </div>
  );
};
