import cardHeaderImageThumbnail1 from "images/cardHeaderBackgrounds/thumb_1.webp";
import cardHeaderImageThumbnail2 from "images/cardHeaderBackgrounds/thumb_2.webp";
import cardHeaderImageThumbnail3 from "images/cardHeaderBackgrounds/thumb_3.webp";
import cardHeaderImageThumbnail4 from "images/cardHeaderBackgrounds/thumb_4.webp";
import cardHeaderImageThumbnail5 from "images/cardHeaderBackgrounds/thumb_5.webp";
import cardHeaderImageThumbnail6 from "images/cardHeaderBackgrounds/thumb_6.webp";
import cardHeaderImageThumbnail7 from "images/cardHeaderBackgrounds/thumb_7.webp";
import cardHeaderImageThumbnail8 from "images/cardHeaderBackgrounds/thumb_8.webp";
import cardHeaderImageThumbnail9 from "images/cardHeaderBackgrounds/thumb_9.webp";
import cardHeaderImageThumbnail10 from "images/cardHeaderBackgrounds/thumb_10.webp";
import cardHeaderImageThumbnail11 from "images/cardHeaderBackgrounds/thumb_11.webp";
import cardHeaderImageThumbnail12 from "images/cardHeaderBackgrounds/thumb_12.webp";
import cardHeaderImageThumbnail13 from "images/cardHeaderBackgrounds/thumb_13.webp";
import cardHeaderImageThumbnail14 from "images/cardHeaderBackgrounds/thumb_14.webp";
import cardHeaderImageThumbnail15 from "images/cardHeaderBackgrounds/thumb_15.webp";
import cardHeaderImageThumbnail16 from "images/cardHeaderBackgrounds/thumb_16.webp";
import cardHeaderImageThumbnail17 from "images/cardHeaderBackgrounds/thumb_17.webp";

export const HEADER_IMAGE_THUMBNAILS = [
  cardHeaderImageThumbnail1,
  cardHeaderImageThumbnail2,
  cardHeaderImageThumbnail3,
  cardHeaderImageThumbnail4,
  cardHeaderImageThumbnail5,
  cardHeaderImageThumbnail6,
  cardHeaderImageThumbnail7,
  cardHeaderImageThumbnail8,
  cardHeaderImageThumbnail9,
  cardHeaderImageThumbnail10,
  cardHeaderImageThumbnail11,
  cardHeaderImageThumbnail12,
  cardHeaderImageThumbnail13,
  cardHeaderImageThumbnail14,
  cardHeaderImageThumbnail15,
  cardHeaderImageThumbnail16,
  cardHeaderImageThumbnail17,
];
