import cn from "classnames";
import React, { memo } from "react";
import { Link } from "react-router-dom";

import CollapseButton from "framework/components/CollapseButton";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import {
  makeNoteBacklink,
  makePageBacklink,
} from "db/backlinks/backlinks.mapping";
import { getEditorPathname } from "helpers";
import { BacklinkElement } from "components/slate/plugins/backlink/types";
import { useCurrentUserId } from "db/currentUser";

import styles from "components/editor/DocumentReferences/ReferenceGroup/index.module.scss";
import { getTargetId } from "db/documents/documents.utils";
import { useStore } from "stores/store";
import { uniq } from "ramda";
import { Document, DocumentType } from "thunk-core";

type Props = {
  referenceTargetId: string;
  document: Document;
  isSharing: boolean;
  isOpened: boolean;
  handleToggle: (targetId: string, isCollapsed: boolean) => void;
  onBacklinkClick?: (element: BacklinkElement) => void;
};

const ReferenceGroupTitle = memo(
  ({
    referenceTargetId,
    document,
    isSharing,
    isOpened,
    handleToggle,
    onBacklinkClick,
  }: Props) => {
    const userId = useCurrentUserId();
    const { sidePanelStore } = useStore();

    return (
      <div className={styles.referenceSourceTitle}>
        <CollapseButton
          className={styles.referenceTitleCollapseArrow}
          isOpened={isOpened}
          onClick={() => handleToggle(getTargetId(document), isOpened)}
        />
        {(function () {
          if (isSharing) {
            return <div>{getDocumentTitle(document)}</div>;
          }

          return (
            <Link
              onClick={(e) => {
                // prevent re-rendering side panel on reference source navigation
                const newSelectedBacklinks = uniq([
                  ...sidePanelStore.selectedBacklinks,
                  referenceTargetId,
                ]);
                sidePanelStore.setSelectedBacklinks(newSelectedBacklinks);

                if (document.type !== DocumentType.SNIPPET && onBacklinkClick) {
                  e.preventDefault();
                  onBacklinkClick(
                    document.type === DocumentType.NOTE
                      ? makeNoteBacklink(userId, { dateId: document.dateId })
                      : makePageBacklink(userId, {
                          documentId: document.id,
                          initialText: document.title,
                        })
                  );
                }
              }}
              to={getEditorPathname(
                document.type,
                document.id,
                document.dateId
              )}
            >
              <div
                className={cn(styles.sourceTitle, {
                  [styles.sourceTitleTemplate]:
                    document.type === DocumentType.SNIPPET,
                })}
              >
                {getDocumentTitle(document)}
              </div>
            </Link>
          );
        })()}
      </div>
    );
  }
);

export default ReferenceGroupTitle;
