import React, { createContext, useContext, useState, useEffect } from "react";
import ImgsViewer from "react-images-viewer";
import { ReactEditor, useSlateStatic } from "slate-react";
import { BsArrowsFullscreen } from "react-icons/bs";
import { Element, Node } from "slate";

import useMemoObject from "hooks/useMemoObject";
import { isImageElement } from "components/slate/plugins/file/utils";
import { ImageElement } from "components/slate/plugins/file/types";

import styles from "components/slate/plugins/file/components/ImagesViewer/index.module.scss";

type Value = {
  images: ImageElement[];
  isOpened: boolean;
  toggleImageViewer: React.Dispatch<React.SetStateAction<boolean>>;
  currentImage: number;
  setCurrentImage: React.Dispatch<React.SetStateAction<number>>;
};

const ImageViewerContext = createContext<Value>(null as Value);

const theme = {
  content: {
    cursor: "pointer",
  },
};

export const ImagesViewerProvider = ({
  content,
  children,
}: React.PropsWithChildren<{ content: Element[] }>) => {
  const [isOpened, toggleImageViewer] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const images = Array.from(Node.nodes({ children: content } as any))
    .map((entry) => entry[0])
    .filter(isImageElement)
    .filter((x) => x.url);

  const value = useMemoObject({
    images,
    isOpened,
    toggleImageViewer,
    currentImage,
    setCurrentImage,
  });

  return (
    <ImageViewerContext.Provider value={value}>
      {children}
    </ImageViewerContext.Provider>
  );
};

export const ImagesViewer = () => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    // postpone rendering
    setIsRendered(true);
  }, []);

  const {
    images,
    isOpened,
    toggleImageViewer,
    currentImage,
    setCurrentImage,
  } = useContext(ImageViewerContext);

  if (!isRendered || images.length === 0) {
    return null;
  }

  return (
    <ImgsViewer
      width={1496}
      imgs={images.map((image: any) => ({
        src: image.url,
      }))}
      currImg={currentImage}
      isOpen={isOpened}
      onClickPrev={() => setCurrentImage((state) => state - 1)}
      onClickNext={() => setCurrentImage((state) => state + 1)}
      onClose={() => toggleImageViewer(false)}
      theme={theme}
      showThumbnails={true}
      onClickThumbnail={setCurrentImage}
      backdropCloseable={true}
      enableKeyboardInput={true}
      onClickImg={() =>
        setCurrentImage((state) => Math.min(state + 1, images.length - 1))
      }
    />
  );
};

export const ImagesViewerSlide = ({
  url,
  children,
}: React.PropsWithChildren<{ url: string }>) => {
  const { images, toggleImageViewer, setCurrentImage } = useContext(
    ImageViewerContext
  );
  const index = images.findIndex((image: any) => image.url === url);
  const editor = useSlateStatic();

  const handleClick = () => {
    ReactEditor.blur(editor);
    setCurrentImage(index);
    toggleImageViewer(true);
  };

  return (
    <div className={styles.slideContainer}>
      <button
        contentEditable={false}
        onClick={handleClick}
        className={styles.expandButton}
      >
        <BsArrowsFullscreen className={styles.expandButtonIcon} />
        View Larger
      </button>
      <div className={styles.imageContainer}>{children}</div>
    </div>
  );
};
