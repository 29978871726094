import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  memo,
} from "react";
import { Editor, Range } from "slate";
import { usePopper } from "react-popper";
import { ReactEditor, useSlateStatic } from "slate-react";
import cn from "classnames";

import { PortalBody } from "framework/components/Portal";
import LinksContextMenu from "components/slate/plugins/menus/formatting/ElementContextMenu/LinksContextMenu";
import { ToolbarMenuContainer } from "components/slate/plugins/menus/formatting/_shared/ToolbarMenuContainer";
import useMemoObject from "hooks/useMemoObject";
import { isLinkElement } from "components/slate/plugins/link/utils";

import styles from "components/slate/plugins/menus/formatting/ElementContextMenu/index.module.scss";

//  @ts-ignore ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
const ElementContextMenuContext = createContext();

export const useElementContextMenu = () =>
  useContext<any>(ElementContextMenuContext);

export const ElementContextMenuProvider = ({ editor, children }: any) => {
  const [type, setType] = useState(null);
  const [path, setPath] = useState(null);
  const [element, setElement] = useState(null);
  const openingTimeoutRef = useRef(null);

  const [isTargetHovered, setIsTargetHovered] = useState(false);
  const [isPopperHovered, setIsPopperHovered] = useState(false);
  const [isCurrentMenuActive, setIsCurrentMenuActive] = useState(false);
  const isMenuActive =
    isTargetHovered || isPopperHovered || isCurrentMenuActive;

  const inactiveCloseTimeoutRef = useRef(null);

  useEffect(() => {
    if (isMenuActive) {
      //  @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
      clearTimeout(inactiveCloseTimeoutRef.current);
    } else {
      //  @ts-ignore ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'null'.
      inactiveCloseTimeoutRef.current = setTimeout(() => {
        closeElementContextMenu();
      }, 100);
    }
  }, [isMenuActive]);

  const closeElementContextMenu = useCallback(() => {
    //  @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
    clearTimeout(openingTimeoutRef.current);
    setType(null);
    setPath(null);
    setElement(null);
  }, [setType, setPath, setElement]);

  const openElementContextMenu = useCallback(
    (newType, newPath, newElement) => {
      if (newElement !== element) {
        closeElementContextMenu();
        const shouldOpen = editor.selection
          ? !Range.isExpanded(editor.selection)
          : true;

        if (shouldOpen) {
          //  @ts-ignore ts-migrate(2322) FIXME: Type 'Timeout' is not assignable to type 'null'.
          openingTimeoutRef.current = setTimeout(() => {
            setType(newType);
            setPath(newPath);
            setElement(newElement);
          }, 600);
        }
      }
    },
    [closeElementContextMenu, element, editor, setType, setPath, setElement]
  );

  useEffect(() => {
    return () => {
      //  @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
      clearTimeout(inactiveCloseTimeoutRef.current);
      //  @ts-ignore ts-migrate(2769) FIXME: No overload matches this call.
      clearTimeout(openingTimeoutRef.current);
    };
  }, []);

  const value = useMemoObject({
    openElementContextMenu,
    closeElementContextMenu,
    setIsTargetHovered,
    setIsPopperHovered,
    setIsCurrentMenuActive,
    path,
    type,
    element,
  });

  return (
    <ElementContextMenuContext.Provider value={value}>
      {children}
    </ElementContextMenuContext.Provider>
  );
};

export const ElementContextMenu = memo(() => {
  const {
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'closeElementContextMenu' does not exist ... Remove this comment to see the full error message
    closeElementContextMenu,
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'setIsPopperHovered' does not exist on ty... Remove this comment to see the full error message
    setIsPopperHovered,
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'setIsCurrentMenuActive' does not exist o... Remove this comment to see the full error message
    setIsCurrentMenuActive,
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'path' does not exist on type 'unknown'.
    path,
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'type' does not exist on type 'unknown'.
    type,
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'element' does not exist on type 'unknown... Remove this comment to see the full error message
    element,
  } = useElementContextMenu();
  const editor = useSlateStatic();

  const target = useMemo(() => {
    if (!path) {
      return null;
    } else {
      const [node] = Editor.node(editor, path);

      if (!node) {
        return null;
      }

      //  @ts-ignore ts-migrate(2345) FIXME: Argument of type 'BaseEditor' is not assignable to... Remove this comment to see the full error message
      const domNode = ReactEditor.toDOMNode(editor, node);
      return domNode;
    }
  }, [editor, path]);
  const popperRef = useRef(null);

  const popperProps = usePopper(target, popperRef.current, {
    placement: "top",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });
  const isVisible = path !== null;

  useEffect(() => {
    popperProps.update && popperProps.update();
  }, [isVisible]);

  const menuProps = {
    closeElementContextMenu,
    setIsCurrentMenuActive,
    path,
    type,
    element,
    editor,
    target,
    popperRef,
  };

  return (
    <PortalBody>
      <div
        className={cn(styles.tooltip, {
          [styles.visible]: isVisible,
        })}
        contentEditable={false}
        ref={popperRef}
        onMouseEnter={() => setIsPopperHovered(true)}
        onMouseLeave={() => setIsPopperHovered(false)}
        style={popperProps.styles.popper}
        {...popperProps.attributes.popper}
      >
        <ToolbarMenuContainer>
          {isLinkElement(element) && <LinksContextMenu {...menuProps} />}
        </ToolbarMenuContainer>
      </div>
    </PortalBody>
  );
});
