import React, { memo, useState } from "react";
import { use100vh } from "react-div-100vh";
import { useResizeDetector } from "react-resize-detector";
import cn from "classnames";

import BacklinksContent from "./backlinks/BacklinksContent";
import TodosContent from "components/editor/SidePanel/todos/TodosContent";
import { useDeviceDetector } from "providers/DeviceDetectorProvider";
import { WidgetErrorBoundary } from "components/errors/WidgetErrorBoundary";
import { useSidePanelMatch } from "components/editor/SidePanel/hooks/useSidePanelMatch";
import MobileSidePanelContainer from "components/layout/MobileSidePanelContainer";

import styles from "./index.module.scss";

const SidePanel = memo(() => {
  const matched = useSidePanelMatch();

  if (!matched) {
    return null;
  }

  return (
    <SidePanelLayout>
      {({ container }) => <SidePanelComponent container={container} />}
    </SidePanelLayout>
  );
});

export default SidePanel;

const SidePanelComponent = ({
  container,
}: {
  container: HTMLDivElement | null;
}) => {
  const [tabsContainer, setTabsContainer] = useState<HTMLDivElement | null>(
    null
  );

  return (
    <WidgetErrorBoundary>
      <div ref={setTabsContainer} className={styles.tabsContainer} />

      <WidgetErrorBoundary>
        <BacklinksContent tabsContainer={tabsContainer} />
      </WidgetErrorBoundary>

      <WidgetErrorBoundary>
        <TodosContent container={container} tabsContainer={tabsContainer} />
      </WidgetErrorBoundary>
    </WidgetErrorBoundary>
  );
};

const SidePanelLayout = ({
  children,
}: {
  children: ({
    container,
  }: {
    container: HTMLDivElement | null;
  }) => React.ReactNode;
}) => {
  const height = use100vh();
  const { width, ref } = useResizeDetector();
  const { isMobileScreen } = useDeviceDetector();

  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  return (
    <div ref={ref}>
      {isMobileScreen ? (
        <MobileSidePanelContainer containerRef={setContainer}>
          {children({ container })}
        </MobileSidePanelContainer>
      ) : (
        <div
          ref={setContainer}
          data-side-panel-container={true}
          className={cn(styles.container, "with-scrollbar")}
          style={{
            height: height - 38,
            width: width - 10,
          }}
        >
          {children({ container })}
        </div>
      )}
    </div>
  );
};
