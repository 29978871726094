import { ImageElement as ImageElementPlate } from "@udecode/plate-ui-image";
import { useReadOnly, useSlateStatic } from "slate-react";

import { ImagesViewerSlide } from "components/slate/plugins/file/components/ImagesViewer";
import ImageUploader from "../ImageUploader";
import { ElementProps } from "components/slate/types";
import { ImageElement } from "components/slate/plugins/file/types";

const ImageComponent = (props: ElementProps & { element: ImageElement }) => {
  const { element } = props;
  const editor = useSlateStatic();
  const readOnly = useReadOnly();

  if (!element.url) {
    if (readOnly) {
      return <div contentEditable={false}>{props.children}</div>;
    }

    return <ImageUploader {...props} />;
  }

  return (
    <ImagesViewerSlide url={element.url}>
      <ImageElementPlate
        {...props}
        caption={{ disabled: true }}
        attributes={props.attributes!}
        editor={editor as any}
        resizableProps={{ minWidth: 170 }}
      />
    </ImagesViewerSlide>
  );
};

export default ImageComponent;
