import { RenderLeafProps } from "slate-react";
import { CodeSyntaxLeaf } from "@udecode/plate-ui-code-block";

export const renderLeaf = (props: RenderLeafProps) => {
  const { attributes, leaf } = props;
  let { children } = props;

  if (leaf.bold) {
    children = <strong className="slate-bold">{children}</strong>;
  }

  if (leaf.code) {
    children = <code className="slate-code">{children}</code>;
  }

  if (leaf.italic) {
    children = <em className="slate-italic">{children}</em>;
  }

  if (leaf.underline) {
    children = <u className="slate-underline">{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s className="slate-strikethrough">{children}</s>;
  }

  if (leaf.highlight) {
    children = <mark className="slate-highlight">{children}</mark>;
  }

  if (leaf.kbd) {
    children = <kbd className="slate-kbd">{children}</kbd>;
  }

  if (leaf.code_syntax) {
    children = <CodeSyntaxLeaf {...props} />;
  }

  return <span {...attributes}>{children}</span>;
};
