import React from "react";
import cn from "classnames";

import { Card, CardTitle } from "framework/components/Card";
import { formatDate, formatDifferenceInDays } from "utils/dateUtils";
import { StreamTitlePortal } from "components/slate/plugins/stream/components/StreamScreen";

import styles from "./index.module.scss";

type EditorCardProps = {
  isSharing?: boolean;
  isSidePanelEditor?: boolean;
  style?: React.CSSProperties;
  className?: string;
  loading?: boolean;
  loadingTimeout?: number;
  title?: React.ReactNode;
  date?: Date;
  tags?: React.ReactNode;
  toolbar?: React.ReactNode;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  isTransparent?: boolean;
};

const EditorCard = (props: EditorCardProps) => {
  const {
    style,
    className,
    loading,
    loadingTimeout,
    tags,
    toolbar,
    children,
    footer,
    isSidePanelEditor,
    isTransparent = false,
  } = props;

  const renderedTitle = renderTitle(props);

  return (
    <Card
      noPadding={true}
      style={style}
      className={cn(styles.container, className, {
        [styles.sidePanel]: isSidePanelEditor,
        [styles.transparent]: isTransparent,
      })}
      loading={loading}
      loadingTimeout={loadingTimeout}
      title={
        renderedTitle ? (
          <StreamTitlePortal>{renderedTitle}</StreamTitlePortal>
        ) : (
          renderedTitle
        )
      }
      tags={tags}
      toolbar={!isTransparent && toolbar}
      footer={!isTransparent && footer}
    >
      {children}
    </Card>
  );
};

export default EditorCard;

const renderTitle = (props: EditorCardProps): React.ReactNode => {
  const { date, isSidePanelEditor, title } = props;

  if (date) {
    return (
      <CardTitle
        isSidePanel={isSidePanelEditor}
        title={formatDifferenceInDays(date)}
        hoverHint={formatDate(date)}
      />
    );
  }

  if (typeof title === "string") {
    return <CardTitle title={title} isSidePanel={isSidePanelEditor} />;
  }

  return title;
};
