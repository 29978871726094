import React from "react";

import { ElementProps } from "components/slate/types";
import { ParagraphElement } from "components/slate/plugins/paragraph/types";

const ParagraphComponent = (
  props: ElementProps & { element: ParagraphElement }
) => {
  const { children, attributes } = props;

  return (
    <p className="slate-p" {...attributes}>
      {children}
    </p>
  );
};

export default ParagraphComponent;
