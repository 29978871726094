import { NavigationKeys } from "constants/navigationKeys";

export const getElementByIndex = (index: number) => {
  const elements = document.querySelectorAll(
    '[data-slate-id] > [data-slate-node="element"]'
  );
  return elements[index];
};

export const getElementSlateId = (element: Element): string | null => {
  const editable = element.closest("[data-slate-id]");

  if (!editable) {
    return null;
  }

  const slateId = editable.getAttribute("data-slate-id");
  return slateId;
};

export const getFirstSlateId = (): string | null => {
  const editable = document.querySelector("[data-slate-id]");

  if (!editable) {
    return null;
  }

  const slateId = editable.getAttribute("data-slate-id");
  return slateId;
};

export const getFocusedSlateId = (): string | null => {
  const selection = window.getSelection();

  if (!selection || !selection.anchorNode) {
    return null;
  }

  const editable = selection.anchorNode.parentElement.closest(
    "[data-slate-id]"
  );

  if (!editable) {
    return null;
  }

  const slateId = editable.getAttribute("data-slate-id");
  return slateId;
};

export const getNextEditorId = (
  currentSlateId: string,
  key: NavigationKeys | null,
  options?: { onlyTerminal?: boolean }
): string | null => {
  if (key == null) {
    return;
  }

  if (!options) {
    options = {};
  }

  const { onlyTerminal = false } = options;

  const editable = document.querySelector(
    `[data-slate-id="${currentSlateId}"]`
  );
  const editables = Array.from(
    document.querySelectorAll(
      `${onlyTerminal ? "[data-slate-terminal]" : ""}[data-slate-id]`
    )
  );
  const leftEditors = Array.from(
    document.querySelectorAll(`[data-slate-horizontal-index="0"]`)
  );
  const rightEditors = Array.from(
    document.querySelectorAll(`[data-slate-horizontal-index="1"]`)
  );
  const index = editables.findIndex(
    (el) => el.getAttribute("data-slate-id") === currentSlateId
  );

  let newEditable: Element | null;
  if (key === NavigationKeys.ArrowDown) {
    newEditable = editables[index + 1];
  } else if (key === NavigationKeys.ArrowUp) {
    newEditable = editables[index - 1];
  } else if (key === NavigationKeys.ArrowLeft) {
    if (Number(editable.getAttribute("data-slate-horizontal-index")) === 1) {
      newEditable = leftEditors[0];
    }
  } else if (key === NavigationKeys.ArrowRight) {
    if (Number(editable.getAttribute("data-slate-horizontal-index")) === 0) {
      newEditable = rightEditors[0];
    }
  }

  if (newEditable) {
    const slateId = newEditable.getAttribute("data-slate-id");
    return slateId;
  }

  return null;
};
