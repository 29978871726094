import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import cn from "classnames";
import { SquareClose } from "thunk-icons";

import Modal from "framework/components/Modal";
import Button from "framework/components/form/Button";
import { uploadImage } from "./db";
import { defaultValidator, DEFAULT_ACCEPT } from "./utils";
import { useCurrentUserId } from "db/currentUser";
import TextInput from "framework/components/form/TextInput";
import { isValidImageUrl } from "components/slate/plugins/file/utils";

import styles from "./index.module.scss";

const ImageUploadModal = ({
  isOpen,
  close,
  maxFiles = 1,
  title = "Upload an Image",
  acceptedTypes = DEFAULT_ACCEPT,
  validator = defaultValidator,
  allowUrls = false,
}: any) => {
  const userId = useCurrentUserId();
  const [files, setFiles] = useState([]);
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("file");

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const closeModal = (urls = []) => {
    close(urls);
    setUrl("");
    setFiles([]);
    setMode("file");
    setError("");
  };

  const {
    getRootProps,
    getInputProps,
    fileRejections,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: acceptedTypes,
    maxFiles,
    validator,
  });

  const fileText = maxFiles > 1 ? "files" : "file";

  const className = useMemo(
    () =>
      cn(
        styles.dropzone,
        { [styles.active]: isDragActive },
        { [styles.accepted]: isDragAccept },
        { [styles.rejected]: isDragReject }
      ),
    [isDragActive, isDragReject, isDragAccept]
  );

  const upload = async () => {
    if (url) {
      if (!isValidImageUrl(url)) {
        setError("Invalid image url");
        return;
      }
      closeModal([url]);
      return;
    }
    if (files.length) {
      setLoading(true);
      const promises = [];
      for (const file of files) {
        promises.push(uploadImage(userId, file));
      }
      const urls = await (await Promise.all(promises)).map((img) => img.url);
      setLoading(false);
      closeModal(urls);
    } else {
      closeModal();
    }
  };

  const actions = (
    <div style={{ marginTop: 15 }}>
      <Button onClick={() => closeModal()}>Cancel</Button>

      <Button
        variant="secondary"
        disabled={mode === "file" ? !files.length : !url}
        loading={loading}
        onClick={upload}
      >
        {mode === "file" ? "Upload" : "Insert"}
      </Button>
    </div>
  );

  const previews = files.map((file) => (
    <div key={file.preview} className={styles.previewContainer}>
      <div contentEditable={false} className={styles.remove}>
        <SquareClose size={28} onClick={() => setFiles([])} />
      </div>
      <img
        className={styles.preview}
        alt="upload preview"
        src={file.preview}
        //  @ts-ignore ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type '"eager" ... Remove this comment to see the full error message
        loading={loading}
      />
    </div>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'path' does not exist on type 'File'.
    <div key={file.path}>
      {errors.map((e) => (
        <div className={styles.fileRejectionMsg} key={e.code}>
          {e.message}
        </div>
      ))}
    </div>
  ));

  const onUrlClick = (e) => {
    e.preventDefault();
    setFiles([]);
    setMode("url");
  };
  const onFileClick = (e) => {
    e.preventDefault();
    setUrl("");
    setMode("file");
    setError("");
  };

  return (
    <Modal
      closeTimeoutMS={100}
      isOpen={isOpen}
      title={title}
      close={closeModal}
      rightActions={actions}
    >
      {mode === "file" && !files.length && (
        <div {...getRootProps({ className })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the {fileText} here ...</p>
          ) : (
            <p>
              Drop {fileText} here, or click to select {fileText}
            </p>
          )}
        </div>
      )}
      {mode === "file" && allowUrls && !files.length && (
        <Button btnClass={styles.urlToggle} onClick={onUrlClick}>
          I already have an image url
        </Button>
      )}
      {mode === "file" && !!files.length && (
        <div style={{ marginTop: 15 }}>{previews}</div>
      )}
      {mode === "file" && !!fileRejectionItems.length && fileRejectionItems}
      {mode === "url" && (
        <TextInput
          type="url"
          error={error}
          labelText="Image url"
          placeholder="htts://www.website.com/image.png"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
      )}
      {mode === "url" && (
        <Button btnClass={styles.urlToggle} onClick={onFileClick}>
          I'll upload a file
        </Button>
      )}
    </Modal>
  );
};

export default ImageUploadModal;
