import { useHistory } from "react-router-dom";

import Button from "framework/components/form/Button";
import julianJedi from "images/julian_jedi.svg";

import styles from "../index.module.scss";

const Insight = () => {
  const history = useHistory();
  return (
    <div className={styles.onboardingContainerSm}>
      <figure className={styles.onboardingImageContainer}>
        <img
          src={julianJedi}
          alt="Julian Jedi"
          className={styles.onboardingImage}
        />
      </figure>

      <h3 className={styles.onboardingHeadline}>
        Consistent daily writing helps you succeed
      </h3>
      <p className={styles.onboardingCopy}>
        We use a daily template and calendar reminders to help.
      </p>

      <Button
        variant="secondary"
        onClick={() => history.push("/onboarding/daily")}
        autoFocus
      >
        Make Daily Template
      </Button>
    </div>
  );
};

export default Insight;
