import React from "react";
import Tippy from "@tippyjs/react";

import "./index.module.scss";

const FiltersTooltip = ({
  children,
  content
}: any) => {
  return (
    <Tippy
      content={content}
      interactive={true}
      placement="bottom"
      offset={[0, 8]}
      hideOnClick={false}
      className="filtersTooltip"
    >
      {children}
    </Tippy>
  );
};

export default FiltersTooltip;
