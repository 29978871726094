import { ParsedToken, User } from "@firebase/auth";

export enum AuthAccessTypes {
  admin = "ADMIN",
  earlyAccess = "EARLY_ACCESS",
  subscription = "SUBSCRIPTION",
  subscriptionExpired = "SUBSCRIPTION_EXPIRED",
  trial = "TRIAL",
  trialExpired = "TRIAL_EXPIRED",
}

export type AuthClaims = ParsedToken & {
  admin?: boolean;
  createdAt?: number;
  createdByInvitation?: boolean;
  createdByUserInvitation?: boolean;
  stripeRole?: "subscriber";
};

export type AuthProviderState = {
  currentUser: User | null;
  claims: AuthClaims;
  token: string | null;
  loading: boolean;
  authAccessType: AuthAccessTypes | null;
};

export enum SpecialUsers {
  ContentManger = "content-manager",
}

export type AuthContextValue = AuthProviderState & {
  userId: string | null;
  specialUser: SpecialUsers | null;
  isAuthenticated: boolean;
};
