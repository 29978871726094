import React, { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { OpenCardIcon } from "thunk-icons";
import { Link } from "react-router-dom";

import { useStore } from "stores/store";
import useAppPopper from "framework/components/poppers/useAppPopper";
import AppPopper, {
  PopperDivider,
  PopperMenuItem,
} from "framework/components/poppers/AppPopper";
import useClickPopper from "framework/components/poppers/useClickPopper";
import ColorPicker from "components/tags/ColorPicker";
import TitleTextInput from "components/tags/TItleTextInput";
import EmojiPicker from "components/tags/EmojiPicker";
import { observer } from "mobx-react-lite";
import { Tag } from "thunk-core";

const UpdateTagMenu = observer(
  (props: {
    tag: Tag;
    children: React.FC<{ ref: (ref: HTMLElement) => void }>;
  }) => {
    const { tag, children } = props;

    const { tagsStore } = useStore();
    const popperProps = useAppPopper({
      placement: "bottom-start",
      hasArrow: true,
    });
    useClickPopper(popperProps);

    const handleEmojiChange = (emoji: Tag["emoji"]) => {
      tagsStore.updateTag(tag.id, { emoji });
    };

    const handleTitleChange = (title: string) => {
      tagsStore.updateTag(tag.id, { title });
      popperProps.popper.update?.();
    };

    const handleColorChange = (
      color: Tag["color"],
      variant: Tag["variant"]
    ) => {
      tagsStore.updateTag(tag.id, { color, variant });
    };

    return (
      <AppPopper
        {...popperProps}
        content={
          (popperProps.isOpened || popperProps.isOpenedDebounced) && (
            <UpdateTagMenuContent
              isOpened={popperProps.isOpened}
              tag={tag}
              onTitleChange={handleTitleChange}
              onColorChange={handleColorChange}
              onEmojiChange={handleEmojiChange}
            />
          )
        }
      >
        {children({ ref: popperProps.setTargetElement })}
      </AppPopper>
    );
  }
);

export default UpdateTagMenu;

const UpdateTagMenuContent = ({
  isOpened,
  tag,
  onTitleChange,
  onColorChange,
  onEmojiChange,
}: {
  isOpened: boolean;
  tag: Tag;
  onTitleChange: (title: string) => void;
  onColorChange: (color: Tag["color"], variant: Tag["variant"]) => void;
  onEmojiChange: (emoji: Tag["emoji"]) => void;
}) => {
  const inputRef = useRef<HTMLElement | null>(null);
  const [title, setTitle] = useState(tag.title);

  useEffect(() => {
    if (isOpened) {
      setTitle(tag.title);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [isOpened]);

  return (
    <div>
      <PopperMenuItem>
        <ColorPicker
          color={tag.color}
          variant={tag.variant}
          onChange={onColorChange}
        />
      </PopperMenuItem>

      <PopperDivider />

      <Link
        to={`/overview?${queryString.stringify({
          t: tag.title,
        })}`}
      >
        <PopperMenuItem>
          <OpenCardIcon size={28} />
          <span>Open tag</span>
        </PopperMenuItem>
      </Link>

      <PopperDivider />

      <PopperMenuItem>
        <EmojiPicker emoji={tag.emoji} onChange={onEmojiChange} />
        <TitleTextInput
          inputRef={inputRef}
          initialValue={tag.title}
          value={title}
          buttonTitle="Save"
          onChange={(e) => setTitle(e.target.value)}
          onSubmit={onTitleChange}
        />
      </PopperMenuItem>
    </div>
  );
};
