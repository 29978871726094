import React, { useState } from "react";
import * as PopperJS from "@popperjs/core";
import { Modifier, usePopper } from "react-popper";
import cn from "classnames";

import { PortalBody } from "framework/components/Portal";
import useOnClickOutside from "hooks/useOnClickOutside";

import styles from "components/slate/components/ElementPopper/index.module.scss";

type ElementPopperProps = {
  children: React.ReactNode;
  isVisible: boolean;
  className?: string;
  element: HTMLElement | null;
  popperOptions?: Omit<Partial<PopperJS.Options>, "modifiers"> & {
    createPopper?: typeof PopperJS.createPopper;
    modifiers?: ReadonlyArray<Modifier<any, any>>;
  };
  onClickOutside?: () => void;
};

const ElementPopper = ({
  isVisible,
  className,
  element,
  children,
  popperOptions = {},
  onClickOutside = () => {},
}: ElementPopperProps) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const popper = usePopper(element, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
    ...popperOptions,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-22, 7],
        },
      },
      {
        name: "preventOverflow",
        options: {
          padding: 20,
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top-start"],
        },
      },
      ...(popperOptions.modifiers || []),
    ],
  });

  useOnClickOutside(
    [{ current: element }, { current: popperElement }],
    onClickOutside
  );

  return (
    <PortalBody>
      <div
        ref={setPopperElement}
        style={popper.styles.popper}
        {...popper.attributes.popper}
        className={cn(styles.elementPopper, className, {
          [styles.visible]: isVisible && element,
        })}
      >
        {children}
      </div>
    </PortalBody>
  );
};

export default ElementPopper;
