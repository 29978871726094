import React, { useMemo, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import useFetch, { CachePolicies } from "use-http";
import cn from "classnames";
import { v4 as uuid } from "uuid";

import useEventListener from "hooks/useEventListener";
import SlateDocumentEditor from "components/slate/editors/SlateDocumentEditor";
import Button from "framework/components/form/Button";
import EditorCard from "components/editor/EditorCard";
import Avatar from "framework/components/Avatar";
import Helmet from "components/Helmet";
import AppResult from "components/results/AppResult";
import { EditorErrorBoundary } from "components/errors/EditorErrorBoundary";
import HeaderLogo from "components/layout/HeaderLogo";
import { SidebarProvider } from "providers/SidebarProvider";
import { SidePanelProvider } from "providers/SidePanelProvider";
import { EditorType } from "components/slate/types";
import { getDocumentTitle } from "stores/utils/getDocumentTitle";
import { getBlocksContent } from "db/blocks/blocks.mapping";
import { SharingProvider } from "providers/SharingProvider";
import { useAuth } from "auth/AuthProvider";
import { useCurrentUserId } from "db/currentUser";
import { getEditorPathname } from "helpers";
import { useFunctionsFetch } from "providers/AppFetchProvider";
import useNotify from "hooks/useNotify";
import ForkingModal from "pages/other/SharingPage/ForkingModal";
import useDialog from "hooks/useDialog";
import Tag from "framework/components/Tag";
import { Block, colors, Document } from "thunk-core";
import SimpleTooltip from "components/SimpleTooltip";
import { useThemeContext } from "providers/ThemeProvider";
import madeWithImage from "images/madeWithThunkNotes_lightmode.svg";
import madeWithImageDark from "images/madeWithThunkNotes_darkmode.svg";

import styles from "./index.module.scss";

//constants for managing header image
const MAXIMUM_HEADER_HEIGHT = 240;
const MINIMUM_HEADER_HEIGHT = 60;
const SHOW_TITLE_START_THRESHOLD = -30;

type SharingData = {
  userName: string;
  avatarUrl: string;
  document: Document;
  blocks: Block[];
  pagesMap: Record<string, SharedBacklink>;
  tagsMap: Record<string, SharedTag>;
  code: string; // on error
};

export type SharedBacklink = {
  title: string;
};

export type SharedTag = {
  title: string;
  color: typeof colors[number] | "default";
  variant: "filled" | "outlined";
  emoji: { id: string; native: string } | null;
};

type Params = {
  userId: string;
  sharedId: string;
};

const SharingPage = () => {
  const { userId, sharedId } = useParams<Params>();

  const containerRef = useRef();

  const { loading, error, data } = useFetch<SharingData>(
    `/sharing/${userId}/${sharedId}`,
    { cachePolicy: CachePolicies.NO_CACHE },
    []
  );

  if (loading) {
    return (
      <div className={styles.pageContainer}>
        <PagePlaceholder />
      </div>
    );
  }

  if (error) {
    console.error(error);
    const isNotFoundError = data?.code === "thunk/db/not-found";

    return (
      <div className={styles.pageContainer}>
        <div className={styles.header}>
          <HeaderLogo className={styles.logo} />
        </div>
        <CardWrapper>
          <EditorCard>
            {isNotFoundError ? (
              <AppResult isCenteredContent={true} resultType={"notShared"} />
            ) : (
              <AppResult isCenteredContent={true} resultType="fetching" />
            )}
          </EditorCard>
        </CardWrapper>
      </div>
    );
  }

  const { pagesMap, tagsMap } = data;

  return (
    <SidebarProvider>
      <SidePanelProvider>
        <SharingProvider pagesMap={pagesMap} tagsMap={tagsMap}>
          <div ref={containerRef} className={styles.pageContainer}>
            <SharingHeader containerRef={containerRef} data={data} />
            <SharedDocumentEditor data={data} />
          </div>
        </SharingProvider>
      </SidePanelProvider>
    </SidebarProvider>
  );
};

export default SharingPage;

const SharedDocumentEditor = ({ data }: { data: SharingData }) => {
  const { darkMode } = useThemeContext();

  const { document, blocks, tagsMap } = data;

  const title = getDocumentTitle(document);
  const slateId = useMemo(() => uuid(), []);
  const { userName, avatarUrl } = data;

  const content = getBlocksContent(blocks);
  const tags =
    document.tags?.map((tagId) => tagsMap[tagId]).filter(Boolean) || [];

  return (
    <CardWrapper className={styles.sharedPage}>
      <Helmet pageTitle={title} />
      <EditorCard title={title} isSharing={true}>
        <div className={styles.sharedCardAvatar}>
          <Avatar avatarUrl={avatarUrl} />
          <span className={styles.usernameText}>{userName}</span>
        </div>
        <div className={styles.lineUnderAvatar}></div>
        <EditorErrorBoundary isMainEditor={true}>
          <SlateDocumentEditor
            slateId={slateId}
            userId={null}
            editorType={EditorType.MainEditor}
            value={content}
            readOnly={true}
            isSharing={true}
          />
        </EditorErrorBoundary>
      </EditorCard>
      <div className={styles.sharePageFooter}>
        <div className={styles.footerTop}></div>
        <a href="http://www.thunknotes.com">
          <img
            src={madeWithImage}
            alt="Made with Thunk Notes"
            className={styles.madeWithImage}
          />
          <img
            src={madeWithImageDark}
            alt="Made with Thunk Notes"
            className={styles.madeWithImageDark}
          />
        </a>
      </div>
    </CardWrapper>
  );
};

const Tags = (props: { tags: SharedTag[] }) => {
  const { tags } = props;

  return (
    <div>
      <div className={styles.tags}>
        {tags.map((tag) => (
          <Tag
            readOnly={true}
            color={tag.color}
            variant={tag.variant}
            emoji={tag.emoji}
          >
            {tag?.title}
          </Tag>
        ))}
      </div>
      {tags.length > 0 && <hr className={styles.tagsDivider} />}
    </div>
  );
};

const PagePlaceholder = () => {
  return (
    <>
      <div className={styles.header}>
        <div />
        <JoinButton />
      </div>
      <CardWrapper>
        <EditorCard loading={true} />
      </CardWrapper>
    </>
  );
};

const SharingHeader = ({
  data,
  containerRef,
}: {
  data: SharingData;
  containerRef?: React.RefObject<HTMLDivElement>;
}) => {
  // start code port for managing header image
  const [actualHeightDifference, setActualHeightDifference] = React.useState(
    MAXIMUM_HEADER_HEIGHT
  );
  const ticking = React.useRef(false);

  useEventListener(
    window,
    "scroll",
    () => {
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          setActualHeightDifference(
            MAXIMUM_HEADER_HEIGHT - Math.round(window.pageYOffset)
          );
          ticking.current = false;
        });

        ticking.current = true;
      }
    },
    [window, ticking]
  );

  const heightToSet = Math.max(
    Math.min(actualHeightDifference, MAXIMUM_HEADER_HEIGHT),
    MINIMUM_HEADER_HEIGHT
  );

  const marginToSet = Math.max(0, 90 + actualHeightDifference);
  // end code port

  if (!data) {
    return null;
  }

  const { document } = data;

  const title = getDocumentTitle(document);

  return (
    <div className={styles.header}>
      <div className={styles.inner} style={{ height: heightToSet }}>
        {document.headerImage && (
          <img
            alt="Card Header"
            className={styles.headerImage}
            src={document.headerImage}
          ></img>
        )}

        <div className={styles.leftActions}></div>
        <SimpleTooltip
          content={"Scroll to top"}
          animation="fade"
          duration={[300, 100]}
        >
          <div
            className={cn(styles.centerActions, {
              [styles.visible]:
                actualHeightDifference < SHOW_TITLE_START_THRESHOLD,
            })}
            style={{ marginTop: marginToSet }}
          >
            <Button
              variant="circularCardHeader"
              size="large"
              btnClass={styles.titleButton}
              onClick={
                () => window.scrollTo({ top: 0, behavior: "smooth" }) // scroll card to top when title button is clicked
              }
            >
              {" "}
              <span className={styles.titleText}>{title}</span>
            </Button>
          </div>
        </SimpleTooltip>
        <JoinButton data={data} />
      </div>
    </div>
  );
};

const CardWrapper = ({ children, className }: any) => {
  return <div className={cn(styles.cardWrapper, className)}>{children}</div>;
};

const JoinButton = ({
  data,
  mobileButton = false,
}: {
  data?: SharingData;
  mobileButton?: boolean;
}) => {
  const functionsFetch = useFunctionsFetch();
  const notify = useNotify();
  const forkingDialog = useDialog<{}, "accountExist" | "newAccount">();
  const [loading, setLoading] = useState(false);

  const { isAuthenticated } = useAuth();
  const userId = useCurrentUserId();

  const handleFork: React.MouseEventHandler = async (e) => {
    e.preventDefault();
    if (data) {
      if (isAuthenticated && document.userId === userId) {
        const href = getEditorPathname(
          document.type,
          document.id,
          document.dateId
        );
        window.open(href, "_blank").focus();
        return;
      }

      const title = getDocumentTitle(document);

      if (isAuthenticated && document.userId !== userId) {
        setLoading(true);
        const forkingNotificationId = notify.loading(
          `Copying "${title}" to your notes.`
        );
        try {
          const { data } = await functionsFetch(
            `/sharing/forkDocumentRequest/${document.id}`,
            {
              method: "post",
            }
          );

          const href = getEditorPathname(
            data.document.type,
            data.document.id,
            data.document.dateId
          );
          window.open(href, "_blank").focus();

          setLoading(false);
        } catch (error) {
          console.error(error);
          notify.error(`An error happened while copying "${title}" note.`);
          setLoading(false);
        } finally {
          notify.remove(forkingNotificationId);
        }
      }

      if (!isAuthenticated) {
        const result = await forkingDialog.open();

        switch (result) {
          case "newAccount": {
            window
              .open(
                `/forking?forkId=${document.id}&forkTitle=${document.title}&redirect=signup`,
                "_blank"
              )
              .focus();
            return;
          }
          case "accountExist": {
            window
              .open(
                `/forking?forkId=${document.id}&forkTitle=${document.title}&redirect=signin`,
                "_blank"
              )
              .focus();
            return;
          }
          default: {
            return;
          }
        }
      }
    }
  };

  if (!data) {
    return null;
  }

  const { document } = data;

  const title = getDocumentTitle(document);

  return (
    <>
      <SimpleTooltip
        content={"Edit a copy of this note"}
        animation="fade"
        duration={[300, 100]}
      >
        <div className={styles.editButtonWrapper}>
          <Button
            as="a"
            href="#"
            btnClass={cn(styles.joinButton, {
              [styles.mobileButton]: mobileButton,
            })}
            variant="circularCardHeader"
            // size="symbol"
            onClick={handleFork}
            loading={loading}
          >
            Edit
          </Button>
        </div>
      </SimpleTooltip>
      <ForkingModal {...forkingDialog} title={title} />
    </>
  );
};
