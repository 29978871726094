import {useEffect } from "react";

import { BeatLoader } from "react-spinners";

import OnboardingTemplate from "./OnboardingTemplate";
import { useOnboardingContext } from "./OnboardingProvider";

import styles from "./index.module.scss";

const OnboardingPage = () => {
  const { userLoading } = useOnboardingContext();

    //allow body scrolling on this page
    useEffect(() => {
      document.body.setAttribute("no-side-panel-page", "true");
    });

  if (userLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <BeatLoader color="var(--blue-color)" />
      </div>
    );
  }

  return <OnboardingTemplate />;
};

export default OnboardingPage;
