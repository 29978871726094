import { isDOMElement } from "utils/dom";

type ScrollOptions = {
  toTop?: boolean;
  topEdge?: number;
};

export const scrollParentToElement = (
  domElement: Element,
  options?: ScrollOptions
) => {
  if (!options) {
    options = {};
  }

  const { toTop = false, topEdge = 100 } = options;

  const rect = domElement.getBoundingClientRect();

  const top = rect.top;
  const bottom = rect.y + rect.height;

  const bottomEdge = window.innerHeight - 100;

  const scrollParent = getScrollParent(domElement);

  if (scrollParent) {
    if (top < topEdge || toTop) {
      scrollParent.scrollBy(0, top - topEdge);
    } else if (bottom > bottomEdge) {
      scrollParent.scrollBy(0, bottom - bottomEdge);

      const rect = domElement.getBoundingClientRect();

      const top = rect.top;
      if (top < topEdge) {
        scrollParent.scrollBy(0, top - topEdge);
      }
    }
  }
};

const getScrollParent = (node: Node): Element => {
  if (node === null) {
    return null;
  }

  if (isDOMElement(node) && node.scrollHeight - node.clientHeight > 10) {
    return node;
  } else {
    return getScrollParent(node.parentNode);
  }
};
