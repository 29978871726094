import React from "react";
import cn from "classnames";
import { FiltersIcon } from "thunk-icons";

import Button, { ButtonProps } from "framework/components/form/Button";

import styles from "./index.module.scss";

export const TableFilterToolbar = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className={styles.filterToolbar}>{children}</div>;
};

export const FilterButton = (props: ButtonProps & { filtersCount: number }) => {
  const { filtersCount, ...rest } = props;

  return (
    <Button
      icon={FiltersIcon}
      iconSize={20}
      variant="secondary"
      {...rest}
      btnClass={cn(styles.filterButton, {
        [styles.filterButtonActive]: filtersCount > 0,
      })}
    >
      {filtersCount > 0
        ? `${filtersCount} Filter${filtersCount > 1 ? "s" : ""}`
        : "Filter"}
    </Button>
  );
};
