import { Entity } from "../types";

export interface TagSchema {
  userId: string;
  title: string;
  color: typeof colors[number] | "default";
  variant: "filled" | "outlined";
  emoji: { id: string; native: string } | null;
}

export interface Tag extends TagSchema, Entity {}

export const colors = [
  "black",
  "yellow",
  "green",
  "light-blue",
  "blue",
  "pink",
  "red",
  "orange",
] as const;
