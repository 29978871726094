import React from "react";
import { Transforms } from "slate";
import { getPointBefore } from "@udecode/plate-core";
import { BaseEmoji } from "emoji-mart";

import CustomEmojiPicker from "components/slate/plugins/emoji/components/EmojiComponent/CustomEmojiPicker";
import { EmojiPluginState } from "components/slate/plugins/emoji/state";
import { useSlateStatic } from "slate-react";

import "emoji-mart/css/emoji-mart.css";

const EmojiSelect = ({
  emojis,
  search,
  flatEmojis,
  valueIndex,
  curRow,
}: EmojiPluginState) => {
  const editor = useSlateStatic();

  const selection = editor.selection;

  const addEmoji = (obj: BaseEmoji) => {
    const startMarkup = ":";
    const startMarkupPointBefore = getPointBefore(editor, selection.anchor, {
      matchString: startMarkup,
      skipInvalid: true,
    });
    const endMarkupPointBefore = selection.anchor;
    const markupRange = {
      anchor: startMarkupPointBefore,
      focus: endMarkupPointBefore,
    };
    Transforms.select(editor, markupRange);
    Transforms.insertText(editor, ` ${obj.native}`);
  };

  return (
    <CustomEmojiPicker
      addEmoji={addEmoji}
      search={search}
      emojis={emojis}
      valueIndex={valueIndex}
      flatEmojis={flatEmojis}
      curRow={curRow}
    />
  );
};

export default EmojiSelect;
