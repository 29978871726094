import React, { useEffect, useState } from "react";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import { doc, updateDoc } from "firebase/firestore";

import TextInput from "framework/components/form/TextInput";
import { db } from "firebaseInstance";
import { useEditableField } from "framework/components/form/EditableField";
import { useCurrentUserId } from "db/currentUser";

const FirstName = ({ user = {} }) => {
  const [unmount, setUnmount] = useState(false);
  const { t } = useTranslation();
  const userId = useCurrentUserId();

  const onSubmit = async (value: any) => {
    try {
      await updateDoc(doc(db, `users/${userId}`), {
        firstName: value,
      });
    } catch (e) {
      console.error(e.message);
      return e;
    }
  };

  const {
    isEditing,
    setIsEditing,
    setRef,
    submit,
    actions,
    form: { handleSubmit },
    handleKeyDown,
  } = useEditableField({
    name: "firstName",
    //  @ts-ignore ts-migrate(2339) FIXME: Property 'firstName' does not exist on type '{}'.
    defaultValue: user.firstName,
    onSubmit,
  });

  useEffect(() => {
    return () => {
      setUnmount(true);
    };
  });

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Prompt
        when={unmount && isEditing}
        message={
          "You may have unsaved changes to First name field, leave this page?"
        }
      />
      <TextInput
        type="text"
        name="firstName"
        placeholder={t("account_form.first_name.placeholder")}
        labelText={t("account_form.first_name.label")}
        labelContentRight={actions}
        inputRef={setRef}
        onKeyDown={handleKeyDown}
        onChange={() => setIsEditing(true)}
      />
    </form>
  );
};

export default FirstName;
