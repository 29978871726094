import { Card } from "framework/components/Card";

import { useHistory } from "react-router-dom";

import { useOnboardingContext } from "../OnboardingProvider";
import Button from "framework/components/form/Button";
import julianStepOne from "images/julian_step_1.svg";

import styles from "../index.module.scss";
import templateStyles from "./DailyTemplate.module.scss";

const DailyTemplate = () => {
  const history = useHistory();
  const { formValues, setFormValues } = useOnboardingContext();

  const { dailyLog, dailySummary, gratitudes, morningRoutine } = formValues;
  const handleSubmit = () => {
    history.push("/onboarding/reminder");
  };

  return (
    <div className={styles.onboardingContainerSplit}>
      <div className={styles.onboardingForm}>
        <div className={styles.onboardingFormContainer}>
          <figure className={styles.onboardingImageContainer}>
            <img
              src={julianStepOne}
              alt="Julian holding step 1 sign"
              className={styles.onboardingImage}
            />
          </figure>

          <h3 className={styles.onboardingHeadlineLg}>
            Let's build your daily template.
          </h3>
          <p className={styles.onboardingCopy}>
            Don't worry, you can customize this later.
          </p>

          <div className={templateStyles.templateOptions}>
            <div className={templateStyles.templateOptionRow}>
              <div className={templateStyles.templateOptionRowInput}>
                <label htmlFor="morning">
                  <input
                    type="checkbox"
                    id="morning"
                    checked={morningRoutine}
                    disabled={!dailyLog && !dailySummary && !gratitudes}
                    onChange={() =>
                      setFormValues({
                        ...formValues,
                        morningRoutine: !morningRoutine,
                      })
                    }
                  />
                  I want to build a great morning routine
                </label>
              </div>
            </div>
            <div className={templateStyles.templateOptionRow}>
              <div className={templateStyles.templateOptionRowInput}>
                <label htmlFor="gratitudes">
                  <input
                    type="checkbox"
                    id="gratitudes"
                    checked={gratitudes}
                    disabled={!dailyLog && !dailySummary && !morningRoutine}
                    onChange={() =>
                      setFormValues({
                        ...formValues,
                        gratitudes: !gratitudes,
                      })
                    }
                  />
                  I want to write daily gratitudes
                </label>
              </div>
            </div>
            <div className={templateStyles.templateOptionRow}>
              <div className={templateStyles.templateOptionRowInput}>
                <label htmlFor="log">
                  <input
                    type="checkbox"
                    id="log"
                    checked={dailyLog}
                    disabled={!morningRoutine && !dailySummary && !gratitudes}
                    onChange={() =>
                      setFormValues({
                        ...formValues,
                        dailyLog: !dailyLog,
                      })
                    }
                  />
                  I want to log my daily activities
                </label>
              </div>
            </div>
            <div className={templateStyles.templateOptionRow}>
              <div className={templateStyles.templateOptionRowInput}>
                <label htmlFor="summary">
                  <input
                    type="checkbox"
                    id="summary"
                    checked={dailySummary}
                    disabled={!dailyLog && !morningRoutine && !gratitudes}
                    onChange={() =>
                      setFormValues({
                        ...formValues,
                        dailySummary: !dailySummary,
                      })
                    }
                  />
                  I want to improve by using daily summaries
                </label>
              </div>
            </div>
          </div>

          <div
            style={{ textAlign: "right", marginTop: "2rem" }}
            className={templateStyles.nextBtn}
          >
            <Button variant="secondary" autoFocus onClick={handleSubmit}>
              Continue to reminder
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.onboardingPreview}>
        <Card
          title={
            <h1 className={templateStyles.previewTitle}>Daily Template</h1>
          }
          className={templateStyles.previewCard}
        >
          <div className={styles.onboardingPreview}>
            {morningRoutine && (
              <div className={templateStyles.previewSection}>
                <h3>Morning</h3>
                <p>
                  <span className={templateStyles.bullet}>•</span>Complete my{" "}
                  <span className={templateStyles.backlink}>
                    Morning Routine
                  </span>
                </p>
              </div>
            )}
            {gratitudes && (
              <div className={templateStyles.previewSection}>
                <h3 className={templateStyles.backlink}>Daily Gratitudes</h3>
                <p>
                  <span>1.</span>
                </p>
                <p>
                  <span>2.</span>
                </p>
                <p>
                  <span>3.</span>
                </p>
              </div>
            )}
            {dailyLog && (
              <div className={templateStyles.previewSection}>
                <h3>Timestamp Log</h3>
                <p>
                  <span className={templateStyles.bullet}>•</span>
                </p>
              </div>
            )}
            {dailySummary && (
              <div className={templateStyles.previewSection}>
                <h3>Daily Summary</h3>
                <p>
                  <span className={templateStyles.bullet}>•</span>Complete my{" "}
                  <span className={templateStyles.backlink}>Daily Summary</span>
                  :
                </p>
              </div>
            )}
          </div>
        </Card>
        <div
          style={{ textAlign: "right", marginTop: "2rem" }}
          className={templateStyles.nextBtnMobile}
        >
          <Button variant="secondary" autoFocus onClick={handleSubmit}>
            Continue to reminder
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DailyTemplate;
