import { Document } from "thunk-core";

export default function getDocumentPathText(
  allPages: Document[],
  page: Document
) {
  const titles = [page.parentId ? page.title : "All notes"];
  const docs = new Map<string, Document>();
  allPages.forEach((p) => docs.set(p.id, p));

  const build = (parentId: string | null) => {
    if (parentId) {
      const parent = docs.get(parentId);
      if (parent) {
        titles.unshift(parent.title);
        build(parent.parentId);
      }
    }
  };

  build(page.parentId);

  const result =
    titles.length > 2
      ? `${titles[0]}/.../${titles[titles.length - 1]}`
      : titles.join("/");

  return result;
}
