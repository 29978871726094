import { Document } from "thunk-core";
import cn from "classnames";

import useBreadcrumb from "components/slate/hooks/useBreadcrumb";
import Link from "framework/components/Link";

import styles from "./index.module.scss";

const Breadcrumb = ({ document }: { document: Document }) => {
  const [titles, ids] = useBreadcrumb(document);

  return (
    <div className={styles.root}>
      {titles.map((title, index) => {
        return (
          <div className={styles.itemWrapper}>
            {index !== titles.length - 1 && (
              <div className={styles.item} key={ids[index]} title={title}>
                <Link to={`/page/${ids[index]}`}>{title}</Link>
              </div>
            )}
            {index === titles.length - 1 && (
              <div
                className={cn(styles.item, styles.end)}
                title={title}
                key={ids[index]}
              >
                {title}
              </div>
            )}
            <div className={styles.carat}>
              {index !== titles.length - 1 && " / "}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
