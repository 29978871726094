import React from "react";

import { ErrorBoundaryBase } from "components/errors/ErrorBoundaryBase";
import AppResult, {
  ProvideFeedbackButton,
  ResetErrorButton,
} from "components/results/AppResult";

type WidgetErrorBoundaryProps = {
  children: React.ReactNode;
  isEmptyFallback?: boolean;
};

export const WidgetErrorBoundary = ({
  children,
  isEmptyFallback,
}: WidgetErrorBoundaryProps) => {
  return (
    <ErrorBoundaryBase
      fallback={({ resetError, eventId }) => {
        if (isEmptyFallback) {
          return null;
        }

        return (
          <AppResult
            isCenteredContent={true}
            resultType="error"
            eventId={eventId}
            actions={
              <>
                <ProvideFeedbackButton eventId={eventId} />
                <ResetErrorButton onReset={() => resetError()} />
              </>
            }
          />
        );
      }}
    >
      {children}
    </ErrorBoundaryBase>
  );
};
