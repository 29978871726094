import React from "react";

import { ErrorBoundaryBase } from "components/errors/ErrorBoundaryBase";
import AppResult from "components/results/AppResult";

type RouteErrorBoundaryProps = {
  children: React.ReactNode;
};

export const RouteErrorBoundary = ({ children }: RouteErrorBoundaryProps) => {
  return (
    <ErrorBoundaryBase
      fallback={({ eventId }) => (
        <AppResult
          isCenteredContent={true}
          resultType="error"
          eventId={eventId}
        />
      )}
    >
      {children}
    </ErrorBoundaryBase>
  );
};
