import { graphFilterTabs } from "pages/app/GraphPage/constants";
import { FilteredGraphData, GraphState } from "pages/app/GraphPage/types";
import { GraphNode } from "stores/types";
import { DocumentType } from "thunk-core";
import { flatten } from "ramda";

export const filterGraphData = (
  { nodesMap, linksMap }: GraphState | null,
  search: string,
  activeTab: string,
  filters: any
): FilteredGraphData => {
  const resultNodesMap = new Map<string, GraphNode>();
  const links = flatten(Array.from(linksMap.values()));

  for (const [id, node] of nodesMap) {
    if (activeTab === graphFilterTabs.favourites) {
      if (node.isFavorite) {
        resultNodesMap.set(id, node);
      }
      continue;
    } else {
      const excluded =
        !node.title.toLowerCase().includes(search.toLowerCase()) ||
        (!filters.orphans &&
          !links.some((link: any) => {
            return link.source === node.id || link.target === node.id;
          })) ||
        (!filters.dailyNotes && node.documentType === DocumentType.NOTE);

      if (!excluded) {
        resultNodesMap.set(id, node);
        continue;
      }
    }
  }

  const resultLinks = links.filter(
    (link) => resultNodesMap.get(link.source) && resultNodesMap.get(link.target)
  );
  const resultNodes = Array.from(resultNodesMap.values());

  return {
    nodes: resultNodes,
    links: resultLinks,
  } as FilteredGraphData;
};
