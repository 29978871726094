import { v4 as uuidv4 } from "uuid";
import i18n from "i18n";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

import { getFilePath } from "thunk-core";
import { storage } from "firebaseInstance";
import { addNotification, removeNotification } from "hooks/useNotifications";
import { getErrorInfo, getErrorInfoForFiles } from "./errors";
import { getFileExtension } from "components/slate/plugins/file/utils";
import { FileElement } from "./types";

export const uploadFile = async (userId: string, file: any) => {
  if (!userId) {
    const errorInfo = getErrorInfo();

    addNotification({
      duration: 2500,
      type: "error",
      text: i18n.t(errorInfo.message),
    });
    return;
  }

  const fileId = uuidv4();
  const ext = getFileExtension(file.name);
  const extPath = ext ? `.${ext}` : "";
  const path = getFilePath(userId, fileId, extPath);

  const uploadingNotificationId = addNotification({
    duration: 0,
    type: "loading",
    text: `Uploading ${file.name}`,
  });

  try {
    const fileRef = ref(storage, path);

    const snapshot = await uploadBytes(fileRef, file);
    const url = await getDownloadURL(snapshot.ref);

    removeNotification(uploadingNotificationId);

    addNotification({
      duration: 2500,
      type: "success",
      text: "File successfully uploaded",
    });

    return {
      id: fileId,
      url,
      fileName: file.name,
      fileSize: file.size,
      extension: ext,
      type: "file",
      children: [{ text: file.name }],
    } as FileElement;
  } catch (error) {
    console.error(error);
    removeNotification(uploadingNotificationId);
    const errorInfo = getErrorInfoForFiles(error);

    addNotification({
      duration: 2500,
      type: "error",
      text: errorInfo.message,
    });
    throw error;
  }
};
