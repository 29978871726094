import React from "react";

import { ElementProps } from "components/slate/types";
import { BlockquoteElement } from "components/slate/plugins/blockquote/types";

const BlockquoteComponent = (
  props: ElementProps & { element: BlockquoteElement }
) => {
  const { children, attributes } = props;

  return (
    <blockquote className="slate-blockquote" {...attributes}>
      {children}
    </blockquote>
  );
};

export default BlockquoteComponent;
