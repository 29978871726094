import { useEffect, useMemo, useState } from "react";

const useLoadingTimeout = (loading: boolean, loadingTimeout: number = 0) => {
  const [delayedLoading, setDelayedLoading] = useState(false);
  const state = useMemo(
    () => ({
      loadingTimeout,
    }),
    []
  );

  state.loadingTimeout = loadingTimeout; // use it as ref

  useEffect(() => {
    if (!loading) {
      setDelayedLoading(false);
      return;
    }

    const timeout = setTimeout(
      () => setDelayedLoading(true),
      state.loadingTimeout
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [loading, state]);

  return delayedLoading;
};

export default useLoadingTimeout;
