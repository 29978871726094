import { useEffect } from "react";
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { observer } from "mobx-react-lite";

import { useAuth } from "auth/AuthProvider";
import NotFoundPage from "pages/other/NotFoundPage";
import AppRoute from "routing/AppRoute";
import SimpleRoute from "routing/SimpleRoute";
import AppLayout from "layout/AppLayout";
import AuthLayout from "layout/AuthLayout";
import SignInPage from "pages/auth/SignInPage";
import ForgotPasswordPage from "pages/auth/ForgotPasswordPage";
import TemplatesOverviewPage from "pages/app/TemplatesOverviewPage";
import AccountPage from "pages/app/AccountPage";
import SharingPage from "pages/other/SharingPage";
import AuthActionRouter from "pages/auth/AuthActionRouter";
import SignUpPage from "pages/auth/SignUpPage";
import NewPasswordPage from "pages/auth/NewPasswordPage";
import GraphPage from "pages/app/GraphPage";
import InvitesPage from "pages/app/InvitesPage";
import PricingPage from "pages/auth/PricingPage";
import SignOutPage from "pages/auth/SignOutPage";
import { toLandingSite } from "routing/utils/redirects";
import { useAppInitialize } from "stores/hooks/useAppInitialize";
import DocumentsOverviewPage from "pages/app/DocumentsOverviewPage";
import PageEditingPage from "pages/app/PageEditingPage";
import NoteEditingPage from "pages/app/NoteEditingPage";
import TemplateEditingPage from "pages/app/TemplateEditingPage";
import FeedbackOverviewPage from "pages/app/FeedbackOverviewPage";
import { DocumentUserProvider } from "providers/DocumentUserProvider";
import { env } from "thunk-env";
import { useCurrentUserId } from "db/currentUser";
import TagsOverviewPage from "pages/app/TagsOverviewPage";
import ForkingPage from "pages/auth/ForkingPage";
import ForkPageHandler from "components/ForkPageHandler";
import OnboardingPage from "pages/app/OnboardingPage";
import OnboardingLayout from "layout/OnboardingLayout";
import SlateWorkspace from "components/slate/workspace/SlateWorkspace";
import { EditorActionsHandler } from "components/slate/actions";

// HashRouter for React Native web view local build
const Router = env.REACT_APP_WEBVIEW ? HashRouter : BrowserRouter;

const PublicRouting = () => {
  return (
    <Switch>
      <Route path="/:userId/:sharedId" component={SharingPage} />
    </Switch>
  );
};

const PrivateRouting = observer(() => {
  const { isAuthenticated } = useAuth();
  const userId = useCurrentUserId();

  const location = useLocation();
  const initializing = useAppInitialize(userId);
  const skipLoader = ["/signup", "/onboarding"].some((prefix) =>
    location.pathname.startsWith(prefix)
  );
  if (isAuthenticated && initializing && !skipLoader) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <BeatLoader color="var(--blue-color)" />
      </div>
    );
  }

  return (
    <Switch>
      {isAuthenticated && <Redirect from="/" exact to="/writing" />}
      {!isAuthenticated && <Redirect from="/" exact to="/signin" />}
      {/** Public */}
      <SimpleRoute path="/signin" layout={AuthLayout} component={SignInPage} />
      <SimpleRoute
        path="/reset"
        layout={AuthLayout}
        component={ForgotPasswordPage}
      />
      <SimpleRoute
        path="/reset-password"
        layout={AuthLayout}
        component={NewPasswordPage}
      />
      <SimpleRoute path="/signup" layout={AuthLayout} component={SignUpPage} />
      <SimpleRoute
        path="/auth-actions"
        layout={AuthLayout}
        component={AuthActionRouter}
      />
      <Route path="/pricing" component={PricingPage} />
      <Route path="/forking" component={ForkingPage} />
      <Route path="/signout" component={SignOutPage} />
      {/** App */}
      <AppRoute
        path="/onboarding"
        layout={OnboardingLayout}
        component={OnboardingPage}
      />
      <AppRoute
        path="/writing"
        layout={AppLayout}
        component={NoteEditingPage}
      />
      <AppRoute
        exact
        fullWidth={true}
        path={"/snippets"}
        layout={AppLayout}
        component={TemplatesOverviewPage}
      />
      <AppRoute
        path={["/snippets/:snippetId", "/users/:userId/snippets/:snippetId"]}
        layout={AppLayout}
        component={TemplateEditingPage}
      />
      <AppRoute
        path={["/page/:pageId", "/users/:userId/page/:pageId"]}
        layout={AppLayout}
        component={PageEditingPage}
      />
      <AppRoute path="/account" layout={AppLayout} component={AccountPage} />
      <AppRoute
        path="/overview"
        fullWidth={true}
        layout={AppLayout}
        component={DocumentsOverviewPage}
      />
      <AppRoute
        path="/tags"
        fullWidth={true}
        layout={AppLayout}
        component={TagsOverviewPage}
      />
      <AppRoute
        path="/graph"
        noDefaultStyles={true}
        layout={AppLayout}
        component={GraphPage}
      />
      <AppRoute
        path="/invitations"
        layout={AppLayout}
        component={InvitesPage}
      />
      {/** Admin */}
      <AppRoute
        isAdminPage={true}
        fullWidth={true}
        path="/feedback"
        layout={AppLayout}
        component={FeedbackOverviewPage}
      />
      {/** Other */}
      <AppRoute path="/empty" layout={AppLayout} component={"div"} />
      {isAuthenticated ? (
        <AppRoute layout={AppLayout} component={NotFoundPage} />
      ) : (
        <Route
          component={() => {
            toLandingSite();
            return null;
          }}
        />
      )}
    </Switch>
  );
});

const RoutingResolver = () => {
  const location = useLocation();
  const isSubscriptionsPage = useRouteMatch("/subscriptions") != null;
  const [_, userId, sharedId] = location.pathname.split("/");
  const isSharingPage =
    userId != null &&
    userId.trim() !== "" &&
    userId.length === 28 &&
    sharedId != null &&
    sharedId.trim() !== "";

  const isPublic = isSubscriptionsPage || isSharingPage;

  useEffect(() => {
    isSharingPage
      ? document.body.removeAttribute("data-not-public")
      : document.body.setAttribute("data-not-public", "true");
  }, [isSharingPage]);

  return isPublic ? <PublicRouting /> : <PrivateRouting />;
};

const Routing = () => {
  const { specialUser } = useAuth();
  const basename = specialUser != null ? specialUser.toString() : undefined;

  return (
    // @ts-ignore
    <Router basename={basename}>
      <EditorActionsHandler />
      <SlateWorkspace>
        <ForkPageHandler>
          <DocumentUserProvider>
            <Route>
              <RoutingResolver />
            </Route>
          </DocumentUserProvider>
        </ForkPageHandler>
      </SlateWorkspace>
    </Router>
  );
};

export default Routing;
