import React, { Fragment, useMemo, useState } from "react";
import { useSlate } from "slate-react";

import { ExtendedEditor } from "./extendedEditor";

const SlateExtended = (props: { children: React.ReactNode }) => {
  const editor = useSlate();
  const { children } = props;

  const initializeExtendedEditor = () => {
    ExtendedEditor.calculateSemanticPaths(editor);
  };

  useState(initializeExtendedEditor);
  useMemo(initializeExtendedEditor, [editor.children]);

  return <Fragment>{children}</Fragment>;
};

export default SlateExtended;
