import { Editor } from "slate";

import { UseSlatePlugin } from "components/slate/types";
import { isMediaEmbedElement } from "components/slate/plugins/mediaEmbed/utils";
import MediaEmbedComponent from "components/slate/plugins/mediaEmbed/components/MediaEmbedComponent";

const useMediaEmbedPlugin: UseSlatePlugin = () => {
  return {
    withOverrides(editor: Editor) {
      const { isVoid } = editor;

      editor.isVoid = (element) => {
        return isMediaEmbedElement(element) || isVoid(element);
      };

      return editor;
    },
    renderElement: (props) => {
      if (isMediaEmbedElement(props.element)) {
        return <MediaEmbedComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useMediaEmbedPlugin;
