import withList from "./withList";
import * as handlers from "./handlers";
import { isListItemElement } from "./utils";
import ListItem from "./components/ListItem";
import { UseSlatePlugin } from "components/slate/types";

const useListPlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withList,
    handlers,
    renderElement: (props) => {
      if (isListItemElement(props.element)) {
        return <ListItem {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useListPlugin;
