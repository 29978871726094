import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import useAuthStateWithToken from "hooks/useAuthStateWithToken";
import { auth } from "firebaseInstance";
import { AuthClaims, AuthContextValue, AuthProviderState } from "auth/types";
import LinearProgress from "framework/components/LinearProgress";
import { getAuthAccessType, getSpecialUser } from "auth/helpers";
import { env } from "thunk-env";
import { User } from "firebase/auth";

const initialState: AuthProviderState = {
  loading: true,
  claims: {},
  token: null,
  currentUser: null,
  authAccessType: null,
};

const initialValue: AuthContextValue = {
  ...initialState,
  userId: null,
  specialUser: null,
  isAuthenticated: false,
};

const AuthContext = React.createContext(initialValue);

export function useAuth() {
  return useContext(AuthContext);
}

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  return (
    <BrowserRouter>
      <AuthProviderComponent>{children}</AuthProviderComponent>
    </BrowserRouter>
  );
};

const AuthProviderComponent = observer(({ children }: AuthProviderProps) => {
  const location = useLocation();

  const [state, setState] = useState<AuthProviderState>(initialState);

  const updateState = (changes: Partial<AuthProviderState>) =>
    setState((state) => ({ ...state, ...changes }));

  const [_currentUser, _loading] = useAuthStateWithToken(auth);

  const specialUser = getSpecialUser(location.pathname, state.currentUser);

  const resetTrial = async (state) => {
    if (state.currentUser != null && state.claims.createdAt == null) {
      const response = await fetch(
        env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT + "/auth/resetTrial",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      if (response.status === 201) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    resetTrial(state);
  }, [state]);

  const handleAuthStateChange = useCallback(
    async (currentUser: User | null, loading: boolean) => {
      if (loading) {
        updateState({ loading: true });
        return;
      }

      if (currentUser) {
        try {
          const token = await currentUser.getIdToken(true);
          const idTokenResult = await currentUser.getIdTokenResult();
          const claims = (idTokenResult.claims as AuthClaims) || {};
          const authAccessType = await getAuthAccessType(
            currentUser.uid,
            currentUser,
            claims
          );
          updateState({
            loading: false,
            claims: claims,
            currentUser: currentUser,
            token,
            authAccessType,
          });
        } catch (error) {
          if (error?.code === "auth/network-request-failed") {
            return;
          }

          updateState({
            loading: false,
            claims: {},
            currentUser: null,
          });
        }
      } else {
        updateState({
          loading: false,
          claims: {},
          currentUser: null,
        });
      }
    },
    []
  );

  useEffect(() => {
    handleAuthStateChange(_currentUser, _loading);
    const listener = () => {
      handleAuthStateChange(_currentUser, _loading);
    };

    window.addEventListener("focus", listener);

    return () => window.removeEventListener("focus", listener);
  }, [_currentUser, _loading]);

  const value: AuthContextValue = useMemo(
    () => ({
      ...state,
      userId:
        specialUser != null
          ? specialUser.toString()
          : state.currentUser?.uid ?? null,
      specialUser,
      isAuthenticated: state.currentUser != null,
    }),
    [state]
  );

  if (state.loading) {
    return <LinearProgress style={{ position: "absolute", top: 0 }} />;
  }

  return (
    <AuthContext.Provider value={value}>
      {specialUser && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 99999,
            fontFamily: "monospace",
          }}
        >
          {specialUser.toString()} mode{" "}
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
});
