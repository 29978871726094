import {
  RecoilState,
  RecoilValue,
  SetterOrUpdater,
  Snapshot,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from "recoil";

export type StateGetter = <T>(recoilVal: RecoilValue<T>) => Promise<T>;
export type StateSetter = <T>(
  recoilVal: RecoilState<T>,
  valOrUpdater: ((currVal: T) => T) | T
) => void;

export type StoreCallback<Args extends ReadonlyArray<unknown>, Return> = (
  get: StateGetter,
  set: StateSetter,
  snapshot: Snapshot
) => (...args: Args) => Return;

export type StoreCallbackLoadable<
  Args extends ReadonlyArray<unknown>,
  Return
> = (
  get: <T>(recoilVal: RecoilValue<T>) => T,
  set: StateSetter,
  snapshot: Snapshot
) => (...args: Args) => Return;

export const useStoreCallback = <Args extends ReadonlyArray<unknown>, Return>(
  fn: StoreCallbackLoadable<Args, Return>,
  deps?: ReadonlyArray<unknown>
): ((...args: Args) => Return) => {
  return useRecoilCallback(({ set, snapshot }) => {
    const get = <T>(value: RecoilValue<T>): T =>
      snapshot.getLoadable(value).contents;

    return fn(get, set, snapshot);
  }, deps);
};

export const useStoreState = <T>(
  state: RecoilState<T>
): [T, SetterOrUpdater<T>] => {
  return useRecoilState(state);
};

export const useStoreValue = <T>(recoilValue: RecoilValue<T>): T => {
  return useRecoilValue(recoilValue);
};
