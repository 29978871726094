import { Descendant } from "slate";

import { Document, DocumentType } from "thunk-core";

export type BacklinkType = "backlink";
export const BacklinkType: BacklinkType = "backlink";

export type BacklinkElement = {
  id: string;
  type: BacklinkType;
  userId: string;
  targetId: Document["id"] | Document["dateId"] | null;
  targetType: DocumentType;
  targetBlockId: string | null;
  blockText: string | null;
  children: Descendant[];
};

export type ExtractedBacklink = BacklinkElement & {
  blockId: string;
};
