import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "auth/AuthProvider";
import useNotify from "hooks/useNotify";
import { getEditorPathname } from "helpers";
import { useFunctionsFetch } from "providers/AppFetchProvider";
import { useUserData } from "hooks/useUserData";
import getForkData from "utils/getForkData";

const ForkPageHandler = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();
  const { isAuthenticated, currentUser } = useAuth();
  const [userProfile, profileLoading] = useUserData(currentUser?.uid);
  const hasCopied = useRef(false);

  const notify = useNotify();
  const functionsFetch = useFunctionsFetch();

  const copyDocument = async (forkId: string) => {
    const { data } = await functionsFetch(
      `/sharing/forkDocumentRequest/${forkId}`,
      {
        method: "post",
      }
    );
    const href = getEditorPathname(
      data.document.type,
      data.document.id,
      data.document.dateId
    );
    window.sessionStorage.removeItem("forkPage");
    history.push(href);
  };

  const handleFork = async (forkData) => {
    const { forkId, forkTitle } = forkData;

    const forkingNotificationId = notify.loading(
      `Copying "${forkTitle}" to your notes.`
    );

    try {
      await copyDocument(forkId);
      notify.success(`"${forkTitle}" successfully copied to your notes.`);
    } catch (error) {
      console.error(error);
      notify.error(`An error happened while copying "${forkTitle}" note.`);
    } finally {
      notify.remove(forkingNotificationId);
    }
  };

  const handleForkForOnboardingFlow = async (forkData) => {
    const { forkId } = forkData;
    try {
      await copyDocument(forkId);
    } catch (error) {
      console.error(error);
      history.push("/");
    }
  };

  useEffect(() => {
    if (!isAuthenticated || profileLoading || !userProfile) {
      return;
    }
    const forkData = getForkData();

    if (forkData) {
      const shouldForkOnboarding =
        userProfile.shouldSeeOnboarding &&
        userProfile.hasCompletedOnboarding &&
        forkData.isNewUser;
      const isInOnboarding =
        userProfile.shouldSeeOnboarding &&
        !userProfile.hasCompletedOnboarding &&
        forkData.isNewUser;

      if (isInOnboarding) {
        return;
      }

      if (shouldForkOnboarding) {
        hasCopied.current = true;
        handleForkForOnboardingFlow(forkData);
        return;
      }
      if (!hasCopied.current) {
        hasCopied.current = true;
        handleFork(forkData);
      }
    }
  }, [isAuthenticated, profileLoading, userProfile]);

  return <>{children}</>;
};

export default ForkPageHandler;
