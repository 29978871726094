import React from "react";
import cn from "classnames";
import { SuccessIcon, WarningIcon, GiftIcon } from "thunk-icons";

import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode;
  status: "success" | "error" | "gift";
  code: string;
};

const states = {
  success: {
    icon: SuccessIcon,
    className: null,
  },
  error: {
    icon: WarningIcon,
    className: null,
  },
  gift: {
    icon: GiftIcon,
    className: styles.gift,
  },
};

const Alert: React.FC<any> = ({ status, children, code }: Props) => {
  const { icon: Icon, className } = states[status] || {};

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.content}>
        {Icon && <Icon className={styles.icon} />}
        <div>{children}</div>
      </div>
      {code && <div className={styles.code}>Error code: "{code}"</div>}
    </div>
  );
};

export default Alert;
