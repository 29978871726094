import { atom, useRecoilState, useRecoilValue, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
import { Location } from "history";

const sidebarPersist = recoilPersist({
  key: "sidebarState",
  storage: window.localStorage,
});

export const SidebarState = atom<boolean>({
  key: "SidebarState",
  default: false,
  effects_UNSTABLE: [sidebarPersist.persistAtom],
});

export const useSidebarState = () => {
  return useRecoilState(SidebarState);
};

export const HelpSectionState = atom<boolean>({
  key: "HelpSectionState",
  default: false,
});

export const useHelpSectionState = () => {
  return useRecoilState(HelpSectionState);
};

export const MainDocumentWidthState = atom<number>({
  key: "MainDocumentWidthState",
  default: 50,
  effects_UNSTABLE: [
    recoilPersist({
      key: "MainDocumentWidthState1",
      storage: window.localStorage,
    }).persistAtom,
  ],
});

const historyPersist = recoilPersist({
  key: "historyState",
  storage: window.sessionStorage,
});

export const HistoryState = atom<{
  locations: Location[];
  index: number;
}>({
  key: "HistoryState",
  default: {
    locations: [],
    index: 0,
  },
  effects_UNSTABLE: [historyPersist.persistAtom],
});

export const useHistoryState = () => {
  return useRecoilState(HistoryState);
};

const HistoryArrowsSelector = selector({
  key: "HistoryArrowsSelector",
  get: ({ get }) => {
    const { locations, index } = get(HistoryState);
    return {
      hasBackward: index !== 0,
      hasForward: index < locations.length - 1,
    };
  },
});

export const useHistoryArrowsValue = () => {
  return useRecoilValue(HistoryArrowsSelector);
};
