import { UseSlatePlugin } from "components/slate/types";
import { withTrailingLine } from "components/slate/plugins/trailingLine/withTrailingLine";

const useTrailingLinePlugin: UseSlatePlugin = () => {
  return {
    withOverrides: withTrailingLine,
  };
};

export default useTrailingLinePlugin;
