import React, { Ref } from "react";
import { use100vh } from "react-div-100vh";
import cn from "classnames";

import styles from "./index.module.scss";

export const MobileSidePanelContainer = ({
  children,
  containerRef,
}: React.PropsWithChildren<{ containerRef: Ref<HTMLDivElement> }>) => {
  const height = use100vh();

  return (
    <div
      ref={containerRef}
      className={cn(styles.sidePanel, "with-scrollbar")}
      style={{ height: height - 64 }}
    >
      {children}
    </div>
  );
};

export default MobileSidePanelContainer;
