import React from "react";
import { createPortal } from "react-dom";

import { useStreamContext } from "./StreamProvider";

import styles from "./index.module.scss";

const StreamOverlay = () => {
  const { isStreamActive } = useStreamContext();

  return createPortal(
    <div
      style={{
        display: isStreamActive ? "block" : "none",
      }}
      className={styles.overlay}
    />,
    document.body
  );
};

export default StreamOverlay;
