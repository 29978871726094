import { Entity } from "../types";

export interface DocumentSchema {
  type: DocumentType;
  userId: string;
  dateId: string | null;
  parentId: string | null; // For sub-pages
  sharedId: string | null;
  originPageId: string | null;
  originUserId: string | null;
  title: string;
  lowercaseTitle: string;
  blocks: string[];
  tags: string[];
  isShared: boolean;
  isFavorite: boolean;
  isEmpty: boolean | null;
  showWordCount: boolean | null;
  hasSyncedRemote: boolean | null;
  imageStoragePath?: string | null;
  imageUrl?: string | null;
  imageStoragePathDark?: string | null;
  imageUrlDark?: string | null;
  contentHash?: string | null;
  lastModifiedSession?: string | null;
  headerImage?: string | null;
}

export interface Document extends DocumentSchema, Entity {}

export enum DocumentType {
  PAGE = "page",
  NOTE = "note",
  SNIPPET = "snippet",
}

export type MakeSnippetParams = {
  id: string;
  userId: string;
  title: string;
  blocks: string[];
};

export type MakeDocumentParams = {
  type: DocumentType;
  userId: string;
  id?: string;
  dateId?: string;
  parentId?: string;
  title?: string;
  blocks?: string[];
  lastModifiedSession?: string | null;
  headerImage?: string | null;
};

export type MakeDocumentAsBacklinkParams = {
  id: string;
  userId: string;
  parentId: string | null;
  parentType: string;
  title: string;
  headerImage?: string | null;
};
