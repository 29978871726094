import React from "react";

import BlockquoteComponent from "components/slate/plugins/blockquote/components/BlockquoteComponent";
import { isBlockquoteElement } from "components/slate/plugins/blockquote/utils";
import { UseSlatePlugin } from "components/slate/types";

const useBlockquotePlugin: UseSlatePlugin = () => {
  return {
    renderElement: (props) => {
      if (isBlockquoteElement(props.element)) {
        return <BlockquoteComponent {...props} element={props.element} />;
      }

      return null;
    },
  };
};

export default useBlockquotePlugin;
