import { marked } from "marked";

const renderer = new marked.Renderer();

renderer.paragraph = function (text) {
  if (text.startsWith("<img") && text.endsWith('">')) {
    // by default img tags renders inside p tag, unwrap img from p tag
    return text;
  } else {
    return "<p>" + text + "</p>";
  }
};

marked.setOptions({
  gfm: true,
  sanitize: true,
  renderer,
});

export const markdownToHtml = (data: string) => {
  const html = marked.parse(data);

  return html;
};
