import React from "react";
import { useTranslation } from "react-i18next";
import TextInput from "framework/components/form/TextInput";

// TODO : implement reauthenticate for email

const Email = ({
  user
}: any) => {
  const { t } = useTranslation();
  return (
    <form>
      <TextInput
        type="email"
        name="email"
        placeholder={t("account_form.email.placeholder")}
        labelText={t("account_form.email.label")}
        value={user.email}
        disabled={true}
      />
    </form>
  );
};

export default Email;
