import { useEffect } from "react";
import ReactNotifications from "react-notifications-component";
import { RecoilRoot } from "recoil";

import { AuthProvider } from "auth/AuthProvider";
import Routing from "routing";
import Helmet from "components/Helmet";
import { ThemeHandler, ThemeProvider } from "providers/ThemeProvider";
import FetchProvider from "providers/FetchProvider";
import ConnectionStatusHandler from "components/ConnectionStatusHandler";
import InputModeProvider from "providers/InputModeProvider";
import SentryAnalytics from "components/analytics/SentryAnalytics";
import HeapAnalytics from "components/analytics/HeapAnalytics";
import { DeviceDetectorProvider } from "providers/DeviceDetectorProvider";
import PreloadImages from "images/PreloadImages";
import { RootErrorBoundary } from "components/errors/RootErrorBoundary";
import ReactQueryProvider from "providers/ReactQueryProvider";
import { AppFetchProvider } from "providers/AppFetchProvider";
import { store, StoreContext } from "stores/store";
import MaintenanceGateway from "components/maintenance/MaintenanceGateway";
import RecoilDebugger from "state/RecoilDebugger";

const App = () => {
  useEffect(() => {
    document.body.classList.add("with-scrollbar");
  }, []);

  return (
    <StoreContext.Provider value={store}>
      <RootErrorBoundary>
        <Helmet />
        <ReactNotifications />
        <ConnectionStatusHandler />
        <PreloadImages />
        <RecoilRoot>
          <RecoilDebugger />
          <DeviceDetectorProvider>
            <InputModeProvider>
              <ThemeProvider>
                <AuthProvider>
                  <ThemeHandler />
                  <SentryAnalytics />
                  <HeapAnalytics />
                  <ReactQueryProvider>
                    <FetchProvider>
                      <AppFetchProvider>
                        <MaintenanceGateway>
                          <Routing />
                        </MaintenanceGateway>
                      </AppFetchProvider>
                    </FetchProvider>
                  </ReactQueryProvider>
                </AuthProvider>
              </ThemeProvider>
            </InputModeProvider>
          </DeviceDetectorProvider>
        </RecoilRoot>
      </RootErrorBoundary>
    </StoreContext.Provider>
  );
};

export default App;
