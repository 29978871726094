import React, { createContext, memo, useCallback, useContext } from "react";
import cn from "classnames";

import { SortingState } from "./useSorting";
import useMemoObject from "hooks/useMemoObject";
import Checkbox from "framework/components/form/Checkbox";

import styles from "./index.module.scss";

type SelectionValue = {
  filteredIds: string[];
  selectedIds: Set<string>;
  setSelectedIds: React.Dispatch<React.SetStateAction<Set<string>>>;
};

const initialSelection: SelectionValue = {
  filteredIds: [],
  selectedIds: new Set<string>(),
  setSelectedIds: () => {},
};

const TableSelectionContext = createContext<SelectionValue>(initialSelection);

export const TableSelectionProvider = (
  props: SelectionValue & { children: React.ReactNode }
) => {
  const { filteredIds, selectedIds, setSelectedIds, children } = props;

  const selectionValue = useMemoObject({
    filteredIds,
    selectedIds,
    setSelectedIds,
  });

  return (
    <TableSelectionContext.Provider value={selectionValue}>
      {children}
    </TableSelectionContext.Provider>
  );
};

export const Table = (
  props: JSX.IntrinsicElements["table"] & {
    filter?: React.ReactNode;
    filterHeader?: React.ReactNode;
    filterCount?: React.ReactNode;
  }
) => {
  const {
    filter,
    filterCount,
    filterHeader = "Filter",
    className,
    ...rest
  } = props;

  return (
    <div className={styles.container}>
      <header className={styles.containerHeader}>
        <div className={styles.containerHeaderContent} />
      </header>
      <div>
        <main className={styles.main}>
          {filter && (
            <aside className={styles.aside}>
              <div className={styles.filterHeader}>
                <span>{filterHeader}</span>
                {filterCount && <span>{filterCount}</span>}
              </div>
              <div className={styles.filter}>{filter}</div>
            </aside>
          )}
          <div style={{ width: "100%" }}>
            <table {...rest} className={cn(styles.table, className)}>
              {props.children}
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export const TableHead = (props: JSX.IntrinsicElements["thead"]) => {
  return (
    <thead {...props} className={cn(styles.tableHead, props.className)}>
      {props.children}
    </thead>
  );
};

export const TableBody = (props: JSX.IntrinsicElements["tbody"]) => {
  return (
    <tbody {...props} className={cn(styles.tableBody, props.className)}>
      {props.children}
    </tbody>
  );
};

export const TableRow = (props: JSX.IntrinsicElements["tr"]) => {
  return (
    <tr {...props} className={cn(styles.tableRow, props.className)}>
      {props.children}
    </tr>
  );
};

export const TableRowEmpty = (
  props: JSX.IntrinsicElements["tr"] & { colSpan: number }
) => {
  const { colSpan, ...rest } = props;

  return (
    <tr {...rest} className={cn(styles.tableRow, props.className)}>
      <TableCell className={styles.noResults} colSpan={colSpan}>
        No items found
      </TableCell>
    </tr>
  );
};

export const TableHeaderCell = <T extends object>(
  props: JSX.IntrinsicElements["th"] & {
    sortingKey?: keyof T;
    sorting?: SortingState<T>;
  }
) => {
  const { sortingKey, sorting, ...rest } = props;
  const sortingClassName =
    sorting && sorting.field === sortingKey ? styles[sorting.order] : undefined;

  return (
    <th
      {...rest}
      className={cn(
        styles.tableCell,
        styles.tableHeaderCell,
        sortingClassName,
        props.className
      )}
    >
      {props.children}
    </th>
  );
};

export const TableHeaderCheckboxCell = (props: JSX.IntrinsicElements["td"]) => {
  const { className, ...rest } = props;
  const { filteredIds, selectedIds, setSelectedIds } = useContext(
    TableSelectionContext
  );

  const checked = selectedIds.size > 0;
  const onChange = () => {
    setSelectedIds(checked ? new Set() : new Set(filteredIds));
  };

  return (
    <TableHeaderCell
      {...rest}
      className={cn(styles.tableHeaderCheckboxCell, className)}
    >
      <Checkbox checked={checked} onChange={onChange} />
    </TableHeaderCell>
  );
};

export const TableCheckboxCell = (
  props: JSX.IntrinsicElements["td"] & {
    itemId: string;
  }
) => {
  const { itemId, ...rest } = props;
  const { selectedIds, setSelectedIds } = useContext(TableSelectionContext);

  const checked = selectedIds?.has(itemId);

  const onChange = useCallback((checked) => {
    setSelectedIds?.((state) => {
      const newState = new Set(state);

      checked ? newState.delete(itemId) : newState.add(itemId);

      return newState;
    });
  }, []);

  return (
    <TableCheckboxCellMemoized
      {...rest}
      checked={checked}
      onChange={onChange}
    />
  );
};

export const TableCheckboxCellMemoized = memo<
  JSX.IntrinsicElements["td"] & {
    checked: boolean;
    onChange: (checked: boolean) => void;
  }
>((props) => {
  const { className, checked, onChange, ...rest } = props;

  return (
    <TableCell
      {...rest}
      className={cn(styles.tableCheckboxCell, props.className)}
    >
      <Checkbox checked={checked} onChange={() => onChange(checked)} />
    </TableCell>
  );
});

export const TableCell = (props: JSX.IntrinsicElements["td"]) => {
  return (
    <td {...props} className={cn(styles.tableCell, props.className)}>
      {props.children}
    </td>
  );
};
