import React from "react";
import { Redirect } from "react-router-dom";

import { ErrorBoundaryBase } from "components/errors/ErrorBoundaryBase";

const HelpPageErrorBoundary = ({ children }: any) => {
  return (
    <ErrorBoundaryBase fallback={() => <Redirect to="/error" />}>
      {children}
    </ErrorBoundaryBase>
  );
};

export default HelpPageErrorBoundary;
