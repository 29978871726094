import React from "react";

type MenuIconProps = {
  component: React.ComponentType<any>;
  size?: number;
  style?: React.CSSProperties;
};

const MenuIcon = ({ component, size = 28, style }: MenuIconProps) => {
  let IconComponent = component;

  return <IconComponent style={{ marginRight: 10, ...style }} size={size} />;
};

export default MenuIcon;
