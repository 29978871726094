import React from "react";

import Modal from "framework/components/Modal";
import Button from "framework/components/form/Button";
import { useUserData } from "hooks/useUserData";
import { useCurrentUserId } from "db/currentUser";
import usersQueries from "db/user/hooks";

export const TemplatesTipModal = ({ templatesTipDialog }: any) => {
  const userId = useCurrentUserId();
  const [user] = useUserData(userId);

  const setTemplatesTipSeenMutation = usersQueries.useSetTemplatesTipSeen();

  if (user && user.isTemplateTipSeen) {
    return null;
  }

  return (
    <Modal
      {...templatesTipDialog}
      mascot="julian"
      title="Template saved!"
      rightActions={
        <Button
          variant="pink"
          onClick={() => {
            templatesTipDialog.close();
            setTemplatesTipSeenMutation.mutate();
          }}
        >
          Got it!
        </Button>
      }
    >
      {({ modalData }: any) => (
        <span>Type /{modalData.title} to try your new template.</span>
      )}
    </Modal>
  );
};
