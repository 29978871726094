import React from "react";

import { makeDateId } from "helpers";

const TileContent = ({ date, filledDays }: any) => {
  const formatted = makeDateId(date);

  if (filledDays != null) {
    const array = filledDays.map((note) => note.dateId);

    if (array.includes(formatted)) {
      return (
        <div className="react-calendar__tile--mark react-calendar__tile--markActive" />
      );
    }
  }

  return <div className="react-calendar__tile--mark" />;
};

export default TileContent;
