import { Editor, Element, Point, Text } from "slate";

import {
  isListItemElement,
  isListItemType,
} from "components/slate/plugins/list/utils";
import { isHeadingType } from "components/slate/plugins/heading/utils";
import { ExtendedEditor } from "components/slate/slate-extended/extendedEditor";

const withReference = (options: {
  contextId?: string;
  contextInterval?: [string, string];
  contextType: Element["type"];
}) => (editor: Editor) => {
  const { apply } = editor;

  editor.apply = (operation) => {
    const { contextId, contextType, contextInterval } = options;

    const isInsertableNode =
      "node" in operation &&
      ((Element.isElement(operation.node) && editor.isInline(operation.node)) ||
        Text.isText(operation.node));

    switch (operation.type) {
      case "insert_node":
        if (isHeadingType(contextType)) {
          apply(operation);
          return;
        }

        if (isInsertableNode) {
          apply(operation);
          return;
        }

        return;
      case "merge_node":
        apply(operation);
        return;
      case "move_node":
        apply(operation);
        return;
      case "remove_node":
        if (isHeadingType(contextType) || isListItemType(contextType)) {
          apply(operation);
          return;
        }

        if (isInsertableNode) {
          apply(operation);
          return;
        }

        return;
      case "set_node":
        if (
          "type" in operation.properties &&
          "type" in operation.newProperties &&
          operation.properties.type !== operation.newProperties.type
        ) {
          // don't allow changing type
          return;
        }

        if (contextId && isListItemType(contextType)) {
          const contextElement = editor.children.find(
            (node) => node.id === contextId
          );

          if (
            isListItemElement(contextElement) &&
            "depth" in operation.newProperties &&
            operation.newProperties.depth != null
          ) {
            operation.newProperties.depth = Math.max(
              operation.newProperties.depth,
              contextElement.depth + 1
            );
          }
        }

        apply(operation);
        return;
      case "split_node":
        if (contextId && isListItemType(contextType)) {
          const contextElement = editor.children.find(
            (node) => node.id === contextId
          );

          if (
            isListItemElement(contextElement) &&
            "depth" in operation.properties &&
            operation.properties.depth != null
          ) {
            operation.properties.depth = Math.max(
              operation.properties.depth,
              contextElement.depth + 1
            );
          }
        }

        apply(operation);
        return;
      case "set_selection":
        const newProperties = operation.newProperties;

        if (!newProperties) {
          apply(operation);
          return;
        }

        if (contextId || contextInterval) {
          const { contextStart, contextEnd } = ExtendedEditor.editorContext(
            editor,
            {
              contextId,
              contextInterval,
            }
          );

          if (newProperties.anchor) {
            if (Point.isBefore(newProperties.anchor, contextStart)) {
              newProperties.anchor = contextStart;
            }

            if (Point.isAfter(newProperties.anchor, contextEnd)) {
              newProperties.anchor = contextEnd;
            }
          }

          if (newProperties.focus) {
            if (Point.isBefore(newProperties.focus, contextStart)) {
              newProperties.focus = contextStart;
            }

            if (Point.isAfter(newProperties.focus, contextEnd)) {
              newProperties.focus = contextEnd;
            }
          }
        }

        apply(operation);
        return;
      case "insert_text":
        apply(operation);
        return;
      case "remove_text":
        apply(operation);
        return;
    }
  };

  return editor;
};

export default withReference;
