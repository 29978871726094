import { BaseEditor, Descendant, Editor, Element, Point } from "slate";
import { isCheckableListItem } from "../plugins/list/utils";

import {
  FoldingElement,
  NestingElement,
  ProgressElement,
  SemanticNode,
} from "./types";
import { crawlChildren } from "./utils";
import { PieCheckboxStates } from "framework/components/form/PieCheckbox";

export interface ExtendedEditor extends BaseEditor {
  children: Element[];
  isContextElement: (element: Element) => boolean;
  compareLevels: (a: Element, b: Element) => number;
  isFoldingElement: (element: any) => boolean;
  isNestingElement: (element: any) => boolean;
  isProgressElement: (element: any) => boolean;
  getSemanticChildren: (children: Descendant[]) => SemanticNode[];
}

const ELEMENT_TO_SEMANTIC_PATH = new WeakMap<Element, SemanticNode[]>();
const ARGUMENTS_WEAK_SET = new WeakSet<any>();

const reverse = <T>(array: T[]) => [...array].reverse();

export const ExtendedEditor = {
  getSemanticChildren(editor: Editor, children: Descendant[]) {
    const tree: SemanticNode[] = [];
    const path: SemanticNode[] = [];
    let index = 0;

    let depthCounters: Record<string, number> = {}; // depth-counters map

    for (const element of children) {
      if (!Element.isElement(element)) {
        continue;
      }

      const edgeIndex = path.findIndex(
        (p) => editor.compareLevels(p.element, element) !== -1
      );

      if (edgeIndex !== -1) {
        // keep only current element parents
        path.splice(edgeIndex);
      }

      // calculate list index
      let listIndex = 0;
      if (ExtendedEditor.isNestingElement(editor, element)) {
        // init counter
        if (depthCounters[element.depth] == null) {
          depthCounters[element.depth] = 0;
        }

        // reset all counters with larger depth
        for (const key of Object.keys(depthCounters)) {
          if (Number(key) > element.depth) {
            depthCounters[key] = 0;
          }
        }

        listIndex = depthCounters[element.depth];
        depthCounters[element.depth]++;
      } else {
        // reset depth counters because current list ends
        depthCounters = {}; // depth-counters map
      }

      // reference editor renders only context element or its semantic children
      // context elements are defined by isContextElement function
      let contextPath: SemanticNode[] = [];
      for (let i = 0; i < path.length; i++) {
        if (ExtendedEditor.isContextElement(editor, path[i].element)) {
          contextPath = path.slice(i);
          break;
        }
      }

      const outsideContext = !(
        ExtendedEditor.isContextElement(editor, element) ||
        contextPath.length > 0
      );

      // calculate hidden
      let foldedAncestor: SemanticNode | undefined;
      let hidden = false;
      if (outsideContext) {
        // hide elements that are out of context
        hidden = true;
      } else {
        const foldedAncestor = reverse(contextPath).find(
          (node) =>
            ExtendedEditor.isFoldingElement(editor, node.element) &&
            node.element.folded
        ) as SemanticNode<Element & FoldingElement>;

        if (foldedAncestor) {
          const foldedCount = foldedAncestor.element.foldedCount ?? 0;
          hidden = foldedCount >= index - foldedAncestor.index;
        }
      }

      // calculate dimmed
      let dimmed = false;
      if (isCheckableListItem(element)) {
        dimmed = element.checked || reverse(path).some((node) => node.dimmed);
      }

      // add current element to path
      path.push({
        element,
        children: [],
        index,
        listIndex,
        outsideContext,
        hidden,
        folded: foldedAncestor,
        dimmed,
        descendants: [],
        path: [],
        progress: 0,
        progressState: PieCheckboxStates.auto,
      });

      const last = path[path.length - 1];
      const parent = path[path.length - 2];
      const children = parent ? parent.children : tree;
      last.path = [...path];

      if (parent) {
        path.slice(0, -1).forEach((x) => {
          x.descendants.push(last);
        });
      }

      children.push(last);

      index++;
    }

    for (const semanticNode of tree) {
      calculateProgress(editor, semanticNode);
    }

    return tree;
  },

  calculateSemanticPaths(editor: Editor) {
    if (!ARGUMENTS_WEAK_SET.has(editor.children)) {
      const semanticChildren = ExtendedEditor.getSemanticChildren(
        editor,
        editor.children
      );
      crawlChildren(semanticChildren, (semanticNode) => {
        ELEMENT_TO_SEMANTIC_PATH.set(semanticNode.element, semanticNode.path);
      });
      ARGUMENTS_WEAK_SET.add(editor.children);
    }
  },

  getDroppableIntervals(
    editor: Editor,
    semanticChildren: SemanticNode[],
    contentLength: number
  ): [number, number][] {
    const intervals: [number, number][] = [];
    let lastIndex = 0;
    let skipCount = 0;

    crawlChildren(
      semanticChildren,
      ({ element, children, index, descendants }, context) => {
        if (skipCount > 0) {
          skipCount = skipCount - descendants.length - 1;
          context.skip();
          return;
        }

        if (
          ExtendedEditor.isFoldingElement(editor, element) &&
          element.folded &&
          children.length
        ) {
          skipCount = element.foldedCount || 0;
        }

        if (index > 0) {
          // finish previous interval
          intervals.push([lastIndex, index - 1]);
        }

        lastIndex = index;
      }
    );

    // finish last interval
    intervals.push([lastIndex, Math.max(contentLength - 1, 0)]);

    return intervals;
  },

  semanticPath(editor: Editor, element: Element): SemanticNode[] {
    let path = ELEMENT_TO_SEMANTIC_PATH.get(element);

    if (!path) {
      // additional recalculation in case it wasn't done
      // one of the case - composition input
      ExtendedEditor.calculateSemanticPaths(editor);

      path = ELEMENT_TO_SEMANTIC_PATH.get(element);

      if (!path) {
        throw new Error(
          `Cannot resolve a semantic path from Slate element: ${JSON.stringify(
            element
          )}`
        );
      }
    }

    return path;
  },

  semanticNode(editor: Editor, element: Element): SemanticNode {
    const path = ExtendedEditor.semanticPath(editor, element);
    return path[path.length - 1];
  },

  semanticNodeById(editor: Editor, id: string): SemanticNode {
    const element = editor.children.find((node) => node.id === id);
    const semanticNode = ExtendedEditor.semanticNode(editor, element);

    return semanticNode;
  },

  semanticAncestors(editor: Editor, element: Element): SemanticNode[] {
    const path = ExtendedEditor.semanticPath(editor, element);
    return path.slice(0, path.length - 1);
  },

  semanticDescendants(editor: Editor, element: Element): SemanticNode[] {
    const semanticNode = ExtendedEditor.semanticNode(editor, element);
    return semanticNode.descendants;
  },

  semanticParent(editor: Editor, element: Element): SemanticNode | null {
    const path = ExtendedEditor.semanticPath(editor, element);
    return path.length > 1 ? path[path.length - 2] : null;
  },

  isHiddenById(editor: Editor, element: Element, id: string | null): boolean {
    const path = ExtendedEditor.semanticPath(editor, element);

    const hidden = id != null && path.some((x) => x.element.id === id);

    return hidden;
  },

  isFoldingElementCurried(editor: Editor) {
    return (element: any): element is Element & FoldingElement =>
      ExtendedEditor.isFoldingElement(editor, element);
  },

  isFoldingElement(
    editor: Editor,
    element: any
  ): element is Element & FoldingElement {
    return editor.isFoldingElement(element);
  },

  isNestingElementCurried(editor: Editor) {
    return (element: any): element is Element & NestingElement =>
      ExtendedEditor.isNestingElement(editor, element);
  },

  isNestingElement(
    editor: Editor,
    element: any
  ): element is Element & NestingElement {
    return editor.isNestingElement(element);
  },

  isProgressElement(
    editor: Editor,
    element: any
  ): element is Element & ProgressElement {
    return editor.isProgressElement(element);
  },

  isContextElement(editor: Editor, element: Element) {
    return editor.isContextElement(element);
  },

  editorContext(
    editor: Editor,
    {
      contextId,
      contextInterval,
    }: {
      contextId?: string | undefined;
      contextInterval?: [string, string] | undefined;
    }
  ) {
    let contextStart: Point | null = null;
    let contextEnd: Point | null = null;

    if (contextId) {
      const contextElement = editor.children.find(
        (node) => node.id === contextId
      );
      const semanticNode = ExtendedEditor.semanticNode(editor, contextElement);

      contextStart = Editor.start(editor, [semanticNode.index]);
      contextEnd = Editor.end(editor, [
        semanticNode.index + semanticNode.descendants.length,
      ]);
    } else if (contextInterval) {
      const [startId, endId] = contextInterval;

      const startSemanticNode = ExtendedEditor.semanticNodeById(
        editor,
        startId
      );
      const endSemanticNode = ExtendedEditor.semanticNodeById(editor, endId);

      contextStart = Editor.start(editor, [startSemanticNode.index]);
      contextEnd = Editor.end(editor, [endSemanticNode.index]);
    } else {
      contextStart = Editor.start(editor, [0]);
      contextEnd = Editor.end(editor, [editor.children.length - 1]);
    }

    return {
      contextStart,
      contextEnd,
    };
  },
};

export function calculateProgress(editor: Editor, node: SemanticNode) {
  if (ExtendedEditor.isProgressElement(editor, node.element)) {
    const children = node.children;
    const progressChildren = node.children.filter((x) =>
      ExtendedEditor.isProgressElement(editor, x.element)
    );

    if (!progressChildren.length) {
      node.progress = node.element.checked ? 1 : 0;
      node.progressState = PieCheckboxStates.manual;
    }

    for (const child of children) {
      calculateProgress(editor, child);
    }

    if (progressChildren.length) {
      let _progress = 0;
      let total = 0;
      for (const child of progressChildren) {
        _progress += child.progress;
        total++;
      }

      node.progress = _progress / total;
      node.progressState = PieCheckboxStates.auto;
    }
  } else {
    for (const child of node.children) {
      calculateProgress(editor, child);
    }
  }
}
