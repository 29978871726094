import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

function FormGroup({ children }: any) {
  return <div className={styles.formGroup}>{children}</div>;
}

FormGroup.propTypes = {
  children: PropTypes.any,
};

export default FormGroup;
