import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";

type HelmetProps = {
  pageTitle?: string;
};

const Helmet = ({ pageTitle }: HelmetProps) => {
  return (
    <ReactHelmet>
      {pageTitle ? <title>Thunk | {pageTitle}</title> : <title>Thunk</title>}
    </ReactHelmet>
  );
};

export default Helmet;
