import { useCallback, useMemo, useRef } from "react";

const useEvent = <T extends (...args: any[]) => any>(fn: T): T => {
  const listener = useRef<T>(fn);

  useMemo(() => {
    listener.current = fn;
  }, [fn]);

  return useCallback(
    ((...args) => {
      return listener.current(...args);
    }) as T,
    []
  );
};

export default useEvent;
