import { descend, sortWith } from "ramda";
import { dateIdToDate } from "helpers";

import { Document } from "thunk-core";

export const sortDocumentsByDate = (documents: Document[]) => {
  const sorted = sortWith(
    [
      descend((x) =>
        x.dateId ? dateIdToDate(x.dateId) : x.createdAt.toDate()
      ),
    ],
    documents
  );

  return sorted;
};
